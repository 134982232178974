import React from 'react';
import Label from '../../../components/Common/TextLabel/Label';
import { LabelFontSize, LabelTextType } from '../../../utils/ComponentEnums';
import { useTranslation } from 'react-i18next';

export default function Start2() {
  const { t } = useTranslation();

  const second = [
    {
      Header: [t('onboardingStart.welcome_to_the_club')],
      Content: [
        t('onboardingStart.create_your_profile_plan_your_trial'),
        t('onboardingStart.start_learning_english_with'),
      ],
    },
  ];
  return second.map((option, index) => (
    <div className="text-center" key={`firstDiv_${index}`}>
      {option.Header.map((optionHeader, i) => (
        <div key={`${optionHeader}_div_${i}`}>
          <Label
            key={`${optionHeader}_label_${i}`}
            fontType={LabelTextType.Bolder}
            fontSize={LabelFontSize.fs1}
            value={optionHeader}
          />
        </div>
      ))}
      <div className="mt-4 mb-1">
        {option.Content.map((content, i) => (
          <div key={`${content}_div_${i}`}>
            <Label
              key={`${content}_label_${i}`}
              fontType={LabelTextType.Bold}
              fontSize={LabelFontSize.fs6}
              value={content}
            />
          </div>
        ))}
      </div>
    </div>
  ));
}
