import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Actions from '../../../store/redux/actions';

const CampaignDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const campaign = useSelector((e) => e.campaign.getById);
  const campaignId = location.state.id;

  useEffect(() => {
    dispatch(
      Actions.campaignActions.getSelectedCampaignDetailsAction(campaignId)
    );
  }, [location.state]);

  return (
    <div className="container">
      <div className="py-5 ">
        <div dangerouslySetInnerHTML={{ __html: campaign.content }}></div>
      </div>
    </div>
  );
};

export default CampaignDetail;
