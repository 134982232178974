import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(endPoint.faqGroup.CREATE_FAQGROUP, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.faqGroup.UPDATE_FAQGROUP}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.faqGroup.REMOVE_FAQGROUP}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.faqGroup.GETBYID_FAQGROUP}/${id}`);
};
export const getAll = async (lang) => {
  return await getRequest(`${endPoint.faqGroup.GETALL_FAQGROUP}/${lang.userLanguage}`);
};
