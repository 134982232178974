import React from 'react';

export default function HeadTags({
  hSize,
  strong,
  marginLeft,
  textColor,
  text,
  marginBottom,
}) {
  let myHeaderTag = undefined;
  let setMyText = strong ? <strong>{text}</strong> : text;

  switch (hSize) {
    case 1:
      myHeaderTag = (
        <h1
          className={`d-flex ms-${marginLeft} mb-${marginBottom} ${textColor}`}
        >
          {setMyText}
        </h1>
      );
      break;
    case 2:
      myHeaderTag = (
        <h2
          className={`d-flex ms-${marginLeft} mb-${marginBottom} ${textColor}`}
        >
          {setMyText}
        </h2>
      );
      break;
    case 3:
      myHeaderTag = (
        <h3
          className={`d-flex ms-${marginLeft} mb-${marginBottom} ${textColor}`}
        >
          {setMyText}
        </h3>
      );
      break;
    case 4:
      myHeaderTag = (
        <h4
          className={`d-flex ms-${marginLeft} mb-${marginBottom} ${textColor}`}
        >
          {setMyText}
        </h4>
      );
      break;
    case 5:
      myHeaderTag = (
        <h5
          className={`d-flex ms-${marginLeft} mb-${marginBottom} ${textColor}`}
        >
          {setMyText}
        </h5>
      );
      break;
    case 6:
      myHeaderTag = (
        <h6
          className={`d-flex ms-${marginLeft} mb-${marginBottom} ${textColor}`}
        >
          {setMyText}
        </h6>
      );
      break;
    default:
      break;
  }
  return <>{myHeaderTag}</>;
}
