const Card = ({
  ref,
  padding,
  id,
  discountBody,
  header,
  body,
  headerLeft,
  bodyRight,
  shadow,
  backGround = '',
  outerBackgroud,
  imgSrc,
  cardTitle,
  cardText,
  onClick,
  visible,
  bodyRounded,
  cardRounded,
  scheduleBorder,
  cardKey,
  cardStyle,
  backGroundImage,
  minHeight,
}) => {
  let rounded = bodyRounded ? 'rounded-4' : 'rounded';
  let className =
    visible !== id
      ? 'card ms-4 d-none'
      : cardRounded
      ? 'card rounded-5'
      : cardStyle?.rounded === undefined
      ? 'card rounded-4'
      : 'card';
  let outClass = ' shadow mb-4';
  let hasShadow = shadow ? (className += ' shadow') : '';
  let hasBg = outerBackgroud ? (className += ' ' + outerBackgroud) : '';

  let scheduleBorderColor = '';

  switch (scheduleBorder) {
    case 'Online':
      scheduleBorderColor = '10px solid #4CBC9A';
      break;
    case 'Cafe':
      scheduleBorderColor = '10px solid #FFDB80';
      break;
    case 'Kids':
      scheduleBorderColor = '10px solid #FF8F80';
      break;
    case 'Company':
      scheduleBorderColor = '10px solid #3A3650';
      break;
    default:
      break;
  }

  return (
    <div
      key={cardKey}
      id={id}
      ref={ref}
      className={`my-2 ${className} ${cardKey}`}
      style={{
        ...cardStyle,
      }}
    >
      {header && (
        <div className="card-header  py-3 bg-white shadow mb-4 rounded">
          <h6 className="text-secondary">{header}</h6>
        </div>
      )}

      {headerLeft && (
        <div className="row g-0" style={{ overflowX: 'auto' }}>
          <div className=" col-2 d-inline-flex  align-items-center justify-content-center border-end ">
            <h6 className="d-inline-flex  fw-bold">{headerLeft}</h6>
          </div>

          <div className="col-10 ">
            <div className={`card-body  flex-grow-1 ${rounded} `}>
              {bodyRight}
            </div>
          </div>
        </div>
      )}

      {discountBody && (
        <div
          className="d-sm-flex justify-content-center"
          style={{ position: 'absolute', left: '0', right: '0' }}
        >
          {discountBody}
        </div>
      )}

      <div
        className={`card-body ${rounded} ${backGround}`}
        style={{
          minHeight: minHeight,
          borderLeft: scheduleBorderColor,
          padding: padding,
          maxHeight: cardStyle?.maxHeight,
          maxWidth: cardStyle?.maxWidth,
          backgroundImage: `url(${backGroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '80%',
          backgroundPositionY: '170px',
          backgroundPositionX: '50px',
          minWidth: '150px',
        }}
        onClick={onClick}
      >
        {imgSrc ? (
          <img
            src={imgSrc.src}
            className={`card-img-top ${imgSrc.round} ${imgSrc.style}`}
            alt={imgSrc.alt}
            style={{
              maxHeight: imgSrc.maxHeight,
              maxWidth: imgSrc.maxWidth,
            }}
          />
        ) : (
          ''
        )}
        {cardTitle ? (
          <span className={`card-title ${cardTitle.style}`}>
            {cardTitle.title}
          </span>
        ) : (
          ''
        )}
        {cardText ? (
          <p className={`card-text ${cardText.style}`}>{cardText.text}</p>
        ) : (
          ''
        )}
        {body}
      </div>
    </div>
  );
};
export default Card;
