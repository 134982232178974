import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import { Entities } from '../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const couponCodes = useSelector((e) => e.couponCode.getAll || []);
  const currentUserClaim = useSelector((e) => e.auth.claim);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.couponCodes.getAll)
    ) {
      dispatch(Actions.couponCodesActions.getAllAction());
    }
  };

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'name', label: t('common.name') },
    { key: 'codes', label: t('couponCodes.codes') },
    { key: 'details', label: t('couponCodes.details') },
    { key: 'startTime', label: t('common.start_date') },
    { key: 'endTime', label: t('common.end_date') },
  ];
  const editAction = (val) => {
    navigate('/coupon-code-update', { state: { id: val.id } });
  };
  const removeAction = async (id) => {
    dispatch(Actions.couponCodesActions.removeAction(id));
    getList();
  };
  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <HeadTags hSize={1} text={t('couponCodes.CouponCodes')} strong />
      </div>
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.speaker.create
      ) && (
        <div className="row mb-3">
          <div className="d-flex justify-content-end">
            <Button
              type={ButtonType.Successbg}
              text={t('couponCodes.add_new')}
              textColor={TextColors.Light}
              buttonCol={2}
              onClick={() => navigate('/coupon-code-create')}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={couponCodes}
                  headers={headers}
                  page={Entities.couponCodes}
                  striped
                  bordered
                  edit={(val) => editAction(val)}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  idSearchOnly={true}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.couponCodes.getAll,
    content
  );
};
export default List;
