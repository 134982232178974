import axios from 'axios';
import config from '../config.json';
import { storageKeys } from '../utils/Enum';
const BASE_URL = config.Connections.API.host;

export const getToken = () => localStorage.getItem(storageKeys.token) ?? '';
export const getAuthorizationHeader = () => `Bearer ${getToken()}`;
const headers = {
  Accept: 'application/json, image/jpeg',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  Authorization: getAuthorizationHeader(),
};

export default axios.create({
  baseURL: BASE_URL,
  headers: headers,
  withCredentials: true,
});
