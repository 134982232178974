import { Navigate } from "react-router-dom";

function checkClaims(currentUserClaim, requiredClaim, content = undefined, helperClaim) {
  if (currentUserClaim !== undefined && content !== undefined) {
    const hasClaim = currentUserClaim.includes(requiredClaim) || (helperClaim !== undefined && currentUserClaim.includes(helperClaim));
    if (hasClaim) {
      return content
    } else {
      return <Navigate to="/unauthorized" replace />
    }

  }
  else if (currentUserClaim !== undefined) {
    return currentUserClaim?.includes(requiredClaim) || (helperClaim !== undefined && currentUserClaim.includes(helperClaim));
  }
}
export default checkClaims;
