import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { useNavigate } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import { ToastContainer, toast } from 'react-toastify';
import { Entities } from '../../../utils/Enum';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const managerList = useSelector((e) => e.manager.getAll);
  const companyList = useSelector((e) => e.company.getAll);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const isSuccess = useSelector((e) => e.manager.isSuccess);
  const validationMessage = useSelector((e) => e.manager.validationMessage);
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);
  const [handleError, setHandleError] = useState('');

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          dispatch(Actions.managerActions.cleanState(true));
          setHandleError('');
          dispatch(Actions.managerActions.getAllAction(filterData));
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getList();
  }, [filterData, currentUserClaim]);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(null, true);
    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
  }, []);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        dispatch(Actions.managerActions.getAllAction(filterData));
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const getList = () => {
    dispatch(Actions.companyActions.dropdownListAction());
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.manager.getAll) &&
      Object.keys(filterData).length > 0 &&
      dateChanged === false
    ) {
      dispatch(Actions.managerActions.getAllAction(filterData));
    }
  };

  const editAction = (val) => {
    navigate('/manager-update', { state: { id: val.id } });
  };
  const removeAction = (id) => {
    dispatch(Actions.managerActions.removeAction(id));
  };
  const onSearch = (e) => {
    e.preventDefault();
    if (checkClaims(currentUserClaim, Claims.backofficeClaims.manager.getAll)) {
      dispatch(Actions.managerActions.getAllAction(filterData));
    }
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      return rest;
    });
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
  };

  const headers = [
    { key: 'id', label: t('common.managerId') },
    { key: 'managerName', label: t('common.name') },
    { key: 'email', label: t('common.email') },
    { key: 'phone', label: t('common.phone') },
    { key: 'image', label: t('manager.table_image') },
    { key: 'companyName', label: t('company.companyName') },
    { key: 'locations', label: t('manager.table_branch') },
    { key: 'isActive', label: t('manager.table_status') },
    { key: 'createdAt', label: t('common.created_at') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <HeadTags hSize={1} text={t('manager.manager_list')} strong />
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                  {checkClaims(
                    currentUserClaim,
                    Claims.backofficeClaims.manager.create
                  ) && (
                    <Button
                      type={ButtonType.Successbg}
                      text={t('manager.add_new_manager')}
                      textColor={TextColors.Light}
                      buttonCol={2}
                      onClick={() => navigate('/manager-create')}
                    />
                  )}
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <Button
                    text={t('cardHeaderFilter.search')}
                    icon={faSearch}
                    type={
                      dateChanged ? ButtonType.Successbg : ButtonType.Secondary
                    }
                    iconColor={TextColors.Light}
                    textColor={TextColors.Light}
                    iconSide
                    bold
                    size={ButtonSize.Small}
                    buttonCol={!mobileView ? 1 : 3}
                    onClick={onSearch}
                    disabled={!dateChanged}
                  />
                </div>
                <div className="row my-2">
                  <div className="col-lg-3">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('manager.filter_manager_name')}
                      inputValue={filterData.name || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-lg-3 mt-md-0 mt-1">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-md-0 mt-1">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-md-2 mt-3">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_company')}
                      options={companyList}
                      selectedProps={{ key: 'companyName', value: 'companyId' }}
                      onChange={(value) => handleDropdown('sType', value)}
                      onReset={() => handleResetDropdown('sType')}
                      isfilter
                    />
                  </div>
                </div>
              </>
            }
          />
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={managerList}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.manager}
                  edit={(val) => editAction(val)}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  idSearchOnly={true}
                  exportName={Entities.manager.exportName}
                />
              </div>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.manager.getAll,
    content
  );
};
export default List;
