const EnumBase = {
  OrderItemStatus: {
    Active: 'OrderItemStatus.0',
    Freezed: 'OrderItemStatus.1',
    Completed: 'OrderItemStatus.2',
    Renew: 'OrderItemStatus.3',
  },
  ProductType: {
    Group: 'ProductType.0',
    Private: 'ProductType.1',
  },
  OrderStatus: {
    Waiting: 'OrderStatus.0',
    PaymentWaiting: 'OrderStatus.1',
    Active: 'OrderStatus.2',
    Completed: 'OrderStatus.3',
    Canceled: 'OrderStatus.4',
    Expired: 'OrderStatus.5',
  },
  TrialStatus: {
    FreeTrial: 'TrialStatus.0',
    PaidTrial: 'TrialStatus.1',
    NonTrial: 'TrialStatus.2',
    Gift: 'TrialStatus.3',
  },
  GiftStatus: {
    Gifting: 'GiftStatus.0',
    Gifted: 'GiftStatus.1',
    NonGift: 'GiftStatus.2',
  },
  TypeOfService: {
    OneToOne: 'TypeOfService.0',
    Group: 'TypeOfService.1',
  },
  UserArea: {
    Online: 'UserArea.0',
    Cafe: 'UserArea.1',
    Kids: 'UserArea.2',
    Company: 'UserArea.3',
    None: 'UserArea.4',
  },
  MeetingAttendeeCreationType: {
    Standart: 'MeetingAttendeeCreationType.0',
    Recomponse: 'MeetingAttendeeCreationType.1',
    FreeTrial: 'MeetingAttendeeCreationType.2',
    PaidTrial: 'MeetingAttendeeCreationType.3',
    ExcuseGift: 'MeetingAttendeeCreationType.4',
    BranchFreeze: 'MeetingAttendeeCreationType.5',
  },
  MeetingAttendeeStatus: {
    Planned: 'MeetingAttendeeStatus.0',
    Completed: 'MeetingAttendeeStatus.1',
    BranchFreeze: 'MeetingAttendeeStatus.2',
    Canceled: 'MeetingAttendeeStatus.3',
    Recompense: 'MeetingAttendeeStatus.4',
    Unattended: 'MeetingAttendeeStatus.5',
    PersonalFreeze: 'MeetingAttendeeStatus.6',
  },
  MeetingStatus: {
    Waiting: 'MeetingStatus.0',
    Completed: 'MeetingStatus.1',
    Frozen: 'MeetingStatus.2',
    Canceled: 'MeetingStatus.3',
    Recompense: 'MeetingStatus.4',
    UnPayedCompleted: 'MeetingStatus.5',
  },
  PaymentStatus: {
    UnPayed: 'PaymentStatus.0',
    Payed: 'PaymentStatus.1',
    Partial: 'PaymentStatus.2',
    Repayment: 'PaymentStatus.3',
  },
  PaymentType: {
    Cash: 'PaymentType.0',
    CreditCard: 'PaymentType.1',
    Eft: 'PaymentType.2',
    Partial: 'PaymentType.3',
    CafeCreditCard: 'PaymentType.4',
    Gift: 'PaymentType.5',
  },
  ClassroomUserStatus: {
    Hidden: 'ClassroomUserStatus.0',
    Active: 'ClassroomUserStatus.1',
    Passive: 'ClassroomUserStatus.2',
  },
  UserStatus: {
    Active: 'UserStatus.0',
    Passive: 'UserStatus.1',
  },
  Environment: {
    DEV: 0,
    BETA: 1,
    GG: 2,
    PROD: 3,
  },
};

export default EnumBase;
