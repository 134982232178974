export const Facebook = {
  Sdk: 'https://connect.facebook.net/en_US/sdk.js',
  ApiKey: '1547774209066930',
  AppVersion: 'v17.0',
};

export const Google = {
  Sdk: 'https://accounts.google.com/gsi/client',
  ClientID:
    '106700452309-l3erv7nhj5f7p032blbda1i8tbm9ogso.apps.googleusercontent.com',
};
export const cardArray = [
  {
    id: 1,
    title: 'Yapı Kredi',
    description: 'TR36 0006 7010 0000 0054 0829 64',
    owner: 'MUSTAFA ÖZMEN',
    png: '/images/Yapı_Kredi_logo.png',
  },
  {
    id: 3,
    title: 'Yapı Kredi',
    description: 'TR86 0006 7010 0000 0022 8411 77',
    owner: 'MEET2TALK YAZILIM ANONİM ŞİRKETİ',
    png: '/images/Yapı_Kredi_logo.png',
  },
];
export const cardPropertyNames = ['id', 'title', 'description', 'owner', 'png'];
