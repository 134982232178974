import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  RegexType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import { formatPhone } from '../../../hooks/useFormatPhone';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import { countryListAllIsoData } from '../../../utils/Enum';
import { getNames } from 'i18n-iso-countries';
import moment from 'moment';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

const Create = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate(-1);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const managerList = useSelector((e) => e.manager.getAll);
  const companyList = useSelector((e) => e.company.getAll);
  const attendee = useSelector((e) => e.attendee.attendee);
  const isSuccess = useSelector((e) => e.attendee.isSuccess);
  const lang = useSelector((e) => e.lang.language);
  const validationMessageAttendee = useSelector(
    (e) => e.attendee.validationMessage
  );
  const learningPurpose = useSelector((e) => e.staticData.LearningPurpose);
  const staticDatas = useSelector((e) => e.staticData);
  const levelGroup = useSelector(
    (e) => e.preferenceOptions.preferenceLevelGroup
  );
  const [createData, setCreateData] = useState(attendee);
  const redirect = '/attendee-list';
  const [handleError, setHandleError] = useState('');
  const [error, setError] = useState({});

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          dispatch(Actions.attendeeActions.cleanState());
          setCreateData({});
          setHandleError('');
          navigate(redirect);
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessageAttendee !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessageAttendee}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessageAttendee !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessageAttendee}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessageAttendee]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value, name);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.attendeeActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(Actions.attendeeActions.createAction(createData));
  };

  async function GetLists() {
    await dispatch(Actions.managerActions.getAllAction('Dropdown'));
    await dispatch(Actions.companyActions.dropdownListAction());
  }
  useEffect(() => {
    GetLists();
  }, []);

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);


  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const handleDropdown = (propName, value) => {
    setCreateData({ ...createData, [propName]: value });
    dispatch(
      Actions.attendeeActions.holdValue({
        ...createData,
        [propName]: value,
      })
    );
  };

  const handleSelectedData = (key, value) => {
    const dataArray = createData?.[key]?.split(',');

    if (dataArray?.length === undefined) {
      const updatedData = { ...createData, [key]: value };
      setCreateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }

    if (dataArray?.includes(value)) {
      const index = dataArray.indexOf(value);
      if (index !== -1) {
        const newDataArray = dataArray.slice();
        newDataArray.splice(index, 1);
        const updatedData = {
          ...createData,
          [key]: newDataArray.join(','),
        };
        setCreateData(updatedData);
        if (newDataArray?.length === 0) {
          const newData = { ...createData };
          delete newData[key];
          setCreateData(newData);
          dispatch(Actions.attendeeActions.holdValue(newData));
        } else {
          dispatch(Actions.attendeeActions.holdValue(updatedData));
        }
      }
    }

    if (dataArray?.length !== undefined && !createData[key]?.includes(value)) {
      const updatedData = {
        ...createData,
        [key]: createData[key] + ',' + value,
      };
      setCreateData(updatedData);
      dispatch(Actions.attendeeActions.holdValue(updatedData));
    }
  };
  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }

  const [countryList, setCountryList] = useState([]);
  const countryNames = countryListAllIsoData.map((item) => {
    const name = getNames(lang?.split(',')[0].split('-')[0])[item.code];
    const isoName = item.name;
    const phone = item.phone;

    return { name: name, phone: phone, isoName: isoName };
  });
  useEffect(() => {
    setCountryList(countryNames);
  }, []);

  const onFocus = (e) => {
    e.preventDefault();
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  };

  useEffect(() => {
    if (createData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  }, [createData?.phone, error?.phone]);

  useEffect(() => {
    return () => {
      dispatch(Actions.attendeeActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('attendee.adding_new_attendee_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'firstName'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={createData.firstName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.firstName}
                      regexType={RegexType.Name}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'lastName'}
                      inputType={InputType.Text}
                      labelText={t('common.lastName')}
                      inputValue={createData.lastName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.lastName}
                      regexType={RegexType.Name}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'dateOfBirth'}
                      inputType={InputType.Date}
                      labelText={t('personalInformation.birth_date')}
                      inputValue={
                        moment(createData.dateOfBirth).format('yyyy-MM-DD') ||
                        ''
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Email}
                      labelText={t('common.email')}
                      inputValue={createData.email || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.email}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.country_code')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: countryList.map((item) => ({
                          key: `${item.name} (${item.phone})`,
                          value: `${item.phone} ${item.isoName}`,
                          name: 'countryCode',
                        })),
                        selectedValue: createData.countryCode || '',
                      }}
                      handleSelectChange={(val) => {
                        setCreateData({
                          ...createData,
                          [val.name]: val.value,
                        });
                        dispatch(
                          Actions.attendeeActions.holdValue({
                            ...createData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'phone'}
                      inputType={InputType.Tel}
                      labelText={t('common.phone')}
                      inputValue={formatPhone(createData.phone) || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      placeholder={'(5xx) xxx xx xx'}
                      onFocus={onFocus}
                      error={error?.phone}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_level')}
                      options={selectedLanguageLevels}
                      selectedProps={{ key: 'key', value: 'key' }}
                      onChange={(value) =>
                        handleDropdown('languageLevel', value)
                      }
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_learningPurpose')}
                      options={learningPurpose}
                      selectedProps={{ key: 'key', value: 'value' }}
                      onChange={(value) =>
                        handleSelectedData('learningPurpose', value)
                      }
                      isMulti
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_manager')}
                      options={managerList}
                      selectedProps={{ key: 'managerName', value: 'managerId' }}
                      onChange={(value) => handleDropdown('managerId', value)}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_company')}
                      options={companyList}
                      selectedProps={{ key: 'companyName', value: 'companyId' }}
                      onChange={(value) => handleDropdown('companyId', value)}
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.country')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: countryList.map((item) => ({
                          key: item.name,
                          value: item.name,
                          name: 'country',
                        })),
                        selectedValue: createData.country || '',
                      }}
                      handleSelectChange={(val) => {
                        setCreateData({
                          ...createData,
                          [val.name]: val.value,
                        });
                        dispatch(
                          Actions.managerActions.holdValue({
                            ...createData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                    />{' '}
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(createData).length > 5 &&
                        Object.values(error).every((e) => e === undefined)
                          ? ButtonType.Successbg
                          : ButtonType.Secondary
                      }
                      text={t('button.common_submit')}
                      textColor={TextColors.Light}
                      onClick={onCreate}
                      buttonCol={2}
                      disabled={
                        Object.keys(createData).length > 5 &&
                        Object.values(error).every((e) => e === undefined)
                          ? false
                          : true
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.attendee.create,
    content
  );
};
export default Create;
