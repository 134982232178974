import { useEffect, useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SelectWithSearch = ({
  values,
  handleChange,
  selectedValue,
  labelText,
  onFocus,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inputTimeout, setInputTimeout] = useState(null);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (inputTimeout) {
      clearTimeout(inputTimeout);
    }

    setInputTimeout(
      setTimeout(() => {
        loadUsers();
      }, 1000)
    );

    return () => clearTimeout(inputTimeout);
  }, [searchTerm]);

  const loadUsers = () => {
    // api call
    const filteredValues = values.filter((text) =>
      text.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setData(filteredValues);
    filteredValues.length === 0 ? setStatus('') : setStatus('dropdown');
  };
  return (
    <>
      <div className="dropdown bg-white d-flex">
        <label
          className="ms-2 position-absolute"
          style={{ marginTop: '-0.75rem', fontSize: '.7rem' }}
        >
          <span className="h6 small bg-white text-muted px-1">{labelText}</span>
        </label>
        <input
          onFocus={(e) => setSearchTerm(e.target.value)}
          type="text"
          placeholder="Location"
          className="form-control mt-2 border border-info"
          data-bs-toggle={status}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            onFocus();
          }}
        />
        {searchTerm !== '' ? (
          <FontAwesomeIcon
            className="mt-3 ms-2"
            role={'button'}
            onClick={() => setSearchTerm('')}
            icon={faXmark}
          />
        ) : undefined}
        <ul className="dropdown-menu">
          {data.map((text) => (
            <li
              className="mx-2"
              key={text}
              id={text}
              onClick={({ target: { id } }) => {
                handleChange(id);
                setSearchTerm(id);
              }}
            >
              {text}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
