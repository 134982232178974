import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../utils/ComponentEnums';
import useDebounce from '../../../hooks/useDebounce';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

const ManageClaims = () => {
  const { t } = useTranslation();
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const userInfo = useSelector((e) => e.user.filteredUser) || [];
  const isSuccessUser = useSelector((e) => e.user.isSuccess);
  const validationMessagesUser = useSelector((e) => e.user.validationMessage);
  const isSuccess = useSelector((e) => e.userOperationClaim.isSuccess);
  const validationMessages = useSelector(
    (e) => e.userOperationClaim.validationMessage
  );
  const [handleValidationMessages, setHandleValidationMessage] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [createData, setCreateData] = useState({});
  const [searcValue, setSearcValue] = useState('');
  const debouncedValue = useDebounce(searcValue, 500);
  const pageNumber = 1;
  const pageSize = 10;

  useEffect(() => {
    if (debouncedValue !== '' && debouncedValue !== null) {
      dispatch(Actions.userActions.getByIdAction(debouncedValue));
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (isSuccess) {
      setCreateData({});
    }
    if (isSuccessUser) {
      setCreateData({ userId: userInfo.id });
      setSearcValue('');
    }
  }, [isSuccess, isSuccessUser]);
  useEffect(() => {
    setHandleValidationMessage(validationMessages);
  }, [validationMessages, validationMessagesUser]);
  const onChangeText = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearcValue(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await dispatch(Actions.userOperationClaimActions.createAction(createData));
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('claims.manage_user_claims_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex row justify-content-start">
                      <div className="col-4">
                        <InputLabelOnBorder
                          id={'userEmail'}
                          inputType={InputType.Email}
                          labelText={t('claims.user_email_address')}
                          inputValue={createData.userEmail || ''}
                          onChange={onChangeText}
                        />
                      </div>
                      <div className="col-2 mt-2">
                        <Button
                          textColor={TextColors.Light}
                          iconColor={TextColors.Light}
                          buttonCol={9}
                          text={t('claims.set_claims')}
                          type={ButtonType.Successbg}
                          onClick={() =>
                            dispatch(Actions.userActions.getFilteredAction())
                          }
                        />
                      </div>
                      {createData.id !== undefined && (
                        <div className="mt-2 d-flex justify-content-end">
                          <Button
                            textColor={TextColors.Light}
                            iconColor={TextColors.Light}
                            buttonCol={0}
                            text={t('claims.submit')}
                            type={ButtonType.Successbg}
                            onClick={onSubmit}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.userOperationClaim.create,
    content
  );
};
export default ManageClaims;
