import {
  create,
  update,
  remove,
  getById,
  getAll,
} from '../../../services/apiServices/faqGroupServices';
import ActionTypes from './actionTypes';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res.data.code === 200) {
        await dispatch(createReducer());
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.faqGroup.CREATE_ACTION_FAQGROUP };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      await dispatch(updateReducer());
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.faqGroup.UPDATE_ACTION_FAQGROUP };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.faqGroup.GETBYID_ACTION_FAQGROUP,
    payload: { data },
  };
};
const getAllAction = (lang) => {
  return async (dispatch) => {
    await getAll(lang).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.faqGroup.GETALL_ACTION_FAQGROUP,
    payload: { data },
  };
};
const holdValue = (data) => {
  return { type: ActionTypes.faqGroup.HOLD_VALUE_FAQGROUP, payload: data };
};

const faqGroupActions = {
  createAction,
  createReducer,
  updateAction,
  updateReducer,
  removeAction,
  getByIdAction,
  getByIdReducer,
  getAllAction,
  getAllReducer,
  holdValue,
};
export default faqGroupActions;
