import React from 'react';
import moment from 'moment';
import { faChessKing } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const MonthlyScheduler = ({
  weeks,
  onlineDatas,
  kidsDatas,
  cafeDatas,
  companyDatas,
  handleCellClick,
}) => {
  const { t } = useTranslation();
  const weekdays = moment.weekdaysShort().map((day, index) => (
    <th key={index} className="weekday col">
      {moment()
        .day(index + 1)
        .format('ddd')}{' '}
      {/* Start from Monday (1) */}
    </th>
  ));

  const renderMonthlyTableHeaders = () => {
    return (
      <tr
        className="text-muted p-0 m-0 text-center position-sticky bg-light top-0"
        style={{ zIndex: '1' }}
      >
        {weekdays}
      </tr>
    );
  };

  const groupEventsByDate = (events) => {
    const groupedEvents = {};
    events?.forEach((event) => {
      const date = event.date;
      if (!groupedEvents[date]) {
        groupedEvents[date] = [];
      }
      groupedEvents[date].push(event);
    });
    return groupedEvents;
  };

  const renderMonthlyTableRows = () => {
    const groupedOnlineDatas = groupEventsByDate(onlineDatas);
    const groupedCafeDatas = groupEventsByDate(cafeDatas);
    const groupedKidsDatas = groupEventsByDate(kidsDatas);
    const groupedCompanyDatas = groupEventsByDate(companyDatas);
    return (
      <>
        {weeks.map((week) => (
          <tr key={week.format('W')}>
            {Array.from({ length: 7 }, (_, i) => {
              const currentDay = moment(week)
                .add(i, 'days')
                .format('DD-MM-YYYY');
              const onlineEvents =
                groupedOnlineDatas[currentDay]?.filter(
                  (item) => item.meetingStatus !== t('MeetingStatus.3')
                ) || [];
              const cafeEvents =
                groupedCafeDatas[currentDay]?.filter(
                  (item) => item.meetingStatus !== t('MeetingStatus.3')
                ) || [];
              const kidsEvents =
                groupedKidsDatas[currentDay]?.filter(
                  (item) => item.meetingStatus !== t('MeetingStatus.3')
                ) || [];
              const companyEvents =
                groupedCompanyDatas[currentDay]?.filter(
                  (item) => item.meetingStatus !== t('MeetingStatus.3')
                ) || [];

              return (
                <td
                  key={moment(week).add(i, 'days').format('DD-MM-YYYY')}
                  className={
                    moment(week).add(i, 'days').format('DD-MM-YYYY') ===
                    moment().format('DD-MM-YYYY')
                      ? 'today p-0'
                      : 'p-0'
                  }
                  style={{ height: '150px' }}
                >
                  <div className="cell-date ">
                    {moment(week).add(i, 'days').format('D')}
                  </div>

                  {onlineEvents.map((event, index) => (
                    <div
                      key={event.id}
                      onClick={() =>
                        handleCellClick(moment(week).add(i, 'days'))
                      }
                      className={`card rounded-3  ${
                        event.isActive === false ? 'opacity-50' : ''
                      }`}
                      style={{ borderLeft: '10px solid #4CBC9A' }}
                      role="button"
                    >
                      {index === 0 && (
                        <div className="card-body p-1">
                          <span className="text-muted d-flex d-sm-none p-0">
                            {onlineEvents.length}
                          </span>
                          {onlineEvents.length > 1 ? (
                            <span className="d-none d-sm-flex p-0 m-0">
                              {onlineEvents.length} {t('calendar.sessions')}
                            </span>
                          ) : (
                            <>
                              <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex ">
                                {event.speakerName}
                              </p>
                              <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex ">
                                {event.interval}
                              </p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}

                  {cafeEvents.map((event, index) => (
                    <div
                      key={event.id}
                      onClick={() =>
                        handleCellClick(moment(week).add(i, 'days'))
                      }
                      className={`card rounded-3  ${
                        event.isActive === false ? 'opacity-50' : ''
                      }`}
                      style={{ borderLeft: '10px solid #FFDB80' }}
                      role="button"
                    >
                      {index === 0 && (
                        <div className="card-body p-1">
                          <span className="text-muted d-flex d-sm-none p-0">
                            {cafeEvents.length}
                          </span>
                          {cafeEvents.length > 1 ? (
                            <span className="d-none d-sm-flex p-0 m-0">
                              {cafeEvents.length} {t('calendar.sessions')}
                            </span>
                          ) : (
                            <>
                              <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex ">
                                {event.speakerName}
                              </p>
                              <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex ">
                                {event.interval}
                              </p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}

                  {kidsEvents.map((event, index) => (
                    <div
                      key={event.id}
                      onClick={() =>
                        handleCellClick(moment(week).add(i, 'days'))
                      }
                      className={`card rounded-3  ${
                        event.isActive === false ? 'opacity-50' : ''
                      }`}
                      style={{ borderLeft: '10px solid #FF8F80' }}
                      role="button"
                    >
                      {index === 0 && (
                        <div className="card-body p-1">
                          <span className="text-muted d-flex d-sm-none p-0">
                            {kidsEvents.length}
                          </span>
                          {kidsEvents.length > 1 ? (
                            <span className="d-none d-sm-flex p-0 m-0">
                              {kidsEvents.length} {t('calendar.sessions')}
                            </span>
                          ) : (
                            <>
                              <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex ">
                                {event.speakerName}
                              </p>
                              <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex ">
                                {event.interval}
                              </p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}

                  {companyEvents.map((event, index) => (
                    <div
                      key={event.id}
                      onClick={() =>
                        handleCellClick(moment(week).add(i, 'days'))
                      }
                      className={`card rounded-3  ${
                        event.isActive === false ? 'opacity-50' : ''
                      }`}
                      style={{ borderLeft: '10px solid #3A3650' }}
                      role="button"
                    >
                      {index === 0 && (
                        <div className="card-body p-1">
                          <span className="text-muted d-flex d-sm-none p-0">
                            {companyEvents.length}
                          </span>
                          {companyEvents.length > 1 ? (
                            <span className="d-none d-sm-flex p-0 m-0">
                              {companyEvents.length} {t('calendar.sessions')}
                            </span>
                          ) : (
                            <>
                              <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex ">
                                {event.speakerName}
                              </p>
                              <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex ">
                                {event.interval}
                              </p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </td>
              );
            })}
          </tr>
        ))}
      </>
    );
  };

  const renderMonthlyView = () => {
    return (
      <div>
        <div className="table-responsive ">
          <table
            className="table table-sm table-bordered"
            style={{ tableLayout: 'fixed', width: '100%' }}
          >
            <thead>{renderMonthlyTableHeaders()}</thead>
            <tbody>{renderMonthlyTableRows()}</tbody>
          </table>
        </div>
      </div>
    );
  };

  return renderMonthlyView();
};

export default MonthlyScheduler;
