import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { ButtonSize } from '../../../utils/ComponentEnums';

const MobileTable = ({
  data,
  headers,
  fromMentorCalendarButton,
  matchValues,
  onClickFuncSetDate,
  onClickFuncPopup,
  onClickFuncRemove,
  actionButtonProps,
  action2ndButtonProps,
  infoButtonProps,
  tableType,
  searchAble,
}) => {
  const { t } = useTranslation();

  const [arrdata, setArrData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');

  //#endregion
  //#region Table Sorting

  const handleSortingChange = (accessor) => {
    setSortField(accessor);
    const newSortOrder = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortOrder);
    handleSorting(sortField, newSortOrder);
  };

  const handleSorting = (sortField, sortDirection) => {
    data = data.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });
  };

  function customCellRenderer(row, header) {
    let value = '';
    header.label === 'Name' &&
    typeof header.key !== 'object' &&
    typeof header.supKey === 'object'
      ? (value =
          row[header.key][header.supKey[0]] +
          ' ' +
          row[header.key][header.supKey[1]])
      : header.label === 'Name' && typeof header.key === 'object'
      ? (value = row[header.key[0]] + ' ' + row[header.key[1]])
      : (value =
          header.supKey === undefined
            ? row[header.key]
            : row[header.key][header.supKey]);

    return <span>{value}</span>;
  }

  useEffect(() => {
    let mergedData = [];
    const mergeData = (data) => {
      if (Array.isArray(data.Group)) {
        mergedData.push(...data.Group);
      }
      if (Array.isArray(data.Private)) {
        mergedData.push(...data.Private);
      }
      return mergedData;
    };
    if (fromMentorCalendarButton) {
      mergedData = mergeData(data);
    } else {
      mergedData = data;
    }
    tableType === 'basic' ? setArrData(mergedData) : setArrData(filteredData);
  }, [filteredData]);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const lowerCaseSearchTerm = event.target.value.toLowerCase();
    const newData = filteredData.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(lowerCaseSearchTerm);
    });
    setArrData(newData);
  };
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!data) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [data]);

  return showLoader ? (
    <div className="d-flex mx-auto align-items-center py-3 justify-content-center">
      <div className="spinner-border " role="status"></div>
    </div>
  ) : (
    <div className="mobile-table card-header">
      {searchAble && (
        <div className="my-2 top-0 end-0">
          <input
            type="text"
            placeholder={t('table.search')}
            value={searchTerm}
            onChange={handleSearch}
            className="form-control mb-3"
          />
        </div>
      )}
      {arrdata?.map((item, index) => (
        <div key={index} className={`mobile-table-row border my-2 `}>
          <div className="mobile-table-row-header card-header align-items-center d-flex justify-content-between">
            <div className="mobile-table-header-text">{index + 1} </div>
            <div className="mobile-table-header-icon">
              <div key={`i_${item}`}>
                {matchValues ? (
                  <div key={`i_${index}_${item[matchValues.key] + index}`}>
                    {((item[matchValues?.actionButtonProps?.key] ===
                      matchValues?.actionButtonProps?.value &&
                      matchValues?.action2ndButtonProps?.key !== undefined &&
                      item[matchValues?.action2ndButtonProps?.key] ===
                        matchValues?.action2ndButtonProps?.value) ||
                      (typeof item[matchValues?.actionButtonProps?.key] ===
                        'string' &&
                        item[matchValues?.actionButtonProps?.key]?.includes(
                          matchValues?.actionButtonProps?.value
                        )) ||
                      (item[matchValues?.actionButtonProps?.key] >
                        matchValues?.actionButtonProps?.value &&
                        item[matchValues?.infoButtonProps?.key] !==
                          matchValues?.infoButtonProps?.value)) && (
                      <Button
                        id={index}
                        type={actionButtonProps.type}
                        text={actionButtonProps.text}
                        onClick={(e) => onClickFuncSetDate(e.currentTarget.id)}
                        textColor={actionButtonProps.textColor}
                        buttonCol={12}
                        size={ButtonSize.Small}
                      />
                    )}
                    {item[matchValues?.action2ndButtonProps?.key] !==
                      matchValues?.action2ndButtonProps?.value &&
                      item[matchValues?.actionButtonProps?.key] ===
                        matchValues?.actionButtonProps?.value && (
                        <Button
                          id={index}
                          type={action2ndButtonProps?.type}
                          text={action2ndButtonProps?.text}
                          onClick={(e) => onClickFuncRemove(e.currentTarget.id)}
                          textColor={action2ndButtonProps?.textColor}
                          buttonCol={12}
                          size={ButtonSize.Small}
                        />
                      )}
                    {item[matchValues?.infoButtonProps?.key] ===
                      matchValues?.infoButtonProps?.value && (
                      <Button
                        id={index}
                        type={infoButtonProps?.type}
                        text={infoButtonProps?.text}
                        onClick={onClickFuncPopup}
                        textColor={infoButtonProps?.textColor}
                        buttonCol={12}
                        size={ButtonSize.Small}
                      />
                    )}
                  </div>
                ) : undefined}
              </div>
            </div>
          </div>
          <div className="card-body">
            {headers.map((header, columnIndex) => (
              <div
                key={columnIndex}
                className="mobile-table-row-column d-flex justify-content-between border-bottom py-1"
              >
                <div className="mobile-table-column-label">{header.label}</div>
                <div className="mobile-table-column-data">
                  {customCellRenderer(item, header)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileTable;
