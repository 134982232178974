import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Common/Input/Input';
import Card from '../../../components/Common/Card/Card';
import Label from '../../../components/Common/TextLabel/Label';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  InputType,
  LabelFontSize,
  LabelTextType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Actions from '../../../store/redux/actions';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import { GoogleAuth } from '../../../services/integrations/googleAuth';
import { FacebookAuth } from '../../../services/integrations/facebookAuth';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import findMyPath from '../../../hooks/useFindMyPath';
import { storageKeys, storageValues } from '../../../utils/Enum';
import useGTM from '../../../hooks/useGTM';

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userPackage = useSelector((e) => e.userPackage);
  const auth = useSelector((e) => e.auth.auth);
  const checkHasItems = userPackage.isPackageComplete;
  const packageData = userPackage.packageData;
  const userLanguage = useSelector((e) => e.lang.language);
  const formattedLanguage = userLanguage.split('-')[0].toLowerCase();
  const [loginData, setLoginData] = useState({
    userLanguage: formattedLanguage,
  });
  const [isRequired, setIsRequired] = useState({});
  const [error, setError] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const validationMessages = useSelector((e) => e.auth.validationMessage);
  const successCheck = useSelector((e) => e.auth.isSuccess);
  const [handleErrorMessage, setHandleErrorMessage] =
    useState(validationMessages);
  const { sendEventToDataLayer } = useGTM();
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;

  useEffect(() => {
    return () => {
      dispatch(Actions.authActions.cleanState(true));
    };
  }, []);

  useEffect(() => {
    setTimeout(() => setHandleErrorMessage(validationMessages), 500);
  }, [validationMessages]);

  useEffect(() => {
    const getToken = () => localStorage.getItem(storageKeys.token);
    const token = getToken();
    const rule = token !== '' && token !== undefined && token !== null;
    if (successCheck !== '') {
      if (successCheck && rule) {
        sendEventToDataLayer({
          event: 'login',
          loginState: 'Success',
        });
      }
      if (!successCheck) {
        sendEventToDataLayer({
          event: 'login',
          loginState: `"Failed" ${validationMessages}`,
        });
      }
    }
    if (Object.keys(userPackage?.packageData).length > 0 && checkHasItems) {
      dispatch(
        Actions.onboardActions.onboardType(
          parseInt(userPackage.packageData.userArea)
        )
      );
    }
    if (rule) {
      isLogged();
    }
  }, [successCheck, auth, validationMessages]);

  useEffect(() => {}, [successCheck, validationMessages]);
  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        onLogin(e);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [loginData]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, required } = e.target;
    if (value !== '') {
      setLoginData({ ...loginData, [id]: value });
    } else {
      setLoginData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value);
    if (required && !validationResult && value === '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    if (required && value !== '' && validationResult) {
      setIsRequired({ ...isRequired, [id]: false });
    } else if (required && value === '') {
      setIsRequired({ ...isRequired, [id]: true });
    }
  };
  const onFocus = (e) => {
    e.preventDefault();
    const { id, required } = e.target;
    if (required && isRequired[id] === undefined) {
      setIsRequired({ ...isRequired, [id]: required });
    }
    setHandleErrorMessage(null);
  };
  async function isLogged() {
    setLoginData({});
    if (lengthCurrentUserClaim) {
      if (!checkHasItems) {
        navigate(findMyPath('dashboard'));
        setShowLoader(false);
      } else {
        if (checkHasItems) {
          navigate(findMyPath('speakers'), {
            state: { createData: packageData },
          });
          setShowLoader(false);
        }
      }
    } else {
      navigate(findMyPath('dashboard'));
      setShowLoader(false);
    }
  }
  const onLogin = async (e) => {
    e.preventDefault();
    setHandleErrorMessage('');
    const keepLoggedIn = document.getElementById('keepLoggedIn').checked;

    if (keepLoggedIn) {
      localStorage.setItem(
        storageKeys.keepLoggedIn,
        storageValues.keepLoggedIn
      );
    }
    if (
      Object.values(loginData).length > 2 &&
      Object.values(isRequired).every((e) => e === false)
    ) {
      setShowLoader(true);
      await dispatch(Actions.authActions.loginAction(loginData)).then((res) =>
        setTimeout(() => {
          setShowLoader(false);
        }, 500)
      );
    } else if (Object.values(error).find((e) => e !== undefined)) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.this_field_is_required')}
        </span>
      );
    } else {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    }
  };
  useEffect(() => {
    return () => {
      setHandleErrorMessage('');
      setError({});
      setShowLoader(false);
    };
  }, []);
  const content = (
    <div className="section5 ">
      <div className="container text-center pt-5">
        <div className="justify-content-center flex-column flex-sm-row mt-5">
          <div className="mx-auto">
            <Card
              body={
                <div className="d-flex align-items-center flex-column">
                  <div>
                    <img
                      className="img"
                      src="/images/meet2talk-logo.svg"
                      // src="/images/yeşil-siyah-logo.png"
                      alt="Meet2Talk Logo"
                      width="100%"
                    />
                  </div>
                  <div className="text-center py-1">
                    <Label
                      fontType={LabelTextType.Bolder}
                      fontSize={LabelFontSize.fs1}
                      value={t('login.welcome')}
                    />
                  </div>
                  <div className="text-center py-1">
                    <Label
                      fontType={LabelTextType.Normal}
                      fontSize={LabelFontSize.fs6}
                      value={t('login.log_in_with_email_address')}
                    />
                  </div>
                  <div className="col-lg-6 p-0 text-center">
                    <Input
                      className="w-100 mt-5 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                      id="email"
                      type={InputType.Email}
                      placeholder={t('login.email')}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      value={loginData.email || ''}
                      error={error.email}
                      required
                    />
                    <Input
                      className="w-100 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                      id="password"
                      type={InputType.Password}
                      placeholder={t('login.password')}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      value={loginData.password || ''}
                      error={error.password}
                      required
                    />
                    {handleErrorMessage !== '' && (
                      <div className="text-center mt-1">
                        <span className="text-danger">
                          {handleErrorMessage}
                        </span>
                      </div>
                    )}
                    <div className="d-flex justify-content-between mb-4">
                      <div className="form-check pe-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="keepLoggedIn"
                        />
                        <label className="form-check-label text-gray-700">
                          {t('login.keep_me_logged_in')}
                        </label>
                      </div>
                      <div className="align-middle ps-3">
                        <Link
                          className="text-decoration-none text-success"
                          to={'/account/forgotpassword'}
                        >
                          {t('login.forgot_password')}
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-column mt-3">
                      <Button
                        type={
                          showLoader
                            ? ButtonType.Secondary
                            : ButtonType.Successbg
                        }
                        bold
                        textColor={TextColors.Light}
                        buttonCol={8}
                        text={t('login.log_in')}
                        onClick={onLogin}
                        loading={showLoader}
                      />
                      <div className="mt-2">
                        {t('login.do_you_need_account')}
                        <Link
                          className="text-decoration-none text-success ms-2"
                          to="/account/register"
                        >
                          {t('login.register')}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="divider mx-auto pt-3 col-lg-6 col-sm-12 ps-0 text-muted">
                    <h6>
                      <span>{t('login.log_in_with')}</span>
                    </h6>
                  </div>
                  <div className="row mx-auto justify-content-center mt-3 ">
                    <FacebookAuth
                      type={'login'}
                      checkHasItems={checkHasItems}
                      packageData={packageData}
                      userLanguage={userLanguage}
                    />

                    <GoogleAuth
                      type={'login'}
                      checkHasItems={checkHasItems}
                      packageData={packageData}
                      userLanguage={userLanguage}
                    />

                    {/* <div className="col border rounded fw-bold d-flex s3 me-2 align-items-center justify-content-center">
                    <div className="svgSocialLogin3">
                      <FontAwesomeIcon icon={faApple} />
                    </div>
                    <div className="ps-3 t3">Apple</div>
                  </div> */}
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  return useLoader() ? <Loader /> : content;
}

export default Login;
