import React, { useState, useEffect } from 'react';
import Element from '../../components/Common/Elements/TestPriceElement';
import axios from '../../services/axios';
import { toast, ToastContainer } from 'react-toastify';
import config from '../../config.json';

import Card from '../../components/Common/Card/Card';
import Packages from '../UI/Packages';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};
const BASE_URL = config.Connections.API.host;

const TestPrice = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(BASE_URL + 'Preference/GetAllPriceParams', headers)
      .then((response) => {
        setData(response.data.data);
      });
  }, []);

  function getMainGroupName(groupName, subGroupName) {
    if (
      groupName.match(/^Location.*$/) ||
      subGroupName?.match(/^SubLocation.*$/)
    ) {
      return 'LocationRestriction';
    }
    if (groupName.match(/^Age.*$/) || subGroupName?.match(/^SubAge.*$/)) {
      return 'AgeRestriction';
    }
    if (
      groupName.match(/^Category.*$/) ||
      subGroupName?.match(/^SubCategory.*$/)
    ) {
      return 'CategoryRestriction';
    }
    if (
      groupName.match(/^Package.*$/) ||
      subGroupName?.match(/^SubPackage.*$/)
    ) {
      return 'PackageRestriction';
    }
    if (groupName.match(/^Zone.*$/) || subGroupName?.match(/^SubZone.*$/)) {
      return 'ZoneRestriction';
    }
    if (
      groupName.match(/^WeekOption.*$/) ||
      subGroupName?.match(/^SubWeekOption.*$/)
    ) {
      return 'WeekOptionsRestriction';
    }
    if (
      groupName.match(/^MonthOption.*$/) ||
      subGroupName?.match(/^SubMonthOption.*$/)
    ) {
      return 'MonthOptionsRestriction';
    }
    if (groupName.match(/^Price.*$/)) {
      return 'SessionPrice';
    }
    if (groupName.match(/^CurrencyRate.*$/)) {
      return 'CurrencyPreference';
    }
  }
  const handleValueChange = (
    mainRowName,
    groupName,
    fieldId,
    event,
    title,
    subRowGroupName,
    subGroupName
  ) => {
    const mainGroupName = getMainGroupName(groupName, subGroupName);
    setData((prevData) => {
      var newData = { ...prevData }; // make a copy of prevData
      newData.inputGroups = newData.inputGroups.map((mainGroup) => {
        if (mainGroup.name === mainGroupName) {
          // restriction main group name
          return {
            ...mainGroup,
            inputGroups: mainGroup.inputGroups.map((rowGroup) => {
              if (rowGroup.name === mainRowName) {
                // Online, Kadıköy, Exam
                return {
                  ...rowGroup,
                  inputGroups: rowGroup.inputGroups.map((inputGroup) => {
                    if (
                      inputGroup.name.includes('Sub') &&
                      inputGroup.name === groupName
                    ) {
                      //subInputGroup
                      return {
                        ...inputGroup,
                        inputGroups: inputGroup.inputGroups.map((subGroups) => {
                          if (subGroups.name === subRowGroupName) {
                            //Exam A2,Exam A1
                            return {
                              ...subGroups,
                              inputGroups: subGroups.inputGroups.map(
                                (subInputGroup) => {
                                  if (subInputGroup.name === subGroupName) {
                                    //SubCategory...
                                    return {
                                      ...subInputGroup,
                                      inputValues:
                                        subInputGroup.inputValues.map(
                                          (inputValue) => {
                                            if (
                                              inputValue.id === fieldId &&
                                              event.target.type === 'number'
                                            ) {
                                              return {
                                                ...inputValue,
                                                value: event.target.value,
                                              };
                                            } else if (
                                              inputValue.id === fieldId &&
                                              inputValue.title === title &&
                                              event.target.type === 'checkbox'
                                            ) {
                                              return {
                                                ...inputValue,
                                                value:
                                                  event.target.checked === true
                                                    ? 1
                                                    : 0,
                                              };
                                            }
                                            return inputValue;
                                          }
                                        ),
                                    };
                                  }
                                  return subInputGroup;
                                }
                              ),
                            };
                          }
                          return subGroups;
                        }),
                      };
                    } else if (inputGroup.name === groupName) {
                      return {
                        ...inputGroup,
                        inputValues: inputGroup.inputValues.map(
                          (inputValue) => {
                            if (
                              inputValue.id === fieldId &&
                              event.target.type === 'number'
                            ) {
                              return {
                                ...inputValue,
                                value: event.target.value,
                              };
                            } else if (
                              inputValue.id === fieldId &&
                              inputValue.title === title &&
                              event.target.type === 'checkbox'
                            ) {
                              return {
                                ...inputValue,
                                value: event.target.checked === true ? 1 : 0,
                              };
                            }
                            return inputValue;
                          }
                        ),
                      };
                    }
                    return inputGroup;
                  }),
                };
              }
              return rowGroup;
            }),
          };
        }
        return mainGroup;
      });

      return newData; // return the modified data object
    });
  };
  const handleSubmit = () => {
    axios
      .put(BASE_URL + 'Preference/UpdatePricePreferencesJson', data, headers)
      .then((response) => {
        if (response.data.code === 200) {
          setData(response.data.data);
          toast.success('Success');
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const groupRender = (subRow, mainRow) => {
    if (subRow.inputGroups.length > 0) {
      let groupname = subRow.inputGroups.map((subRowGroup) => subRowGroup.name);

      return (
        <td
          key={`${groupname.join('_')}_${subRow.id}`}
          className={`px-2 row ${groupname.join('_')}_${subRow.id}`}
        >
          <table className="border w-100">
            <tbody>{subGroupRender(subRow, mainRow)}</tbody>
          </table>
        </td>
      );
    } else {
      return (
        <td
          key={`${mainRow.name}_${subRow.name}_${subRow.id}`}
          className={`px-2 ${mainRow.name}_${subRow.name}_${subRow.id}`}
        >
          <table className="border  w-100 ">
            <thead>
              <tr>
                <th className="fw-bold">{subRow.name}:</th>
              </tr>
            </thead>
            <tbody>{subGroupRender(subRow, mainRow)}</tbody>
          </table>
        </td>
      );
    }
  };
  const subGroupRender = (subGroup, mainRow) => {
    if (subGroup.inputGroups?.length > 0) {
      if (subGroup.name.includes('Sub')) {
        return (
          <tr key={`${mainRow.name}_${subGroup.name}_${subGroup.id}`}>
            {subGroup.inputGroups.map((subRowGroup) => (
              <td key={subRowGroup.id} className="d-flex overflow-x-scroll">
                <label className="align-self-center me-3 fw-bold">
                  {subRowGroup.name}
                </label>
                {subRowGroup.inputGroups.map((inputGroup) => (
                  <div key={inputGroup.id} className="row border px-3 col">
                    <label className="fw-bold">{inputGroup.name}</label>
                    {inputGroup.inputValues.map((inputValue) => (
                      <div key={inputValue.id} className="row d-flex">
                        <Element
                          key={inputValue.id}
                          field={inputValue}
                          onChange={(event) =>
                            handleValueChange(
                              mainRow.name,
                              subGroup.name,
                              inputValue.id,
                              event,
                              inputValue.title,
                              subRowGroup.name,
                              inputGroup.name
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </td>
            ))}
          </tr>
        );
      }
    } else {
      return subGroup.inputValues?.map((inputValue) => (
        <tr
          key={`${mainRow.name}_${subGroup.name}_${subGroup.id}_${inputValue.title}_${inputValue.id}`}
          className={`${mainRow.name}_${subGroup.name}_${subGroup.id}_${inputValue.id}`}
        >
          <td className="px-2">
            <Element
              key={inputValue.id}
              field={inputValue}
              onChange={(event) =>
                handleValueChange(
                  mainRow.name,
                  subGroup.name,
                  inputValue.id,
                  event,
                  inputValue.title
                )
              }
            />
          </td>
        </tr>
      ));
    }
  };

  const content = () => {
    const renderResult = [];
    var dataInputGroup = data.inputGroups ?? 0;

    for (let i = 0; i < dataInputGroup.length; i++) {
      const mainRows = dataInputGroup[i].inputGroups;
      const mainRowsRender = [];
      for (let y = 0; y < mainRows.length; y++) {
        const inputGroupsItem = mainRows[y].inputGroups;
        //mainHeader.push(subHeadCardRender(mainRows[y]))
        let subgroups = [];

        for (let j = 0; j < inputGroupsItem.length; j++) {
          if (inputGroupsItem[j].name !== 'SubInputGroup') {
            subgroups.push(groupRender(inputGroupsItem[j], mainRows[y]));
          }
        }

        mainRowsRender.push(
          <Card
            cardKey={`nonSubInput${y}_${mainRows[y].name}_${mainRows[y].id}`}
            headerLeft={mainRows[y].name}
            bodyRight={subgroups}
          />
        );
        subgroups = [];
        for (let j = 0; j < inputGroupsItem.length; j++) {
          if (inputGroupsItem[j].name === 'SubInputGroup') {
            subgroups.push(groupRender(inputGroupsItem[j], mainRows[y]));
          }
        }

        if (subgroups.length > 0) {
          mainRowsRender.push(
            <Card
              cardKey={`subInput${y}_${mainRows[y].name}_${mainRows[y].id}`}
              headerLeft={mainRows[y].name}
              bodyRight={subgroups}
            />
          );
        }
      }

      renderResult.push(
        <div className="w-75">
          <Card
            cardKey={`${i}_${dataInputGroup[i].name}_${dataInputGroup[i].id}`}
            header={dataInputGroup[i].name}
            body={mainRowsRender}
          />
        </div>
      );
    }

    return (
      <div className="px-3">
        <table>
          <tbody>{renderResult}</tbody>
        </table>

        <ToastContainer
          limit={1}
          position={'top-center'}
          autoClose={2000}
          closeOnClick
          theme="light"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>

        <div className="mt-4">
          <Packages />
        </div>
      </div>
    );
  };
  return content();
};
export default TestPrice;
