import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(endPoint.faq.CREATE_FAQ, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.faq.UPDATE_FAQ}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.faq.REMOVE_FAQ}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.faq.GETBYID_FAQ}/${id}`);
};
export const getAll = async (lang) => {
  return await getRequest(`${endPoint.faq.GETALL_FAQ}/${lang.userLanguage}`);
};
