import React, { useRef } from 'react';

const PaymentPolicy = ({ onScrollToBottom }) => {
  const modalContentRef = useRef();

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
    const isScrolledToBottom = scrollTop + clientHeight + 100 >= scrollHeight;
    if (isScrolledToBottom && onScrollToBottom) {
      onScrollToBottom();
    }
  };

  const containerStyle = {
    maxHeight: 'calc(100vh - 20vh)',
    height: '500px',
    overflowY: 'auto',
  };

  return (
    <div
      onScroll={handleScroll}
      ref={modalContentRef}
      className="container-fluid"
      style={containerStyle}
    >
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="card shadow mb-4">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <main>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <p align="center">
                              <strong>SATIŞ SÖZLEŞMESİ</strong>
                            </p>
                            <p align="center">
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>1. </strong>
                              <strong>SATICI BİLGİLERİ</strong>
                            </p>
                            <p>Satıcı: Mustafa ÖZMEN </p>
                            <p>
                              Adresi: Rasimpaşa Mahallesi, Başakçı Sokak, No:7
                              Kadıköy / İstanbul
                            </p>
                            <p>Telefon: +90 552 510 20 85</p>
                            <p>E-posta: info@meet2talk.cafe</p>
                            <p>
                              <strong>2. </strong>
                              <strong> MÜŞTERİ BİLGİLERİ</strong>
                            </p>
                            <p>
                              Müşteri, meet2talk.online internet sitesine veya
                              aynı sisteme bağlı bulunan diğer bilgisayar
                              yazılımlarını kullanan ve/veya üye olan kişidir.
                              MÜŞTERİ’nin üye olurken kullanılan adres ve
                              iletişim bilgileri esas alınır.
                            </p>
                            <p>
                              <strong>3. </strong>
                              <strong>SÖZLEŞME KONUSU</strong>
                            </p>
                            <p>
                              İşbu Sözleşme’nin konusu Meet2Talk’un
                              “meet2talk.online” internet sitesi üzerinden
                              kullanıcıya sunduğu üyeliğe dayalı İngilizce
                              konuşma kulübü etkinliği hizmet satışına
                              ilişkindir.
                            </p>
                            <p>
                              <strong>4. </strong>
                              <strong>HİZMETİN NİTELİĞİ</strong>
                            </p>
                            <p>
                              <strong>4.1. </strong>
                              Sözleşme konusu hizmetin temel özellikleri
                              meet2talk.online sitesinde “Kullanım Koşulları”
                              başlığı altında yer almaktadır. MÜŞTERİ bu şartlar
                              kapsamında seçeceği paket karşılığında
                              “meet2talk.online” internet sitesi üzerinden
                              üyeliğe dayalı İngilizce konuşma kulübü
                              etkinliğine katılabilecektir. İngilizce konuşma
                              kulübü etkinliği ders bazında çevrimiçi
                              platformlar ve/veya kafe niteliğindeki şubeler
                              üzerinden verilmektedir.
                            </p>
                            <p>
                              <strong>4.2.</strong>
                              MÜŞTERİ, meet2talk.online sitesindeki
                              açıklamalardan Meet2Talk sisteminin kullanılması
                              için yeterli olan sistem gereksinimlerini
                              anladığını, bu sistem gereksinimlerinin Meet2Talk
                              sisteminin çalışması için asgari gereksinimler
                              olduğunu ve kendi bilgisayarı veya mobil cihazının
                              bu asgari gereklilikleri taşımaması halinde
                              hizmetten yararlanmasının kısmen veya tamamen
                              mümkün olmayabileceğini kabul ve beyan eder.
                            </p>
                            <p>
                              <strong>5. </strong>
                              <strong>ÖDEME BİLGİLERİ</strong>
                            </p>
                            <p>
                              MÜŞTERİ, Sözleşme konusu hizmetin temel
                              nitelikleriyle, satış fiyatı ve ödeme şekline
                              ilişkin tüm ön bilgileri meet2talk.online
                              sitesinden okuyup bilgi sahibi olduğunu ve
                              elektronik ortamda gerekli teyidi verdiğini kabul
                              ve beyan eder. Listelenen fiyat satış fiyatıdır.
                              Fiyatlar güncelleme yapılana dek geçerlidir.
                              Süreli olarak ilan edilmiş fiyat var ise
                              belirtilen süre sonuna kadar geçerlidir.
                            </p>
                            <p>
                              <strong>6. </strong>
                              <strong>GENEL HÜKÜMLER</strong>
                            </p>
                            <p>
                              <strong>6.1.</strong>
                              MÜŞTERİ, SATICI'ya ait internet sitesinde Sözleşme
                              konusu hizmetin temel nitelikleri, satış fiyatı ve
                              ödeme şekli ile hizmetin ifasına ilişkin ön
                              bilgileri okuyup, bilgi sahibi olduğunu kabul
                              eder. MÜŞTERİ, SATICI ile iletişime geçerek
                              hizmetin satın alımını onaylaması sonrasında
                              elektronik ortamda gerekli teyidi verdiğini kabul,
                              beyan ve taahhüt eder.
                            </p>
                            <p>
                              <strong>6.2.</strong>
                              Ödemeler kredi kartı ile veya Havale/EFT yoluyla
                              gerçekleştirebilmektedir. Havale/EFT işlemi
                              sırasında açıklama bölümüne sipariş numarasının
                              mutlaka belirtilmelidir. Havale/EFT yoluyla ödeme
                              yapıldıktan sonra MÜŞTERİ, Meet2Talk çağrı
                              merkezini arayarak üyeliğini aktif hale
                              getirmelidir. Sözleşmeye konu hizmet bedeli,
                              Havale/EFT yoluyla belirtilen hesaba ulaşmadıkça
                              satış gerçekleşmez. Bu durumda MÜŞTERİ’nin SATICI
                              tarafından sunulan hizmetleri alması mümkün
                              değildir. Bu gereğin yerine getirilmemesi hizmetin
                              gecikmesinden SATICI sorumlu olmayacaktır.
                            </p>
                            <p>
                              <strong>6.3. </strong>
                              Sipariş konusu hizmetin ifası için ön
                              bilgilendirme formunun elektronik ortamda MÜŞTERİ
                              tarafından onaylanmış ve hizmet bedelinin
                              MÜŞTERİ'nin tercih ettiği ödeme şekli ile ödenmiş
                              olması şarttır. Herhangi bir nedenle hizmet bedeli
                              ödenmez veya banka kayıtlarında iptal edilir ise,
                              SATICI hizmetin ifası yükümlülüğünden kurtulmuş
                              kabul edilir. MÜŞTERİ, hizmet bedelini ödemekle,
                              meet2talk.online internet sitesinde yayınlanan ön
                              bilgilendirme formunun okumuş ve onaylamış
                              sayılacağını peşinen kabul eder.
                            </p>
                            <p>
                              <strong>6.4.</strong>
                              SATICI ilgili faturayı ödemenin yapıldığı günü
                              takip eden on dört gün içinde MÜŞTERİ'nin kayıt
                              olurken bildirdiği e-posta adresine gönderir.
                            </p>
                            <p>
                              <strong>6.5.</strong>
                              SATICI'nın hizmeti anında ifa edilen
                              hizmetlerdendir ve teslimat gerektirmez. Hizmet
                              satın alındığı anda teslim ve ifa edilmiş sayılır.
                            </p>
                            <p>
                              <strong>7. </strong>
                              <strong>CAYMA HAKKI</strong>
                            </p>
                            <p>
                              Sözleşme konusu hizmet, Mesafeli Sözleşmelere Dair
                              Yönetmeliğin madde 7/4 (f) hükümleri
                              doğrultusunda, elektronik ortamda anında ifa
                              edilen ve tüketiciye anında teslim edilen gayri
                              maddi nitelikte olduğundan, MÜŞTERİ’nin cayma
                              hakkı ve iptal/iade bulunmamaktadır.
                            </p>
                            <p>
                              <strong>8. </strong>
                              <strong>AYIPLI HİZMET</strong>
                            </p>
                            <p>
                              Sözleşme konusu hizmetin ayıplı ifa edildiği
                              durumlarda, MÜŞTERİ, 6502 Sayılı Tüketicinin
                              Korunması Hakkında Kanun’un 15. maddesi uyarınca,
                              hizmetin yeniden görülmesi, ayıp oranında hizmet
                              bedelinden indirim veya sözleşmenin feshi
                              haklarından birini SATICI’ya karşı kullanmakta
                              serbesttir.
                            </p>
                            <p>
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>9. </strong>
                              <strong>
                                İTİRAZ VE ŞİKAYETLERİN ÇÖZÜMLENMESİ
                              </strong>
                            </p>
                            <p>
                              MÜŞTERİ, satın aldığı hizmetle ilgili talep ve
                              şikâyetlerini SATICI'nın yukarıda belirtilmiş olan
                              e-posta adresine iletebilir. Haklı talep ve
                              şikâyetlerin derhal çözümlenmesi için SATICI
                              elinden gelenin en iyisini yapacağını bildirmekle
                              beraber sorunun bu şekilde uzlaşma yolu ile
                              çözümlenememesi halinde, Sözleşme konusu
                              hizmetlere ilişkin şikâyet ve başvurular,
                              Bakanlıkça her yıl aralık ayında belirlenen
                              parasal sınırlar dâhilinde, hizmetin satın
                              alındığı veya MÜŞTERİ’nin yerleşim yerinin
                              bulunduğu yerdeki tüketici sorunları hakem
                              heyetine veya tüketici mahkemesine
                              yapılabilecektir.
                            </p>
                            <p>
                              <strong>10. </strong>
                              <strong>
                                HİZMETE SON VERME VE FESİH ŞARTLARI
                              </strong>
                            </p>
                            <p>
                              <strong>10.1. </strong>
                              İşbu Sözleşme’deki tüm açıklamalar ve bilgiler
                              MÜŞTERİ'nin işbu formu meet2talk.online internet
                              sitesi üzerinden onayladığı an itibari ile
                              geçerlidir. İlgili hükümler saklı kalmak üzere
                              tarafların Sözleşme'den doğan ödeme ve teslim
                              yükümlülüklerini yerine getirdikleri zamana kadar
                              işbu Sözleşme yürürlükte kalacaktır.
                            </p>
                            <p>
                              <strong>10.2. </strong>
                              Sözleşmenin MÜŞTERİ tarafından haksız nedenle
                              feshi ve/veya Sözleşme’ye konu hizmetin
                              kullanılarak tüketilmesi veya Sözleşme’ye konu
                              paket süresinin sonra ermesi halinde herhangi bir
                              tutarın iadesi söz konusu olmayacaktır.
                              Sözleşmenin feshi tarihi itibariyle, MÜŞTERİ’ye
                              sunulacak hizmetler sonlandırılacaktır.
                            </p>
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPolicy;
