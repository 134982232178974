import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const screenReducer = (state = initialState.screensize, action) => {
  switch (action.type) {
    case ActionTypes.screen.SCREEN_SIZE_CHECKED:
      return {
        ...state,
        size: action.payload,
      };
    case ActionTypes.screen.SIZE_SET:
      return {
        ...state,
        setted: action.payload,
      };
    default:
      return state;
  }
};
export default screenReducer;
