import { InputType, RegexType } from '../utils/ComponentEnums';
import { REGEX } from '../utils/RegexEnums';
let result = true;
function regexValidator(type, text, regexType = "") {

  if (regexType !== "") {
    switch (regexType) {
      case RegexType.Name:
        result = REGEX.NAME.test(text);
        break;
      default:
        break;
    }
  }
  else if (regexType === "") {
    switch (type) {
      case InputType.Text:
        result = REGEX.TEXT.test(text);
        break;
      case InputType.Number:
        result = REGEX.NUMBER.test(text);
        break;
      case InputType.Email:
        result = REGEX.EMAIL.test(text);
        break;
      case InputType.Password:
        result = REGEX.PASSWORD.test(text);
        break;
      // case InputType.Date:
      //   result = REGEX.DATE.test(text);
      //   break;
      case InputType.Tel:
        result = REGEX.TEL.test(text);
        break;
      case InputType.Url:
        result = REGEX.URL.test(text);
        break;
      default:
        break;
    }
  }

  return result;
}
export default regexValidator;
