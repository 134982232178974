import addDay from './useAddDay';

function filterTimeSet(value = null, view = false, effort = false, t = false) {
  if (view) {
    const date = new Date();
    const newDate = date.setDate(date.getDate());
    const eDays = new Date(newDate);
    const nDay = eDays.getDate().toString().padStart(2, '0');
    const nMonth = (eDays.getMonth() + 1).toString().padStart(2, '0');
    const extraDateTime = addDay(
      `00:00`,
      `${nDay}-${nMonth}-${eDays.getFullYear()}`,
      false,
      false,
      true
    );
    const addDays = date.setDate(date.getDate() - (t ? 0 : 3));
    const x = new Date(addDays);
    const xDay = date.getDate().toString().padStart(2, '0');
    const xMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const normalDateTime = addDay(
      `00:00`,
      `${xDay}-${xMonth}-${x.getFullYear()}`,
      false,
      false,
      true
    );
    const viewXDay = date.setDate(date.getDate());
    const wDays = new Date(viewXDay);
    const strTime = normalDateTime;
    const endTime = extraDateTime;

    const strView = `${wDays.getFullYear()}-${(wDays.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${wDays.getDate().toString().padStart(2, '0')}`;
    const endView = `${eDays.getFullYear()}-${(eDays.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${eDays.getDate().toString().padStart(2, '0')}`;

    if (effort) {
      const currentDate = new Date();

      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        10
      );

      const endOfMonth = new Date();

      if (endOfMonth.getDate() >= 10) {
        // End date is in the current month
        endOfMonth.setDate(10);
      } else {
        // End date is in the previous month
        endOfMonth.setMonth(endOfMonth.getMonth() - 1);
        endOfMonth.setDate(10);
      }

      const startOfMonthForEffort = new Date(endOfMonth);
      startOfMonthForEffort.setDate(10);

      if (currentDate.getDate() > 10 || (currentDate.getDate() === 10 && endOfMonth.getDate() >= 10)) {
        // End date day is greater than the start date day, or end date day is the same and current date day is the same or greater
        startOfMonthForEffort.setMonth(currentDate.getMonth());
      } else {
        // End date day is less than the start date day, adjust to the previous month
        startOfMonthForEffort.setMonth(currentDate.getMonth() - 1);
      }

      const endOfDay = new Date(endOfMonth);
      endOfDay.setDate(endOfDay.getDate() + 1);

      // Adjusting for time zone offset
      const getDateString = (date) => {
        const offset = date.getTimezoneOffset();
        const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
        return adjustedDate.toISOString().split('T')[0];
      };
      const formatDate = (date) =>
        `${date.getDate().toString().padStart(2, '0')}-${(
          date.getMonth() + 1
        ).toString().padStart(2, '0')}-${date.getFullYear()}`;

      const strTime = addDay(
        `00:00`,
        formatDate(startOfMonthForEffort),
        false,
        false,
        true
      );

      const endTime = addDay(
        `00:00`,
        formatDate(currentDate),
        false,
        false,
        true
      );

      const strView = getDateString(startOfMonthForEffort).replace(/-/g, '-');

      const endView = getDateString(currentDate).replace(/-/g, '-');

      return { strTime, endTime, strView, endView };
    }


    return { strTime, endTime, strView, endView };
  }
  const date = value === null ? new Date() : new Date(value);
  const nDay = date.getDate().toString().padStart(2, '0');
  const nMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  const strTime = addDay(
    `00:00`,
    `${nDay}-${nMonth}-${date.getFullYear()}`,
    false,
    false,
    true
  );
  const addDays = date.setDate(date.getDate() + 7);
  const x = new Date(addDays);
  const xDay = date.getDate().toString().padStart(2, '0');
  const xMonth = (date.getMonth() + 1).toString().padStart(2, '0');

  const endTime = addDay(
    `00:00`,
    `${xDay}-${xMonth}-${x.getFullYear()}`,
    false,
    false,
    true
  );
  
  return { strTime, endTime };
}

export default filterTimeSet;
