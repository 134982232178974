import {
  create,
  update,
  remove,
  getById,
  getAll,
} from '../../../services/apiServices/branchFreezeServices';
import ActionTypes from './actionTypes';

const createAction = (createData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await create(createData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(createReducer());
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.branchFreeze.CREATE_ACTION_BRANCHFREEZE };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await update(updateData, id).then(async (res) => {
      if (res?.data?.code === 200) {
        dispatch(createReducer());
        await dispatch(updateReducer());
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
      if (id !== undefined) {
        await dispatch(updateReducer());
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.branchFreeze.UPDATE_ACTION_BRANCHFREEZE };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.branchFreeze.GETBYID_ACTION_BRANCHFREEZE,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.branchFreeze.GETALL_ACTION_BRANCHFREEZE,
    payload: { data },
  };
};
const holdValue = (data) => {
  return {
    type: ActionTypes.branchFreeze.HOLD_VALUE_BRANCHFREEZE,
    payload: data,
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.branchFreeze.HANDLE_ERROR_BRANCHFREEZE,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.branchFreeze.HANDLE_VALIDATION_BRANCHFREEZE,
    payload: data,
  };
};

const cleanState = () => {
  return async (dispatch) => {
    await dispatch(updateReducer());
    await dispatch(handleError(''));
    await dispatch(handleValidationReducer(''));

  };
};
const branchFreezeActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  holdValue,
  cleanState,
  handleError,
  handleValidationReducer,
};
export default branchFreezeActions;
