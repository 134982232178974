import { useEffect } from 'react';
import config from '../config.json';

const useGTM = () => {
  const gtmConfig = config.eCommerce.GTM;

  useEffect(() => {
    let gtmScript;
    // Function to load GTM script dynamically
    const loadGTMScript = () => {
      gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','${gtmConfig.datalayerName}','${gtmConfig.containerId}');
      `;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);
    };

    const loadGTMNoScript = () => {
      const gtmNoScript = document.createElement('noscript');
      gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmConfig.containerId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(gtmNoScript);
    };

    // Initialize GTM on component mount
    loadGTMNoScript();
    loadGTMScript();

    // Clean up on component unmount
    return () => {
      // Remove the dynamically created script element
      if (gtmScript && gtmScript.parentNode) {
        gtmScript.parentNode.removeChild(gtmScript);
      }
    };
  }, []);
  // const eventData = { platform: 'web', ...data };
  const sendEventToDataLayer = (data) => {
    if (window[gtmConfig.datalayerName || 'dataLayer']) {
      window[gtmConfig.datalayerName || 'dataLayer'].push({
        ...data,
        environment: gtmConfig.environment,
      });
    } else {
      console.warn('Data layer does not exist');
    }
  };

  const generateItemId = (location,
    area,
    matchingId,
    timesPerWeek,
    monthOption,
    type) => {
    const itemIdString = `${location}${area}${matchingId}${timesPerWeek}${monthOption}${type}`;
    return parseInt(itemIdString);
  }
  return {
    sendEventToDataLayer,
    generateItemId
  };
};

export default useGTM;
