import { postRequest, putRequest, getRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.user.UPDATE_USER}/${id}`, updateData);
};
export const selfUpdate = async (updateData, requestType) => {
  let URL;
  switch (requestType) {
    case 'UserArea':
      URL = endPoint.user.USERAREA;
      break;
    case 'Currency':
      URL = endPoint.user.USER_LANGUAGE;
      break;
    default:
      URL = endPoint.user.USER_LANGUAGE;
      break;
  }
  return await putRequest(`${URL}`, updateData);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.user.GETBYID_USER}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.user.GETALL_USER);
};
export const phoneConfirmation = async (data) => {
  return await postRequest(endPoint.user.PHONE_CONFIRMATION, data);
};
export const confirmPhoneNumber = async (data) => {
  return await postRequest(`${endPoint.user.CONFIRM_PHONE_NUMBER}`, data);
};
export const forgotPassword = async (data) => {
  return await postRequest(endPoint.user.FORGOT_PASSWORD, data);
};
export const resetPassword = async (data) => {
  return await postRequest(endPoint.user.RESET_PASSWORD, data);
};
export const createEmailToken = async (data) => {
  return await postRequest(`${endPoint.user.CREATE_EMAIL_TOKEN}`, data);
};
export const verifyEmail = async (token) => {
  return await postRequest(`${endPoint.user.VERIFY_EMAIL}`, JSON.stringify(token));
};
export const changePassword = async (data) => {
  return await postRequest(`${endPoint.user.CHANGE_PASSWORD}`, data);
};
export const getCurrentUserInfos = async (id) => {
  return await getRequest(`${endPoint.user.CURRENT_USER_INFOS}/${id}`);
};
export const updatePreference = async (data) => {
  return await postRequest(`${endPoint.user.USER_PREFERENCE}`, data);
};

export const getCurrencyByIPAddress = async () => {
  return await getRequest(`${endPoint.user.USER_GET_CURRENCY_BY_IP_ADDRESS}`);
};
