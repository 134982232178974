import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../../store/redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/Common/Button/Button";
import {
	ButtonType,
	ButtonSize
} from "../../../../utils/ComponentEnums";
import Card from "../../../../components/Common/Card/Card";
import Table from "../../../../components/Common/Table/Table";

const BackOfficeAttendeeFreezeList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const attendeeFreezeList = useSelector((e) => e.attendeeFreeze.getAll);

	const [startDate, setStartDate] = useState(new Date());
	const [valid, setValid] = useState(true);

	const handleChangeRaw = (value) => {
		if (value !== undefined) {
			if (moment(value, "MM/DD/YYYY", true).isValid()) {
				setValid(true);
			} else {
				setValid(false);
				setStartDate(null);
			}
		}
	};
	const getList = async () => {
		await dispatch(Actions.attendeeFreezeActions.getAllAction());
	};

	useEffect(() => {
		getList();
	}, []);

	const headers = [
		{ key: 'name', label: 'Name' },
		{ key: 'email', label: 'Email' },
		{ key: 'actions', label: 'Actions' }, // new column for actions
		{ key: 'icon', label: 'Icon' }, // new column for actions
		{ key: 'delete', label: 'delete' }, // new column for actions
	];

	const handleDeleteRow = rowIndex => {
	};

	function customCellRenderer(row, header) {
		const value = row[header.key];
		if (header.key === 'actions') {
			return (
				<div className="mx-auto d-flex justify-content-center">
					<Button
						type={ButtonType.Info}
						size={ButtonSize.Small}
						text="Click Me"
						onClick={() => alert(`Clicked button for row ${row.id}`)}
					></Button>
				</div>
			);
		}
		if (header.key === 'delete') {
			return (

				<div className="mx-auto d-flex justify-content-center">
					<Button
						type={ButtonType.Danger}
						size={ButtonSize.Small}
						text="Delete"
						onClick={() => handleDeleteRow(row)}
					></Button>
				</div>
			);
		}
		if (header.key === 'icon') {
			return (
				<div className="text-center">
					<FontAwesomeIcon icon={faVideoCamera} /> {value}
				</div>
			);
		} else {
			return <span>{value}</span>;
		}
	}

	return (
		<div className="container-fluid">
			<div className="d-sm-flex align-items-center justify-content-between mb-4">
				<h1 className="h3 mb-0 text-gray-800">
					Attendee Freeze List
				</h1>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<Card
						header={
							<div>
								<div className="row text-secondary d-flex">
									<div className="col-lg-6 col-12 justify-content-start">
										<div className="mb-2">
											<button
												type="button"
												className="btn btn-success me-2 mt-2"
											>
												Copy
											</button>
											<button
												type="button"
												className="btn btn-success me-2 mt-2"
											>
												CSV
											</button>
											<button
												type="button"
												className="btn btn-success me-2 mt-2"
											>
												Excel
											</button>
											<button
												type="button"
												className="btn btn-success me-2 mt-2"
											>
												PDF
											</button>
											<button
												type="button"
												className="btn btn-success me-2 mt-2"
											>
												Print
											</button>
											<button
												type="button"
												className="btn btn-success mt-2"
											>
												Column Visibility
											</button>
										</div>
									</div>

								</div>
							</div>
						}
						body={
							<div>
								<Table data={attendeeFreezeList} headers={headers} striped bordered renderCell={customCellRenderer} />
							</div>
						}
					></Card>
				</div>
			</div>
		</div>
	);
};
export default BackOfficeAttendeeFreezeList;
