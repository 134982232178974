import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Common/Button/Button';

import { ButtonType, ButtonSize } from '../../../../../utils/ComponentEnums';
import LetUsCallYou from '../LetUsCallYou';
import { useNavigate } from 'react-router-dom';

const Antalya = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFreeTrialClick = () => {
    navigate('/cafe/order/triallesson', {
      state: {
        fromNavigateWhere: 'freeTrial',
        trialStatus: 0,
      },
    });
  };
  return (
    <div>
      <section className="py-5 text-center container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 mt-lg-5 ">
            <h1 className="display-4 pb-4  ">Meet2talk Antalya</h1>
            <h4 className=" py-2">
              Haşimişcan Mah. Arık Cad. <br />
              No:44 Muratpaşa/Antalya
            </h4>
            <a className="py-2" href="tel:+905358336919">
              <h4>0535 833 6919</h4>
            </a>
            <div className=" d-flex justify-content-center py-2">
              <Button
                text={t('landingMainBanner.get_trial')}
                textColor="text-white"
                type={ButtonType.LandingGreenBg}
                border
                ButtonSize={ButtonSize.Small}
                buttonCol={6}
                onClick={() => handleFreeTrialClick()}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-5">
            <div className="ratio ratio-4x3 ">
              <iframe
                className="embed-responsive-item rounded-4 "
                src="https://www.youtube.com/embed/63tiivy_Tqg"
                title="Meet2Talk"
                allowFullScreen
                style={{ boxShadow: ' 9px 9px #00c905' }}
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <div className="py-5">
        <LetUsCallYou
          iframe={
            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.352340197883!2d30.707799915292263!3d36.88191847993174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c39007baf973c5%3A0x2531936a0d90b47c!2zSGHFn2ltacWfY2FuLCBBcsSxayBDZC4gTm86NDQsIDA3MTAwIE11cmF0cGHFn2EvQW50YWx5YQ!5e0!3m2!1str!2str!4v1624446642105!5m2!1str!2str'
          }
          area={1}
          location={'Antalya'}
        />
      </div>
    </div>
  );
};

export default Antalya;
