import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';

export default function ButtonIconText({
  rounded,
  icon,
  title,
  backGroundAll = '',
  textColor = '',
  to,
  onClick,
}) {
  const navigate = useNavigate();
  const isFunc = () => {
    if (to === undefined) {
      onClick();
    } else {
      navigate(`/${to}`);
      onClick();
    }
  };
  return (
    <div
      role={'button'}
      style={{ background: '#EFE9F1', height: '40px' }}
      onClick={isFunc}
      className={`d-flex align-items-center justify-content-center ${textColor} ${backGroundAll} 
			${rounded ? `rounded-${rounded}` : ''}`}
    >
      <FontAwesomeIcon
        style={{ width: '12px', color: 'blueviolet' }}
        icon={icon}
      />
      <label
        role={'button'}
        className={`${backGroundAll} ms-2`}
        style={{ fontSize: '12px', color: 'blueviolet' }}
      >
        {title}
      </label>
    </div>
  );
}
