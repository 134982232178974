import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(
    endPoint.sessionPackage.CREATE_SESSIONPACKAGES,
    createData
  );
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.sessionPackage.UPDATE_SESSIONPACKAGES}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(
    `${endPoint.sessionPackage.REMOVE_SESSIONPACKAGES}/${id}`
  );
};
export const getById = async (id) => {
  return await getRequest(
    `${endPoint.sessionPackage.GETBYID_SESSIONPACKAGES}/${id}`
  );
};
export const getAll = async () => {
  return await getRequest(endPoint.sessionPackage.GETALL_SESSIONPACKAGES);
};
