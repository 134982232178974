import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Label from '../../../components/Common/TextLabel/Label';
import Card from '../../../components/Common/Card/Card';
import { Loader } from '../../../components/Common/Loader/Loader';
import { LabelFontSize, LabelTextType } from '../../../utils/ComponentEnums';
import { serviceOptions } from '../../../utils/Enum';
import useLoader from '../../../hooks/useLoader';
import findMyPath from '../../../hooks/useFindMyPath';
import { checkLogged } from '../../../hooks/useCheckLogged';

const Subscriptions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const auth = useSelector((e) => e.auth.auth);
  const userInfo = useSelector((e) => e.user.getById);
  const pathname = location.pathname;
  const isTrial = pathname.includes('triallesson');
  const sessionType = queryParams.get('sessionType');
  const serviceType = queryParams.get('serviceType');
  const locationState = location.state;
  const fromNavigateWhere = locationState?.fromNavigateWhere;
  const trialStatusRule =
    pathname.includes('triallesson') && pathname.includes('kids')
      ? 1
      : pathname.includes('triallesson') &&
        (!userInfo?.freeTrialUsed || userInfo.freeTrialUsed === undefined)
      ? 0
      : pathname.includes('triallesson') &&
        (userInfo?.freeTrialUsed || userInfo.freeTrialUsed === undefined)
      ? 1
      : 2;

  const [createData, setCreateData] = useState({});
  const isLoggedIn = auth !== '';
  const isOnline = createData?.userArea === 0;
  const isCafe = createData?.userArea === 1;
  const isKids = createData?.userArea === 2;

  useEffect(() => {
    const pathname = location.pathname;
    const ruleGift = fromNavigateWhere === 'giftSession';
    const userAreaRule = pathname.includes('cafe')
      ? parseInt(serviceType) || 1
      : pathname.includes('kids')
      ? parseInt(serviceType) || 2
      : parseInt(serviceType) || 0;
    const productTypeRule =
      userAreaRule === 1
        ? 0
        : sessionType !== null
        ? parseInt(sessionType)
        : undefined;
    dispatch(Actions.onboardActions.onboardType(userAreaRule));

    if (isLoggedIn) {
      dispatch(
        Actions.userActions.selfUpdateAction(
          { userArea: userAreaRule },
          'UserArea',
          true
        )
      ).then(() => {
        dispatch(Actions.userActions.getByIdAction(checkLogged()));
      });
    }

    let createDataBuilder = {
      createData: {
        ...locationState,
        userArea: userAreaRule,
        trialStatus: trialStatusRule,
        productType: productTypeRule,
        isGift: ruleGift,
        speakerId: locationState?.speakerId,
        speakerName: locationState?.speakerName,
        speakerImage: locationState?.speakerImage,
      },
    };
    setCreateData({ ...createData, ...createDataBuilder.createData });
    searchParams.set('serviceType', userAreaRule);
    if (userAreaRule === 1) {
      searchParams.set('sessionType', productTypeRule);
    }
    navigate(pathname + '?' + searchParams.toString(), {
      replace: true,
      state: {
        ...locationState,
        ...createData,
        ...createDataBuilder.createData,
      },
    });
  }, [location.pathname]);

  const handleServiceTypeClick = (option) => {
    const { value, url } = option;
    let targetRoute;
    dispatch(Actions.onboardActions.onboardType(value));
    if (isTrial) {
      targetRoute = `${url}/order/triallesson`;
    } else {
      targetRoute = `${url}/order/package`;
    }
    searchParams.set('serviceType', value);
    searchParams.delete('sessionType');
    navigate(targetRoute + '?' + searchParams.toString(), {
      replace: true,
      state: {
        ...locationState,
        ...createData,
      },
    });
  };

  const handleSessionTypeClick = (val) => {
    setCreateData((prevCreateData) => {
      const updatedCreateData = {
        ...prevCreateData,
        productType: val,
      };

      return updatedCreateData;
    });
  };

  const handleClick = (val) => {
    const privateRoute = findMyPath(
      createData.trialStatus === 2
        ? 'order/package/one-to-one'
        : 'order/triallesson/one-to-one'
    );
    const groupRoute = findMyPath(
      createData.trialStatus === 2
        ? 'order/package/group'
        : 'order/triallesson/group'
    );
    const updatedCreateData =
      val !== undefined
        ? {
            ...createData,
            productType: val,
          }
        : createData;
    const pathRule =
      updatedCreateData?.productType === 0 ? groupRoute : privateRoute;
    if (!isCafe && !isKids && !isOnline) {
      navigate('/company-price-request');
      return;
    }
    navigate(pathRule, {
      state: { createData: updatedCreateData },
    });
  };

  useEffect(() => {
    if (createData.productType !== undefined) {
      searchParams.set('sessionType', createData.productType);
      navigate(location.pathname + '?' + searchParams.toString(), {
        replace: true,
        state: {
          ...locationState,
          ...createData,
        },
      });
    }
  }, [createData.productType]);

  useEffect(() => {
    if (isCafe && auth !== '') {
      const navigatingPath = findMyPath('order/package/group');
      const ruleGift = fromNavigateWhere === 'giftSession';
      let state = {
        state: {
          createData: {
            ...locationState,
            userArea: createData?.userArea,
            productType: 0,
            trialStatus: trialStatusRule,
            isGift: ruleGift,
          },
        },
      };
      navigate(navigatingPath, {
        state: state.state,
      });
    }
  }, [createData?.userArea]);

  useEffect(() => {
    const filterObject = {
      TypeOfService:
        createData.productType === undefined
          ? null
          : createData?.productType === 1
          ? [0]
          : [1],
      UserArea: createData?.userArea,
      TrialStatus: createData?.trialStatus,
    };
    dispatch(Actions.filterActions.holdFilter(filterObject));
  }, [createData]);

  const content = (
    <div className="container vh-100 my-lg-5">
      <div className="row">
        <div className="col">
          <Card
            cardRounded
            body={
              <div className="row">
                <div className="col my-3">
                  <div>
                    <Label
                      fontType={LabelTextType.Bolder}
                      fontSize={LabelFontSize.fs1}
                      value={t('subscription.if_you_are_ready')}
                    />
                  </div>
                  <div className="mt-4">
                    <Label
                      fontSize={LabelFontSize.fs6}
                      value={t(
                        'subscription.now_that_you_understand_how_meet2talk_works'
                      )}
                    />
                  </div>
                  {auth === '' ? (
                    <div className="my-3 my-md-5">
                      <div>
                        <h4 className="mt-2 ms-2">
                          {t('attendeeDashboard.type_of_service')}
                        </h4>
                        <div className="d-flex my-3 justify-content-start">
                          {serviceOptions.map((option) => (
                            <button
                              key={option.key}
                              title={option.key}
                              value={option.key}
                              className={`btn btn-default border border-1 mx-2 rounded-4 ${
                                createData?.userArea === option.value
                                  ? 'bg-custom text-white'
                                  : ''
                              }`}
                              onClick={() => handleServiceTypeClick(option)}
                            >
                              {t(`${option.key}`, { lng: 'en' })}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div>
                        <h4 className="mt-2 ms-2">
                          {t('attendeeDashboard.session_type')}
                        </h4>
                        <div className="d-flex my-3 justify-content-start">
                          {!isCafe && (
                            <button
                              id={'productType1'}
                              className={`btn btn-default border border-1 mx-2 rounded-4 ${
                                createData?.productType === 1
                                  ? 'bg-custom text-white'
                                  : ''
                              }`}
                              onClick={() => handleSessionTypeClick(1)}
                            >
                              {t('button.one_to_one')}
                            </button>
                          )}
                          <button
                            id={'productType0'}
                            value={0}
                            className={`btn btn-default border border-1 mx-2 rounded-4 ${
                              createData?.productType === 0
                                ? 'bg-custom text-white'
                                : ''
                            }`}
                            onClick={() => handleSessionTypeClick(0)}
                          >
                            {t('button.group')}
                          </button>
                        </div>
                      </div>
                      {createData?.productType !== undefined && (
                        <div className="d-flex justify-content-md-end justify-content-center text-decoration-none">
                          <button
                            className="btn bg-custom text-white"
                            onClick={() => handleClick()}
                          >
                            {t('button.continue')}
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap justify-content-between">
                      {!isCafe && (
                        <div className="col-12 col-md-6 px-1 mt-2 mt-md-3">
                          <button
                            title={'One-to-one'}
                            className="btn btn-default btn-sm border border-1 my-2 px-sm-2 py-sm-2 rounded-3 text-nowrap bg-success text-white fw-bold"
                            style={{ width: '100%' }}
                            onClick={() => handleClick(1)}
                          >
                            {t('button.one_to_one')}
                          </button>
                        </div>
                      )}

                      <div
                        className={`col-12 col-md-6 px-1 mt-2 mt-md-3 ${
                          isCafe ? 'mx-auto' : ''
                        }`}
                      >
                        <button
                          title={'Group'}
                          className="btn btn-default btn-sm border border-1 my-2 px-sm-3 py-sm-2 rounded-3 text-nowrap bg-warning text-white fw-bold"
                          style={{ width: '100%' }}
                          onClick={() => handleClick(0)}
                        >
                          {t('button.group')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
  return useLoader() ? <Loader /> : content;
};
export default Subscriptions;
