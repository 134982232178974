import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = ({ userArea }) => {
  const { t } = useTranslation();
  return (
    <footer className="w-100 h-100 bg-success footer py-md-4">
      <div className="container d-flex px-5  px-md-0">
        <div className="row text-start justify-content-between">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <ul className="list-unstyled">
              <li className="text-white fw-bold h4">
                <a className="navbar-brand" href="/">
                  <img
                    className="img"
                    src="/images/meet2talk-logo-footer.svg"
                    alt="Meet2Talk Logo"
                    style={{ maxWidth: '200px' }}
                  />
                </a>
              </li>
              <li className="text-white">{t('footer.description')}</li>
              <li className="text-white pt-2">
                <a
                  className="text-decoration-none"
                  href="https://www.instagram.com/meet2talk.cafe"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    className="pe-2"
                    icon={faInstagram}
                  ></FontAwesomeIcon>
                </a>
                &nbsp;
                <a
                  className="text-decoration-none"
                  href="https://www.tiktok.com/@meet2talk"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    className="pe-2"
                    icon={faTiktok}
                  ></FontAwesomeIcon>
                </a>
                &nbsp;
                <a
                  className="text-decoration-none"
                  href="https://www.youtube.com/@Meet2Talk"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    className="pe-2"
                    icon={faYoutube}
                  ></FontAwesomeIcon>
                </a>
                &nbsp;
                <a
                  className="text-decoration-none"
                  href="https://www.facebook.com/meet2talkcafe"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    className="pe-2"
                    icon={faFacebook}
                  ></FontAwesomeIcon>
                </a>
                &nbsp;
                <a
                  className="text-decoration-none"
                  href="https://twitter.com/Meet2talk"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    className="pe-2"
                    icon={faXTwitter}
                  ></FontAwesomeIcon>
                </a>
                &nbsp;
                <a
                  className="text-decoration-none"
                  href="https://tr.linkedin.com/company/meet2talkonline"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <FontAwesomeIcon
                    className="pe-2"
                    icon={faLinkedin}
                  ></FontAwesomeIcon>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-2 ">
            <ul className="list-unstyled py-1">
              <li className="text-white h6 fw-bold">
                {t('footer.learn_english')}
              </li>
              <li className="text-white pt-2">
                <a className="nav-link" href="/">
                  {t('footer.online')}
                </a>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/cafe">
                  {t('footer.cafe')}
                </a>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/kids">
                  {t('footer.kids')}
                </a>
              </li>
              <li className="text-white fw-bold">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://blog.meet2talk.online"
                >
                  BLOG
                </a>
              </li>

              <li className="text-white fw-bold">
                <a className="nav-link" href="/campaigns">
                  {t('footer.campaigns')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-2">
            <ul className="list-unstyled py-1">
              <li className="text-white h6 fw-bold">{t('navbar.corporate')}</li>
              <li className="text-white pt-2">
                <a className="nav-link" href="/company">
                  {t('footer.company')}
                </a>
              </li>
              <li className="text-white">
                <Link className="nav-link" to="/career/create">
                  {t('navbarCorporate.become_a_mentor')}
                </Link>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/become-a-partner">
                  {t('footer.become_a_partner')}
                </a>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/kullanim-kosullari">
                  {t('footer.terms_of_use')}
                </a>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/gizlilik-politikasi">
                  {t('footer.privacy_policy')}
                </a>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/satis-sozlesmesi">
                  {t('footer.payment_policy')}
                </a>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/uyelik-sozlesmesi">
                  {t('footer.membership_policy')}
                </a>
              </li>
              <li className="text-white">
                <a className="nav-link" href="/cerez-politikasi">
                  {t('footer.cookie_policy')}
                </a>
              </li>
              {/* <li className="text-white fw-bold">HABERDAR OL</li> */}
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <ul className="list-unstyled py-1">
              <li className="text-white h6 fw-bold"> {t('footer.contact')}</li>
              <li>
                <a
                  className="text-decoration-none text-white"
                  href="tel:+908502123636"
                >
                  0 (850) 212 36 36
                </a>
              </li>
              <li>
                <a
                  className="text-decoration-none text-white"
                  href="mailto:info@meet2talk.cafe"
                >
                  info@meet2talk.cafe
                </a>
              </li>
              <li className="text-white pt-2"> {t('footer.address')}</li>
              <li className="text-white pt-2 ">
                Rasimpaşa, Başakçı Sk. No:7, 34716 Kadıköy/İstanbul
              </li>
            </ul>
          </div>
          <hr className="text-white" />
        </div>
      </div>

      <div className="container">
        <div className="col-sm-12 col-md-12 d-flex justify-content-center">
          <span className="text-white text-decoration-none">
            © {new Date().getFullYear()} Meet2Talk Co. All rights reserved
          </span>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
