import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Common/Button/Button";
import {
	ButtonType,
	ButtonSize
} from "../../../utils/ComponentEnums";

import Card from "../../../components/Common/Card/Card";

const BackOfficeCreatePackage = () => {
	const { t } = useTranslation();

	return (
		<div className="container-fluid">
			<div className="d-sm-flex align-items-center justify-content-between mb-4">
				<h1 className="h3 mb-0 text-gray-800">Create Package</h1>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<Card
						header={"Create Package"}
						body={
							<div>
								<div className="row">
									<div className="col-3 fw-bold">Package Name</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="package-name"
												id="text"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Capacity</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="capacity"
												id="text"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Times Per Week</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="times-per-week"
												id="text"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Count Of Month</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="count-of-month"
												id="text"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Total Duration</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="total-duration"
												id="text"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Meeting Count</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="meeting-count"
												id="text"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Trial Lesson</div>
									<div className="col-8">
										<form>
											<input
												className="form-check-input"
												type="checkbox"
												value="monday"
												id="monday"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">
										Parasut Product Code
									</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="text"
												id="parasut-product-code"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">
										Discount Decrease
									</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="text"
												id="discount-decrease"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Meeting Price</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="text"
												id="meeting-price"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Total Price</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="text"
												id="total-price"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">
										Count of Recompense Attendee
									</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="text"
												id="count-of-recompense-attendee"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Branch</div>
									<div className="col-8">
										<select
											id="branch"
											name="branch"
											className="form-select"
										>
											<option value="online">Online</option>
											<option value="mecidiyekoy">Mecidiyeköy</option>
											<option value="besiktas">Beşiktaş</option>
										</select>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Group</div>
									<div className="col-8">
										<select
											id="group"
											name="group"
											className="form-select"
										>
											<option value="cafe">Cafe</option>
											<option value="online">Online</option>
											<option value="kids">Kids</option>
										</select>
									</div>
								</div>
								<hr className="mt-4" />
								<div className="row mt-4">
									<div className="col-3">
										<Button
											type={ButtonType.Success}
											size={ButtonSize.Small}
											text="Save"
										></Button>
									</div>
								</div>
							</div>
						}
					></Card>
				</div>
			</div>
		</div>
	);
};
export default BackOfficeCreatePackage;
