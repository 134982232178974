import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
  getFiltredReqeust,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(endPoint.attendee.CREATE_ATTENDEE, createData);
};
export const update = async (updateData, id = '') => {
  return await putRequest(
    `${endPoint.attendee.UPDATE_ATTENDEE}${id !== '' ? `/${id}` : ''}`,
    updateData
  );
};

export const remove = async (id) => {
  return await removeRequest(`${endPoint.attendee.REMOVE_ATTENDEE}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.attendee.GETBYID_ATTENDEE}/${id}`);
};
export const getAll = async (filterData) => {
  return await postRequest(endPoint.attendee.GETALL_ATTENDEE, filterData);
};
export const getAttendeeBySearchKeyword = async (data) => {
  return await postRequest(`${endPoint.attendee.SEARCH_ATTENDEE}`, data, data);
};
export const getRefCode = async () => {
  return await getRequest(endPoint.attendee.GET_REF_CODE);
};
export const sendRefCodeEmail = async (createData) => {
  return await postRequest(endPoint.attendee.SEND_REF_CODE, createData);
};
export const getNotesByMeetingAttendee = async (id) => {
  return await getRequest(`${endPoint.attendee.GetNotesByMeetingAttendee}/${id}`);
};
