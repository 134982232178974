import {
  create,
  update,
  remove,
  getAll,
  getById,
  getByZone,
} from '../../../services/apiServices/bannerServices';
import {
  reponseStatus,
  responseErrorStatus,
} from '../../../utils/Enum';
import ActionTypes from './actionTypes';
import config from '../../../config.json';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(createReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const createReducer = () => {
  return { type: ActionTypes.banner.Create };
};
const updateAction = (updateData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          await dispatch(updateReducer());
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.banner.Update };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const getAllAction = (requestType) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(requestType);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.banner.GetAll,
    payload: data,
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.banner.GetById,
    payload: data,
  };
};
const getByZoneAction = () => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getByZone();
        let response = res?.data?.data;
        await dispatch(getByZoneReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByZoneReducer = (data) => {
  return {
    type: ActionTypes.banner.GetActiveBannerByZone,
    payload: data,
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.banner.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.banner.HandleValidation,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.banner.CleanList };
};
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
      dispatch(cleanListReducer());
    }
  };
};
const bannerActions = {
  createAction,
  updateAction,
  removeAction,
  getAllAction,
  getByIdAction,
  getByZoneAction,
  handleError,
  handleValidationReducer,
  cleanState,
};
export default bannerActions;
