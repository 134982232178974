import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Actions from '../../store/redux/actions';

import { loadExternalScript } from './loadExternalScript';
import { Facebook } from '../../utils/ExternalVariables';
import { checkLogged } from '../../hooks/useCheckLogged';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import findMyPath from '../../hooks/useFindMyPath';

export const FacebookAuth = ({
  type,
  checkHasItems,
  packageData,
  userLanguage,
  privacyAndTermsChecked,
  setErrorState,
  refId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const OauthLanguage = userLanguage?.split('-')[0].toLowerCase();
  const status = useSelector((e) => e.auth.auth);
  const successCheck = useSelector((e) => e.auth.isSuccess);
  const currency = useSelector(
    (e) => e.preferenceOptions.preferenceCurrencyUnit
  );


  const onLoginClick = () => {
    // Doesnt work. with async Await. withouth the async await doesnt dispatch to redux thus user needs to click twice for icon.
    FB.login(
      function (response) {
        if (response.status === 'connected') {
          FB.api('/me', { fields: 'id,name,email' }, function (userData) {
            const { id, name, email } = userData;
            // Use the obtained data as needed
            onLoginNavigate(type, response.authResponse.accessToken, email);
          });
        }
      },
      { scope: 'public_profile,email' }
    );
  };

  const onLoginNavigate = async (type, token) => {
    if (type === 'login') {
      dispatch(
        Actions.authActions.oauthFacebookLoginAction(token, OauthLanguage)
      )
      if (successCheck) {
        await applyPackageAndLogIn(); // Apply the package if the user has one
      }
    } else if (type === 'register') {
      dispatch(
        Actions.authActions.oauthFacebookRegisterAction(
          token,
          OauthLanguage,
          currency.code,
          refId
        )
      )
      if (successCheck) {
        await applyPackageAndLogIn(); // Apply the package if the user has one
      }
    }
  };
  // Load the Facebook Javascript SDK
  const src = Facebook.Sdk;

  const applyPackageAndLogIn = async () => {
    if (checkHasItems === false) {
      navigate(findMyPath('dashboard'));
    } else {
      if (checkHasItems) {
        navigate(findMyPath('speakers'), { state: { createData: packageData } });
      }
    }
  };

  useEffect(() => {
    loadExternalScript(src)
      .then(() => {
        /*global FB*/
        //initializing the Facebook api

        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: Facebook.ApiKey,
            cookie: true,
            xfbml: true,
            version: Facebook.AppVersion,
          });

          // auto authenticate with the api if already logged in with facebook
        };
      })
      .catch(console.error);

    //removing the sctript after we are done
    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, [src]);

  const handleClick = () => {
    if (type === 'register') {
      if (privacyAndTermsChecked) {
        onLoginClick();
      } else {
        setErrorState(
          <span className="text-danger">
            {t('register.please_read_and_check')}
          </span>
        );
      }
    }
    else {
      onLoginClick();
    }

  };
  return (
    <div
      role="button"
      className=" col border rounded fw-bold d-inline-flex s1 me-2 align-items-center justify-content-center"
      onClick={handleClick}
      style={{
        zIndex: 1,
        opacity: 1,
      }}
    >
      <div className="svgSocialLogin1">
        <FontAwesomeIcon icon={faFacebook} />
      </div>
      <div className="ps-3 t1">Facebook</div>
    </div>
  );
};
