import ActionTypes from './actionTypes';
import {
  login,
  register,
  refreshToken,
  logout,
  setCookie,
  oauthGoogleRegister,
  oauthGoogleLogin,
  oauthFacebookRegister,
  oauthFacebookLogin,
  setAreaDetails,
} from '../../../services/apiServices/authServices';
import Actions from '.';
import { formActions, storageKeys, storageValues } from '../../../utils/Enum';
import refreshPage from '../../../hooks/useReload';
import removeCookieItem from '../../../hooks/useRemoveCookie.js';

const registerAction = (registerData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await register(registerData).then(async (response) => {
      const res = response?.data?.data;
      let token = '';
      let claims = [];
      let id = '';
      let version = '';
      let userArea = res?.userArea;
      if (response?.data?.isSuccess) {
        setAreaDetails(userArea);
        token = res?.token;
        const parts = token?.split('.');
        const payload = JSON.parse(window?.atob(parts[1]));
        claims =
          payload?.[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ];
        id =
          payload?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
          ];
        version = payload.website;
        localStorage.setItem(storageKeys.token, token);
        localStorage.setItem(storageKeys.UIVersion, version);
        setCookie(storageKeys.firstRegister, storageValues.firstRegister);
        setCookie(
          storageKeys.firstRegisterLanding,
          storageValues.firstRegisterLanding
        );
        dispatch(Actions.onboardActions.onboardType(userArea));
        await dispatch(handleError(true));
        await dispatch(
          registerMessage(
            token,
            claims === undefined ? [] : claims,
            id,
            true,
            version,
            userArea
          )
        );
        if (sessionStorage.getItem(storageKeys.utmData) !== null) {
          const obj = {
            form: formActions.register.key,
            value: true,
          };
          sessionStorage.setItem(
            storageKeys.utmDataInsert,
            JSON.stringify(obj)
          );
        }
      } else {
        await dispatch(handleValidationReducer(response?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const registerMessage = (token, claim, id, showLoading, version, userArea) => {
  const values = setAreaDetails(userArea);
  const path = values?.path;
  return {
    type: ActionTypes.auth.SIGNUP_ACTION,
    payload: { token, claim, id, showLoading, version, path },
  };
};
const loginAction = (loginData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await login(loginData).then(async (response) => {
      const res = response?.data?.data;
      let token = '';
      let claims = [];
      let id = '';
      let version = '';
      let userArea = res?.userArea;
      let language = res?.language;
      if (response?.data?.isSuccess) {
        setAreaDetails(userArea);
        token = res?.token;
        const parts = token?.split('.');
        const payload = JSON.parse(window?.atob(parts[1]));
        claims =
          payload?.[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ];
        id =
          payload?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
          ];
        version = payload.website;
        localStorage.setItem(storageKeys.token, token);
        localStorage.setItem(storageKeys.UIVersion, version);
        dispatch(Actions.langActions.pickLanguage(language.toLowerCase()));
        dispatch(Actions.langActions.setFlag(language.toLowerCase()));
        await dispatch(Actions.onboardActions.onboardType(userArea));
        await dispatch(handleError(true));
        await dispatch(
          loginMessage(
            token,
            claims === undefined ? [] : claims,
            id,
            false,
            version,
            userArea
          )
        );
      } else {
        await dispatch(handleValidationReducer(response?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const loginMessage = (token, claims, id, showLoading, version, userArea) => {
  const values = setAreaDetails(userArea);
  const path = values?.path;
  return {
    type: ActionTypes.auth.LOGIN_ACTION,
    payload: { token, claims, id, showLoading, version, path },
  };
};
const logoutAction = () => {
  return async (dispatch) => {
    await dispatch(handleError(''));
    await logout().then(async () => {
      removeCookieItem([
        storageKeys.firstRegister,
        storageKeys.firstRegisterLanding
      ]);
      sessionStorage.removeItem(storageKeys.utmData);
      sessionStorage.removeItem(storageKeys.utmDataInsert);
      sessionStorage.removeItem(storageKeys.currentMenu);
      sessionStorage.removeItem(storageKeys.resetMentors);
      sessionStorage.removeItem(storageKeys.landingRule);
      sessionStorage.removeItem(storageKeys.levelGroup);
      sessionStorage.removeItem(storageKeys.currency);
      sessionStorage.removeItem(storageKeys.userArea);
      sessionStorage.removeItem(storageKeys.token);
      await dispatch({ type: ActionTypes.auth.LOGOUT_ACTION });
      refreshPage();
    });
  };
};
const loadingToggleAction = (status) => {
  return { type: ActionTypes.auth.LOADING_TOGGLE_ACTION, payload: status };
};
const refreshAction = () => {
  return async (dispatch) => {
    await refreshToken().then(async (response) => {
      let token = '';
      let claims = [];
      let id = '';
      let version = '';
      if (response.code !== 'ERR_BAD_REQUEST') {
        token = response?.data?.data?.token;
        const parts = token?.split('.');
        const payload = JSON.parse(window?.atob(parts[1]));
        claims =
          payload?.[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ];
        id =
          payload?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
          ];
        version = payload.website;
        localStorage.setItem(storageKeys.UIVersion, version);
        localStorage.setItem(storageKeys.token, token);
        return await dispatch(
          loginMessage(
            token,
            claims === undefined ? [] : claims,
            id,
            false,
            version
          )
        );
      } else {
        logoutAction();
        return await dispatch(loginMessage(token, claims, id, false, version));
      }
    });
  };
};

const oauthGoogleRegisterAction = (googleToken, userLanguage, currency, refId) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await oauthGoogleRegister(googleToken, userLanguage, currency, refId).then(
      async (response) => {
        const res = response?.data?.data;
        let token = '';
        let claims = [];
        let id = '';
        let version = '';
        let userArea = res?.userArea;
        let oAuth = '';
        if (response?.data?.isSuccess) {
          setAreaDetails(userArea);
          token = res?.token;
          oAuth = res?.oAuth;
          const parts = token?.split('.');
          const payload = JSON.parse(window?.atob(parts[1]));
          claims =
            payload?.[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
          id =
            payload?.[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
          version = payload.website;
          localStorage.setItem(storageKeys.token, token);
          setCookie(storageKeys.firstRegister, storageValues.firstRegister);
          setCookie(
            storageKeys.firstRegisterLanding,
            storageValues.firstRegisterLanding
          );
          dispatch(Actions.onboardActions.onboardType(userArea));
          await dispatch(handleError(true));
          await dispatch(
            oauthRegisterMessage(
              token,
              claims === undefined ? [] : claims,
              id,
              true,
              oAuth,
              version,
              userArea
            )
          );
        } else {
          await dispatch(handleValidationReducer(response?.data?.message));
          await dispatch(handleError(false));
        }
      }
    );
  };
};

const oauthRegisterMessage = (
  token,
  claim,
  id,
  showLoading,
  oAuth,
  version,
  userArea
) => {
  const values = setAreaDetails(userArea);
  const path = values?.path;
  return {
    type: ActionTypes.auth.OAUTH_ACTION,
    payload: { token, claim, id, showLoading, oAuth, version, path },
  };
};

const oauthGoogleLoginAction = (googleToken, userLanguage) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await oauthGoogleLogin(googleToken, userLanguage).then(async (response) => {
      const res = response?.data?.data;
      let token = '';
      let claims = [];
      let id = '';
      let version = '';
      let userArea = res?.userArea;
      let language = res?.language;
      if (response?.data?.isSuccess) {
        setAreaDetails(userArea);
        token = res?.token;
        const parts = token?.split('.');
        const payload = JSON.parse(window?.atob(parts[1]));
        claims =
          payload?.[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ];
        id =
          payload?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
          ];
        version = payload.website;
        localStorage.setItem(storageKeys.token, token);
        localStorage.setItem(storageKeys.UIVersion, version);
        dispatch(Actions.langActions.pickLanguage(language.toLowerCase()));
        dispatch(Actions.langActions.setFlag(language.toLowerCase()));
        await dispatch(Actions.onboardActions.onboardType(userArea));
        await dispatch(handleError(true));
        await dispatch(
          oauthLoginMessage(
            token,
            claims === undefined ? [] : claims,
            id,
            true,
            version,
            userArea
          )
        );
      } else {
        await dispatch(handleValidationReducer(response?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const oauthLoginMessage = (
  token,
  claim,
  id,
  showLoading,
  version,
  userArea
) => {
  const values = setAreaDetails(userArea);
  const path = values?.path;
  return {
    type: ActionTypes.auth.OAUTH_ACTION,
    payload: { token, claim, id, showLoading, version, path },
  };
};

const oauthFacebookRegisterAction = (facebookToken, userLanguage, currency, refId) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await oauthFacebookRegister(facebookToken, userLanguage, currency, refId).then(
      async (response) => {
        const res = response?.data?.data;
        let token = '';
        let claims = [];
        let id = '';
        let version = '';
        let userArea = res?.userArea;
        let oAuth = '';
        if (response?.data?.isSuccess) {
          setAreaDetails(userArea);
          token = res?.token;
          oAuth = res?.oAuth;
          const parts = token?.split('.');
          const payload = JSON.parse(window?.atob(parts[1]));
          claims =
            payload?.[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
          id =
            payload?.[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
          version = payload.website;
          localStorage.setItem(storageKeys.token, token);
          localStorage.setItem(storageKeys.UIVersion, version);
          setCookie(storageKeys.firstRegister, storageValues.firstRegister);
          setCookie(
            storageKeys.firstRegisterLanding,
            storageValues.firstRegisterLanding
          );
          dispatch(Actions.onboardActions.onboardType(userArea));
          await dispatch(
            oauthRegisterMessage(
              token,
              claims === undefined ? [] : claims,
              id,
              true,
              oAuth,
              version,
              userArea
            )
          );
          await dispatch(handleError(true));
        } else {
          await dispatch(handleValidationReducer(response?.data?.message));
          await dispatch(handleError(false));
        }
      }
    );
  };
};

const oauthFacebookLoginAction = (facebookToken, userLanguage) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await oauthFacebookLogin(facebookToken, userLanguage).then(
      async (response) => {
        const res = response?.data?.data;
        let token = '';
        let claims = [];
        let id = '';
        let version = '';
        let userArea = res?.userArea;
        let language = res?.language;
        if (response?.data?.isSuccess) {
          setAreaDetails(userArea);
          token = res?.token;
          const parts = token?.split('.');
          const payload = JSON.parse(window?.atob(parts[1]));
          claims =
            payload?.[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
          id =
            payload?.[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
          version = payload.website;
          localStorage.setItem(storageKeys.token, token);
          localStorage.setItem(storageKeys.UIVersion, version);
          dispatch(Actions.langActions.pickLanguage(language.toLowerCase()));
          dispatch(Actions.langActions.setFlag(language.toLowerCase()));
          await dispatch(Actions.onboardActions.onboardType(userArea));
          await dispatch(handleError(true));
          await dispatch(
            oauthLoginMessage(
              token,
              claims === undefined ? [] : claims,
              id,
              false,
              version,
              userArea
            )
          );
        } else {
          await dispatch(handleValidationReducer(response?.data?.message));
          await dispatch(handleError(false));
        }
      }
    );
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.auth.HANDLE_VALIDATION_AUTH,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.auth.HANDLE_ERROR_AUTH,
    payload: data,
  };
};

const cleanState = (validation = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};

const authActions = {
  registerAction,
  registerMessage,
  loginAction,
  loginMessage,
  loadingToggleAction,
  refreshAction,
  logoutAction,
  oauthGoogleRegisterAction,
  oauthRegisterMessage,
  oauthGoogleLoginAction,
  oauthLoginMessage,
  oauthFacebookRegisterAction,
  oauthFacebookLoginAction,
  cleanState,
};

export default authActions;
