import React, { useEffect, useState } from 'react';
import Card from '../../../../components/Common/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import Actions from '../../../../store/redux/actions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import addDay from '../../../../hooks/useAddDay';
import calculateUTC from '../../../../hooks/useCalculateUTC';
import { ToastContainer, toast } from 'react-toastify';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import Button from '../../../../components/Common/Button/Button';
import {
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../../utils/ComponentEnums';
import getTimeZone from '../../../../hooks/useGetTimeZone';
import { useNavigate } from 'react-router-dom';
import refreshPage from '../../../../hooks/useReload';

const customToastStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const SpeakerDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const meetingList = useSelector(
    (e) => e.meetingSchema.getAllToday.meetingLevelDetailItems
  );
  const meetings = useSelector((e) => e.meetingSchema.getSpeakerSchedule);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const speakerPayment = useSelector((e) => e.speaker.speakerPayment);
  const timeZone = getTimeZone();
  const [meetingInfo, setMeetingInfo] = useState('');
  const [meetingDetails, setMeetingDetails] = useState({});
  const [filteredMeetings, setFilteredMeetings] = useState(undefined);
  const [sessionMinute, setSessionMinute] = useState({ is90: false });

  useEffect(() => {
    dispatch(Actions.meetingSchemaActions.getAllSpeakerScheduleAction());
    getList();
  }, []);
  const getList = async () => {
    if (
      checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.speakerAvailability.create
      )
    ) {
      await dispatch(
        Actions.meetingSchemaActions.getTodaysMeetingBySpeakerAction()
      );
      await dispatch(Actions.speakerActions.speakerPaymentBySpeakerAction());
    }
  };

  const UpdateLevel = () => {};

  const currentDay = moment().format('DD-MM-YYYY') || '';

  const scrollToInterval = (interval) => {
    const element = document.getElementById(interval);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  function handleDates(item) {
    const [year, month, day] = item?.split('T')[0].split('-').map(Number);
    const date = `${day}-${month}-${year}`;
    const timeArray = item?.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const time = `${h}:${m}`;
    const prosess = addDay(time, date);
    const fixedDate = `${prosess}T${time}:00.000Z`;

    const [xday, xmonth, xyear] = fixedDate
      ?.split('T')[0]
      .split('-')
      .map(Number);
    const fixDate = `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
      .toString()
      .padStart(2, '0')}T${calculateUTC(fixedDate?.split('T')[1])}`;
    return fixDate;
  }
  const handleGroupChange = async (contact) => {
    const currentTime = new Date();
    const utcTime = new Date(
      currentTime.getTime() + currentTime.getTimezoneOffset() * 60000
    );
    const date = `${currentTime.getFullYear()}-${(currentTime.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentTime.getDate().toString().padStart(2, '0')}`;

    let availableMeetings;
    let details;
    let fixDates;
    meetings?.forEach((meeting, i) => {
      const [y, mo, d] = meeting.date.split('-').map(Number);
      const nDate = addDay(meeting.interval, `${d}-${mo}-${y}`);
      const [dx, mox, yx] = nDate.split('-').map(Number);
      const cDate = `${yx}-${mox.toString().padStart(2, '0')}-${dx
        .toString()
        .padStart(2, '0')}`;
      const getCurrent = calculateUTC(
        `${utcTime.getHours()}:${utcTime.getMinutes()}`
      );
      const cTime = calculateUTC(
        `${meeting.interval.split('-')[0].split(':')[0]}:${
          meeting.interval.split('-')[0].split(':')[1]
        }`
      );

      const [hx, mx] = getCurrent.split(':').map(Number);
      const asMinx = hx * 60 + mx;
      const [h, m] = cTime.split(':').map(Number);
      const asMin = h * 60 + m - 15;
      if (cDate === date.split('T')[0] && asMin <= asMinx) {
        meeting.meetingDetails.forEach((meetingDetail, index) => {
          const [parsedS, parsedE] = meeting?.interval.split('-').map(String);
          const [sH, sM] = parsedS?.split(':').map(Number);
          const [eH, eM] = parsedE?.split(':').map(Number);
          const is90 = eH * 60 + eM - (sH * 60 + sM) >= 90;
          setSessionMinute({ is90: is90 });
          let addMin;
          switch (index) {
            case 0:
              addMin = is90 ? 40 * 60000 : 30 * 60000;
              break;
            case 1:
              addMin = is90 ? 40 * 60000 : 30 * 60000;
              break;
            default:
              addMin = 40 * 60000;
              break;
          }
          const fixDate = handleDates(meetingDetail.startDate);
          const xDate = new Date(fixDate).getTime() + addMin;
          const meetingDateTime = new Date(xDate);
          if (
            meetingDateTime >= currentTime &&
            availableMeetings === undefined
          ) {
            availableMeetings = meetingDetail;
            details = meetings[i];
            fixDates = fixDate;
          }
        });
      }
    });

    if (availableMeetings !== undefined) {
      setMeetingDetails({
        ...details,
        date: fixDates.split('T')[0],
        interval: `${calculateUTC(
          details.interval.split('-')[0]
        )}-${calculateUTC(details.interval.split('-')[1])}`,
      });
    }
    setFilteredMeetings(
      availableMeetings !== undefined ? [availableMeetings] : undefined
    );
  };

  const findMeetingUrl = (item, sesMin) => {
    let meetingUrl = null;

    const fifteenMinutesBeforeStart = new Date(handleDates(item[0]?.startDate));
    fifteenMinutesBeforeStart.setMinutes(
      fifteenMinutesBeforeStart.getMinutes() - 15
    );
    const clickAbleLastFiveMins = fifteenMinutesBeforeStart;
    clickAbleLastFiveMins.setMinutes(clickAbleLastFiveMins.getMinutes() + 10);

    const currentDateTime = new Date();
    const firstDate = new Date(handleDates(item[0]?.startDate));
    const min =
      clickAbleLastFiveMins.getMinutes() - currentDateTime.getMinutes();

    if (currentDateTime < clickAbleLastFiveMins) {
      return setMeetingInfo(
        <div>
          {t('mySessions.join_session_info', { min })
            ?.split('{br}')
            ?.map((item, index) => (
              <span
                key={index}
                className="d-flex position-relative justify-content-start"
                style={{ maxWidth: '550px' }}
              >
                <strong>{item}</strong>
              </span>
            ))}
        </div>
      );
    } else if (
      currentDateTime > fifteenMinutesBeforeStart &&
      currentDateTime < firstDate
    ) {
      meetingUrl = item[0]?.meetingUrl;
      setMeetingInfo('');
    }
    const rule = item?.length === 1 ? item?.length : item?.length - 1;
    for (let i = 0; i < rule; i++) {
      const meeting = item[i];
      const meetingDateTime = new Date(handleDates(meeting?.startDate));
      if (item.length === 1) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }
      const nextMeeting = item[i + 1];
      let nextMeetingDateTime;
      if (nextMeeting !== undefined && sesMin) {
        const secondLink = new Date(handleDates(nextMeeting?.startDate));
        nextMeetingDateTime = new Date(
          secondLink.setMinutes(secondLink.getMinutes() - 10)
        );
      } else {
        nextMeetingDateTime = new Date(handleDates(nextMeeting?.startDate));
      }
      if (
        i === 0 &&
        currentDateTime >= meetingDateTime &&
        currentDateTime <= nextMeetingDateTime
      ) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }

      if (currentDateTime > nextMeetingDateTime) {
        meetingUrl = nextMeeting?.meetingUrl;
        break;
      }
    }

    return meetingUrl;
  };
  const handleClick = (item) => {
    const meetingUrl = findMeetingUrl(item, sessionMinute);
    if (meetingUrl) {
      window.open(meetingUrl, '_blank');
    } else {
      dispatch(Actions.meetingSchemaActions.getAllSpeakerScheduleAction());
    }
  };

  useEffect(() => {
    if (meetingInfo !== '') {
      toast.info(meetingInfo, {
        onClose: () => {
          setMeetingInfo('');
        },
      });
    }
  }, [meetingInfo]);

  useEffect(() => {
    if (meetings.length > 0 && filteredMeetings === undefined) {
      handleGroupChange(meetings);
    }
  }, [meetings]);

  const cardHeader = [
    { key: 'date', label: t('calendar.table_session_date') },
    { key: 'interval', label: t('meetingSchema.interval') },
  ];

  useEffect(() => {
    const timerId = setTimeout(() => {
      refreshPage();
    }, 5 * 60 * 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const compareMeetings = (a, b) => {
    const intervalA = calculateUTC(a?.interval?.split('-')[0]);
    const intervalB = calculateUTC(b?.interval?.split('-')[0]);

    if (intervalA < intervalB) {
      return -1;
    }
    if (intervalA > intervalB) {
      return 1;
    }
    return 0;
  };

  const sortedMeetings = meetingList?.sort(compareMeetings);

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">
          {t('speakerDashboard.speaker_dashboard')}
        </h1>
      </div>
      <div className="row ">
        <div className="col-3 rounded px-3">
          <Card
            padding={0}
            body={
              <div className="row ">
                {/* <div className="col-md-6">
                  <div className="card-header h5 bg-primary text-white">
                    {t(`${speakerPayment?.[0]?.month}`)}
                  </div>
                  <div className="card-body">
                    <h6 className="card-text">
                      {t('speakerDashboard.minutes')}{' '}
                      {speakerPayment?.[0]?.totalMinute}
                    </h6>
                    <h6 className="card-text">
                      {t('speakerDashboard.amount')}{' '}
                      {speakerPayment?.[0]?.totalPrice}
                    </h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-header h5 bg-secondary text-white">
                    {t(`${speakerPayment?.[1]?.month}`)}
                  </div>
                  <div className="card-body">
                    <h6 className="card-text">
                      {t('speakerDashboard.minutes')}{' '}
                      {speakerPayment?.[1]?.totalMinute}
                    </h6>
                    <h6 className="card-text">
                      {t('speakerDashboard.amount')}{' '}
                      {speakerPayment?.[1]?.totalPrice}
                    </h6>
                  </div>
                </div> */}

                <div className=" ">
                  <Button
                    text={t('sidebarBackOffice.effort_report')}
                    type={ButtonType.Success}
                    outline
                    bold
                    onClick={() => {
                      navigate('/speaker-effort-report');
                    }}
                    buttonCol={12}
                  />
                </div>
              </div>
            }
          />
        </div>
        <div className="col-4 ms-auto">
          <Card
            body={
              <div>
                <div className="d-sm-flex align-items-center justify-content-start mb-4 form-div-border">
                  <HeadTags hSize={6} strong text={'Active Session'} />
                  {meetingDetails?.userArea !== 1 && (
                    <div className="ms-auto d-inline-flex px-2 flex-shrink-0">
                      <Button
                        type={
                          filteredMeetings === undefined
                            ? ButtonType.Secondary
                            : ButtonType.Info
                        }
                        buttonCol={12}
                        size={ButtonSize.Small}
                        textColor={TextColors.Light}
                        text={t('mySessions.join_session')}
                        onClick={() =>
                          handleClick(meetingDetails?.meetingDetails)
                        }
                        disabled={filteredMeetings === undefined}
                      />
                    </div>
                  )}
                </div>
                {filteredMeetings !== undefined ? (
                  cardHeader?.map((val, i) => (
                    <div
                      key={i}
                      className={`d-flex justify-content-between border-bottom py-1 ${
                        i % 2 ? 'bg-light' : ''
                      }`}
                    >
                      <div className="fw-bold px-1">{val?.label}</div>
                      <div className="px-1">{meetingDetails?.[val?.key]}</div>
                    </div>
                  ))
                ) : (
                  <span className="fw-bold text-center">
                    You dont have active session yet.
                  </span>
                )}
              </div>
            }
          />
        </div>
      </div>
      <span className="d-flex justify-content-end fw-bold">
        {t('utc.calculation', { timeZone })}
      </span>
      <div className="col-12">
        <Card
          body={
            <div>
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="flex-shrink-1">
                      <h6>
                        {' '}
                        {t('speakerDashboard.todays_meeting')} - ( {currentDay}{' '}
                        )
                      </h6>
                    </div>

                    <div className="nav-pills flex-grow-0">
                      {sortedMeetings?.map((meeting, index) => (
                        <button
                          key={index}
                          className="btn btn-sm btn-link"
                          onClick={() =>
                            scrollToInterval(
                              `${calculateUTC(
                                meeting?.interval?.split('-')[0]
                              )} - ${calculateUTC(
                                meeting?.interval?.split('-')[1]
                              )}`
                            )
                          }
                        >
                          {`${calculateUTC(
                            meeting?.interval?.split('-')[0]
                          )} - ${calculateUTC(
                            meeting?.interval?.split('-')[1]
                          )}`}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="table-responsive">
                    {meetingList?.map((meeting, index) => (
                      <div
                        id={`${calculateUTC(
                          meeting?.interval?.split('-')[0]
                        )} - ${calculateUTC(meeting?.interval?.split('-')[1])}`}
                        key={index}
                      >
                        <h6 className="d-flex justify-content-center fw-bold">
                          ({' '}
                          {`${calculateUTC(
                            meeting?.interval?.split('-')[0]
                          )} - ${calculateUTC(
                            meeting?.interval?.split('-')[1]
                          )}`}{' '}
                          - {t(meeting?.level)} - {meeting?.location} )
                        </h6>
                        <table className="table  table-striped table-sm">
                          <tbody className="align-middle">
                            {meeting?.meetingLevelAttendee?.map(
                              (attendee, attendeeIndex) => (
                                <tr key={attendeeIndex}>
                                  <td>
                                    {`${calculateUTC(
                                      meeting?.interval?.split('-')[0]
                                    )} - ${calculateUTC(
                                      meeting?.interval?.split('-')[1]
                                    )}`}
                                  </td>
                                  <td>
                                    {t(meeting?.level)}
                                    {/* <button
                                      type="button"
                                      className="btn btn-sm btn-link"
                                      onClick={() =>
                                        UpdateLevel(attendee?.attendeeId)
                                      }
                                    >
                                      {t('speakerDashboard.change_level')}
                                    </button> */}
                                  </td>
                                  <td>{attendee?.attendeeName}</td>
                                  <td>(M){attendee?.managerName}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }
        ></Card>
      </div>
      {meetingInfo !== '' && (
        <ToastContainer
          style={customToastStyle}
          limit={1}
          autoClose={2000}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );
  return content;
};
export default SpeakerDashboard;
