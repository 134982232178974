import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const userPackageReducer = (state = initialState.userPackage, action) => {
  switch (action.type) {
    case ActionTypes.userPackage.CREATE_ACTION_USERPACKAGE:
      return {
        ...state,
        packageData: action.payload,
        isPackageComplete: true,
      };
    case ActionTypes.userPackage.COMPLETE_ACTION_USERPACKAGE:
      return initialState.userPackage;
    default:
      return state;
  }
};

export default userPackageReducer;
