import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/redux/store';
import Actions from './store/redux/actions';
import { storageKeys } from './utils/Enum';
import useGTM from '../src/hooks/useGTM';
import { Loader } from './components/Common/Loader/Loader';
import App from './App';

// const App = lazy(() => import('./App')); TODO How it works?

const MainApp = () => {
  const { sendEventToDataLayer } = useGTM();
  var userLang = navigator.language || navigator.userLanguage;
  const localStorageLang = localStorage.getItem(storageKeys.languge);
  if (!localStorageLang) {
    localStorage.setItem(storageKeys.languge, userLang);
  }

  const currency = sessionStorage.getItem(storageKeys.currency);
  const storagedCurrency =
    currency !== null &&
    currency !== 'undefined' &&
    currency !== '{}' &&
    JSON.parse(currency).code !== null &&
    JSON.parse(currency).code !== '';
  if (!storagedCurrency) {
    store.dispatch(Actions.userActions.getCurrenyByIPAddressAction());
  }

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<MainApp />);
