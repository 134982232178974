import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const faqReducer = (state = initialState.faq, action) => {
  switch (action.type) {
    case ActionTypes.faq.CREATE_ACTION_FAQ:
      return {
        ...state,
        faq: initialState.faq.faq,
      };
    case ActionTypes.faq.UPDATE_ACTION_FAQ:
      return {
        ...state,
        faq: {},
        getById: {},
      };
    case ActionTypes.faq.GETBYID_ACTION_FAQ:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.faq.GETALL_ACTION_FAQ:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.faq.HOLD_VALUE_FAQ:
      return {
        ...state,
        faq: action.payload,
      };
    default:
      return state;
  }
};

export default faqReducer;
