import React from 'react';
import {
  LabelFontSize,
  LabelPosition,
  LabelTextType,
  TextColors,
} from '../../../utils/ComponentEnums';

const Label = ({
  id,
  fontType,
  fontSize,
  labelPosition,
  value,
  type,
  textColor,
  muted,
  icon,
}) => {
  let className = '';
  switch (fontType) {
    case LabelTextType.Normal:
      className += LabelTextType.Normal;
      break;
    case LabelTextType.Bold:
      className += LabelTextType.Bold;
      break;
    case LabelTextType.Bolder:
      className += LabelTextType.Bolder;
      break;
    case LabelTextType.Italic:
      className += LabelTextType.Italic;
      break;
    case LabelTextType.Light:
      className += LabelTextType.Light;
      break;
    case LabelTextType.Muted:
      className += LabelTextType.Muted;
      break;
    default:
      className += LabelTextType.Normal;
      break;
  }
  switch (fontSize) {
    case LabelFontSize.fs1:
      className += LabelFontSize.fs1;
      break;
    case LabelFontSize.fs2:
      className += LabelFontSize.fs2;
      break;
    case LabelFontSize.fs3:
      className += LabelFontSize.fs3;
      break;
    case LabelFontSize.fs4:
      className += LabelFontSize.fs4;
      break;
    case LabelFontSize.fs5:
      className += LabelFontSize.fs5;
      break;
    case LabelFontSize.fs6:
      className += LabelFontSize.fs6;
      break;
    default:
      className += LabelFontSize.fs6;
      break;
  }
  switch (labelPosition) {
    case LabelPosition.NearInput:
      className += LabelPosition.NearInput;
      break;
    case LabelPosition.AboveInput:
      className += LabelPosition.AboveInput;
      break;
    default:
      break;
  }

  muted ? (className += ' text-muted') : (className = className);

  return (
    <label id={id} type={type} className={`${className} ${textColor}`}>
      {value}
      {icon}
    </label>
  );
};

export default Label;
