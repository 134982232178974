import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ButtonType,
  ButtonSize,
  ButtonIcon,
  ButtonWidth,
} from '../../../utils/ComponentEnums';
import { LoaderButton } from '../Loader/LoaderButton';

const Button = ({
  loading,
  buttonCol,
  iconSide,
  text,
  size,
  type,
  icon,
  iconColor,
  borderless,
  outline,
  disabled,
  bold,
  onClick,
  width,
  textColor = '',
  id,
  clicked,
  border,
  borderColor,
  hidden,
  disableHover,
}) => {
  let className = `btn ${bold ? ' fw-bold' : ''} ${hidden ? 'd-none' : ''}`;
  let checkOutline = `${border ? 'rounded-pill' : ''} ${
    borderColor ? 'border border-success' : ''
  } btn${outline ? '-outline' : ''}`;
  let iconElement = null;

  switch (size) {
    case ButtonSize.Small:
      className += ButtonSize.Small;
      break;
    case ButtonSize.ExtraSmall:
      className += ButtonSize.ExtraSmall;
      break;
    case ButtonSize.Large:
      className += ButtonSize.Large;
      break;
    default:
      break;
  }

  if (type !== undefined) {
    checkOutline += type;
  }

  switch (width) {
    case ButtonWidth.S:
      className += ButtonWidth.S;
      break;
    case ButtonWidth.M:
      className += ButtonWidth.M;
      break;
    case ButtonWidth.L:
      className += ButtonWidth.L;
      break;
    default:
      className += ButtonWidth.XL;
      break;
  }

  if (icon !== undefined) {
    iconElement = <FontAwesomeIcon className={` ${iconColor}`} icon={icon} />;
  }

  if (clicked === id && id !== undefined) {
    checkOutline = ' btn btn-success';
  } else if (borderless !== undefined && outline === undefined) {
    checkOutline += ' border-0';
  }

  if (disableHover) {
    className += ' no-hover';
  }
  return (
    <div className={`col-${buttonCol}`}>
      <button
        key={`${id}_${text}`}
        id={id}
        role={onClick === undefined ? 'none' : 'button'}
        type="button"
        className={`${className}  ${checkOutline} d-flex align-items-center justify-content-center`}
        onClick={onClick}
        disabled={loading !== undefined ? loading : disabled}
      >
        {iconSide && iconElement && <span className="pe-2">{iconElement}</span>}
        {text && (
          <span className={` ${textColor}`}>
            {!loading ? text : <LoaderButton />}
          </span>
        )}
        {!iconSide && iconElement && (
          <span className="px-1">{iconElement}</span>
        )}
      </button>
    </div>
  );
};

export default Button;
