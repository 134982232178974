import React, { useEffect, useRef, useState } from 'react';
import styles from '../../../assets/Styles.module.scss';
import { useTranslation } from 'react-i18next';

const Icon = () => {
  return (
    <svg height="18" width="18" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path
        d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
        fill="red"
      ></path>
    </svg>
  );
};

const DropdownWithSearch = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  selectedProps,
  myValue,
  onBlur,
  onFocus,
  resetState,
  selectedValues,
  isCalendarLabel = false,
  setStateFromState,
  noTranslate = false,
  disable = false,
  onReset,
  isfilter,
  required,
  selectRule,
  style,
  lockSelection = false,
}) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    setStateFromState ? [setStateFromState] : isMulti ? [] : null
  );
  const [labelTop, setLabelTop] = useState('-2.15rem');

  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef();
  const inputRef = useRef();
  function filterSelectedValues(data, selectedValues) {
    const checkObj = !isMulti ? selectedProps.searchKey : selectedProps.value;
    const selectedDatas = Array.isArray(selectedValues)
      ? selectedValues
      : Number.isInteger(selectedValues)
      ? [selectedValues]
      : selectedValues?.split(',');
    const filteredObjects = data?.filter((obj) =>
      selectedDatas?.includes(obj?.[checkObj])
    );
    return !isMulti ? filteredObjects[0] : filteredObjects;
  }
  const tooltip = t('input_on_label.select_required_fields');
  let checkRequiredField =
    required !== undefined ? (
      <span className="text-danger fw-bold ms-1">*</span>
    ) : null;
  useEffect(() => {
    if (selectedValues) {
      setSelectedValue(filterSelectedValues(options, selectedValues));
    }

    if (selectedValue?.length > 0) {
      getDisplay();
    }
  }, [selectedValues]);
  useEffect(() => {
    if (resetState) {
      setSelectedValue(isMulti ? [] : null);
    }
    setSearchValue('');
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu, resetState, selectedValues]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });
  const handleInputClick = (e) => {
    if (!disable) {
      setShowMenu(!showMenu);
      if (typeof onFocus === 'function') {
        onFocus(e);
      }
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    }
  };

  const getDisplay = () => {
    if (
      selectedValue === null ||
      selectedValue?.length === 0 ||
      selectedValues === ''
    ) {
      return myValue ? myValue : placeHolder;
    }
    if (isMulti) {
      return (
        <div className={styles.dropdown_tags}>
          {selectedValue.map((option, index) => (
            <div
              key={`${option[selectedProps.value]}_${index}_${index + 1}`}
              className={styles.dropdown_tag_item}
              style={{
                fontSize:
                  style === undefined && selectedValue.length > 4 && '.55rem',
              }}
            >
              {!noTranslate
                ? t(option[selectedProps.key])
                : option[selectedProps.key]}
              <span
                key={`span_${option[selectedProps.value]}_${index}_${
                  index + 1
                }`}
                onClick={(e) => onTagRemove(e, option)}
                className={styles.dropdown_tag_close}
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }

    return selectedProps?.supKey !== undefined
      ? selectedValue?.[selectedProps?.key] +
          ' x ' +
          selectedValue?.[selectedProps?.supKey]
      : !noTranslate
      ? t(selectedValue?.[selectedProps?.key])
      : selectedValue?.[selectedProps?.key];
  };

  const removeOption = (option) => {
    return selectedValue.filter(
      (o) => o[selectedProps.value] !== option[selectedProps.value]
    );
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(option[selectedProps.value]);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (
        selectedValue.findIndex(
          (o) => o[selectedProps.value] === option[selectedProps.value]
        ) >= 0
      ) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
      setShowMenu(false);
    }

    setSelectedValue(newValue);
    onChange(option[selectedProps.value]);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return (
        selectedValue.filter(
          (o) => o[selectedProps.value] === option[selectedProps.value]
        ).length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }
    return selectedValue[selectedProps.value] === option[selectedProps.value];
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options?.filter(
      (option) =>
        `${
          option[
            selectedProps.search !== undefined
              ? selectedProps.search
              : selectedProps.key
          ]
        }`
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setSelectedValue(isMulti ? [] : null);
    setSearchValue('');
    onReset(); // Call the onReset function passed as a prop
  };

  const styleInside = {
    border: '1px solid #dcf1f7',
    borderColor: '#dcf1f7 !important',
  };

  useEffect(() => {
    const updateLabelPosition = () => {
      if (inputRef.current) {
        const inputHeight = inputRef.current.offsetHeight;
        setLabelTop(`-${inputHeight + 1}px`);
      }
    };

    updateLabelPosition();
  }, [selectedValue]);

  return (
    <div
      className={styles.dropdown_container}
      style={style !== undefined ? style : styleInside}
    >
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={styles.dropdown_input}
      >
        {(getDisplay()?.props !== undefined ||
          !getDisplay()?.includes(t('dropdownWithSearch.select'))) &&
          style === undefined && (
            <label
              className="ms-2 position-absolute"
              style={{ marginTop: labelTop }}
            >
              {!isCalendarLabel && (
                <span
                  className={`${styles.responsive_text} bg-white text-muted px-1`}
                >
                  {placeHolder
                    .replace(/\.\.\./g, '')
                    .replace(t('table.select'), '')}
                </span>
              )}
              {checkRequiredField}
            </label>
          )}
        <div className={`d-flex ${style !== undefined && 'ms-2'}`}>
          {!disable ? getDisplay() : tooltip}
        </div>
        <div className="dropdown-tools d-flex">
          {isfilter && selectedValue ? (
            <div className="dropdown-tool" onClick={handleReset}>
              <CloseIcon />
            </div>
          ) : null}
          <div className="dropdown-tool">
            <Icon />
          </div>
        </div>
      </div>
      {showMenu && !lockSelection && (
        <div className={styles.dropdown_menu}>
          {isSearchable && (
            <div className={styles.search_box}>
              <input onChange={onSearch} value={searchValue} ref={searchRef} />
            </div>
          )}
          {getOptions().length === 0 && (
            <span className="text-danger"> {selectRule}</span>
          )}
          {getOptions()?.map((option, optionIndex) => (
            <div
              onClick={(e) => {
                if (isMulti) {
                  e.stopPropagation();
                }
                onItemClick(option);
              }}
              key={`${option[selectedProps.value]}_${
                option[selectedProps.key]
              }_${optionIndex}_${optionIndex + 1}`}
              className={`${styles.dropdown_item} ${
                isSelected(option) ? styles.selected : ''
              }`}
            >
              {option[selectedProps.supKey] !== undefined
                ? `${option[selectedProps.key]} x ${
                    option[selectedProps.supKey]
                  } ${
                    option[selectedProps?.display] !== undefined
                      ? option[selectedProps?.display].split(' ')[4]
                      : null
                  }`
                : !noTranslate
                ? t(option[selectedProps.key])
                : option[selectedProps.key]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownWithSearch;
