import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../store/redux/actions';
import checkClaims from '../../hooks/useCheckClaims';
import Claims from '../../utils/Claims';
import HeadTags from '../../components/Common/HeadTags/HeadTags';
import Card from '../../components/Common/Card/Card';
import Table from '../../components/Common/Table/Table';
import { ToastContainer, toast } from 'react-toastify';
import { Entities } from '../../utils/Enum';

const CallThem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const callList = useSelector((e) => e.subscription.getAll);
  const isSuccess = useSelector((e) => e.subscription.isSuccess);
  const validationMessage = useSelector(
    (e) => e.subscription.validationMessage
  );
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const navigate = useNavigate();
  const deleteAble = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.mailSubscription.removeThem
  );
  const [handleError, setHandleError] = useState('');

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          dispatch(Actions.subscriptionActions.cleanState(true));
          setHandleError('');
          dispatch(Actions.subscriptionActions.getAllAction());
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    if (
      checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.mailSubscription.getAll
      )
    ) {
      dispatch(Actions.subscriptionActions.getAllAction());
    }
  };

  const headers = [
    { key: 'id', label: t('mailSubscription.table_id') },
    { key: 'name', label: t('common.name') },
    { key: 'email', label: t('common.email') },
    { key: 'phone', label: t('common.phone') },
    { key: 'note', label: t('mailSubscription.table_note') },
    { key: 'area', label: t('mailSubscription.table_area') },
    { key: 'location', label: t('mailSubscription.table_location') },
    { key: 'createdAt', label: t('common.created_at') },
    {
      key: 'deletedBy',
      label: t('common.deleted_by'),
      perm: deleteAble,
    },
    {
      key: 'deletedAt',
      label: t('common.deleted_at'),
      perm: deleteAble,
    },
  ];

  const removeAction = async (id) => {
    await dispatch(Actions.subscriptionActions.removeAction(id));
  };

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
  };

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-center mb-4">
        <HeadTags hSize={1} text={t('mailSubscription.header_perm')} strong />
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={callList}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.mailSubscription}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  exportName={Entities.mailSubscription.exportName}
                />
              </div>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.mailSubscription.getAll,
    content
  );
};
export default CallThem;
