import React, { useRef } from 'react';

const CookiePolicy = ({ onScrollToBottom }) => {
  const modalContentRef = useRef();

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
    const isScrolledToBottom = scrollTop + clientHeight + 100 >= scrollHeight;
    if (isScrolledToBottom && onScrollToBottom) {
      onScrollToBottom();
    }
  };

  const containerStyle = {
    maxHeight: 'calc(100vh - 20vh)',
    height: '500px',
    overflowY: 'auto',
  };

  return (
    <div
      onScroll={handleScroll}
      ref={modalContentRef}
      className="container-fluid"
      style={containerStyle}
    >
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="card shadow mb-4">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <main>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <p align="center">
                              <strong>
                                Çerezlere İlişkin Aydınlatma Metni
                              </strong>
                            </p>
                            <p align="center">
                              <strong> </strong>
                            </p>

                            <p>
                              Meet2Talk olarak çerez politikamıza ilişkin sizi
                              bilgilendirmek istiyoruz.
                            </p>
                            <p>
                              Meet2Talk internet sitesi, mobil cihaz uygulaması
                              vb. çevrimiçi platformlarımızı ziyaretiniz
                              sırasında deneyiminizi geliştirebilmek için çerez
                              kullanıyoruz.
                            </p>
                            <p>
                              Çerezler ziyaret sırasında kullandığınız cihazdaki
                              tarayıcıya yerleştirilen dosyalardır. Çoğu
                              tarayıcı çerezlere ilişkin seçim yapmana ve/veya
                              çerezleri silmene izin verir.
                            </p>
                            <p>
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>Neden çerez kullanıyoruz? </strong>
                            </p>
                            <p>
                              Çevrimiçi platformlarımızın çalışması için gerekli
                              temel fonksiyonları gerçekleştirmek. Örneğin
                              oturumdan düştüğünüz takdirde tekrar e-posta ve
                              şifre bilgilerinizi doldurmadan sisteme tekrar
                              bağlanabilmenizi sağlamak için çerez kullanıyoruz.
                            </p>
                            <p>
                              Çevrimiçi platformlarımızdaki problem ve hataları
                              analiz etmek ve bunların daha iyi çalışabilmesini
                              sağlamak. Örneğin sistem, uygunluğu olan bir
                              oturumun tercih edilmesine izin vermiyorsa bu
                              durumu çözümleyebilmek için çerez kullanıyoruz.
                            </p>
                            <p>
                              Kişiselleştirme, hedefleme ve reklamcılık
                              faaliyeti gerçekleştirmek. Örneğin, ziyaretçilerin
                              görüntüledikleri sayfa ve ürünler üzerinden
                              ziyaretçilerin ilgi alanlarıyla bağlantılı reklam
                              gösterilmesi için çerez kullanıyoruz.
                            </p>
                            <p>
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>Hangi çerezleri kullanıyoruz?</strong>
                            </p>
                            <p>
                              Şu an için Google Analytics, Google Ads ve
                              Facebook Ads çerezlerini kullanıyoruz. Bu
                              çerezlerden hangisine izin verip vermeyeceğinizi
                              seçebilir ve dilerseniz hepsini kabul veya
                              reddedebilirsiniz.
                            </p>
                            <p>
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>
                                Çerezleri nasıl kontrol edebilirim?
                              </strong>
                            </p>
                            <p>
                              Çerezlere onay vermiş olsanız dahi kullandığınız
                              tarayıcı veya mobil cihaz ayarlarından
                              çerezlerinizi yönetebilirsiniz
                            </p>
                            <p>
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>Çerezler güncelleniyor mu?</strong>
                            </p>
                            <p>
                              Kimi zaman çerez politikamızda geliştirme veya
                              yenileme yapabiliyoruz. Ancak yaptığımız
                              değişiklikleri her zaman buradan takip edip güncel
                              bilgileri alabilirsiniz.
                            </p>
                            <p>
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>
                                Çerezlere ilişkin ne gibi haklarım var?
                              </strong>
                            </p>
                            <p>
                              6698 Sayılı Kanun gereği çerezlere ilişkin ne gibi
                              haklarınızın olduğunu öğrenmek için{' '}
                              <a
                                className="fw-bold"
                                href="/gizlilik-politikasi"
                                target="_blank"
                              >
                                Gizlilik Politikamızı
                              </a>{' '}
                              okuyunuz. Burada kişisel verilerinize ilişkin ne
                              gibi haklarınızın olduğu açıkça belirtilmiştir.
                            </p>

                            <p>
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>Güncellenme Tarihi: 25.01.2022</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
