import React, { useState } from 'react';
import Card from '../../../components/Common/Card/Card';
import WeeklyCalendar from '../../../components/Common/Calendar/WeeklyCalendar';
import ModalWithSearch from '../../../components/Common/Modal/ModalWithSearch';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Dropdown from '../../../components/Common/Dropdown/Dropdown';
import learningPurposeSVG from '../../../assets/images/learning_purpose.svg';
import typeOfServiceSVG from '../../../assets/images/typeofservice.svg';
import sessionLevelSVG from '../../../assets/images/session_level.svg';
import meet2talkButton from '../../../assets/images/meet2talk_button.svg';
import servicesSVG from '../../../assets/images/services.svg';
import Input from '../../../components/Common/Input/Input';
import BasicTable from '../../../components/Common/Table/BasicTable';
import { ToastContainer, toast } from 'react-toastify';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

export default function ScheduleManager({
  getMeIndex = undefined,
  attendeeId = undefined,
  navigateMe,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const fromNavigateWhere = getMeIndex?.fromNavigateWhere;
  const isRecompense = fromNavigateWhere === 'recompense';
  const isSetDate = fromNavigateWhere === 'setDate';
  const hideRule = isRecompense || isSetDate;
  const onlyMentors = useSelector((e) => e.calendar.getCalendarSpeakers);
  const staticDatas = useSelector((e) => e.staticData);
  const ageInterval = useSelector((e) => e.ageInterval.getAll);
  const [filter, setFilter] = useState({
    StartDateTime: null,
    EndDateTime: null,
    LearningPurpose: null,
    LanguageLevels: null,
    TypeOfService: null,
    Native: null,
    FullName: null,
    UserArea: 0,
    Locations: null,
    SelectedIds: null,
    PackageId: null,
    AgeId: null,
    CompanyId: null,
    DateDetails: {
      dayOfWeek: null,
      timeInterval: null,
      selectedDate: null,
      selectedTime: null,
    },
    AttendeeId: null,
  });
  const [color, setColor] = useState(undefined);
  const [request, setRequest] = useState(false);
  const isOnline = filter?.UserArea === 0;
  const isCafe = filter?.UserArea === 1;
  const isKids = filter?.UserArea === 2;
  const isCompany = filter?.UserArea === 3;

  useEffect(() => {
    if (isRecompense) {
      setColor(getMeIndex?.whichone);
    } else if (isSetDate) {
      setColor(getMeIndex?.selectLaterItems?.productType);
    }
  }, []);

  useEffect(() => {
    if (
      filter?.TypeOfService?.includes(0) &&
      filter?.TypeOfService?.includes(1)
    ) {
      setColor(undefined);
    } else if (filter?.TypeOfService?.includes(0)) {
      setColor('Private');
    } else if (filter?.TypeOfService?.includes(1)) {
      setColor('Group');
    } else {
      setColor(undefined);
    }
  }, [filter]);

  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const learningPurpose = staticDatas.LearningPurpose;
  const levelGroup = useSelector(
    (e) => e.preferenceOptions.preferenceLevelGroup
  );
  const typeOfService = staticDatas.TypeOfService;
  const native = staticDatas.Native;
  const userArea = staticDatas.UserArea;
  const locations = useSelector((e) => e.location.branches);
  const [searchValue, setSearchValue] = useState('');
  const [childData, setChildData] = useState([]);
  const [removedIndex, setRemovedIndex] = useState(null);
  const [addData, setAddData] = useState(false);
  const [selectLaterResult, setSelectResult] = useState('');
  const orderItemIsSuccess = useSelector((e) => e.order.isSuccess);
  const orderItemValidationMessage = useSelector(
    (e) => e.order.validationMessage
  );

  useEffect(() => {
    return () => {
      dispatch(Actions.meetingAttendeeActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (orderItemIsSuccess && orderItemValidationMessage === '' && isSetDate) {
      setSelectResult(
        <span
          className="d-flex position-relative mx-auto  justify-content-center fs-6"
          style={{ maxWidth: '550px' }}
        >
          <strong> {t('order.select_later_set_date_success')}</strong>
        </span>
      );
    } else if (
      orderItemIsSuccess &&
      orderItemValidationMessage !== '' &&
      isSetDate
    ) {
      setSelectResult(
        <span
          className="d-flex position-relative mx-auto  justify-content-center fs-6"
          style={{ maxWidth: '550px' }}
        >
          <strong> {orderItemValidationMessage}</strong>
        </span>
      );
    } else if (
      !orderItemIsSuccess &&
      orderItemValidationMessage !== '' &&
      isSetDate
    ) {
      setSelectResult(
        <span
          className="d-flex position-relative mx-auto  justify-content-center fs-6"
          style={{ maxWidth: '550px' }}
        >
          <strong> {orderItemValidationMessage}</strong>
        </span>
      );
    }
  }, [isSetDate, orderItemIsSuccess, orderItemValidationMessage]);

  useEffect(() => {
    if (selectLaterResult !== '' && orderItemValidationMessage === '') {
      toast.info(selectLaterResult, {
        onClose: () => {
          setSelectResult('');
          setChildData([]);
          setTableData([]);
          navigateMe(2);
          dispatch(Actions.resetStoreAction.resetStore());
        },
      });
    } else if (selectLaterResult !== '' && orderItemValidationMessage !== '') {
      toast.error(selectLaterResult, {
        onClose: () => {
          setSelectResult('');
          setChildData([]);
          setTableData([]);
          navigateMe(2);
          dispatch(Actions.resetStoreAction.resetStore());
        },
      });
    }
  }, [selectLaterResult]);

  function removeSelectedIndex(index) {
    if (index !== null) {
      const updatedData = tableData.filter((_, i) => i !== index);
      setChildData(updatedData);
      setRemovedIndex(index);
    }
  }

  function addSelected(value) {
    setChildData(value);
    setAddData(true);
  }
  useEffect(() => {
    if (addData) {
      setTableData(childData);
      setAddData(false);
      setRemovedIndex(null);
    }
    if (removedIndex !== null) {
      setTableData(childData);
      setRemovedIndex(null);
      setAddData(false);
    }
    if (childData?.length === 0) {
      setChildData(null);
    }
  }, [addData, childData, removedIndex]);

  useEffect(() => {
    if (filter.UserArea === 1) {
      dispatch(
        Actions.locationActions.getLocationsByUserAreaAction({
          userArea: 1,
        })
      );
    }
    if (isKids && ageInterval.length === 0 && !hideRule) {
      dispatch(Actions.ageIntervalActions.getAllAction(2));
    }
  }, [filter.UserArea, hideRule]);

  useEffect(() => {
    const { strTime, endTime } = filterTimeSet();
    if (isRecompense) {
      const apiCallObject = { ...getMeIndex?.filterData };
      apiCallObject.AttendeeId = attendeeId;
      apiCallObject.CompanyId = getMeIndex?.selectLaterItems?.companyId;
      apiCallObject.StartDateTime = strTime;
      apiCallObject.EndDateTime = endTime;
      apiCallObject.AgeId =
        getMeIndex?.selectLaterItems?.ageId === 4 ? 4 : null;
      apiCallObject.IsRecompense = true;

      setFilter(apiCallObject);
    } else if (isSetDate) {
      setFilter({
        ...filter,
        StartDateTime: strTime,
        EndDateTime: endTime,
        LanguageLevels:
          getMeIndex?.selectLaterItems?.languageLevel !== null
            ? getMeIndex?.selectLaterItems?.languageLevel
            : null,
        Locations: [getMeIndex?.selectLaterItems?.location],
        TypeOfService: [
          getMeIndex?.selectLaterItems?.productType === 'Private' ? 0 : 1,
        ],
        UserArea: getMeIndex?.selectLaterItems?.userArea,
        PackageId: getMeIndex?.selectLaterItems?.packageId,
        AttendeeId: attendeeId,
        TrialStatus: getMeIndex?.selectLaterItems?.trialStatus,
        CompanyId: getMeIndex?.selectLaterItems?.companyId,
        AgeId: getMeIndex?.selectLaterItems?.ageId === 4 ? 4 : null,
      });
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        StartDateTime: strTime,
        EndDateTime: endTime,
        UserArea: 0,
      }));
    }

    setRequest(true);
  }, [hideRule, isRecompense, isSetDate]);

  async function getAllSpeakersWithFilter() {
    await dispatch(Actions.calendarActions.getFilteredAction(filter));
    await dispatch(Actions.calendarActions.getFiltredSpeakersAction(filter));
  }
  useEffect(() => {
    if (filter.StartDateTime !== null && request) {
      getAllSpeakersWithFilter();
    }
  }, [filter, request]);

  function extractSpeakerDetails(list) {
    return (
      list?.map((item) => ({
        SpeakerName: item.speakerName,
        SpeakerId: item.speakerId,
        SpeakerImage: item.speakerImage,
        SpeakerTitle: item.speakerTitle,
      })) || []
    );
  }

  useEffect(() => {
    extractSpeakerDetails(onlyMentors);
  }, [onlyMentors]);

  const handleSelectedSpeakers = (value, date) => {
    if (filter.SelectedIds === null) {
      setFilter({
        ...filter,
        SelectedIds: [value],
        DateTime: date,
      });
    } else {
      const isSelected = filter?.SelectedIds?.includes(value);
      const updatedSelectedIds = isSelected
        ? filter?.SelectedIds?.filter((id) => id !== value)
        : [...filter.SelectedIds, value];
      if (updatedSelectedIds?.length === 0) {
        setFilter({ ...filter, SelectedIds: null });
      } else {
        setFilter({
          ...filter,
          SelectedIds: updatedSelectedIds,
          DateTime: date,
        });
        dispatch(
          Actions.calendarActions.getFilteredAction({
            ...filter,
            DateTime: date,
          })
        );
      }
    }
  };
  const onClickColor = (set) => {
    set === 1
      ? setColor(undefined)
      : set === 2
      ? setColor('Private')
      : setColor('Group');
    set === 2 && color === 'Private'
      ? setColor(undefined)
      : set === 3 && color === 'Group'
      ? setColor(undefined)
      : (set = set);
  };
  const [selectedCell, setSelectedCell] = useState(null);
  const handleCellSelect = (cellValue) => {
    setSelectedCell(cellValue);
  };
  const handleSelectChange = (target, isMulti = false, takeKey = false) => {
    setShowLoader(true);
    let name = target.name;
    let value = target.value;
    const optionValue = takeKey ? target.key : parseInt(value);

    if (isMulti) {
      const updatedArray = [...(filter[name] || [])];
      const valueIndex = updatedArray.indexOf(optionValue);

      if (valueIndex !== -1) {
        updatedArray.splice(valueIndex, 1);
      } else {
        updatedArray.push(optionValue);
      }
      setFilter({
        ...filter,
        [name]: updatedArray.length > 0 ? updatedArray : null,
      });
    }
    if (!isMulti) {
      if (name === 'UserArea') {
        if (filter[name] !== optionValue) {
          setFilter({
            ...filter,
            [name]: optionValue,
            TypeOfService: null,
            People: null,
            LanguageLevels: null,
            Locations: null,
            FullName: null,
            AgeId: null,
          });
        }
      } else {
        if (filter[name] === optionValue) {
          setFilter({
            ...filter,
            [name]: null,
          });
        } else {
          setFilter({
            ...filter,
            [name]: optionValue,
          });
        }
      }
    }
  };

  const onKeyPress = (e) => {
    const { value } = e.target;
    if (e.key === 'Enter') {
      const newValue = value.trim() === '' ? null : value;
      setFilter({ ...filter, FullName: newValue });
    }
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const newValue = value.trim() === '' ? null : value;
    setSearchValue(newValue);
  };
  const onSearchClick = (e) => {
    e.preventDefault();
    const inputValue = document.getElementById('FullName').value;
    const newValue = inputValue.trim() === '' ? null : inputValue;
    setFilter({ ...filter, FullName: newValue });
  };

  const handleContinue = async () => {
    if (getMeIndex?.selectLaterItems?.timesPerWeek !== tableData.length) {
      const message = `${tableData.length}/${
        getMeIndex?.selectLaterItems?.timesPerWeek
      } ${t('button.select_session_to_continue')}`;
      setNotifyMesage(
        <div>
          <span
            className="d-flex position-relative mx-auto justify-content-center fs-6"
            style={{ maxWidth: '550px' }}
          >
            <strong>{t('calendar.you_must_complete_your_selections')}</strong>
          </span>
          <span
            className="d-flex position-relative mx-auto justify-content-center fs-6"
            style={{ maxWidth: '550px' }}
          >
            <strong>{message}</strong>
          </span>
        </div>
      );
    }
    if (getMeIndex?.selectLaterItems?.timesPerWeek === tableData.length) {
      const requestData = {
        SelectionOptions: tableData,
        OrderItemId: getMeIndex?.selectLaterItems?.orderItemId,
        AttendeeId: attendeeId,
      };
      dispatch(Actions.orderActions.orderSetDateAction(requestData));
    }
  };

  const headers = [
    { key: 'speakerName', label: t('calendar.table_mentor') },
    {
      key: 'sessionDate',
      label: t('calendar.table_session_date'),
    },
    { key: 'sessionContent', label: t('calendar.table_session') },
    { key: 'sessionCount', label: t('calendar.table_session_count') },
    { key: '', label: '' },
  ];
  const customToastStyle = {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: 10000,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const [notifyMessage, setNotifyMesage] = useState('');
  useEffect(() => {
    if (notifyMessage) {
      toast.warn(notifyMessage, {
        onClose: () => {
          setNotifyMesage('');
        },
      });
    }
  }, [notifyMessage]);

  useEffect(() => {
    return () => {
      dispatch(Actions.calendarActions.cleanState(true));
    };
  }, []);

  const selectedLanguageLevels = [];
  if (
    levelGroup &&
    levelGroup?.adult &&
    levelGroup?.kids &&
    filter.UserArea !== undefined
  ) {
    if (filter?.UserArea === 2) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.kids]
      );
    } else if (filter?.UserArea !== 2 && filter?.UserArea !== undefined) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    }
  }

  function setAreaName() {
    let txt = '';
    switch (filter?.UserArea) {
      case 0:
        txt = 'UserArea.0';
        break;
      case 1:
        txt = 'UserArea.1';
        break;
      case 2:
        txt = 'UserArea.2';
        break;
      case 3:
        txt = 'UserArea.3';
        break;

      default:
        txt = 'landing.filter_session_type';
        break;
    }
    return txt;
  }

  const content = (
    <div className="container-fluid mb-5">
      <div className="row">
        {!hideRule && (
          <div className="col-12">
            <Card
              body={
                <div className="col-12">
                  <div
                    className={`d-lg-flex flex-row align-items-center filter-section`}
                  >
                    <div className="col-sm-8 col-md-6 col-lg-4 flex-fill mb-sm-1">
                      <Dropdown
                        key={'schedule_userArea'}
                        id={'userArea'}
                        title={t(setAreaName(), {
                          lng: 'en',
                        })}
                        propNames={{
                          key: 'name',
                          value: 'value',
                          name: 'key',
                        }}
                        options={userArea}
                        selectedOptions={userArea?.filter(
                          (val) => filter?.UserArea === parseInt(val.value)
                        )}
                        handleChange={(target) => handleSelectChange(target)}
                        rounded={2}
                        svgWidth={35}
                        svg={meet2talkButton}
                        arrowUp
                        isFilter
                      />
                    </div>
                    {!isCafe && (
                      <div className="col-sm-8 col-md-6 col-lg-4 flex-fill mb-sm-1">
                        <Dropdown
                          key={'schedule_typeOfService'}
                          id={'typeofService'}
                          title={t('attendeeDashboard.type_of_service')}
                          propNames={{
                            key: 'name',
                            value: 'value',
                            name: 'key',
                          }}
                          options={typeOfService}
                          selectedOptions={typeOfService.filter((service) =>
                            filter?.TypeOfService?.includes(
                              parseInt(service.value)
                            )
                          )}
                          handleChange={(target) =>
                            handleSelectChange(target, true)
                          }
                          rounded={2}
                          svgWidth={35}
                          svg={typeOfServiceSVG}
                          arrowUp
                          isFilter
                          isMulti
                          resetState={filter.TypeOfService === null}
                        />
                      </div>
                    )}
                    {(isCafe || isCompany) && (
                      <div className="col-sm-8 col-md-6 col-lg-4 flex-fill mb-sm-1">
                        <Dropdown
                          key={'schedule_locations'}
                          id={'locations'}
                          title={t('attendeeDashboard.locations')}
                          propNames={{
                            key: 'key',
                            value: 'value',
                            name: 'key',
                          }}
                          options={locations}
                          selectedOptions={locations.filter((service) =>
                            filter?.Locations?.includes(parseInt(service.value))
                          )}
                          handleChange={(target) =>
                            handleSelectChange(target, true)
                          }
                          rounded={2}
                          svgWidth={35}
                          svg={typeOfServiceSVG}
                          arrowUp
                          isFilter
                          isMulti
                          resetState={filter.Locations === null}
                        />
                      </div>
                    )}
                    {isKids && (
                      <div className="col-sm-8 col-md-6 col-lg-4 flex-fill mb-sm-1">
                        <Dropdown
                          key={'schedule_ageInterval'}
                          id={'ageInterval'}
                          title={
                            filter?.AgeId === null ||
                            filter?.AgeId === undefined ||
                            ageInterval?.filter(
                              (f) => f.value === filter?.AgeId
                            )?.[0]?.key === undefined
                              ? t('attendeeDashboard.ageInterval')
                              : `${
                                  ageInterval?.filter(
                                    (f) => f.value === filter?.AgeId
                                  )?.[0]?.key
                                } ${t('attendeeDashboard.age')}`
                          }
                          propNames={{
                            key: 'name',
                            value: 'value',
                            name: 'key',
                          }}
                          options={ageInterval}
                          handleChange={(target) => handleSelectChange(target)}
                          rounded={2}
                          svgWidth={35}
                          svg={typeOfServiceSVG}
                          arrowUp
                          isFilter
                          resetState={filter.AgeId === null}
                        />
                      </div>
                    )}
                    <div className="col-sm-8 col-md-6 col-lg-4 flex-fill mb-sm-1">
                      <Dropdown
                        key={'schedule_languageLevels'}
                        id={'languageLevels'}
                        title={t(
                          filter?.LanguageLevels === null ||
                            filter?.LanguageLevels === undefined
                            ? 'attendeeDashboard.language_level'
                            : filter?.LanguageLevels
                        )}
                        propNames={{
                          key: 'key',
                          value: 'value',
                          name: 'key',
                        }}
                        options={selectedLanguageLevels}
                        handleChange={(target) =>
                          handleSelectChange(target, false, true)
                        }
                        rounded={2}
                        svgWidth={35}
                        svg={sessionLevelSVG}
                        arrowUp
                        isFilter
                        addIndex
                        resetState={filter.LanguageLevels === null}
                      />
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-4 flex-fill mb-sm-1">
                      <Dropdown
                        key={'schedule_native'}
                        id={'native'}
                        title={t('attendeeDashboard.native')}
                        propNames={{
                          key: 'key',
                          value: 'value',
                          name: 'key',
                        }}
                        options={native}
                        selectedOptions={native.filter((service) =>
                          filter?.Native?.includes(parseInt(service.value))
                        )}
                        handleChange={(target) =>
                          handleSelectChange(target, true)
                        }
                        rounded={2}
                        svgWidth={35}
                        svg={servicesSVG}
                        arrowUp
                        isFilter
                        isMulti
                        resetState={filter.Native === null}
                      />
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-4 flex-fill mb-sm-1">
                      <Dropdown
                        key={'schedule_learningPurpose'}
                        id={'learningPurpose'}
                        title={t('attendeeDashboard.learning_purpose')}
                        propNames={{
                          key: 'key',
                          value: 'value',
                          name: 'key',
                        }}
                        options={learningPurpose}
                        selectedOptions={learningPurpose.filter((service) =>
                          filter?.LearningPurpose?.includes(
                            parseInt(service.value)
                          )
                        )}
                        handleChange={(target) =>
                          handleSelectChange(target, true)
                        }
                        rounded={2}
                        svgWidth={35}
                        svg={learningPurposeSVG}
                        arrowUp
                        isFilter
                        isMulti
                        resetState={filter.LearningPurpose === null}
                      />
                    </div>
                    <div className="flex-fill">
                      <div className="d-flex justify-content-evenly">
                        <Input
                          key={'schedule_fullName'}
                          id="FullName"
                          className={
                            'col-12 me-1 bg-m2t border-0 medium border-bottom border-secondary'
                          }
                          type={InputType.Text}
                          placeholder={t('attendeeDashboard.search_mentors')}
                          value={searchValue}
                          onKeyPress={onKeyPress}
                          onChange={onChangeSearch}
                          style={{ fontSize: '.86rem' }}
                        />
                        <Button
                          icon={ButtonIcon.Search}
                          borderless
                          iconColor={TextColors.Success}
                          onClick={onSearchClick}
                          buttonCol={1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        )}
        {hideRule && (
          <div className={`d-flex justify-content-center`}>
            <div className="d-flex align-items-center">
              <Input
                key={'schedule_fullName'}
                id="FullName"
                className={
                  'col-12 me-1 bg-m2t border-0 medium border-bottom border-secondary'
                }
                type={InputType.Text}
                placeholder={t('attendeeDashboard.search_mentors')}
                value={searchValue}
                onKeyPress={onKeyPress}
                onChange={onChangeSearch}
                style={{ fontSize: '.86rem' }}
              />
              <Button
                icon={ButtonIcon.Search}
                borderless
                iconColor={TextColors.Success}
                onClick={onSearchClick}
                buttonCol={1}
              />
            </div>
          </div>
        )}
        <div className="col-12">
          <Card
            padding={0}
            body={
              <>
                <div className="col-md-6 col-12 ms-auto">
                  {tableData?.length !== 0 && tableData !== undefined ? (
                    <Card
                      padding={0}
                      body={
                        <>
                          <div className="row">
                            <div className="col-12">
                              <BasicTable
                                key={'setDateTable'}
                                data={tableData}
                                headers={headers}
                                onClickFuncSetDate={handleContinue}
                                labelFunc={(val) => removeSelectedIndex(val)}
                                labelProp={''}
                                oncalendar={true}
                              />
                              {tableData?.length > 0 && (
                                <div className="d-flex justify-content-end">
                                  <Button
                                    id={'bo_continue'}
                                    type={ButtonType.Successbg}
                                    text={t('button.continue')}
                                    onClick={handleContinue}
                                    textColor={TextColors.Light}
                                    buttonCol={3}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    />
                  ) : undefined}
                </div>
                <div>
                  <WeeklyCalendar
                    area={filter.UserArea}
                    filter={filter}
                    getMeIndex={(val) => navigateMe(val)}
                    modalData={filter.SelectedIds}
                    attendeeId={attendeeId}
                    recompenseOrderItemId={getMeIndex?.orderItemId}
                    recompenseMeetingAttendeeId={getMeIndex?.meetingAttendeeId}
                    color={color}
                    fromRecompense={isRecompense}
                    fromSetDate={isSetDate}
                    removeAction={removedIndex}
                    recompenseDate={getMeIndex?.filterData?.StartDateTime}
                    selectLaterState={getMeIndex?.selectLaterItems}
                    onCellSelect={handleCellSelect}
                    removeWithSelect={(val) => {
                      removeSelectedIndex(val);
                    }}
                    getTableData={(val) => {
                      addSelected(val);
                    }}
                    getSelectedDate={(val) => {
                      if (val.popup) {
                        const date = addDay(val.time, val.filter, true);
                        const [year, month, day] = date
                          .split('T')[0]
                          .split('-')
                          .map(Number);
                        const nDate = `${year}-${month
                          .toString()
                          .padStart(2, '0')}-${day
                          .toString()
                          .padStart(2, '0')}T${date.split('T')[1]}`;
                        let changes = {
                          ...filter,
                          StartDateTime: nDate,
                        };
                        if (filter.SelectedIds === null) {
                          const arr = [];
                          val.ids
                            .split(',')
                            .forEach((el) => arr.push(parseInt(el.trim())));
                          changes.SelectedIds = arr;
                        } else if (filter.SelectedIds === undefined) {
                          const arr = [];
                          val.ids
                            .split(',')
                            .forEach((el) => arr.push(parseInt(el.trim())));
                          changes = {
                            ...changes,
                            SelectedIds: arr,
                          };
                        }
                        dispatch(
                          Actions.calendarActions.getCellDetailsAction(changes)
                        );
                      } else if (!val.popup) {
                        const { strTime, endTime } = filterTimeSet(
                          val.filter.split('T')[0]
                        );
                        const changes = {
                          ...filter,
                          StartDateTime: strTime,
                          EndDateTime: endTime,
                        };
                        setFilter(changes);
                      }
                    }}
                    modalWithSearch={
                      <ModalWithSearch
                        isSearchable
                        styleProps={{ maxWidth: 550 }}
                        placeHolder={t('mentor.speakers')}
                        options={extractSpeakerDetails(onlyMentors)}
                        selectedProps={{
                          key: 'SpeakerName',
                          value: 'SpeakerId',
                        }}
                        onChange={(value) => {
                          handleSelectedSpeakers(value, filter?.StartDateTime);
                        }}
                        isMulti
                        searchWithChild={(val) => {
                          let text = val === '' ? null : val;
                          setFilter({ ...filter, FullName: text });
                        }}
                        resetState={filter.SelectedIds === null ? true : false}
                        isCalendarLabel
                      />
                    }
                  />
                </div>
                {!hideRule && (
                  <div
                    className="d-flex text-nowrap justify-content-between mt-3"
                    style={{ fontSize: '1em' }}
                  >
                    <div className="col-6 p-1" style={{ fontSize: '1em' }}>
                      <Button
                        type={ButtonType.Success}
                        textColor={TextColors.Light}
                        text={t('button.one_to_one')}
                        size={ButtonSize.Small}
                        bold
                        onClick={() => {
                          onClickColor(2);
                        }}
                      />
                    </div>
                    <div className="col-6 p-1">
                      <Button
                        type={ButtonType.Warning}
                        textColor={TextColors.Light}
                        text={t('button.group')}
                        bold
                        size={ButtonSize.Small}
                        onClick={() => {
                          onClickColor(3);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            }
          />
          {(notifyMessage !== '' || selectLaterResult !== '') && (
            <ToastContainer
              style={customToastStyle}
              autoClose={false}
              closeOnClick
              theme="light"
            />
          )}
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.calendar.update,
    content
  );
}
