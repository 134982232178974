import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(endPoint.manager.CREATE_MANAGER, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.manager.UPDATE_MANAGER}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.manager.REMOVE_MANAGER}/${id}`);
};
export const getById = async (id = '') => {
  return await getRequest(
    `${endPoint.manager.GETBYID_MANAGER}${id !== '' ? `/${id}` : ''}`
  );
};
export const getAll = async (requestType = '') => {
  const typeData = typeof requestType === 'object' ? true : false;
  if (typeData || requestType === '') {
    return await postRequest(
      `${endPoint.manager.GETALL_MANAGER}`,
      typeData ? requestType : null
    );
  } else if (requestType !== '' && !typeData) {
    return await getRequest(
      `${endPoint.manager.GETALL_MANAGER}${`/${requestType}`}`
    );
  }
};
