import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const speakerEffortReducer = (state = initialState.speakerEffort, action) => {
    switch (action.type) {
        case ActionTypes.speakerEffort.GET_DETAILS_ACTION_SPEAKEREFFORT:
            return {
                ...state,
                getById: action.payload.data,
            };
        case ActionTypes.speakerEffort.GET_ALL_ACTION_SPEAKEREFFORT:
            return {
                ...state,
                getAll: action.payload.data,
            };
        case ActionTypes.speakerEffort.HANDLE_ERROR_SPEAKEREFFORT:
            return {
                ...state,
                isSuccess: action.payload,
            };
        case ActionTypes.speakerEffort.HANDLE_VALIDATION_SPEAKEREFFORT:
            return {
                ...state,
                validationMessage: action.payload,
            };

        default:
            return state;
    }
};

export default speakerEffortReducer;
