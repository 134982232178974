import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const sessionPackagesReducer = (
  state = initialState.sessionPackage,
  action
) => {
  switch (action.type) {
    case ActionTypes.sessionPackage.CREATE_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        sessionPackage: initialState.sessionPackage.sessionPackage,
      };
    case ActionTypes.sessionPackage.UPDATE_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        sessionPackage: {},
        getById: {},
      };
    case ActionTypes.sessionPackage.GETBYID_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.sessionPackage.GETALL_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.sessionPackage.HOLD_VALUE_SESSIONPACKAGE:
      return {
        ...state,
        sessionPackage: action.payload,
      };
    case ActionTypes.sessionPackage.HANDLE_ERROR_SESSIONPACKAGE:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default sessionPackagesReducer;
