import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Common/Button/Button';

import { ButtonType, ButtonSize } from '../../../../../utils/ComponentEnums';
import LetUsCallYou from '../LetUsCallYou';
import { useNavigate } from 'react-router-dom';

const Maslak = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Maslak İngilizce Konuşma Kulubü - Meet2Talk';

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Meet2Talk Maslak Şubemizde İngilizceyi ana dili İngilizce mentörler eşliğinde ve sosyal bir ortamda konuşarak, eğlenerek ve pratik yaparak öğrenin!'
      );
    }
  }, []);

  const handleFreeTrialClick = () => {
    navigate('/cafe/order/triallesson', {
      state: {
        fromNavigateWhere: 'freeTrial',
        trialStatus: 0,
      },
    });
  };

  return (
    <div>
      <section className="py-5 text-center container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 mt-lg-5 ">
            <h1 className="display-4 pb-4  ">Meet2Talk Maslak</h1>
            <h4 className=" py-2">
              Kollektif House Maslak <br /> Kat: 10 Oda: 1031-1033
              <br /> 42 Maslak, Ahi Evran Cd. No:6, 34485 Sarıyer/İstanbul
            </h4>
            <a className="py-2" href="tel:+905524865240">
              <h4>0552 486 52 40</h4>
            </a>
            <div className=" d-flex justify-content-center py-2">
              <Button
                text={t('landingMainBanner.get_trial')}
                textColor="text-white"
                type={ButtonType.LandingGreenBg}
                border
                ButtonSize={ButtonSize.Small}
                buttonCol={6}
                onClick={() => handleFreeTrialClick()}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-5">
            <div className="ratio ratio-4x3 ">
              <iframe
                className="embed-responsive-item rounded-4 "
                src={'https://www.youtube.com/embed/DsGSg5_WW7o'}
                title="Meet2Talk"
                allowFullScreen
                style={{ boxShadow: ' 9px 9px #00c905' }}
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <div className="album py-5 text-center">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/maslak_1.JPG"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/maslak_2.JPG"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5 ">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/maslak_3.JPG"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/maslak_4.JPG"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/maslak_5.JPG"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/maslak_6.JPG"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5">
        <LetUsCallYou
          iframe={
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d192748.57771033983!2d28.8798448!3d40.9881256!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab5aca1d105b3%3A0xbf4ef43a3cbdfde9!2sMeet%202%20Talk%20Cafe%20Maslak!5e0!3m2!1str!2str!4v1710748062430!5m2!1str!2str'
          }
          area={1}
          location={'Maslak'}
        />
      </div>
    </div>
  );
};

export default Maslak;
