import React, { useEffect } from 'react';
import styles from '../../../assets/Styles.module.scss';

const VideoModal = ({ videoUrl, styleProps, onClose }) => {
  const keydownHandler = ({ key }) => {
    switch (key) {
      case 'Escape':
        onClose();
        break;
      default:
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });
  return (
    <div className={styles.modal} onClick={onClose}>
      <div
        className={`rounded-5  ${styles.modal_dialog}`}
        style={{
          maxWidth: `${styleProps?.maxWidth}px `,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <iframe
          className="embed-responsive-item "
          src={videoUrl}
          title="Meet2Talk"
          allowFullScreen
          style={{
            maxWidth: `${styleProps?.maxWidth}px`,
            height: `${styleProps?.height}px`,
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
