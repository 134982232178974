import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import myArrowDown from '../../../assets/images/down.svg';
import useClickOutside from '../../../hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

export default function Dropdown({
  buttonCol,
  propNames,
  rounded,
  svg,
  svgWidth,
  bordered,
  id,
  options,
  handleChange,
  icon,
  title,
  arrowUp,
  backGroundAll = '',
  textColor = '',
  rightIcon,
  rightArrowUp,
  arrowSVG,
  isMulti = false,
  isFilter,
  svgRounded,
  group,
  selectedOptions,
  noClickAble,
  forCalendar,
  forLang,
  noEffect = false,
  keepState = false,
  resetState = false,
  addIndex = false,
  fontSize,
}) {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : {});

  useEffect(() => {
    if (
      selectedOptions !== null &&
      selectedOptions !== undefined &&
      selectedOptions?.length > 0 &&
      (selectedValue.length === 0 || Object.keys(selectedValue).length === 0)
    ) {
      setSelectedValue(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (resetState) {
      setSelectedValue(isMulti ? [] : {});
    }
  }, [resetState]);

  let checkIcon = '';
  let checkRightArrow = '';
  arrowSVG
    ? (arrowSVG = <img src={myArrowDown} alt=""></img>)
    : (arrowSVG = undefined);
  clicked
    ? (checkIcon = <FontAwesomeIcon icon={faChevronUp} />)
    : (checkIcon = <FontAwesomeIcon icon={faChevronDown} />);
  clicked
    ? (checkRightArrow = <FontAwesomeIcon className="" icon={faSortUp} />)
    : (checkRightArrow = <FontAwesomeIcon className="" icon={faSortDown} />);
  let iconElement = null;
  let rightIconElement = null;
  let className = 'dropdown-menu';
  let setArrows = arrowUp ? checkIcon : null;
  let setRightArrows = rightArrowUp ? checkRightArrow : null;
  let buttonclassName = 'btn border-0';

  const dropdownRef = useClickOutside(() => {
    if (clicked) {
      setClicked(false);
    }
  });
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const justifyContentClass =
    isMobile && !forLang ? 'justify-content-start' : 'justify-content-center';
  switch (icon) {
    case undefined:
      iconElement = null;
      break;
    case icon:
      iconElement = (
        <span className="ms-2">
          <FontAwesomeIcon icon={icon} />
        </span>
      );
      //iconElement = icon.prefix === "fas" ? <FontAwesomeIcon className='ms-2' icon={icon} /> : icon;
      break;
    default:
      break;
  }
  switch (rightIcon) {
    case undefined:
      rightIconElement = null;
      break;
    case rightIcon:
      rightIconElement = (
        <span>
          <FontAwesomeIcon icon={rightIcon} />
        </span>
      );
      break;
    default:
      break;
  }
  const removeOption = (option) => {
    if (group === undefined) {
      return selectedValue.filter(
        (o) => !o[propNames.value] === option[propNames.value]
      );
    } else if (group !== undefined) {
      return selectedValue.filter(
        (o) =>
          !(
            o[propNames.value] === option[propNames.value] &&
            o.name === option.name
          )
      );
    }
  };

  const onItemClick = (e, option) => {
    e.stopPropagation();
    if (isMulti && group === undefined) {
      let newValue;
      if (
        selectedValue.findIndex(
          (o) => o[propNames.value] === option[propNames.value]
        ) >= 0
      ) {
        newValue = removeOption(option);
        setSelectedValue(newValue);
      } else {
        newValue = [...selectedValue, option];
        setSelectedValue(newValue);
      }
    } else if (isMulti && group !== undefined) {
      let newValue;
      if (
        selectedValue.findIndex(
          (o) =>
            o[propNames.value] === option[propNames.value] &&
            o.group === option.group
        ) >= 0
      ) {
        newValue = removeOption(option);
        setSelectedValue(newValue);
      } else {
        newValue = [...selectedValue, option];
        setSelectedValue(newValue);
      }
    } else if (!isMulti) {
      if (selectedValue?.value === option.value) {
        if (!keepState) {
          setSelectedValue({});
        } else {
          if (!isMobile) {
            setClicked(false);
          }
          return;
        }
      } else {
        setSelectedValue(option);
      }
    }
    handleChange(option);
  };

  const isSelected = (option) => {
    if (isMulti && group === undefined) {
      return (
        selectedValue?.filter(
          (o) => o?.[propNames?.value] === option?.[propNames?.value]
        ).length > 0
      );
    } else if (isMulti && group !== undefined) {
      return (
        selectedValue?.filter(
          (o) =>
            o?.[propNames?.value] === option?.[propNames?.value] &&
            o?.group === option?.group
        ).length > 0
      );
    } else if (isMulti && group !== undefined) {
      return (
        selectedValue?.filter(
          (o) =>
            o[propNames.value] === option[propNames.value] &&
            o.group === option.group
        ).length > 0
      );
    }
    if (!selectedValue) {
      return false;
    }

    return group === undefined
      ? selectedValue[propNames.value] === option[propNames.value] ||
          selectedValue?.[0]?.[propNames.value] === option[propNames.value]
      : (selectedValue[propNames.value] === option[propNames.value] &&
          selectedValue.group === option.group) ||
          (selectedValue?.[0]?.[propNames.value] === option[propNames.value] &&
            selectedValue?.[0]?.group === option.group);
  };

  const debouncedTouchStart = useCallback(
    _debounce((e, option) => {
      e.stopPropagation();

      if (isFilter) {
        onItemClick(e, option);
      } else {
        handleChange(e.currentTarget || e.target);
      }
      setClicked(false);
    }, 80),
    [isFilter, onItemClick, handleChange]
  );

  return (
    <div ref={dropdownRef}>
      <div
        key={`${id}_keys`}
        role={noClickAble ? 'none' : 'button'}
        id={id}
        data-bs-toggle={!noClickAble ? 'dropdown' : ''}
        data-bs-auto-close="outside"
        aria-expanded="false"
        style={{ position: 'relative' }}
        className={`d-flex align-items-center ${justifyContentClass}  ${textColor} ${backGroundAll}
      ${bordered ? ' border border-1 border-success rounded-2' : ''} ${
          rounded ? `rounded-${rounded}` : ''
        }`}
      >
        <div
          className="d-flex align-items-center text-nowrap"
          style={{ height: isMobile ? '25px' : '' }}
        >
          {svg ? (
            <div
              onClick={() => {
                if (!noClickAble || noClickAble === undefined) {
                  setClicked(true);
                }
              }}
              style={{ position: 'relative' }}
            >
              {isMulti && (
                <span className="position-absolute top-0 end-0 badge rounded-pill bg-danger">
                  {selectedValue?.length || ''}
                </span>
              )}
              <img
                className={`rounded-${svgRounded}`}
                src={svg}
                width={svgWidth}
                alt={svg}
              />
            </div>
          ) : (
            iconElement
          )}
          {title && (
            <span
              onClick={() => {
                if (!noClickAble || noClickAble === undefined) {
                  setClicked(true);
                }
              }}
              className={`${backGroundAll} d-inline-flex ms-1`}
              style={{
                fontSize: fontSize !== undefined ? `${fontSize}px` : '',
              }}
            >
              {title}
            </span>
          )}
          <span
            onClick={() => {
              if (!noClickAble || noClickAble === undefined) {
                setClicked(true);
              }
            }}
            className={`${
              (!noClickAble || noClickAble === undefined) && buttonclassName
            } ${textColor} ${buttonCol} d-flex px-1`}
          >
            <span
              className="pe-sm-1"
              style={{ fontSize: isMobile ? (arrowUp ? '12px' : '13px') : '' }}
            >
              {setArrows}
              {setRightArrows}
              {rightIconElement}
            </span>
          </span>
          <span className="d-flex">{arrowSVG}</span>
        </div>
      </div>
      {options && (
        <div
          className={clicked ? `${className} show` : `${className} hide`}
          aria-labelledby={id}
          style={{
            position: 'absolute',
          }}
        >
          {group
            ? options
                .reduce(
                  (groups, option) => {
                    const groupIndex = groups.findIndex(
                      (group) => group.title === option.group
                    );
                    if (groupIndex === -1) {
                      groups.push({ title: option.group, options: [option] });
                    } else {
                      groups[groupIndex].options.push(option);
                    }
                    return groups;
                  },
                  [{ title: null, options: [] }]
                )
                .map((group, indexGroup) => (
                  <div key={`${group.title}_${indexGroup}`}>
                    {group.title && (
                      <span className=" fw-bold mt-2 ms-2">
                        {t(`${group.title}`)}
                      </span>
                    )}
                    {propNames !== undefined
                      ? group.options.map((option, index) => (
                          <button
                            key={`${group.title}-${option.id}-${index}`}
                            className={`dropdown-item ${
                              isFilter && isSelected(option)
                                ? 'rounded-3 bg-primary text-white'
                                : ''
                            }`}
                            style={{ marginBottom: '3px' }}
                            id={
                              option[propNames.title]
                                ? option[propNames.title]
                                : option[propNames.name]
                            }
                            value={`${group.title}-${option.id}-${index}`}
                            onTouchStart={(e) => {
                              if (isMobile) {
                                e.stopPropagation();
                                if (isMulti) {
                                  if (isFilter) {
                                    onItemClick(e, option);
                                  } else {
                                    handleChange(e.currentTarget);
                                    setClicked(false);
                                  }
                                } else {
                                  debouncedTouchStart(e, option);
                                }
                              }
                            }}
                            onClick={(e) => {
                              if (!isMobile) {
                                e.stopPropagation();
                                if (isFilter) {
                                  if (!isMulti) {
                                    setClicked(false);
                                  }
                                  onItemClick(e, option);
                                } else {
                                  handleChange(e.currentTarget);
                                  setClicked(false);
                                }
                              }
                            }}
                          >
                            {addIndex
                              ? `${index + 1}- ${t(
                                  `${option?.[propNames?.name]}`
                                )}`
                              : t(`${option?.[propNames?.name]}`)}
                          </button>
                        ))
                      : undefined}
                  </div>
                ))
            : forCalendar
            ? options?.map((option, index) => (
                <li
                  role="button"
                  key={`${option.key}_${index}_${index + 1}`}
                  className={`dropdown-item ${
                    !noEffect && isFilter && isSelected(option)
                      ? 'rounded-3 bg-primary text-white'
                      : ''
                  }`}
                  title={
                    option[propNames.title]
                      ? option[propNames.title]
                      : option[propNames.name]
                  }
                  style={{ marginBottom: '3px' }}
                  id={id}
                  value={option[propNames.value]}
                  onTouchStart={(e) => {
                    if (isMobile) {
                      e.stopPropagation();
                      if (isMulti) {
                        if (isFilter) {
                          onItemClick(e, option);
                        } else {
                          handleChange(e.currentTarget);
                          setClicked(false);
                        }
                      } else {
                        debouncedTouchStart(e, option);
                      }
                    }
                  }}
                  onClick={(e) => {
                    if (!isMobile) {
                      e.stopPropagation();
                      if (isFilter) {
                        if (!isMulti) {
                          setClicked(false);
                        }
                        onItemClick(e, option);
                      } else {
                        handleChange(e.currentTarget);
                        setClicked(false);
                      }
                    }
                  }}
                  href="#"
                >
                  {option[propNames.name]}
                </li>
              ))
            : options?.map((option, index) => (
                <li
                  role="button"
                  key={`${option?.key}_${index}_${index + 1}`}
                  className={`dropdown-item ${
                    isFilter && isSelected(option)
                      ? 'rounded-3 bg-primary text-white'
                      : ''
                  }`}
                  id={
                    option?.[propNames?.title]
                      ? option?.[propNames?.title]
                      : option?.[propNames?.name]
                  }
                  style={{ marginBottom: '3px' }}
                  value={option?.[propNames?.value]}
                  lang={option?.[propNames?.path]}
                  onTouchStart={(e) => {
                    if (isMobile) {
                      e.stopPropagation();
                      if (isMulti) {
                        if (isFilter) {
                          onItemClick(e, option);
                        } else {
                          handleChange(e.currentTarget);
                          setClicked(false);
                        }
                      } else {
                        debouncedTouchStart(e, option);
                      }
                    }
                  }}
                  onClick={(e) => {
                    if (!isMobile) {
                      e.stopPropagation();
                      if (isFilter) {
                        if (!isMulti) {
                          setClicked(false);
                        }
                        onItemClick(e, option);
                      } else {
                        handleChange(e.currentTarget);
                        setClicked(false);
                      }
                    }
                  }}
                  href="#"
                >
                  {addIndex
                    ? `${index + 1}- ${t(`${option?.[propNames?.name]}`)}`
                    : t(`${option?.[propNames?.name]}`)}
                </li>
              ))}
        </div>
      )}
    </div>
  );
}
