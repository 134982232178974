import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DailyScheduler = ({
  reportDates,
  sideOn,
  onlineDatas,
  kidsDatas,
  cafeDatas,
  companyDatas,
  handleCellClick,
  selectedDay,
  onSelectedDayChange,
  flatData,
}) => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedScheduleDay, setSelectedScheduleDay] = useState(
    moment(selectedDay)
  );
  const calendarRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // update the current time every minute

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    const timeSlotEl = calendarEl.querySelector(
      `[data-time="${currentTime.getHours()}:00"]`
    );
    if (timeSlotEl) {
      calendarEl.scrollTop = timeSlotEl.offsetTop - 75;
    }
  }, [currentTime]);

  const timeDiff = (interval) => {
    const [startTimeString, endTimeString] = interval.split('-');
    const [startHours, startMinutes] = startTimeString
      .split(':')
      .map((str) => parseInt(str));
    const [endHours, endMinutes] = endTimeString
      .split(':')
      .map((str) => parseInt(str));

    const startDate = new Date();
    startDate.setHours(startHours);
    startDate.setMinutes(startMinutes);

    const endDate = new Date();
    endDate.setHours(endHours);
    endDate.setMinutes(endMinutes);

    const differenceInMinutes =
      (endDate.getTime() - startDate.getTime()) / 60000;

    const result = differenceInMinutes / 30;

    return result;
  };

  let timeSlots = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      timeSlots.push(
        `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`
      );
    }
  }

  const renderDailyTableHeaders = () => {
    const handleDayClick = (day) => {
      setSelectedScheduleDay(day);
      onSelectedDayChange(day);
    };

    return (
      <div
        className="d-flex align-items-center justify-content-between pb-2 m-0 text-center"
        style={{ overflowX: 'auto', width: '100%', maxWidth: '100vw' }}
      >
        {reportDates.map((day, index) => {
          const hasValue = flatData?.some(
            (item) => item.date === day.format('DD-MM-YYYY')
          );

          return (
            <button
              key={`${day.format('DD-MM-YYYY')}-${index}`}
              className={`btn btn-default rounded-4 border py-2 mx-1  d-inline-flex align-items-center justify-content-center flex-grow-1 ${
                selectedDay.isSame(day, 'day')
                  ? 'selected bg-t-calendar text-white'
                  : 'bg-light'
              }`}
              style={{ fontSize: '1em', width: '30px' }}
              onClick={() => handleDayClick(day)}
            >
              {day.format('ddd')}
              <br />
              {day.format('D')}

              {hasValue && <span className="badge badge-primary" />}
            </button>
          );
        })}
      </div>
    );
  };

  const renderDailyTableRows = () => {
    return timeSlots.map((time, i) => (
      <tr key={time} className="">
        <td
          data-time={time}
          className="p-0 text-center align-middle  text-muted"
          style={{ width: '75px', maxWidth: '75px' }}
        >
          {time}
        </td>

        <td
          key={`${selectedDay.format('DD-MM-YYYY')}-${time}`}
          className={`p-0`}
          contentEditable={false}
        >
          <div style={{ height: '30px' }}>
            {onlineDatas
              .filter((item) => item.meetingStatus !== t('MeetingStatus.3'))
              .map((value, i) => {
                let text = '';

                if (
                  time === value.interval.split('-')[0] &&
                  selectedDay.format('DD-MM-YYYY') === value.date
                ) {
                  text = (
                    <div key={i}>
                      <div
                        onClick={(event) =>
                          handleCellClick(moment(selectedDay), time, event)
                        }
                        className={`card rounded-3 ${
                          value.isActive === false ? 'opacity-50' : ''
                        } `}
                        style={{
                          borderLeft: '10px solid #4CBC9A ',
                          height: `${timeDiff(value.interval) * 30}px`,
                          overflowX: 'hidden',
                        }}
                        role={'button'}
                      >
                        <div className="card-body d-flex align-self-center text-center p-0 m-0">
                          <p className="p-0 m-0 fw-bold align-self-center ">
                            {value.speakerName}
                          </p>
                          <p className="text-muted m-0 p-0  ps-3 align-self-center ">
                            {value.interval}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return text;
              })}

            {cafeDatas
              .filter((item) => item.meetingStatus !== t('MeetingStatus.3'))
              .map((value, i) => {
                let text = '';

                if (
                  time === value.interval.split('-')[0] &&
                  selectedDay.format('DD-MM-YYYY') === value.date
                ) {
                  text = (
                    <div key={i}>
                      <div
                        onClick={(event) =>
                          handleCellClick(moment(selectedDay), time, event)
                        }
                        className={`card rounded-3 ${
                          value.isActive === false ? 'opacity-50' : ''
                        } `}
                        style={{
                          borderLeft: '10px solid #FFDB80 ',
                          height: `${timeDiff(value.interval) * 30}px`,
                          overflowX: 'hidden',
                        }}
                        role={'button'}
                      >
                        <div className="card-body d-flex align-self-center text-center p-0 m-0">
                          <p className="p-0 m-0 fw-bold align-self-center ">
                            {value.speakerName}
                          </p>
                          <p className="text-muted m-0 p-0  ps-3 align-self-center ">
                            {value.interval}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return text;
              })}

            {kidsDatas
              .filter((item) => item.meetingStatus !== t('MeetingStatus.3'))
              .map((value, i) => {
                let text = '';

                if (
                  time === value.interval.split('-')[0] &&
                  selectedDay.format('DD-MM-YYYY') === value.date
                ) {
                  text = (
                    <div key={i}>
                      <div
                        onClick={(event) =>
                          handleCellClick(moment(selectedDay), time, event)
                        }
                        className={`card rounded-3 ${
                          value.isActive === false ? 'opacity-50' : ''
                        } `}
                        style={{
                          borderLeft: '10px solid #FF8F80 ',
                          height: `${timeDiff(value.interval) * 30}px`,
                          overflowX: 'hidden',
                        }}
                        role={'button'}
                      >
                        <div className="card-body  d-flex align-self-center text-center p-0 m-0">
                          <p className="p-0 m-0 fw-bold align-self-center ">
                            {value.speakerName}
                          </p>{' '}
                          <p className="text-muted m-0 p-0  ps-3 align-self-center ">
                            {value.interval}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return text;
              })}

            {companyDatas
              .filter((item) => item.meetingStatus !== t('MeetingStatus.3'))
              .map((value, i) => {
                let text = '';

                if (
                  time === value.interval.split('-')[0] &&
                  selectedDay.format('DD-MM-YYYY') === value.date
                ) {
                  text = (
                    <div key={i}>
                      <div
                        onClick={(event) =>
                          handleCellClick(moment(selectedDay), time, event)
                        }
                        className={`card rounded-3 ${
                          value.isActive === false ? 'opacity-50' : ''
                        } `}
                        style={{
                          borderLeft: '10px solid #3A3650 ',
                          height: `${timeDiff(value.interval) * 30}px`,
                          overflowX: 'hidden',
                        }}
                        role={'button'}
                      >
                        <div className="card-body d-flex align-self-center text-center p-0 m-0">
                          <p className="p-0 m-0 fw-bold align-self-center ">
                            {value.speakerName}
                          </p>
                          <p className="text-muted m-0 p-0  ps-3 align-self-center ">
                            {value.interval}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return text;
              })}
          </div>
        </td>
      </tr>
    ));
  };

  const renderDailyView = () => {
    return (
      <div>
        {renderDailyTableHeaders()}
        <div
          className="calendar-scroll"
          style={{ width: '100%' }}
          ref={calendarRef}
        >
          <table
            className="table table-bordered responsive "
            style={{ tableLayout: 'fixed', width: '100%' }}
            ref={calendarRef}
          >
            <tbody>{renderDailyTableRows()}</tbody>
          </table>
        </div>
      </div>
    );
  };

  return renderDailyView();
};

export default DailyScheduler;
