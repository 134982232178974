import {
  getFiltred,
  getBySpeakerId,
  getAll,
  getFiltredSpeakers,
  getSelectedSpeakerData,
  getCellDetails,
  getAllCalendarDataWithFilterBO,
  getCellDetailsBO,
  getMeetingAttendees,
} from '../../../services/apiServices/calendarServices';
import ActionTypes from './actionTypes';

const getByIdAction = (id) => {
  return async (dispatch) => {
    await getBySpeakerId(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.calendar.GET_ACTION_CALENDAR_DATA_BY_SPEAKERID,
    payload: data,
  };
};
const getAllAction = (date) => {
  return async (dispatch) => {
    await getAll(date).then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.calendar.GETALL_ACTION_CALENDAR_DATA,
    payload: data,
  };
};
const getFilteredAction = (data, keepData) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    await getFiltred(data).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getFilteredReducer(response, keepData));
    });
  };
};
const getFilteredReducer = (data, keepData) => {
  return {
    type: ActionTypes.calendar.GETFILTRED_ACTION_CALENDAR,
    payload: { data: keepData !== undefined ? [...keepData, ...data] : data },
  };
};
const getFiltredSpeakersAction = (data) => {
  return async (dispatch) => {
    await getFiltredSpeakers(data).then(async (res) => {
      let response = res.data.data;
      await dispatch(getFiltredSpeakersReducer(response));
    });
  };
};
const getFiltredSpeakersReducer = (data) => {
  return {
    type: ActionTypes.calendar.GET_FILTERED_SPEAKERS,
    payload: data,
  };
};

const getSelectedSpeakerDataAction = (data) => {
  return async (dispatch) => {
    await getSelectedSpeakerData(data).then(async (res) => {
      let response = res.data.data;
      await dispatch(getSelectedSpeakerDataReducer(response));
    });
  };
};
const getSelectedSpeakerDataReducer = (data) => {
  return {
    type: ActionTypes.calendar.GET_ACTION_CALENDAR_DATA_BY_SPEAKERID,
    payload: data,
  };
};
const getCellDetailsAction = (data) => {
  return async (dispatch) => {
    dispatch(handleError(''));
    dispatch(handleValidationReducer(''));
    await getCellDetails(data).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.isSuccess) {
        await dispatch(getCellDetailsReducer(response?.data));
        dispatch(handleError(true));
      } else {
        dispatch(handleError(false));
        dispatch(handleValidationReducer(res?.data?.message));
      }
    });
  };
};
const getCellDetailsReducer = (data) => {
  return {
    type: ActionTypes.calendar.GET_CELL_DETAILS,
    payload: data,
  };
};

const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.calendar.HANDLE_VALIDATION_CALENDAR,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.calendar.HANDLE_ERROR_CALENDAR,
    payload: data,
  };
};

const getAllBOCalendarAction = (data) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    await dispatch(getAllBOCalendarReducer([]));
    await getAllCalendarDataWithFilterBO(data).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.isSuccess) {
        await dispatch(getAllBOCalendarReducer(response?.data));
      }
    });
  };
};
const getAllBOCalendarReducer = (data) => {
  return {
    type: ActionTypes.calendar.GetAllCalendarDataWithFilterBO,
    payload: data,
  };
};

const getCellDetailsBOAction = (data) => {
  return async (dispatch) => {
    await getCellDetailsBO(data).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.isSuccess) {
        await dispatch(getCellDetailsBOReducer(response?.data));
      }
    });
  };
};
const getCellDetailsBOReducer = (data) => {
  return {
    type: ActionTypes.calendar.getCellDetailsBO,
    payload: data,
  };
};

const getMeetingAttendeesAction = (data) => {
  return async (dispatch) => {
    dispatch(getMeetingAttendeesReducer([]));
    await getMeetingAttendees(data).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.isSuccess) {
        await dispatch(getMeetingAttendeesReducer(response));
      }
    });
  };
};
const getMeetingAttendeesReducer = (data) => {
  return {
    type: ActionTypes.calendar.getMeetingAttendees,
    payload: data,
  };
};
const handleLoading = (data) => {
  return {
    type: ActionTypes.calendar.handleLoading,
    payload: data,
  };
};
const cleanState = (all = false) => {
  return async (dispatch) => {
    await dispatch(handleError(''));
    await dispatch(handleValidationReducer(''));
    if (all) {
      await dispatch(getFilteredReducer([]));
      await dispatch(getAllBOCalendarReducer([]));
    }
    await dispatch(getCellDetailsReducer([]));
    await dispatch(getCellDetailsBOReducer([]));
    await dispatch(getMeetingAttendeesReducer([]));
  };
};

const calendarActions = {
  getByIdAction,
  getAllAction,
  getFilteredAction,
  getFiltredSpeakersAction,
  getSelectedSpeakerDataAction,
  getSelectedSpeakerDataReducer,
  getFilteredReducer,
  getCellDetailsAction,
  getCellDetailsReducer,
  handleValidationReducer,
  getAllBOCalendarAction,
  getCellDetailsBOAction,
  getMeetingAttendeesAction,
  getMeetingAttendeesReducer,
  cleanState,
};
export default calendarActions;
