import React, { useState } from 'react';
import Card from '../../../../components/Common/Card/Card';
import LineChart from '../../../../components/BackOffice/Charts/LineChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPause, faPerson } from '@fortawesome/free-solid-svg-icons';
import { faFirstOrder } from '@fortawesome/free-brands-svg-icons';
import BarChart from '../../../../components/BackOffice/Charts/BarChart';
import PieChart from '../../../../components/BackOffice/Charts/PieChart';
import { UserData } from '../../../../components/BackOffice/Charts/Data';

const AdminDashboard = () => {
  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: 'Users Gained',
        data: UserData.map((data) => data.userGain),
        backgroundColor: [
          'rgba(75,192,192,1)',
          '#ecf0f1',
          '#50AF95',
          '#f3ba2f',
          '#2a71d0',
        ],
        borderColor: 'black',
        borderWidth: 2,
      },
    ],
  });
  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Admin Dashboard</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <Card
            header="Meet2Talk Back Office"
            body={
              <div>
                <LineChart chartData={userData} />
                <div className="row text-center mt-4">
                  <div className="col-6">
                    <ul className="list-unstyled">
                      <li className="icon text-success">
                        <FontAwesomeIcon icon={faPerson} />
                      </li>
                      <li>Attendee</li>
                      <li>44 New Attendees</li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="list-unstyled">
                      <li className="icon text-warning">
                        <FontAwesomeIcon icon={faFirstOrder}></FontAwesomeIcon>
                      </li>
                      <li>Orders</li>
                      <li>21 Orders</li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="list-unstyled">
                      <li className="icon text-info">
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                      </li>
                      <li>Trial Lesson Count</li>
                      <li>45 Trial Lesson Count</li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="list-unstyled">
                      <li className="icon text-grey">
                        <FontAwesomeIcon icon={faPause}></FontAwesomeIcon>
                      </li>
                      <li>Attendee Freeze</li>
                      <li>0 New AteendeFreeze</li>
                    </ul>
                  </div>
                </div>
              </div>
            }
          ></Card>
        </div>
        <div className="col-12 col-md-8">
          <Card
            header="Meeting Capacity Rate"
            body={
              <div>
                <BarChart chartData={userData} />
              </div>
            }
          ></Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <Card
            header="Location Ratio of Orders"
            body={
              <div>
                <PieChart chartData={userData} />
              </div>
            }
          ></Card>
        </div>
        <div className="col-12 col-md-8">
          <Card
            header="Balance of Payments"
            body={
              <div>
                <BarChart chartData={userData} />
              </div>
            }
          ></Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-4">
          <Card
            header="Day's Turnover"
            body={
              <div>
                <BarChart chartData={userData} />
              </div>
            }
          ></Card>
        </div>
      </div>
    </div>
  );
  return content;
};
export default AdminDashboard;
