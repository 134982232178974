import React, { useEffect } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
const Accordion = ({ items, propNames }) => {
  const [clicked, setClicked] = useState({});

  const handleArrows = (id, index) => {
    setClicked({
      ...clicked,
      [`#collapse${id}${index}`]: clicked[`#collapse${id}${index}`]
        ? false
        : true,
    });
  };
  useEffect(() => {
    if (
      items &&
      items.length > 0 &&
      clicked &&
      Object.keys(clicked).length > 0
    ) {
      const firstItemId = items[0]?.faqGroupId.toString();
      const firstClickedItemId =
        Object.keys(clicked)[0]?.split('#collapse')[1][0];

      if (
        firstItemId &&
        firstClickedItemId &&
        firstItemId !== firstClickedItemId
      ) {
        setClicked({});
      }
    }
  }, [items, clicked]);
  return (
    <div className={'accordion'}>
      {items?.map((item, index) => (
        <div className="accordion-item" key={index}>
          <h3
            className="accordion-header bg-light border border-2"
            id={`heading${item.faqGroupId}${index}`}
          >
            <button
              className={`accordion-button ${
                clicked[`#collapse${item.faqGroupId}${index}`]
                  ? 'collapsed'
                  : ''
              } d-flex justify-content-between`}
              type="button"
              data-bs-toggle={`collapse`}
              data-bs-target={`#collapse${item.faqGroupId}${index}`}
              aria-expanded={false}
              aria-controls={`collapse${item.faqGroupId}${index}`}
              onClick={(e) => {
                handleArrows(item.faqGroupId, index);
              }}
              id={`#collapse${item.faqGroupId}${index}`}
            >
              {item[propNames.header]}
              {clicked[`#collapse${item.faqGroupId}${index}`] ? (
                <FontAwesomeIcon className="mt-3 me-1" icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon className="mt-3" icon={faChevronDown} />
              )}
            </button>
          </h3>
          <div
            id={`collapse${item.faqGroupId}${index}`}
            className={`border accordion-collapse collapse `}
            aria-labelledby={`heading${item.faqGroupId}${index}`}
            data-bs-parent="#accordion"
            key={`collapse${item.faqGroupId}${index}`}
          >
            <p className="accordion-body">{item[propNames.content]} </p>

            {item[propNames.videoContent] && (
              <div className="accordion-body d-flex justify-content-center">
                <iframe
                  width={'50%'}
                  height={210}
                  src={`https://www.youtube.com/embed/${
                    item[propNames.videoContent].split('https://youtu.be/')[1]
                  }`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={item[propNames.youtubeLink]}
                  style={{ display: 'block' }}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
