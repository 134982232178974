import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../../components/Common/Card/Card';
import MeetingSchemaCalendar from '../../../../components/Common/Calendar/MeetingSchemaCalendar';

const AvailabilityCalendar = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h3 className=" fw-bold mb-0 text-gray-800">
          {t('speakerAvailability.speaker_availability')}
        </h3>
      </div>
      <div className="col-12">
        <Card padding={4} body={<MeetingSchemaCalendar />} />
      </div>
    </div>
  );
};
export default AvailabilityCalendar;
