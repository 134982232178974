import { storageKeys } from '../utils/Enum';

export const checkLogged = () => {
  const token = localStorage.getItem(storageKeys.token);
  let id = [];
  if (token !== null) {
    id.push(
      JSON.parse(window?.atob(token.split('.')[1]))[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
      ]
    );
  }
  return token === null ? '' : id;
};
