const headClaims = {
  ageInterval: 'ageInterval',
  attendee: 'attendee',
  attendeeFreeze: 'attendeeFreeze',
  basket: 'basket',
  branch: 'branch',
  branchFreeze: 'branchFreeze',
  calendar: 'calendar',
  banner: 'banner',
  career: 'career',
  companyPriceRequest: 'companyPriceRequest',
  couponCodes: 'couponCodes',
  faq: 'faq',
  faqGroup: 'faqGroup',
  gift: 'gift',
  location: 'location',
  mailSubscription: 'mailSubscription',
  manager: 'manager',
  meetingInterval: 'meetingInterval',
  meetingLevel: 'meetingLevel',
  meetingTopic: 'meetingTopic',
  meetingSchema: 'meetingSchema',
  operationClaim: 'operationClaim',
  operationRestriction: 'operationRestriction',
  package: 'package',
  payment: 'payment',
  prefence: 'prefence',
  price: 'price',
  sessionPackage: 'sessionPackage',
  sessionCategory: 'sessionCategory',
  speaker: 'speaker',
  speakerPayment: 'speakerPayment',
  speakerAvailability: 'speakerAvailability',
  timesPerMonth: 'timesPerMonth',
  timesPerWeek: 'timesPerWeek',
  user: 'user',
  userOperationClaim: 'userOperationClaim',
  zone: 'zone',
  order: 'order',
  meeting: 'meeting',
  feedback: 'feedback',
  lead: 'lead',
  company: 'company',
  campaign: 'campaign',
};
const backofficeClaims = {
  ageInterval: {
    create: 'ageInterval.create',
    update: 'ageInterval.update',
    delete: 'ageInterval.delete',
    get: 'ageInterval.get',
    getAll: 'ageInterval.getall',
  },
  attendee: {
    create: 'attendee.create',
    update: 'attendee.update',
    delete: 'attendee.delete',
    get: 'attendee.get',
    getAll: 'attendee.getall',
  },
  attendeeFreeze: {
    create: 'attendeeFreeze.create',
    update: 'attendeeFreeze.update',
    delete: 'attendeeFreeze.delete',
    get: 'attendeeFreeze.get',
    getAll: 'attendeeFreeze.getall',
  },
  basket: {
    create: 'basket.create',
    update: 'basket.update',
    delete: 'basket.delete',
    get: 'basket.get',
    getAll: 'basket.getall',
  },
  branch: {
    create: 'branch.create',
    update: 'branch.update',
    delete: 'branch.delete',
    get: 'branch.get',
    getAll: 'branch.getall',
  },
  branchFreeze: {
    create: 'branchFreeze.create',
    update: 'branchFreeze.update',
    delete: 'branchFreeze.delete',
    get: 'branchFreeze.get',
    getAll: 'branchFreeze.getall',
  },
  calendar: {
    create: 'calendar.create',
    update: 'calendar.update',
    delete: 'calendar.delete',
    get: 'calendar.get',
    getAll: 'calendar.getall',
  },
  banner: {
    create: 'banner.create',
    update: 'banner.update',
    delete: 'banner.delete',
    get: 'banner.get',
    getAll: 'banner.getall',
  },
  career: {
    create: 'career.create',
    update: 'career.update',
    delete: 'career.delete',
    get: 'career.get',
    getAll: 'career.getall',
  },
  companyPriceRequest: {
    create: 'companyPriceRequest.create',
    update: 'companyPriceRequest.update',
    delete: 'companyPriceRequest.delete',
    get: 'companyPriceRequest.get',
    getAll: 'companyPriceRequest.getall',
  },
  couponCodes: {
    create: 'couponCodes.create',
    update: 'couponCodes.update',
    delete: 'couponCodes.delete',
    get: 'couponCodes.get',
    getAll: 'couponCodes.getall',
  },
  faq: {
    create: 'faq.create',
    update: 'faq.update',
    delete: 'faq.delete',
    get: 'faq.get',
    getAll: 'faq.getall',
  },
  faqGroup: {
    create: 'faqGroup.create',
    update: 'faqGroup.update',
    delete: 'faqGroup.delete',
    get: 'faqGroup.get',
    getAll: 'faqGroup.getall',
  },
  gift: {
    create: 'gift.create',
    update: 'gift.update',
    delete: 'gift.delete',
    get: 'gift.get',
    getAll: 'gift.getall',
  },
  location: {
    create: 'location.create',
    update: 'location.update',
    delete: 'location.delete',
    get: 'location.get',
    getAll: 'location.getall',
  },
  mailSubscription: {
    create: 'mailSubscription.create',
    update: 'mailSubscription.update',
    delete: 'mailSubscription.delete',
    get: 'mailSubscription.get',
    getAll: 'mailSubscription.getall',
    removeThem: 'mailSubscription.removeThem',
  },
  manager: {
    create: 'manager.create',
    update: 'manager.update',
    delete: 'manager.delete',
    get: 'manager.get',
    getAll: 'manager.getall',
  },
  meetingInterval: {
    create: 'meetingInterval.create',
    update: 'meetingInterval.update',
    delete: 'meetingInterval.delete',
    get: 'meetingInterval.get',
    getAll: 'meetingInterval.getall',
  },
  meetingLevel: {
    create: 'meetingLevel.create',
    update: 'meetingLevel.update',
    delete: 'meetingLevel.delete',
    get: 'meetingLevel.get',
    getAll: 'meetingLevel.getall',
  },
  meetingTopic: {
    create: 'meetingTopic.create',
    update: 'meetingTopic.update',
    delete: 'meetingTopic.delete',
    get: 'meetingTopic.get',
    getAll: 'meetingTopic.getall',
  },
  operationClaim: {
    create: 'operationClaim.create',
    update: 'operationClaim.update',
    delete: 'operationClaim.delete',
    get: 'operationClaim.get',
    getAll: 'operationClaim.getall',
  },
  operationRestriction: {
    create: 'operationRestriction.create',
    update: 'operationRestriction.update',
    delete: 'operationRestriction.delete',
    get: 'operationRestriction.get',
    getAll: 'operationRestriction.getall',
  },
  package: {
    create: 'package.create',
    update: 'package.update',
    delete: 'package.delete',
    get: 'package.get',
    getAll: 'package.getall',
  },
  payment: {
    create: 'payment.create',
    update: 'payment.update',
    delete: 'payment.delete',
    get: 'payment.get',
    getAll: 'payment.getall',
  },
  prefence: {
    create: 'prefence.create',
    update: 'prefence.update',
    delete: 'prefence.delete',
    get: 'prefence.get',
    getAll: 'prefence.getall',
  },
  price: {
    create: 'price.create',
    update: 'price.update',
    delete: 'price.delete',
    get: 'price.get',
    getAll: 'price.getall',
  },
  sessionPackage: {
    create: 'sessionPackage.create',
    update: 'sessionPackage.update',
    delete: 'sessionPackage.delete',
    get: 'sessionPackage.get',
    getAll: 'sessionPackage.getall',
  },
  sessionCategory: {
    create: 'sessionCategory.create',
    update: 'sessionCategory.update',
    delete: 'sessionCategory.delete',
    get: 'sessionCategory.get',
    getAll: 'sessionCategory.getall',
  },
  speaker: {
    create: 'speaker.create',
    update: 'speaker.update',
    delete: 'speaker.delete',
    get: 'speaker.get',
    getAll: 'speaker.getall',
    effort: 'speaker.effort',
  },
  speakerAvailability: {
    create: 'speakerAvailability.create',
    update: 'speakerAvailability.update',
    delete: 'speakerAvailability.delete',
    get: 'speakerAvailability.get',
    getAll: 'speakerAvailability.getall',
  },
  speakerPayment: {
    create: 'speakerPayment.create',
    update: 'speakerPayment.update',
    delete: 'speakerPayment.delete',
    get: 'speakerPayment.get',
    getAll: 'speakerPayment.getall',
  },
  timesPerMonth: {
    create: 'timesPerMonth.create',
    update: 'timesPerMonth.update',
    delete: 'timesPerMonth.delete',
    get: 'timesPerMonth.get',
    getAll: 'timesPerMonth.getall',
  },
  timesPerWeek: {
    create: 'timesPerWeek.create',
    update: 'timesPerWeek.update',
    delete: 'timesPerWeek.delete',
    get: 'timesPerWeek.get',
    getAll: 'timesPerWeek.getall',
  },
  user: {
    create: 'user.create',
    update: 'user.update',
    delete: 'user.delete',
    get: 'user.get',
    getAll: 'user.getall',
  },
  userOperationClaim: {
    create: 'userOperationClaim.create',
    update: 'userOperationClaim.update',
    delete: 'userOperationClaim.delete',
    get: 'userOperationClaim.get',
    getAll: 'userOperationClaim.getall',
  },
  zone: {
    create: 'zone.create',
    update: 'zone.update',
    delete: 'zone.delete',
    get: 'zone.get',
    getAll: 'zone.getall',
  },
  meetingSchema: {
    create: 'meetingSchema.create',
    update: 'meetingSchema.update',
    delete: 'meetingSchema.delete',
    get: 'meetingSchema.get',
    getAll: 'meetingSchema.getall',
  },
  order: {
    create: 'order.create',
    update: 'order.update',
    delete: 'order.delete',
    get: 'order.get',
    getAll: 'order.getall',
    accountingDept: 'order.eftpaymentcontrol',
    createGift: 'order.creategift',
    renew: 'order.renew',
  },
  meeting: {
    create: 'meeting.create',
    update: 'meeting.update',
    delete: 'meeting.delete',
    get: 'meeting.get',
    getAll: 'meeting.getall',
  },
  feedback: {
    delete: 'feedback.delete',
    getAll: 'feedback.getall',
  },
  lead: {
    create: 'lead.create',
    update: 'lead.update',
    getAll: 'lead.getall',
  },
  company: {
    create: 'company.create',
    update: 'company.update',
    delete: 'company.delete',
    get: 'company.get',
    getAll: 'company.getall',
  },
  campaign: {
    create: 'campaign.create',
    update: 'campaign.update',
    delete: 'campaign.delete',
    get: 'campaign.get',
    getAll: 'campaign.getall',
  },
};
const actionClaims = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  get: 'get',
  eftConfirm: 'accountingDept',
  availabilityCreate: 'create',
  renewOrderItem: 'renew',
  adminRecompense: 'admin.attendeeRecompense',
  adminSetdate: 'admin.attendeeSetdate',
  unRecompenseAttendee: 'admin.unRecompenseAttendee',
  meetingRecompense: 'admin.meetingRecompense',
  meetingCancel: 'admin.meetingCancel',
  orderReports: 'admin.orderReporting',
  companyAttendee: 'company.attendee',
  companyMeeting: 'company.meeting',
  companyOrder: 'company.order',
};
const Claims = {
  backofficeClaims,
  headClaims,
  actionClaims,
};

export default Claims;
