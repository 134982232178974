import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

const ToastWithParams = ({
  onClose,
  id,
  text = '',
  content = undefined,
  autoClose = false,
  fail = false,
  success = false,
  info = false,
}) => {
  const { t } = useTranslation();
  const [handleError, setHandleError] = useState('');
  const [stateId, setStateId] = useState(null);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999999,
    width: '400px',
  };

  useEffect(() => {
    const source = content !== undefined && content !== '' ? content : text;

    if (source !== undefined && source !== '') {
      setStateId(id);
      setHandleError(
        <React.Fragment>
          {source?.split('{br}')?.map((item, index) => (
            <span
              key={index}
              className="d-flex position-relative justify-content-center text-center"
            >
              <strong>{item}</strong>
            </span>
          ))}
        </React.Fragment>
      );
    }
  }, [content, text]);

  const handleOnClose = () => {
    onClose();
    setHandleError('');
    setStateId(null);
  };

  useEffect(() => {
    if (handleError !== '' && fail) {
      toast.error(handleError, {
        onClose: () => {
          handleOnClose();
        },
        containerId: id,
      });
    } else if (handleError !== '' && content === '') {
      toast.warning(handleError, {
        onClose: () => {
          handleOnClose();
        },
        containerId: id,
      });
    } else if (handleError !== '' && success) {
      toast.success(handleError, {
        onClose: () => {
          handleOnClose();
        },
        containerId: id,
      });
    } else if (handleError !== '' && info) {
      toast.info(handleError, {
        onClose: () => {
          handleOnClose();
        },
        containerId: id,
      });
    }
  }, [handleError]);

  return (
    handleError !== '' &&
    stateId === id && (
      <React.Fragment key={`${id}_component_${id}`}>
        <div className="overlay"></div>
        <ToastContainer
          key={`${id}_component_${id}`}
          containerId={id}
          style={customToastStyle}
          limit={1}
          autoClose={autoClose}
          // closeOnClick
          theme="light"
        />
      </React.Fragment>
    )
  );
};
export default ToastWithParams;
