import React from 'react';

const Iframe = ({ videoId, width, height, title }) => {
  return (
    <iframe
      width={width}
      height={height}
      className={'rounded-4 '}
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      loading="lazy"
      allowFullScreen
      title={title}
      style={{ display: 'block' }}
    />
  );
};

export default Iframe;
