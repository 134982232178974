import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import findMyPath from '../../../hooks/useFindMyPath';

const Result3D = ({ htmlContent }) => {
  const navigate = useNavigate();
  const callBackContent = useSelector((e) => e.payment.callBackContent);
  const [content, setContent] = useState('');
  function OnLoadEvent() {
    document?.postForm?.submit();
  }
  function AutoSubmit() {
    document?.getElementById('webform0')?.submit();
  }
  function moveWindow() {
    document?.hiddenForm?.submit();
  }
  useEffect(() => {
    if (content !== '') {
      OnLoadEvent();
      AutoSubmit();
      moveWindow();
    }
  }, [content]);

  useEffect(() => {
    setContent(decodeHTML(htmlContent));
  }, []);
  useEffect(() => {
    if (callBackContent === '') {
      navigate(findMyPath('dashboard'));
    }
  }, [callBackContent]);
  function decodeHTML(encodedString) {
    return encodedString
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&');
  }
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default Result3D;
