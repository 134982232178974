import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const companyPriceRequestReducer = (
  state = initialState.companyPriceRequest,
  action
) => {
  switch (action.type) {
    case ActionTypes.companyPriceRequest.CREATE_ACTION_PRICEREQUEST:
      return {
        ...state,
        companyPriceRequest:
          initialState.companyPriceRequest.companyPriceRequest,
      };
    case ActionTypes.companyPriceRequest.UPDATE_ACTION_PRICEREQUEST:
      return {
        ...state,
        companyPriceRequest: {},
        getById: {},
      };
    case ActionTypes.companyPriceRequest.GETBYID_ACTION_PRICEREQUEST:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.companyPriceRequest.GETALL_ACTION_PRICEREQUEST:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.companyPriceRequest.HOLD_VALUE_PRICEREQUEST:
      return {
        ...state,
        companyPriceRequest: action.payload,
      };
    case ActionTypes.companyPriceRequest.HANDLE_ERROR_PRICEREQUEST:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.companyPriceRequest.HANDLE_VALIDATION_PRICEREQUEST:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.resetStore.RESET_STORE:
      return {
        ...state,
        isSuccess: '',
        validationMessage: '',
      };
    default:
      return state;
  }
};

export default companyPriceRequestReducer;
