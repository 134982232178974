import { getRequest, postRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(endPoint.classroom.COMMIT_MESSAGE, createData);
};
export const getPrivateRoomMembers = async () => {
  return await getRequest(endPoint.classroom.GET_PRIVATE_ROOM_MEMBERS);
};
export const getPrivateMessages = async () => {
  return await getRequest(endPoint.classroom.GET_PRIVATE_MESSAGES);
};
export const getGroupMessages = async (id) => {
  return await getRequest(`${endPoint.classroom.GET_GROUP_MESSAGES}/${id}`);
};
export const getGroupRoomMembers = async () => {
  return await getRequest(endPoint.classroom.GET_GROUP_ROOM_MEMBERS);
};
export const hideClassroomGroup = async (data) => {
  return await postRequest(endPoint.classroom.HideClassroomGroup, data);
};
