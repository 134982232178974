import React from 'react';

import { useTranslation } from 'react-i18next';
import LetUsCallYou from './LetUsCallYou';
import Button from '../../../../components/Common/Button/Button';
import { ButtonSize, ButtonType } from '../../../../utils/ComponentEnums';
import { useNavigate } from 'react-router-dom';
import landingCalendar from '../../../../assets/images/landingcalendar.svg';
import landingPeople from '../../../../assets/images/landingpeople.svg';
import landingClown from '../../../../assets/images/landingclown.svg';

const Cafe = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFreeTrialClick = () => {
    navigate('/cafe/order/triallesson', {
      state: {
        fromNavigateWhere: 'freeTrial',
        trialStatus: 0,
      },
    });
  };
  return (
    <div className="container-fluid align-items-center">
      <div className="banner bg-light rounded-3 m-2 py-lg-5">
        <div className="container flex-md-column-reverse">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 mt-5 order-md-1 order-2">
              <h1 className="display-4 py-2 fw-bold text-start">
                {t('Landing.Cafe.Why_Meet2Talk_Cafe')}
              </h1>
              <h6 className="text-muted">
                {t('Landing.Cafe.With_Meet2Talk_Cafe')}
              </h6>
              <div className="d-flex justify-content-evenly py-md-4 py-2">
                <div className="flex-grow-1 mx-3 text-nowrap">
                  <Button
                    text={t('landingMainBanner.get_trial')}
                    textColor="text-white"
                    type={ButtonType.LandingGreenBg}
                    border
                    size={ButtonSize.Small}
                    onClick={() => handleFreeTrialClick()}
                  />
                </div>

                <div className="flex-grow-1 mx-3 text-nowrap">
                  <Button
                    text={t('landingMainBanner.buy_package')}
                    textColor="text-landing-green"
                    border
                    size={ButtonSize.Small}
                    borderColor
                    onClick={() => {
                      navigate('/cafe/order/package', {
                        state: { trialStatus: 2 },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-2 order-md-2 order-1">
              <div className="ratio ratio-custom ">
                <iframe
                  className="embed-responsive-item rounded-4 "
                  src="https://www.youtube.com/embed/ykOx7-3qlyk"
                  title="Meet2Talk"
                  allowFullScreen
                  style={{ boxShadow: ' 9px 9px #00c905' }}
                  loading="lazy"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section3 */}
      <div className="our_story py-lg-5 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-5 mt-5">
              <div className="ratio ratio-custom ">
                <iframe
                  className="embed-responsive-item rounded-4 "
                  src="https://www.youtube.com/embed/YQcWBGE3aCI"
                  title="Meet2Talk"
                  allowFullScreen
                  style={{ boxShadow: '-9px 9px 0 0 #00c905 ' }}
                  loading="lazy"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mt-lg-5 ms-4 ">
              <h3 className="display-4 py-2 fw-bold  text-start">
                {t('MainPart2OurStory.Our_Story')}
              </h3>
              <span className="text-muted">
                {t('MainPart2OurStory.Our_Story_Description')}
              </span>
            </div>
          </div>
        </div>
      </div>
      .{/* Section4 */}
      <div className="how_it_works py-lg-5  bg-light text-center px-lg-5">
        <div className="container">
          <h1 className="text-black mb-5 fw-bold">
            {t('MainPart3HowItWorks.How_it_works')}
          </h1>
          <div className="row g-3 d-flex justify-content-between">
            <div className="d-flex col-sm-4 align-items-center">
              <div className="flex-shrink-0">
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img
                    className="img ms-1"
                    src={landingCalendar}
                    alt={'landingCalendar'}
                    style={{ maxWidth: '60px' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 2.5,
                      width: '63px',
                      height: '63px',
                      borderRadius: '50%',
                      border: '3px solid black',
                    }}
                  ></div>
                </div>
              </div>
              <div className="flex-grow-1 text-start mx-2">
                <h6 className="text-black fw-bold">
                  {t('MainPart3HowItWorks.Trial_header')}
                </h6>
                <p>{t('MainPart3HowItWorks.Trial_description')}</p>
              </div>
            </div>
            <div className="d-flex col-sm-4 align-items-center">
              <div className="flex-shrink-0">
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img
                    className="img ms-1"
                    src={landingPeople}
                    alt={'landingPeople'}
                    style={{ maxWidth: '60px' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 2.5,
                      width: '63px',
                      height: '63px',
                      borderRadius: '50%',
                      border: '3px solid black',
                    }}
                  ></div>
                </div>
              </div>
              <div className="flex-grow-1 text-start mx-2">
                <h6 className="text-black fw-bold">
                  {t('MainPart3HowItWorks.Instructors_header')}
                </h6>
                <p>{t('MainPart3HowItWorks.Instructors_description')}</p>
              </div>
            </div>
            <div className="d-flex col-sm-4 align-items-center">
              <div className="flex-shrink-0">
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img
                    className="img ms-1"
                    src={landingClown}
                    alt={'landingClown'}
                    style={{ maxWidth: '60px' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 2.5,
                      width: '63px',
                      height: '63px',
                      borderRadius: '50%',
                      border: '3px solid black',
                    }}
                  ></div>
                </div>
              </div>
              <div className="flex-grow-1 text-start mx-2">
                <h6 className="text-black fw-bold">
                  {t('MainPart3HowItWorks.Speak_English_header')}
                </h6>
                <p>{t('MainPart3HowItWorks.Speak_English_description')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="album py-5 text-center">
        <div className="container">
          <h1 className="text-black mb-lg-5 fw-bold">
            {t('Landing.Cafe.Our_Branches')}
          </h1>
          <div className="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <div className="position-relative my-3">
                <img
                  className="img-fluid rounded-3 mb-3 branch-image"
                  src="/images/cafe/besiktas_kapak.jpg"
                  alt="meee2talk-besiktas"
                />
                <a
                  className="h3 fw-bolder text-decoration-none link-dark stretched-link"
                  href="/cafe/branch/besiktas"
                >
                  Beşiktaş
                </a>
              </div>
            </div>
            <div className="col">
              <div className="position-relative my-3">
                <img
                  className="img-fluid rounded-3 mb-3 branch-image"
                  src="/images/cafe/kadikoy_kapak.jpg"
                  alt="meee2talk-kadikoy"
                  s
                />
                <a
                  className="h3 fw-bolder text-decoration-none link-dark stretched-link"
                  href="/cafe/branch/kadikoy"
                >
                  Kadıköy
                </a>
              </div>
            </div>
            <div className="col">
              <div className="position-relative my-3">
                <img
                  className="img-fluid rounded-3 mb-3 branch-image"
                  src="/images/cafe/mecidiyekoy_kapak.jpg"
                  alt="meee2talk-mecidiyekoy"
                />
                <a
                  className="h3 fw-bolder text-decoration-none link-dark stretched-link"
                  href="/cafe/branch/mecidiyekoy"
                >
                  Mecidiyeköy
                </a>
              </div>
            </div>
            <div className="col">
              <div className="position-relative my-3">
                <img
                  className="img-fluid rounded-3 mb-3 branch-image"
                  src="/images/cafe/antalya_kapak.jpg"
                  alt="meee2talk-antalya"
                />
                <a
                  className="h3 fw-bolder text-decoration-none link-dark stretched-link"
                  href="/cafe/branch/antalya"
                >
                  Antalya
                </a>
              </div>
            </div>
            <div className="col">
              <div className="position-relative my-3">
                <img
                  className="img-fluid rounded-3 mb-3 branch-image"
                  src="/images/cafe/camlica_kapak.jpg"
                  alt="meee2talk-camlica"
                />
                <a
                  className="h3 fw-bolder text-decoration-none link-dark stretched-link"
                  href="/cafe/branch/camlica"
                >
                  Çamlıca
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetUsCallYou image area={1} location={'Cafe'} />
    </div>
  );
};

export default Cafe;
