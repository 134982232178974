import React, { useState } from "react";
import { useTranslation } from "react-i18next";



import Card from "../../../../components/Common/Card/Card";
import MultiSelectDropdown from "../../../../components/Common/Dropdown/MultiSelectDropdown";

const BackOfficeQuestionCreate = () => {
	const { t } = useTranslation();

	const [value, setvalue] = useState("");


	const options = [
		{ id: 1, key: 1, name: 'Option 1', value: 'option1', group: 'Group 1' },
		{ id: 2, key: 2, name: 'Option 2', value: 'option2', group: 'Group 2' },
		{ id: 3, key: 3, name: 'Option 3', value: 'option3', group: 'Group 1' },
		{ id: 4, key: 4, name: 'Option 4', value: 'option4', group: 'Group 1' },
		{ id: 5, key: 5, name: 'Option 5', value: 'option5', group: 'Group 3' },
		{ id: 6, key: 6, name: 'Option 6', value: 'option6', group: 'Group 1' },
		{ id: 7, key: 7, name: 'Option 7', value: 'option7', group: 'Group 2' },
		{ id: 8, key: 8, name: 'Option 8', value: 'option8', group: 'Group 2' },
		{ id: 9, key: 9, name: 'Option 9', value: 'option9', group: 'Group 2' },
		{ id: 10, key: 10, name: 'Option 10', value: 'option10', group: 'Group 1' },
	];
	const [selectedOptions, setSelectedOptions] = useState([]);

	const handleSelectionChange = (newSelection) => {
		setSelectedOptions(newSelection);
	};

	const clearSelection = () => {
		setSelectedOptions([]);
	};

	const removeSelectedOption = (optionValue) => {
		setSelectedOptions(selectedOptions.filter((value) => value !== optionValue));
	};


	return (
		<div className="container-fluid">
			<div className="d-sm-flex align-items-center justify-content-between mb-4">
				<h1 className="h3 mb-0 text-gray-800">Question Create</h1>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<Card
						header={"Speaker Rating Create"}
						body={
							<div>
								<div className="mb-3">
									<label
										htmlFor="exampleFormControlTextarea1"
										className="form-label"
									>
										Question
									</label>
									<textarea
										className="form-control"
										id="exampleFormControlTextarea1"
										rows="3"
									></textarea>
								</div>
								<label>Area</label>
								<div className="d-flex justify-content-start my-2">
									<MultiSelectDropdown
										options={options}
										name={"Select Area"}
										title={"Select Area"}
										propNames={{ id: "id", key: "key", value: "value", name: "name", }}
										onSelectionChange={handleSelectionChange}
										selectedOptions={selectedOptions}
										removeSelectedOption={removeSelectedOption}
										id={"Area"}
									/>

								</div>
								{selectedOptions.length !== 0 && (
									<div>
										<div className="col-md-auto row my-3">
											{selectedOptions.map((option) => (
												<div key={option} className=" col-md-auto ms-2 d-flex align-items-center justify-content-center  text-custom rounded-4  bg-custom bg-opacity-25">


													{option} <button type="button" className="btn-close border-lightcustom text-custom  bg-lightcustom ms-2 w-25" onClick={() => removeSelectedOption(option)}></button>


												</div>
											))}
										</div>
										<button type="button" className="btn btn-default shadow" onClick={clearSelection}>Clear All</button>

									</div>
								)}

							</div>
						}
					></Card>
				</div>
			</div>
		</div>
	);
};
export default BackOfficeQuestionCreate;
