import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styles from '../../../assets/Styles.module.scss';
import { useTranslation } from 'react-i18next';
import { BasicConfirmModalActions } from '../../../utils/Enum';

const BasicConfirmModal = forwardRef(({ onClose, onSave, type }, ref) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleEscKeyPress = (event) => {
    if (event.keyCode === 27 && isOpen) {
      onClose();
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13 && isOpen) {
      onSave();
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', handleEscKeyPress);
    window.addEventListener('keyup', handleEnterKeyPress);
    return () => {
      window.removeEventListener('keyup', handleEscKeyPress);
      window.removeEventListener('keyup', handleEnterKeyPress);
    };
  }, [isOpen]);
  function close() {
    setIsOpen(false);
  }
  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
      onClose();
    },
    open() {
      setIsOpen(true);
    },
  }));
  
  return (
    <React.Fragment>
      {isOpen && (
        <div
          className={styles.basic_confirm_modal_overlay}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.basic_confirm_modal} tabIndex="-1">
            <div className={styles.basic_confirm_modal_dialog}>
              <div className={styles.modal_content}>
                <div className={styles.basic_confirm_modal_header}>
                  <button
                    type="button"
                    className={styles.basic_confirm_modal_header}
                    onClick={() => {
                      onClose();
                      close();
                    }}
                    aria-label="Close"
                  />
                </div>
                <div className={styles.basic_confirm_modal_title}>
                  <h5>
                    <p>{t(`basicConfirmModal.${type?.title}`)}</p>
                  </h5>
                </div>
                <div className={styles.basic_confirm_modal_body}>
                  <p>{t(`basicConfirmModal.${type?.text}`)}</p>
                </div>
                <div className={styles.basic_confirm_modal_footer}>
                  <button
                    type="button"
                    className={type?.firstButton?.className}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (type === BasicConfirmModalActions.logOut) {
                        onSave();
                        close();
                      } else {
                        onClose();
                        close();
                      }
                    }}
                  >
                    {t(`${type?.firstButton?.text}`)}
                  </button>
                  <button
                    type="button"
                    className={type?.secondButton?.className}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (type === BasicConfirmModalActions.logOut) {
                        onClose();
                        close();
                      } else {
                        onSave();
                        close();
                      }
                    }}
                  >
                    {t(`${type?.secondButton?.text}`)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});
export default BasicConfirmModal;
