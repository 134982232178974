import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import getTimeZone from '../../../../hooks/useGetTimeZone';
import { useTranslation } from 'react-i18next';

const WeeklyScheduler = ({
  sideOn,
  onlineDatas,
  kidsDatas,
  cafeDatas,
  companyDatas,
  handleCellClick,
  reportDates,
}) => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date());
  const calendarRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // update the current time every minute

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    const timeSlotEl = calendarEl.querySelector(
      `[data-time="${currentTime.getHours()}:00"]`
    );

    if (timeSlotEl) {
      calendarEl.scrollTop = timeSlotEl.offsetTop - 75;
    }
  }, [currentTime]);

  const timeDiff = (interval) => {
    const [startTimeString, endTimeString] = interval.split('-');
    const [startHours, startMinutes] = startTimeString
      .split(':')
      .map((str) => parseInt(str));
    const [endHours, endMinutes] = endTimeString
      .split(':')
      .map((str) => parseInt(str));

    const startDate = new Date();
    startDate.setHours(startHours);
    startDate.setMinutes(startMinutes);

    const endDate = new Date();
    endDate.setHours(endHours);
    endDate.setMinutes(endMinutes);

    const differenceInMinutes =
      (endDate.getTime() - startDate.getTime()) / 60000;

    const result = differenceInMinutes / 30;

    return result;
  };

  let timeSlots = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      timeSlots.push(
        `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`
      );
    }
  }

  const renderWeeklyTableHeaders = () => {
    return (
      <tr
        className="text-muted position-sticky bg-light top-0 "
        style={{ zIndex: '1' }}
      >
        <th
          className="p-0 text-center"
          style={{ width: '50px', maxWidth: '50px' }}
        >
          {`UTC${getTimeZone()}`}
        </th>
        {reportDates?.map((day) => (
          <th
            key={day.format('dddd, MMMM Do YYYY')}
            className="text-center p-0 m-0"
          >
            {sideOn ? day.format('dd') : day.format('ddd')}
            <br />
            {sideOn ? undefined : day.format('D')}
          </th>
        ))}
      </tr>
    );
  };
  const renderWeeklyTableRows = () => {
    return timeSlots.map((time, i) => (
      <tr key={time}>
        <td
          data-time={time}
          className="p-0 text-center align-middle text-muted"
          style={{ width: '75px', maxWidth: '75px' }}
        >
          {time}
        </td>
        {reportDates?.map((day, index) => {
          const onlineEventsForDay = onlineDatas.filter((value) => {
            return (
              time === value.interval.split('-')[0] &&
              day.format('DD-MM-YYYY') === value.date &&
              value.meetingStatus !== t('MeetingStatus.3')
            );
          });
          const cafeEventsForDay = cafeDatas.filter((value) => {
            return (
              time === value.interval.split('-')[0] &&
              day.format('DD-MM-YYYY') === value.date &&
              value.meetingStatus !== t('MeetingStatus.3')
            );
          });
          const kidsEventsForDay = kidsDatas.filter((value) => {
            return (
              time === value.interval.split('-')[0] &&
              day.format('DD-MM-YYYY') === value.date &&
              value.meetingStatus !== t('MeetingStatus.3')
            );
          });
          const companyEventsForDay = companyDatas.filter((value) => {
            return (
              time === value.interval.split('-')[0] &&
              day.format('DD-MM-YYYY') === value.date &&
              value.meetingStatus !== t('MeetingStatus.3')
            );
          });
          return (
            <td
              key={`${day.format('DD-MM-YYYY')}-${time}-${index}`}
              className={`p-0 `}
              contentEditable={false}
              style={{ width: '75px', maxWidth: '75px' }}
            >
              {
                <div style={{ height: '30px', width: '75' }}>
                  {
                    <>
                      {onlineEventsForDay.map((value, i) => {
                        let text = '';

                        if (
                          time === value.interval.split('-')[0] &&
                          day.format('DD-MM-YYYY') === value.date
                        ) {
                          text = (
                            <div key={i}>
                              <div
                                onClick={(event) =>
                                  handleCellClick(moment(day), time, event)
                                }
                                className={`card  align-middle rounded-3 overlo ${
                                  value.isActive === false ? 'opacity-50' : ''
                                } `}
                                style={{
                                  borderLeft: '10px solid #4CBC9A ',
                                  height: `${timeDiff(value.interval) * 30}px`,
                                  overflowX: 'hidden',
                                }}
                                role={'button'}
                              >
                                <div className="card-body row d-flex align-self-center text-center p-0 m-0">
                                  <span className="text-muted align-self-center d-flex d-sm-none p-0 m-0">
                                    {onlineEventsForDay?.length}
                                  </span>
                                  {onlineEventsForDay?.length > 1 ? (
                                    <span>{onlineEventsForDay?.length}</span>
                                  ) : (
                                    <>
                                      <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex justify-content-center ">
                                        {value.speakerName}
                                      </p>
                                      <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex  justify-content-center">
                                        {value.interval}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return text;
                      })}

                      {cafeEventsForDay.map((value, i) => {
                        let text = '';

                        if (
                          time === value.interval.split('-')[0] &&
                          day.format('DD-MM-YYYY') === value.date
                        ) {
                          text = (
                            <div key={i}>
                              <div
                                onClick={(event) =>
                                  handleCellClick(moment(day), time, event)
                                }
                                className={`card rounded-3 ${
                                  value.isActive === false ? 'opacity-50' : ''
                                } `}
                                style={{
                                  borderLeft: '10px solid #FFDB80 ',
                                  height: `${timeDiff(value.interval) * 30}px`,
                                  overflowX: 'hidden',
                                }}
                                role={'button'}
                              >
                                <div className="card-body row d-flex align-self-center text-center p-0 m-0">
                                  <span className="text-muted align-self-center d-flex d-sm-none p-0 m-0">
                                    {cafeEventsForDay?.length}
                                  </span>
                                  {cafeEventsForDay?.length > 1 ? (
                                    <span>{cafeEventsForDay?.length}</span>
                                  ) : (
                                    <>
                                      <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex justify-content-center ">
                                        {value.speakerName}
                                      </p>
                                      <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex justify-content-center ">
                                        {value.interval}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return text;
                      })}

                      {kidsEventsForDay.map((value, i) => {
                        let text = '';

                        if (
                          time === value.interval.split('-')[0] &&
                          day.format('DD-MM-YYYY') === value.date
                        ) {
                          text = (
                            <div key={i}>
                              <div
                                onClick={(event) =>
                                  handleCellClick(moment(day), time, event)
                                }
                                className={`card rounded-3 ${
                                  value.isActive === false ? 'opacity-50' : ''
                                } `}
                                style={{
                                  borderLeft: '10px solid #FF8F80 ',
                                  height: `${timeDiff(value.interval) * 30}px`,
                                  overflowX: 'hidden',
                                }}
                                role={'button'}
                              >
                                <div className="card-body row d-flex align-self-center text-center p-0 m-0">
                                  <span className="text-muted align-self-center d-flex d-sm-none p-0 m-0">
                                    {kidsEventsForDay?.length}
                                  </span>
                                  {kidsEventsForDay?.length > 1 ? (
                                    <span>{kidsEventsForDay?.length}</span>
                                  ) : (
                                    <>
                                      <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex justify-content-center">
                                        {value.speakerName}
                                      </p>
                                      <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex justify-content-center">
                                        {value.interval}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return text;
                      })}

                      {companyEventsForDay.map((value, i) => {
                        let text = '';

                        if (
                          time === value.interval.split('-')[0] &&
                          day.format('DD-MM-YYYY') === value.date
                        ) {
                          text = (
                            <div key={i}>
                              <div
                                onClick={(event) => {
                                  handleCellClick(moment(day), time, event);
                                }}
                                className={`card rounded-3 ${
                                  value.isActive === false ? 'opacity-50' : ''
                                } `}
                                style={{
                                  borderLeft: '10px solid #3A3650 ',
                                  height: `${timeDiff(value.interval) * 30}px`,
                                  overflowX: 'hidden',
                                }}
                                role={'button'}
                              >
                                <div className="card-body row d-flex align-self-center text-center p-0 m-0">
                                  <span className="text-muted align-self-center d-flex d-sm-none p-0 m-0">
                                    {companyEventsForDay?.length}
                                  </span>
                                  {companyEventsForDay?.length > 1 ? (
                                    <span>{companyEventsForDay?.length}</span>
                                  ) : (
                                    <>
                                      <p className="p-0 m-0 align-self-center fw-bold d-none d-sm-flex justify-content-center ">
                                        {value.speakerName}
                                      </p>
                                      <p className="text-muted m-0 p-0 align-self-center d-none d-sm-flex justify-content-center">
                                        {value.interval}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return text;
                      })}
                    </>
                  }
                </div>
              }
            </td>
          );
        })}
      </tr>
    ));
  };

  const renderWeeklyView = () => {
    return (
      <div>
        <div
          className="calendar-scroll"
          style={{ width: '100%' }}
          ref={calendarRef}
        >
          <table
            className="table table-bordered "
            style={{ tableLayout: 'fixed', width: '100%' }}
          >
            <thead>{renderWeeklyTableHeaders()}</thead>
            <tbody>{renderWeeklyTableRows()}</tbody>
          </table>
        </div>
      </div>
    );
  };

  return renderWeeklyView();
};

export default WeeklyScheduler;
