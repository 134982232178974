import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const attendeeReducer = (state = initialState.attendee, action) => {
  switch (action.type) {
    case ActionTypes.attendee.CREATE_ACTION_ATTENDEE:
      return {
        ...state,
        attendee: initialState.attendee.attendee,
      };
    case ActionTypes.attendee.UPDATE_ACTION_ATTENDEE:
      return {
        ...state,
        attendee: {},
        getById: [],
      };
    case ActionTypes.attendee.GETBYID_ACTION_ATTENDEE:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.attendee.GETALL_ACTION_ATTENDEE:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.attendee.SEARCH_ATTENDEE:
      return {
        ...state,
        filteredAttendee: action.payload,
      };
    case ActionTypes.attendee.HOLD_VALUE_ATTENDEE:
      return {
        ...state,
        attendee: action.payload,
      };
    case ActionTypes.attendee.GET_REF_CODE_ATTENDEE:
      return {
        ...state,
        refCode: action.payload.data,
      };
    case ActionTypes.attendee.GetNotesByMeetingAttendee:
      return {
        ...state,
        freeTrialNote: action.payload.data,
      };
    case ActionTypes.attendee.HANDLE_ERROR_ATTENDEE:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.attendee.HANDLE_VALIDATION_ATTENDEE:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.attendee.CleanList:
      return {
        ...state,
        attendee: {},
        getById: [],
        getAll: [],
        filteredAttendee: [],
        freeTrialNote: {},
      };
    default:
      return state;
  }
};

export default attendeeReducer;
