import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const meetingSchemaReducer = (state = initialState.meetingSchema, action) => {
  switch (action.type) {
    case ActionTypes.meetingSchema.CREATE_ACTION_MEETINGSCHEMA:
      return {
        ...state,
        meetingSchema: initialState.meetingSchema.meetingSchema,
      };
    case ActionTypes.meetingSchema.UPDATE_ACTION_MEETINGSCHEMA:
      return {
        ...state,
        meetingSchema: {},
        getById: [],
      };
    case ActionTypes.meetingSchema.GETBYID_ACTION_MEETINGSCHEMA:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.meetingSchema.GETALL_ACTION_MEETINGSCHEMA:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.meetingSchema.HOLD_VALUE_MEETINGSCHEMA:
      return {
        ...state,
        meetingSchema: action.payload,
      };
    case ActionTypes.meetingSchema.HANDLE_ERROR_MEETINGSCHEMA:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.meetingSchema.GETALL_ACTION_MEETINGSCHEDULE:
      return {
        ...state,
        getAllSchedule: action.payload.data,
      };
    case ActionTypes.meetingSchema.GETALL_SESSION_COUNT_ACTION_MEETINGSCHEDULE:
      return {
        ...state,
        getAllSesionCount: action.payload.data,
      };
    case ActionTypes.meetingSchema.GET_SPEAKER_DROPDOWN_LIST_ACTION:
      return {
        ...state,
        dropdownList: action.payload,
      };
    case ActionTypes.meetingSchema.HANDLE_VALIDATION_MEETINGSCHEMA:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.meetingSchema.GETALL_ACTION_MEETING_BY_SPEAKER:
      return {
        ...state,
        getAllToday: action.payload.data,
      };
    case ActionTypes.meetingSchema.GetMeetingSpeakerSchedule:
      return {
        ...state,
        getSpeakerSchedule: action.payload.data,
      };
    case ActionTypes.meetingSchema.GET_MEETING_ATTENDEES:
      return {
        ...state,
        getMeetingAttendees: action.payload.data,
      };
    case ActionTypes.meetingSchema.ATTENDANCE_CHECK:
      return {
        ...state,
        attendanceCheck: action.payload.data,
      };
    case ActionTypes.meetingSchema.CleanList:
      return {
        ...state,
        meetingSchema: {},
        getById: [],
        getAll: [],
        getAllSchedule: [],
        getAllSesionCount: [],
        dropdownList: [],
        getAllToday: [],
        getSpeakerSchedule: [],
        getMeetingAttendees: [],
        attendanceCheck: [],
      };
    default:
      return state;
  }
};

export default meetingSchemaReducer;
