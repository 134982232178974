import {
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faShoppingCart,
  faUserFriends,
  faSearch,
  faEllipsis,
  faPlay,
  faDesktop,
  faRotate,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faCheckCircle,
  faChevronRight,
  faCommenting,
  faArrowLeftLong,
  faArrowRightLong,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

export const ButtonType = {
  Primary: '-primary',
  Secondary: '-secondary',
  Success: '-success',
  Danger: '-danger',
  Warning: '-warning',
  Info: '-info',
  Light: '-light',
  Dark: '-dark',
  Deneme: '-deneme',
  Custom: '-custom',
  Online: ' bg-custom',
  Cafe: ' bg-d-cafe',
  Kids: ' bg-t-turuncu',
  Company: ' bg-t-calendar',
  Successbg: ' bg-custom',
  LandingGreenBg: ' bg-landing-green',
  ChatInitial: ' bg-c-gri',
  ChatDuring: ' bg-c-orange',
  CalendarSelectButton: '-turuncu',
};

export const ButtonSize = {
  Small: ' btn-sm',
  Large: ' btn-lg',
  ExtraSmall: 'btn-xs',
};
export const ButtonWidth = {
  S: ' w-25',
  M: ' w-50',
  L: ' w-75',
  XL: ' w-100',
};

export const ButtonIcon = {
  Info: faInfoCircle,
  Warning: faExclamationTriangle,
  Check: faCheck,
  Cart: faShoppingCart,
  User: faUserFriends,
  Search: faSearch,
  Play: faPlay,
  Ellipsis: faEllipsis,
  Desktop: faDesktop,
  Cycle: faRotate,
  ArrowLeft: faChevronLeft,
  ArrowRight: faChevronRight,
  CheckCircle: faCheckCircle,
  ArrowUp: faChevronUp,
  ArrowDown: faChevronDown,
  Commenting: faCommenting,
  ArrowRightLong: faArrowRightLong,
  TrashCan: faTrashCan,
};
export const ButtonBold = {
  Bold: ' fw-bold',
};
export const InputType = {
  Button: 'button',
  Checkbox: 'checkbox',
  Color: 'color',
  Date: 'date',
  DateTimeLocal: 'datetime-local',
  Email: 'email',
  File: 'file',
  Hidden: 'hidden',
  Image: 'image',
  Month: 'month',
  Number: 'number',
  Password: 'password',
  Radio: 'radio',
  Range: 'range',
  Reset: 'reset',
  Search: 'search',
  Submit: 'submit',
  Switch: 'switch',
  Tel: 'tel',
  Text: 'text',
  Time: 'time',
  Url: 'url',
  Week: 'week',
};
export const RegexType = {
  Name: 'name',
};
export const InputSize = {
  Small: 'form-control form-control-sm w-25 mt-2',
  Medium: 'form-control w-100 mt-2',
  Large: 'form-control w-75 form-control-lg h-50 mt-2',
};
export const LabelTextType = {
  Normal: 'fw-normal',
  Light: 'fw-light',
  Bold: 'fw-bold',
  Bolder: 'fw-bolder',
  Italic: 'fst-italic',
};
export const LabelPosition = {
  NearInput: ' ms-0',
  AboveInput: ' ms-2',
};
export const LabelFontSize = {
  fs1: ' fs-1 ',
  fs2: ' fs-2 ',
  fs3: ' fs-3 ',
  fs4: ' fs-4 ',
  fs5: ' fs-5 ',
  fs6: ' fs-6 ',
};
export const TableSize = {
  Small: ' w-25',
  Medium: 'w-50',
  Large: ' w-75',
  Full: ' w-100',
};
export const SelectWidth = {
  WTwentyFive: ' w-25',
  WFifty: ' w-50',
  WSeventyFive: ' w-75',
  WHundred: ' w-100',
};
export const AccordionWidth = {
  WTwentyFive: ' w-25',
  WFifty: ' w-50',
  WSeventyFive: ' w-75',
  WHundred: ' w-100',
};
export const DropdownWidth = {
  WTwentyFive: ' w-25',
  WFifty: ' w-50',
  WSeventyFive: ' w-75',
  WHundred: ' w-100',
};
export const AlertType = {
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
  Error: 'error',
};
export const AlertColor = {
  Success: ' btn btn-success',
  Info: ' btn btn-info',
  Warning: ' btn btn-warning',
  Error: ' btn btn-danger',
};
export const BreadcrumbSize = {
  Small: ' h5',
  Medium: ' h3',
  Large: ' h1',
};
export const BreadcrumbType = {
  Primary: ' text-primary',
  Secondary: ' text-secondary',
  Success: ' text-success',
  Danger: ' text-danger',
  Warning: ' text-warning',
  Info: ' text-info',
  Light: ' text-light',
  Dark: ' text-dark',
};
export const TextAreaWidth = {
  WTwentyFive: ' w-25',
  WFifty: ' w-50',
  WSeventyFive: ' w-75',
  WHundred: ' w-100',
};
export const TextRounded = {
  Rounded: ' rounded',
};
export const BackGroundColors = {
  Primary: 'bg-primary',
  Secondary: 'bg-secondary',
  Success: 'bg-success',
  Danger: 'bg-danger',
  Warning: 'bg-warning',
  Info: 'bg-info',
  Light: 'bg-light',
  Dark: 'bg-dark',
  Deneme: 'bg-deneme',
  Custom: 'bg-custom',
  Successbg: 'bg-custom',
  Grey: 'bg-t-gri',
  Online: 'bg-online-green',
  Cafe: 'bg-t-sarı',
  Kids: 'bg-t-turuncu',
  Company: 'bg-t-calendar',
  DashboardOnline: 'bg-d-online',
  DashboardCafe: 'bg-d-cafe',
  DashboardKids: 'bg-d-kids',
  DashboardCompany: 'bg-d-company',
};
export const TextColors = {
  Primary: 'text-primary',
  Secondary: 'text-secondary',
  Success: 'text-success',
  Danger: 'text-danger',
  Warning: 'text-warning',
  Info: 'text-info',
  Light: 'text-light',
  Dark: 'text-dark',
  Custom: 'text-custom',
  Muted: 'text-muted',
  Online: 'text-custom',
  Cafe: 'text-d-cafe',
  Kids: 'text-t-turuncu',
  Company: 'text-t-calendar',
  Chat_Online: 'text-d-online',
  Chat_Cafe: 'text-d-cafe',
  Chat_Kids: 'text-d-kids',
  Chat_Company: 'text-d-company',
};
