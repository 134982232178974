import { setAreaDetails } from '../../../services/apiServices/authServices';
import ActionTypes from './actionTypes';

const onboardType = (onboard) => {
  return async (dispatch) => {

    const values = setAreaDetails(onboard);
    const path = values.path;
    const myBoard = values.myBoard;
    const enumValue = values.enumValue;
    dispatch(onboardTypeReducer(enumValue, path, myBoard));
  };
};
const onboardTypeReducer = (enumValue, path, onboard) => {
  return {
    type: ActionTypes.onboard.ONBOARD_CHOSEN,
    payload: { enumValue, path, onboard },
  };
};

const onboardActions = {
  onboardType,
};

export default onboardActions;
