import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Actions from '../../../store/redux/actions';
import Card from '../../../components/Common/Card/Card';
import Modal from '../../../components/Common/Modal/Modal';
import Label from '../../../components/Common/TextLabel/Label';
import Button from '../../../components/Common/Button/Button';
import {
  LabelFontSize,
  TextColors,
  LabelTextType,
  BackGroundColors,
} from '../../../utils/ComponentEnums';
import online from '../../../assets/images/meet2talk_button.svg';
import kids from '../../../assets/images/kids.svg';
import cafe from '../../../assets/images/cafe.svg';
import company from '../../../assets/images/marketing.svg';
import { useTranslation } from 'react-i18next';
import findMyPath from '../../../hooks/useFindMyPath';
import { UserAreasSring } from '../../../utils/Enum';

export default function Start3() {
  const { t } = useTranslation();
  const third = [
    {
      Header: [t('onboardingStart.if_you_are_ready_lets_get_started')],
      Content: [
        t('onboardingStart.now_that_you_understand_how_meet2talk_works'),
        t('onboardingStart.dont_forget_you_can_switch'),
      ],
    },
  ];
  const onlineDetail = [
    {
      Title: 'Online',
      Content: t('onboardingStart.online_detail'),
      Image: `${online}`,
    },
  ];
  const cafeDetail = [
    {
      Title: 'Cafe',
      Content: t('onboardingStart.cafe_detail'),
      Image: `${cafe}`,
    },
  ];
  const kidsDetail = [
    {
      Title: 'Kids',
      Content: t('onboardingStart.kids_detail'),
      Image: `${kids}`,
    },
  ];
  const companyDetail = [
    {
      Title: 'Company',
      Content: t('onboardingStart.company_detail'),
      Image: `${company}`,
    },
  ];
  const [detail, setDetail] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  function editContent(text, color) {
    const regexPattern = /(.+?)\s*%“.*?”%\s*(.+)/;
    const matchResult = text.match(regexPattern);
    const regex = /%([^%]*)%/;
    const match = text.match(regex);
    const extractedText = match ? match[1] : '';
    return (
      <p>
        {matchResult?.[1]}
        <span className={`text-${setColor(color)}`}>
          <strong>{` ${extractedText} `}</strong>
        </span>
        {matchResult?.[2]}
      </p>
    );
  }

  function setColor(title) {
    let color = '';
    switch (title) {
      case UserAreasSring.Online:
        color = 'custom';
        break;
      case UserAreasSring.Cafe:
        color = 't-sarı';
        break;
      case UserAreasSring.Kids:
        color = 't-turuncu';
        break;
      case UserAreasSring.Company:
        color = 't-calendar';
        break;
      default:
        break;
    }
    return color;
  }

  const onboardingData = [
    {
      type: 'Online',
      labelValue: 'Online',
      imgSrc: online,
      detail: onlineDetail,
      selfUpdateActionIndex: 0,
      to: '/online/dashboard',
    },
    {
      type: 'Cafe',
      labelValue: 'Cafe',
      imgSrc: cafe,
      detail: cafeDetail,
      selfUpdateActionIndex: 1,
      to: '/cafe/dashboard',
    },
    {
      type: 'Kids',
      labelValue: 'Kids',
      imgSrc: kids,
      detail: kidsDetail,
      selfUpdateActionIndex: 2,
      to: '/kids/dashboard',
    },
    {
      type: 'Company',
      labelValue: 'Company',
      imgSrc: company,
      detail: companyDetail,
      selfUpdateActionIndex: 3,
      to: '/company/dashboard',
    },
  ];
  const handleClick = (selfUpdateActionIndex) => {
    dispatch(Actions.onboardActions.onboardType(selfUpdateActionIndex));
    dispatch(
      Actions.userActions.selfUpdateAction(
        { userArea: selfUpdateActionIndex },
        'UserArea'
      )
    );
  };
  return third.map((option, index) => (
    <div className="text-center pb-2" key={`firstDiv_${index}`}>
      {option.Header.map((optionHeader, i) => (
        <div key={`${optionHeader}_div_${i}`}>
          <Label
            key={`${optionHeader}_label_${i}`}
            fontType={LabelTextType.Bolder}
            fontSize={LabelFontSize.fs1}
            value={optionHeader}
          />
        </div>
      ))}
      <div className="mt-4 mb-5">
        {option.Content.map((content, i) => (
          <div key={`${content}_div_${i}`}>
            <Label
              key={`${content}_label_${i}`}
              fontType={LabelTextType.Bold}
              fontSize={LabelFontSize.fs6}
              value={content}
            />
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center flex-column">
        {detail?.map((option, index) => (
          <Modal
            key={index}
            isVisible={isActive}
            title={option?.Title}
            styleProps={{ maxWidth: 500 }}
            content={editContent(option?.Content, option?.Title)}
            imgSrc={{
              src: `${option?.Image}`,
              round: 'img-fluid',
              alt: `${option?.Image}`,
              width: '25%',
            }}
            onClose={() => setIsActive(!isActive)}
            textColor={setColor(option?.Title)}
          />
        ))}
      </div>
      <div className="row mt-5 mb-5">
        {onboardingData?.map((item) => (
          <div className="col-6 col-md-3 mb-5">
            <Label
              fontType={LabelTextType.Bold}
              fontSize={LabelFontSize.fs6}
              value={item.labelValue}
              textColor={TextColors[item?.type]}
            />
            <Link
              to={item.to}
              className="d-flex justify-content-center"
              onClick={() => handleClick(item.selfUpdateActionIndex)}
            >
              <Card
                cardStyle={{ maxWidth: '150px', maxHeight: '120px' }}
                imgSrc={{
                  src: item.imgSrc,
                  round: 'img-fluid',
                  alt: item.imgSrc,
                  maxWidth: '100px',
                  maxHeight: '75px',
                }}
                backGround={BackGroundColors[item?.type]}
              />
            </Link>
            <Button
              text={t('onboardingStart.detail')}
              textColor={TextColors.Muted}
              onClick={() => {
                setDetail(item?.detail);
                setIsActive(!isActive);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  ));
}
