import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import useClickOutside from '../../../hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import checkClaims from '../../../hooks/useCheckClaims';
import { useDispatch, useSelector } from 'react-redux';
import Claims from '../../../utils/Claims';
import Actions from '../../../store/redux/actions';
import ConfirmModal from '../Modal/ConfirmModal';
import addDay from '../../../hooks/useAddDay';

export default function ActionDropdown({ id, data, onClick }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const [isMobile, setIsMobile] = useState(false);
  const [clicked, setClicked] = useState(false);

  let className = 'dropdown-menu';
  let buttonclassName = 'btn border-0';

  const dropdownRef = useClickOutside(() => {
    if (clicked === true) {
      setClicked(false);
    }
  });
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const justifyContentClass = isMobile
    ? 'justify-content-start'
    : 'justify-content-center';

  function fixDate(valDate, valInterval) {
    const [hours, minutes] = valInterval
      ?.split('-')[0]
      ?.split(':')
      ?.map(Number);
    const [d, m, y] = valDate?.split('-')?.map(Number);
    const result = addDay(
      `${hours}:${minutes}`,
      `${y}-${m}-${d}`,
      false,
      false,
      false,
      false,
      false,
      true
    );

    const formattedDateTime = {
      date: `${result.resultDate}`,
      interval: `${result.nTime}`,
    };

    return formattedDateTime;
  }
  const today = new Date();
  const meetingdDate = fixDate(data?.date, data?.interval);
  const [day, month, year] = meetingdDate.date.split('/');
  const [hour, minute] = meetingdDate.interval.split(':');
  const datetime = new Date(year, month - 1, day, hour, minute);

  const onItemClick = (option) => {
    const target = parseInt(option?.value);
    const needConfirmationActions = [15, 16];
    if (!confirmModal.active && needConfirmationActions.includes(target)) {
      return setConfirmModal({
        ...confirmModal,
        active: true,
        type: target,
        data: data,
      });
    }

    switch (confirmModal?.type) {
      case 15:
        dispatch(
          Actions.meetingAttendeeActions.recompenseMeetingAction(
            confirmModal?.data?.meetingId
          )
        );
        onClick();
        break;
      case 16:
        dispatch(
          Actions.meetingAttendeeActions.cancelMeetingAction(
            confirmModal?.data?.meetingId
          )
        );
        onClick();
        break;
      default:
        break;
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const defaultView = screenWidth < 768;
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTouchStart = (e, option) => {
    e.stopPropagation();
    if (defaultView) {
      setTimeout(() => {
        onItemClick(e.currentTarget || e.target);
        setClicked(false);
      }, 200);
    }
  };
  const [actions, setActions] = useState([]);
  const checkLengthActions = actions.length !== 0;

  const recompenseMeetingAction = {
    key: 16,
    name: t('dropdown.recompenseMeeting'),
    value: 15,
  };
  const cancelMeetingAction = {
    key: 17,
    name: t('dropdown.cancelMeeting'),
    value: 16,
  };

  useEffect(() => {
    const newTableActions = [];
    if (today < datetime) {
      if (
        checkClaims(currentUserClaim, Claims.actionClaims.meetingRecompense)
      ) {
        newTableActions.push(recompenseMeetingAction);
      }
      if (checkClaims(currentUserClaim, Claims.actionClaims.meetingCancel)) {
        newTableActions.push(cancelMeetingAction);
      }
    }
    setActions(newTableActions);
  }, [currentUserClaim]);

  const confirmModalRef = useRef();
  const [confirmModal, setConfirmModal] = useState({
    active: false,
    type: undefined,
    data: {},
  });
  const resetConfirmModalState = () => {
    setConfirmModal({
      active: false,
      type: undefined,
      data: {},
    });
  };

  useEffect(() => {
    if (confirmModal?.active) {
      return confirmModalRef.current?.open();
    }
    return () => {};
  }, [confirmModal?.active]);

  return (
    <div
      ref={dropdownRef}
      className={`${actions?.length === 0 ? ' d-none' : ''}`}
    >
      <div
        role={`button`}
        id={id}
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        onClick={() => setClicked(!clicked)}
        style={{ position: 'relative' }}
        className={`d-flex align-items-center ${justifyContentClass}`}
      >
        <span className={`${buttonclassName} d-flex px-1`}>
          <span className={`pe-sm-1 ${!defaultView ? 'ms-1' : ''}`}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </span>
        </span>
      </div>
      {actions && (
        <div
          className={clicked ? `${className} show` : `${className} hide`}
          aria-labelledby={id}
          style={{
            position: 'absolute',
          }}
          onMouseLeave={() => setClicked(false)}
          onTouchEnd={() => setClicked(false)}
        >
          {actions?.map((option, index) => (
            <li
              role="button"
              key={`${option?.key}_${index}_${index + 1}`}
              className={`dropdown-item`}
              id={option?.['name']}
              style={{ marginBottom: '3px', top: 'auto', bottom: '100%' }}
              value={option?.['value']}
              onTouchStart={(e) => handleTouchStart(e, option)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!defaultView) {
                  onItemClick(e.currentTarget);
                  setClicked(false);
                }
              }}
              href="#"
            >
              {t(`${option?.['name']}`)}
            </li>
          ))}
        </div>
      )}
      {confirmModal?.active && (
        <ConfirmModal
          ref={confirmModalRef}
          type={confirmModal?.type}
          page={confirmModal?.page}
          data={confirmModal?.data}
          onClose={() => {
            resetConfirmModalState();
          }}
          onSave={() => {
            onItemClick();
            confirmModalRef.current?.close();
          }}
        />
      )}
    </div>
  );
}
