import Claims from '../utils/Claims';

function checkTableActionClaims(currentUserClaim, page, claim) {
  if (currentUserClaim?.length > 0 && (claim?.includes("admin") || claim?.includes("company"))) {
    return currentUserClaim?.includes(claim)
  }
  else if (currentUserClaim?.length > 0 && claim !== "") {
    return currentUserClaim?.includes(Claims.backofficeClaims[page][claim])
  }
  else if (currentUserClaim?.length === 0) {
    return page === 'attendee' && claim === ''
  }
}
export default checkTableActionClaims;
