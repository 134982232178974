import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="container-fluid Error mt-5 pt-5">
      <h1 style={{ textAlign: 'center' }}>{t('Unauthorized.1')}</h1>
      <br />
      <p style={{ textAlign: 'center', color: 'chocolate' }}>
        {t('Unauthorized.2')}
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50px',
        }}
      >
        <button
          style={{ color: 'whitesmoke', backgroundColor: 'green' }}
          onClick={goBack}
        >
          {t('Unauthorized.3')}
        </button>
      </div>
    </div>
  );
};

export default Unauthorized;
