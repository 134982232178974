import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const calendarReducer = (state = initialState.calendar, action) => {
  switch (action.type) {
    case ActionTypes.calendar.GET_ACTION_CALENDAR_DATA_BY_SPEAKERID:
      return {
        ...state,
        getBySpeakerId: action.payload,
      };
    case ActionTypes.calendar.GETALL_ACTION_CALENDAR_DATA:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.calendar.GETFILTRED_ACTION_CALENDAR:
      return {
        ...state,
        showLoading: false,
        getAllFiltered: action.payload.data,
      };
    case ActionTypes.calendar.GET_FILTERED_SPEAKERS:
      return {
        ...state,
        getCalendarSpeakers: action.payload,
      };
    case ActionTypes.calendar.GET_CELL_DETAILS:
      return {
        ...state,
        getCellDatas: action.payload,
      };
    case ActionTypes.calendar.HANDLE_VALIDATION_CALENDAR:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.calendar.HANDLE_ERROR_CALENDAR:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.calendar.handleLoading:
      return {
        ...state,
        showLoading: action.payload,
      };
    case ActionTypes.calendar.GetAllCalendarDataWithFilterBO:
      return {
        ...state,
        showLoading: false,
        getAllFiltered: action.payload,
      };
    case ActionTypes.calendar.getCellDetailsBO:
      return {
        ...state,
        getCellDatas: action.payload,
      };
    case ActionTypes.calendar.getMeetingAttendees:
      return {
        ...state,
        getMeetings: action.payload,
      };
    default:
      return state;
  }
};

export default calendarReducer;
