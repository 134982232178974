import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(endPoint.location.CREATE_LOCATION, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.location.UPDATE_LOCATION}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.location.REMOVE_LOCATION}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.location.GETBYID_LOCATION}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.location.GETALL_LOCATION);
};
export const getLocationsByUserArea = async (data) => {
  return await postRequest(endPoint.location.GET_LOCATION_BY_USER_AREA, data);
};
