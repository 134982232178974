import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Common/Button/Button';

import { ButtonType, ButtonSize } from '../../../../../utils/ComponentEnums';
import LetUsCallYou from '../LetUsCallYou';
import { useNavigate } from 'react-router-dom';

const Camlica = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFreeTrialClick = () => {
    navigate('/cafe/order/triallesson', {
      state: {
        fromNavigateWhere: 'freeTrial',
        trialStatus: 0,
      },
    });
  };
  return (
    <div>
      <section className="py-5 text-center container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 mt-lg-5 ">
            <h1 className="display-4 pb-4  ">Meet2talk Çamlıca</h1>
            <h4 className=" py-2">
              Küçük Çamlıca Mah. Bulgurlu Caddesi <br />
              Uluçınar Sok. No:5 Üsküdar/İstanbul
            </h4>
            <a className="py-2" href="tel:+905524865241">
              <h4>0541 148 2022</h4>
            </a>
            <div className=" d-flex justify-content-center py-2">
              <Button
                text={t('landingMainBanner.get_trial')}
                textColor="text-white"
                type={ButtonType.LandingGreenBg}
                border
                ButtonSize={ButtonSize.Small}
                buttonCol={6}
                onClick={() => handleFreeTrialClick()}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-5">
            <div className="ratio ratio-4x3 ">
              <iframe
                className="embed-responsive-item rounded-4 "
                src="https://www.youtube.com/embed/oQX9OBtYHfo"
                title="Meet2Talk"
                allowFullScreen
                style={{ boxShadow: ' 9px 9px #00c905' }}
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <div className="album py-5 text-center">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/camlica_1.jpg"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/camlica_2.jpg"
                  alt="..."
                  s
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5 ">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/camlica_3.jpg"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/camlica_4.jpg"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/camlica_5.jpg"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5">
        <LetUsCallYou
          iframe={
            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.2746136698343!2d29.066999017443848!3d41.01924760000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac86cb4ebbbc1%3A0x904ac9b0f59dcc91!2zS8O8w6fDvGsgw4dhbWzEsWNhLCBVbHXDp8SxbmFyIFNva2HEn8SxIE5vOjUsIDM0Njk2IMOcc2vDvGRhci_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1646874570287!5m2!1str!2str'
          }
          area={1}
          location={'Çamlıca'}
        />
        {/* <div className="container">
          <div className="row">
            <div className="col-md-6">
              <iframe
                className="embed-responsive-item rounded-4  border-0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.2746136698343!2d29.066999017443848!3d41.01924760000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac86cb4ebbbc1%3A0x904ac9b0f59dcc91!2zS8O8w6fDvGsgw4dhbWzEsWNhLCBVbHXDp8SxbmFyIFNva2HEn8SxIE5vOjUsIDM0Njk2IMOcc2vDvGRhci_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1646874570287!5m2!1str!2str"
                title="Meet2Talk"
                allowFullScreen
                width={'100%'}
                height={'100%'}
              ></iframe>
            </div>

            <div className="col-md-6 row  justify-content-center align-items-center ">
              <div className=" text-center ">
                <h2 className=" align-self-center">
                  {t('MainPart7Contact.Header')}
                </h2>
                <span className="text-muted  align-self-center">
                  {t('MainPart7Contact.Description')}
                </span>
              </div>
              <form className="justify-content-center px-5">
                <div className="form-floating mb-3 mx-5">
                  <input
                    type="text"
                    className="form-control border-0 border-bottom bg-transparent"
                    id="name"
                    placeholder={t('MainPart7Contact.Name')}
                  />
                  <label htmlFor="name">{t('MainPart7Contact.Name')}</label>
                </div>
                <div className="form-floating mb-3 mx-5">
                  <input
                    type="tel"
                    className="form-control border-0  border-bottom bg-transparent"
                    id="phone"
                    placeholder={t('MainPart7Contact.Phone')}
                  />
                  <label htmlFor="phone">{t('MainPart7Contact.Phone')}</label>
                </div>
                <div className="form-floating mb-3 mx-5">
                  <input
                    type="text"
                    className="form-control border-0 border-bottom bg-transparent"
                    id="message"
                    placeholder={t('MainPart7Contact.Message')}
                  />
                  <label htmlFor="message">
                    {t('MainPart7Contact.Message')}
                  </label>
                </div>
                <button
                  type="submit"
                  className="btn btn-success rounded-5 d-flex mx-auto"
                >
                  {t('MainPart7Contact.Subscribe')}
                </button>
              </form>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Camlica;
