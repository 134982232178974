import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const leadReducer = (state = initialState.lead, action) => {
    switch (action.type) {
        case ActionTypes.lead.Update:
            return {
                ...state,
                getAll: [],
            };
        case ActionTypes.lead.GetById:
            return {
                ...state,
                getById: action.payload,
            };
        case ActionTypes.lead.GetAll:
            return {
                ...state,
                getAll: action.payload,
            };
        case ActionTypes.lead.HandleError:
            return {
                ...state,
                isSuccess: action.payload,
            };
        case ActionTypes.lead.HandleValidation:
            return {
                ...state,
                validationMessage: action.payload,
            };
        case ActionTypes.lead.CleanList:
            return {
                ...state,
                getById: [],
                getAll: [],
            };
        default:
            return state;
    }
};

export default leadReducer;
