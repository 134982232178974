import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import Input from '../../../components/Common/Input/Input';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import { useLocation } from 'react-router-dom';
import { REGEX } from '../../../utils/RegexEnums';
import { SelectWithSearch } from '../../../components/Common/Input/SelectWithSearch';
import moment from 'moment';

const Update = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const userData = useSelector((e) => e.user.user);
  const userInfo = useSelector((e) => e.user.getById);
  const staticDatas = useSelector((e) => e.staticData);
  const dispatch = useDispatch();
  const [updateData, setUpdateData] = useState({});
  const checkData = Object.keys(updateData).length === 0;
  const [dataChanged, setDataChanged] = useState(true);
  const [changeAsked, setChangeAsked] = useState(false);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...userData, [id]: value });
    if (value) {
      setDataChanged(false);
    }
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...userData });
    } else {
      dispatch(Actions.speakerActions.holdValue({ ...updateData }));
    }
  };
  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...userData });
    }
  };
  const onSave = async (e) => {
    e.preventDefault();
    // location?.state?.id
    await dispatch(Actions.userActions.updateAction(updateData, 2));
    FetchById();
    setUpdateData({});
    setDataChanged(true);
  };
  async function FetchById() {
    // location?.state?.id
    await dispatch(Actions.userActions.getByIdAction(2));
  }

  async function GetLists() {
    // let requests = [5, 6, 7];
    // requests.forEach(async (element) => {
    //   await dispatch(Actions.staticDataActions.getFilteredData(element));
    // });
  }
  useEffect(() => {
    FetchById();
    GetLists();
  }, []);
  const choices = [
    'Istanbul, Turkey',
    'Ankara, Turkey',
    'London, United Kingdom',
    'Tokyo, Japan',
  ];
  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags hSize={5} strong text={'Update User Form'} />
                </div>
                <div className="row">
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'firstName'}
                      inputType={InputType.Text}
                      labelText={'Name'}
                      inputValue={
                        checkData ? userInfo.firstName : updateData.firstName
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'lastName'}
                      inputType={InputType.Text}
                      labelText={'Surname'}
                      inputValue={
                        checkData ? userInfo.lastName : updateData.lastName
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Text}
                      labelText={'Emaill Address'}
                      inputValue={checkData ? userInfo.email : updateData.email}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'phone'}
                      inputType={InputType.Text}
                      labelText={'Phone Number'}
                      inputValue={
                        checkData
                          ? userInfo?.phone?.replace(REGEX.TEL, '$1-$2-$3')
                          : updateData?.phone?.replace(REGEX.TEL, '$1-$2-$3')
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      pattern={REGEX.TEL}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    {changeAsked ? (
                      <SelectWithSearch
                        values={choices}
                        selectedValue
                        onFocus={onFocus}
                        labelText={'Location'}
                        handleChange={(val) => {
                          setUpdateData({ ...updateData, country: val });
                          setChangeAsked(false);
                          setDataChanged(false);
                        }}
                      />
                    ) : (
                      <InputLabelOnBorder
                        id={'country'}
                        inputType={InputType.Text}
                        labelText={'Location'}
                        select={{
                          selectStyle: 'selectStyle',
                          option: [],
                          selectedValue: checkData
                            ? userInfo.country
                            : updateData.country,
                        }}
                        handleSelectChange={(val) => {
                          setChangeAsked(true);
                          onFocus();
                        }}
                      />
                    )}
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'dateOfBirth'}
                      inputType={InputType.Date}
                      labelText={'Birth Date'}
                      inputValue={
                        checkData
                          ? moment(userInfo.dateOfBirth).format('yyyy-MM-DD')
                          : moment(updateData.dateOfBirth).format('yyyy-MM-DD')
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <CustomFileInput
                      id={'userImage'}
                      hidden={true}
                      buttonText={t('fileUpload.upload_picture')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      func={(val) => {
                        setUpdateData(
                          Object.assign({}, updateData, { userImage: val })
                        );
                        dispatch(
                          Actions.speakerActions.holdValue(
                            Object.assign({}, updateData, { userImage: val })
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(updateData).length <= 2
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={'Submit'}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={
                        Object.keys(updateData).length <= 2 ? true : false
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Update;
