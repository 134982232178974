import React, { useRef } from 'react';

const Privacy = ({ onScrollToBottom }) => {
  const modalContentRef = useRef();

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
    const isScrolledToBottom = scrollTop + clientHeight + 100 >= scrollHeight;
    if (isScrolledToBottom && onScrollToBottom) {
      onScrollToBottom();
    }
  };

  const containerStyle = {
    maxHeight: 'calc(100vh - 20vh)',
    height: '500px',
    overflowY: 'auto',
  };

  return (
    <div
      onScroll={handleScroll}
      ref={modalContentRef}
      className="container-fluid"
      style={containerStyle}
    >
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="card shadow mb-4">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <p
                      style={{
                        marginTop: '12pt',
                        marginBottom: '12pt',
                        textAlign: 'center',
                      }}
                    >
                      <strong>GİZLİLİK POLİTİKASI</strong>
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Meet2Talk Kişisel Verilerin İşlenmesine Yönelik Gizlilik
                      Politikası Metni <strong>("Gizlilik Politikası")</strong>{' '}
                      ile <strong>Meet2Talk Cafe ("Meet2Talk")</strong>olarak,
                      6698 Sayılı Kişisel Verilerin Korunması Kanunu{' '}
                      <strong>("KVKK")</strong>uyarınca, veri sorumlusu
                      sıfatıyla, sizleri KVKK kapsamındaki aydınlatma
                      yükümlülüğümüz çerçevesinde bilgilendirmek istiyoruz.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Bu bilgilendirme meet2talk.online internet sitesinde
                      bulunan, genel kitleye yönelik Meet2Talk internet sitesi{' '}
                      <strong>("Site")</strong>, Meet2Talk mobil cihaz
                      uygulaması <strong>("Uygulama")</strong> ve şubelerimiz
                      üzerinden verilen her türlü hizmet için geçerlidir.{' '}
                      <strong>"Hizmetler"</strong> ifadesi site, uygulama ve
                      şubeler üzerinden sizlere sağlanan servis ve ürünleri
                      ifade etmektedir. Bu aydınlatma metni içerisinde yer alan
                      hususları tamamen veya kısmen onaylamıyorsanız lütfen
                      Hizmetler'e erişmeyin veya bunları kullanmayın.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>Veri Sorumlusu Kimdir?</strong>
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Veri sorumlusu Meet2Talk Cafe adına Mustafa Özmen'dir.
                      Veri sorumlusunun iletişim bilgileri aşağıdadır:
                      <br />
                      <strong>Posta adresi: </strong>Rasimpaşa Mahallesi,
                      Başakçı Sokak, No:7 Kadıköy / İstanbul
                      <br />
                      <strong>E-posta: </strong>info@meet2talk.cafe
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>
                        Kişisel Verilerinizi Ne Zaman ve Nasıl İşliyoruz?
                      </strong>
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Aşağıdaki hallerde kişisel verilerinizi işliyoruz. Bu
                      hallerin çoğu kısmen veya tamamen otomatik olan yollarla
                      gerçekleşmektedir:
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      Hizmetler için bir hesap oluşturmanız ve/veya Hizmetler’i
                      kullanmanız halinde,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      Meet2Talk’tan hizmet ve/veya ürün talep etmeniz halinde,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      Hizmetler’e dahil herhangi bir uygulamayı bir üyelik
                      planına dayalı olsun veya olmasın yüklemeniz ve/veya
                      kullanmanız halinde,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      Site’yi ziyaret etmeniz halinde,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      Meet2Talk’un bültenini almak için abone olmanız halinde,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      Hata, ihlal veya başka türlü uygunsuz materyallere ilişkin
                      bildirim yapmanız veya diğer bir sebeple Meet2Talk ile
                      temasa geçmeniz halinde,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      Müşteri memnuniyeti veya pazarlama anketi gibi Meet2Talk
                      tarafından yapılan bir araştırmayı yanıtlamanız veya
                      Meet2Talk’un gönderdiği iletilere yanıt vermeniz halinde
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Öte yandan Uygulama’mızı indirdiğinizde, Hizmetler'i
                      kullandığınızda ya da Site’yi ziyaret ettiğinizdeki reklam
                      faaliyetlerimizi geliştirmek amacıyla çerez kullanıyoruz.
                      Böylece özel pazarlama ve reklam başarısının ölçülmesi
                      hedeflenmektedir. Meet2Talk’un çerez kullanımına dair
                      detaylı bilgiyi Site’deki{' '}
                      <a
                        className="fw-bold"
                        href="/cerez-politikasi"
                        target="_blank"
                      >
                        Çerez Politikası’nda
                      </a>{' '}
                      bulabilirsiniz.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>
                        Kişisel Verilerinizi İşlememizin Hukuki Sebebi Nedir?
                      </strong>
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Size Meet2Talk bünyesinde Hizmetler’i sağlayabilmek için
                      kişisel verilerinizi işliyoruz. Örneğin size yakın
                      seviyede İngilizce bilgisi olan katılımcılarla bir arada
                      hizmet alabilmeniz için bize bildirmiş olduğunuz İngilizce
                      seviyenizi kaydediyoruz.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Temel politikamız gereği onayınız olmadan herhangi bir
                      kişisel verinizi toplamıyoruz. Amacımız gönüllülük esasına
                      dayalı olarak sağlamış olduğunuz kişisel verileri bilginiz
                      dahilinde işlemektedir. Kişisel verilerinizi işlememize
                      ilişkin onay verip vermemek her zaman sizin elinizdedir.
                      Dilediğinizde onayınızı kısmen veya tamamen geri
                      alabilirisiniz (KVKK m. 5/1).
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Adli veya idari süreçlerde kimi zaman onayınız olmasa dahi
                      anılan mercilerin bağlayıcı talimatları sebebiyle kamu
                      kuruluşlarıyla bilgi paylaşabiliyoruz. Bu tür durumların
                      Meet2Talk’un inisiyatifinde olmadığını ve kanunun hükmünün
                      yerine getirilmesi niteliğinde olduğunu hatırlatmak
                      isteriz (KVKK m. 5/1-a veya KVKK m. 5/1-e).
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Meet2Talk’a üye olmanın şartlarından birisi{' '}
                      <a
                        className="fw-bold"
                        href="/kullanim-kosullari"
                        target="_blank"
                      >
                        Kullanım Koşulları’na
                      </a>{' '}
                      onay vermektir.{' '}
                      <a
                        className="fw-bold"
                        href="/kullanim-kosullari"
                        target="_blank"
                      >
                        Kullanım Koşullar’ı
                      </a>{' '}
                      aramızdaki sözleşmenin şartlarını ve kapsamını
                      belirlemektedir. Bu kapsamda sözleşmenin kurulması veya
                      ifasıyla doğrudan doğruya ilgili olması halinde kişisel
                      verilerinizi işleyebilmekteyiz (KVKK m. 5/1-c).
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      İstisnai ve zorunlu hallerde temel hak ve özgürlüklerinize
                      zarar vermeden, meşru menfaatlerimiz için kişisel
                      verilerinizi işleyebiliyoruz (KVKK m. 5/1-f).
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '0pt' }}>
                      <strong>Hangi Kişisel Verileriniz Toplanıyor?</strong>
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '12pt' }}>
                      Meet2Talk olarak aşağıdaki bilgilerinizi topluyoruz:
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '0pt' }}>
                      1) Hesap kayıt bilgileriniz: E-posta, şifre, isim,
                      soyisim, telefon numarası, doğum tarihi
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      2) Ödeme bilgileriniz: Ödeme yapılan kart sahibinin ismi
                      soyismi, kart numarası, son kullanma tarihi, CVV kodu,
                      fatura adresi, banka hesap bilgileri (Bu bilgileriniz
                      PayTR ve Garanti Bankası tarafından toplanmaktadır)
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      3) Çevrimiçi toplantılara ilişkin bilgileriniz: Site veya
                      Uygulama üzerinden sağlanan toplantıların sesli ve
                      görüntülü kayıtları
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      4) Fiziki toplantılara ilişkin bilgileriniz: Fiziki
                      toplantı sırasında alınan sesli ve görüntülü kayıtlar
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      5) Diğer bilgileriniz: Anket vb. çalışmalar sırasında
                      katılımcının sağladığı bilgiler, Meet2Talk ile yapılan
                      e-posta yazışmaları, Meet2Talk bülten aboneliği, yarışma
                      ve/veya promosyon kayıt bilgileri, yardım/destek talebi
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>Kişisel Verilerinizi Neden İşliyoruz?</strong>
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Meet2Talk bir İngilizce konuşma kulübüdür. Bu kulüp hem
                      fiziken hem de çevrimiçi olarak faaliyet göstermektedir.
                      Her iki faaliyet kapsamında hizmet almak istediğinizde
                      üyeliğinizin gerçekleşebilmesi için sizlerden birtakım
                      bilgiler almalıyız. Örneğin sahte/balon kayıtların önüne
                      geçebilmek ve oturumunuza ilişkin son dakika acil bir
                      değişiklik hakkında sizleri bilgilendirebilmek için
                      telefon numaranıza ihtiyaç duyuyoruz. Nitekim kişisel
                      verileriniz, yasaklanmış veya hukuka aykırı faaliyetlerin
                      önlenmesi tespiti ve soruşturulması (örneğin oturum
                      başkanına hakaret edilmesi) ile{' '}
                      <a
                        className="fw-bold"
                        href="/kullanim-kosullari"
                        target="_blank"
                      >
                        Kullanım Koşulları’nın
                      </a>{' '}
                      uygulanabilmesi (örneğin ilgili kişinin kişisel
                      verilerinin işlenmesine rıza vermeye ehil olup olmadığının
                      tespit edilebilmesi) amaçlarıyla da işlenebilmektedir.
                      Bazen adli ve idari merciler nezdindeki kanuni
                      yükümlülüklerimizi yerine getirmek için de kişisel
                      verilerinizi işleyebiliyoruz (örneğin ilgili belediyenin
                      denetimleri sırasında kafede bulunan kişilerin bilgileri
                      paylaşılabilmektedir).
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Kişisel verilerinizi işleme sebebimiz sadece bunlarla
                      sınırlı değildir. Müşteri ilişkilerini yönetmek amacıyla
                      kişisel verilerinizi işliyoruz. Aldığınız hizmetteki
                      fiyat/performans verimliliğini artırabilmek, sizlere daha
                      makul teklifler sunabilmek ve Hizmetler’in hem nicel hem
                      de nitel kalitesini artırabilmek adına pazar ve müşteri
                      analizleri, piyasa araştırmaları gibi istatistiklere
                      ihtiyaç duyuyoruz. Bize sunmuş olduğunuz bilgiler bu
                      kapsamda Meet2Talk’un iş takibi ile iş yönteminin
                      geliştirilmesi için işlenebilir.
                    </p>

                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>
                        Kişisel Verilerinizi Üçüncü Kişilerle Paylaşıyor muyuz?
                      </strong>
                    </p>

                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Kişisel verileriniz sadece Meet2Talk bünyesinde
                      tutulmaktadır. Haliyle kural olarak kişisel verilerinizi
                      üçüncü kişilerle paylaşmıyoruz. Ancak unutmayınız ki
                      çevrimiçi ödeme yapabilmeniz için bir aracı firma olan
                      PayTR’den destek alıyoruz. Ödeme yapabilmeniz için gerekli
                      olan bilgileri PayTR’ye iletmeniz gerekecektir.
                    </p>

                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Ayrıca, adli ve idari mercilerin kişisel verilerinizin
                      aktarılmasına ilişkin bağlayıcı talimatları halinde
                      kişisel verilerinizi ilgili mercie aktarabiliyoruz.
                      Örneğin vergi denetimi halinde faturadaki bilgilerinizi
                      vergi müfettişlerine vermemiz gerekebilir. Veyahut almakta
                      olduğunuz Hizmetler’in sağlanabilmesi için bazen üçüncü
                      kişilerle bilgi paylaşıyoruz. Buradaki paylaşım
                      zorunluluğu sağladığımız Hizmetler’in doğasından
                      kaynaklanıyor. Örneğin faturanızı adresinize
                      gönderebilmemiz için adresinizi kargo şirketi ile
                      paylaşıyoruz. Bir başka örnek olarak katılmak istediğiniz
                      kulüp oturumuna başkanlık edecek oturum sorumlumuzla
                      isim-soyisim ve İngilizce bilgi seviyenizi
                      paylaşabilmekteyiz. Böylece oturum sorumlusu ile kolayca
                      koordine olabilmenizi hedefliyoruz.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>
                        Kişisel Verileriniz Ne Kadar Süre Saklanıyor?
                      </strong>
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Meet2Talk kullanım veya bülten üyeliğiniz devam ettiği
                      sürece kişisel verilerinizi saklıyoruz. Üyelikten çıksanız
                      dahi aramıza tekrar dönmemiz halinde size daha hızlı ve
                      iyi hizmet verebilmek için üyeliğe ilişkin kişisel
                      verilerinizi iki yıl boyunca saklıyoruz. İlgili mevzuatın
                      gerektirmesi veya hak talebinde bulunmak, bir hakkı
                      kullanmak veya savunmak için bu konularla bağlantılı
                      kişisel verilerinizi iki yıldan daha uzun bir süre de
                      saklayabiliriz.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>
                        Gizlilik Politikası’nda Değişiklik Yapılıyor mu?
                      </strong>
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      Bu Gizlilik Politikası'nda kimi zaman değişiklik
                      yapılabilir. Sizleri etkileyecek değişiklikler
                      yapıldığında, bu değişiklik yürürlüğe girmeden önce
                      e-posta, SMS yoluyla veya Hizmetler üzerinden konu
                      hakkında sizleri bilgilendiriyoruz. Böyle bir durumda
                      güncel Gizlilik Politikası’nı Site’de de yayınlıyoruz.
                      Kişisel veri işleme faaliyetlerimiz hakkında bilgi sahibi
                      olmak için bu Gizlilik Politikası'nı sık sık gözden
                      geçirmenizi öneririz.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '0pt' }}>
                      <strong>
                        Kişisel Verilerinizin Korunmasına İlişkin Haklarınız
                        Nelerdir?
                      </strong>
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '12pt' }}>
                      KVKK m. 11 uyarınca Meet2Talk’a başvurarak;
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '0pt' }}>
                      1) Kişisel verilerinizin işlenip işlenmediğini öğrenme,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      2) Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                      etme,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      3) Kişisel verilerinizin işlenme amacını ve bunların
                      amacına uygun kullanılıp kullanılmadığını öğrenme,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      4) Yurt içinde veya yurt dışında kişisel verilerinizin
                      aktarıldığı üçüncü kişileri bilme,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      5) Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                      hâlinde bunların düzeltilmesini isteme,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      6) Amaç, süre ve meşruiyet prensipleri dâhilinde
                      değerlendirilmek üzere kişisel verilerinizin işlenmesini
                      gerektiren sebeplerin ortadan kalkması halinde silinmesini
                      veya yok edilmesini isteme,
                    </p>

                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      7) Kişisel verilerinizin düzeltilmesi, silinmesi ya da yok
                      edilmesi halinde bu işlemlerin Kişisel verilerin
                      aktarıldığı üçüncü kişilere bildirilmesini isteme,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      8) İşlenen kişisel verilerinizin münhasıran otomatik
                      sistemler vasıtasıyla analiz edilmesi durumunda aleyhinize
                      bir sonucun ortaya çıkması halinde bu sonuca itiraz etme,
                    </p>
                    <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
                      9) Kişisel verilerinizin kanuna aykırı olarak işlenmesi ve
                      bu sebeple zarara uğramanız hâlinde zararın giderilmesini
                      talep etme.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      haklarına sahipsiniz. Bu kapsamda, yukarıdaki haklarınıza
                      ilişkin talebinizi Kişisel Verileri Koruma Kurumu
                      tarafından belirlenen şartları taşıyacak şekilde aşağıda
                      verilen posta ve/veya e-posta adresimize
                      gönderebilirsiniz. Lütfen güncel başvuru yöntemlerini
                      başvuru öncesinde mevzuattan teyit ediniz. Meet2Talk,
                      talebin niteliğine göre talebi en kısa sürede ve en geç
                      otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
                      Taleplerin Meet2Talk tarafından sonuçlandırılmasına
                      ilişkin ayrıca bir maliyet doğması hâlinde Kişisel
                      Verileri Koruma Kurulunca belirlenen tarifedeki ücretler
                      Meet2Talk tarafından talep edilebilir.
                    </p>
                    <p style={{ marginTop: '12pt', marginBottom: '12pt' }}>
                      <strong>Posta adresi: </strong>Rasimpaşa mah. Başakçı Sk.
                      No:7 Kadıköy / İstanbul
                      <br />
                      <strong>E-posta: </strong>info@meet2talk.cafe
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
