import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const onboardReducer = (state = initialState.onboard, action) => {
  switch (action.type) {
    case ActionTypes.onboard.ONBOARD_CHOSEN:
      return {
        ...state,
        enum: action.payload.enumValue,
        path: action.payload.path,
        onboard: action.payload.onboard,
      };
    default:
      return state;
  }
};
export default onboardReducer;
