import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styles from '../../../assets/Styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ConfirmationModalActions } from '../../../utils/Enum';
import addDay from '../../../hooks/useAddDay';

const ConfirmModal = forwardRef(
  (
    { onClose, onSave, type = undefined, page = undefined, data = undefined },
    ref
  ) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    function fixDate(valDate, valInterval) {
      const [hours, minutes] = valInterval
        ?.split('-')[0]
        ?.split(':')
        ?.map(Number);
      const [d, m, y] = valDate?.split('-')?.map(Number);
      const result = addDay(
        `${hours}:${minutes}`,
        `${y}-${m}-${d}`,
        false,
        false,
        false,
        false,
        false,
        true
      );

      const formattedDateTime = {
        date: `${result.resultDate}`,
        interval: `${result.nTime}`,
      };

      return formattedDateTime;
    }

    useEffect(() => {
      let action;
      let text;
      let value;
      let id;
      let supValue;
      let supText;
      page = t(`confirmModal.${page}`);
      switch (type) {
        case ConfirmationModalActions.removeAction.value:
          action = t(ConfirmationModalActions.removeAction.name);
          text = ConfirmationModalActions.removeAction.text;
          value =
            data?.name ||
            data?.speakerName ||
            data?.managerName ||
            data?.attendeeName ||
            data?.fullName ||
            data?.id;
          supText =
            value === data?.id
              ? t('confirmModal.text_id')
              : t('confirmModal.text_name');
          break;
        case ConfirmationModalActions.setPayedAction.value:
          action = t(ConfirmationModalActions.setPayedAction.name);
          text = ConfirmationModalActions.setPayedAction.text;
          value = data?.attendeeName;
          id = data?.id;
          supValue = data?.netAmount;
          break;
        case ConfirmationModalActions.recompenseAction.value:
          const recompenseDate = fixDate(data?.date, data?.interval);
          page = t('confirmModal.session');
          action = t(ConfirmationModalActions.recompenseAction.name);
          text = ConfirmationModalActions.recompenseAction.text;
          value = recompenseDate.date;
          supValue = recompenseDate.interval;
          break;
        case ConfirmationModalActions.interviewedAction.value:
          action = t(ConfirmationModalActions.interviewedAction.name);
          text = ConfirmationModalActions.interviewedAction.text;
          value = data?.fullName;
          supText =
            value === data?.id
              ? t('confirmModal.text_id')
              : t('confirmModal.text_name');
          break;
        case ConfirmationModalActions.unPayMeetingAction.value:
          const meetingDate = fixDate(data?.date, data?.interval);
          action = t(ConfirmationModalActions.unPayMeetingAction.name);
          text = ConfirmationModalActions.unPayMeetingAction.text;
          value = meetingDate.date;
          supValue = meetingDate.interval;
          break;
        case ConfirmationModalActions.unrecompenseAction.value:
          const unrecompenseDate = fixDate(data?.date, data?.interval);
          page = t('confirmModal.session');
          action = t(ConfirmationModalActions.unrecompenseAction.name);
          text = ConfirmationModalActions.unrecompenseAction.text;
          value = unrecompenseDate.date;
          supValue = unrecompenseDate.interval;
          break;
        case ConfirmationModalActions.recompenseMeetingAction.value:
          const recompenseMeetingDate = fixDate(data?.date, data?.interval);
          page = t('confirmModal.session');
          action = t(ConfirmationModalActions.recompenseMeetingAction.name);
          text = ConfirmationModalActions.recompenseMeetingAction.text;
          value = recompenseMeetingDate.date;
          supValue = recompenseMeetingDate.interval;
          supText = data?.speakerName;
          break;
        case ConfirmationModalActions.cancelMeetingAction.value:
          const cancelMeetingDate = fixDate(data?.date, data?.interval);
          page = t('confirmModal.session');
          action = t(ConfirmationModalActions.cancelMeetingAction.name);
          text = ConfirmationModalActions.cancelMeetingAction.text;
          value = cancelMeetingDate.date;
          supValue = cancelMeetingDate.interval;
          supText = data?.speakerName;
          break;
        default:
          break;
      }
      setTitle(t('confirmModal.title', { action, page }));
      action = action.toLowerCase();
      page = page.toLowerCase();
      setText(
        t(`confirmModal.${text}`, {
          page,
          action,
          value,
          id,
          supValue,
          supText,
        })
      );
    }, [type]);

    const handleEscKeyPress = (event) => {
      if (event.keyCode === 27 && isOpen) {
        onClose();
      }
    };
    const handleEnterKeyPress = (event) => {
      if (event.keyCode === 13 && isOpen) {
        onSave();
      }
    };
    useEffect(() => {
      window.addEventListener('keyup', handleEscKeyPress);
      window.addEventListener('keyup', handleEnterKeyPress);
      return () => {
        window.removeEventListener('keyup', handleEscKeyPress);
        window.removeEventListener('keyup', handleEnterKeyPress);
      };
    }, [isOpen]);
    function close() {
      setIsOpen(false);
    }
    useImperativeHandle(ref, () => ({
      close() {
        setIsOpen(false);
        onClose();
      },
      open() {
        setIsOpen(true);
      },
    }));

    return (
      <React.Fragment>
        {isOpen && (
          <div
            className={styles.confirm_modal_overlay}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.confirm_modal} tabIndex="-1">
              <div className={styles.confirm_modal_dialog}>
                <div className={styles.modal_content}>
                  <div className={styles.confirm_modal_header}>
                    <button
                      type="button"
                      className={styles.confirm_modal_header}
                      onClick={() => {
                        onClose();
                        close();
                      }}
                      aria-label="Close"
                    />
                  </div>
                  <div className={styles.confirm_modal_title}>
                    <h5>
                      <strong>{title}</strong>
                    </h5>
                  </div>
                  <div className={styles.confirm_modal_body}>
                    <p>{text}</p>
                  </div>
                  <div className={styles.confirm_modal_footer}>
                    <button
                      type="button"
                      className="btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                        close();
                      }}
                    >
                      {t('confirmModal.close')}
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        onSave();
                        close();
                      }}
                    >
                      {t('confirmModal.submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
);
export default ConfirmModal;
