import React, { useState } from 'react';
import { Loader } from '../Loader/Loader';
import useLoader from '../../../hooks/useLoader';

export const TabItem = (props) => <div {...props} />;
export const Tabs = ({
  defaultIndex = 0,
  children,
  stopBubbling = undefined,
  textColor,
  noAnimation,
}) => {
  const [bindIndex, setBindIndex] = useState(defaultIndex);
  const changeTab = (newIndex) => {
    setBindIndex(newIndex);
  };
  const items = React.Children.toArray(children);
  const tabContent = (
    <div className="wrapper">
      <div
        className="tab-menu border-bottom d-flex flex-nowrap overflow-auto"
        onClick={(e) => (stopBubbling === undefined ? '' : stopBubbling(e))}
      >
        {items?.map(({ props: { index, label } }) => (
          <button
            key={`tab-btn-${index}`}
            onClick={() => {
              changeTab(index);
            }}
            className={`fs-6 fw-bolder  ${textColor} ${
              bindIndex === index ? 'focus' : 'text-muted '
            }`}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="tab-view">
        {items?.map(({ props }) => (
          <div
            {...props}
            className={`fw-normal text-muted tab-content ${
              bindIndex === props.index ? 'selected' : ''
            }`}
            key={`tab-content-${props.index}`}
          />
        ))}
      </div>
    </div>
  );
  return useLoader(bindIndex) && !noAnimation ? <Loader /> : tabContent;
};
