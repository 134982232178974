import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Actions from '../../../store/redux/actions';
import useLoader from '../../../hooks/useLoader';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import { Loader } from '../../../components/Common/Loader/Loader';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import {
  ButtonType,
  InputType,
  RegexType,
  TextColors,
} from '../../../utils/ComponentEnums';
import {
  faApple,
  faGoogle,
  faSquareFacebook,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCircleExclamation,
  faCircleUser,
} from '@fortawesome/free-solid-svg-icons';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import Tooltip from '../../../components/Common/ToolTip/Tooltip';
import { getNames } from 'i18n-iso-countries';
import { ModalTitles, countryListAllIsoData } from '../../../utils/Enum';
import { formatPhone } from '../../../hooks/useFormatPhone';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import PhoneConfirmation from '../AttendeePages/PhoneConfirmation';

const PersonalInformation = () => {
  const phoneConfirmationModal = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector((e) => e.lang.language);
  const sessionCounts = useSelector((e) => e.meetingSchema.getAllSesionCount);
  const userId = useSelector((e) => e.auth.currentUser);
  const getBoard = useSelector((e) => e.onboard.onboard);
  const userData = useSelector((e) => e.user.user);
  const userInfo = useSelector((e) => e.user.getById);
  const staticDatas = useSelector((e) => e.staticData);
  const levelGroup = useSelector(
    (e) => e.preferenceOptions.preferenceLevelGroup
  );
  const [updateData, setUpdateData] = useState({});
  const isSuccessUser = useSelector((e) => e.user.isSuccess);
  const isSuccessAttendee = useSelector((e) => e.attendee.isSuccess);
  const [dataChanged, setDataChanged] = useState(true);
  const [imgSrc, setImgSrc] = useState('');
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const [error, setError] = useState({});
  const [phoneConfirmationInfo, setPhoneConfirmationInfo] = useState('');

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;
    if (value !== '') {
      setUpdateData({ ...userData, [id]: value });
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value, name);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    if (value) {
      setDataChanged(false);
    }
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...userData });
    } else {
      dispatch(Actions.userActions.holdValue({ ...updateData }));
    }
  };

  const onSave = async (e) => {
    e?.preventDefault();
    if (updateData?.phone !== userInfo?.phone) {
      return phoneConfirmationModal.current.open();
    }

    if (
      Object.keys(updateData).length > 0 &&
      Object.values(error).every((e) => e === undefined)
    ) {
      await dispatch(Actions.userActions.updateAction(updateData, userId));
    }
  };

  const onUploadPicture = (e) => {};
  const onFacebookCon = async (e) => {
    e.preventDefault();
  };
  const onGoogleCon = async (e) => {
    e.preventDefault();
  };
  const onAppleCon = async (e) => {
    e.preventDefault();
  };
  async function FetchById() {
    await dispatch(Actions.userActions.getByIdAction(userId));
  }

  useEffect(() => {
    FetchById();
    if (lengthCurrentUserClaim) {
      dispatch(Actions.meetingSchemaActions.getAllSessionCountAction());
    }
  }, []);

  useEffect(() => {
    if (isSuccessAttendee) {
      FetchById();
      setDataChanged(true);
    }
  }, [isSuccessAttendee]);

  useEffect(() => {
    if (isSuccessUser) {
      FetchById();
      setUpdateData({});
      setDataChanged(true);
      setImgSrc('');
    }
  }, [isSuccessUser]);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      setUpdateData({ ...userInfo });
    }
  }, [userInfo]);

  const [countryList, setCountryList] = useState([]);
  const countryNames = countryListAllIsoData.map((item) => {
    const name = getNames(lang?.split(',')[0].split('-')[0])[item.code];
    const isoName = item.name;
    const phone = item.phone;

    return { name: name, phone: phone, isoName: isoName };
  });
  useEffect(() => {
    setCountryList(countryNames);
  }, []);

  const selectedLanguageLevels = [];

  if (
    updateData.languageLevel !== undefined &&
    selectedLanguageLevels.length === 0
  ) {
    if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
      if (userInfo.userArea !== 2) {
        const levelLng = levelGroup?.adult;
        Object.values(levelGroup).forEach((lang) => {
          if (staticDatas.LanguageLevels[lang] && levelLng === lang) {
            selectedLanguageLevels.push(
              ...staticDatas.LanguageLevels[levelLng]
            );
          }
        });
      } else if (userInfo.userArea === 2) {
        const levelLng = levelGroup?.kids;
        Object.values(levelGroup).forEach((lang) => {
          if (staticDatas.LanguageLevels[lang] && levelLng === lang) {
            selectedLanguageLevels.push(
              ...staticDatas.LanguageLevels[levelLng]
            );
          }
        });
      } else {
        const levelLng = updateData?.languageLevels?.split('.')[0];
        Object.values(levelGroup).forEach((lang) => {
          if (staticDatas.LanguageLevels[lang] && levelLng === lang) {
            selectedLanguageLevels.push(
              ...staticDatas.LanguageLevels[levelLng]
            );
          }
        });
      }
    }
  }

  const onFocus = (e) => {
    e.preventDefault();
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, updateData?.phone);
      setUpdateData({
        ...updateData,
        phone: cleanValue,
      });
    }
  };

  useEffect(() => {
    if (updateData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, updateData?.phone);
      setUpdateData({
        ...updateData,
        phone: cleanValue,
      });
    }
  }, [updateData?.phone, error?.phone]);

  const isUpdateAble =
    Object.values(error).every((e) => e !== undefined) &&
    Object.keys(error).length > 0;

  return useLoader(getBoard) ? (
    <Loader />
  ) : (
    <div className="container-fluid">
      <div className="row mb-4">
        <HeadTags
          hSize={3}
          strong
          text={t('headerMyProfile.personal_information')}
        />
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('personalInformation.profile_picture')}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center">
                  {userInfo.userImage || imgSrc ? (
                    <img
                      className={'img-fluid rounded-4 me-1'}
                      src={imgSrc ? imgSrc : userInfo.userImage}
                      alt={userInfo.userImage}
                      height={200}
                      style={{ maxHeight: '200px' }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faCircleUser} size="10x" />
                  )}
                </div>
                <CustomFileInput
                  id={'userImage'}
                  hidden={true}
                  buttonText={t('personalInformation.upload_picture')}
                  textColor={TextColors.Light}
                  buttonColor={ButtonType.Successbg}
                  fileType={'Image'}
                  dataFrom={'Profile'}
                  removeState={imgSrc === '' ? true : false}
                  func={(val) => {
                    if (!val) {
                      setUpdateData({});
                      setImgSrc('');
                      setDataChanged(false);
                      const { extension, ...userDataWithoutExtension } =
                        userData;
                      dispatch(
                        Actions.userActions.holdValue({
                          ...userDataWithoutExtension,
                          userImage: '',
                        })
                      );
                    } else {
                      setUpdateData(
                        Object.assign({}, userData, {
                          userImage: val.base64String,
                          extension: val.extension,
                        })
                      );
                      setImgSrc(val.imgSrc);
                      setDataChanged(false);
                      dispatch(
                        Actions.userActions.holdValue(
                          Object.assign({}, userData, {
                            userImage: val.base64String,
                            extension: val.extension,
                          })
                        )
                      );
                    }
                  }}
                />
                {lengthCurrentUserClaim ? (
                  <div className="col-12 d-flex justify-content-center mt-4 form-div-border"></div>
                ) : undefined}
                {lengthCurrentUserClaim ? (
                  <div className="row d-flex text-center mt-3">
                    <div className="col-3 d-flex fw-bold">
                      <div className="row">
                        <div className="col-12">
                          <span>{sessionCounts?.onlineSessions}</span>
                        </div>
                        <div
                          className="col-12 text-muted"
                          style={{ fontSize: '.75rem' }}
                        >
                          <span>{t('personalInformation.online')}</span>
                          <br />
                          <span
                            style={{ position: 'relative', bottom: '.6rem' }}
                          >
                            {t('calendar.session')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 d-flex fw-bold">
                      <div className="row">
                        <div className="col-12">
                          <span>{sessionCounts?.cafeSessions}</span>
                        </div>
                        <div
                          className="col-12 text-muted"
                          style={{ fontSize: '.75rem' }}
                        >
                          <span>{t('personalInformation.cafe')}</span>
                          <br />
                          <span
                            style={{ position: 'relative', bottom: '.6rem' }}
                          >
                            {t('calendar.session')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 d-flex fw-bold">
                      <div className="row">
                        <div className="col-12">
                          <span>{sessionCounts?.kidsSessions}</span>
                        </div>
                        <div
                          className="col-12 text-muted"
                          style={{ fontSize: '.75rem' }}
                        >
                          <span>{t('personalInformation.kids')}</span>
                          <br />
                          <span
                            style={{ position: 'relative', bottom: '.6rem' }}
                          >
                            {t('calendar.session')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 d-flex fw-bold">
                      <div className="row">
                        <div className="col-12">
                          <span>{sessionCounts?.companySessions}</span>
                        </div>
                        <div
                          className="col-12 text-muted"
                          style={{ fontSize: '.75rem' }}
                        >
                          <span>{t('personalInformation.company')}</span>
                          <br />
                          <span
                            style={{ position: 'relative', bottom: '.6rem' }}
                          >
                            {t('calendar.session')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : undefined}
              </>
            }
          />
        </div>
        <div className="col-lg-7">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('personalInformation.card_header')}
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    <InputLabelOnBorder
                      id={'firstName'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={updateData.firstName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.firstName}
                      regexType={RegexType.Name}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <InputLabelOnBorder
                      id={'lastName'}
                      inputType={InputType.Text}
                      labelText={t('common.lastName')}
                      inputValue={updateData.lastName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.lastName}
                      regexType={RegexType.Name}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Text}
                      labelText={t('common.email')}
                      inputValue={updateData.email || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.country_code')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: countryList.map((item) => ({
                          key: `${item.name} (${item.phone})`,
                          value: `${item.phone} ${item.isoName}`,
                          name: 'countryCode',
                        })),
                        selectedValue: updateData?.countryCode || '',
                      }}
                      handleSelectChange={(val) => {
                        setUpdateData({
                          ...updateData,
                          [val.name]: val.value,
                        });
                        setDataChanged(false);
                        dispatch(
                          Actions.userActions.holdValue({
                            ...updateData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className={`d-flex col-md-6 mt-4`}>
                    <InputLabelOnBorder
                      id={'phone'}
                      inputType={InputType.Tel}
                      labelText={t('common.phone')}
                      inputValue={formatPhone(updateData?.phone) || ''}
                      placeholder={'(5xx) xxx xx xx'}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      error={error?.phone}
                    />
                    {!userInfo?.phoneIsVerified && (
                      <button
                        className={`btn btn-info ms-1 text-nowrap`}
                        style={{
                          color: 'white',
                          fontSize: '10px',
                          padding: '3px 6px',
                        }}
                        onClick={() => phoneConfirmationModal.current.open()}
                      >
                        {t('personalInformation.startConfirm')}
                      </button>
                    )}
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('personalInformation.nationality')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: countryList.map((item) => ({
                          key: item.name,
                          value: item.name,
                          name: 'country',
                        })),
                        selectedValue: updateData?.country || '',
                      }}
                      handleSelectChange={(val) => {
                        setUpdateData({
                          ...updateData,
                          [val.name]: val.value,
                        });
                        setDataChanged(false);
                        dispatch(
                          Actions.userActions.holdValue({
                            ...updateData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                    />{' '}
                  </div>
                  {lengthCurrentUserClaim &&
                    (userInfo.languageLevel === '' ? (
                      <div className="col-6 mt-4">
                        <InputLabelOnBorder
                          labelText={t('personalInformation.english_level')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: selectedLanguageLevels,
                            selectedValue: updateData?.languageLevel || '',
                            selectedProp: 'key',
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              languageLevel: val.key,
                            });
                            setDataChanged(false);
                            dispatch(
                              Actions.userActions.holdValue({
                                ...updateData,
                                languageLevel: val.key,
                              })
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div className="col-6 mt-4">
                        {userInfo.languageLevel !== '' &&
                          userInfo.languageLevel !== undefined && (
                            <div className="d-flex align-items-center">
                              <label className="flex-shrink-1  ">
                                <Tooltip
                                  bottom={70}
                                  id={'profile_language_level'}
                                  text={
                                    <span className="text-dark fw-bold text-nowrap ">
                                      {t('personalInformation.tooltip')}
                                    </span>
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="text-warning mt-2"
                                    size="3x"
                                    icon={faCircleExclamation}
                                  />
                                </Tooltip>
                              </label>
                              <div className="flex-grow-0">
                                <InputLabelOnBorder
                                  labelText={t(
                                    'personalInformation.english_level'
                                  )}
                                  inputType={InputType.Text}
                                  inputValue={t(`${userInfo?.languageLevel}`)}
                                  disabled
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'dateOfBirth'}
                      inputType={InputType.Date}
                      labelText={t('personalInformation.birth_date')}
                      inputValue={
                        moment(updateData.dateOfBirth).format('yyyy-MM-DD') ||
                        ''
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        dataChanged || isUpdateAble
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('personalInformation.save')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      disabled={dataChanged || isUpdateAble ? true : false}
                      buttonCol={3}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      <ModalInsideComponent
        ref={phoneConfirmationModal}
        title={ModalTitles.phoneConfirmation}
        child={
          phoneConfirmationInfo === '' ? (
            <PhoneConfirmation
              phoneConfirmInfo={(val) => setPhoneConfirmationInfo(val)}
            />
          ) : (
            phoneConfirmationInfo
          )
        }
        onClose={() => {
          if (phoneConfirmationInfo !== '') {
            onSave();
            setPhoneConfirmationInfo('');
          }
          phoneConfirmationModal.current?.close();
        }}
      />
      {/* <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-7">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags hSize={6} strong text={'Connections'} />
                </div>
                <div className="row">
                  <div className="col-1 text-center">
                    <FontAwesomeIcon
                      className="fa-xl mt-3 ms-3"
                      icon={faSquareFacebook}
                    />
                  </div>
                  <div className="col-7">
                    <Input
                      className={'form-control mt-2 form-input-border'}
                      placeholder={'Facebook Account Connection'}
                      disabled
                    />
                  </div>
                  <div className="col-4 mt-2">
                    <Button
                      type={ButtonType.Successbg}
                      text={'Connect'}
                      textColor={TextColors.Light}
                      buttonCol={11}
                      onClick={onFacebookCon}
                    />
                  </div>
                  <div className="col-1 text-center">
                    <FontAwesomeIcon
                      className="fa-xl mt-3 ms-3"
                      icon={faGoogle}
                    />
                  </div>
                  <div className="col-7">
                    <Input
                      className={'form-control mt-2 form-input-border'}
                      placeholder={'Google Account Connection'}
                      disabled
                    />
                  </div>
                  <div className="col-4 mt-2">
                    <Button
                      type={ButtonType.Successbg}
                      text={'Connect'}
                      textColor={TextColors.Light}
                      buttonCol={11}
                      onClick={onGoogleCon}
                    />
                  </div>
                  <div className="col-1 text-center">
                    <FontAwesomeIcon
                      className="fa-xl mt-3 ms-3"
                      icon={faApple}
                    />
                  </div>
                  <div className="col-7">
                    <Input
                      className={'form-control mt-2 form-input-border'}
                      placeholder={'Apple Account Connection'}
                      disabled
                    />
                  </div>
                  <div className="col-4 mt-2">
                    <Button
                      type={ButtonType.Successbg}
                      text={'Connect'}
                      textColor={TextColors.Light}
                      buttonCol={11}
                      onClick={onAppleCon}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div> */}
    </div>
  );
};

export default PersonalInformation;
