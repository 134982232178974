import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import AdminDashboard from './DashboardSubs/AdminDashboard';
import ManagerDashboard from './DashboardSubs/ManagerDashboard';
import SpeakerDashboard from './DashboardSubs/SpeakerDashboard';
import AttendeeDashboard from './DashboardSubs/AttendeeDashboard';
import { storageKeys } from '../../../utils/Enum';
import { useOutletContext } from 'react-router-dom';
import findMyPath from '../../../hooks/useFindMyPath';
import Error404 from '../../Landing/CommonPages/Error404';
import getCookieItem from '../../../hooks/useGetCookieItem';
import removeCookieItem from '../../../hooks/useRemoveCookie.js';
import CompanyDashboard from './DashboardSubs/CompanyDashboard.jsx';

const Dashboard = () => {
  const props = useOutletContext();
  const userInfo = useSelector((e) => e.user.getById);
  const currentUserClaim = props?.props?.currentUserClaim;
  const getBoard = useSelector((e) => e.onboard.onboard);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const currentUrl = window.location.pathname;
  const [selectedDashboard, setSelectedDashboard] = useState({});
  const isFirstRegister = () => getCookieItem(storageKeys.firstRegister);

  const firstRegister = isFirstRegister();
  const firstRegisterAsBool = !!firstRegister; // first register language level modal <<<---

  function canDeleteItems(currentUserClaims) {
    const deleteClaim = Claims.backofficeClaims.manager.delete;
    return (
      !lengthCurrentUserClaim && checkClaims(currentUserClaims, deleteClaim)
    );
  }

  function canCreateItems(currentUserClaims) {
    const createClaim = Claims.backofficeClaims.manager.create;
    return (
      !lengthCurrentUserClaim &&
      checkClaims(currentUserClaims, createClaim, undefined)
    );
  }

  function isCompanyManager(currentUserClaims) {
    const companyManager = Claims.actionClaims.companyOrder;
    return (
      !lengthCurrentUserClaim &&
      checkClaims(currentUserClaims, undefined, undefined, companyManager)
    );
  }
  function isManagerOrAdmin(currentUserClaims) {
    return (
      !lengthCurrentUserClaim &&
      !canDeleteItems(currentUserClaims) &&
      !canCreateItems(currentUserClaims) &&
      !isCompanyManager(currentUserClaims)
    );
  }

  useEffect(() => {
    const isFirstRegisterLanding = () =>
      getCookieItem(storageKeys.firstRegisterLanding);
    const fromRegister = isFirstRegisterLanding();
    if (fromRegister) {
      removeCookieItem(storageKeys.firstRegisterLanding);
    }
    if (canDeleteItems(currentUserClaim)) {
      const findPath = findMyPath('dashboard');
      const pathCheck = findPath === currentUrl;
      setSelectedDashboard({ admin: pathCheck });
    } else if (canCreateItems(currentUserClaim)) {
      const findPath = findMyPath('dashboard');
      const pathCheck = findPath === currentUrl;
      setSelectedDashboard({ manager: pathCheck });
    } else if (isCompanyManager(currentUserClaim)) {
      const findPath = findMyPath('dashboard');
      const pathCheck = findPath === currentUrl;
      setSelectedDashboard({ company: pathCheck });
    } else if (isManagerOrAdmin(currentUserClaim)) {
      const findPath = findMyPath('dashboard');
      const pathCheck = findPath === currentUrl;
      setSelectedDashboard({ speaker: pathCheck });
    } else {
      const findPath = findMyPath('dashboard');
      const pathCheck = findPath === currentUrl;
      setSelectedDashboard({ attendee: pathCheck });
    }
  }, []);

  switch (true) {
    case useLoader(getBoard):
      return <Loader />;
    case selectedDashboard?.admin:
      return <AdminDashboard />;
    case selectedDashboard?.manager:
      return <ManagerDashboard />;
    case selectedDashboard?.company:
      return <CompanyDashboard />;
    case selectedDashboard?.speaker:
      return <SpeakerDashboard />;
    case selectedDashboard?.attendee:
      return (
        <AttendeeDashboard
          askLanguageLevel={false}
          askBirthDate={userInfo.userArea === 2 && firstRegisterAsBool}
        />
      );
    default:
      return <Error404 />;
  }
};

export default Dashboard;
