import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function ProgressLine({
  col,
  marginLeft,
  textMarginLeft,
  text,
  iconColor,
  lastItem,
  noLine,
  icon,
}) {
  return (
    <>
      <div className={` ${lastItem ? 'flex-shrink-1' : `flex-grow-1 `}`}>
        <div className={`ps-${marginLeft}`}>
          <div className="row">
            <div className="d-flex align-items-center ">
              <div className=" p-0 m-0 align-items-center d-block ">
                {icon ? (
                  <FontAwesomeIcon
                    className={iconColor}
                    icon={icon}
                    size="2x"
                  />
                ) : undefined}
              </div>
              {lastItem ? undefined : (
                <div
                  style={{ flex: 1 }}
                  className="p-0 m-0 border border-bottom-3"
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className={`ps-${textMarginLeft}`}>
          <strong>{text}</strong>
        </div>
      </div>
      {/* {lastItem ? (
        <div className="flex-shrink">
          <div className={`ps-${marginLeft}`}>
            <FontAwesomeIcon className="text-custom" icon={faCheckCircle} />
          </div>
          <div className={`ps-${textMarginLeft}`}>
            <strong style={{ fontSize: '0.85em' }}>{text}</strong>
          </div>
        </div>
      ) : undefined} */}
    </>
  );
}
