import React, { useState } from 'react';
import Input from '../Input/Input';
import { InputType } from '../../../utils/ComponentEnums';
import Dropdown from '../Dropdown/Dropdown';

const Element = ({
  field: { type, id, label, value, data, title },
  onChange,
}) => {
  switch (type) {
    case 'Input': //InputType.Text
      return (
        <Input
          className="m-2"
          type={InputType.Text}
          id={id}
          labelValue={label}
          value={value}
          onChange={onChange}
        />
      );
    case 'Number':
      return (
        <Input
          className="m-2"
          style={{ maxWidth: '75px' }}
          type={InputType.Number}
          id={id}
          labelValue={label}
          value={value}
          onChange={onChange}
        />
      );
    case 'Checkbox': //InputType.Checkbox
      return (
        <Input
          className="m-2 "
          type={InputType.Checkbox}
          id={id}
          labelValue={label}
          value={value}
          checked={value}
          onChange={onChange}
        />
      );
    case 'Radio': //InputType.Checkbox
      return (
        <Input
          className="m-2 "
          type={InputType.Radio}
          id={id}
          labelValue={label}
          value={value}
          checked={value}
          onChange={onChange}
        />
      );

    case 'DropDown': //Dropdown
      return (
        <Dropdown
          name={title}
          options={data}
          id={id}
          propNames={{ id: 'id', key: 'key', value: 'value', name: 'name' }}
          //handleChange={handleSelectChange}
        />
      );

    default:
      return null;
  }
};

export default Element;
