import React, { useEffect, useState } from 'react';
import Table from '../../../components/Common/Table/Table';
import Card from '../../../components/Common/Card/Card';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import addDay from '../../../hooks/useAddDay';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/Common/Input/Input';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import Toast from '../../../components/Common/Popup/Toast';
import { ActionFilterRules, Entities } from '../../../utils/Enum';

const MeetingDetails = ({
  requestData,
  handleBack,
  date,
  filter,
  calendarRequest,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const popupDetails = useSelector((e) => e.calendar.getCellDatas);
  const data = useSelector((e) => e.calendar.getMeetings);
  const isManager = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.get
  );
  const [buttonText, setButtonText] = useState({});
  const [handlePopup, setHandlePopup] = useState({
    isPrivateSession: false,
    cellDataLength: 0,
    isRecompense: false,
  });

  useEffect(() => {
    if (popupDetails?.contents !== undefined) {
      setHandlePopup({
        ...handlePopup,
        cellDataLength: popupDetails?.contents?.length,
      });
    }
  }, [popupDetails]);

  function extractSpeakerIds(data) {
    if (!data || !Array.isArray(data?.contents)) {
      return [];
    }
    const speakerIds = data.contents.map((item) => item.speakerId);

    return speakerIds;
  }
  const getCell = () => {
    dispatch(Actions.calendarActions.getMeetingAttendeesReducer([]));
    const arr = extractSpeakerIds(popupDetails);
    const changes = {
      ...filter,
      StartDateTime: addDay(date.time, date.day, true),
      SelectedIds: arr,
    };
    dispatch(Actions.calendarActions.getCellDetailsBOAction(changes));
  };

  const toOperationAction = (val) => {
    const url = new URL('/operations', window.location.origin);
    const state = {
      attendee: {
        id: val.supId,
        name: val.name ?? '',
        email: val.email,
        phone: val.phone ?? '',
      },
    };
    url.search = new URLSearchParams(state.attendee).toString();
    window.open(url.toString(), '_blank');
  };

  const recompenseAction = (val) => {
    setHandlePopup({
      ...handlePopup,
      isPrivateSession: val.isPrivateSession,
      isRecompense: true,
    });
    const recompenseData = {
      redatedMeetingAttendeeId: val.meetingAttendeeId,
      orderItemId: val.orderItemId,
      redateFrom: `${val.date}T${val.interval.split('-')[0]}:00.000Z`,
    };
    dispatch(Actions.meetingAttendeeActions.recompenseAction(recompenseData));
  };

  const unRecompenseAction = (val) => {
    setHandlePopup({ ...handlePopup, isPrivateSession: val.isPrivateSession });
    const recompenseData = {
      newMeetingAttendeeId: val.meetingAttendeeId,
      orderItemId: val.orderItemId,
    };
    dispatch(Actions.meetingAttendeeActions.unRecompenseAction(recompenseData));
  };

  const forwardAction = (val) => {
    navigate(`/meetings/${val.supId}/${val.orderItemId}`, {
      state: {
        id: val.orderItemId,
        name: val.name,
        email: val.email,
        phone: val.phone,
        attendeeId: val.supId,
      },
    });
  };

  const handleClick = (url, i) => {
    if (isManager) {
      navigator.clipboard.writeText(url);
      setButtonText((prevButtonTexts) => ({
        ...prevButtonTexts,
        [i]: t('buy.eft_copy'),
      }));
      setTimeout(() => {
        setButtonText({});
      }, 1200);
    } else {
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.calendarActions.getMeetingAttendeesReducer([]));
      dispatch(Actions.meetingAttendeeActions.cleanState(true));
    };
  }, []);

  const headers = [
    { key: 'attendeeId', label: t('common.attendeeId'), perm: isManager },
    { key: 'name', label: t('common.name') },
    { key: 'phone', label: t('common.phone'), perm: isManager },
    { key: 'date', label: t('meeting.table_meetingDate') },
    { key: 'interval', label: t('meeting.table_interval') },
    { key: 'status', label: t('meeting.table_status') },
    { key: 'trialStatus', label: t('orderItem.trialStatus'), perm: isManager },
    { key: 'meetingType', label: t('orderItem.meetingType'), perm: isManager },
    {
      key: 'orderItemId',
      label: t('common.orderItemId'),
      perm: isManager,
    },
  ];

  return (
    <div className="container-fluid">
      <Card
        body={
          <div className="row">
            <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
              <div className="col-2">
                <Button
                  type={ButtonType.Successbg}
                  icon={ButtonIcon.ArrowLeft}
                  iconColor={TextColors.Light}
                  bold
                  onClick={() => {
                    getCell();
                    handleBack(false);
                  }}
                  buttonCol={4}
                />
              </div>
            </div>
            <div className="d-sm-flex align-items-center justify-content-center form-div-border">
              <HeadTags hSize={3} strong text={t('meeting.attendeeList')} />
            </div>
            {data?.zoomLinks &&
              Object.keys(data.zoomLinks).map(
                (key, i) =>
                  data.zoomLinks[key] !== null && (
                    <div
                      className="row mt-1 col-lg-12 m-0"
                      style={{ transform: 'scale(0.9)' }}
                      key={i}
                    >
                      <div className="col-sm-12 col-md-8 m-0">
                        <Input
                          className={'form-control border'}
                          type={InputType.Url}
                          value={data.zoomLinks[key]}
                          disabled
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 m-0">
                        <Button
                          id={`share-${i}`}
                          text={buttonText[i] || `Session ${i + 1}`}
                          type={
                            buttonText[i] !== undefined
                              ? ButtonType.Secondary
                              : ButtonType.Info
                          }
                          textColor={TextColors.Light}
                          buttonCol={12}
                          bold
                          onClick={() => handleClick(data.zoomLinks[key], i)}
                          disabled={buttonText[i] !== undefined ? true : false}
                        />
                      </div>
                    </div>
                  )
              )}
            <Table
              data={data?.list ?? []}
              headers={headers}
              striped
              bordered
              page={'meetingDetails'}
              currentUserClaim={currentUserClaim}
              forwardPage={Entities.meeting}
              toOperation={(val) => toOperationAction(val)}
              recompense={(val) => recompenseAction(val)}
              unRecompense={(val) => unRecompenseAction(val)}
              forward={(val) => forwardAction(val)}
              exportName={Entities.meetingAttendee.exportName}
              filterActionsRule={true}
              filterRule={ActionFilterRules.meetingDetails}
            />
          </div>
        }
      />
      <Toast
        id={'meetingDetails'}
        stateName={'meetingAttendee'}
        onClose={() => {
          dispatch(Actions.meetingAttendeeActions.cleanState(true));
          if (
            handlePopup.isPrivateSession &&
            handlePopup.cellDataLength === 1
          ) {
            calendarRequest();
          } else if (
            !handlePopup.isPrivateSession &&
            handlePopup.cellDataLength !== 1
          ) {
            dispatch(
              Actions.calendarActions.getMeetingAttendeesAction(requestData)
            );
          } else if (
            handlePopup.isPrivateSession &&
            handlePopup.cellDataLength !== 1
          ) {
            getCell();
            handleBack(false);
          } else if (handlePopup.isRecompense) {
            dispatch(
              Actions.calendarActions.getMeetingAttendeesAction(requestData)
            );
          }
        }}
      />
    </div>
  );
};

export default MeetingDetails;
