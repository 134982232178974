import { postRequest, putRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const recompense = async (data, id = '') => {
  return await postRequest(
    `${endPoint.meetingRedate.Recompense}${id !== '' ? '/' + id : ''}`,
    data
  );
};
export const setDate = async (data, id = '') => {
  return await putRequest(
    `${endPoint.meetingRedate.SetDate}${id !== '' ? '/' + id : ''}`,
    data
  );
};
export const unRecompense = async (data) => {
  return await postRequest(
    `${endPoint.meetingRedate.UnRecompense}`,
    data
  );
};
export const recompenseMeeting = async (meetingId) => {
  return await postRequest(
    `${endPoint.meetingRedate.RecompenseMeeting}/${meetingId}`
  );
};
export const cancelMeeting = async (meetingId) => {
  return await postRequest(
    `${endPoint.meetingRedate.CancelMeeting}/${meetingId}`
  );
};