import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Common/Button/Button';
import { ButtonType, RegexType, TextColors } from '../../utils/ComponentEnums';
import Card from '../../components/Common/Card/Card';
import { InputType } from '../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../store/redux/actions';
import InputLabelOnBorder from '../../components/Common/Input/InputLabelOnBorder';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import regexValidator from '../../hooks/useRegexValidator';
import validationMessage from '../../hooks/useValidationMessage';
import Modal from '../../components/Common/Modal/Modal';
import Privacy from '../UI/CommonPages/Privacy';

const LeadForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get('campaignName');
  const isSuccess = useSelector((e) => e.lead.isSuccess);
  const validationMessages = useSelector((e) => e.lead.validationMessage);
  const learningPurpose = useSelector((e) => e.staticData.LearningPurpose);
  const staticDatas = useSelector((e) => e.staticData);
  const levelGroup = useSelector(
    (e) => e.preferenceOptions.preferenceLevelGroup
  );
  const [createData, setCreateData] = useState({
    privacyConsent: false,
    campaignName: key,
  });
  const [handleError, setHandleError] = useState('');
  const [error, setError] = useState({});
  const [privacyAndTermsChecked, setPrivacyAndTermsChecked] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [handleValidationMessages, setHandleValidationMessage] =
    useState(validationMessages);
  const locationList = useSelector((e) => e.location.getAll);

  const showPrivacyPolicyModalHandler = () => {
    if (!privacyAndTermsChecked) {
      setPrivacyModalVisible(true);
    }
  };
  const handlePrivacyCheckboxChange = () => {
    setPrivacyAccepted(!privacyAccepted);
    setPrivacyModalVisible(false);
  };
  useEffect(() => {
    if (privacyAccepted) {
      setCreateData({
        ...createData,
        privacyConsent: true,
      });
      setPrivacyAndTermsChecked(true);
    }
  }, [privacyAccepted]);
  useEffect(() => {
    setHandleValidationMessage(validationMessages);
  }, [validationMessages]);

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          setCreateData({});
          setHandleError('');
          dispatch(Actions.leadActions.cleanState());
          navigate('/form-success');
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && handleValidationMessages !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{handleValidationMessages}</strong>
        </span>
      );
    } else if (!isSuccess && handleValidationMessages !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{handleValidationMessages}</strong>
        </span>
      );
    }
  }, [isSuccess, handleValidationMessages]);

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\+90\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/;
    return phoneRegex.test(value);
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;

    let formattedValue = value;

    if (id === 'phone') {
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.startsWith('90')) {
        formattedValue = '+90';

        const digits = numericValue.substring(2, 12);
        if (digits.length >= 10) {
          formattedValue +=
            ' ' + digits.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
        } else {
          formattedValue += ' ' + digits;
        }
      }
    }

    if (id === 'note' && value.length > 400) {
      formattedValue = value.substring(0, 400);
    }

    setCreateData({ ...createData, [id]: formattedValue });

    const validationResult =
      id === 'phone'
        ? validatePhoneNumber(formattedValue)
        : regexValidator(type, value, name);

    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };

  const onCreate = async (e) => {
    e.preventDefault();
    if (createData.privacyConsent === false) {
      setHandleError(
        <span className="text-danger">{t('lead.please_read_and_check')}</span>
      );
    } else if (
      !createData.name ||
      !createData.email ||
      !createData.phone ||
      !createData.learningPurpose ||
      !createData.languageLevel ||
      !createData.location ||
      !createData.ageInterval
    ) {
      setHandleError(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    } else await dispatch(Actions.leadActions.createAction(createData));
  };

  async function GetLists() {
    await dispatch(Actions.locationActions.getAllAction());
  }
  useEffect(() => {
    GetLists();
  }, []);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult) {
    let languageLevels = staticDatas.LanguageLevels?.[levelGroup.adult];

    selectedLanguageLevels.push(...languageLevels);
  }

  const onFocus = (e) => {
    e.preventDefault();
    if (!createData.phone || createData.phone.trim() === '') {
      setCreateData({ ...createData, phone: '+90' });
    }
  };
  const text = t('lead.privacy_policy');
  const parts = text.split(/(\{\{privacy_policy\}\}|\{\{terms_of_use\}\})/);

  const [mobileView, setMobileView] = useState();
  const handleResize = () => {
    const currentWidth = window.innerWidth;
    if (currentWidth === 663 || currentWidth < 577) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  useEffect(() => {
    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container">
      <div className="d-flex justify-content-center mt-4">
        <div className="col-11 banner-card">
          <div className="green-section">
            {!mobileView &&
              t('lead.title')
                ?.split('{br}')
                ?.map((item, index) => (
                  <span
                    key={index}
                    className={`d-flex position-relative justify-content-start offset-2 text-white ${
                      index === 0 ? 'mt-4 fs-3' : 'mt-2 fs-5'
                    }`}
                  >
                    <strong>{item}</strong>
                  </span>
                ))}
            {mobileView &&
              t('lead.title')
                ?.split('{br}')
                ?.map((item, index) => (
                  <span
                    key={index}
                    className={`d-flex position-relative justify-content-start offset-3 text-white ${
                      index === 0 ? 'mt-2' : 'mt-1'
                    }`}
                  >
                    <strong>{item}</strong>
                  </span>
                ))}
          </div>
          <div className="red-section">
            {!mobileView &&
              t('lead.description')
                ?.split('{br}')
                ?.map((item, index) => (
                  <span
                    key={index}
                    className={`d-flex position-relative justify-content-start text-dark fs-4 ${
                      index === 0 ? 'mt-4' : 'mt-2'
                    }`}
                    style={{ marginLeft: '460px' }}
                  >
                    <strong>{item}</strong>
                  </span>
                ))}
            {mobileView &&
              t('lead.description')
                ?.split('{br}')
                ?.map((item, index) => (
                  <span
                    key={index}
                    className={`d-flex position-relative justify-content-center text-dark ${
                      index === 0 ? 'mt-1' : ''
                    }`}
                    style={{ marginLeft: '130px', fontSize: '.65rem' }}
                  >
                    <strong>{item}</strong>
                  </span>
                ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <div className="col-11">
          <Card
            body={
              <React.Fragment>
                <div className="container mb-3">
                  <div className="row">
                    <div className="col-12 col-md-6 order-1 order-md-0">
                      <div className="row">
                        <div className="col-md-6">
                          <InputLabelOnBorder
                            id={'name'}
                            inputType={InputType.Text}
                            labelText={t('speakerEffort.full_name') + '**'}
                            inputValue={createData.name || ''}
                            onChange={onChangeText}
                            error={error?.name}
                            regexType={RegexType.Name}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputLabelOnBorder
                            id={'email'}
                            inputType={InputType.Email}
                            labelText={t('common.email') + '**'}
                            inputValue={createData.email || ''}
                            onChange={onChangeText}
                            error={error?.email}
                          />
                        </div>
                        <div className="col-md-6 mt-2">
                          <InputLabelOnBorder
                            id={'phone'}
                            inputType={InputType.Tel}
                            labelText={t('common.phone') + '**'}
                            inputValue={createData.phone || ''}
                            onChange={onChangeText}
                            placeholder={'+90 (5xx) xxx xx xx'}
                            onFocus={onFocus}
                            error={error?.phone}
                          />
                        </div>
                        <div className="col-md-6 mt-2">
                          <InputLabelOnBorder
                            labelText={
                              t('dropdownSelect.select_learningPurpose') + '**'
                            }
                            inputType={InputType.Text}
                            select={{
                              selectStyle: 'selectStyle',
                              option: learningPurpose,
                            }}
                            handleSelectChange={(val) => {
                              setCreateData({
                                ...createData,
                                learningPurpose: t(val.key, { lng: 'en' }),
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6 mt-2">
                          <InputLabelOnBorder
                            labelText={
                              t('dropdownSelect.select_level') +
                              ' ' +
                              t('lead.languageLevelsDesc') +
                              ' ' +
                              '**'
                            }
                            inputType={InputType.Text}
                            select={{
                              selectStyle: 'selectStyle',
                              option: selectedLanguageLevels,
                              addIndex: true,
                            }}
                            handleSelectChange={(val) => {
                              setCreateData({
                                ...createData,
                                languageLevel: t(val.value, { lng: 'en' }),
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6 mt-2">
                          <InputLabelOnBorder
                            labelText={
                              t('dropdownSelect.select_location') + '**'
                            }
                            inputType={InputType.Text}
                            select={{
                              selectStyle: 'selectStyle',
                              option: locationList
                                .filter(
                                  (i) =>
                                    i.locationName !== 'Company' &&
                                    i.locationName !== 'CompanyOnline'
                                )
                                .map((item) => ({
                                  key: item.locationName,
                                  value: item.id,
                                  name: 'location',
                                })),
                              special: true,
                            }}
                            handleSelectChange={(val) => {
                              setCreateData({
                                ...createData,
                                [val.name]: val.key,
                              });
                            }}
                          />{' '}
                        </div>
                        <div className="col-md-6 mt-2">
                          <InputLabelOnBorder
                            labelText={
                              t('dropdownSelect.select_age_interval') + '**'
                            }
                            inputType={InputType.Text}
                            select={{
                              selectStyle: 'selectStyle',
                              option: [
                                {
                                  key: '18 - 24',
                                  value: '18-24',
                                  name: 'ageInterval',
                                },
                                {
                                  key: '24 - 35',
                                  value: '24-35',
                                  name: 'ageInterval',
                                },
                                {
                                  key: '35 - 50',
                                  value: '35-50',
                                  name: 'ageInterval',
                                },
                                {
                                  key: '50+',
                                  value: '50+',
                                  name: 'ageInterval',
                                },
                              ],
                            }}
                            handleSelectChange={(val) => {
                              setCreateData({
                                ...createData,
                                [val.name]: val.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-12 mt-2 py-1">
                          <InputLabelOnBorder
                            id={'note'}
                            inputType={InputType.Text}
                            labelText={t('lead.english_background')}
                            placeholder={t(
                              'lead.english_background_description'
                            )}
                            inputValue={createData.note || ''}
                            onChange={onChangeText}
                            onFocus={onFocus}
                            textArea={{ row: 3, col: 12 }}
                          />
                        </div>
                        <div className="col-12 mt-2">
                          <div className="text-dark text-start align-items-center">
                            <div className="form-check pe-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="marketAndCommercial"
                              />
                              <label className="form-check-label text-gray-700">
                                {t(
                                  'register.marketing_and_commercial_communications'
                                )}
                              </label>
                            </div>
                            <div className="form-check pe-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={privacyAndTermsChecked}
                                id="privacyAndTerms"
                                defaultChecked={privacyAndTermsChecked}
                                required
                                checked={privacyAndTermsChecked}
                                onClick={() => {
                                  showPrivacyPolicyModalHandler();
                                  setHandleError('');
                                  setHandleValidationMessage('');
                                }}
                              />
                              <label className="form-check-label text-gray-700">
                                {parts.map((part, index) => {
                                  if (index === 0) {
                                    return <span key={index}>{part}</span>;
                                  }
                                  const key = part.includes('privacy_policy')
                                    ? 'privacy_policy'
                                    : 'terms_of_use';
                                  return (
                                    <span key={index}>
                                      {part.startsWith('{{') ? (
                                        <span
                                          onClick={() => {
                                            if (key === 'privacy_policy') {
                                              setPrivacyModalVisible(
                                                !privacyModalVisible
                                              );
                                            }
                                            setHandleError('');
                                            setHandleValidationMessage('');
                                          }}
                                          style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {key === 'privacy_policy'
                                            ? t('register.privacy_policy')
                                            : t('register.terms_of_use')}
                                        </span>
                                      ) : (
                                        <span>{part}</span>
                                      )}
                                    </span>
                                  );
                                })}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center justify-content-md-end mt-2">
                          <div className="col-12 col-md-2">
                            <Button
                              type={
                                Object.keys(createData).length > 8 &&
                                Object.values(error).every(
                                  (e) => e === undefined
                                )
                                  ? ButtonType.Successbg
                                  : ButtonType.Secondary
                              }
                              text={t('button.common_submit')}
                              textColor={TextColors.Light}
                              onClick={(e) => onCreate(e)}
                              buttonCol={12}
                              disabled={
                                Object.keys(createData).length > 8 &&
                                Object.values(error).every(
                                  (e) => e === undefined
                                )
                                  ? false
                                  : true
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3 order-0 order-md-1">
                      <div className="ratio ratio-custom">
                        <iframe
                          className="embed-responsive-item rounded-4"
                          src="https://www.youtube.com/embed/rXzN1GZZBgo?autoplay=1&mute=1&loop=1&cc_load_policy=1&playlist=rXzN1GZZBgo"
                          title="Meet2Talk"
                          allowFullScreen
                          loading="lazy"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          style={{ boxShadow: '9px 9px #00c905' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
      <Modal
        isVisible={privacyModalVisible}
        content={<Privacy />}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        footer={
          <Button
            type={ButtonType.Successbg}
            textColor={TextColors.Light}
            bold
            buttonCol={3}
            text={t('button.accept')}
            onClick={handlePrivacyCheckboxChange}
          />
        }
        onClose={() => {
          setPrivacyModalVisible(false);
        }}
      />
    </div>
  );
};
export default LeadForm;
