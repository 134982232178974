import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const userOperationClaimReducer = (
  state = initialState.userOperationClaim,
  action
) => {
  switch (action.type) {
    case ActionTypes.userOperationClaim
      .HANDLE_ERROR_USER_OPERATION_CLAIM_ACTION:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.userOperationClaim
      .HANDLE_VALIDATION_USER_OPERATION_CLAIM_ACTION:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default userOperationClaimReducer;
