import {
  create,
  getPrivateRoomMembers,
  getPrivateMessages,
  getGroupMessages,
  getGroupRoomMembers,
  hideClassroomGroup,
} from '../../../services/apiServices/classroomServices';
import ActionTypes from './actionTypes';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(createReducer());
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.classroom.COMMIT_MESSAGE_ACTION_CLASSROOM };
};

const getPrivateRoomMembersAction = (requestType) => {
  return async (dispatch) => {
    await getPrivateRoomMembers(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getPrivateRoomMembersReducer(response));
    });
  };
};
const getPrivateRoomMembersReducer = (data) => {
  return {
    type: ActionTypes.classroom.GET_MEMBERS_ACTION_CLASSROOM,
    payload: { data },
  };
};

const getPrivateMessagesAction = (requestType) => {
  return async (dispatch) => {
    await getPrivateMessages(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getPrivateMessagesReducer(response));
    });
  };
};
const getPrivateMessagesReducer = (data) => {
  return {
    type: ActionTypes.classroom.GET_PRIVATE_MESSAGE_ACTION_CLASSROOM,
    payload: { data },
  };
};

const getGroupMessagesAction = (id) => {
  return async (dispatch) => {
    await getGroupMessages(id).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getGroupMessagesReducer(response));
    });
  };
};
const getGroupMessagesReducer = (data) => {
  return {
    type: ActionTypes.classroom.GET_GROUP_MESSAGE_ACTION_CLASSROOM,
    payload: { data },
  };
};
const getGroupRoomMembersAction = (requestType) => {
  return async (dispatch) => {
    await getGroupRoomMembers(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getGroupRoomMembersReducer(response));
    });
  };
};
const getGroupRoomMembersReducer = (data) => {
  return {
    type: ActionTypes.classroom.GET_GROUP_MEMBERS_ACTION_CLASSROOM,
    payload: { data },
  };
};
const hideClassroomGroupAction = (data) => {
  return async (dispatch) => {
    dispatch(handleValidationReducer(''));
    dispatch(handleErrorReducer(''));
    await hideClassroomGroup(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        dispatch(createReducer());
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleErrorReducer(false));
      }
    });
  };
};
const handleErrorReducer = (data) => {
  return {
    type: ActionTypes.classroom.HandleErrorClassroom,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.classroom.HandleValidationClassroom,
    payload: data,
  };
};
const cleanState = (validation = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleErrorReducer(''));
      dispatch(handleValidationReducer(''));
    } else {
      await dispatch(handleErrorReducer(''));
      await dispatch(handleValidationReducer(''));
    }
  };
};

const classroomActions = {
  createAction,
  createReducer,
  getPrivateRoomMembersAction,
  getPrivateRoomMembersReducer,
  getPrivateMessagesAction,
  getPrivateMessagesReducer,
  getGroupMessagesAction,
  getGroupMessagesReducer,
  getGroupRoomMembersAction,
  hideClassroomGroupAction,
  cleanState,
};
export default classroomActions;
