import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import Toast from '../../../components/Common/Popup/Toast';
import Input from '../../../components/Common/Input/Input';

const Update = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const companyId = location?.state?.id;
  const navigate = useNavigate();
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const companyData = useSelector((e) => e.company.getById);
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const [imgSrc, setImgSrc] = useState('');
  const redirect = '/company-list';
  const [error, setError] = useState({});

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.companyActions.cleanState());
  };

  useEffect(() => {
    if (
      Object.keys(companyData).length > 0 &&
      Object.keys(updateData).length === 0
    ) {
      setUpdateData({ ...companyData });
      setImgSrc(companyData.logo);
    }
  }, [companyData]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;

    if (value !== '') {
      setUpdateData({ ...updateData, [id]: value });
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value, name);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }

    setDataChanged(false);
  };

  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(Actions.companyActions.updateAction(updateData));
  };

  async function FetchById() {
    await dispatch(Actions.companyActions.getByIdAction(companyId));
  }

  useEffect(() => {
    FetchById();
    return () => {
      dispatch(Actions.companyActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-12 col-md-1 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags hSize={5} strong text={t('company.update_form')} />
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputLabelOnBorder
                      id={'name'}
                      labelText={t('company.companyName')}
                      inputValue={updateData.name || ''}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2 mt-md-0">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Email}
                      labelText={t('company.companyEmail')}
                      inputValue={updateData.email || ''}
                      onChange={onChangeText}
                      error={error?.email}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {imgSrc ? (
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={imgSrc}
                          alt={imgSrc}
                          width={'100px'}
                        />
                      ) : undefined}
                    </div>
                    <CustomFileInput
                      id={'logo'}
                      key={'logo'}
                      hidden={true}
                      buttonText={t('company.uploadLogo')}
                      textColor={TextColors.Light}
                      removeState={
                        updateData.logo !== undefined && updateData.logo !== ''
                          ? false
                          : true
                      }
                      buttonColor={ButtonType.Successbg}
                      fileType={'Logo'}
                      dataFrom={'Profile'}
                      func={(val) => {
                        setUpdateData(
                          Object.assign({}, updateData, {
                            logo: val.base64String,
                            imgExtension: val.extension,
                            url: val.fileName,
                          })
                        );
                        setDataChanged(false);
                        setImgSrc(val.imgSrc);
                      }}
                    />
                  </div>
                  <div className="col-12 col-6 mt-3">
                    <InputLabelOnBorder
                      id={'taxNo'}
                      inputType={InputType.Number}
                      labelText={t('company.taxNo')}
                      inputValue={updateData.taxNo || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-12 col-6 mt-4">
                    <InputLabelOnBorder
                      id={'address'}
                      inputType={InputType.Text}
                      labelText={t('company.address')}
                      inputValue={updateData?.address || ''}
                      onChange={onChangeText}
                      textArea={{ row: 2, col: 20 }}
                    />
                  </div>
                  <div className="col-12 col-6 mt-4">
                    <Input
                      key={'isActive'}
                      id={'isActive'}
                      value={updateData.isActive}
                      labelValue={t('speaker.is_active')}
                      checked={updateData.isActive}
                      type={InputType.Checkbox}
                      onClick={(e) => {
                        setUpdateData({
                          ...updateData,
                          [e.target.id]: !updateData.isActive,
                        });
                        setDataChanged(false);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'company'}
                        onClick={onSave}
                        objectLengthRule={!dataChanged}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'company'}
        onClose={() => {
          dispatch(Actions.companyActions.cleanState(true));
          setImgSrc('');
          setUpdateData({});
          navigate(redirect);
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.update,
    content
  );
};
export default Update;
