import {
  create,
  update,
  remove,
  getById,
  getAll,
} from '../../../services/apiServices/sessionCategoryServices';
import ActionTypes from './actionTypes';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(createReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.sessionCategory.CREATE_ACTION_SESSIONCATEGORY };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(updateReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.sessionCategory.UPDATE_ACTION_SESSIONCATEGORY };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.sessionCategory.GETBYID_ACTION_SESSIONCATEGORY,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.sessionCategory.GETALL_ACTION_SESSIONCATEGORY,
    payload: { data },
  };
};
const holdValue = (data) => {
  return {
    type: ActionTypes.sessionCategory.HOLD_VALUE_SESSIONCATEGORY,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.sessionCategory.HANDLE_ERROR_SESSIONCATEGORY,
    payload: data,
  };
};
const sessionCategoryActions = {
  createAction,
  createReducer,
  updateAction,
  updateReducer,
  removeAction,
  getByIdAction,
  getByIdReducer,
  getAllAction,
  getAllReducer,
  holdValue,
  handleError,
};
export default sessionCategoryActions;
