import {
  create,
  update,
  remove,
  getById,
  getByName,
  getAll,
} from '../../../services/apiServices/couponCodesServices';
import { promoCodeCalculate } from '../../../services/apiServices/priceServices';
import ActionTypes from './actionTypes';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.couponCodes.CREATE_ACTION_COUPONCODES };
};
const updateAction = (updateData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
      if (res?.data?.isSuccess) {
        await dispatch(createReducer());
      }
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.couponCodes.UPDATE_ACTION_COUPONCODES };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getAllAction = (requestType) => {
  return async (dispatch) => {
    await getAll(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.couponCodes.GETALL_ACTION_COUPONCODES,
    payload: { data },
  };
};


const holdValue = (data) => {
  return {
    type: ActionTypes.couponCodes.HOLD_VALUE_COUPONCODES,
    payload: data,
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.banner.GetById,
    payload: { data },
  };
};
const getByNameAction = (name) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await promoCodeCalculate(name).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(getByNameReducer(res?.data?.data));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }

    });
  };
};
const getByNameReducer = (data) => {
  return {
    type: ActionTypes.couponCodes.GETBYNAME_ACTION_COUPONCODES,
    payload: { data },
  };
};

const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.couponCodes.HANDLE_VALIDATION_COUPONCODES,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.couponCodes.HANDLE_ERROR_COUPONCODES,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(updateReducer());
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};

const couponCodesActions = {
  getByNameAction,
  handleError,
  createAction,
  updateAction,
  removeAction,
  getAllAction,
  getByIdAction,
  holdValue,
  cleanState
};
export default couponCodesActions;
