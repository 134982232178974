import React, { useRef } from 'react';

const TermsOFUse = ({ onScrollToBottom }) => {
  const modalContentRef = useRef();

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
    const isScrolledToBottom = scrollTop + clientHeight + 100 >= scrollHeight;
    if (isScrolledToBottom && onScrollToBottom) {
      onScrollToBottom();
    }
  };

  const containerStyle = {
    maxHeight: 'calc(100vh - 20vh)',
    height: '500px',
    overflowY: 'auto',
  };

  return (
    <div
      onScroll={handleScroll}
      ref={modalContentRef}
      className="container-fluid"
      style={containerStyle}
    >
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="card shadow mb-4">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <h6
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                            textAlign: 'center',
                          }}
                        >
                          <strong>KULLANIM KOŞULLARI</strong>
                          <br />
                          <strong>&nbsp;</strong>
                        </h6>
                        <h6
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>1) TARAFLAR</strong>
                        </h6>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          Kullanım Koşullarının tarafları aşağıdaki şekildedir:
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          - Bir tarafta “meet2talk.online” internet sitesinin
                          faaliyetini yürüten Mustafa ÖZMEN (bundan sonra
                          “Meet2Talk” olarak anılacaktır)
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          - Diğer tarafta “meet2talk.online” internet sitesine
                          üye olmuş “Kullanıcı” bulunmaktadır.
                        </p>
                        <h6
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>2) KULLANIM KOŞULLARININ KONUSU</strong>
                        </h6>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          İşbu Kullanım Koşulları metninin konusu Meet2Talk’un
                          “meet2talk.online” internet sitesi üzerinden
                          Kullanıcı’ya sunduğu üyeliğe dayalı İngilizce konuşma
                          kulübü etkinliği ve bu etkinlikten yararlanma
                          şartlarına ilişkindir. Kullanıcı, Meet2Talk internet
                          sitesi üzerinden hesap açarak, ücretsiz ders tercihi
                          yaparak ve/veya Meet2Talk’ın sağladığı herhangi bir
                          imkânı kullanarak, anılan internet sitesindeki
                          paketlerden birisini satın alarak Meet2Talk ile
                          bağlayıcı bir sözleşme yapmış sayılır ve bu Kullanım
                          Koşullarını ve bu koşullara uymayı kabul etmiş
                          sayılır.
                        </p>
                        <h6
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>3) KULLANIM KOŞULLARI</strong>
                        </h6>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>
                            Deneme Dersi ve Seviye Belirleme:&nbsp;
                          </strong>
                          “meet2talk.online” internet sitesine üye olan
                          Kullanıcı, kendi seçtikleri İngilizce seviyesinde
                          [first step (A1), early stage (A2), communicator B1),
                          talker (B2), presenter (C1-C2)] ücretsiz deneme
                          dersine hak kazanır. Deneme dersinin sonunda,
                          Kullanıcı’nın nihai yabancı dil seviyesi, deneme
                          dersinin eğitmeni tarafından Kullanıcı’nın İngilizce
                          yetisine göre belirlenir. Kullanıcı’nın ders aldığı
                          eğitmen/eğitmenler, gerekli gördüğü takdirde paket
                          süreci içerisindeki herhangi bir safhada,
                          Kullanıcı’nın yabancı dil seviyesini değiştirebilir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Paketler:&nbsp;</strong>Meet2Talk’ın
                          Kullanıcı’ya sunduğu paketler altı kişilik, üç kişilik
                          veya bir kişilik özel ders şeklinde üçe ayrılmaktadır.
                          Altı kişilik pakette yüz yirmi dakika; üç kişilik
                          pakette seksen dakika; bir kişilik özel derste ise on
                          beş, otuz veya bir saatlik dersler verilmektedir. Altı
                          ve üç kişilik paketler haftada en az bir gün; bir
                          kişilik özel dersler ise haftada en az iki gün olacak
                          şekilde tercih edilebilir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Paket Yenileme:&nbsp;</strong> Meet2Talk
                          Kullanıcı’nın paketinin bitiminden bir hafta önce
                          Kullanıcı’ya paket yenilemesi için meet2talk.online
                          sitesi üzerinden teklifte bulunmaktadır. Bu teklif
                          kabul edildiği takdirde Kullanıcı, hali hazırdaki ders
                          günü ve eğitmen tercini değiştirmeksizin hizmetten
                          yararlanmaya devam edebilecektir. Anılan teklif kabul
                          edilmediği takdirde Kullanıcı, mevcut paketini
                          yenilemek istese dahi Meet2Talk, hali hazırdaki ders
                          günü ve eğitmen tercihinin devam edeceğini garanti
                          etmemektedir. Satın alınan paket süresince
                          kullanılmayan bir ders hakkı, hiçbir şart altında
                          Kullanıcı’nın sonraki yeni kullanım dönemine
                          aktarılmaz.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Ders Günlerinin Belirlenmesi:&nbsp;</strong>
                          Kullanıcı’nın satın aldığı paketin başlangıç tarihi
                          “meet2talk.online” sitesi üzerinden kayıt yaptırılan
                          ilk ders günüdür. Deneme dersi günü paket başlangıç
                          tarihi değildir. Kullanıcı’nın deneme dersi sonrasında
                          seçeceği ilk ders günleri satın alınan paket süresince
                          sabit kalacaktır. Kullanıcı’nın paketi kapsamında
                          seçtiği gün/günler ve eğitmen/eğitmenler seçiminin
                          yapıldığı ilk otuz dakika içerisinde değiştirilebilir
                          veya geri alınabilir. Otuz dakikadan sonra gün/günler
                          ve eğitmen/eğitmenlere ilişkin değişim talepleri
                          Meet2Talk’un kabulüne bağlıdır. Meet2Talk ders
                          programında değişiklik yapma hakkını saklı tutar.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Derslerin İşlenişi:&nbsp;</strong>
                          Meet2Talk dersleri çevrimiçi platformlar üzerinden
                          yapılmaktadır. Kullanıcı’nın çevrimiçi platformlardan
                          dersi takip edebilmek için gerekli bilgisayar,
                          internet vb. teknik donanımı kendisi sağlayacaktır.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Dersin Askıya Alınması:&nbsp;</strong>
                          Kullanıcı’nın “meet2talk.online” hesabı üzerinden
                          seçmiş olduğu derslere ilişkin iki türlü askıya alma
                          imkânı bulunmaktadır:
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>1. seçenek:&nbsp;</strong>Kullanıcı dersin
                          başlangıç saatinden üç saat önceye kadar tercihini
                          askıya alabilir. Askıya alınan ders karşılığında
                          Kullanıcı’ya, askıya alınan ders gününü takip eden
                          on gün içinde boş olan dersler içinden yeni bir ders
                          seçme hakkı verilir. Meet2Talk, askıya alınan dersler
                          için on gün içinde Kullanıcı’ya her halükârda bir
                          ders sunmayı taahhüt etmemektedir. Anılan iptal
                          kapsamında boş derslerin tercihi süreci Kullanıcı’nın
                          kendi inisiyatifindedir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>2. seçenek:&nbsp;</strong>Kullanıcı seçmiş
                          olduğu dersi en az on bir gün önceden askıya aldığı
                          takdirde dersin askıya alındığı tarihten önceki on
                          gün, askıya alınan ders günü veya iptal edilen ders
                          gününü takip eden on gün içerisinde boş olan dersler
                          içinden yeni bir ders seçme hakkına sahiptir.
                          Meet2Talk, askıya alınan dersler için yeni tercih
                          yapılabilecek süre zarfında Kullanıcı’ya her halükârda
                          bir ders sunmayı taahhüt etmemektedir. Anılan iptal
                          kapsamında boş derslerin tercih süreci Kullanıcı’nın
                          kendi inisiyatifindedir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Derslerin Disiplini:&nbsp;</strong>
                          Meet2Talk eğitmen veya çalışanları Kullanıcı
                          tarafından herhangi bir hakaretamiz ifade veya
                          hizmetin/dersin işleyişini bozacak herhangi bir
                          davranışa maruz kaldıkları takdirde Meet2Talk, işbu
                          Sözleşme’yi tek taraflı feshetme hakkına sahiptir.
                          Dersler ve sunulan hizmet sırasında Kullanıcı
                          tarafından ırkçılık, şiddet ve nefret söylemleri vb.
                          konuşmaların yapılması halinde Meet2Talk işbu
                          Sözleşme’yi tek taraflı feshetme hakkına sahiptir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Eğitmen Değişikliği:&nbsp;</strong>
                          Meet2Talk, Kullanıcı’nın seçmiş olduğu derslere
                          ilişkin herhangi bir bildirim yapmadan eğitmen
                          değiştirme hakkına sahiptir. Eğitmenin rahatsızlığı
                          vb. durumlarda derslere diğer eğitmenler girmektedir.
                          Eğitmen değişikliği durumunda derslerin ertelenmesi ya
                          da bir önceki eğitmenle telafi derslerinin yapılması
                          söz konusu değildir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Bilgi Paylaşımı:&nbsp;</strong>Meet2Talk,
                          dersin işlenme şeklinin geliştirilebilmesi amacıyla
                          Kullanıcı’nın sadece ad-soyad, e-posta, telefon
                          numarası bilgilerini eğitmenlerle paylaşmaktadır.
                          Meet2Talk eğitmen ve çalışanları telefon, adres vb.
                          kişisel bilgilerini Kullanıcı ile paylaşmamaktadır.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Reklam Faaliyetleri:&nbsp;</strong>
                          Meet2Talk’a ait sosyal medya hesaplarında paylaşmak
                          üzere derslerin işlenişine ilişkin kısa video ve
                          fotoğraflar paylaşılmaktadır. Kullanıcı, video ve/veya
                          fotoğrafının Meet2Talk sosyal medya hesaplarında
                          yayınlanmasını istemediği takdirde bu durumu derhal
                          Meet2Talk’a bildirmelidir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>
                            Ödeme Yöntemleri ve Faturalandırma:&nbsp;
                          </strong>
                          Kullanıcı’nın seçtiği paket ödemesi, meet2talk.online
                          sitesi üzerinden online ödeme veya havale/EFT yoluyla
                          yapılabilmektedir. Havale/EFT yoluyla ödeme
                          yapıldıktan sonra Kullanıcı, Meet2Talk çağrı merkezini
                          arayarak üyeliğini aktif hale getirmelidir. Deneme
                          dersi haricindeki derslerin meet2talk.online
                          sitesinden seçilebilmesi için meet2talk.online
                          internet sitesinde ödemenin yapılacağı tarih
                          itibariyle geçerli olan hizmet bedeli üzerinden ödeme
                          yapılmalıdır. Meet2Talk Kullanıcı’ya ödemenin
                          yapıldığı tarihten yedi gün içinde fatura gönderimi
                          sağlamaktadır. Fatura, Meet2Talk üyelik formunda
                          Kullanıcı’nın belirtmiş olduğu e-posta adresine
                          gönderilir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Üçüncü Kişiye Devir:&nbsp;</strong>
                          Kullanıcı, Meet2Talk üyeliği kapsamındaki hiçbir
                          hakkını kısmen veya tamamen üçüncü bir kişiye
                          devredemez. Kullanıcı’dan gelen bu tür talepler
                          Meet2Talk tarafından reddedilecektir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>İletişim Bilgileri Değişikliği:&nbsp;</strong>
                          Kullanıcı, Meet2Talk üyelik formunda belirttiği
                          iletişim bilgilerini (telefon, e-posta)
                          değiştirebilir. Kullanıcı, bu değişiklikleri
                          meet2talk.online sitesi üzerinden kendi hesap
                          bilgilerine ait bölümde değiştirmelidir. İletişim
                          bilgilerinin güncel, doğru ve eksiksiz tutulması
                          Kullanıcı’nın sorumluluğundadır.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Fikri ve Sınai Haklar:&nbsp;</strong>
                          meet2talk.online sitesi yazılımı, tasarımı ve Türk
                          Patent ve Marka Kurumunun “2017 109319 – Hizmet” marka
                          numaralı “Meet2Talk” markası, Meet2Talk’un
                          mülkiyetindedir ve bunlara ilişkin telif hakkı ve/veya
                          diğer fikri mülkiyet hakları, ilgili kanunlarca
                          korunmaktadır. Anılan yazılım ve tasarımlar Kullanıcı
                          tarafından izinsiz kullanılamaz, iktisap edilemez ve
                          değiştirilemez.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>Geçerlilik Süresi:&nbsp;</strong>
                          İşbu Sözleşme, Sözleşme’den doğan ödeme ve teslim
                          yükümlülüklerini yerine getirildiği zamana kadar
                          yürürlükte kalacaktır. Kullanıcı’nın işbu Sözleşme’yi
                          veya sair ilgili mevzuat sebebiyle doğan
                          yükümlülüklerinden herhangi birini ihlal etmesi veya
                          aykırı davranması halinde Meet2Talk Kullanıcı’nın
                          üyeliğini iptal ederek Sözleşme’yi tek taraflı olarak
                          feshedebilecektir.
                        </p>
                        <p
                          style={{
                            marginTop: '12pt',
                            marginBottom: '12pt',
                          }}
                        >
                          <strong>İletişim Kanalları:&nbsp;</strong>
                          Meet2Talk’ın resmi yazışma adresi
                          “info@meet2talk.cafe”dir. İşbu Sözleşme uyarınca,
                          Meet2Talk, Kullanıcı’nın meet2talk.online sitesine
                          kayıtlı e-posta adresine bilgilendirme e-postası ve
                          cep telefonuna bilgilendirme SMS’i gönderme yetkisine
                          sahiptir. Kullanıcı Meet2Talk’tan hizmet almaya
                          başlayarak bilgilendirme e-postası ve SMS’lerinin
                          belirtmiş olduğu e-posta ve telefonlara gönderilmesini
                          kabul etmiş sayılacaktır.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOFUse;
