import { postRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const promoCodeCalculate = async (data) => {
  return await postRequest(endPoint.price.PromoCodeCalculate, data);
};

export const priceCalculate = async (ids) => {
  return await postRequest(
    `${endPoint.price.PRICE_CALCULATE}?zoneId=1&typeId=1&locationId=${ids.location}&ageId=${ids.age}&packageId=${ids.package}&timesPerWeekId=${ids.timesPerWeek}&currency=${ids.currencyCode}&couponCode=${ids.couponCode}`
  );
};
