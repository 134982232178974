import React, { useEffect } from 'react';
import styles from '../../../assets/Styles.module.scss';

const Modal = ({
  isVisible = false,
  title,
  content,
  footer,
  onClose,
  imgSrc,
  styleProps,
  textColor,
  titleMS,
}) => {
  const handleEscKeyPress = (event) => {
    if (event.keyCode === 27) {
      onClose();
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13) {
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', handleEscKeyPress);
    window.addEventListener('keyup', handleEnterKeyPress);
    return () => {
      window.removeEventListener('keyup', handleEscKeyPress);
      window.removeEventListener('keyup', handleEnterKeyPress);
    };
  }, []);

  return !isVisible ? null : (
    <div className={styles.modal} onClick={onClose}>
      <div
        className={styles.modal_dialog}
        style={{
          minWidth: `${styleProps?.minWidth}px`,
          maxWidth: `${styleProps?.maxWidth}px`,
          height: `${styleProps?.height}px`,
          borderRadius: `${styleProps?.borderRadius}px`,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modal_header}>
          {title && (
            <>
              <h4 className={`p-2 text-${textColor}`}>
                {imgSrc && (
                  <img
                    src={imgSrc?.src}
                    className={'img-fluid me-3'}
                    style={{
                      width: imgSrc.width,
                    }}
                    alt={imgSrc?.alt}
                  />
                )}
                <strong className={`ms-${titleMS}`}>{title}</strong>
              </h4>
            </>
          )}
          <button
            type="button"
            className="btn-close text-right position-absolute top-0 end-0 p-3"
            onClick={onClose}
          />
        </div>
        <div className={styles.modal_body}>
          <div className={styles.modal_content}>{content}</div>
        </div>
        {footer && <div className={styles.modal_footer}>{footer}</div>}
      </div>
    </div>
  );
};
export default Modal;
