import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const priceReducer = (
    state = initialState.price,
    action
) => {
    switch (action.type) {
        case ActionTypes.price.CalculatedPrices:
            return {
                ...state,
                calculatedPrices: action.payload,
            };
        case ActionTypes.price.HandleError:
            return {
                ...state,
                isSuccess: action.payload,
            };
        case ActionTypes.price.HandleValidation:
            return {
                ...state,
                validationMessage: action.payload,
            };
        default:
            return state;
    }
};

export default priceReducer;
