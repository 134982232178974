import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const faqGroupReducer = (state = initialState.faqGroup, action) => {
  switch (action.type) {
    case ActionTypes.faqGroup.CREATE_ACTION_FAQGROUP:
      return {
        ...state,
        faqGroup: initialState.faqGroup.faqGroup,
      };
    case ActionTypes.faqGroup.UPDATE_ACTION_FAQGROUP:
      return {
        ...state,
        faqGroup: {},
        getById: {},
      };
    case ActionTypes.faqGroup.GETBYID_ACTION_FAQGROUP:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.faqGroup.GETALL_ACTION_FAQGROUP:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.faqGroup.HOLD_VALUE_FAQGROUP:
      return {
        ...state,
        faqGroup: action.payload,
      };
    default:
      return state;
  }
};

export default faqGroupReducer;
