import { useEffect } from 'react';
import { useState } from 'react';

const Tooltip = ({ children, text, id, bottom, textMS }) => {
  const [show, setShow] = useState(false);
  const [tooltipBottom, setTooltipBottom] = useState(
    `calc(100% - ${bottom}px)`
  );
  useEffect(() => {
    const element = document.getElementById(`${bottom}_${id}`);
    if (element) {
      element.style.setProperty('--tooltip-bottom', tooltipBottom);
    }
  }, [bottom, id, tooltipBottom]);
  return (
    <div id={`${bottom}_${id}`} className="tooltip-container">
      <div className={` ${show ? 'tooltip-box visible' : 'tooltip-box'}`}>
        <span className={`ms-${textMS}`}>{text}</span>
      </div>
      <div
        onMouseEnter={() => setShow(true)}
        onTouchStart={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onTouchEnd={() => setShow(false)}
      >
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
