import { storageKeys } from '../utils/Enum';
import config from '../config.json';
import EnumBase from '../utils/EnumBase';

const utmDataBuilder = (utmId) => {
  if (config.Connections.Environment === EnumBase.Environment.PROD) {
    const utmData = {
      attendeeId: null,
      name: '-',
      email: '-',
      phone: '-',
      languageLevel: '-',
      learningPurpose: '-',
      location: '-',
      ageInterval: '-',
      utmId: utmId,
      formName: '-',
      formValue: '-',
    };
    sessionStorage.setItem(storageKeys.utmData, JSON.stringify(utmData));
  }
};

export default utmDataBuilder;
