import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Common/Button/Button';
import {
  ButtonType,
  InputSize,
  LabelTextType,
} from '../../utils/ComponentEnums';
import { InputType } from '../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../store/redux/actions';
import CustomFileInput from '../../components/Common/FileInput/FileUpload';
import Input from '../../components/Common/Input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import regexValidator from '../../hooks/useRegexValidator';
import validationMessage from '../../hooks/useValidationMessage';
import SubmitButton from '../../components/Common/Button/SubmitButton';
import Toast from '../../components/Common/Popup/Toast';

const Feedback = ({ handleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customFileInputRef = useRef();
  const userInfo = useSelector((e) => e.user.getById);
  const [createData, setCreateData] = useState({});
  const [isRequired, setIsRequired] = useState({ email: true, message: true });
  const [error, setError] = useState({});
  const [requiredError, setRequiredError] = useState({});

  const handleButtonClick = (e) => {
    customFileInputRef.current.handleFileInsert(e);
  };

  useEffect(() => {
    if (userInfo?.email !== undefined) {
      setCreateData({ ...createData, email: userInfo?.email });
      setIsRequired({ ...isRequired, email: false });
    }
  }, [userInfo]);

  const onFocus = (e) => {
    e.preventDefault();
    setRequiredError({});
  };
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, required } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
      setError((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
      setIsRequired({ ...isRequired, [id]: true });
    }
    const validationResult = regexValidator(type, value);
    if (required && !validationResult && value === '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    } else if (required && validationResult && value !== '') {
      setError({ ...error, [id]: undefined });
    }
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }

    if (required && value !== '' && validationResult) {
      setIsRequired({ ...isRequired, [id]: false });
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    if (
      Object.keys(createData).length > 0 &&
      Object.values(isRequired).every((e) => e === false)
    ) {
      await dispatch(Actions.feedbackActions.createAction(createData));
    } else if (!Object.values(isRequired).every((e) => e === false)) {
      const keys = Object.keys(isRequired).filter((key) => isRequired[key]);

      const updatedErrors = keys.reduce(
        (acc, key) => {
          acc[key] = (
            <span className="text-danger">
              {t('validationMessages.this_field_is_required')}
            </span>
          );
          return acc;
        },
        { ...requiredError }
      );

      setRequiredError(updatedErrors);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.feedbackActions.cleanState());
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Input
                id={'email'}
                className={InputSize.Medium}
                type={InputType.Email}
                value={createData.email || ''}
                labelValue={t('feedback.input_email')}
                labelFontType={LabelTextType.Bold}
                placeholder={'name@example.com'}
                onChange={onChangeText}
                onFocus={onFocus}
                error={error.email}
                required
                requiredError={requiredError.email}
              />
            </div>
            <div className="col-12 mt-md-4 py-1">
              <Input
                id={'message'}
                className={InputSize.Medium}
                inputType={InputType.Text}
                labelValue={t('feedback.message')}
                value={createData.message || ''}
                textArea={{ row: 3, col: 12 }}
                labelFontType={LabelTextType.Bold}
                onChange={onChangeText}
                onFocus={onFocus}
                required
                requiredError={requiredError.message}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomFileInput
                id={'image'}
                key={'image'}
                hidden={true}
                removeState={createData.image !== undefined ? false : true}
                fileType={'Image'}
                dataFrom={'Career'}
                changeTxt={true}
                ref={customFileInputRef}
                func={(val) => {
                  setCreateData(
                    Object.assign({}, createData, {
                      image: val.base64String,
                      imgExtension: val.extension,
                      url: val.fileName,
                    })
                  );
                }}
              />
            </div>
          </div>
          {createData?.image !== undefined && (
            <div className="d-flex justify-content-between text-custom fw-bold mb-2">
              <label> {t('feedback.image_info', { count: 1 })}</label>
              <FontAwesomeIcon
                role="button"
                onClick={() => {
                  setCreateData((prevState) => {
                    const { image, imgExtension, url, ...rest } = prevState;
                    return rest;
                  });
                }}
                icon={faTrashCan}
              />
            </div>
          )}
          <div className="d-md-flex justify-content-md-between mb-2">
            <div className="col-12 col-md-6 me-md-1 me-0">
              <Button
                type={ButtonType.Custom}
                outline
                text={t('feedback.upload_image_button')}
                buttonCol={12}
                onClick={handleButtonClick}
              />
            </div>
            <div className="col-12 col-md-6 mt-2 mt-md-0">
              <SubmitButton stateName={'feedback'} onClick={onSave} />
            </div>
          </div>
        </div>
      </div>
      <Toast
        stateName={'feedback'}
        onClose={() => {
          setCreateData({});
          handleModal(false);
        }}
      />
    </div>
  );
};
export default Feedback;
