import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import Card from '../../../components/Common/Card/Card';
import Label from '../../../components/Common/TextLabel/Label';
import Button from '../../../components/Common/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  ButtonSize,
  ButtonType,
  LabelFontSize,
  LabelTextType,
} from '../../../utils/ComponentEnums';
import Start1 from './Start1';
import Start2 from './Start2';
import Start3 from './Start3';
import firstPage from '../../../assets/images/onboarding_first_page.png';
import secondPage from '../../../assets/images/onboarding_second_page.png';

export default function OnBoarding() {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [clicked, setClicked] = useState('Slide1');
  const isMobile = window.innerWidth <= 768;
  let htmlContent = null;
  let backGroundImage = null;
  switch (index) {
    case 0:
      htmlContent = <Start1 />;
      backGroundImage = firstPage;
      break;
    case 1:
      htmlContent = <Start2 />;
      backGroundImage = secondPage;
      break;
    case 2:
      htmlContent = <Start3 />;
      break;
    default:
      break;
  }
  const content = (
    <div className="section5">
      <div className="container text-center">
        <div className=" d-flex my-5">
          <div className="col mx-auto">
            <Card
              body={
                <div className="col-12">
                  {htmlContent}
                  {index !== 2 && (
                    <img
                      className="img-fluid mx-auto mt-2 mt-md-4"
                      src={backGroundImage}
                      alt="INFO"
                      style={{
                        width: isMobile ? '100%' : '50%',
                        height: 'auto',
                      }}
                    />
                  )}

                  <div className="col mx-auto  ">
                    <div className="row mx-auto ">
                      {index === 2 ? (
                        ''
                      ) : (
                        <div className="col-3 ms-auto text-center">
                          <div className="d-flex col-6 p-0 m-0 mx-auto align-items-center justify-content-center">
                            <Button
                              type={ButtonType.Success}
                              bold
                              text={<FontAwesomeIcon icon={faLongArrowRight} />}
                              buttonCol={12}
                              size={ButtonSize.Small}
                              onClick={() => {
                                setIndex(2);
                                setClicked('Slide3');
                              }}
                            />
                          </div>
                          <div>
                            <Label
                              fontType={LabelTextType.Bold}
                              fontSize={LabelFontSize.fs6}
                              value={t('button.skip')}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center flex-column">
                      <div className="row">
                        <div className="col-4">
                          <Button
                            type={ButtonType.Successbg}
                            bold
                            id={'Slide1'}
                            clicked={clicked}
                            buttonCol={3}
                            onClick={(e) => {
                              setIndex(0);
                              setClicked(e.target.id);
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <Button
                            type={ButtonType.Successbg}
                            bold
                            id={'Slide2'}
                            clicked={clicked}
                            buttonCol={3}
                            onClick={(e) => {
                              setIndex(1);
                              setClicked(e.target.id);
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <Button
                            type={ButtonType.Successbg}
                            bold
                            id={'Slide3'}
                            clicked={clicked}
                            buttonCol={3}
                            onClick={(e) => {
                              setIndex(2);
                              setClicked(e.target.id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  return content;
}
