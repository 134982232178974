import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../store/redux/actions';
import { loadExternalScript } from './loadExternalScript';
import { Google } from '../../utils/ExternalVariables';
import { useNavigate } from 'react-router-dom';
import { checkLogged } from '../../hooks/useCheckLogged';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import findMyPath from '../../hooks/useFindMyPath';

export const GoogleAuth = ({
  type,
  checkHasItems,
  packageData,
  userLanguage,
  privacyAndTermsChecked,
  setErrorState,
  refId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useSelector((e) => e.onboard.path);
  const myPath = path === '' ? '/dashboard' : `${path}/dashboard`
  const customButton = useRef(null);
  const OauthLanguage = userLanguage?.split('-')[0].toLowerCase();
  const status = useSelector((e) => e.auth.auth);
  const successCheck = useSelector((e) => e.auth.isSuccess);
  const currency = useSelector(
    (e) => e.preferenceOptions.preferenceCurrencyUnit
  );


  const src = Google.Sdk;
  const id = Google.ClientID;

  const [googleSdkLoaded, setGoogleSdkLoaded] = useState(false);

  useEffect(() => {
    const loadGoogleScript = async () => {
      try {
        await loadExternalScript(src);

        /* global google */
        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
          ux_mode: 'popup',
        });
        setGoogleSdkLoaded(true);
      } catch (error) {
        console.error('Error loading or initializing Google SDK:', error);
      }
    };

    loadGoogleScript();

    return () => {
      setGoogleSdkLoaded(false);
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, [src]);

  const handleCredentialResponse = async (response) => {
    if (type === 'login') {
      dispatch(
        Actions.authActions.oauthGoogleLoginAction(
          response.credential,
          OauthLanguage
        )
      )
      removeGoogleSignInWrapper();
      if (successCheck) {
        await applyPackageAndLogIn();
      }
    } else if (type === 'register') {
      // Capture user information from Google OAuth response
      dispatch(
        Actions.authActions.oauthGoogleRegisterAction(
          response.credential,
          OauthLanguage,
          currency.code,
          refId
        )
      )
      if (successCheck) {
        await applyPackageAndLogIn();
      }
      removeGoogleSignInWrapper();
    }
  };

  const applyPackageAndLogIn = async () => {
    if (checkHasItems === false) {
      navigate(findMyPath('dashboard'));
    } else {
      if (checkHasItems) {
        navigate(findMyPath('speakers'), { state: { createData: packageData } });
      }
    }
  };

  const openGoogleSignInPopup = () => {
    if (!googleSdkLoaded) {
      console.error('Google SDK is not yet loaded.');
      return;
    }

    try {
      const googleLoginWrapper = document.createElement('div');
      googleLoginWrapper.classList.add('custom-google-button');
      googleLoginWrapper.style.display = 'none';
      document.body.appendChild(googleLoginWrapper);

      if (window.google.accounts.id) {
        window.google.accounts.id.renderButton(googleLoginWrapper, {
          type: 'icon',
          width: '100%',
        });
        const googleLoginWrapperButton =
          googleLoginWrapper.querySelector('div[role=button]');
        googleLoginWrapperButton.click();
      } else {
        console.error('Google SDK initialization failed.');
      }
    } catch (error) {
      console.error('Error in openGoogleSignInPopup:', error);
    }
  };

  const removeGoogleSignInWrapper = () => {
    const googleLoginWrapper = document.querySelector('.custom-google-button');
    if (googleLoginWrapper) {
      document.body.removeChild(googleLoginWrapper);
    }
  };
  const handleClick = () => {
    if (type === 'register') {
      if (privacyAndTermsChecked) {
        openGoogleSignInPopup();
      } else {
        setErrorState(
          <span className="text-danger">
            {t('register.please_read_and_check')}
          </span>
        );
      }
    }
    else {
      openGoogleSignInPopup();
    }

  };
  return (
    <div
      role="button"
      className="col border rounded fw-bold d-flex s2 ms-4 align-items-center justify-content-center"
      ref={customButton}
      onClick={handleClick}
      style={{
        zIndex: 1,
        opacity: 1,
      }}
    >
      <div className="svgSocialLogin2">
        <FontAwesomeIcon icon={faGoogle} />
      </div>
      <div className="ps-3 t2">Google</div>
    </div>
  );
};
