import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(
    endPoint.companyPriceRequest.CREATE_COMPANYPRICEREQUEST,
    createData
  );
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.companyPriceRequest.UPDATE_COMPANYPRICEREQUEST}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(
    `${endPoint.companyPriceRequest.REMOVE_COMPANYPRICEREQUEST}/${id}`
  );
};
export const getById = async (id) => {
  return await getRequest(
    `${endPoint.companyPriceRequest.GETBYID_COMPANYPRICEREQUEST}/${id}`
  );
};
export const getAll = async () => {
  return await getRequest(
    endPoint.companyPriceRequest.GETALL_COMPANYPRICEREQUEST
  );
};
