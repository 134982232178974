function removeCookieItem(cookieName) {
    const d = new Date();
    d.setTime(d.getTime() - 1);
    let expires = 'expires=' + d.toUTCString();
    if (Array.isArray(cookieName)) {
        cookieName.forEach(name => {
            document.cookie = name + '=;' + expires + ';path=/';
        });
    } else {
        document.cookie = cookieName + '=;' + expires + ';path=/';
    }
}

export default removeCookieItem;
