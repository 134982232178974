import {
  create,
  update,
  remove,
  getById,
  getAll,
  getAllAttendeeSchedule,
  getAllSessionsCount,
  getSpeakerDropdownList,
  getTodaysMeetingBySpeaker,
  getAllSpeakerSchedule,
  getMeetingAttendees,
  attendanceCheck,
} from '../../../services/apiServices/meetingSchemaServices';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';
import ActionTypes from './actionTypes';
import config from '../../../config.json';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
      if (res?.data?.isSuccess) {
        await dispatch(createReducer());
      }
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.meetingSchema.CREATE_ACTION_MEETINGSCHEMA };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData, id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
      if (res?.data?.isSuccess) {
        await dispatch(createReducer());
      }
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.meetingSchema.UPDATE_ACTION_MEETINGSCHEMA };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
      await dispatch(handleError(reponseStatus.fail));
    });;
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(holdValue(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETBYID_ACTION_MEETINGSCHEMA,
    payload: { data },
  };
};
const getAllAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(data);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETALL_ACTION_MEETINGSCHEMA,
    payload: { data },
  };
};
const getAllAttendeeScheduleAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllAttendeeScheduleReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAllAttendeeSchedule(id);
        let response = res?.data?.data;
        await dispatch(getAllAttendeeScheduleReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllAttendeeScheduleReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETALL_ACTION_MEETINGSCHEDULE,
    payload: { data },
  };
};
const getAllSessionCountAction = () => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllSessionCountReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAllSessionsCount();
        let response = res?.data?.data;
        await dispatch(getAllSessionCountReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllSessionCountReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETALL_SESSION_COUNT_ACTION_MEETINGSCHEDULE,
    payload: { data },
  };
};
const getSpeakerDropdownListAction = (filter) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getSpeakerDropdownListReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getSpeakerDropdownList(filter);
        let response = res?.data?.data;
        if (!res?.data?.isSuccess) {
          await dispatch(getSpeakerDropdownListReducer([]));
          await dispatch(handleValidationReducer(res?.data?.message));
          await dispatch(handleError(false));
        }
        else {
          await dispatch(getSpeakerDropdownListReducer(response));
          await dispatch(handleError(""));
        }
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getSpeakerDropdownListReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GET_SPEAKER_DROPDOWN_LIST_ACTION,
    payload: data,
  };
};
const getTodaysMeetingBySpeakerAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllTodayReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getTodaysMeetingBySpeaker(id);
        let response = res?.data?.data;
        await dispatch(getAllTodayReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllTodayReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GETALL_ACTION_MEETING_BY_SPEAKER,
    payload: { data },
  };
};

const getAllSpeakerScheduleAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllSpeakerScheduleReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAllSpeakerSchedule(id);
        let response = res?.data?.data;
        await dispatch(getAllSpeakerScheduleReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllSpeakerScheduleReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GetMeetingSpeakerSchedule,
    payload: { data },
  };
};
const getMeetingAttendeesAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getMeetingAttendeesReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getMeetingAttendees(data);
        let response = res?.data?.data;
        await dispatch(getMeetingAttendeesReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getMeetingAttendeesReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.GET_MEETING_ATTENDEES,
    payload: { data },
  };
};
const attendanceCheckAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await attendanceCheck(data).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
      if (res?.data?.isSuccess) {
        await dispatch(getMeetingAttendeesReducer([]));
      }
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const attendanceCheckReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.ATTENDANCE_CHECK,
    payload: { data },
  };
};

const holdValue = (data) => {
  return {
    type: ActionTypes.meetingSchema.HOLD_VALUE_MEETINGSCHEMA,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.meetingSchema.HANDLE_ERROR_MEETINGSCHEMA,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.meetingSchema.HANDLE_VALIDATION_MEETINGSCHEMA,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.meetingSchema.CleanList };
};

const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
      dispatch(cleanListReducer());
    }
  };
};
const meetingSchemaActions = {
  createAction,
  createReducer,
  updateAction,
  updateReducer,
  removeAction,
  getByIdAction,
  getByIdReducer,
  getAllAction,
  getAllReducer,
  holdValue,
  handleError,
  getAllAttendeeScheduleAction,
  getAllSessionCountAction,
  getSpeakerDropdownListAction,
  cleanState,
  getTodaysMeetingBySpeakerAction,
  getAllSpeakerScheduleAction,
  getMeetingAttendeesAction,
  attendanceCheckAction,
};
export default meetingSchemaActions;
