import React from 'react';
import { useTranslation } from 'react-i18next';
import generateRandomKey from '../../../hooks/useKeyGenerator';

export default function InputLabelOnBorder({
  textArea,
  id,
  onFocus,
  pattern,
  labelText,
  inputType,
  placeholder,
  inputValue,
  select,
  handleSelectChange,
  onChange,
  onBlur,
  maxNumber,
  minNumber,
  error,
  required,
  disabled,
  noTranslate = false,
  regexType,
  callBack,
}) {
  const { t } = useTranslation();
  let errorField = ' border border-danger';
  let checkRequiredField =
    required !== undefined ? (
      <span className="text-danger fw-bold ms-1">*</span>
    ) : null;

  return (
    <React.Fragment>
      <label
        className="ms-2 position-absolute"
        style={{ marginTop: '-0.25rem', fontSize: select ? '.7rem' : '.7rem' }}
      >
        <span className="h6 small bg-white text-muted px-1">{labelText}</span>
        {checkRequiredField}
      </label>
      {select ? (
        <select
          key={select?.option?.[0]?.name}
          onFocus={onFocus}
          className={`form-control mt-2 form-input-border border-2 ${select?.selectStyle}`}
          value={select?.selectedValue}
          onChange={(e) => {
            const { value, id } = e.target;
            if (value !== '') {
              if (typeof select?.option?.[0] === 'object') {
                handleSelectChange({
                  value,
                  name:
                    select?.option[0]?.name.charAt(0).toLowerCase() +
                    select?.option[0]?.name.slice(1),
                  key:
                    select?.selectedProp !== undefined
                      ? value
                      : select?.special
                      ? select?.option[value - 1]?.key
                      : `${select?.option[0]?.key.split('.')[0]}.${value}`,
                });
              } else {
                handleSelectChange(value);
              }
            }
          }}
        >
          {select?.option?.length === 0 ? (
            <option>{select?.selectedValue}</option>
          ) : typeof select?.option?.[0] !== 'object' ? (
            [
              (select?.selectedValue === undefined ||
                select?.selectedValue === '') && (
                <option key="select-placeholder" value="">
                  {t('inputLabelOnBorder.select')}
                </option>
              ),
              select?.option?.map((value, index) => (
                <option
                  id={value}
                  key={select?.option?.[0]?.name + value + index}
                  value={index}
                >
                  {t(`${value}`)}
                </option>
              )),
            ]
          ) : (
            [
              (select?.selectedValue === undefined ||
                select?.selectedValue === '') && (
                <option key="select-placeholder" value="">
                  {t('inputLabelOnBorder.select')}
                </option>
              ),
              select?.option?.map((value, index) => (
                <option
                  id={value.key + index}
                  key={`option_${index}_${value.key}`}
                  value={
                    select?.selectedProp !== undefined
                      ? value[select?.selectedProp]
                      : value.value
                  }
                >
                  {!noTranslate
                    ? select?.addIndex
                      ? `${index + 1}- ${t(`${value.key}`)}`
                      : t(`${value.key}`)
                    : value.key}
                </option>
              )),
            ]
          )}
        </select>
      ) : textArea ? (
        <textarea
          id={id}
          className="form-control mt-2 form-input-border border-2"
          cols={textArea.col}
          rows={textArea.row}
          onChange={onChange}
          onBlur={onBlur}
          value={inputValue}
          placeholder={placeholder}
          name={regexType}
        />
      ) : (
        <React.Fragment>
          <input
            key={generateRandomKey}
            id={id}
            placeholder={placeholder}
            value={inputValue}
            onBlur={onBlur}
            onFocus={onFocus}
            pattern={pattern}
            onChange={onChange}
            type={inputType}
            className={`form-control mt-2 form-input-border border-2 ${
              error !== undefined ? errorField : ''
            }`}
            max={maxNumber}
            min={minNumber}
            disabled={disabled}
            name={regexType}
            required={required}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                inputValue !== '' &&
                inputValue &&
                callBack
              ) {
                callBack(e);
              }
            }}
          />
          <div className="text-center">{error}</div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
