//ICONS , STYLES
import styles from '../Submenus/Languages.module.scss';
//HOOKS
import useClickOutside from '../../../../hooks/useClickOutside';
import { React, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../store/redux/actions';
import Dropdown from '../../Dropdown/Dropdown';
import { ButtonIcon, TextColors } from '../../../../utils/ComponentEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Languages = () => {
  const [isLanguagesOpen, setisLanguagesOpen] = useState(false);
  let domNode = useClickOutside(() => {
    setisLanguagesOpen(false);
  });
  const currentLang = useSelector((e) => e.lang.language);
  const flag = useSelector((e) => e.lang.flag);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const languages = [
    { key: 1, name: 'en-GB', value: 0, title: 'English' },
    { key: 2, name: 'tr-TR', value: 1, title: 'Türkçe' },
  ];
  const currencyOptions = useSelector(
    (e) => e.preferenceOptions.currenyOptions
  );
  const currency = useSelector(
    (e) => e.preferenceOptions.preferenceCurrencyUnit
  );

  const lng = languages
    .find((a) => a.name === currentLang.split(',')[0])
    .name.split('-')
    .at(0)
    .toLowerCase();
  async function handleLanguage(target) {
    i18next.changeLanguage(target.title);
    const language = target.title.split('-')[0];
    if (lng !== language) {
      dispatch(Actions.resetStoreAction.resetStore());
      await dispatch(
        Actions.userActions.selfUpdateAction(
          { language: language, currency: currency.name },
          'Language'
        )
      );
      await dispatch(Actions.langActions.pickLanguage(target.title));
    }
  }

  function handleCurrency(currencyUnit) {
    const currencyName = currencyUnit.Name;
    if (currency?.name !== currencyName) {
      dispatch(Actions.resetStoreAction.resetStore());
      dispatch(
        Actions.userActions.selfUpdateAction(
          { language: lng, currency: currencyName },
          'Currency'
        )
      );
    }
  }

  useEffect(() => {
    if (currencyOptions.length === 0) {
      dispatch(Actions.preferenceActions.getCurrencyAction());
    }
  }, []);

  return (
    <div
      ref={domNode}
      className={`${styles.icon_container} ${styles.Languages} ${
        isLanguagesOpen ? styles.icon_active : undefined
      }`}
      onClick={() => {
        setisLanguagesOpen(!isLanguagesOpen);
      }}
    >
      <div>
        <button
          className="btn btn-default border-0 dropdown-toggle"
          type="button"
          id="navbarDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
        >
          <img
            className="me-2"
            src={
              require(flag === 'gb'
                ? `../../../../assets/images/flags/gb.svg`
                : `../../../../assets/images/flags/tr.svg`).default
            }
            width={'16px'}
            alt={'svg'}
          />
          {currency.symbol}
          {/* {currentLang?.split(',')[1]} , {currency.Name} */}
        </button>
        <ul
          className="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdown"
        >
          <div className="p-2">
            <h6> {t('navbar.site_language')}</h6>
            <Dropdown
              options={languages}
              name={'Language'}
              title={currentLang?.split(',')[1]}
              handleChange={(target) => handleLanguage(target)}
              // backGroundAll={BackGroundColors.Grey}
              propNames={{
                id: 'key',
                key: 'name',
                value: 'value',
                name: 'title',
                title: 'name',
              }}
              svg={
                require(flag === 'gb'
                  ? `../../../../assets/images/flags/gb.svg`
                  : `../../../../assets/images/flags/tr.svg`).default
              }
              svgRounded={1}
              svgWidth={'15px'}
              rightIcon={ButtonIcon.ArrowDown}
              id={'language'}
              textColor={TextColors.Dark}
              rounded={2}
              bordered
              buttonCol={'col-sm-1 me-sm-2'}
              forCalendar
              forLang
            />
          </div>

          <div className="p-2">
            <h6> {t('navbar.currency')}</h6>

            <div className="dropdown ">
              <button
                className="btn btn-default d-flex align-items-center  w-100 mx-auto justify-content-center border border-1 border-success rounded-2 "
                type="button"
                id="currencyDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {currency?.name}
                <span className="ms-1">
                  <FontAwesomeIcon icon={ButtonIcon.ArrowDown} />
                </span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="currencyDropdown">
                {currencyOptions.map((currency) => (
                  <li
                    key={currency.Code}
                    className="dropdown-item"
                    onClick={() => handleCurrency(currency)}
                  >
                    {currency.Name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};
export default Languages;
