import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import findMyPath from '../../../hooks/useFindMyPath';

const Pagination = ({ itemsPerPage, totalItems, paginate, contentId }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

  const scrollToSpeakersContent = () => {
    const speakersContent = document.getElementById(contentId);
    if (speakersContent) {
      const topOffset = speakersContent.offsetTop;
      const middleScrollPosition = topOffset / 1.25;
      window.scrollTo({
        top: middleScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    paginate(pageNumber);

    scrollToSpeakersContent();
  };

  const pageRange = (min, max) =>
    [...Array(max - min + 1)].map((_, i) => min + i);

  const renderPageNumbers = () => {
    const isMobile = window.innerWidth < 768;

    const pageNumbers = isMobile
      ? pageRange(
          Math.max(currentPage - 1, 1),
          Math.min(currentPage + 1, totalPages)
        )
      : pageRange(
          Math.max(currentPage - 2, 1),
          Math.min(currentPage + 2, totalPages)
        );
    return (
      <ul className="pagination align-items-center p-0 m-0">
        <li
          className={`page-item ${
            currentPage === 1 ? 'disabled opacity-50' : ''
          }`}
        >
          <button
            className="page-link bg-transparent text-t-gri px-2 py-1"
            onClick={() => handleClick(currentPage - 1)}
            disabled={currentPage === 1}
            style={{ border: 'transparent' }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </li>

        {totalPages > 5 && currentPage > 3 && (
          <>
            <li className="page-item">
              <button
                className={`page-link rounded-3 px-2 py-1 ${
                  !currentPage
                    ? 'text-white btn bg-custom'
                    : 'text-custom btn bg-lightcustom'
                }`}
                onClick={() => handleClick(1)}
              >
                1
              </button>
            </li>
            {currentPage - 3 <= 1 ? null : (
              <li className="page-item disabled opacity-75">
                <button className="page-link rounded-3 bg-lightcustom text-t-gri px-2 py-1">
                  ...
                </button>
              </li>
            )}
          </>
        )}
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item`}>
            <button
              className={`page-link rounded-3  px-2 py-1 ${
                currentPage === number
                  ? 'text-white  btn bg-custom'
                  : 'text-custom btn bg-lightcustom'
              }`}
              style={{ boxShadow: 'none' }}
              onClick={() => handleClick(number)}
            >
              {number}
            </button>
          </li>
        ))}
        {totalPages > 5 && currentPage < totalPages - 2 && (
          <>
            {currentPage + 3 >= totalPages ? null : (
              <li className="page-item disabled opacity-75">
                <button className="page-link rounded-3 bg-lightcustom text-t-gri px-2 py-1">
                  ...
                </button>
              </li>
            )}

            <li className="page-item">
              <button
                className={`page-link rounded-3 px-2 py-1 ${
                  !currentPage
                    ? 'text-white btn bg-custom'
                    : 'text-custom btn bg-lightcustom'
                }`}
                onClick={() => handleClick(totalPages)}
              >
                {totalPages}
              </button>
            </li>
          </>
        )}
        <li
          className={`page-item ${
            currentPage === 1 ? 'disabled opacity-50' : ''
          }`}
        >
          <button
            className="page-link bg-transparent text-t-gri px-2 py-1"
            onClick={() =>
              currentPage === totalPages && contentId !== undefined
                ? navigate(findMyPath('speakers'))
                : handleClick(currentPage + 1)
            }
            disabled={currentPage === totalPages && contentId === undefined}
            style={{ border: 'transparent' }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </li>
      </ul>
    );
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex justify-content-start">
        <span>
          {startIndex}-{endIndex} / {totalItems}
        </span>
      </div>
      <div className="d-flex justify-content-end">{renderPageNumbers()}</div>
    </div>
  );
};

export default Pagination;
