import React from 'react';
import { useTranslation } from 'react-i18next';
import Faq from '../../Landing/Faq';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';

const Support = () => {
  const { t } = useTranslation();
  return useLoader() ? (
    <Loader />
  ) : (
    <>
      <div className="d-flex justify-content-start ms-5">
        <HeadTags hSize={3} text={'Support'} strong />
      </div>
      {/* <div className="d-flex justify-content-start ms-4 mt-5">
        <Faq props={'calledBySupport'} />
      </div> */}
    </>
  );
};
export default Support;
