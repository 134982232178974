import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Common/Button/Button';

import { ButtonType, ButtonSize } from '../../../../../utils/ComponentEnums';
import LetUsCallYou from '../LetUsCallYou';
import { useNavigate } from 'react-router-dom';

const Besiktas = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFreeTrialClick = () => {
    navigate('/cafe/order/triallesson', {
      state: {
        fromNavigateWhere: 'freeTrial',
        trialStatus: 0,
      },
    });
  };
  return (
    <div>
      <section className="py-5 text-center container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 mt-lg-5 ">
            <h1 className="display-4 pb-4  ">Meet2talk Beşiktaş</h1>
            <h4 className=" py-2">
              Vişnezade Mah. Aktarlar Sk.
              <br />
              No: 2/A, Beşiktaş/İstanbul
            </h4>
            <a className="py-2" href="tel:+905524865239">
              <h4>0552 486 5239</h4>
            </a>
            <div className=" d-flex justify-content-center py-2">
              <Button
                text={t('landingMainBanner.get_trial')}
                textColor="text-white"
                type={ButtonType.LandingGreenBg}
                border
                ButtonSize={ButtonSize.Small}
                buttonCol={6}
                onClick={() => handleFreeTrialClick()}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-5">
            <div className="ratio ratio-4x3 ">
              <iframe
                className="embed-responsive-item rounded-4 "
                src="https://www.youtube.com/embed/qUNJ6jCpPN0"
                title="Meet2Talk"
                allowFullScreen
                style={{ boxShadow: ' 9px 9px #00c905' }}
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <div className="album py-5 text-center">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/besiktas_main.jpg"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/besiktas_1.jpg"
                  alt="..."
                  s
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5 ">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/besiktas_2.jpg"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/besiktas_3.jpg"
                  alt="..."
                />
              </div>
            </div>
            <div className="col">
              <div className="position-relative mb-5">
                <img
                  className="img-fluid rounded-3 mb-3"
                  src="/images/cafe/besiktas_4.jpg"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5">
        <LetUsCallYou
          iframe={
            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.1524098502787!2d28.996244951416536!3d41.043796025084895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7d95fe0bf2d%3A0x106f6df5f59dbd12!2sMeet%202%20Talk%20Cafe%20Be%C5%9Fikta%C5%9F!5e0!3m2!1sen!2str!4v1594564392314!5m2!1sen!2str'
          }
          area={1}
          location={'Beşiktaş'}
        />
      </div>
    </div>
  );
};

export default Besiktas;
