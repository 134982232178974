import React, { useState } from 'react';
import axios from 'axios';
import Card from '../../components/Common/Card/Card';
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import config from '../../config.json';
import { useSelector } from 'react-redux';
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};
const BASE_URL = config.Connections.API.host;

export default function Packages() {
  const [pageData, setPageData] = useState({
    Zone: '',
    Age: 0,
    Category: 0,
    Location: 0,
    Package: 0,
    WeekOption: 0,
    MonthOption: 0,
    Area: 0,
    Capacity: 0,
    Currency: 0,
  });

  const [priceData, setPriceData] = useState({
    Zone: '',
    Age: '',
    Category: '',
    Location: '',
    Package: '',
    WeekOption: '',
    MonthOption: '',
    Currency: '',
  });
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [ages, setAges] = useState([]);
  const [zones, setZones] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [weekOptions, setWeekOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [capacities, setCapacities] = useState([]);
  const [hideCategory, setHideCategory] = useState(true);
  const [hideAge, setHideAge] = useState(true);
  const [hideLocation, setHideLocation] = useState(true);
  const [hidePackage, setHidePackage] = useState(true);
  const [hideWeekOption, setHideWeekOption] = useState(true);
  const [hideMonthOption, setHideMonthOption] = useState(true);
  const [hideCurrencyOption, setHideCurrencyOption] = useState(true);

  const [hideArea, setHideArea] = useState(true);
  const [hideCapacity, setHideCapacity] = useState(true);
  const [priceCalculate, setPriceCalculate] = useState([]);

  const onReset = () => {
    setCategories([]);
    setAges([]);
    setLocations([]);
    setPackages([]);
    setWeekOptions([]);
    setMonthOptions([]);
    setAreas([]);
    setCapacities([]);
    setPriceCalculate([]);
    setPriceData([]);
    setCurrencyOptions([]);

    setHideCategory(true);
    setHideAge(true);
    setHideLocation(true);
    setHidePackage(true);
    setHideWeekOption(true);
    setHideMonthOption(true);
    setHideArea(true);
    setHideCapacity(true);
    setHideCurrencyOption(true);
  };

  const onCreating = () => {
    axios.post(BASE_URL + 'Package/Create', pageData, headers).then((res) => {
      toast.success('Oluşturuldu');
      onReset();
    });
  };
  const handleSelectChange = (e) => {
    e.preventDefault();
    setPageData({ ...pageData, [e.target.title]: parseInt(e.target.value) });
    setPriceData({ ...priceData, [e.target.title]: e.target.id });

    switch (e.target.title) {
      case 'Zone':
        axios
          .get(
            `${BASE_URL}OperationRestriction/GetAvaibleAges/${parseInt(
              e.target.value
            )}/${pageData.Category}/${pageData.Location}/${pageData.Package}/${
              pageData.WeekOption
            }/${pageData.Area}/${pageData.Capacity}`,
            headers
          )
          .then((res) => {
            setAges(res.data);
            setHideAge(false);
          });
        break;
      case 'Age':
        axios
          .get(
            `${BASE_URL}OperationRestriction/GetAvaibleCategory/${
              pageData.Zone
            }/${parseInt(e.target.value)}/${pageData.Location}/${
              pageData.Package
            }/${pageData.WeekOption}/${pageData.Area}/${pageData.Capacity}`,
            headers
          )
          .then((res) => {
            setCategories(res.data);
            setHideCategory(false);
          });
        break;
      case 'Category':
        axios
          .get(
            `${BASE_URL}OperationRestriction/GetAvaibleLocations/${
              pageData.Zone
            }/${pageData.Age}/${parseInt(e.target.value)}`,
            headers
          )
          .then((res) => {
            setLocations(res.data);
            setHideLocation(false);
          });
        break;
      case 'Location':
        axios
          .get(
            `${BASE_URL}OperationRestriction/GetAvaiblePackages/${
              pageData.Zone
            }/${pageData.Age}/${pageData.Category}/${parseInt(
              e.target.value
            )}/${pageData.WeekOption}`,
            headers
          )
          .then((res) => {
            setPackages(res.data);
            setHidePackage(false);
          });
        break;
      case 'Package':
        axios
          .get(
            `${BASE_URL}OperationRestriction/GetAvaibleWeekOptions/${
              pageData.Zone
            }/${pageData.Age}/${pageData.Category}/${
              pageData.Location
            }/${parseInt(e.target.value)}/${pageData.Area}/${
              pageData.Capacity
            }`,
            headers
          )
          .then((res) => {
            setWeekOptions(res.data);
            setHideWeekOption(false);
          });
        break;
      case 'WeekOption':
        axios
          .get(
            `${BASE_URL}OperationRestriction/GetAvaibleMonthOptions/${
              pageData.Zone
            }/${pageData.Age}/${pageData.Category}/${pageData.Location}/${
              pageData.Package
            }/${parseInt(e.target.value)}/${pageData.Area}/${
              pageData.Capacity
            }`,
            headers
          )
          .then((res) => {
            setMonthOptions(res.data);
            setHideMonthOption(false);
          });
        break;
      case 'MonthOption':
        axios
          .get(`${BASE_URL}Preference/GetById/${10}`, headers)
          .then((res) => {
            setCurrencyOptions(res.data.data);

            setHideCurrencyOption(false);
          });
        break;
      case 'Currency':
        const selectedCode = e.target.name;
        setSelectedCurrency(selectedCode);
        break;
      default:
        break;
    }
  };

  async function GetList() {
    axios.get(BASE_URL + 'Zone/GetAll', headers).then((res) => {
      setZones(res?.data?.data);
    });
  }
  useEffect(() => {
    GetList();
  }, []);

  const currencies = currencyOptions.value
    ? JSON.parse(currencyOptions.value)
    : [];

  const onCalculate = () => {
    axios
      .post(
        `${BASE_URL}Price/Calculate?zoneId=${pageData.Zone}&typeId=${pageData.Category}&locationId=${pageData.Location}&ageId=${pageData.Age}&packageId=${pageData.Package}&timesPerWeekId=${pageData.WeekOption}&monthDiscountId=${pageData.MonthOption}&currency=${pageData.Currency}`,
        headers
      )
      .then((res) => {
        setPriceCalculate(res.data.data);
      });
  };
  const content = (
    <div className="container-fluid">
      <h3 className="ms-4">Create Package</h3>
      <div className="row">
        <div className="col-12">
          <Card
            body={
              <div className="container-fluid">
                <div className="col d-flex align-items-center justify-content-center">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Zones
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {zones.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'Zone'}
                          className="dropdown-item1 text-nowrap px-1"
                          id={option.zoneName}
                          value={option.id}
                          href="#"
                          role="button"
                        >
                          {option.zoneName}
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="dropdown" hidden={hideAge}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Ages
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {ages.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'Age'}
                          className="dropdown-item2 text-nowrap px-1"
                          id={option.minAge + '-' + option.maxAge}
                          value={option.id}
                          href="#"
                          role="button"
                        >
                          {option.minAge + '-' + option.maxAge}
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="dropdown" hidden={hideCategory}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Categories
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {categories.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'Category'}
                          className="dropdown-item3 text-nowrap px-1"
                          id={option.categoryName}
                          value={option.id}
                          href="#"
                          role="button"
                        >
                          {option.categoryName}
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="dropdown" hidden={hideLocation}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Locations
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {locations.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'Location'}
                          className="dropdown-item4 text-nowrap px-1"
                          id={option.locationName}
                          value={option.id}
                          href="#"
                          role="button"
                        >
                          {option.locationName}
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="dropdown" hidden={hidePackage}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Packages
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {packages.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'Package'}
                          className="dropdown-item5 text-nowrap px-1"
                          id={option.displayName}
                          value={option.id}
                          href="#"
                          role="button"
                        >
                          {option.displayName}
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="dropdown" hidden={hideWeekOption}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Week Options
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {weekOptions.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'WeekOption'}
                          className="dropdown-item6 text-nowrap px-1"
                          id={option.perWeek}
                          value={option.id}
                          href="#"
                          role="button"
                        >
                          {option.perWeek}
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="dropdown" hidden={hideMonthOption}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Month Options
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {monthOptions.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'MonthOption'}
                          className="dropdown-item7 text-nowrap px-1"
                          id={option.monthDuration}
                          value={option.id}
                          href="#"
                          role="button"
                        >
                          {option.monthDuration}
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="dropdown" hidden={hideCurrencyOption}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Currency Options
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      {currencies?.map((option, index) => (
                        <li
                          onClick={handleSelectChange}
                          key={index}
                          title={'Currency'}
                          className="dropdown-item7 text-nowrap px-1"
                          id={option.Name}
                          value={option.Code}
                          href="#"
                          role="button"
                        >
                          {option.Name}
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-4"></div>
      </div>
      <div className="row">
        {hideAge === false ? (
          <span className="fw-bold  mx-1 ">Seçili Parametreler :</span>
        ) : undefined}
        <div className="mb-3 d-flex">
          {pageData.Zone ? (
            <span className="fw-bold  mx-1 ">Zone : {priceData.Zone},</span>
          ) : undefined}
          {pageData.Age ? (
            <span className="fw-bold  mx-1">Age : {priceData.Age},</span>
          ) : undefined}
          {pageData.Category ? (
            <span className="fw-bold  mx-1">
              Category : {priceData.Category},
            </span>
          ) : undefined}
          {pageData.Location ? (
            <span className="fw-bold  mx-1">
              Location : {priceData.Location},
            </span>
          ) : undefined}
          {pageData.Package ? (
            <span className="fw-bold  mx-1">
              Package : {priceData.Package},
            </span>
          ) : undefined}
          {pageData.WeekOption ? (
            <span className="fw-bold mx-1">
              WeekOption : {priceData.WeekOption},
            </span>
          ) : undefined}
          {pageData.MonthOption ? (
            <span className="fw-bold  mx-1">
              MonthOption : {priceData.MonthOption},
            </span>
          ) : undefined}
          {pageData.Currency ? (
            <span className="fw-bold  mx-1">
              Currency : {priceData.Currency}
            </span>
          ) : undefined}
        </div>

        <div className="col-4">
          <button
            className="btn btn-success"
            type={'submit'}
            onClick={onCreating}
          >
            {' '}
            Create Package
          </button>

          <button className="btn btn-info" type={'reset'} onClick={onReset}>
            {' '}
            Reset All
          </button>
        </div>
        <div className="col-4">
          <button
            className="btn btn-success"
            type={'submit'}
            onClick={onCalculate}
          >
            {' '}
            Calculate{' '}
          </button>
        </div>
        <ToastContainer
          limit={1}
          position={'top-center'}
          autoClose={2000}
          closeOnClick
          theme="light"
        />
        <div className="col-4">
          <div className="d-flex row">
            {priceCalculate?.map((price, index) => (
              <span key={price.priceId} className="fw-bold">
                {price.priceName} : {price.priceValue} {''} {priceData.Currency}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  return content;
}

// {"Age": 2,"Zone": [1],"Type": [1],"Location":[1],"WeekOptions":[1],"Package":[1],"Area":[1],"Capacity":[1],
// "Age": 1,"Zone": [1],"Type": [1],"Location":[1],"WeekOptions":[1],"Package":[1],"Area":[1],"Capacity":[1]}
