import { useState, useEffect } from 'react';

function useLoader(value) {
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    setShowLoader(true);
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, value ?? 80);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);
  return showLoader;
}

export default useLoader;
