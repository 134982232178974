import Register from './AuthPages/Register';
import Login from './AuthPages/Login';
import Main from './CommonPages/Main';
import CompanyPriceRequest from './CompanyPriceRequest';
import BecomeASpeaker from './BecomeASpeaker';
import BecomeAPartner from './BecomeAPartner';
import Error404 from './CommonPages/Error404';
import Unauthorized from './CommonPages/Unauthorized';
import Faq from './Faq';
import OnBoarding from './OnBoarding/OnBoarding';
import Online from './CommonPages/MainSubPages/Online';
import Cafe from './CommonPages/MainSubPages/Cafe';
import Kids from './CommonPages/MainSubPages/Kids';
import Company from './CommonPages/MainSubPages/Company';
import Kadikoy from './CommonPages/MainSubPages/Branches/Kadikoy';
import Antalya from './CommonPages/MainSubPages/Branches/Antalya';
import Besiktas from './CommonPages/MainSubPages/Branches/Besiktas';
import Camlica from './CommonPages/MainSubPages/Branches/Camlica';
import Maslak from './CommonPages/MainSubPages/Branches/Maslak';
import Mecidiyekoy from './CommonPages/MainSubPages/Branches/Mecidiyekoy';
import Campaigns from './CampaignPages/Campaign';
import CampaignDetail from './CampaignPages/CampaignDetail';
import ForgotPassword from './AuthPages/ForgotPassword';
import VerifyEmail from './AuthPages/VerifyEmail';
import LeadForm from './LeadForm';
import NewMain from './CommonPages/NewMain';
import NewKadikoy from './CommonPages/NewKadıköy';


const Landing = {
  Main,
  Register,
  Login,
  CompanyPriceRequest,
  BecomeASpeaker,
  BecomeAPartner,
  Error404,
  Unauthorized,
  Faq,
  OnBoarding,
  Online,
  Cafe,
  Kids,
  Company,
  Kadikoy,
  Antalya,
  Besiktas,
  Camlica,
  Maslak,
  Mecidiyekoy,
  Campaigns,
  CampaignDetail,
  ForgotPassword,
  VerifyEmail,
  LeadForm,
  NewMain,
  NewKadikoy,
};

export default Landing;
