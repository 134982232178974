import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import { useNavigate } from 'react-router-dom';
import BasicTable from '../../../components/Common/Table/BasicTable';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faComputerMouse,
  faGift,
  faShareFromSquare,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import Input from '../../../components/Common/Input/Input';
import MobileTable from '../../../components/Common/Table/MobileTable';
import config from '../../../config.json';
import findMyPath from '../../../hooks/useFindMyPath';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import { ToastContainer, toast } from 'react-toastify';

const icons = [faShareFromSquare, faComputerMouse, faShoppingCart, faGift];
export default function EarnFreeSession({ refCode }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((e) => e.user.getById);
  const earnedFreeSessions = useSelector((e) => e.order.earnedFreeSessions);
  const isSuccess = useSelector((e) => e.attendee.isSuccess);
  const validationResponse = useSelector((e) => e.attendee.validationMessage);
  const myUrl = `${config.Connections.UI.host}account/register?refCode=${refCode.refId}`;
  const [createData, setCreateData] = useState({
    senderFullName: `${userInfo?.firstName} ${userInfo?.lastName}`,
    url: refCode?.refId,
  });
  const [buttonTxt, setButtonTxt] = useState(false);
  const [error, setError] = useState({});
  const [handleError, setHandleError] = useState('');

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.error(handleError, {
        onClose: () => {
          const { recipientEmail, ...updatedCreateData } = createData;
          setCreateData(updatedCreateData);
          setHandleError('');
          setError({});
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          const { recipientEmail, ...updatedCreateData } = createData;
          setCreateData(updatedCreateData);
          setHandleError('');
          setError({});
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationResponse !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationResponse}</strong>
        </span>
      );
    } else if (!isSuccess && validationResponse !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationResponse}</strong>
        </span>
      );
    }
  }, [isSuccess, validationResponse]);

  const textArr = [
    t('earnFreeSession.first_explanation'),
    t('earnFreeSession.second_explanation'),
    t('earnFreeSession.third_explanation'),
    t('earnFreeSession.forth_explanation'),
  ];
  const headers = [
    { key: 'orderNumber', label: t('earnFreeSessionTable.order_number') },
    {
      key: 'orderStatus',
      label: t('earnFreeSessionTable.order_status'),
    },
    { key: 'earnedDate', label: t('earnFreeSessionTable.earned_date') },
    { key: 'earnedFrom', label: t('earnFreeSessionTable.earned_from') },
    {
      key: 'undatedNumberOf',
      label: t('earnFreeSessionTable.undated_number_of'),
    },
  ];
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
      setError((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else if (validationResult && value !== '') {
      setError({ ...error, [id]: undefined });
    }
  };
  const selectedValues = earnedFreeSessions
    ?.filter((item) => item.undatedNumberOf > 0)
    ?.map((item) => ({
      orderNumber: item.orderNumber,
    }));

  const setDateButton = (val) => {
    navigate(findMyPath('speakers'), {
      state: {
        fromNavigateWhere: 'earnFreeSessions',
        filterData: {
          TypeOfService: null,
          UserArea: userInfo?.userArea,
          DateTime: null,
          FullName: null,
          Native: null,
          LearningPurpose: null,
          LanguageLevels: null,
          Locations: null,
          SelectedIds: null,
          CompanyId: 1,
          DateDetails: {
            dayOfWeek: null,
            timeInterval: null,
            selectedDate: null,
            selectedTime: null,
          },
        },
        selectLaterItems: {
          orderItemId: selectedValues?.[val]?.orderNumber,
          productType: 'Group',
          timesPerWeek: 1,
        },
      },
    });
  };

  useEffect(() => {
    dispatch(Actions.orderActions.getEarnedFreeSessionsAction());
  }, []);
  const handleClick = async () => {
    navigator.clipboard.writeText(myUrl);
    setButtonTxt(true);
    setTimeout(() => {
      setButtonTxt(false);
    }, 2000);
  };

  const onSendingEmail = () => {
    if (
      Object.values(error).every((e) => e === undefined) &&
      Object.keys(error).length > 0 &&
      handleError === ''
    ) {
      dispatch(Actions.attendeeActions.sendRefCodeEmailAction(createData));
    } else if (
      Object.values(error).every((e) => e !== undefined) &&
      Object.keys(error).length === 0
    ) {
      setError({
        recipientEmail: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    }
  };

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
    width: '500px',
  };

  const formattedDetails = t('earnFreeSession.details')
    .split('{br}')
    .map((item, index) => (
      <p key={index} className={'mb-2'}>
        <strong>{item.split(':')[0]}</strong>: {item.split(':')[1]}
      </p>
    ));

  const styleText = (text, color) => {
    const parts = text.split(/\|([^|]+)\|/g);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span key={index} style={{ color }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="container-fluid">
      <div className="row mt-2">
        <div className="col-12 container">
          <React.Fragment>
            <div className="col-12 col-md-4 d-flex align-items-center justify-content-start mt-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="color-bar" />
              </div>
              <span className="fs-4" style={{ lineHeight: '1.1' }}>
                <strong>
                  {styleText(
                    t('earnFreeSession.card_header_explanation'),
                    '#4CBC9A'
                  )}
                </strong>
              </span>
            </div>
            <div className="col-12 mt-4">
              <div className="row g-4">
                {textArr.map((value, index) => (
                  <div
                    key={`first_div_earngift${index}`}
                    className="col col-md-2 d-flex align-items-stretch me-md-5 me-0"
                  >
                    <div className="square text-center d-flex flex-column align-items-center">
                      <FontAwesomeIcon
                        className="fa-4x text-info mb-3"
                        icon={icons[index]}
                      />
                      <div className="fw-bold text-dark mb-3">{value}</div>
                      <div className="billiard-ball d-flex align-items-center justify-content-center">
                        <div className="ball-number">{index + 1}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row mb-3 mt-4">
                <div className="col d-flex flex-column flex-md-row mb-3 mb-md-0">
                  <div className="col col-md-2 mb-2 mb-md-0 me-0 me-md-2">
                    <Input
                      className={'form-control border'}
                      type={InputType.Email}
                      value={myUrl}
                      disabled
                    />
                  </div>
                  <div className="col col-md-2 d-flex justify-content-md-end me-0 me-md-4 mb-2 mb-md-0">
                    <Button
                      id={'share'}
                      text={t(
                        !buttonTxt
                          ? 'earnFreeSession.first_second_text'
                          : 'buy.eft_copy'
                      )}
                      type={
                        !buttonTxt ? ButtonType.Successbg : ButtonType.Secondary
                      }
                      textColor={TextColors.Light}
                      buttonCol={12}
                      bold
                      onClick={handleClick}
                    />
                  </div>
                  <div className="col col-md-3 mb-2 mb-md-0 me-0 me-md-2">
                    <Input
                      id={'recipientEmail'}
                      className={'form-control border'}
                      type={InputType.Email}
                      placeholder={t('earnFreeSession.first_input_placeholder')}
                      onChange={onChangeText}
                      value={createData.recipientEmail || ''}
                      error={error.recipientEmail}
                    />
                  </div>
                  <div className="col col-md-2 d-flex justify-content-md-end">
                    <Button
                      id={'invite'}
                      text={t('earnFreeSession.first_button_text')}
                      type={ButtonType.Successbg}
                      textColor={TextColors.Light}
                      bold
                      buttonCol={12}
                      onClick={(e) => onSendingEmail()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-md-5 mt-2">
              <div className="col col-md-4">
                <div className="square p-4">
                  <HeadTags
                    text={t('earnFreeSession.header')}
                    hSize={5}
                    strong
                  />
                  {formattedDetails}
                </div>
              </div>
              <div className="col col-md-4 mt-2 mt-md-0">
                <div className="square p-4 text-start">
                  <div className="mt-4">
                    <HeadTags
                      text={t('earnFreeSession.explainingHeader')}
                      hSize={5}
                      strong
                    />
                    <p>
                      <strong>{t('earnFreeSession.explainingText')}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
        <div className="col-12 container mt-2">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-between mt-3">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('earnFreeSession.card_table_header')}
                    textColor={TextColors.Dark}
                  />
                </div>
                <div className="container-fluid mt-5">
                  <div className="row">
                    <div className="col-sm-12 d-none d-sm-block">
                      <BasicTable
                        data={earnedFreeSessions}
                        headers={headers}
                        actionButtonProps={{
                          text: t('earnFreeSessionTable.button_set_date'),
                          type: ButtonType.Successbg,
                          textColor: TextColors.Light,
                        }}
                        matchValues={{
                          actionButtonProps: {
                            key: 'undatedNumberOf',
                            value: 0,
                          },
                          infoButtonProps: {
                            key: 'undatedNumberOf',
                            value: null,
                          },
                        }}
                        onClickFuncSetDate={(val) => setDateButton(val)}
                      />
                    </div>
                    <div className="d-block d-sm-none">
                      <MobileTable
                        searchAble
                        data={earnedFreeSessions}
                        headers={headers}
                        tableType={'basic'}
                        actionButtonProps={{
                          text: t('earnFreeSessionTable.button_set_date'),
                          type: ButtonType.Successbg,
                          textColor: TextColors.Light,
                        }}
                        matchValues={{
                          actionButtonProps: {
                            key: 'undatedNumberOf',
                            value: 0,
                          },
                          infoButtonProps: {
                            key: 'undatedNumberOf',
                            value: null,
                          },
                        }}
                        onClickFuncSetDate={(val) => setDateButton(val)}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          key={'feedback_component'}
          style={customToastStyle}
          autoClose={false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );
}
