import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import { ActionFilterRules, Entities } from '../../../utils/Enum';
import Toast from '../../../components/Common/Popup/Toast';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((e) => e.user.getById);
  const callList = useSelector((e) => e.lead.getAll);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const staticDatas = useSelector((e) => e.staticData);
  const learningPurpose = staticDatas.LearningPurpose;
  const levelGroup = useSelector(
    (e) => e.preferenceOptions.preferenceLevelGroup
  );

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    dispatch(Actions.leadActions.getAllAction());
  };

  const editAction = (val) => {
    navigate('/lead-update', { state: { id: val.id } });
  };

  const setInterviewedAction = (val) => {
    dispatch(Actions.leadActions.updateAction({ id: val })).then(() => {
      dispatch(Actions.leadActions.getAllAction());
    });
  };

  const setRegisterAction = (val) => {
    const phone = val.phone.replace(/\D/g, '').slice(2);
    const nameParts = val.name.trim().split(' ');
    const lastName = nameParts.pop();
    const firstName = nameParts.join(' ');
    const level = selectedLanguageLevels.find(
      (level) =>
        t(level.value, { lng: 'en' }) === val.languageLevel ||
        t(level.value, { lng: 'tr' }) === val.languageLevel
    ).value;
    const purpose = learningPurpose.find(
      (item) =>
        t(item.key, { lng: 'en' }) === val.learningPurpose ||
        t(item.key, { lng: 'tr' }) === val.learningPurpose
    ).value;

    const object = {
      firstName: firstName,
      lastName: lastName,
      email: val.email,
      phone: phone,
      learningPurpose: purpose,
      languageLevel: level,
      formId: val.id,
      managerId: userInfo.managerId === 0 ? 1 : userInfo.managerId,
    };
    dispatch(Actions.attendeeActions.createAction(object));
  };

  const mapLanguageLevelToEnglish = (data) => {
    return data.map((item) => ({
      ...item,
      learningPurpose: t(item.learningPurpose),
      languageLevel: t(item.languageLevel, { lng: 'en' }),
    }));
  };

  const mappedCallList = mapLanguageLevelToEnglish(callList);
  const headers = [
    { key: 'id', label: t('mailSubscription.table_id') },
    { key: 'name', label: t('common.name') },
    { key: 'email', label: t('common.email') },
    { key: 'phone', label: t('common.phone') },
    { key: 'learningPurpose', label: t('becomeAMentor.learningPurposes') },
    { key: 'location', label: t('mailSubscription.table_location') },
    { key: 'languageLevel', label: t('meeting.table_level') },
    { key: 'ageInterval', label: t('meetingSchema.age_interval') },
    { key: 'privacyConsent', label: t('Privacy Consent') },
    { key: 'note', label: t('mailSubscription.table_note') },
    { key: 'campaignName', label: t('Campaign') },
    { key: 'interviewed', label: t('career.interviewed') },
    { key: 'interviewer', label: t('career.interviewer') },
    { key: 'createdAt', label: t('common.created_at') },
    { key: 'isRegistered', hide: true },
  ];

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    selectedLanguageLevels.push(
      ...staticDatas.LanguageLevels[levelGroup?.kids]
    );
    selectedLanguageLevels.push(
      ...staticDatas.LanguageLevels[levelGroup?.adult]
    );
  }

  useEffect(() => {
    return () => {
      dispatch(Actions.leadActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-center mb-4">
        <HeadTags hSize={1} text={t('Lead Form List')} strong />
      </div>
      <div className="row">
        <div className="col">
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={mappedCallList}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.lead}
                  currentUserClaim={currentUserClaim}
                  edit={(val) => editAction(val)}
                  interviewed={(val) => setInterviewedAction(val)}
                  setRegister={(val) => setRegisterAction(val)}
                  exportName={Entities.lead.exportName}
                  filterActionsRule={true}
                  filterRule={ActionFilterRules.register}
                  tdfontSize={10}
                />
              </div>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'attendee'}
        onClose={() => {
          dispatch(Actions.leadActions.getAllAction());
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.lead.getAll,
    content
  );
};
export default List;
