import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const staticDataReducer = (state = initialState.staticDatas, action) => {
  switch (action.type) {
    case ActionTypes.staticData.GETALL_STATICDATA:
      return {
        ...state,
        [action.payload.name]: action.payload.newData,
      };
    default:
      return state;
  }
};

export default staticDataReducer;
