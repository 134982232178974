import React, { useEffect } from 'react';
import styles from '../../../assets/Styles.module.scss';

const CalendarModal = ({
  isVisible = false,
  title,
  content,
  footer,
  onClose,
  imgSrc,
  styleProps,
  textColor,
}) => {
  const keydownHandler = ({ key }) => {
    switch (key) {
      case 'Escape':
        onClose();
        break;
      default:
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });

  return !isVisible ? null : (
    <div className={styles.calendar_modal} onClick={onClose}>
      <div
        className={`${styles.modal_dialog} ${styles.responsive_dialog}`}
        style={{
          maxWidth: `${styleProps?.maxWidth}px`,
          minHeight: `${styleProps?.minHeight}px`,
          maxHeight: `${styleProps?.maxHeight}px`,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modal_header}>
          {title && (
            <>
              <h5 className={`text-${textColor}`}>
                {imgSrc && (
                  <img
                    src={imgSrc?.src}
                    className={'img-fluid me-3'}
                    style={{
                      width: imgSrc.width,
                    }}
                    alt={imgSrc?.alt}
                  />
                )}
                <strong>{title}</strong>
              </h5>
            </>
          )}
          <button
            type="button"
            className="btn-close text-right position-absolute top-0 end-0 m-3"
            onClick={onClose}
          ></button>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.modal_content}>{content}</div>
        </div>
        {footer && <div className={styles.modal_footer}>{footer}</div>}
      </div>
    </div>
  );
};
export default CalendarModal;
