import React from 'react';

import CompanyPriceRequest from '../../CompanyPriceRequest';
import { useTranslation } from 'react-i18next';
import landingCalendar from '../../../../assets/images/landingcalendar.svg';
import landingPeople from '../../../../assets/images/landingpeople.svg';
import landingClown from '../../../../assets/images/landingclown.svg';

const Company = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid align-items-center">
      <div className="banner bg-light rounded-3 my-2 py-lg-5">
        <div className="container flex-md-column-reverse">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 mt-5 order-md-1 order-2">
              <h1 className="display-4 py-2  text-start">
                Meet2Talk {t('MainPart4PresentationHeaderCompany.Header')}
              </h1>
              <h6 className="text-muted">
                {t('MainPart4PresentationHeaderCompany.Description')}
              </h6>
            </div>
            <div className="col-sm-12 col-md-6 mt-2 order-md-2 order-1">
              <div className="ratio ratio-custom">
                <iframe
                  className="embed-responsive-item rounded-4 "
                  src="https://www.youtube.com/embed/SdWMO3DnmhI"
                  title="Meet2Talk"
                  allowFullScreen
                  style={{ boxShadow: ' 9px 9px #00c905' }}
                  loading="lazy"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section3 */}
      <div className="our_story py-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-5 mt-5">
              <div className="ratio ratio-custom">
                <iframe
                  className="embed-responsive-item rounded-4 "
                  src="https://www.youtube.com/embed/YQcWBGE3aCI"
                  title="Meet2Talk"
                  allowFullScreen
                  style={{ boxShadow: '-9px 9px 0 0 #00c905 ' }}
                  loading="lazy"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mt-lg-5 ms-4">
              <h3 className="display-4 py-2 fw-bold text-start">
                {t('MainPart2OurStory.Our_Story')}
              </h3>
              <span className="text-muted">
                {t('MainPart2OurStory.Our_Story_Description')}
              </span>
            </div>
          </div>
        </div>
      </div>
      .{/* Section4 */}
      <div className="how_it_works py-5 bg-light text-center px-5">
        <div className="container">
          <h1 className="text-black mb-5 fw-bold">
            {t('MainPart3HowItWorks.How_it_works')}
          </h1>
          <div className="row g-3 d-flex justify-content-between">
            <div className="d-flex col-sm-4 align-items-center">
              <div className="flex-shrink-0">
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img
                    className="img ms-1"
                    src={landingCalendar}
                    alt={'landingCalendar'}
                    style={{ maxWidth: '60px' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 2.5,
                      width: '63px',
                      height: '63px',
                      borderRadius: '50%',
                      border: '3px solid black',
                    }}
                  ></div>
                </div>
              </div>
              <div className="flex-grow-1 text-start mx-2">
                <h6 className="text-black fw-bold">
                  {t('MainPart3HowItWorks.Trial_header')}
                </h6>
                <p>{t('MainPart3HowItWorks.Trial_description')}</p>
              </div>
            </div>
            <div className="d-flex col-sm-4 align-items-center">
              <div className="flex-shrink-0">
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img
                    className="img ms-1"
                    src={landingPeople}
                    alt={'landingPeople'}
                    style={{ maxWidth: '60px' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 2.5,
                      width: '63px',
                      height: '63px',
                      borderRadius: '50%',
                      border: '3px solid black',
                    }}
                  ></div>
                </div>
              </div>
              <div className="flex-grow-1 text-start mx-2">
                <h6 className="text-black fw-bold">
                  {t('MainPart3HowItWorks.Instructors_header')}
                </h6>
                <p>{t('MainPart3HowItWorks.Instructors_description')}</p>
              </div>
            </div>
            <div className="d-flex col-sm-4 align-items-center">
              <div className="flex-shrink-0">
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img
                    className="img ms-1"
                    src={landingClown}
                    alt={'landingClown'}
                    style={{ maxWidth: '60px' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 2.5,
                      width: '63px',
                      height: '63px',
                      borderRadius: '50%',
                      border: '3px solid black',
                    }}
                  ></div>
                </div>
              </div>
              <div className="flex-grow-1 text-start mx-2">
                <h6 className="text-black fw-bold">
                  {t('MainPart3HowItWorks.Speak_English_header')}
                </h6>
                <p>{t('MainPart3HowItWorks.Speak_English_description')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form-area py-5 my-lg-5">
        <div className="container">
          <div className="row justify-content-between align-items-center bg-light">
            <div className="col-md-6">
              <img
                src={`../images/letuscallyouwp.webp`}
                className="rounded-5"
                width={'100%'}
                height={'80%'}
                alt="letuscallyouwp.webp"
              />
            </div>
            <div className="col-md-6 justify-content-center align-items-center">
              <CompanyPriceRequest />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
