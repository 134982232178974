import React, { useState } from 'react';
import List from '../../../BackOffice/Company/CompanyOrder/List';

const CompanyDashboard = () => {
  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Company Dashboard</h1>
      </div>
      <List />
    </div>
  );
  return content;
};
export default CompanyDashboard;
