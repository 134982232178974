import { postRequest, getRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const getBySpeakerId = async (id) => {
  return await getRequest(
    `${endPoint.calendar.GetCalendarData}/${id}`
  );
};
export const getAll = async (date) => {
  return await postRequest(endPoint.calendar.GetAllCalendarData, date);
};
export const getFiltred = async (data) => {
  return await postRequest(
    `${endPoint.calendar.GetAllCalendarDataWithFilter}`,
    JSON.stringify(data)
  );
};
export const getFiltredSpeakers = async (data) => {
  return await postRequest(
    `${endPoint.calendar.GetAllSpeakersWithFilter}`,
    JSON.stringify(data)
  );
};
export const getSelectedSpeakerData = async (data) => {
  return await postRequest(
    `${endPoint.calendar.GetSelectedSpeakerCalenderData}`,
    data
  );
};
export const getCellDetails = async (data) => {
  return await postRequest(endPoint.calendar.GetCellDetails, data);
};
export const getAllCalendarDataWithFilterBO = async (data) => {
  return await postRequest(
    endPoint.calendar.GetAllCalendarDataWithFilterBO,
    data
  );
};
export const getCellDetailsBO = async (data) => {
  return await postRequest(endPoint.calendar.GetCellDetailsBO, data);
};
export const getMeetingAttendees = async (data) => {
  return await postRequest(endPoint.calendar.GetMeetingAttendees, data);
};
