import { getRequest, postRequest } from "../axiosRequest";
import endPoint from './endPointAddresses';

export const getAll = async (requestType = '') => {
    const typeData = typeof requestType === 'object' ? true : false;
    if (typeData || requestType === '') {
        return await postRequest(
            `${endPoint.speakerEffort.GET_ALL_SPEAKEREFFORT}`,
            typeData ? requestType : null
        );
    } else if (requestType !== '' && !typeData) {
        return await getRequest(
            `${endPoint.speakerEffort.GET_ALL_SPEAKEREFFORT}${`/${requestType}`}`
        );
    }
};
export const getSpeakerDetail = async (data) => {
    return await postRequest(endPoint.speakerEffort.GET_DETAIL_SPEAKEREFFORT, data);
};

export const unPayMeeting = async (data) => {
    return await postRequest(endPoint.speakerEffort.UN_PAY_MEETING_SPEAKEREFFORT, data);
};
