import { REGEX } from "../utils/RegexEnums";

export function formatPhone(showValue = undefined, cleanValue = undefined) {

  let value = "";
  if (cleanValue !== undefined) {
    value = cleanValue?.replace(/[-()]/g, '');
  }
  else if (showValue !== undefined) {
    value = showValue?.replace(
      REGEX.TEL,
      '($1)-$2-$3-$4'
    )
  }
  return value;
}
