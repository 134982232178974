import { storageKeys } from '../../utils/Enum';
import { postRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const attendeeMarketingSourceCreate = async (createData) => {
  return await postRequest(
    endPoint.attendeeMarketingSource.Create,
    createData
  ).then(() => {
    sessionStorage.removeItem(storageKeys.utmDataInsert);
  });
};
