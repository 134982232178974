import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import useLoader from '../../../hooks/useLoader';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Input from '../../../components/Common/Input/Input';
import BasicPopUp from '../../../components/Common/Popup/BasicPopUp';
import { MultiselectCheckbox } from '../../../components/Common/MultiselectCheckbox/MultiselectCheckbox';
import { Loader } from '../../../components/Common/Loader/Loader';
import {
  ButtonType,
  ButtonSize,
  TextColors,
} from '../../../utils/ComponentEnums';
import { checkLogged } from '../../../hooks/useCheckLogged';
import Modal from '../../../components/Common/Modal/Modal';
import Privacy from '../CommonPages/Privacy';
import TermsOFUse from '../CommonPages/TermsOFUse';
import PaymentPolicy from '../CommonPages/PaymentPolicy';
import MembershipPolicy from '../CommonPages/MembershipPolicy';
import CookiePolicy from '../CommonPages/CookiePolicy';

export default function ProfileSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((e) => e.user?.getById?.preferences);
  const [isOpen, setIsOpen] = useState(false);
  const isSuccess = useSelector((e) => e.user.isSuccess);
  const validationMessage = useSelector((e) => e.user.validationMessage);
  const [handleValidation, setHandleValidation] = useState('');
  const [contactType, setContactType] = useState(null);
  const [wantedInfos, setWantedInfos] = useState(null);
  const [userSettings, setUserSettings] = useState({});
  const [checkButtons, setCheckButtons] = useState(false);
  const notificationType = useSelector((e) => e.staticData.NotificationType);
  const notificationCategory = useSelector(
    (e) => e.staticData.NotificationCategory
  );
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [membershipModalVisible, setMembershipModalVisible] = useState(false);
  const [cookieModalVisible, setCookieModalVisible] = useState(false);

  const [answer, setAnswer] = useState('Deactivate My Account');

  useEffect(() => {
    if (userInfo !== undefined) {
      const userNotificationType = JSON.parse(userInfo)?.NotificationChannel;
      const userNotificationCategory =
        JSON.parse(userInfo)?.NotificationCategory;
      setContactType(createOptionsList(notificationType, userNotificationType));
      setWantedInfos(
        createOptionsList(notificationCategory, userNotificationCategory)
      );
    }
  }, [notificationCategory, notificationType, userInfo]);
  function createOptionsList(rawData, selectedValues) {
    return rawData.map((item) => ({
      value: parseInt(item.value),
      type: item.key,
      checked: selectedValues?.includes(parseInt(item.value)),
    }));
  }
  const onDecisionAccount = (e) => {
    e.preventDefault();
    if (e.target.id === 'Yes') {
      // dispatch(Actions.account.suspended()) waiting backend
      dispatch(Actions.authActions.logoutAction());
    }
    setIsOpen(false);

    //setAnswer("Deactivated")
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const onUpdate = (e) => {
    e.preventDefault();
    dispatch(Actions.userActions.updatePreferenceAction(userSettings));
  };
  const onDownload = (e) => {
    e.preventDefault();
    // service needed
  };

  useEffect(() => {
    if (isSuccess && contactType !== null && wantedInfos !== null) {
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
      setHandleValidation({});
      setCheckButtons(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    setHandleValidation(
      <span className="text-danger">
        {t('profileSettings.update_error_message')}
      </span>
    );
  }, [validationMessage]);

  function setSetting(data, type) {
    if (type === 'NotificationType') {
      const filteredData = data?.filter((item) => item.checked);
      const resultArray = filteredData?.map((item) => item.value);
      setUserSettings({ ...userSettings, NotificationChannel: resultArray });
    }
    if (type === 'NotificationCategory') {
      const filteredData = data?.filter((item) => item.checked);
      const resultArray = filteredData?.map((item) => item.value);
      setUserSettings({ ...userSettings, NotificationCategory: resultArray });
    }
  }
  useEffect(() => {
    if (contactType !== null && wantedInfos !== null) {
      let updatedSettings = { ...userSettings };

      if (contactType !== null) {
        const filteredContactType = contactType?.filter((item) => item.checked);
        const resultContactType = filteredContactType?.map(
          (item) => item.value
        );
        updatedSettings = {
          ...updatedSettings,
          NotificationChannel: resultContactType,
        };
      }
      if (wantedInfos !== null) {
        const filteredWantedInfos = wantedInfos?.filter((item) => item.checked);
        const resultWantedInfos = filteredWantedInfos?.map(
          (item) => item.value
        );
        updatedSettings = {
          ...updatedSettings,
          NotificationCategory: resultWantedInfos,
        };
      }
      setUserSettings(updatedSettings);
    }
  }, [wantedInfos, contactType]);

  const handleModalOpen = (modalType) => {
    if (modalType === 'terms') {
      setTermsModalVisible(true);
    } else if (modalType === 'privacy') {
      setPrivacyModalVisible(true);
    } else if (modalType === 'payment') {
      setPaymentModalVisible(true);
    } else if (modalType === 'membership') {
      setMembershipModalVisible(true);
    } else if (modalType === 'cookie') {
      setCookieModalVisible(true);
    }
  };
  return useLoader() ? (
    <Loader />
  ) : (
    <div className="container-fluid">
      <div className="row mb-4">
        <HeadTags hSize={3} strong text={t('profileSettings.main_header')} />
      </div>
      <Card
        body={
          <>
            <div className="row">
              <div className="col-lg">
                <Card
                  body={
                    <>
                      <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                        <HeadTags
                          hSize={6}
                          strong
                          text={t(
                            'profileSettings.communication_channels_preferences'
                          )}
                        />
                      </div>
                      <div className="row">
                        <HeadTags
                          hSize={6}
                          strong
                          text={t('profileSettings.i_would_like_to_recieve')}
                        />
                      </div>
                      <div className="d-sm-flex flex-sm-row">
                        <div className="d-sm-flex flex-sm-column">
                          <MultiselectCheckbox
                            options={contactType}
                            propNames={{
                              type: 'type',
                              checked: 'checked',
                              id: 'value',
                            }}
                            onChange={(data) => {
                              setSetting(data, 'NotificationType');
                              setCheckButtons(true);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
              <div className="col-lg">
                <Card
                  body={
                    <>
                      <div className="d-sm-flex align-items-center justify-content-between mb-sm-4 form-div-border">
                        <HeadTags
                          hSize={6}
                          strong
                          text={t(
                            'profileSettings.communication_topic_preferences'
                          )}
                        />
                      </div>
                      <div className="d-sm-flex">
                        <HeadTags
                          hSize={6}
                          strong
                          text={t('profileSettings.i_would_like_to_recieve')}
                        />
                      </div>
                      <div className="d-sm-flex flex-sm-row">
                        <div className="d-sm-flex flex-sm-column">
                          <MultiselectCheckbox
                            options={wantedInfos}
                            propNames={{
                              type: 'type',
                              checked: 'checked',
                              id: 'value',
                            }}
                            onChange={(data) => {
                              setSetting(data, 'NotificationCategory');
                              setCheckButtons(true);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
              <div className="d-flex justify-content-end">
                <div className={`col-sm-${checkButtons ? 2 : 1}`}>
                  {validationMessage && validationMessage}
                  <Button
                    id={'secondSetup'}
                    text={
                      checkButtons
                        ? t('profileSettings.update_my_preferences')
                        : t('profileSettings.updated')
                    }
                    type={
                      checkButtons ? ButtonType.Primary : ButtonType.Secondary
                    }
                    size={ButtonSize.Small}
                    bold
                    buttonCol={12}
                    disabled={checkButtons ? false : true}
                    onClick={onUpdate}
                  />
                </div>
              </div>
            </div>
          </>
        }
      />
      {/* <div className="col-sm-12">
        <Card
          body={
            <>
              <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                <HeadTags hSize={6} strong text={'Data and Account'} />
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <Input
                    className={'form-control mt-2 form-input-border'}
                    placeholder={'Download all my data on Meet2Talk'}
                    disabled
                  />
                </div>
                <div className="col-sm-5 mt-2">
                  <Button
                    type={ButtonType.Successbg}
                    text={'Download'}
                    textColor={TextColors.Light}
                    buttonCol={5}
                    onClick={onDownload}
                  />
                </div>
                <div className="col-sm-5">
                  <Input
                    className={'form-control mt-2 form-input-border'}
                    placeholder={answer}
                    disabled
                  />
                </div>
                <div className="col-sm-5 mt-2">
                  <Button
                    type={ButtonType.Successbg}
                    text={'Deactivate'}
                    textColor={TextColors.Light}
                    buttonCol={5}
                    onClick={() => setIsOpen(true)}
                  />
                  <BasicPopUp
                    isOpen={isOpen}
                    onClose={handleClose}
                    position={{ top: 50, left: 500 }}
                    text={
                      'Your account will be suspended, are you sure? Then you can open it through customer service.'
                    }
                    button={{ text: 'Yes', text2: 'No' }}
                    outline
                    buttonType={ButtonType.Danger}
                    buttonFunc={onDecisionAccount}
                  />
                </div>
              </div>
            </>
          }
        />
      </div> */}
      <Card
        body={
          <>
            <div className="card-header fs-6 fw-bold bg-transparent px-0">
              {t('profileSettings.legal_documents')}
            </div>
            <ul className="ps-1">
              <li
                className="listItem fw-bold py-1"
                onClick={() => handleModalOpen('terms')}
                style={{ cursor: 'pointer' }}
              >
                {t('profileSettings.terms_of_service')}
              </li>
              <li
                className="listItem fw-bold py-1"
                onClick={() => handleModalOpen('privacy')}
                style={{ cursor: 'pointer' }}
              >
                {t('profileSettings.privacy_policy')}
              </li>
              <li
                className="listItem fw-bold py-1"
                onClick={() => handleModalOpen('payment')}
                style={{ cursor: 'pointer' }}
              >
                {t('footer.payment_policy')}
              </li>{' '}
              <li
                className="listItem fw-bold py-1"
                onClick={() => handleModalOpen('membership')}
                style={{ cursor: 'pointer' }}
              >
                {t('footer.membership_policy')}
              </li>{' '}
              <li
                className="listItem fw-bold py-1"
                onClick={() => handleModalOpen('cookie')}
                style={{ cursor: 'pointer' }}
              >
                {t('footer.cookie_policy')}
              </li>
            </ul>
          </>
        }
      />
      <Modal
        isVisible={privacyModalVisible}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        content={<Privacy />}
        onClose={() => {
          setPrivacyModalVisible(false);
        }}
      />
      <Modal
        isVisible={termsModalVisible}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        content={<TermsOFUse />}
        onClose={() => {
          setTermsModalVisible(false);
        }}
      />
      <Modal
        isVisible={paymentModalVisible}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        content={<PaymentPolicy />}
        onClose={() => {
          setPaymentModalVisible(false);
        }}
      />{' '}
      <Modal
        isVisible={membershipModalVisible}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        content={<MembershipPolicy />}
        onClose={() => {
          setMembershipModalVisible(false);
        }}
      />{' '}
      <Modal
        isVisible={cookieModalVisible}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        content={<CookiePolicy />}
        onClose={() => {
          setCookieModalVisible(false);
        }}
      />
    </div>
  );
}
