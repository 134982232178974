import React, { useEffect, useState } from 'react';
import { InputType } from '../../utils/ComponentEnums';
import { useTranslation } from 'react-i18next';
import Actions from '../../store/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import regexValidator from '../../hooks/useRegexValidator';
import validationMessage from '../../hooks/useValidationMessage';
import InputLabelOnBorder from '../../components/Common/Input/InputLabelOnBorder';
import { formatPhone } from '../../hooks/useFormatPhone';

const BecomeAPartner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const becomeAPartner = useSelector((e) => e.becomeAPartner.becomeAPartner);
  const [createData, setCreateData] = useState(becomeAPartner);
  const [isRequired, setIsRequired] = useState({});
  const [handleErrorMessage, setHandleErrorMessage] = useState(null);
  const [error, setError] = useState({});
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(e.target.type, e.target.value);
    if (e.target.required && !validationResult && e.target.value === '') {
      setError({
        ...error,
        [e.target.id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    } else if (e.target.required && validationResult && e.target.value !== '') {
      setError({ ...error, [e.target.id]: undefined });
    }
    if (!validationResult && e.target.value !== '') {
      setError({
        ...error,
        [e.target.id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(e.target.type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [e.target.id]: undefined });
    }
    if (e.target.required & (e.target.value !== '') & validationResult) {
      setIsRequired({ ...isRequired, [id]: false });
    }
  };
  const onFocus = (e) => {
    e.preventDefault();
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
    if (e.target.required) {
      setIsRequired({ ...isRequired, [e.target.id]: e.target.required });
    }
    setHandleErrorMessage(null);
  };
  useEffect(() => {
    if (createData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  }, [createData?.phone, error?.phone]);
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.becomeAPartnerActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    if (
      (Object.values(isRequired).length !== 0) &
      Object.values(isRequired).every((e) => e === false)
    ) {
      await dispatch(Actions.becomeAPartnerActions.createAction(createData));
      setCreateData({});
    }
    setHandleErrorMessage(
      <span className="text-danger">
        {' '}
        {t('validationMessages.please_fill_the_required_inputs')}
      </span>
    );
  };
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col ">
          <form>
            <div className="col ">
              <div className="py-2">
                <InputLabelOnBorder
                  id={'fullName'}
                  inputType={InputType.Text}
                  labelText={t('common.name')}
                  inputValue={createData.fullName || ''}
                  onChange={onChangeText}
                  onBlur={onBlur}
                />
              </div>

              <div className="py-2">
                <InputLabelOnBorder
                  id={'email'}
                  inputType={InputType.Email}
                  labelText={t('companyPriceRequest.business_email')}
                  inputValue={createData.email || ''}
                  onChange={onChangeText}
                  onBlur={onBlur}
                  error={error?.email}
                />
              </div>

              <div className="py-2">
                <InputLabelOnBorder
                  id={'companyName'}
                  inputType={InputType.Text}
                  labelText={t('companyPriceRequest.company_name')}
                  inputValue={createData.companyName || ''}
                  onChange={onChangeText}
                  onBlur={onBlur}
                />
              </div>

              <div className="py-2">
                <InputLabelOnBorder
                  id={'phone'}
                  inputType={InputType.Tel}
                  labelText={t('common.phone')}
                  inputValue={formatPhone(createData?.phone) || ''}
                  onChange={onChangeText}
                  onBlur={onBlur}
                  error={error?.phone}
                  onFocus={onFocus}
                  placeholder={'(5xx) xxx xx xx'}
                />
              </div>

              <div className="py-2">
                <InputLabelOnBorder
                  id={'positionInCompany'}
                  inputType={InputType.Text}
                  labelText={t('companyPriceRequest.position_in_company')}
                  inputValue={createData.positionInCompany || ''}
                  onChange={onChangeText}
                  onBlur={onBlur}
                />
              </div>

              <div className="py-2">
                <InputLabelOnBorder
                  id={'message'}
                  inputType={InputType.Text}
                  labelText={t('companyPriceRequest.your_message')}
                  inputValue={createData.message || ''}
                  onChange={onChangeText}
                  onBlur={onBlur}
                  textArea
                />
              </div>

              <div className="py-2">
                <InputLabelOnBorder
                  id={'peopleRange'}
                  inputType={InputType.Number}
                  labelText={t('companyPriceRequest.number_of_employees')}
                  inputValue={createData.peopleRange || ''}
                  onChange={onChangeText}
                  onBlur={onBlur}
                />
              </div>

              <div>{handleErrorMessage}</div>
              <button
                onClick={onCreate}
                type="button"
                className="btn btn-success px-4 fs-6 mt-3"
              >
                {t('button.common_submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BecomeAPartner;
