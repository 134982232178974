import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(endPoint.career.CREATE_CAREER, createData);
};
export const update = async (id) => {
  return await putRequest(`${endPoint.career.UPDATE_CAREER}/${id}`);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.career.REMOVE_CAREER}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.career.GETBYID_CAREER}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.career.GETALL_CAREER);
};
