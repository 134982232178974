import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useEffect } from 'react';

export default function BasicPopUp({
  s,
  modify,
  isOpen,
  onClose,
  position,
  text,
  button,
  buttonFunc,
  buttonType,
  outline,
  textColor,
  buttonWidth,
}) {
  const [content, setContent] = useState('');
  const checkData = Object.keys(content).length === 1;

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setContent({ value });
  };

  const onFocus = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setContent({ value });
  };

  const onBlur = (e) => {
    e.preventDefault();
  };
  useEffect(() => {}, []);
  return (
    <>
      {isOpen ? (
        <div
          className={`popup bg-light rounded-2`}
          style={{
            zIndex: 50,
            minHeight: '4rem',
            position: 'absolute',
            top: position.top,
            left: position.left,
          }}
        >
          <div className="popup-content">
            <div className="container">
              <div className="ms=auto d-flex justify-content-end">
                <span role={'button'} onClick={onClose}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </div>
              <div className="row">
                <div className="col-12">
                  {modify ? (
                    <textarea
                      id="specialNote"
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onChange={onChangeText}
                      value={checkData ? content[0] : text}
                      onMouseLeave={() => s(Object.values(content)[0])}
                    ></textarea>
                  ) : text !== '' ? (
                    <span className={textColor} style={{ fontSize: '.8rem' }}>
                      {text}
                    </span>
                  ) : (
                    <textarea
                      id="specialNote"
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onChange={onChangeText}
                      value={checkData ? content[0] : text}
                      onMouseLeave={() => s(Object.values(content)[0])}
                    ></textarea>
                  )}
                </div>
              </div>

              {button !== undefined ? (
                <div className="row mt-3">
                  <div className="col-12 d-flex justify-content-around">
                    {text !== '' ? (
                      <button
                        onClick={buttonFunc}
                        id={button.text.toString()}
                        className={`btn ${
                          outline
                            ? 'btn-outline' + buttonType
                            : 'btn' + buttonType
                        } ${outline ? '' : 'text-light'} ${
                          buttonWidth
                            ? buttonWidth
                            : button.text2
                            ? 'w-25'
                            : 'w-75'
                        } btn-sm`}
                      >
                        {button.text}
                      </button>
                    ) : (
                      <button
                        onClick={buttonFunc}
                        id={button.text.toString()}
                        className={`btn ${
                          outline
                            ? 'btn-outline' + buttonType
                            : 'btn- bg-custom'
                        } ${outline ? '' : 'text-light'} ${
                          button.text2 ? 'w-25' : 'w-75'
                        } btn-sm`}
                      >
                        {'Save'}
                      </button>
                    )}
                    {button.text2 ? (
                      <button
                        id={button.text2.toString()}
                        onClick={buttonFunc}
                        className={`btn btn-primary ${
                          button.text2 ? 'w-25' : 'w-75'
                        } btn-sm`}
                      >
                        {button.text2}
                      </button>
                    ) : undefined}
                  </div>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}
