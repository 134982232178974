import {
  create,
  update,
  remove,
  getById,
  getAll,
} from '../../../services/apiServices/attendeeFreezeServices';
import ActionTypes from './actionTypes';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res.data.code === 200) {
        await dispatch(createReducer());
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.attendeeFreeze.CREATE_ACTION_ATTENDEEFREEZE };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      await dispatch(updateReducer());
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.attendeeFreeze.UPDATE_ACTION_ATTENDEEFREEZE };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.attendeeFreeze.GETBYID_ACTION_ATTENDEEFREEZE,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.attendeeFreeze.GETALL_ACTION_ATTENDEEFREEZE,
    payload: { data },
  };
};
const holdValue = (data) => {
  return {
    type: ActionTypes.attendeeFreeze.HOLD_VALUE_ATTENDEEFREEZE,
    payload: data,
  };
};

const attendeeFreezeActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  holdValue,
};
export default attendeeFreezeActions;
