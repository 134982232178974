import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Actions from '../../../store/redux/actions';
import { Loader } from '../../../components/Common/Loader/Loader';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { TabItem, Tabs } from '../../../components/Common/Tabs/Tabs';
import Card from '../../../components/Common/Card/Card';
import { TextColors } from '../../../utils/ComponentEnums';
import SendGiftSession from './SendGiftSession';
import EarnFreeSession from './EarnFreeSession';
import RedeemCode from './RedeemCode';
import useLoader from '../../../hooks/useLoader';

const GiftCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redeemCode = queryParams.get('redeemCode');
  const toEarnFreeSession = location.state?.where;
  const getBoard = useSelector((e) => e.onboard.onboard);
  const refCode = useSelector((e) => e.attendee.refCode);
  const [selectedTab, setSelectedTab] = useState(
    redeemCode !== null
      ? 3
      : toEarnFreeSession !== undefined
      ? toEarnFreeSession
      : 2
  );

  useEffect(() => {
    dispatch(Actions.attendeeActions.getRefCodeAction());
  }, []);

  return useLoader(getBoard) ? (
    <Loader />
  ) : (
    <div className="container-fluid">
      <Card
        body={
          <React.Fragment>
            <div className="d-sm-flex align-items-center justify-content-between">
              <HeadTags
                hSize={2}
                strong
                text={t('giftCode.header')}
                marginLeft={3}
              />
            </div>
            <div className="row">
              <Tabs
                defaultIndex={selectedTab.toString()}
                textColor={TextColors.Info}
              >
                <TabItem label={t('giftCode.earn_free_session_tab')} index="2">
                  <EarnFreeSession refCode={refCode} />
                </TabItem>
                <TabItem label={t('giftCode.send_gift_session_tab')} index="1">
                  <SendGiftSession />
                </TabItem>
                <TabItem label={t('giftCode.redeem_code_tab')} index="3">
                  <RedeemCode redeemCode={redeemCode} />
                </TabItem>
              </Tabs>
            </div>
          </React.Fragment>
        }
      />
    </div>
  );
};
export default GiftCode;
