import React, { useState } from 'react';

function MultiSelectDropdown({
  options,
  onSelectionChange,
  removeSelectedOption,
  selectedOptions,
  id,
  propNames,
  title,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    if (selectedOptions.includes(value)) {
      removeSelectedOption(value);
    } else {
      const newSelection = [...selectedOptions, value];
      onSelectionChange(newSelection);
    }
  };

  return (
    <div onMouseLeave={toggleDropdown}>
      <div>
        <button
          className="btn btn-default border border-2 rounded-4"
          onClick={toggleDropdown}
        >
          {title}
        </button>
      </div>

      {isOpen && (
        <div
          className="border border-2 mt-3"
          aria-labelledby={id}
          style={{ maxHeight: '200px', overflowY: 'scroll' }}
        >
          {options
            .reduce(
              (groups, option) => {
                const groupIndex = groups.findIndex(
                  (group) => group.title === option.group
                );
                if (groupIndex === -1) {
                  groups.push({ title: option.group, options: [option] });
                } else {
                  groups[groupIndex].options.push(option);
                }
                return groups;
              },
              [{ title: null, options: [] }]
            )
            .map((group) => (
              <div key={group.title}>
                {group.title && <h4 className="mt-2 ms-2">{group.title}</h4>}

                {propNames !== undefined
                  ? group.options.map((option, index) => (
                      <button
                        key={index}
                        title={
                          option[propNames.title]
                            ? option[propNames.title]
                            : option[propNames.name]
                        }
                        value={option[propNames.value]}
                        className={`btn btn-default border border-1 rounded-4 ${
                          selectedOptions.includes(option[propNames.value])
                            ? 'active bg-custom'
                            : ''
                        }`}
                        onClick={() =>
                          handleOptionClick(option[propNames.value])
                        }
                      >
                        {option[propNames.name]}
                      </button>
                    ))
                  : undefined}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default MultiSelectDropdown;
