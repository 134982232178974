import { getRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const preferenceGetById = async (id) => {
  return await getRequest(`${endPoint.preference.GETBYID_PREFERENCE}/${id}`);
};
export const preferenceGetAll = async (requestType = '') => {
  return await getRequest(
    `${endPoint.preference.GETALL_PREFERENCE}/${requestType}`
  );
};
export const getLevelGroup = async (userArea = 0) => {
  return await getRequest(
    `${endPoint.preference.GET_LEVEL_GROUP}?userArea=${userArea}`
  );
};
