import { setAreaDetails } from '../../../services/apiServices/authServices';
import {
  DayOfWeeks,
  Gender,
  KidsLangLevel,
  Language,
  LanguageLevelGroup,
  LearningPurpose,
  Native,
  NotificationCategory,
  NotificationType,
  OrderStatus,
  TypeOfService,
  UserArea,
  MeetingStatus,
  PaymentStatus,
  storageKeys,
  PaymentType,
  CurrencyUnit,
  MeetingAttendeeStatus,
} from '../../../utils/Enum';

const getToken = () => localStorage.getItem(storageKeys.token);
const getPath = () => sessionStorage.getItem(storageKeys.path);
const token = getToken();
const path = getPath();

let claims = [];
token
  ? (claims = JSON.parse(window?.atob(token?.split('.')[1]))[
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
  ])
  : (claims = '');
let id = '';
token
  ? (id = JSON.parse(window?.atob(token?.split('.')[1]))[
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
  ])
  : (id = '');
let version = '';
token
  ? (version = JSON.parse(window?.atob(token?.split('.')[1]))['website'])
  : (version = '');
let area = 'Online';
token
  ? (area = JSON.parse(window?.atob(token?.split('.')[1]))['typ'])
  : (area = 'Online');

const userArea = sessionStorage.getItem(storageKeys.userArea) ?? setAreaDetails(area).myBoard;
sessionStorage.setItem(storageKeys.userArea, userArea);

let enumValue;
let oboardPath;
switch (userArea) {
  case 'Online':
  case 'Çevrimiçi':
    enumValue = 0;
    oboardPath = '/online';
    break;
  case 'Cafe':
  case 'Kafe':
    enumValue = 1;
    oboardPath = '/cafe';
    break;
  case 'Kids':
  case 'Çocuklar':
    enumValue = 2;
    oboardPath = '/kids';
    break;
  case 'Company':
  case 'Şirket':
    enumValue = 3;
    oboardPath = '/company';
    break;
  default:
    enumValue = 4;
    oboardPath = '';
    break;
}

const storedCurrency =
  sessionStorage.getItem(storageKeys.currency) !== null &&
    sessionStorage.getItem(storageKeys.currency) !== 'undefined'
    ? JSON.parse(sessionStorage.getItem(storageKeys.currency))
    : { code: 949, name: 'TL', symbol: '₺' };

export const initialState = {
  auth: {
    auth: token || '',
    showLoading: false,
    claim: claims || [],
    currentUser: id,
    validationMessage: '',
    isSuccess: '',
    oAuth: '',
    version: version || '',
    path: path || '',
  },
  language: {
    language: localStorage.getItem(storageKeys.languge),
    flag: localStorage.getItem(storageKeys.languge),
  },
  screensize: {
    size: window.innerWidth < 1024 ? true : false,
    setted: true,
  },
  career: {
    career: {},
    getById: [],
    getAll: [],
    validationMessage: '',
    isSuccess: '',
  },
  companyPriceRequest: {
    companyPriceRequest: { reviewed: false },
    getById: [],
    getAll: [],
    validationMessage: '',
    isSuccess: '',
  },
  faqGroup: {
    faqGroup: {},
    getById: [],
    getAll: [],
  },
  faq: {
    faq: {},
    getById: [],
    getAll: [],
  },
  user: {
    user: {},
    getById: [],
    getAll: [],
    userPreferences: [],
    isSuccess: '',
    validationMessage: '',
    selfUpdate: '',
  },
  staticDatas: {
    LearningPurpose: LearningPurpose,
    SessionLevel: [],
    TypeOfService: TypeOfService,
    Countries: [],
    KidsLangLevel: KidsLangLevel,
    Language: Language,
    CountryCode: [],
    CurrencyUnit: CurrencyUnit,
    DayOfWeeks: DayOfWeeks,
    LanguageLevels: LanguageLevelGroup,
    MeetingLanguageLevels: [],
    PaymentStatus: PaymentStatus,
    PaymentType: PaymentType,
    UserArea: UserArea,
    Native: Native,
    Gender: Gender,
    NotificationType: NotificationType,
    NotificationCategory: NotificationCategory,
    OrderStatus: OrderStatus,
    MeetingStatus: MeetingStatus,
    MeetingAttendeeStatus: MeetingAttendeeStatus,
  },
  speaker: {
    speaker: {
      isFullTimer: false,
      detailInfo: { Education: '', Profession: '', Certificates: '' },
    },
    getById: [],
    getAll: [],
    filteredSpeakers: [],
    pagination: {
      TotalCount: '',
      PageSize: '',
      CurrentPage: '',
      TotalPages: '',
      HasNext: '',
      HasPrevious: '',
    },
    validationMessage: '',
    isSuccess: '',
    speakerPayment: {},
  },

  zone: {
    zone: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  test_price_studies: {
    zone: {
      zone: {},
      getById: [],
      getAll: [],
    },
  },
  onboard: {
    onboard: userArea,
    enum: enumValue,
    path: oboardPath,
  },
  couponCode: {
    couponCode: {},
    getByName: {},
    getById: [],
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  timesPerWeek: {
    timesPerWeek: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  sessionPackage: {
    sessionPackage: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  attendee: {
    attendee: {},
    getById: [],
    getAll: [],
    filteredAttendee: [],
    freeTrialNote: {},
    refCode: '',
    isSuccess: '',
    validationMessage: '',
  },
  attendeeFreeze: {
    attendeeFreeze: {},
    getById: [],
    getAll: [],
  },
  branchFreeze: {
    branchFreeze: {},
    getById: [],
    getAll: [],
    validationMessage: '',
    isSuccess: '',
  },
  manager: {
    manager: {},
    getById: [],
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  location: {
    location: {},
    getById: [],
    getAll: [],
    branches: [],
    isSuccess: '',
    validationMessage: '',
  },
  ageInterval: {
    ageInterval: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  timesPerMonth: {
    timesPerMonth: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  sessionCategory: {
    sessionCategory: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  meetingSchema: {
    meetingSchema: {},
    getById: [],
    getAll: [],
    getAllSchedule: [],
    getAllSesionCount: [],
    isSuccess: '',
    validationMessage: '',
    dropdownList: [],
    getAllToday: [],
    getSpeakerSchedule: [],
    getMeetingAttendees: [],
    attendanceCheck: [],
  },
  order: {
    order: {},
    calendarTable: [],
    getById: [],
    getAll: [],
    eftList: [],
    mySubsPeriod: [],
    orderHistory: [],
    activeOrders: [],
    basketItems: [],
    isEmpty: '',
    earnedFreeSessions: [],
    giftSessions: [],
    getMeetingsByOrder: [],
    payments: [],
    trialList: [],
    renewOrderItem: [],
    calculationRemainingSessionFee: [],
    attendeeOrders: [],
    isSuccess: '',
    validationMessage: '',
    showLoading: false,
  },
  becomeAPartner: {
    becomeAPartner: {},
    getById: [],
    getAll: [],
  },
  banner: {
    banner: { zones: '1' },
    getById: {},
    getAll: [],
    getActiveBannerByZone: {},
    validationMessage: '',
    isSuccess: '',
  },
  userPackage: {
    packageData: {},
    isPackageComplete: false,
  },
  calendar: {
    getAllFiltered: [],
    getBySpeakerId: [],
    getAll: [],
    getCalendarSpeakers: [],
    getCellDatas: [],
    getMeetings: [],
    isSuccess: '',
    validationMessage: '',
  },
  speakerAvailability: {
    speakerAvailability: {},
    getAll: [],
    isSuccess: '',
    validationMessage: '',
    getSelectedAvaibilities: [],
  },
  classroom: {
    classroomMessage: {},
    getPrivateRoomMembers: [],
    getPrivateMessages: [],
    getGroupMessages: [],
    getGroupRoomMembers: [],
    isSuccess: '',
    validationMessage: '',
  },

  preferenceOptions: {
    isSuccess: '',
    currenyOptions: [],
    preferenceCurrencyUnit: token === null ? storedCurrency : {},
    preferenceLevelGroup: JSON.parse(
      sessionStorage.getItem(storageKeys.levelGroup)
    ) ?? {
      adult: 'Quintet',
      kids: 'KidsLangLevel',
    },
  },
  subscription: {
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  userOperationClaim: {
    isSuccess: '',
    validationMessage: '',
  },
  payment: {
    installment: {},
    callBackContent: '',
    resultMessage: '',
    isSuccess: '',
    validationMessage: '',
  },
  meetingAttendee: { isSuccess: '', validationMessage: '' },
  filter: {
    filter: {},
    createData: [],
    forBadge: [],
  },
  feedback: {
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  speakerEffort: {
    getAll: [],
    getDetail: [],
    isSuccess: '',
    validationMessage: '',
  },
  lead: {
    getById: [],
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  price: {
    calculatedPrices: [],
    isSuccess: '',
    validationMessage: '',
  },
  company: {
    company: {},
    getById: [],
    getAll: [],
    getCompanyAttendeesDropdown: [],
    getCompanyAttendees: [],
    getCompanyManagers: [],
    companyOrders: {},
    isSuccess: '',
    validationMessage: '',
  },
  campaign: {
    campaign: {
      zones: '1', campaignImages: [{
        id: 1,
        imageName: '',
        imageSrc: '',
        imageExtension: '',
      },]
    },
    getById: {},
    getAll: [],
    validationMessage: '',
    isSuccess: '',
  },
};
