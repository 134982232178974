import { storageKeys } from '../utils/Enum';
import { attendeeMarketingSourceCreate } from '../services/apiServices/attendeeMarketingSourceServices';

const utmDataSender = (data, formActions) => {
  const utmData = JSON.parse(sessionStorage.getItem(storageKeys.utmData));
  if (formActions.value === 0) {
    utmData.attendeeId = data.attendeeId;
    utmData.name =
      data.firstName === '' ? '-' : `${data.firstName} ${data.lastName}`;
    utmData.email = data.email;
    utmData.formName = formActions.key;
    utmData.formValue = formActions.value;
  } else if (formActions.value === 1) {
    utmData.name = data.name;
    utmData.email = data.email;
    utmData.phone = data.phone;
    utmData.location = data.location;
    utmData.formName = formActions.key;
    utmData.formValue = formActions.value;
  } else if (formActions.value === 4) {
    utmData.name = data.name;
    utmData.email = data.email;
    utmData.phone = data.phone;
    utmData.languageLevel = data.languageLevel;
    utmData.learningPurpose = data.learningPurpose;
    utmData.location = data.location;
    utmData.ageInterval = data.ageInterval;
    utmData.formName = formActions.key;
    utmData.formValue = formActions.value;
  } else {
    utmData.attendeeId = null;
    utmData.name = null;
    utmData.email = null;
    utmData.phone = null;
    utmData.languageLevel = null;
    utmData.learningPurpose = null;
    utmData.location = null;
    utmData.ageInterval = null;
    utmData.formName = formActions.key;
    utmData.formValue = formActions.value;
    if (formActions.value === 2) {
      utmData.orderItemId = data;
    } else {
      utmData.orderId = data;
    }
  }

  attendeeMarketingSourceCreate(utmData);
};

export default utmDataSender;
