import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  ButtonSize,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Input from '../../../components/Common/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import Actions from '../../../store/redux/actions';
import { Loader } from '../../../components/Common/Loader/Loader';
import useLoader from '../../../hooks/useLoader';
import { ToastContainer, toast } from 'react-toastify';

export default function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((e) => e.user.getById);
  const isSuccess = useSelector((e) => e.user.isSuccess);
  const [passwordData, setPasswordData] = useState({});
  const [isRequired, setIsRequired] = useState({});
  const validationMessages = useSelector((e) => e.user.validationMessage);
  const [handleErrorMessage, setHandleErrorMessage] =
    useState(validationMessages);
  const [error, setError] = useState({});
  const [matchErr, setMatchErr] = useState(null);
  const [isforgotOpen, setIsForgotOpen] = useState(false);
  const [notifyMessage, setNotifyMesage] = useState('');
  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.userActions.cleanState(true));
      setNotifyMesage('');
      setIsForgotOpen(false);
      setMatchErr(null);
      setError({});
      setPasswordData({});
    };
  }, []);

  useEffect(() => {
    setHandleErrorMessage(validationMessages);
  }, [validationMessages]);

  useEffect(() => {
    if (notifyMessage !== '' && isSuccess && !isforgotOpen) {
      toast.info(notifyMessage, {
        onClose: () => {
          setNotifyMesage('');
          dispatch(Actions.authActions.logoutAction());
        },
      });
    }
    if (notifyMessage !== '' && isSuccess && isforgotOpen) {
      toast.warning(notifyMessage, {
        onClose: () => {
          setNotifyMesage('');
          dispatch(Actions.authActions.logoutAction());
          setIsForgotOpen(false);
        },
      });
    }
  }, [notifyMessage]);

  useEffect(() => {
    if (isSuccess && !isforgotOpen && Object.keys(passwordData).length > 0) {
      const message = (
        <span className="d-flex justify-content-center fw-bold">
          {t('changePassword.reset_success')}
          <br />
          {t('changePassword.log_out_soon')}
        </span>
      );
      setNotifyMesage(message);
    }
    if (isSuccess && isforgotOpen) {
      const message = (
        <span className="d-flex justify-content-center fw-bold">
          {t('changePassword.reset_info')}
          <br />
          {t('changePassword.log_out_soon')}
        </span>
      );
      setNotifyMesage(message);
    }
  }, [isSuccess, isforgotOpen]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setPasswordData({ ...passwordData, [id]: value });
    const validationResult = regexValidator(e.target.type, e.target.value);
    if (e.target.required & !validationResult & (e.target.value === '')) {
      setError({
        ...error,
        [e.target.id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    } else {
      setError({ ...error, [e.target.id]: undefined });
    }
    if (!validationResult & (e.target.value !== '')) {
      setError({
        ...error,
        [e.target.id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(e.target.type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [e.target.id]: undefined });
    }
    if (e.target.required & (e.target.value !== '') & validationResult) {
      setIsRequired({ ...isRequired, [id]: false });
    }
  };
  const onBlur = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value === '') {
      const { [id]: deletedValue, ...updatedCreateData } = passwordData;
      setPasswordData(updatedCreateData);
    }
    (passwordData.newPassword !== passwordData.confirmPassword) &
    (passwordData.confirmPassword !== '') &
    (error.confirmPassword === undefined)
      ? setMatchErr(
          <span className="text-danger">
            {' '}
            {t('validationMessages.passwords_must_match')}
          </span>
        )
      : setMatchErr(null);
  };
  const onFocus = (e) => {
    e.preventDefault();
    if (e.target.required) {
      setIsRequired({ ...isRequired, [e.target.id]: e.target.required });
    }
    setHandleErrorMessage(null);
  };
  const onChangePassword = async (e) => {
    e.preventDefault();
    if (
      Object.values(isRequired).length !== 0 &&
      Object.values(isRequired).every((e) => e === false) &&
      passwordData.newPassword === passwordData.confirmPassword
    ) {
      await dispatch(Actions.userActions.changePasswordAction(passwordData));
    }
    if (
      Object.values(isRequired).length !== 0 &&
      Object.values(isRequired).every((e) => e !== false)
    ) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    }
    if (
      Object.values(isRequired).length === 0 &&
      Object.keys(passwordData).length === 0
    ) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    }
  };

  const onForgotMyPassword = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.userActions.forgotPasswordAction({
        email: userInfo.email,
      })
    );
    setIsForgotOpen(true);
  };

  const content = (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('headerMyProfile.change_password')}
                  />
                </div>
                <div className="row">
                  <div className="col-12 justify-content-center">
                    <Input
                      id={'oldPassword'}
                      className={
                        'form-control mt-2 border border-secodary border-3'
                      }
                      placeholder={t('changePassword.current_password')}
                      type={InputType.Password}
                      style={{ background: '#FAF7F7' }}
                      value={passwordData.oldPassword || ''}
                      error={error.oldPassword}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-6 mt-2 justify-content-center">
                    <Input
                      id={'newPassword'}
                      className={
                        'form-control mt-2 border border-secodary border-3'
                      }
                      placeholder={t('changePassword.new_password')}
                      type={InputType.Password}
                      style={{ background: '#FAF7F7' }}
                      value={passwordData.newPassword || ''}
                      error={error.newPassword}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-6 mt-2 justify-content-center">
                    <Input
                      id={'confirmPassword'}
                      className={
                        'form-control mt-2 border border-secodary border-3'
                      }
                      placeholder={t('changePassword.re_enter_new_password')}
                      type={InputType.Password}
                      style={{ background: '#FAF7F7' }}
                      value={passwordData.confirmPassword || ''}
                      error={error.confirmPassword}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                  </div>
                  {handleErrorMessage !== '' && (
                    <div className="text-center mt-1">
                      <span className="text-danger">{handleErrorMessage}</span>
                    </div>
                  )}
                  <div className="text-center mt-1">
                    {passwordData.newPassword !== '' ? matchErr : undefined}
                  </div>
                  <div className="col-md-4 mt-3">
                    <Button
                      type={ButtonType.Primary}
                      text={t('changePassword.change_password')}
                      textColor={TextColors.Light}
                      buttonCol={12}
                      onClick={onChangePassword}
                    />
                  </div>
                  {notifyMessage !== '' && (
                    <ToastContainer
                      style={customToastStyle}
                      limit={1}
                      autoClose={2000}
                      closeOnClick
                      theme="light"
                    />
                  )}
                </div>
              </>
            }
          />
        </div>
        <div className="col-lg-4">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('changePassword.reset_my_password')}
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    <span
                      className="text-muted ms-5"
                      style={{ fontSize: '.8rem' }}
                    >
                      {t('changePassword.if_you_forgot_your_password')}
                    </span>
                  </div>
                  <div className="col mt-3">
                    <Button
                      text={t('changePassword.reset_my_password')}
                      type={ButtonType.Danger}
                      buttonCol={12}
                      size={ButtonSize.Small}
                      outline
                      bold
                      onClick={onForgotMyPassword}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
  return useLoader() ? <Loader /> : content;
}
