import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment-timezone";
import Actions from "../../../../store/redux/actions";
import Button from "../../../../components/Common/Button/Button";
import {
	ButtonType,
	ButtonSize,
	InputType,
	InputSize,
	LabelTextType
} from "../../../../utils/ComponentEnums";
import Card from "../../../../components/Common/Card/Card";
import Input from "../../../../components/Common/Input/Input";

const BackOfficeAttendeeFreezeCreate = () => {
	const { t } = useTranslation();
	const attendeeFreeze = useSelector((e) => e.attendeeFreeze.attendeeFreeze)
	const dispatch = useDispatch();
	const [createData, setCreateData] = useState(attendeeFreeze);

	const onChangeText = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		setCreateData({ ...createData, [id]: value });
	};
	const onBlur = (e) => {
		e.preventDefault();
		dispatch(Actions.attendeeFreezeActions.holdValue({ ...createData }))
	};
	const onCreate = async (e) => {
		e.preventDefault();
		await dispatch(Actions.attendeeFreezeActions.createAction(createData));
	};

	const [startDate, setStartDate] = useState(new Date());
	const [valid, setValid] = useState(true);

	const handleChangeRaw = (value) => {
		if (value !== undefined) {
			if (moment(value, "MM/DD/YYYY", true).isValid()) {
				setValid(true);
			} else {
				setValid(false);
				setStartDate(null);
			}
		}
	};

	return (
		<div className="container-fluid">
			<div className="d-sm-flex align-items-center justify-content-between mb-4">
				<h1 className="h3 mb-0 text-gray-800">
					Attendee Freeze Create
				</h1>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<Card
						header={"Attendee Freeze Create"}
						body={
							<div>
								<div className="row">
									<div className="col-3 fw-bold">Attendee Name</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="attendee-name"
												id="text"
											/>
										</form>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-3 fw-bold">Start Date</div>
									<div className="col-8">
										<Input
											type={InputType.Date}
											className={InputSize.Medium}
											id={"startDate"}
											onChange={(date) => setStartDate(date)}
											inputValue={(event) =>
												handleChangeRaw(event.target.value)}
											labelValue={"Start Date"}
											labelFontType={LabelTextType.Bold}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-3 fw-bold">End Date</div>
									<div className="col-8">
										<Input
											type={InputType.Date}
											className={InputSize.Medium}
											id={"endDate"}
											onChange={(date) => setStartDate(date)}
											inputValue={(event) =>
												handleChangeRaw(event.target.value)}
											labelValue={"End Date"}
											labelFontType={LabelTextType.Bold}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-3 fw-bold">Reason</div>
									<div className="col-8">
										<form>
											<input
												className="form-control"
												type="attendee-name"
												id="text"
											/>
										</form>
									</div>
								</div>
								<hr className="mt-4" />
								<div className="row mt-4">
									<div className="col-3">
										<Button
											type={ButtonType.Success}
											size={ButtonSize.Small}
											text={t("BackOfficeCreateSpeaker.232")}
											onClick={onCreate}
										/>
									</div>
								</div>
							</div>
						}
					></Card>
				</div>
			</div>
		</div>
	);
};
export default BackOfficeAttendeeFreezeCreate;
