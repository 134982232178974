import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const classroomReducer = (state = initialState.classroom, action) => {
  switch (action.type) {
    case ActionTypes.classroom.COMMIT_MESSAGE_ACTION_CLASSROOM:
      return {
        ...state,
        classroomMessage: initialState.campaign.campaign,
      };

    case ActionTypes.classroom.GET_PRIVATE_MEMBERS_ACTION_CLASSROOM:
      return {
        ...state,
        getPrivateRoomMembers: action.payload.data,
      };
    case ActionTypes.classroom.GET_PRIVATE_MESSAGE_ACTION_CLASSROOM:
      return {
        ...state,
        getPrivateMessages: action.payload.data,
      };
    case ActionTypes.classroom.GET_GROUP_MESSAGE_ACTION_CLASSROOM:
      return {
        ...state,
        getGroupMessages: action.payload.data,
      };
    case ActionTypes.classroom.GET_GROUP_MEMBERS_ACTION_CLASSROOM:
      return {
        ...state,
        getGroupRoomMembers: action.payload.data,
      };
    case ActionTypes.classroom.HandleErrorClassroom:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.classroom.HandleValidationClassroom:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default classroomReducer;
