import utmDataSender from '../../../hooks/useUtmDataSender';
import {
  create,
  getAll,
  remove,
} from '../../../services/apiServices/subscriptionServices';
import { formActions, storageKeys } from '../../../utils/Enum';
import ActionTypes from './actionTypes';

const createAction = (createData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await create(createData).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        const storageKey = sessionStorage.getItem(storageKeys.utmData);
        if (
          storageKey !== null &&
          storageKey !== '' &&
          storageKey !== undefined
        ) {
          utmDataSender(createData, formActions.letUsCall);
        }
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const removeAction = (id) => {
  return async (dispatch) => {
    await remove(id).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.subscription.GET_ALL_SUBSCRIPTION, payload: data };
};

const handleError = (data) => {
  return {
    type: ActionTypes.subscription.HANDLE_ERROR_SUBSCRIPTION,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.subscription.HANDLE_VALIDATION_SUBSCRIPTION,
    payload: data,
  };
};

const cleanState = (validation = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};

const subscriptionActions = {
  createAction,
  getAllAction,
  removeAction,
  cleanState,
};
export default subscriptionActions;
