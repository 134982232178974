import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Card from '../../../components/Common/Card/Card';
import Button from '../../../components/Common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import ProgressLine from '../../../components/Common/ProgressLine/ProgressLine';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import BasicTable from '../../../components/Common/Table/BasicTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Actions from '../../../store/redux/actions';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { Loader } from '../../../components/Common/Loader/Loader';
import Modal from '../../../components/Common/Modal/Modal';
import { ModalTitles, storageKeys, storageValues } from '../../../utils/Enum';
import findMyPath from '../../../hooks/useFindMyPath';
import PhoneConfirmation from '../AttendeePages/PhoneConfirmation';
import useGTM from '../../../hooks/useGTM';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import Toast from '../../../components/Common/Popup/Toast';

export default function OrderBasket() {
  const phoneConfirmationModal = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.order.basketItems);
  const goBack = () => {
    const previousPath = window.history.state?.usr?.previousPath ?? -1;
    sessionStorage.setItem(
      storageKeys.currentMenu,
      storageValues.currentMenu.schedule
    );

    navigate(previousPath, {
      state: { menu: storageValues.currentMenu.schedule },
    });
  };
  const userSuccess = useSelector((e) => e.user.isSuccess);
  const [selectedSubsLength, setSelectedSubsLength] = useState({});
  const [promoCode, setPromoCode] = useState({});
  const [arrow, setArrow] = useState({});
  const [isOpen, setIsOpen] = useState({ isOpen: false });
  const currency = useSelector(
    (e) => e.preferenceOptions.preferenceCurrencyUnit
  );
  const userInfo = useSelector((e) => e.user.getById);
  const currencyCode = useSelector((e) => e.user.getById?.currency?.code);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { sendEventToDataLayer, generateItemId } = useGTM();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const affiliation = queryParams?.get('utm_source');
  const userLevel = userInfo?.languageLevel;
  const sessionPackage = useSelector((e) => e.sessionPackage.getAll);
  const ageInterval = useSelector((e) => e.ageInterval.getAll);
  const learningPurposes = useSelector((e) => e.staticData.LearningPurpose);
  const [freeTrialData, setFreeTrialData] = useState({});
  useEffect(() => {
    if (sessionPackage.length === 0) {
      dispatch(Actions.sessionPackageActions.getAllAction());
    }
    if (
      basket?.unpayedOrderItemList?.length > 0 ||
      basket?.unpayedOrderItemList === undefined
    ) {
      dispatch(Actions.userPackageActions.completeAction());
    }
    dispatch(Actions.ageIntervalActions.getAllAction(2));
  }, []);

  const onSelectSubsLenght = (e) => {
    e.preventDefault();
    let pp = e.currentTarget.id.split('_')[0];
    updateOrderItem(e.currentTarget.title, pp);
    if (
      Object.keys(selectedSubsLength).find(
        (element) => element.split('_')[0] === pp
      )
    ) {
      const newItems = { ...selectedSubsLength };
      delete newItems[
        Object.keys(selectedSubsLength).find(
          (element) => element.split('_')[0] === pp
        )
      ];
      setSelectedSubsLength({
        ...newItems,
        [e.currentTarget.id]: 'border-5',
        ['id' + pp]: e.currentTarget.id.substring(0, 1),
      });
    } else {
      setSelectedSubsLength({
        ...selectedSubsLength,
        [e.currentTarget.id]: 'border-5',
        ['id' + pp]: e.currentTarget.id.substring(0, 1),
      });
    }
  };
  async function updateOrderItem(title, id) {
    const values = title.split('_');
    const obj = {
      // netAmount: parseFloat(values[0]),
      // totalAmount: parseFloat(values[1]),
      monthOptions: parseInt(values[2]),
    };
    await dispatch(Actions.orderActions.updateAction(obj, id));
    handleBasket();
    setSelectedIndex(obj.monthOptions - 1);

    setPromoCode({
      ...promoCode,
      trialStatus:
        basket?.unpayedOrderItemList[0]?.scheduleUserSelection?.trialStatus,
    });
  }

  useEffect(() => {
    if (
      basket?.unpayedOrderItemList?.length > 0 &&
      userInfo?.attendeeId !== undefined &&
      basket?.unpayedOrderItemList?.[0]?.orderItemDescription === 'Free Trial'
    ) {
      const myData = {
        AttendeeId: userInfo?.attendeeId,
        UserArea: userInfo?.userArea,
        OrderItemId: basket?.unpayedOrderItemList?.[0]?.id,
        SelectionOptions:
          basket?.unpayedOrderItemList?.[0]?.scheduleUserSelection
            ?.selectionOptions,
      };
      setFreeTrialData(myData);
    }
  }, [basket, userInfo]);

  const onBuy = (e) => {
    e.preventDefault();
    if (auth !== '' && !userHasPhone) {
      phoneConfirmationModal.current.open();
    } else if (
      userInfo?.phoneIsVerified &&
      basket?.unpayedOrderItemList?.length > 0 &&
      basket?.unpayedOrderItemList?.[0]?.trialStatus === 'FreeTrial' &&
      freeTrialData?.AttendeeId !== undefined
    ) {
      if (!freeTrialDone) {
        dispatch(Actions.orderActions.orderSetDateAction(freeTrialData));
      }
      navigate('/congratulations', {
        state: { freeTrialData, createPackage: true, isFreeTrial: true },
      });
    } else {
      navigate(findMyPath('order/paymentdetail'));
    }
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setPromoCode({ ...promoCode, couponCode: value });
  };

  const onClickPromo = async (e) => {
    e?.preventDefault();
    await dispatch(Actions.couponCodesActions.getByNameAction(promoCode));
    handleBasket();
  };
  const handleBasket = () => {
    dispatch(Actions.orderActions.getBasketItemsAction());
  };

  const handleClick = (e) => {
    e.preventDefault();
    arrow[e.currentTarget.id] === true
      ? setArrow({ ...arrow, [e.currentTarget.id]: false })
      : setArrow({ ...arrow, [e.currentTarget.id]: true });
  };

  const handleTrashCan = async (id) => {
    await dispatch(Actions.orderActions.removeAction(id));
    setIsOpen({ isOpen: false });
    handleBasket();
  };

  useEffect(() => {
    if (userSuccess) {
      dispatch(Actions.orderActions.getBasketItemsAction());
    }
  }, [userSuccess]);

  useEffect(() => {
    const newState = {};
    basket?.unpayedOrderItemList?.forEach((item, index) => {
      const selectedNetAmount = item?.options?.monthOptions;
      const selectedIndex = item?.subsType?.totalCostItems?.findIndex(
        (costItem) => costItem.monthId === selectedNetAmount
      );

      setSelectedIndex(selectedIndex);
      if (selectedNetAmount !== undefined && selectedIndex !== -1) {
        newState[`id${item.id}`] = index;
        newState[`${item.id}_${selectedIndex}`] = 'border-5';
      }
    });
    if (
      basket &&
      basket.unpayedOrderItemList &&
      basket.unpayedOrderItemList.length > 0
    ) {
      setPromoCode({
        ...promoCode,

        trialStatus:
          basket?.unpayedOrderItemList[0]?.scheduleUserSelection?.trialStatus,
      });
    }
    setSelectedSubsLength(newState);
  }, [basket]);

  //TODO ay değişimi yapıldığında isteğin tekrar atılması
  // useEffect(() => {
  //   const fetchCouponCode = async () => {
  //     if (couponSuccessCheck && promoCode.couponCode !== '') {
  //       await dispatch(Actions.couponCodesActions.getByNameAction(promoCode));
  //     }
  //   };

  //   fetchCouponCode();
  // }, [selectedIndex]);

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'typeofSession', label: t('orderBasket.table_typeofSession') },
    { key: 'order', label: t('orderBasket.table_order') },
    { key: 'netAmount', label: t('orderBasket.table_netAmount') },
    { key: 'undated', label: t('orderBasket.table_undated') },
  ];

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    if (basket?.length !== 0) {
      setShowLoader(false);
    } else
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);

    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, [basket]);

  const userPackage = useSelector((e) => e.userPackage);
  const checkHasItems = userPackage.isPackageComplete;
  const userHasPhone = useSelector((e) => e.user?.getById?.phoneIsVerified);
  const auth = useSelector((e) => e.auth.auth);
  const [phoneConfirmationInfo, setPhoneConfirmationInfo] = useState('');
  const [freeTrialDone, setFreeTrialDone] = useState(false);

  useEffect(() => {
    if (
      userHasPhone !== undefined &&
      phoneConfirmationModal.current !== undefined &&
      phoneConfirmationModal.current?.check() === false
    ) {
      if (!userHasPhone && auth !== '' && checkHasItems) {
        phoneConfirmationModal.current.open();
      }
    }
  }, [phoneConfirmationModal.current]);

  useEffect(() => {
    if (
      userInfo?.phoneIsVerified &&
      basket?.unpayedOrderItemList?.length > 0 &&
      basket?.unpayedOrderItemList?.[0]?.trialStatus === 'FreeTrial' &&
      freeTrialData?.AttendeeId !== undefined &&
      !freeTrialDone
    ) {
      if (userInfo?.phoneNumberAvaibleForFreeTrial) {
        setFreeTrialDone(true);
        dispatch(Actions.orderActions.orderSetDateAction(freeTrialData));
      }
    }
  }, [userInfo, phoneConfirmationInfo, freeTrialData, basket]);

  useEffect(() => {
    if (userHasPhone && phoneConfirmationInfo === '' && freeTrialDone) {
      dispatch(Actions.orderActions.getBasketItemsReducer([], true));
      navigate('/congratulations', {
        state: { freeTrialData, createPackage: true, isFreeTrial: true },
      });
    }
  }, [phoneConfirmationInfo]);

  function findNameByAgeInterval(value) {
    const item = ageInterval.find((item) => item.id === value);
    return item ? `${item.minAge} - ${item.maxAge}` : null;
  }
  function matchLearningPurposes(learningPurposesString) {
    const learningPurposesArray = learningPurposesString?.split(',');

    const matchedKeys = learningPurposes
      ?.filter((item) => learningPurposesArray?.includes(item.value))
      ?.map((item) => t(item.key));

    return matchedKeys;
  }

  useEffect(() => {
    if (
      basket !== undefined &&
      basket !== '' &&
      basket?.unpayedOrderItemList !== undefined
    ) {
      const basketItems = basket?.unpayedOrderItemList;

      let userArea, productType, timesPerWeek, duration;
      let totalAmount = basketItems?.reduce(
        (sum, item) => sum + item.netAmount,
        0
      );
      const itemsArray = basketItems.map((item, index) => {
        userArea = item.userArea;
        productType = item.productType;
        timesPerWeek = item.options.timesPerWeek ?? 1;
        duration = sessionPackage?.find(
          (a) => a.id === item.options.sessionPackage
        )?.minute;

        const totalCostItem = item?.subsType?.totalCostItems?.find(
          (costItem) => costItem.rowIndex === item.options.sessionPackage
        );

        return {
          item_id: generateItemId(
            item.options.location,
            userInfo.userArea,
            item.options.sessionPackage,
            timesPerWeek,
            item.options.monthOptions,
            1
          ),
          item_name: item.orderItemDescription,
          affiliation: affiliation ?? '',
          coupon: totalCostItem?.couponCode ?? '',
          index: index,
          age_interval: findNameByAgeInterval(item.ageInterval),
          learning_purposes: matchLearningPurposes(
            item.scheduleUserSelection.learningPurposes
          ),

          item_brand: 'Meet2Talk',
          price: item.netAmount,
          session_type: productType,
          days: timesPerWeek,
          duration: duration,
          quantity: 1,
        };
      });

      sendEventToDataLayer({
        event: 'add_to_cart',
        ecommerce: {
          currency: currency.name === 'TL' ? 'TRY' : currency.name,
          value: totalAmount,
          attendee: userArea,
          language_level: t(
            userLevel ?? sessionStorage.getItem(storageKeys.userLevel),
            { lng: 'en' }
          ),
          items: itemsArray,
        },
      });
    }
  }, [basket.orderId]);

  const trialStatusCondition =
    basket &&
    basket.unpayedOrderItemList &&
    basket.unpayedOrderItemList.length > 0 &&
    basket.unpayedOrderItemList[0]?.trialStatus === 'FreeTrial';

  const trialStatus = trialStatusCondition ? 0 : null;

  const content = (
    <div className="container-fluid  mb-5 pb-5">
      <div className="d-flex container px-md-5 py-3 align-items-center  justify-content-evenly">
        <ProgressLine
          marginLeft={0}
          text={t('progressLine.selection')}
          textMarginLeft={0}
          icon={ButtonIcon.CheckCircle}
          iconColor={TextColors.Custom}
        />
        <ProgressLine
          marginLeft={0}
          text={t('progressLine.summarize')}
          textMarginLeft={0}
          icon={ButtonIcon.CheckCircle}
          iconColor={TextColors.Success}
        />
        <ProgressLine
          marginLeft={0}
          text={t('progressLine.pay')}
          textMarginLeft={0}
          lastItem
          icon={ButtonIcon.CheckCircle}
          iconColor={TextColors.Secondary}
        />
      </div>

      <div className="d-inline-block  py-2 ">
        <Button
          type={ButtonType.Successbg}
          icon={ButtonIcon.ArrowLeft}
          iconColor={TextColors.Light}
          bold
          onClick={goBack}
        />
      </div>

      <div className="d-flex">
        <div className="col-12">
          <Card
            cardRounded
            body={
              <>
                {basket?.length === 0 ? (
                  <div className="h1 text-center my-5 ">
                    {t('buy.empty_basket')}{' '}
                  </div>
                ) : (
                  <div>
                    <div className="col my-2 my-md-3">
                      <HeadTags
                        hSize={4}
                        strong
                        text={t('orderBasket.my_basket')}
                      />
                    </div>
                    {basket?.unpayedOrderItemList?.map((value, i) => (
                      <div
                        key={`1st_div_${i}`}
                        className="d-flex p-0 m-0 justify-content-center align-items-center"
                      >
                        <Modal
                          isVisible={isOpen.isOpen}
                          styleProps={{ maxWidth: 300 }}
                          title={t('orderBasket.are_you_sure')}
                          titleMS={5}
                          content={
                            <div className="d-flex">
                              <div className="ms-5 w-50">
                                <Button
                                  text={t('button.yes')}
                                  type={ButtonType.Danger}
                                  outline
                                  bold
                                  onClick={() => handleTrashCan(isOpen.orderId)}
                                  buttonCol={6}
                                />
                              </div>
                              <div className="mx-2 w-50">
                                <Button
                                  text={t('button.no')}
                                  type={ButtonType.Success}
                                  outline
                                  bold
                                  onClick={() => setIsOpen({ isOpen: false })}
                                  buttonCol={6}
                                />
                              </div>
                            </div>
                          }
                          onClose={() => setIsOpen({ isOpen: false })}
                        />

                        <div
                          id={basket?.unpayedOrderItemList?.[i]?.id}
                          className="flex-grow-1"
                        >
                          <div className="col-12 ">
                            <Card
                              bodyRounded
                              padding={0}
                              body={
                                <>
                                  <div className="table-responsive table-sm row px-1 px-sm-2 ">
                                    <BasicTable
                                      data={[
                                        basket?.unpayedOrderItemList[i],
                                      ].map((item) => {
                                        return {
                                          netAmount:
                                            item?.netAmount +
                                            ' ' +
                                            currency.symbol,
                                          order: item?.userArea,
                                          id: item.id,
                                          undated: item.unDatedSessionCount,
                                          typeofSession:
                                            item?.orderItemDescription,
                                        };
                                      })}
                                      headers={headers}
                                      emptyThread
                                      func={handleClick}
                                      status={arrow}
                                    />
                                  </div>
                                  <div
                                    id={value.id}
                                    className={arrow[value.id] ? 'd-none' : ''}
                                  >
                                    <div className="row mx-1 mx-sm-3">
                                      {basket?.unpayedOrderItemList?.[
                                        i
                                      ]?.subsType?.totalCostItems?.map(
                                        (v, l) => (
                                          <div
                                            key={`subs_div_${value.id}${l}`}
                                            className="col-md-6 pb-1 mb-sm-3"
                                          >
                                            <Link
                                              id={`${value.id}_${l}`}
                                              className="text-decoration-none"
                                              onClick={onSelectSubsLenght}
                                              key={`subs_lenght_link_${l}`}
                                              tabIndex={i}
                                              title={`${v.netAmount}_${
                                                v.totalAmount
                                              }_${l + 1}`}
                                            >
                                              <Card
                                                padding={0}
                                                bodyRounded
                                                cardStyle={{
                                                  maxHeight: '100px',
                                                }}
                                                discountBody={
                                                  v.discountPercent &&
                                                  v.discountPercent !== 0 ? (
                                                    <div className="d-flex justify-content-center">
                                                      <label className="d-sm-flex justify-content-center me-sm-1 px-2 rounded-top rounded-4 bg-danger text-white fw-bold">
                                                        {basket?.unpayedOrderItemList.some(
                                                          (item) =>
                                                            item?.subsType?.totalCostItems?.some(
                                                              (costItem) =>
                                                                costItem.couponCode !==
                                                                ''
                                                            )
                                                        )
                                                          ? v.couponDiscountPercent +
                                                            v.discountPercent
                                                          : v.discountPercent}
                                                        %
                                                        {t(
                                                          'onlineGroup.discount'
                                                        )}
                                                      </label>
                                                    </div>
                                                  ) : null
                                                }
                                                body={
                                                  <div className="d-flex justify-content-between my-3 pt-1">
                                                    <div className="d-sm-flex ps-1 left-0 text-start flex-sm-column">
                                                      <div className="text-dark fs-sm-5">
                                                        <strong>
                                                          {v.month}{' '}
                                                          {t(
                                                            'onlineGroup.month'
                                                          )}
                                                        </strong>
                                                      </div>
                                                      <div className="text-dark fs-sm-6 fw-bold">
                                                        {v.timesPerWeek *
                                                          v.month *
                                                          4}{' '}
                                                        x {v.packageMinutes}{' '}
                                                        {t(
                                                          'onlineGroup.minute'
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="d-sm-flex pe-1 ms-auto text-end right-0 flex-sm-column">
                                                      <div className="text-muted">
                                                        <strong className="text-dark fs-sm-5">
                                                          {v.netAmount}{' '}
                                                          {currency.symbol}
                                                        </strong>
                                                        /{' '}
                                                        {t('onlineGroup.total')}
                                                      </div>
                                                      <div className="text-muted">
                                                        <strong className="text-dark fs-sm-5">
                                                          {v.pricePerSession}{' '}
                                                          {currency.symbol}
                                                        </strong>
                                                        /{' '}
                                                        {t(
                                                          'onlineGroup.session'
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                }
                                                outerBackgroud={`border ${
                                                  Object.keys(
                                                    selectedSubsLength
                                                  ).find(
                                                    (element) =>
                                                      element ===
                                                      `${value.id}_${l}`
                                                  ) === `${value.id}_${l}`
                                                    ? 'border-5'
                                                    : 'border-1'
                                                } border-success`}
                                                key={`subs_lenght_card_${l}`}
                                              />
                                            </Link>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </>
                              }
                              outerBackgroud={`border border-4 border-success`}
                            />
                          </div>
                        </div>

                        <div className="flex-shrink-1 align-self-center ps-2 m-0 ">
                          <FontAwesomeIcon
                            id={basket?.unpayedOrderItemList[i].id}
                            role={'button'}
                            onClick={(e) =>
                              setIsOpen({
                                isOpen: true,
                                orderId: e.currentTarget.id,
                              })
                            }
                            className="text-success fa-2xl"
                            icon={faTrashCan}
                          />
                        </div>
                      </div>
                    ))}
                    {basket?.unpayedOrderItemList?.length > 0 &&
                      basket?.unpayedOrderItemList?.[0]?.trialStatus !==
                        'FreeTrial' && (
                        <>
                          {(basket?.unpayedOrderItemList[0]
                            ?.scheduleUserSelection?.packageOptions
                            ?.couponCode === '' ||
                            basket?.unpayedOrderItemList[0]
                              ?.scheduleUserSelection?.packageOptions
                              ?.couponCode === null) && (
                            <div className="d-flex ms-sm-5 align-items-center me-sm-5 mt-sm-5">
                              <div className="d-flex">
                                <InputLabelOnBorder
                                  id={'couponCode'}
                                  labelText={t('onlineGroup.promo_code')}
                                  inputType={InputType.Text}
                                  inputValue={promoCode.couponCode}
                                  placeholder={t('onlineGroup.promo_code')}
                                  onChange={onChangeText}
                                  callBack={(e) => onClickPromo(e)}
                                />
                              </div>
                              <div className="d-flex justify-content-end mt-2 ms-1">
                                <SubmitButton
                                  stateName={'price'}
                                  onClick={onClickPromo}
                                  objectLengthRule={
                                    promoCode.couponCode === '' ? true : false
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="container col-md-6">
                            <div className="row mt-4 justify-content-center">
                              <div className="col-12 text-center">
                                <h3>
                                  <strong>
                                    {t('onlineGroup.order_summary')}
                                  </strong>
                                </h3>
                              </div>
                            </div>
                            {basket?.unpayedOrderItemList?.map((value, i) => (
                              <div
                                key={`2nd_div_${i}`}
                                className="row d-flex justify-content-between mt-4"
                              >
                                <div className="col-10 text-start px-0">
                                  <div>
                                    <span>
                                      {/* {
                                      basket?.unpayedOrderItemList[
                                        i
                                      ]?.subsType?.totalCostItems?.at(
                                        selectedSubsLength[`id` + value.id] !==
                                          undefined &&
                                          Object.keys(selectedSubsLength)
                                            .find((element) =>
                                              element.startsWith(value.id)
                                            )
                                            .split('_')[1]
                                      ).timesPerWeek
                                    }{' '} */}
                                      {value.orderItemDescription}
                                    </span>
                                  </div>
                                  {value.trialStatus === 'PaidTrial'
                                    ? null
                                    : basket?.unpayedOrderItemList[
                                        i
                                      ]?.subsType?.totalCostItems?.at(
                                        selectedSubsLength[`id` + value.id] !==
                                          undefined &&
                                          Object.keys(selectedSubsLength)
                                            .find((element) =>
                                              element.startsWith(value.id)
                                            )
                                            .split('_')[1]
                                      ).discountPercent !== 0 && (
                                        <div>
                                          <strong>
                                            {
                                              basket?.unpayedOrderItemList[
                                                i
                                              ]?.subsType?.totalCostItems?.at(
                                                selectedSubsLength[
                                                  `id` + value.id
                                                ] !== undefined &&
                                                  Object.keys(
                                                    selectedSubsLength
                                                  )
                                                    .find((element) =>
                                                      element.startsWith(
                                                        value.id
                                                      )
                                                    )
                                                    .split('_')[1]
                                              ).discountPercent
                                            }
                                            % {t('onlineGroup.discount')}
                                          </strong>
                                        </div>
                                      )}
                                  {basket?.unpayedOrderItemList[i]?.subsType !==
                                    null &&
                                  basket?.unpayedOrderItemList[
                                    i
                                  ]?.subsType?.totalCostItems?.at(
                                    selectedSubsLength[`id` + value.id] !==
                                      undefined &&
                                      Object.keys(selectedSubsLength)
                                        .find((element) =>
                                          element.startsWith(value.id)
                                        )
                                        .split('_')[1]
                                  ).couponCode !== '' ? (
                                    <div className="text-muted fw-bold">
                                      {
                                        basket?.unpayedOrderItemList[
                                          i
                                        ]?.subsType?.totalCostItems?.at(
                                          selectedSubsLength[
                                            `id` + value.id
                                          ] !== undefined &&
                                            Object.keys(selectedSubsLength)
                                              .find((element) =>
                                                element.startsWith(value.id)
                                              )
                                              .split('_')[1]
                                        ).couponCode
                                      }
                                      , {t('onlineGroup.discount')}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="col-2 text-end border-bottom px-0">
                                  <div className="row d-flex justify-content-end">
                                    <div className="col">
                                      <span>
                                        {value.trialStatus === 'PaidTrial'
                                          ? value.netAmount
                                          : basket?.unpayedOrderItemList[
                                              i
                                            ]?.subsType?.totalCostItems?.at(
                                              selectedSubsLength[
                                                `id` + value.id
                                              ] !== undefined &&
                                                Object.keys(selectedSubsLength)
                                                  .find((element) =>
                                                    element.startsWith(value.id)
                                                  )
                                                  .split('_')[1]
                                            ).totalAmount}{' '}
                                        {currency.symbol}
                                      </span>
                                    </div>
                                  </div>
                                  {value.trialStatus === 'PaidTrial'
                                    ? null
                                    : basket?.unpayedOrderItemList[
                                        i
                                      ]?.subsType?.totalCostItems?.at(
                                        selectedSubsLength[`id` + value.id] !==
                                          undefined &&
                                          Object.keys(selectedSubsLength)
                                            .find((element) =>
                                              element.startsWith(value.id)
                                            )
                                            .split('_')[1]
                                      ).discountPercent !== 0 && (
                                        <div className="text-muted">
                                          <strong className="text-dark">
                                            -
                                            {
                                              basket?.unpayedOrderItemList[
                                                i
                                              ]?.subsType?.totalCostItems?.at(
                                                selectedSubsLength[
                                                  `id` + value.id
                                                ] !== undefined &&
                                                  Object.keys(
                                                    selectedSubsLength
                                                  )
                                                    .find((element) =>
                                                      element.startsWith(
                                                        value.id
                                                      )
                                                    )
                                                    .split('_')[1]
                                              ).discountAmount
                                            }{' '}
                                            {currency.symbol}
                                          </strong>
                                        </div>
                                      )}
                                  {basket?.unpayedOrderItemList[i]?.subsType !==
                                    null &&
                                  basket?.unpayedOrderItemList[
                                    i
                                  ]?.subsType?.totalCostItems?.at(
                                    selectedSubsLength[`id` + value.id] !==
                                      undefined &&
                                      Object.keys(selectedSubsLength)
                                        .find((element) =>
                                          element.startsWith(value.id)
                                        )
                                        .split('_')[1]
                                  ).couponCode !== '' ? (
                                    <div className="text-muted fw-bold">
                                      -
                                      {basket?.unpayedOrderItemList[
                                        i
                                      ]?.subsType?.totalCostItems?.at(
                                        selectedSubsLength[`id` + value.id] !==
                                          undefined &&
                                          Object.keys(selectedSubsLength)
                                            .find((element) =>
                                              element.startsWith(value.id)
                                            )
                                            .split('_')[1]
                                      ).totalAmount -
                                        basket?.unpayedOrderItemList[
                                          i
                                        ]?.subsType?.totalCostItems?.at(
                                          selectedSubsLength[
                                            `id` + value.id
                                          ] !== undefined &&
                                            Object.keys(selectedSubsLength)
                                              .find((element) =>
                                                element.startsWith(value.id)
                                              )
                                              .split('_')[1]
                                        ).discountAmount -
                                        basket?.unpayedOrderItemList[
                                          i
                                        ]?.subsType?.totalCostItems?.at(
                                          selectedSubsLength[
                                            `id` + value.id
                                          ] !== undefined &&
                                            Object.keys(selectedSubsLength)
                                              .find((element) =>
                                                element.startsWith(value.id)
                                              )
                                              .split('_')[1]
                                        ).netAmount}{' '}
                                      {currency.symbol}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="row mt-4 text-center">
                                  <div className="col border-bottom">
                                    <strong>
                                      {
                                        basket?.unpayedOrderItemList[
                                          i
                                        ]?.subsType?.totalCostItems?.at(
                                          selectedSubsLength[
                                            `id` + value.id
                                          ] !== undefined &&
                                            Object.keys(selectedSubsLength)
                                              .find((element) =>
                                                element.startsWith(value.id)
                                              )
                                              .split('_')[1]
                                        ).month
                                      }{' '}
                                      {t('onlineGroup.month_fee')}
                                    </strong>
                                  </div>

                                  <div className="col border-bottom">
                                    <strong>
                                      {value.trialStatus === 'PaidTrial'
                                        ? value.netAmount
                                        : basket?.unpayedOrderItemList[
                                            i
                                          ]?.subsType?.totalCostItems?.at(
                                            selectedSubsLength[
                                              `id` + value.id
                                            ] !== undefined &&
                                              Object.keys(selectedSubsLength)
                                                .find((element) =>
                                                  element.startsWith(value.id)
                                                )
                                                .split('_')[1]
                                          ).netAmount}{' '}
                                      {currency.symbol}
                                    </strong>
                                    <span className="ms-3 fw-bold">
                                      {t('orderBasket.total')}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    <div className="d-flex justify-content-center mt-4">
                      <div className="col-4">
                        <Button
                          textColor={TextColors.Light}
                          text={t('button.complete')}
                          bold
                          type={ButtonType.Successbg}
                          buttonCol={12}
                          onClick={onBuy}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            }
          />
        </div>
      </div>
      <Toast
        id={'couponCode'}
        stateName={'couponCode'}
        onClose={() => {
          dispatch(Actions.couponCodesActions.cleanState(true));
        }}
      />
      <ModalInsideComponent
        ref={phoneConfirmationModal}
        title={ModalTitles.phoneConfirmation}
        child={
          phoneConfirmationInfo === '' ? (
            <PhoneConfirmation
              phoneConfirmInfo={(val) => setPhoneConfirmationInfo(val)}
              trialStatus={trialStatus}
            />
          ) : (
            phoneConfirmationInfo
          )
        }
        onClose={() => {
          if (phoneConfirmationInfo !== '') {
            if (
              !freeTrialDone &&
              userInfo?.phoneIsVerified &&
              basket?.unpayedOrderItemList?.length > 0 &&
              basket?.unpayedOrderItemList?.[0]?.trialStatus === 'FreeTrial' &&
              freeTrialData?.AttendeeId !== undefined
            ) {
              dispatch(Actions.orderActions.getBasketItemsReducer([], true));
              dispatch(Actions.orderActions.orderSetDateAction(freeTrialData));
            } else if (freeTrialDone) {
              navigate('/congratulations', {
                state: {
                  freeTrialData,
                  createPackage: true,
                  isFreeTrial: true,
                },
              });
            } else if (freeTrialData?.AttendeeId === undefined) {
              navigate(findMyPath('order/paymentdetail'));
            }
          }
          setPhoneConfirmationInfo('');
          phoneConfirmationModal.current?.close();
        }}
      />
    </div>
  );
  return showLoader ? <Loader /> : content;
}
