import {
    priceCalculate
} from '../../../services/apiServices/priceServices';
import ActionTypes from './actionTypes';

const priceCalculateAction = (data) => {
    return async (dispatch) => {
        await dispatch(handleValidationReducer(''));
        await dispatch(handleError(''));
        await dispatch(priceCalculateReducer([]));
        await priceCalculate(data).then(async (res) => {
            if (res?.data?.isSuccess) {
                await dispatch(handleError(true));
                if (res?.data?.messageType !== null) {
                    await dispatch(handleValidationReducer(res?.data?.message));
                }
            } else {
                await dispatch(handleValidationReducer(res?.data?.message));
                await dispatch(handleError(false));
            }
            await dispatch(priceCalculateReducer(res.data.data));
        });
    };
};
const priceCalculateReducer = (data) => {
    return {
        type: ActionTypes.price.CalculatedPrices,
        payload: data,
    };
};

const handleValidationReducer = (data) => {
    return {
        type: ActionTypes.price.HandleValidation,
        payload: data,
    };
};
const handleError = (data) => {
    return {
        type: ActionTypes.price.HandleError,
        payload: data,
    };
};

const cleanState = (validation = false) => {
    return async (dispatch) => {
        if (validation) {
            dispatch(handleValidationReducer(''));
            dispatch(handleError(''));
        } else {
            dispatch(handleValidationReducer(''));
            dispatch(handleError(''));
            dispatch(priceCalculateReducer([]))
        }
    };
};
const priceActions = {
    priceCalculateAction,
    cleanState
};
export default priceActions;
