import {
  getLevelGroup,
  preferenceGetById,
} from '../../../services/apiServices/preference.Services';
import { defaultLevelGroup, storageKeys } from '../../../utils/Enum';
import ActionTypes from './actionTypes';

const getLevelGroupAction = (userArea) => {
  return async (dispatch) => {
    await getLevelGroup(userArea).then(async (res) => {
      let response = res?.data?.data;
      if (Object?.keys(response)?.length > 0) {
        const adultLevelGroups = response.map(
          (item) => item.adultLanguageLevel.levelGroup
        );
        const kidsLevelGroups = response.map(
          (item) => item.kidsLanguageLevel.levelGroup
        );
        const uniqueAdultLevelGroups = new Set(adultLevelGroups);
        const uniqueKidsLevelGroups = new Set(kidsLevelGroups);
        await dispatch(
          getLevelGroupReducer({
            adult: Array.from(uniqueAdultLevelGroups)[0],
            kids: Array.from(uniqueKidsLevelGroups)[0],
          })
        );
      } else {
        await dispatch(getLevelGroupReducer(defaultLevelGroup));
      }
    });
  };
};
const getLevelGroupReducer = (data) => {
  sessionStorage.setItem(storageKeys.levelGroup, JSON.stringify(data));
  return {
    type: ActionTypes.preference.GET_LEVEL_GROUP,
    payload: data,
  };
};
const getCurrencyAction = () => {
  return async (dispatch) => {
    await preferenceGetById(10).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getCurrencyReducer(JSON.parse(response?.value)));
    });
  };
};
const getCurrencyReducer = (data) => {
  return { type: ActionTypes.preference.GET_CURRENCY_OPTION, payload: data };
};

const setCurrencyAction = (currency, isSuccess) => {
  const modifiedCurrencyUnit = {
    code: currency?.Code || currency?.code,
    name: currency?.Name || currency?.name,
    symbol: currency?.Symbol || currency?.symbol,
  };
  sessionStorage.setItem(
    storageKeys.currency,
    JSON.stringify(modifiedCurrencyUnit)
  );
  return {
    type: ActionTypes.preference.CURRENCY_CHOSEN,
    payload: { data: modifiedCurrencyUnit, isSuccess: isSuccess },
  };
};
const preferenceActions = {
  getCurrencyAction,
  setCurrencyAction,
  getLevelGroupAction,
};

export default preferenceActions;
