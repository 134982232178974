import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(
    endPoint.timesPerWeek.CREATE_TIMESPERWEEK,
    createData
  );
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.timesPerWeek.UPDATE_TIMESPERWEEK}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(
    `${endPoint.timesPerWeek.REMOVE_TIMESPERWEEK}/${id}`
  );
};
export const getById = async (id) => {
  return await getRequest(
    `${endPoint.timesPerWeek.GETBYID_TIMESPERWEEK}/${id}`
  );
};
export const getAll = async () => {
  return await getRequest(endPoint.timesPerWeek.GETALL_TIMESPERWEEK);
};
