import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(
    endPoint.attendeeFreeze.CREATE_ATTENDEEFREEZE,
    createData
  );
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.attendeeFreeze.UPDATE_ATTENDEEFREEZE}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(
    `${endPoint.attendeeFreeze.REMOVE_ATTENDEEFREEZE}/${id}`
  );
};
export const getById = async (id) => {
  return await getRequest(
    `${endPoint.attendeeFreeze.GETBYID_ATTENDEEFREEZE}/${id}`
  );
};
export const getAll = async () => {
  return await getRequest(endPoint.attendeeFreeze.GETALL_ATTENDEEFREEZE);
};
