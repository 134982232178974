import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const branchFreezeReducer = (state = initialState.branchFreeze, action) => {
  switch (action.type) {
    case ActionTypes.branchFreeze.CREATE_ACTION_BRANCHFREEZE:
      return {
        ...state,
        branchFreeze: initialState.branchFreeze.branchFreeze,
      };
    case ActionTypes.branchFreeze.UPDATE_ACTION_BRANCHFREEZE:
      return {
        ...state,
        branchFreeze: {},
        getById: {},
      };
    case ActionTypes.branchFreeze.GETBYID_ACTION_BRANCHFREEZE:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.branchFreeze.GETALL_ACTION_BRANCHFREEZE:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.branchFreeze.HOLD_VALUE_BRANCHFREEZE:
      return {
        ...state,
        branchFreeze: action.payload,
      };
    case ActionTypes.branchFreeze.HANDLE_ERROR_BRANCHFREEZE:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.branchFreeze.HANDLE_VALIDATION_BRANCHFREEZE:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default branchFreezeReducer;
