import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const couponCodesReducer = (state = initialState.couponCode, action) => {
  switch (action.type) {
    case ActionTypes.couponCodes.CREATE_ACTION_COUPONCODES:
      return {
        ...state,
        couponCode: initialState.couponCode.couponCode,
      };
    case ActionTypes.couponCodes.UPDATE_ACTION_COUPONCODES:
      return {
        ...state,
        couponCode: {},
      };
    case ActionTypes.couponCodes.GETALL_ACTION_COUPONCODES:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.couponCodes.HOLD_VALUE_COUPONCODES:
      return {
        ...state,
        couponCode: action.payload,
      };
    case ActionTypes.couponCodes.GETBYNAME_ACTION_COUPONCODES:
      return {
        ...state,
        getByName: action.payload.data,
      };
    case ActionTypes.couponCodes.HANDLE_VALIDATION_COUPONCODES:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.couponCodes.HANDLE_ERROR_COUPONCODES:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.resetStore.RESET_STORE:
      return {
        ...state,
        getByName: {},
        isSuccess: '',
        validationMessage: '',
      };
    default:
      return state;
  }
};

export default couponCodesReducer;
