import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const createSpeakerAvailability = async (createData) => {
  return await postRequest(
    endPoint.speakerAvailability.CREATE_AVAILABILITY,
    createData
  );
};

export const updateSpeakerAvailability = async (updateData, id) => {
  return await putRequest(
    `${endPoint.speakerAvailability.UPDATE_AVAILABILITY}/${id}`,
    updateData
  );
};

export const removeSpeakerAvailability = async (id) => {
  return await removeRequest(
    `${endPoint.speakerAvailability.REMOVE_AVAILABILITY}/${id}`
  );
};

export const getAllSpeakerAvailabilities = async (id = '') => {
  return await getRequest(`${endPoint.speakerAvailability.GETALL_AVAILABILITY}${id !== '' ? `/${id}` : ''}`);
};

export const getSelectedSpeakerDates = async (filter) => {
  return await postRequest(
    endPoint.speakerAvailability.GET_SELECTED_AVAILABILITY,
    filter
  );
};
