import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styles from '../../../assets/Styles.module.scss';
import { useTranslation } from 'react-i18next';

const ModalInsideComponent = forwardRef(
  ({ onClose, child, title, maxWidth }, ref) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [wasRecentlyClosed, setWasRecentlyClosed] = useState(false);

    const handleEscKeyPress = (event) => {
      if (event.keyCode === 27 && isOpen) {
        onClose();
      }
    };

    useEffect(() => {
      window.addEventListener('keyup', handleEscKeyPress);
      return () => {
        window.removeEventListener('keyup', handleEscKeyPress);
      };
    }, [isOpen]);

    useImperativeHandle(ref, () => ({
      close() {
        setIsOpen(false);
        setWasRecentlyClosed(true);
      },
      open() {
        setIsOpen(true);
      },
      check() {
        return wasRecentlyClosed;
      },
    }));

    return (
      <React.Fragment>
        {isOpen && (
          <div
            className={styles.basic_confirm_modal_overlay}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={styles.basic_confirm_modal}
              tabIndex="-1"
              style={{ maxWidth: `${maxWidth}px` }}
            >
              <div className={styles.basic_confirm_modal_dialog}>
                <div className={styles.modal_content}>
                  <div className={styles.basic_confirm_modal_header}>
                    <button
                      type="button"
                      className={styles.basic_confirm_modal_header}
                      onClick={() => {
                        onClose();
                      }}
                      aria-label="Close"
                    />
                  </div>
                  <div className={`${styles.confirm_modal_title} mb-4`}>
                    <h5>
                      <strong>{t(title)}</strong>
                    </h5>
                  </div>
                  <div className={styles.basic_confirm_modal_body}>{child}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
);
export default ModalInsideComponent;
