import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import Button from '../../../../components/Common/Button/Button';
import Card from '../../../../components/Common/Card/Card';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../../components/Common/Dropdown/DropdownWithSearch';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../../utils/ComponentEnums';
import { ToastContainer, toast } from 'react-toastify';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const orderItemData = useSelector((e) => e.order.getById);
  const isSuccess = useSelector((e) => e.order.isSuccess);
  const validationMessage = useSelector((e) => e.order.validationMessage);
  const locationList = useSelector((e) => e.location.branches);
  const orderItemId = location?.state?.id;
  const orderId = location.state?.orderId;
  const isCafe = location?.state?.userArea === 'Cafe';
  const redirect = '/order-item-list';
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const [handleError, setHandleError] = useState('');

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          setUpdateData({});
          setHandleError('');
          navigate(redirect, { state: { id: orderId } });
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    setUpdateData({
      ...updateData,
      [id]: type === 'number' ? parseInt(value) : value,
    });
    if (value) {
      setDataChanged(false);
    }
  };

  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...orderItemData });
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(Actions.orderActions.orderItemUpdateAction(updateData));
    setDataChanged(true);
  };

  const handleDropdown = (propName, value) => {
    const parsedValue = parseInt(value);
    setUpdateData({ ...updateData, [propName]: parsedValue });
    dispatch(
      Actions.orderActions.holdValue({
        ...updateData,
        [propName]: parsedValue,
      })
    );
    setDataChanged(false);
  };

  useEffect(() => {
    dispatch(Actions.orderActions.getOrderItemDetailsAction(orderItemId));
    if (isCafe) {
      dispatch(
        Actions.locationActions.getLocationsByUserAreaAction({
          userArea: 1,
        })
      );
    }
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(orderItemData).length > 0 &&
      Object.keys(updateData).length === 0
    ) {
      setUpdateData({ ...orderItemData, id: orderItemId });
    }
  }, [orderItemData]);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('order.update_order_item_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'redateRight'}
                      inputType={InputType.Number}
                      labelText={t('orderItem.redateRight')}
                      inputValue={updateData.redateRight}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'undatedPackageCount'}
                      inputType={InputType.Number}
                      labelText={t('order.undated_package_count')}
                      inputValue={updateData.undatedPackageCount}
                      onChange={onChangeText}
                    />
                  </div>
                  {isCafe && (
                    <div className="col-6 mt-4">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('order.table_location')}
                        options={locationList}
                        selectedProps={{ key: 'key', value: 'id' }}
                        selectedValues={updateData.location}
                        onChange={(value) => handleDropdown('Location', value)}
                        onFocus={onFocus}
                      />
                    </div>
                  )}
                  {isCafe && (
                    <div className="d-flex justify-content-end mt-2">
                      <span className="text-danger fw-bold">
                        {t('orderItem.location_change_warning')}
                      </span>
                    </div>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      type={
                        dataChanged
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.common_submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={dataChanged ? true : false}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.update,
    content
  );
};
export default Update;
