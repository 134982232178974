import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../store/redux/actions';
import Accordion from '../../components/Common/Accordion/Accordion';
import Card from '../../components/Common/Card/Card';
import HeadTags from '../../components/Common/HeadTags/HeadTags';

const Faq = ({ props }) => {
  const { t } = useTranslation();
  const faqList = useSelector((e) => e.faq.getAll);
  const faqGroupList = useSelector((e) => e.faqGroup.getAll);
  const userLanguage = useSelector((e) => e.lang.language);
  const dispatch = useDispatch();
  const [obj, setObj] = useState([]);
  let obj1 = faqList?.filter((o) => o.faqGroupId === 1);
  const [selectedSubject, setSelectedSubject] = useState(1);

  useEffect(() => {
    if (faqList.length === 0) {
      dispatch(
        Actions.faqActions.getAllAction({
          userLanguage: userLanguage.split('-')[0].toLowerCase(),
        })
      );
    }
    if (faqGroupList.length === 0) {
      dispatch(
        Actions.faqGroupActions.getAllAction({
          userLanguage: userLanguage.split('-')[0].toLowerCase(),
        })
      );
    }
  }, [faqList, faqGroupList]);

  let results = [];
  results = faqList?.reduce(function (results, faq) {
    (results[faq.faqGroupId] = results[faq.faqGroupId] || []).push(faq);
    return results;
  }, {});
  let names = faqGroupList?.map((e, i) => {
    return (
      <div className="border-bottom border-secondary mt-3" key={i}>
        <Link
          className={`text-decoration-none text-success fw-bolder ${
            selectedSubject === e.id ? 'fs-5' : 'fs-6'
          }`}
          key={i}
          id={e.id}
          onClick={(e) => {
            setObj(results[e.target.id]);
            setSelectedSubject(parseInt(e.target.id));
          }}
        >
          {e.name}
        </Link>
      </div>
    );
  });

  return (
    <div className="container">
      {props === 'calledBySupport' ? undefined : (
        <div className="d-flex justify-content-center mt-5">
          <HeadTags hSize={2} text={'Frequently Asked Questions'} strong />
        </div>
      )}
      <div className="row">
        <div className="col-12 col-md-3">{names}</div>
        <div className="col-12 col-md-9">
          <Card
            body={
              <Accordion
                iconArrow
                propNames={{
                  header: 'question',
                  content: 'answer',
                  videoContent: 'youtubeLink',
                }}
                items={obj.length === 0 ? obj1 : obj}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Faq;
