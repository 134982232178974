import {
  create,
  update,
  remove,
  getById,
  getAll,
} from '../../../services/apiServices/companyPriceRequestServices';
import ActionTypes from './actionTypes';
import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(handleValidationReducer(''));
    dispatch(handleError(''));
    await create(createData).then(async (res) => {
      if (res?.data?.isSuccess) {
        dispatch(createReducer());
        dispatch(handleError(true));
      } else {
        dispatch(handleValidationReducer(res?.data?.message));
        dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.companyPriceRequest.CREATE_ACTION_PRICEREQUEST };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      await dispatch(updateReducer());
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.companyPriceRequest.UPDATE_ACTION_PRICEREQUEST };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.companyPriceRequest.GETBYID_ACTION_PRICEREQUEST,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.companyPriceRequest.GETALL_ACTION_PRICEREQUEST,
    payload: { data },
  };
};
const holdValue = (data) => {
  return {
    type: ActionTypes.companyPriceRequest.HOLD_VALUE_PRICEREQUEST,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.companyPriceRequest.HANDLE_VALIDATION_PRICEREQUEST,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.companyPriceRequest.HANDLE_ERROR_PRICEREQUEST,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(
        handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default)
      );
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
      await dispatch(updateReducer());
    }
  };
};
const companyPriceRequestActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  holdValue,
  cleanState,
};
export default companyPriceRequestActions;
