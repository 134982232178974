import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const speakerAvailabilityReducer = (
  state = initialState.speakerAvailability,
  action
) => {
  switch (action.type) {
    case ActionTypes.speakerAvailability.CREATE_ACTION_AVAILABILITY:
      return {
        ...state,
        speakerAvailability:
          initialState.speakerAvailability.speakerAvailability,
      };
    case ActionTypes.speakerAvailability.UPDATE_ACTION_AVAILABILITY:
      return {
        ...state,
        speakerAvailability: {},
      };
    case ActionTypes.speakerAvailability.GETALL_ACTION_AVAILABILITY:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.speakerAvailability.HOLD_VALUE_AVAILABILITY:
      return {
        ...state,
        speakerAvailability: action.payload,
      };
    case ActionTypes.speakerAvailability.HANDLE_ERROR_AVAILABILITY:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.speakerAvailability.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.speakerAvailability.GET_SELECTED_AVAILABILITY:
      return {
        ...state,
        getSelectedAvaibilities: action.payload,
      };
    default:
      return state;
  }
};

export default speakerAvailabilityReducer;
