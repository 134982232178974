import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const preferenceReducer = (state = initialState.preferenceOptions, action) => {
  switch (action.type) {
    case ActionTypes.preference.CURRENCY_CHOSEN:
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
        preferenceCurrencyUnit: action.payload.data,
      };
    case ActionTypes.preference.GET_CURRENCY_OPTION:
      return {
        ...state,
        currenyOptions: action.payload,
      };
    case ActionTypes.preference.GET_LEVEL_GROUP:
      return {
        ...state,
        preferenceLevelGroup: action.payload,
      };
    default:
      return state;
  }
};
export default preferenceReducer;
