import React, { useEffect, useRef, useState } from 'react';
import Payment from 'payment';
import { useTranslation } from 'react-i18next';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '../../../components/Common/Card/Card';
import Input from '../../../components/Common/Input/Input';
import {
  InputSize,
  InputType,
  LabelTextType,
} from '../../../utils/ComponentEnums';
import Button from '../../../components/Common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import ProgressLine from '../../../components/Common/ProgressLine/ProgressLine';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import Actions from '../../../store/redux/actions';
import Modal from '../../../components/Common/Modal/Modal';
import BasicTable from '../../../components/Common/Table/BasicTable';
import Result3D from './Payment3DResult';
import findMyPath from '../../../hooks/useFindMyPath';
import useGTM from '../../../hooks/useGTM';
import { ModalTitles, storageKeys } from '../../../utils/Enum';
import PaymentPolicy from '../CommonPages/PaymentPolicy';
import MembershipPolicy from '../CommonPages/MembershipPolicy';
import ToastWithParams from '../../../components/Common/Popup/ToastWithParams';
import PhoneConfirmation from '../AttendeePages/PhoneConfirmation';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import { cardArray, cardPropertyNames } from '../../../utils/ExternalVariables';

const Buy = () => {
  const phoneConfirmationModal = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const goBack = () => navigate(-1);
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
  const errorMessage = queryParams.get('error');
  const [buyingMethod, setBuyingMethod] = useState('');
  const getBoard = useSelector((e) => e.onboard.onboard);
  const basket = useSelector((state) => state.order.basketItems);
  const currency = useSelector(
    (e) => e.preferenceOptions.preferenceCurrencyUnit
  );
  const userInfo = useSelector((e) => e.user.getById);
  const currencyCode = userInfo?.currency?.code;
  const sessionPackage = useSelector((e) => e.sessionPackage.getAll);
  const userLevel = userInfo?.languageLevel;
  const installment = useSelector((e) => e.payment.installment);
  const callBackContent = useSelector((e) => e.payment.callBackContent);
  const resultMessage = useSelector((e) => e.payment.resultMessage);
  //var lookup = require('binlookup')();
  const [buttonText, setButtonText] = useState({});
  const [creditCardData, setCreditCardData] = useState({});
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [issuer, setIssuer] = useState('');
  const [focused, setFocused] = useState('');
  const [handleError, setHandleError] = useState('');
  const [checkID, setCheckID] = useState(undefined);
  const [installmentData, setInstallmentData] = useState([]);
  const [billOptions, setBillOptions] = useState('individual');
  const [billingData, setBillingData] = useState({});
  const isSuccess = useSelector((e) => e.payment.isSuccess);
  const validationMessages = useSelector((e) => e.payment.validationMessage);
  const { sendEventToDataLayer, generateItemId } = useGTM();
  const affiliation = queryParams?.get('utm_source');
  const ageInterval = useSelector((e) => e.ageInterval.getAll);
  const learningPurposes = useSelector((e) => e.staticData.LearningPurpose);
  const [showLoader, setShowLoader] = useState(false);
  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };
  const [paymentAndMemberShipChecked, setpaymentAndMemberShipChecked] =
    useState(false);
  const [paymentModalVisible, setpaymentModalVisible] = useState(false);
  const [memberShipModalVisible, setMemberShipModalVisible] = useState(false);
  const [paymentAccepted, setpaymentAccepted] = useState(false);
  const [MemberShipAccepted, setMemberShipAccepted] = useState(false);
  const userHasPhone = useSelector((e) => e.user?.getById?.phoneIsVerified);
  const auth = useSelector((e) => e.auth.auth);
  const [phoneConfirmationInfo, setPhoneConfirmationInfo] = useState('');

  useEffect(() => {
    if (
      userHasPhone !== undefined &&
      phoneConfirmationModal.current !== undefined &&
      phoneConfirmationModal.current?.check() === false
    ) {
      if (!userHasPhone && auth !== '') {
        phoneConfirmationModal.current.open();
      }
    }
  }, [phoneConfirmationModal.current]);

  const handlePaymentandMemberShipCheckboxChange = () => {
    setpaymentAndMemberShipChecked(!paymentAndMemberShipChecked);
  };
  const handlePaymentCheckboxChange = () => {
    setpaymentAccepted(!paymentAccepted);
    setpaymentModalVisible(false);
  };
  const handleMemberShipCheckboxChange = () => {
    setMemberShipAccepted(!MemberShipAccepted);
    setMemberShipModalVisible(false);
  };

  useEffect(() => {
    if (MemberShipAccepted && paymentAccepted) {
      setpaymentAndMemberShipChecked(true);
    }
  }, [MemberShipAccepted, paymentAccepted]);
  useEffect(() => {
    if (status === 'success') {
      sendEventToDataLayer({
        event: 'paymentType',
        paymentType: 'Credit Card',
      });
    }
  }, [status]);

  const handleBasket = () => {
    dispatch(Actions.orderActions.getBasketItemsAction());
  };
  useEffect(() => {
    if (sessionPackage.length === 0) {
      dispatch(Actions.sessionPackageActions.getAllAction());
    }
    handleBasket();
    dispatch(Actions.ageIntervalActions.getAllAction(2));

    return () => {
      dispatch(Actions.paymentActions.resetStateAction());
      setHandleError('');
    };
  }, []);

  function findNameByAgeInterval(value) {
    const item = ageInterval.find((item) => item.id === value);
    return item ? `${item.minAge} - ${item.maxAge}` : null;
  }
  function matchLearningPurposes(learningPurposesString) {
    const learningPurposesArray = learningPurposesString?.split(',');

    const matchedKeys = learningPurposes
      ?.filter((item) => learningPurposesArray?.includes(item.value))
      ?.map((item) => t(item.key));

    return matchedKeys;
  }

  useEffect(() => {
    if (
      basket !== undefined &&
      basket !== '' &&
      basket?.unpayedOrderItemList !== undefined
    ) {
      const basketItems = basket?.unpayedOrderItemList;
      const totalAmount = basketItems?.reduce(
        (sum, item) => sum + item.netAmount,
        0
      );
      const itemsArray = basketItems.map((item) => {
        const days = item.options.timesPerWeek;
        const duration = sessionPackage?.find(
          (a) => a.id === item.options.sessionPackage
        )?.minute;
        const sessionType =
          sessionPackage?.find((a) => a.id === item.options.sessionPackage)
            ?.person > 1
            ? 'Group'
            : 'OneToOne';

        return {
          days,
          duration,
          session_type: sessionType,
          item_id: generateItemId(
            item.options.location,
            userInfo.userArea,
            item.options.sessionPackage,
            item.options.timesPerWeek,
            item.options.monthOptions,
            1
          ),
          age_interval: findNameByAgeInterval(item.ageInterval),
          learning_purposes: matchLearningPurposes(
            item.scheduleUserSelection.learningPurposes
          ),
          item_name: item.orderItemDescription,
          currency: currency.name === 'TL' ? 'TRY' : currency.name,
          item_brand: 'Meet2talk',
          price: item.netAmount,
          quantity: 1,
        };
      });

      sendEventToDataLayer({
        event: 'begin_checkout',
        ecommerce: {
          transaction_id: basket.orderId,
          value: totalAmount,
          currency: currency.name === 'TL' ? 'TRY' : currency.name,
          attendee:
            userInfo.userArea === 0
              ? 'Online'
              : userInfo.userArea === 2
              ? 'Kids'
              : userInfo.userArea === 1
              ? 'Cafe'
              : 'Company',
          language_level: t(
            userLevel ?? sessionStorage.getItem(storageKeys.userLevel),
            { lng: 'en' }
          ),
          items: itemsArray,
        },
      });
    }
  }, [basket.orderId]);
  useEffect(() => {
    if (isSuccess) {
      const basketItems = basket?.unpayedOrderItemList;
      const totalAmount = basketItems?.reduce(
        (sum, item) => sum + item.netAmount,
        0
      );
      function findFirstCouponCode(orderItemList) {
        for (const item of orderItemList) {
          const couponCode = item?.subsType?.totalCostItems.find(
            (costItem) => costItem.couponCode
          )?.couponCode;
          if (couponCode) {
            return couponCode;
          }
        }
        return '';
      }
      const firstItem = basketItems[0];
      const days = firstItem?.orderItemDescription.split(' - ')[2];
      const duration = sessionPackage?.find(
        (a) => a.id === firstItem?.options.sessionPackage
      )?.minute;
      const session_type =
        sessionPackage?.find((a) => a.id === firstItem?.options.sessionPackage)
          ?.person > 1
          ? 'Group'
          : 'OneToOne';
      sendEventToDataLayer({
        event: 'purchase',
        ecommerce: {
          transaction_id: basket.orderId,
          affiliation: affiliation ?? '',
          value: totalAmount,
          currency: currency.name === 'TL' ? 'TRY' : currency.name,
          couponCode: findFirstCouponCode(basket.unpayedOrderItemList),
          payment_type:
            buyingMethod === 2
              ? 'EFT'
              : buyingMethod === 1
              ? 'Credit Card'
              : '',
          attendee:
            userInfo.userArea === 0
              ? 'Online'
              : userInfo.userArea === 2
              ? 'Kids'
              : userInfo.userArea === 1
              ? 'Cafe'
              : 'Company',
          language_level: t(
            userLevel ?? sessionStorage.getItem(storageKeys.userLevel),
            { lng: 'en' }
          ),
          items: basketItems.map((item) => ({
            item_id: generateItemId(
              item.options.location,
              userInfo.userArea,
              item.options.sessionPackage,
              item.options.timesPerWeek,
              item.options.monthOptions,
              1
            ),
            age_interval: findNameByAgeInterval(item.ageInterval),
            learning_purposes: matchLearningPurposes(
              item.scheduleUserSelection.learningPurposes
            ),
            item_name: item.orderItemDescription,
            currency: currency.name === 'TL' ? 'TRY' : currency.name,
            item_brand: 'Meet2talk',
            price: item.netAmount,
            quantity: 1,
            days: days,
            duration: duration,
            session_type: session_type,
          })),
        },
      });
      sessionStorage.removeItem(storageKeys.userLevel);
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(Actions.orderActions.getBasketItemsAction());
  }, [currencyCode]);

  function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  }
  function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === 'amex' ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
  }
  function formatCreditCardNumber(value) {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
      case 'amex':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;
      case 'dinersclub':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    return nextValue.trim();
  }
  function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
  }

  const handleInputChange = async ({ target }) => {
    switch (target.name) {
      case 'number':
        target.value = formatCreditCardNumber(target.value);
        setNumber(target.value);
        setCreditCardData({
          ...creditCardData,
          cardNumber: target.value.replace(/ /g, ''),
        });
        if (target.value.length === 7 || target.value.length === 19) {
          var request = target.value.replace(/ /g, '');
          // await lookup(request).then(async (response) => {
          //   dispatch(
          //     Actions.paymentActions.paymentInstallmentAction({
          //       creditCardDetails: response,
          //       orderId: basket.orderId,
          //       isPartial: buyingMethod === 0 ? true : false,
          //       cardNumber: request,
          //     })
          //   );
          // });
          dispatch(
            Actions.paymentActions.paymentInstallmentAction({
              orderId: basket.orderId,
              isPartial: buyingMethod === 0 ? true : false,
              cardNumber: request,
            })
          );
        }
        break;
      case 'expiry':
        target.value = formatExpirationDate(target.value);
        setCreditCardData({
          ...creditCardData,
          expiryMonth: target.value.slice('0', '2'),
          expiryYear: target.value.slice('3'),
        });
        setExpiry(target.value);
        break;
      case 'cvc':
        target.value = formatCVC(target.value);
        setCreditCardData({ ...creditCardData, cvv: target.value });
        setCvc(target.value);
        break;
      default:
        setCreditCardData({ ...creditCardData, cardOwnerName: target.value });
        setName(target.value);
        break;
    }
  };
  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };
  const [message, setMessage] = useState('');
  const [payStatus, setPayStatus] = useState(undefined);
  const handleSubmit = (e) => {
    e?.preventDefault();
    setShowLoader(true);
    if (buyingMethod !== '' && buyingMethod !== 2) {
      if (cvc === '' || name === '' || number === '' || expiry === '') {
        return setHandleError(t('buy.payment_credit_card_info'));
      }
    }
    if (billOptions === 'individual') {
      const requiredFields = ['fullName', 'city', 'county'];

      const hasEmptyFields = requiredFields.some(
        (field) => billingData[field] === undefined || billingData[field] === ''
      );

      if (hasEmptyFields) {
        setShowLoader(false);
        return setHandleError(t('buy.required_filed_warning'));
      }
      if (buyingMethod === '') {
        setShowLoader(false);
        return setHandleError(t('buy.payment_method_select'));
      }
    }
    if (billOptions === 'corporate') {
      const requiredFields = [
        'taxOrTckn',
        'taxAdministration',
        'fullName',
        'city',
        'county',
        'address',
      ];

      const hasEmptyFields = requiredFields.some(
        (field) => billingData[field] === undefined || billingData[field] === ''
      );

      if (hasEmptyFields) {
        setShowLoader(false);
        return setHandleError(t('buy.required_filed_warning'));
      }
      if (Object.keys(billingData).length === 0) {
        setShowLoader(false);
        return setHandleError(t('buy.required_filed_warning'));
      }
      if (buyingMethod === '') {
        setShowLoader(false);
        return setHandleError(t('buy.payment_method_select'));
      }
    }
    if (!userHasPhone && auth !== '') {
      return phoneConfirmationModal.current.open();
    }
    if (handleError === '') {
      const myOptions = {
        ...creditCardData,
        billingInformation: billingData,
      };
      dispatch(Actions.paymentActions.paymentAction(myOptions));
      setPayStatus(true);
    }
  };

  const handleButtonClick = (text, i) => {
    navigator.clipboard.writeText(text);
    setButtonText((prevButtonTexts) => ({
      ...prevButtonTexts,
      [i]: t('buy.eft_copy'),
    }));
    setTimeout(() => {
      setButtonText({});
    }, 2000);
  };
  useEffect(() => {
    if (
      isSuccess &&
      Object.keys(creditCardData).length >= 4 &&
      callBackContent !== ''
    ) {
      setName('');
      setNumber('');
      setExpiry('');
      setCvc('');
      setIssuer('');
      setCreditCardData('');
      setBillingData({});
    }
    if (isSuccess && buyingMethod === 2) {
      sendEventToDataLayer({
        event: 'paymentType',
        paymentType: 'Eft',
      });
      setMessage(t('buy.order_created_successfully'));
    }
    if (isSuccess && callBackContent !== '' && callBackContent !== null) {
      navigate('/3d-result');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (status !== null && status !== undefined) {
      if (status === 'success') {
        setPayStatus(true);
        setMessage(t('buy.credit_card'));
      } else if (status === 'fail') {
        setPayStatus(false);
        setMessage(t('buy.credit_cardFail'));
      }
    }
  }, [status]);

  const setValue = (changeEvent) => {
    const value = parseInt(changeEvent.target.value);
    setBuyingMethod(value);
    setInstallmentData([]);
    if (value === 1 || value === 0) {
      setCreditCardData({
        ...creditCardData,
        isPartial: value === 0 ? true : false,
        orderId: basket.orderId,
        currency: currency.code,
        paymentType: value !== 2 ? 1 : value,
      });
    } else {
      setCreditCardData({
        isPartial: false,
        orderId: basket.orderId,
        currency: currency.code,
        paymentType: value,
      });
    }
  };

  const handleCloseModals = () => {
    if (handleError === '') {
      if (status === 'success') {
        navigate('/congratulations', {
          state: { createPackage: true, isBuying: true },
        });
      } else if (status === 'fail') {
        navigate(findMyPath('order-basket'));
      } else if (payStatus && buyingMethod === 2) {
        navigate('/congratulations', {
          state: { createPackage: true, isBuying: true },
        });
      }
      setBuyingMethod('');
      dispatch(Actions.paymentActions.resetStateAction());
    }
    setHandleError('');
    setShowLoader(false);
  };
  let totalSum = 0;
  const [selectedInstallment, setSelectedInstallment] = useState(1);

  if (installmentData.length > 0) {
    const selectedInstallmentData = installmentData.find(
      (installment) => installment.installment === selectedInstallment
    );

    totalSum = selectedInstallmentData
      ? selectedInstallmentData.totalAmount
      : 0;
  } else {
    totalSum =
      basket?.unpayedOrderItemList?.reduce(
        (sum, item) => sum + item.netAmount,
        0
      ) || 0;
  }

  const headers = [
    { key: '', label: '' },
    {
      key: 'installment',
      label:
        buyingMethod === 1
          ? t('buy.installment_table_header_installment')
          : t('buy.installment_table_header_expiry'),
    },
    {
      key: 'value',
      label: t('buy.installment_table_header_month'),
    },
    {
      key: 'totalAmount',
      label: t('buy.installment_total_amount'),
    },
  ];

  useEffect(() => {
    if (installment?.installmentArray?.length > 0 && isSuccess) {
      setInstallmentData(installment?.installmentArray);
    }
  }, [installment, isSuccess]);

  const installmentTableContent = (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{t('buy.installment_table')}</h5>
        <BasicTable
          data={installmentData}
          headers={headers}
          labelProp={''}
          inputRadio
          labelFunc={(val) => {
            setSelectedInstallment(val);
            setCreditCardData({
              ...creditCardData,
              installmentCount: buyingMethod === 1 && val === 1 ? 0 : val,
              cardName: installment?.cardName,
              country: installment?.country,
              bank: installment?.bank,
            });
          }}
        />
      </div>
    </div>
  );

  const onBlur = (e) => {
    e.preventDefault();
    const tckn = billingData?.tckn;
    const taxtOrTckn = billingData?.taxtOrTckn;
    if (taxtOrTckn && taxtOrTckn.length !== 11) {
      setCheckID(<span className="text-danger">{t('buy.id_check')}</span>);
    } else {
      setCheckID(undefined);
    }
  };

  // TODO bu kısım için bir validasyon yapılacak.
  const handleBillingData = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (id === 'tckn' || id === 'taxOrTckn') {
      const intValue = Math.abs(parseInt(value.replace(/\D/g, ''), 10));

      if (!isNaN(intValue) && intValue.toString().length <= 11) {
        setCheckID(false);
        setBillingData({ ...billingData, [id]: intValue });
      } else {
        setCheckID(true);
      }
    } else {
      if (value !== '') {
        setBillingData({ ...billingData, [id]: value });
      } else {
        setBillingData((prevState) => {
          const { [id]: _, ...rest } = prevState;
          return rest;
        });
      }
    }
  };
  useEffect(() => {
    if (userInfo?.firstName !== undefined && userInfo?.firstName !== '') {
      setBillingData({
        ...billingData,
        fullName: `${userInfo?.firstName} ${userInfo?.lastName}`,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    setName('');
    setNumber('');
    setExpiry('');
    setCvc('');
    setIssuer('');
  }, [buyingMethod]);

  useEffect(() => {
    if (!isSuccess && isSuccess !== '' && handleError === '') {
      setHandleError(t('buy.buy_button'));
    } else if (isSuccess) {
      setShowLoader(false);
    }
  }, [isSuccess]);

  const [checkURL, setCheckURL] = useState(true);
  function isURL(str) {
    const urlPattern =
      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return urlPattern.test(str);
  }
  useEffect(() => {
    if (callBackContent !== '') {
      if (isURL(callBackContent)) {
        setCheckURL(true);
        window.location.href = callBackContent;
      } else {
        setCheckURL(false);
      }
    }
  }, [callBackContent]);

  const text = t('buy.payment_policy_and_membership_policy');
  const parts = text.split(
    /(\{\{payment_policy\}\}|\{\{membership_policy\}\})/
  );

  const content = checkURL ? (
    <div className="container-fluid">
      <div className="d-flex container px-md-5  py-3  align-items-center justify-content-evenly">
        {
          <>
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.selection')}
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Custom}
            />
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.summarize')}
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Custom}
            />
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.pay')}
              textMarginLeft={0}
              lastItem
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Success}
            />
          </>
        }
      </div>
      <div className="d-inline-block py-2  ">
        <Button
          type={ButtonType.Successbg}
          icon={ButtonIcon.ArrowLeft}
          iconColor={TextColors.Light}
          bold
          onClick={goBack}
        />
      </div>

      <div className="row">
        <div className="col-12">
          <Card
            cardRounded
            body={
              <>
                <div className="row mt-lg-4 ">
                  <div className="col-lg-7 pt-4">
                    <div className="col m-lg-5  text-center">
                      <h3 className="text-dark">
                        <strong>{t('buy.bill_details')}</strong>
                      </h3>
                    </div>
                    <div className="d-flex justify-content-start">
                      <span>{t('buy.bill_info')}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-start">
                      <div className="col-md-4">
                        <Input
                          key={'individual.radio'}
                          type={InputType.Radio}
                          className={InputSize.Medium}
                          checked={billOptions === 'individual'}
                          id={'individual'}
                          labelValue={t('buy.individual_invoice')}
                          placeholder={t('buy.individual_invoice')}
                          labelOnRight
                          onClick={() => setBillOptions('individual')}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          key={'corporate.radio'}
                          type={InputType.Radio}
                          className={InputSize.Medium}
                          checked={billOptions === 'corporate'}
                          id={'corporate'}
                          labelValue={t('buy.corporate_invoice')}
                          placeholder={t('buy.corporate_invoice')}
                          labelOnRight
                          onClick={() => setBillOptions('corporate')}
                        />
                      </div>
                    </div>
                    {billOptions === 'individual' ? (
                      <div className="row my-lg-2">
                        <div className="col-md-6">
                          <Input
                            key={'fullname2'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'fullName'}
                            placeholder={t('buy.full_name')}
                            labelValue={t('buy.full_name')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                            value={billingData.fullName || ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            key={'tckn'}
                            type={InputType.Number}
                            className={InputSize.Medium}
                            id={'tckn'}
                            placeholder={t('buy.tckn')}
                            labelValue={t('buy.tckn')}
                            labelFontType={LabelTextType.Bold}
                            onChange={handleBillingData}
                            error={checkID}
                            onBlur={onBlur}
                            value={billingData.tckn || ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            key={'city2'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'city'}
                            placeholder={t('buy.city')}
                            labelValue={t('buy.city')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                            value={billingData.city || ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            key={'county2'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'county'}
                            placeholder={t('buy.county')}
                            labelValue={t('buy.county')}
                            labelFontType={LabelTextType.Bold}
                            required
                            value={billingData.county || ''}
                            onChange={handleBillingData}
                          />
                        </div>
                        {/* <div className="col-md-6">
                          <Input
                            type={InputType.Date}
                            className={InputSize.Medium}
                            id={'birthDate'}
                            placeholder={t('buy.birthDate')}
                            labelValue={t('buy.birthDate')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                          />
                        </div> */}
                        <div className="col-md-6">
                          <Input
                            key={'address2'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'address'}
                            placeholder={t('buy.address')}
                            labelValue={t('buy.address')}
                            labelFontType={LabelTextType.Bold}
                            textArea={{ row: 3, col: 12 }}
                            onChange={handleBillingData}
                            value={billingData.address || ''}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row my-lg-2">
                        <div className="col-md-6">
                          <Input
                            key={'taxOrTckn'}
                            type={InputType.Number}
                            className={InputSize.Medium}
                            id={'taxOrTckn'}
                            placeholder={t('buy.tax_or_tckn')}
                            labelValue={t('buy.tax_or_tckn')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                            error={checkID}
                            onBlur={onBlur}
                            value={billingData.taxOrTckn || ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            key={'taxAdministration'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'taxAdministration'}
                            placeholder={t('buy.tax_administration')}
                            labelValue={t('buy.tax_administration')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                            value={billingData.taxAdministration || ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            key={'fullname1'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'fullName'}
                            placeholder={t('buy.company_or_personal_name')}
                            labelValue={t('buy.full_name')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                            value={billingData.fullName || ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            key={'city'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'city'}
                            placeholder={t('buy.city')}
                            labelValue={t('buy.city')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                            value={billingData.city || ''}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            key={'county'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'county'}
                            placeholder={t('buy.county')}
                            labelValue={t('buy.county')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                            value={billingData.county || ''}
                          />
                        </div>
                        {/* <div className="col-md-6">
                          <Input
                            type={InputType.Date}
                            className={InputSize.Medium}
                            id={'birthDate'}
                            placeholder={t('buy.birthDate')}
                            labelValue={t('buy.birthDate')}
                            labelFontType={LabelTextType.Bold}
                            required
                            onChange={handleBillingData}
                          />
                        </div> */}
                        <div className="col-md-6">
                          <Input
                            key={'address'}
                            type={InputType.Text}
                            className={InputSize.Medium}
                            id={'address'}
                            placeholder={t('buy.address')}
                            labelValue={t('buy.address')}
                            labelFontType={LabelTextType.Bold}
                            textArea={{ row: 3, col: 12 }}
                            required
                            onChange={handleBillingData}
                            value={billingData.address || ''}
                          />
                        </div>
                      </div>
                    )}
                    <div className=" d-sm-block d-lg-none bg-light">
                      <div className="col ms-5 me-5 mt-lg-5 ">
                        <h4 className="text-dark ms-4 pt-2 text-center">
                          <strong>{t('buy.order_details')}</strong>
                        </h4>
                      </div>
                      {basket?.unpayedOrderItemList?.length === 0 ? (
                        <div className="h1 text-center my-5 ">
                          {t('buy.empty_basket')}
                        </div>
                      ) : (
                        <div>
                          {basket?.unpayedOrderItemList?.map((item, index) => {
                            return (
                              <div key={index} className="col mt-lg-5 ">
                                <Card
                                  body={
                                    <div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        {/* <div className="d-flex small-2 medium-2 large-2 bg-t-turuncu columns text-center">
                                    <FontAwesomeIcon
                                      icon={faSearch}
                                      className="d-inline p-4 align-self-center"
                                    ></FontAwesomeIcon>
                                  </div> */}
                                        <div className="col ms-2 align-middle fs-6 ">
                                          <p>{item.orderItemDescription}</p>
                                        </div>
                                        <div className="col-2  align-middle fs-6 ">
                                          <p>{`${item.netAmount} ${currency.symbol}`}</p>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}

                      <div
                        className="border-top d-flex justify-content-between align-items-center border-bottom py-2 "
                        style={{ fontSize: '2em' }}
                      >
                        {t('onlineGroup.total')} :{' '}
                        <strong>
                          {totalSum} {currency.symbol}
                        </strong>
                      </div>
                    </div>

                    <div className="row mt-lg-4 ">
                      <div className="col-md-12 pt-4">
                        <div className="col m-lg-5  text-center">
                          <h3 className="text-dark">
                            <strong>{t('buy.payment_method')}</strong>
                          </h3>
                        </div>
                        <div className="opacity-50 my-1">
                          {t('buy.credit_card_or_other_payment_methods')}
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input border border-3"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value={1}
                            onClick={setValue}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            {t('buy.pay_with_credit_card')}
                          </label>
                        </div>
                        <span className="opacity-50">
                          {t('buy.credit_card_information_will_be_taken')}
                        </span>
                        {buyingMethod === 1 && (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group my-2">
                              <input
                                type="tel"
                                name="number"
                                className="form-control"
                                placeholder={t('buy.card_number')}
                                pattern="[\d| ]{16,22}"
                                required
                                value={number}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                              />
                            </div>
                            <div className="form-group my-2">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder={t('common.name')}
                                required
                                value={name}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                              />
                            </div>
                            <div className="row my-2">
                              <div className="col-6">
                                <input
                                  type="tel"
                                  name="expiry"
                                  className="form-control"
                                  placeholder={t('buy.expiry_date')}
                                  pattern="\d\d/\d\d"
                                  required
                                  value={expiry}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type="tel"
                                  name="cvc"
                                  className="form-control"
                                  placeholder="CVC"
                                  pattern="\d{3,4}"
                                  required
                                  value={cvc}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                />
                              </div>
                            </div>
                            <div className="d-sm-block d-lg-none bg-light">
                              <div className="py-3">
                                {/* <Card1
                              number={number}
                              name={name}
                              expiry={expiry}
                              cvc={cvc}
                              focused={focused}
                              callback={handleCallback}
                            /> */}
                              </div>
                            </div>
                          </form>
                        )}
                        {/* {handleErrorMessage !== '' &&
                          creditCardData.installment === undefined && (
                            <div className="text-center mt-1">
                              <span className="text-danger">
                                {handleErrorMessage}
                              </span>
                            </div>
                          )} */}
                        {/* TODO Partial future */}
                        {buyingMethod === 1 &&
                          installmentData?.length > 0 &&
                          installmentTableContent}
                        {/* <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault13"
                            value={0}
                            onClick={setValue}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault3"
                          >
                            {t('buy.partial_pay')}
                          </label>
                        </div>
                        <span className="opacity-50">
                          {t('buy.partial_info')}
                        </span> */}
                        {buyingMethod === 0 && (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group my-2">
                              <input
                                type="tel"
                                name="number"
                                className="form-control"
                                placeholder={t('buy.card_number')}
                                pattern="[\d| ]{16,22}"
                                required
                                value={number}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                              />
                            </div>
                            <div className="form-group my-2">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder={t('common.name')}
                                required
                                value={name}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                              />
                            </div>
                            <div className="row my-2">
                              <div className="col-6">
                                <input
                                  type="tel"
                                  name="expiry"
                                  className="form-control"
                                  placeholder={t('buy.expiry_date')}
                                  pattern="\d\d/\d\d"
                                  required
                                  value={expiry}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type="tel"
                                  name="cvc"
                                  className="form-control"
                                  placeholder="CVC"
                                  pattern="\d{3,4}"
                                  required
                                  value={cvc}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                />
                              </div>
                            </div>
                            <div className="d-sm-block d-lg-none bg-light">
                              <div className="py-3">
                                {/* <Card1
                              number={number}
                              name={name}
                              expiry={expiry}
                              cvc={cvc}
                              focused={focused}
                              callback={handleCallback}
                            /> */}
                              </div>
                            </div>
                          </form>
                        )}
                        {/* {handleErrorMessage !== '' &&
                          creditCardData.installment === undefined && (
                            <div className="text-center mt-1">
                              <span className="text-danger">
                                {handleErrorMessage}
                              </span>
                            </div>
                          )} */}
                        {/* {buyingMethod === 0 &&
                          installmentData?.length > 0 &&
                          installmentTableContent} */}
                        <div className="form-check">
                          <input
                            className="form-check-input border border-3"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            onClick={setValue}
                            value={2}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            {t('buy.pay_with_transfer')}
                          </label>
                        </div>
                        <span className="opacity-50">
                          {t('buy.after_registering_your_order')}
                        </span>
                        {buyingMethod === 2 && (
                          <div className="modal-body my-5">
                            {cardArray
                              .filter((val) =>
                                basket?.unpayedOrderItemList[0]?.userArea ===
                                'Cafe'
                                  ? val?.id !== 3
                                  : val?.id === 3
                              )
                              .map((card, i) => (
                                <div
                                  className="row"
                                  key={card[cardPropertyNames[0]]}
                                >
                                  <div className="col-sm-9 col-12">
                                    <Card
                                      cardTitle={{
                                        title: (
                                          <div className="col-sm-3 col-md-6 col-lg-12 d-flex align-items-center">
                                            <strong className="ms-3">
                                              {card[cardPropertyNames[1]]}
                                            </strong>
                                            <img
                                              className="ms-3"
                                              src={card[cardPropertyNames[4]]}
                                              alt={card[cardPropertyNames[4]]}
                                              width={100}
                                            />
                                          </div>
                                        ),
                                      }}
                                      shadow={true}
                                      cardRounded={true}
                                      body={
                                        <>
                                          <div
                                            value={card[cardPropertyNames[2]]}
                                            className="row ms-2 mt-2 text-muted fst-italic"
                                          >
                                            <p className="col-8">
                                              {card[cardPropertyNames[2]]}
                                            </p>
                                            <div className="col-4 d-flex justify-content-end">
                                              <Button
                                                textColor={TextColors.Light}
                                                text={
                                                  buttonText[i] || t('buy.copy')
                                                }
                                                disabled={
                                                  buttonText[i] !== undefined
                                                    ? true
                                                    : false
                                                }
                                                bold
                                                type={
                                                  buttonText[i] !== undefined
                                                    ? ButtonType.Secondary
                                                    : ButtonType.Info
                                                }
                                                buttonCol={12}
                                                onClick={() => {
                                                  handleButtonClick(
                                                    card[cardPropertyNames[2]],
                                                    i
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {cardPropertyNames[3] ? (
                                            <div className="row ms-3  text-muted fst-italic ">
                                              {card[cardPropertyNames[3]]}
                                            </div>
                                          ) : undefined}
                                        </>
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                      {/* {handleErrorMessage !== '' &&
                        Object.keys(creditCardData).length > 2 && (
                          <div className="text-center mt-1">
                            <span className="text-danger">
                              {handleErrorMessage}
                            </span>
                          </div>
                        )} */}
                      <div className="form-check d-flex align-items-center m-2">
                        <input
                          className="form-check-input border border-3"
                          type="checkbox"
                          id="marketAndCommercial"
                          checked={paymentAndMemberShipChecked}
                          onChange={handlePaymentandMemberShipCheckboxChange}
                        />
                        <label className="form-check-label text-gray-700 ps-2">
                          {parts.map((part, index) => {
                            if (index === 0) {
                              return <span key={index}>{part}</span>;
                            }
                            const key = part.includes('payment_policy')
                              ? 'payment_policy'
                              : 'membership_policy';
                            return (
                              <span key={index}>
                                {part.startsWith('{{') ? (
                                  <span
                                    onClick={() => {
                                      if (key === 'payment_policy') {
                                        setpaymentModalVisible(
                                          !paymentModalVisible
                                        );
                                      } else {
                                        setMemberShipModalVisible(
                                          !memberShipModalVisible
                                        );
                                      }
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {key === 'payment_policy'
                                      ? t('buy.payment_policy')
                                      : t('buy.membership_policy')}
                                  </span>
                                ) : (
                                  <span>{part}</span>
                                )}
                              </span>
                            );
                          })}
                        </label>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button
                          textColor={TextColors.Light}
                          text={t('button.buy')}
                          bold
                          type={
                            !paymentAndMemberShipChecked
                              ? ButtonType.Secondary
                              : showLoader
                              ? ButtonType.Secondary
                              : ButtonType.Successbg
                          }
                          buttonCol={4}
                          onClick={handleSubmit}
                          loading={
                            !paymentAndMemberShipChecked
                              ? undefined
                              : showLoader
                          }
                          disabled={!paymentAndMemberShipChecked}
                        />
                      </div>
                      <ToastWithParams
                        text={handleError}
                        content={message}
                        onClose={handleCloseModals}
                        autoClose={false}
                        fail={!payStatus}
                        success={payStatus && buyingMethod !== 1}
                      />
                    </div>
                    <div
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                      }}
                    ></div>
                  </div>
                  <div className="col-lg-5 mt-3 d-none d-lg-block borderLeft">
                    <div className="col ms-5 me-5 mt-5">
                      <h4 className="text-dark ms-4 pt-2 text-center">
                        <strong>{t('buy.order_details')}</strong>
                      </h4>
                    </div>
                    {basket?.unpayedOrderItemList?.length === 0 ? (
                      <div className="h1 text-center my-5 ">
                        {t('buy.empty_basket')}
                      </div>
                    ) : (
                      <div>
                        {basket?.unpayedOrderItemList?.map((item, index) => {
                          return (
                            <div key={index} className="col mt-lg-5 ">
                              <Card
                                body={
                                  <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      {/* <div className="d-flex small-2 medium-2 large-2 bg-t-turuncu columns text-center">
                                    <FontAwesomeIcon
                                      icon={faSearch}
                                      className="d-inline p-4 align-self-center"
                                    ></FontAwesomeIcon>
                                  </div> */}
                                      <div className="col ms-2 align-middle fs-6 ">
                                        <p>{item.orderItemDescription}</p>
                                      </div>
                                      <div className="col-2  align-middle fs-6 ">
                                        <p>{`${item.netAmount} ${currency.symbol}`}</p>
                                      </div>
                                    </div>
                                  </div>
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div
                      className="border-top d-flex justify-content-between align-items-center border-bottom py-2 "
                      style={{ fontSize: '2em' }}
                    >
                      {t('onlineGroup.total')} :{' '}
                      <strong>
                        {totalSum} {currency.symbol}
                      </strong>
                    </div>
                    <div className="py-3">
                      {/* <Card1
                    number={number}
                    name={name}
                    expiry={expiry}
                    cvc={cvc}
                    focused={focused}
                    callback={handleCallback}
                  /> */}
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      <Modal
        isVisible={paymentModalVisible}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        content={<PaymentPolicy />}
        footer={
          <Button
            type={ButtonType.Successbg}
            textColor={TextColors.Light}
            bold
            buttonCol={3}
            text={t('button.accept')}
            onClick={handlePaymentCheckboxChange}
          />
        }
        onClose={() => {
          setpaymentModalVisible(false);
        }}
      />
      <ModalInsideComponent
        ref={phoneConfirmationModal}
        title={ModalTitles.phoneConfirmation}
        child={
          phoneConfirmationInfo === '' ? (
            <PhoneConfirmation
              phoneConfirmInfo={(val) => setPhoneConfirmationInfo(val)}
            />
          ) : (
            phoneConfirmationInfo
          )
        }
        onClose={() => {
          if (phoneConfirmationInfo === '') {
            setShowLoader(false);
          } else {
            handleSubmit();
          }
          phoneConfirmationModal.current?.close();
        }}
      />
      <Modal
        isVisible={memberShipModalVisible}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        content={<MembershipPolicy />}
        footer={
          <Button
            type={ButtonType.Successbg}
            textColor={TextColors.Light}
            bold
            buttonCol={3}
            text={t('button.accept')}
            onClick={handleMemberShipCheckboxChange}
          />
        }
        onClose={() => {
          setMemberShipModalVisible(false);
        }}
      />
    </div>
  ) : (
    <Result3D htmlContent={callBackContent} />
  );
  return useLoader(getBoard) ? <Loader /> : content;
};
export default Buy;
