import {
  createSpeakerAvailability,
  updateSpeakerAvailability,
  removeSpeakerAvailability,
  getAllSpeakerAvailabilities,
  getSelectedSpeakerDates,
} from '../../../services/apiServices/speakerAvailabilityServices';
import ActionTypes from './actionTypes';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await createSpeakerAvailability(createData).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};

const createReducer = () => {
  return { type: ActionTypes.speakerAvailability.CREATE_ACTION_AVAILABILITY };
};

const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await updateSpeakerAvailability(updateData, id).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(updateReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};

const updateReducer = () => {
  return { type: ActionTypes.speakerAvailability.UPDATE_ACTION_AVAILABILITY };
};

const removeAction = (id) => {
  return async () => {
    await removeSpeakerAvailability(id);
  };
};

const getAllAction = (requestType) => {
  return async (dispatch) => {
    await getAllSpeakerAvailabilities(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};

const getAllReducer = (data) => {
  return {
    type: ActionTypes.speakerAvailability.GETALL_ACTION_AVAILABILITY,
    payload: { data },
  };
};

const holdValue = (data) => {
  return {
    type: ActionTypes.speakerAvailability.HOLD_VALUE_AVAILABILITY,
    payload: data,
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.speakerAvailability.HANDLE_ERROR_AVAILABILITY,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.speakerAvailability.HandleValidation,
    payload: data,
  };
};
const getSelectedAvaibilitiesAction = (filter) => {
  return async (dispatch) => {
    await getSelectedSpeakerDates(filter).then(async (res) => {
      let response = res.data.data;
      if (res?.data?.code === 200) {
        await dispatch(getSelectedAvaibilitiesReducer(response));
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const getSelectedAvaibilitiesReducer = (data) => {
  return {
    type: ActionTypes.speakerAvailability.GET_SELECTED_AVAILABILITY,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};

const speakerAvailabilityActions = {
  createAction,
  createReducer,
  updateAction,
  updateReducer,
  removeAction,
  getAllAction,
  getAllReducer,
  holdValue,
  handleError,
  getSelectedAvaibilitiesAction,
  cleanState
};

export default speakerAvailabilityActions;
