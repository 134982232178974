import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import Button from '../../../../components/Common/Button/Button';
import Card from '../../../../components/Common/Card/Card';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../../utils/ComponentEnums';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const timesPerWeekData = useSelector((e) => e.timesPerWeek.timesPerWeek);
  const timesPerWeekInfo = useSelector((e) => e.timesPerWeek.getById);
  const isSuccess = useSelector((e) => e.timesPerWeek.isSuccess);
  const goBack = () => navigate(-1);
  const timesPerWeekId = location?.state?.id;
  const [updateData, setUpdateData] = useState({});
  const checkData = Object.keys(updateData).length === 0;
  const redirect = '/times-per-week-list';

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...timesPerWeekData, [id]: value });
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...timesPerWeekData });
    } else {
      dispatch(Actions.timesPerWeekActions.holdValue({ ...updateData }));
    }
  };
  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...timesPerWeekData });
    }
  };
  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.timesPerWeekActions.updateAction(updateData, timesPerWeekId)
    );
  };
  async function FetchById() {
    await dispatch(Actions.timesPerWeekActions.getByIdAction(timesPerWeekId));
  }

  useEffect(() => {
    FetchById();
    if (isSuccess) {
      navigate(redirect);
      setUpdateData({});
      dispatch(Actions.timesPerWeekActions.handleError(''));
    }
  }, [isSuccess]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-5 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('timesPerWeek.update_times_per_week_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'perWeek'}
                      inputType={InputType.Number}
                      labelText={t('timesPerWeek.per_week')}
                      inputValue={
                        checkData
                          ? timesPerWeekInfo.perWeek
                          : updateData.perWeek
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'weekOptionSessionValue'}
                      inputType={InputType.Number}
                      labelText={t('timesPerWeek.session_value')}
                      inputValue={
                        checkData
                          ? timesPerWeekInfo.weekOptionSessionValue
                          : updateData.weekOptionSessionValue
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(updateData).length <= 1
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.common_submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={
                        Object.keys(updateData).length <= 1 ? true : false
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.timesPerWeek.update,
    content
  );
};
export default Update;
