import React, { useRef } from 'react';

const MembershipPolicy = ({ onScrollToBottom }) => {
  const modalContentRef = useRef();

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
    const isScrolledToBottom = scrollTop + clientHeight + 100 >= scrollHeight;
    if (isScrolledToBottom && onScrollToBottom) {
      onScrollToBottom();
    }
  };

  const containerStyle = {
    maxHeight: 'calc(100vh - 20vh)',
    height: '500px',
    overflowY: 'auto',
  };

  return (
    <div
      onScroll={handleScroll}
      ref={modalContentRef}
      className="container-fluid"
      style={containerStyle}
    >
      <div className="row">
        <div className="col-lg-12 mb-4">
          <div className="card shadow mb-4">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <main>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <p align="center">
                              <strong>ÜYELİK SÖZLEŞMESİ</strong>
                            </p>
                            <p align="center">
                              <strong> </strong>
                            </p>
                            <p>
                              <strong>1. </strong>
                              <strong>Taraflar</strong>
                            </p>
                            <p>
                              a) www.meet2talk.online internet sitesinin
                              faaliyetlerini yürüten Rasimpaşa Mahallesi Başakçı
                              Sokak no:7 Kadıköy/İstanbul adresinde mukim
                              Meet2Talk Cafe (Bundan böyle Meet2Talk olarak
                              anılacaktır).
                            </p>
                            <p>
                              b) www.meet2talk.online internet sitesine üye olan
                              internet kullanıcısı (“Üye”), belirtilen koşulları
                              kabul etmiş sayılacaktır.
                            </p>

                            <p>
                              <strong>2. </strong>
                              <strong> Sözleşmenin Konusu</strong>
                            </p>
                            <p>
                              İşbu Sözleşme’nin konusu Meet2Talk’nın sahip
                              olduğu internet sitesi www.meet2talk.online ‘dan
                              üyenin faydalanma şartlarının belirlenmesidir.
                            </p>
                            <p>
                              <strong>3. </strong>
                              <strong>Tarafların Hak ve Yükümlülükleri</strong>
                            </p>
                            <p>
                              <strong>3.1. </strong>
                              Üye, www.meet2talk.online internet sitesine üye
                              olurken verdiği kişisel ve diğer sair bilgilerin
                              kanunlar önünde doğru olduğunu, Meet2Talk’ı bu
                              bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı
                              tüm zararları aynen ve derhal tazmin edeceğini
                              beyan ve taahhüt eder.
                            </p>
                            <p>
                              <strong>3.2. </strong>
                              Üye, Meet2Talk tarafından kendisine verilmiş olan
                              şifreyi başka kişi ya da kuruluşlara veremez,
                              üyenin söz konusu şifreyi kullanma hakkı bizzat
                              kendisine aittir. Bu sebeple doğabilecek tüm
                              sorumluluk ile üçüncü kişiler veya yetkili
                              merciler tarafından Meet2Talk’a karşı ileri
                              sürülebilecek tüm iddia ve taleplere karşı,
                              Meet2Talk’ın söz konusu izinsiz kullanımdan
                              kaynaklanan her türlü tazminat ve sair talep hakkı
                              saklıdır.
                            </p>
                            <p>
                              <strong>3.3. </strong>
                              Üye, www.meet2talk.online internet sitesini
                              kullanırken yasal mevzuat hükümlerine riayet
                              etmeyi ve bunları ihlal etmemeyi baştan kabul ve
                              taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve
                              cezai yükümlülükler tamamen ve münhasıran üyeyi
                              bağlayacaktır.
                            </p>
                            <p>
                              <strong>3.4. </strong>
                              Üye, www.meet2talk.online internet sitesini hiçbir
                              şekilde kamu düzenini bozucu, genel ahlaka aykırı,
                              başkalarını rahatsız ve taciz edici şekilde,
                              yasalara aykırı bir amaç için, başkalarının fikri
                              ve telif haklarına tecavüz edecek şekilde
                              kullanamaz. Ayrıca, üye başkalarının hizmetleri
                              kullanmasını önleyici veya zorlaştırıcı faaliyet
                              (spam, virus, truva atı, vb.) ve işlemlerde
                              bulunamaz.
                            </p>
                            <p>
                              <strong>3.5. </strong>
                              www.meet2talk.online internet sitesinde üyeler
                              tarafından beyan edilen, yazılan, kullanılan fikir
                              ve düşünceler, tamamen üyelerin kendi kişisel
                              görüşleridir ve görüş sahibini bağlar. Bu görüş ve
                              düşüncelerin Meet2Talk ile hiçbir ilgi ve
                              bağlantısı yoktur. Meet2Talk’ın üyenin beyan
                              edeceği fikir ve görüşler nedeniyle üçüncü
                              kişilerin uğrayabileceği zararlardan ve üçüncü
                              kişilerin beyan edeceği fikir ve görüşler
                              nedeniyle üyenin uğrayabileceği zararlardan dolayı
                              herhangi bir sorumluluğu bulunmamaktadır.
                            </p>
                            <p>
                              <strong>3.6. </strong>
                              Meet2Talk, üye verilerinin yetkisiz kişilerce
                              okunmasından ve üye yazılım ve verilerine
                              gelebilecek zararlardan dolayı sorumlu
                              olmayacaktır. Üye, www.meet2talk.online internet
                              sitesinin kullanılmasından dolayı uğrayabileceği
                              herhangi bir zarar yüzünden Meet2Talk’tan tazminat
                              talep etmemeyi peşinen kabul etmiştir.
                            </p>
                            <p>
                              <strong>3.7. </strong>
                              Üye, diğer internet kullanıcılarının yazılımlarına
                              ve verilerine izinsiz olarak ulaşmamayı veya
                              bunları kullanmamayı kabul etmiştir. Aksi
                              takdirde, bundan doğacak hukuki ve cezai
                              sorumluluklar tamamen üyeye aittir.
                            </p>
                            <p>
                              <strong>3.8. </strong>
                              İşbu üyelik sözleşmesi içerisinde sayılan
                              maddelerden bir ya da birkaçını ihlal eden üye
                              işbu ihlal nedeniyle cezai ve hukuki olarak şahsen
                              sorumlu olup, Meet2Talk’ı bu ihlallerin hukuki ve
                              cezai sonuçlarından ari tutacaktır. Ayrıca; işbu
                              ihlal nedeniyle, olayın hukuk alanına intikal
                              ettirilmesi halinde, Meet2Talk’ın üyeye karşı
                              üyelik sözleşmesine uyulmamasından dolayı tazminat
                              talebinde bulunma hakkı saklıdır.
                            </p>
                            <p>
                              <strong>3.9. </strong>
                              Meet2Talk’ın her zaman tek taraflı olarak
                              gerektiğinde üyenin üyeliğini silme, müşteriye ait
                              dosya, belge ve bilgileri silme hakkı vardır. Üye
                              işbu tasarrufu önceden kabul eder. Bu durumda,
                              Meet2Talk’ın hiçbir sorumluluğu yoktur.
                            </p>
                            <p>
                              <strong>3.10. </strong>
                              www.meet2talk.online internet sitesi yazılım ve
                              tasarımı Meet2Talk mülkiyetinde olup, bunlara
                              ilişkin telif hakkı ve/veya diğer fikri mülkiyet
                              hakları ilgili kanunlarca korunmakta olup, bunlar
                              üye tarafından izinsiz kullanılamaz, iktisap
                              edilemez ve değiştirilemez. Bu web sitesinde adı
                              geçen başkaca şirketler ve ürünleri sahiplerinin
                              ticari markalarıdır ve ayrıca fikri mülkiyet
                              hakları kapsamında korunmaktadır.
                            </p>
                            <p>
                              <strong>3.11. </strong>
                              Meet2Talk tarafından www.meet2talk.online internet
                              sitesinin iyileştirilmesi, geliştirilmesine
                              yönelik olarak ve/veya yasal mevzuat çerçevesinde
                              siteye erişmek için kullanılan İnternet servis
                              sağlayıcısının adı ve Internet Protokol (IP)
                              adresi, Siteye erişilen tarih ve saat, sitede
                              bulunulan sırada erişilen sayfalar ve siteye
                              doğrudan bağlanılmasını sağlayan Web sitesinin
                              Internet adresi gibi birtakım bilgiler
                              toplanabilir.
                            </p>
                            <p>
                              <strong>3.12. </strong>
                              Meet2Talk, üyenin kişisel bilgilerini yasal bir
                              zorunluluk olarak istendiğinde veya (a) yasal
                              gereklere uygun hareket etmek veya Meet2Talk’a
                              tebliğ edilen yasal işlemlere uymak; (b)Meet2Talk
                              ve www.meet2talk.online web sitesi ailesinin
                              haklarını ve mülkiyetini korumak ve savunmak için
                              gerekli olduğuna iyi niyetle kanaat getirdiği
                              hallerde açıklayabilir.
                            </p>
                            <p>
                              <strong>3.13. </strong>
                              www.meet2talk.online web sitesinin virüs ve
                              benzeri amaçlı yazılımlardan arındırılmış olması
                              için mevcut imkanlar dahilinde tedbir alınmıştır.
                              Bunun yanında nihai güvenliğin sağlanması için
                              kullanıcının, kendi virüs koruma sistemini tedarik
                              etmesi ve gerekli korunmayı sağlaması
                              gerekmektedir. Bu bağlamda üye
                              www.meet2talk.online web sitesine girmesiyle,
                              kendi yazılım ve işletim sistemlerinde
                              oluşabilecek tüm hata ve bunların doğrudan ya da
                              dolaylı sonuçlarından kendisinin sorumlu olduğunu
                              kabul etmiş sayılır.
                            </p>
                            <p>
                              <strong>3.14. </strong>
                              www.meet2talk.online, sitenin içeriğini dilediği
                              zaman değiştirme, kullanıcılara sağlanan herhangi
                              bir hizmeti değiştirme ya da sona erdirme veya
                              www.meet2talk.online web sitesinde kayıtlı
                              kullanıcı bilgi ve verilerini silme hakkını saklı
                              tutar.
                            </p>
                            <p>
                              <strong>3.15. </strong>
                              Meet2Talk, üyelik sözleşmesinin koşullarını hiçbir
                              şekil ve surette ön ihbara ve/veya ihtara gerek
                              kalmaksızın her zaman değiştirebilir,
                              güncelleyebilir veya iptal edebilir. Değiştirilen,
                              güncellenen yada yürürlükten kaldırılan her hüküm
                              , yayın tarihinde tüm üyeler bakımından hüküm
                              ifade edecektir.
                            </p>
                            <p>
                              <strong>3.16. </strong>
                              Taraflar, Meet2Talk’a ait tüm bilgisayar
                              kayıtlarının tek ve gerçek münhasır delil olarak,
                              HUMK madde 287′ye uygun şekilde esas alınacağını
                              ve söz konusu kayıtların bir delil sözleşmesi
                              teşkil ettiği hususunu kabul ve beyan eder.
                            </p>
                            <p>
                              <strong>3.17. </strong>
                              Meet2Talk, iş bu üyelik sözleşmesi uyarınca,
                              üyelerinin kendisinde kayıtlı elektronik posta
                              adreslerine bilgilendirme e-postaları ve cep
                              telefonlarına bilgilendirme SMS’leri gönderme
                              yetkisine sahip olmakla beraber, üye işbu üyelik
                              sözleşmesini onaylamasıyla beraber bilgilendirme
                              maillerinin elektronik posta adresine ve
                              bilgilendirme SMS’lerinin cep telefonuna
                              gönderilmesini kabul etmiş sayılacaktır.
                            </p>
                            <p>
                              <strong>4. </strong>
                              <strong>Sözleşmenin Feshi</strong>
                            </p>
                            <p>
                              İşbu sözleşme üyenin üyeliğini iptal etmesi veya
                              Meet2Talk tarafından üyeliğinin iptal edilmesine
                              kadar yürürlükte kalacaktır. Meet2Talk üyenin
                              üyelik sözleşmesinin herhangi bir hükmünü ihlal
                              etmesi durumunda üyenin üyeliğini iptal ederek
                              sözleşmeyi tek taraflı olarak feshedebilecektir.
                            </p>

                            <p>
                              <strong>5. </strong>
                              <strong>İhtilaflerin Halli</strong>
                            </p>
                            <p>
                              İşbu sözleşmeye ilişkin ihtifallerde İstanbul
                              Mahkemeleri ve İcra Daireleri yetkilidir.
                            </p>
                            <p>
                              <strong>6. </strong>
                              <strong>Yürürlük</strong>
                            </p>
                            <p>
                              Üyenin, üyelik kaydı yapması üyenin üyelik
                              sözleşmesinde yer alan tüm maddeleri okuduğu ve
                              üyelik sözleşmesinde yer alan maddeleri kabul
                              ettiği anlamına gelir. İşbu Sözleşme üyenin üye
                              olması anında akdedilmiş ve karşılıklı olarak
                              yürürlüğe girmiştir.
                            </p>
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipPolicy;
