import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case ActionTypes.user.UPDATE_ACTION_USER:
      return {
        ...state,
        user: {},
        getById: [],
      };
    case ActionTypes.user.GETBYID_ACTION_USER:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.user.GETALL_USER:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.user.HOLD_VALUE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ActionTypes.user.HANDLE_ERROR_USER:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.user.HANDLE_VALIDATION_USER:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.user.SELF_UPDATE:
      return {
        ...state,
        selfUpdate: action.payload,
      };
    case ActionTypes.resetStore.RESET_STORE:
      return {
        ...state,
        getById: [],
      };
    default:
      return state;
  }
};

export default userReducer;
