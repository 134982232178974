import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import myArrowDown from '../../../assets/images/down.svg';
import useClickOutside from '../../../hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

export default function SubscriptionDropdown({
  propNames,
  rounded,
  bordered,
  id,
  options,
  handleChange,
  icon,
  title,
  arrowUp,
  backGroundAll = '',
  textColor = '',
  rightIcon,
  rightArrowUp,
  arrowSVG,
  isMulti,
  isFilter,
  noClickAble,
  selectedOptions,
  forCalendar,
  addIndex = false,
  keepStateUp = false,
}) {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : {});

  useEffect(() => {
    if (
      selectedOptions !== null &&
      selectedOptions !== undefined &&
      selectedOptions?.length > 0 &&
      (selectedValue.length === 0 || Object.keys(selectedValue).length === 0)
    ) {
      setSelectedValue(selectedOptions);
    }
  }, [selectedOptions]);

  let checkIcon = '';
  let checkRightArrow = '';
  arrowSVG
    ? (arrowSVG = <img className="img-fluid" src={myArrowDown} alt=""></img>)
    : (arrowSVG = undefined);
  clicked
    ? (checkIcon = <FontAwesomeIcon icon={faChevronUp} />)
    : (checkIcon = <FontAwesomeIcon icon={faChevronDown} />);
  clicked
    ? (checkRightArrow = <FontAwesomeIcon className="" icon={faSortUp} />)
    : (checkRightArrow = <FontAwesomeIcon className="" icon={faSortDown} />);
  let iconElement = null;
  let rightIconElement = null;
  let className = 'dropdown-menu';
  let setArrows = arrowUp ? checkIcon : null;
  let setRightArrows = rightArrowUp ? checkRightArrow : null;
  let buttonclassName = 'btn border-0 p-0 m-0';

  switch (icon) {
    case undefined:
      iconElement = null;
      break;
    case icon:
      iconElement = (
        <span className="ms-2">
          <FontAwesomeIcon icon={icon} />
        </span>
      );
      //iconElement = icon.prefix === "fas" ? <FontAwesomeIcon className='ms-2' icon={icon} /> : icon;
      break;
    default:
      break;
  }
  switch (rightIcon) {
    case undefined:
      rightIconElement = null;
      break;
    case rightIcon:
      rightIconElement = (
        <span>
          <FontAwesomeIcon icon={rightIcon} />
        </span>
      );
      break;
    default:
      break;
  }

  const removeOption = (option) => {
    return selectedValue.filter(
      (o) => o[propNames.value] !== option[propNames.value]
    );
  };

  const onItemClick = (option) => {
    if (keepStateUp) {
      setSelectedValue(option);
      return handleChange(option);
    } else if (isMulti) {
      let newValue;
      if (
        selectedValue.findIndex(
          (o) => o[propNames.value] === option[propNames.value]
        ) >= 0
      ) {
        newValue = removeOption(option);
        setSelectedValue(newValue);
      } else {
        newValue = [...selectedValue, option];
        setSelectedValue(newValue);
      }
    } else if (!isMulti) {
      if (selectedValue?.value === option.value) {
        setSelectedValue({});
      } else {
        setSelectedValue(option);
      }
    }
    handleChange(option);
  };
  const dropdownRef = useClickOutside(() => {
    if (clicked === true) {
      setClicked(false);
    }
  });

  const isSelected = (option) => {
    if (isMulti) {
      return (
        selectedValue?.filter(
          (o) => o[propNames.value] === option[propNames.value]
        ).length > 0
      );
    }
    if (!selectedValue) {
      return false;
    }
    return (
      selectedValue[propNames.value] === option[propNames.value] ||
      selectedValue?.[0]?.[propNames.value] === option[propNames.value]
    );
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const defaultView = screenWidth < 768;
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const debouncedTouchStart = _debounce((e, option) => {
    e.stopPropagation();
    if (isFilter) {
      onItemClick(option);
      setClicked(false);
    } else {
      handleChange(e.currentTarget || e.target);
      setClicked(false);
    }
  }, 200);

  return (
    <div ref={dropdownRef}>
      <div style={{ position: 'relative' }}>
        {isMulti && (
          <span className="position-absolute top-0 end-0 badge rounded-pill bg-danger">
            {selectedValue?.length || ''}
          </span>
        )}
      </div>
      <div
        key={`${id}_keys`}
        role={noClickAble ? 'none' : 'button'}
        id={id}
        data-bs-toggle={!noClickAble ? 'dropdown' : ''}
        data-bs-auto-close="outside"
        aria-expanded="false"
        onClick={() => setClicked(!clicked)}
        style={{ position: 'relative' }}
        className={`d-flex ${textColor} ${backGroundAll} ${
          bordered ? 'border border-1 border-custom rounded-2' : ''
        } ${
          rounded ? `rounded-${rounded}` : ''
        } justify-content-between px-3 py-md-2 align-items-center d-inline-grid align-items-center justify-content-center my-sm-4 my-1`}
      >
        {title && (
          <h6
            className={`fw-bold m-0 p-0  ${
              window.innerWidth < 578 ? 'fs-6' : 'fs-3'
            }`}
          >
            {title}
          </h6>
        )}
        <span
          className={`${
            (!noClickAble || noClickAble === undefined) && buttonclassName
          } d-sm-flex`}
        >
          <span className="me-sm-1 d-sm-none">
            {setArrows}
            {setRightArrows}
            {rightIconElement}
          </span>
        </span>
        <span className=" d-none d-sm-block">{arrowSVG}</span>
      </div>
      {options && (
        <div
          onMouseLeave={() => setClicked(false)}
          className={clicked ? className + ' show' : className + ' hide'}
          aria-labelledby={id}
          style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            margin: '0',
            boxShadow: '0px 10px 15px 5px rgba(0,0,0,0.1)',
          }}
        >
          {propNames !== undefined
            ? options?.map((option, index) => (
                <button
                  key={`${option.key}_${index}`}
                  className={`dropdown-item ${
                    isFilter && isSelected(option)
                      ? 'rounded-3 bg-primary text-white'
                      : ''
                  }`}
                  style={{ marginBottom: '3px' }}
                  title={
                    forCalendar
                      ? option[propNames.title]
                        ? option[propNames.title]
                        : option[propNames.name]
                      : undefined
                  }
                  id={forCalendar ? id : option[propNames.name]}
                  value={option[propNames.value]}
                  onTouchStart={(e) => {
                    if (defaultView) {
                      e.stopPropagation();
                      if (isMulti) {
                        if (isFilter) {
                          onItemClick(option);
                        } else {
                          handleChange(e.currentTarget);
                          setClicked(false);
                        }
                      } else {
                        debouncedTouchStart(e, option);
                      }
                    }
                  }}
                  onClick={(e) => {
                    if (!defaultView) {
                      e.stopPropagation();
                      if (isFilter) {
                        if (!isMulti) {
                          setClicked(false);
                        }
                        onItemClick(option);
                      } else {
                        handleChange(e.currentTarget);
                        setClicked(false);
                      }
                    }
                  }}
                >
                  {addIndex
                    ? `${index + 1}- ${t(`${option?.[propNames?.name]}`)}`
                    : t(`${option?.[propNames?.name]}`)}
                </button>
              ))
            : undefined}
        </div>
      )}
    </div>
  );
}
