import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const MultiselectCheckbox = ({ options, onChange, propNames }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(options);
  const toggle = (index) => {
    const newData = [...data];
    newData.splice(index, 1, {
      [propNames['id']]: data[index][propNames['id']],
      [propNames['type']]: data[index][propNames['type']],
      [propNames['checked']]: !data[index][propNames['checked']],
    });
    setData(newData);
    //onChange(newData.filter(x => x.checked));
    onChange(newData);
  };

  return (
    <>
      {data?.map((item, index) => (
        <label className="me-5" key={item[propNames['type']]}>
          <input
            key={item[propNames['type']]}
            className="me-2"
            readOnly
            type="checkbox"
            checked={item[propNames['checked']] || false}
            onClick={() => toggle(index)}
          />
          {t(`${item[propNames['type']]}`)}
        </label>
      ))}
    </>
  );
};
