import ActionTypes from './actionTypes';

const createAction = (packageData) => {
  return async (dispatch) => {
    dispatch(createReducer(packageData));
  };
};

const createReducer = (packageData) => {
  return {
    type: ActionTypes.userPackage.CREATE_ACTION_USERPACKAGE,
    payload: packageData,
  };
};

const completeAction = () => {
  return async (dispatch) => {
    dispatch(completeReducer());
  };
};

const completeReducer = () => {
  return { type: ActionTypes.userPackage.COMPLETE_ACTION_USERPACKAGE };
};

const userPackageActions = {
  createAction,
  completeAction,
};

export default userPackageActions;
