import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import Button from '../../../../components/Common/Button/Button';
import {
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../../utils/ComponentEnums';
import Card from '../../../../components/Common/Card/Card';
import Table from '../../../../components/Common/Table/Table';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../../components/Common/Dropdown/DropdownWithSearch';
import addDay from '../../../../hooks/useAddDay';
import filterTimeSet from '../../../../hooks/useFilterTimeSet';
import { useNavigate } from 'react-router-dom';
import { Entities, ModalTitles } from '../../../../utils/Enum';
import ModalInsideComponent from '../../../../components/Common/Modal/ModalInsideComponent';
import AttendeeNotes from '../../Attendee/AttendeeNotes';

const List = () => {
  const freeTrialNoteModal = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trialList = useSelector((e) => e.order.trialList);
  const locationList = useSelector((e) => e.location.getAll);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const managerList = useSelector((e) => e.manager.getAll);
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [attendeeInfo, setAttendeeInfo] = useState({});
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(Actions.managerActions.getAllAction('Dropdown'));
    dispatch(Actions.locationActions.getAllAction());
  }, []);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        dispatch(Actions.orderActions.upcomingTrialListAction(filterData));
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  useEffect(() => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) &&
      Object.keys(filterData).length > 0 &&
      dateChanged === false
    ) {
      dispatch(Actions.orderActions.upcomingTrialListAction(filterData));
    }
  }, [filterData]);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(
      null,
      true,
      false,
      true
    );

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    if (checkClaims(currentUserClaim, Claims.backofficeClaims.order.get)) {
      dispatch(Actions.orderActions.upcomingTrialListAction(filterData));
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    setDateChanged(true);
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      return rest;
    });
  };

  const editSupAction = (id) => {
    navigate('/attendee-update', { state: { id: id.attendeeId } });
  };

  const forwardAction = (val) => {
    navigate(`/meetings/${val.orderItemId}/${val.supId}`, {
      state: { id: val.orderItemId, supId: val.supId },
    });
  };

  const freeTrialNoteAction = async (val) => {
    await dispatch(
      Actions.attendeeActions.getNotesByMeetingAttendeeAction(val.attendeeId)
    ).then(() => {
      setAttendeeInfo({
        managerName: val.managerName,
        attendeeName: val.attendeeName,
        phone: val.phone,
      });
    });
  };

  useEffect(() => {
    if (attendeeInfo?.attendeeName !== undefined) {
      freeTrialNoteModal.current?.open();
    }
  }, [attendeeInfo]);

  useEffect(() => {
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  const headers = [
    { key: 'orderId', label: t('common.orderId') },
    { key: 'attendeeName', label: t('common.name') },
    { key: 'phone', label: t('common.phone') },
    { key: 'date', label: t('meeting.table_meetingDate') },
    { key: 'interval', label: t('meeting.table_interval') },
    { key: 'productType', label: t('orderItem.productType') },
    { key: 'locationName', label: t('location.location_name') },
    { key: 'speakerName', label: t('meeting.table_speaker') },
    { key: 'managerName', label: t('manager.filter_manager_name') },
    { key: 'languageLevel', label: t('meeting.table_level') },
    { key: 'learningPurpose', label: t('LearningPurpose') },
    { key: 'createdAt', label: t('common.created_at') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-center mb-0 mb-md-5">
        <HeadTags hSize={1} text={t('order.upcoming_trials')} strong />
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <Button
                    text={t('cardHeaderFilter.search')}
                    icon={faSearch}
                    type={
                      dateChanged ? ButtonType.Successbg : ButtonType.Secondary
                    }
                    iconColor={TextColors.Light}
                    textColor={TextColors.Light}
                    iconSide
                    bold
                    size={ButtonSize.Small}
                    buttonCol={!mobileView ? 1 : 3}
                    onClick={onSearch}
                    disabled={!dateChanged}
                  />
                </div>
                <div className="row my-2">
                  <div className="col-12 col-md-3">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-12 col-md-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_manager')}
                      options={managerList}
                      selectedProps={{ key: 'managerName', value: 'managerId' }}
                      onChange={(value) => handleDropdown('sType', value)}
                      onReset={() => handleResetDropdown('sType')}
                      isfilter
                    />
                  </div>
                  <div className="col-12 col-md-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedProps={{ key: 'locationName', value: 'id' }}
                      onChange={(value) => handleDropdown('SType2', value)}
                      onReset={() => handleResetDropdown('SType2')}
                      isfilter
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          />
          <Card
            body={
              <Table
                // searchAble
                data={trialList}
                headers={headers}
                striped
                bordered
                page={Entities.order}
                supPage={Entities.attendee}
                forwardPage={Entities.meeting}
                editSup={(val) => editSupAction(val)}
                forward={(val) => forwardAction(val)}
                freeTrialNote={(val) => freeTrialNoteAction(val)}
                currentUserClaim={currentUserClaim}
                exportName={Entities.order.exportName.upcomingTrial}
              />
            }
          />
        </div>
      </div>
      <ModalInsideComponent
        ref={freeTrialNoteModal}
        title={ModalTitles.freeTrialNote}
        child={<AttendeeNotes data={attendeeInfo} />}
        onClose={() => {
          dispatch(Actions.attendeeActions.cleanState());
          freeTrialNoteModal.current?.close();
        }}
        maxWidth={800}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.getAll,
    content
  );
};
export default List;
