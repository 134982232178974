import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import Input from '../../../components/Common/Input/Input';
import {
  ButtonType,
  InputType,
  LabelFontSize,
  LabelTextType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Actions from '../../../store/redux/actions';
import Card from '../../../components/Common/Card/Card';
import Label from '../../../components/Common/TextLabel/Label';
import Button from '../../../components/Common/Button/Button';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import Modal from '../../../components/Common/Modal/Modal';
import { GoogleAuth } from '../../../services/integrations/googleAuth';
import { FacebookAuth } from '../../../services/integrations/facebookAuth';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import findMyPath from '../../../hooks/useFindMyPath';
import useGTM from '../../../hooks/useGTM';
import Privacy from '../../UI/CommonPages/Privacy';
import TermsOFUse from '../../UI/CommonPages/TermsOFUse';

function Register() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const refCode = queryParams.get('refCode');
  const userPackage = useSelector((e) => e.userPackage);
  const checkHasItems = userPackage.isPackageComplete;
  const packageData = userPackage.packageData;
  const userArea = useSelector((e) => e.onboard.onboard);
  const userLanguage = useSelector((e) => e.lang.language);
  const currency = useSelector(
    (e) => e.preferenceOptions.preferenceCurrencyUnit
  );
  const [registerData, setRegisterData] = useState({});
  const status = useSelector((e) => e.auth.auth);
  const oAuthStatus = useSelector((e) => e.auth?.oAuth);
  const oAuthUser = useSelector((e) => e.user.user);
  const [updateData, setUpdateData] = useState({});
  const from = location.state?.from?.pathname || '/dashboard';
  const [isRequired, setIsRequired] = useState({});
  const [handleErrorMessage, setHandleErrorMessage] = useState(null);
  const [error, setError] = useState({});
  const [matchErr, setMatchErr] = useState(null);
  const validationMessages = useSelector((e) => e.auth.validationMessage);
  const successCheck = useSelector((e) => e.auth.isSuccess);
  const [handleValidationMessages, setHandleValidationMessage] =
    useState(validationMessages);
  const { sendEventToDataLayer } = useGTM();
  const [privacyAndTermsChecked, setPrivacyAndTermsChecked] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const showPrivacyPolicyModalHandler = () => {
    if (!privacyAndTermsChecked) {
      setPrivacyModalVisible(true);
    }
  };

  const showTermsOfUseModalHandler = () => {
    if (!privacyAndTermsChecked) {
      setTermsModalVisible(true);
    }
  };

  const handlePrivacyCheckboxChange = () => {
    setPrivacyAccepted(!privacyAccepted);
    setPrivacyModalVisible(false);
  };
  const handleTermsCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
    setTermsModalVisible(false);
  };

  useEffect(() => {
    if (termsAccepted && privacyAccepted) {
      setRegisterData({
        ...registerData,
        [registerData.privacyAndTerms]: true,
      });
      setUpdateData({ ...updateData, [updateData.privacyAndTerms]: true });

      setPrivacyAndTermsChecked(true);
    }
  }, [termsAccepted, privacyAccepted]);

  useEffect(() => {
    setHandleValidationMessage(validationMessages);
  }, [validationMessages]);

  useEffect(() => {
    if (Object.keys(userPackage?.packageData).length > 0 && checkHasItems) {
      dispatch(
        Actions.onboardActions.onboardType(
          parseInt(userPackage.packageData.userArea)
        )
      );
    }
    if (successCheck !== '') {
      if (successCheck && status !== '') {
        sendEventToDataLayer({
          event: 'register',
          registerState: 'Success',
        });
        if (checkHasItems) {
          navigate(findMyPath('speakers'), {
            state: { createData: packageData },
          });
        }
      }
      if (!successCheck) {
        sendEventToDataLayer({
          event: 'register',
          registerState: `"Failed" ${validationMessages}`,
        });
      }
    }
  }, [successCheck, validationMessages]);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter' && oAuthStatus === '') {
        onRegister(e);
      } else if (e.key === 'Enter' && oAuthStatus !== '') {
        onOauthPasswordChange(e);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [registerData]);

  const onOauthChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    setUpdateData({
      ...updateData,
      oldPassword: oAuthStatus === 'Google' ? 'M2talkgoogle' : 'M2talkfacebook',
      [id]: value,
    });
    const validationResult = regexValidator(type, value);
    if (!validationResult & (value !== '')) {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };
  const onOauthBlur = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value === '') {
      setUpdateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }

    (updateData.newPassword !== updateData.confirmPassword) &
    (updateData.confirmPassword !== '') &
    (error.confirmPassword === undefined)
      ? setMatchErr(
          <span className="text-danger">
            {' '}
            {t('validationMessages.passwords_must_match')}
          </span>
        )
      : setMatchErr(null);
  };
  const onOauthFocus = (e) => {
    e.preventDefault();
    setHandleErrorMessage(null);
  };
  const onOauthPasswordChange = async (e) => {
    e.preventDefault();
    if (updateData.newPassword === updateData.confirmPassword) {
      await dispatch(Actions.userActions.changePasswordAction(updateData));
      navigate('/onboarding');
    } else if (updateData.oauth_email === undefined) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    }
  };
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, required } = e.target;
    setRegisterData({ ...registerData, [id]: value });
    const validationResult = regexValidator(type, value);
    if (required && !validationResult && value === '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    if (required && value !== '' && validationResult) {
      setIsRequired({ ...isRequired, [id]: false });
    } else if (required && value === '') {
      setIsRequired({ ...isRequired, [id]: true });
    }
  };
  const onBlur = (e) => {
    e.preventDefault();
    (registerData.password !== registerData.confirmPassword) &
    (registerData.confirmPassword !== '') &
    (error.confirmPassword === undefined)
      ? setMatchErr(
          <span className="text-danger">
            {' '}
            {t('validationMessages.passwords_must_match')}
          </span>
        )
      : setMatchErr(null);
  };
  const onFocus = (e) => {
    e.preventDefault();
    setRegisterData({ ...registerData, currencyCode: currency.code });
    const { id, required } = e.target;
    if (required && isRequired[id] === undefined) {
      setIsRequired({ ...isRequired, [id]: required });
    }
    setHandleErrorMessage(null);
    setHandleValidationMessage('');
  };
  const onRegister = async (e) => {
    e.preventDefault();
    if (
      Object.values(isRequired).length !== 0 &&
      Object.values(isRequired).length === 3 &&
      Object.values(isRequired).every((e) => e === false) &&
      registerData.password === registerData.confirmPassword &&
      privacyAndTermsChecked === true
    ) {
      setShowLoader(true);
      await dispatch(Actions.authActions.registerAction(registerData)).then(
        (res) =>
          setTimeout(() => {
            setShowLoader(false);
          }, 500)
      );
      setRegisterData({});
      setHandleErrorMessage(null);
    } else if (privacyAndTermsChecked === false) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('register.please_read_and_check')}
        </span>
      );
    } else if (
      Object.values(isRequired).length !== 0 &&
      Object.values(isRequired).length === 3 &&
      Object.values(isRequired).every((e) => e !== false) &&
      privacyAndTermsChecked === false
    ) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    } else if (
      Object.values(isRequired).length === 0 ||
      Object.values(isRequired).length < 4 ||
      registerData.email === '' ||
      registerData.password === '' ||
      registerData.confirmPassword === '' ||
      privacyAndTermsChecked === false
    ) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    }
    setShowLoader(false);
  };
  const setErrorState = (errorMessage) => {
    setHandleErrorMessage(<span className="text-danger">{errorMessage}</span>);
  };
  useEffect(() => {
    return () => {
      dispatch(Actions.authActions.cleanState(true));
      setHandleValidationMessage('');
      setMatchErr(null);
      setHandleErrorMessage(null);
      setError({});
      setRegisterData({});
      setShowLoader(false);
    };
  }, []);
  const text = t('register.privacy_policy_and_terms_of_use');
  const parts = text.split(/(\{\{privacy_policy\}\}|\{\{terms_of_use\}\})/);

  useEffect(() => {
    if (Object.keys(registerData).length === 0) {
      setRegisterData({
        ...registerData,
        userArea: userArea,
        userLanguage: userLanguage.split('-')[0].toLowerCase(),
        refId: refCode ?? '',
      });
    }
  }, [registerData]);

  const content = (
    <div className="section5">
      <div className="container text-center pt-5">
        <div className="justify-content-center flex-column flex-sm-row mt-5">
          <div className="mx-auto">
            <Card
              body={
                <div className="d-flex align-items-center flex-column">
                  <div>
                    <img
                      className="img"
                      src="/images/meet2talk-logo.svg"
                      // src="/images/yeşil-siyah-logo.png"
                      alt="Meet2Talk Logo"
                      width="100%"
                    />
                  </div>
                  <div className="text-center py-1">
                    <Label
                      fontType={LabelTextType.Bolder}
                      fontSize={LabelFontSize.fs1}
                      value={t('register.welcome')}
                    />
                  </div>
                  <div className="text-center py-1">
                    <Label
                      fontType={LabelTextType.Normal}
                      fontSize={LabelFontSize.fs6}
                      value={t(
                        'register.start_learning_english_with_meet2Talk'
                      )}
                    />
                  </div>
                  <div className="col-lg-6 p-0 text-center">
                    <Input
                      className="w-100 mt-5 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                      id={'email'}
                      type={InputType.Email}
                      placeholder={t('register.email')}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={registerData.email || ''}
                      error={error.email}
                      required
                    />
                    <Input
                      className="w-100 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                      id={'password'}
                      type={InputType.Password}
                      placeholder={t('register.password')}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={registerData.password || ''}
                      error={error.password}
                      required
                    />
                    <Input
                      className="w-100 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                      id={'confirmPassword'}
                      type={InputType.Password}
                      placeholder={t('register.confirm_password')}
                      onChange={onChangeText}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={registerData.confirmPassword || ''}
                      error={error.confirmPassword}
                      required
                    />
                    {handleValidationMessages !== '' && (
                      <div className="text-center mt-1">
                        <span className="text-danger">
                          {handleValidationMessages}
                        </span>
                      </div>
                    )}
                    {handleErrorMessage && (
                      <div className="text-center mt-1">
                        <span className="text-danger">
                          {handleErrorMessage}
                        </span>
                      </div>
                    )}
                    <div className="text-center mt-1">
                      {registerData.password !== '' ? matchErr : undefined}
                    </div>
                    <div className="text-dark text-start align-items-center mt-2">
                      <div className="form-check pe-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="marketAndCommercial"
                        />
                        <label className="form-check-label text-gray-700">
                          {t(
                            'register.marketing_and_commercial_communications'
                          )}
                        </label>
                      </div>

                      <div className="form-check pe-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={privacyAndTermsChecked}
                          id="privacyAndTerms"
                          defaultChecked={privacyAndTermsChecked}
                          required
                          checked={privacyAndTermsChecked}
                          onClick={() => {
                            showPrivacyPolicyModalHandler();
                            showTermsOfUseModalHandler();
                            setHandleErrorMessage(null);
                            setHandleValidationMessage('');
                          }}
                        />
                        <label className="form-check-label text-gray-700">
                          {parts.map((part, index) => {
                            if (index === 0) {
                              return <span key={index}>{part}</span>;
                            }
                            const key = part.includes('privacy_policy')
                              ? 'privacy_policy'
                              : 'terms_of_use';
                            return (
                              <span key={index}>
                                {part.startsWith('{{') ? (
                                  <span
                                    onClick={() => {
                                      if (key === 'privacy_policy') {
                                        setPrivacyModalVisible(
                                          !privacyModalVisible
                                        );
                                      } else {
                                        setTermsModalVisible(
                                          !termsModalVisible
                                        );
                                      }
                                      setHandleErrorMessage(null);
                                      setHandleValidationMessage('');
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {key === 'privacy_policy'
                                      ? t('register.privacy_policy')
                                      : t('register.terms_of_use')}
                                  </span>
                                ) : (
                                  <span>{part}</span>
                                )}
                              </span>
                            );
                          })}
                        </label>
                      </div>
                    </div>

                    <div className="d-flex align-items-center flex-column mt-3">
                      <Button
                        type={
                          showLoader
                            ? ButtonType.Secondary
                            : ButtonType.Successbg
                        }
                        bold
                        buttonCol={8}
                        textColor={TextColors.Light}
                        text={t('register.register')}
                        onClick={onRegister}
                        loading={showLoader}
                      />
                      <div className="mt-2">
                        {t('register.are_you_already_registered')}
                        <Link
                          className="text-decoration-none text-success ms-2"
                          to="/account/login"
                        >
                          {t('register.log_in')}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="divider mx-auto pt-3 col-lg-6 col-sm-12 ps-0 text-muted">
                    <h6>
                      <span>{t('register.register_with')}</span>
                    </h6>
                  </div>
                  <div className="row mx-auto justify-content-center mt-3 ">
                    <FacebookAuth
                      type={'register'}
                      checkHasItems={checkHasItems}
                      packageData={packageData}
                      userLanguage={userLanguage}
                      privacyAndTermsChecked={privacyAndTermsChecked}
                      setErrorState={setErrorState}
                      refId={refCode}
                    />

                    <GoogleAuth
                      type={'register'}
                      checkHasItems={checkHasItems}
                      packageData={packageData}
                      userLanguage={userLanguage}
                      privacyAndTermsChecked={privacyAndTermsChecked}
                      setErrorState={setErrorState}
                      refId={refCode}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
      {/* TODO Email Registerdan gelecek. */}
      {oAuthStatus !== '' ? (
        <Modal
          isVisible={status !== '' ? true : false}
          styleProps={{ maxWidth: 550 }}
          title={t('registerModal.register_created_successfully')}
          content={
            <div>
              <h6>{t('registerModal.oauth_verification_link')}</h6>
              <div className="col p-0 text-center">
                <Input
                  key={'oauth_email'}
                  className="w-100 mt-5 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                  id={'oauth_email'}
                  type={InputType.Email}
                  placeholder={t('register.email')}
                  onChange={onOauthChangeText}
                  onFocus={onOauthFocus}
                  onBlur={onOauthBlur}
                  disabled
                  value={
                    status !== '' &&
                    JSON?.parse(window?.atob(status?.split('.')[1]))['email']
                  }
                  error={error.email}
                  required
                />
                <Input
                  key={'oauth_password'}
                  className="w-100 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                  id={'newPassword'}
                  type={InputType.Password}
                  placeholder={t('register.password')}
                  onChange={onOauthChangeText}
                  onFocus={onOauthFocus}
                  onBlur={onOauthBlur}
                  value={updateData.newPassword || ''}
                  error={error.newPassword}
                  required
                />
                <Input
                  key={'oauth_confirmPassword'}
                  className="w-100 mb-3 bg-m2t h6 py-1 border-0 border-bottom border-secondary"
                  id={'confirmPassword'}
                  type={InputType.Password}
                  placeholder={t('register.confirm_password')}
                  onChange={onOauthChangeText}
                  onFocus={onOauthFocus}
                  onBlur={onOauthBlur}
                  value={updateData.confirmPassword || ''}
                  error={error.confirmPassword}
                />
              </div>
            </div>
          }
          footer={
            <Button
              type={ButtonType.Successbg}
              textColor={TextColors.Light}
              bold
              buttonCol={5}
              text={t('button.common_submit')}
              onClick={onOauthPasswordChange}
            />
          }
          onClose={() => {
            navigate('/onboarding');
          }}
        />
      ) : (
        <Modal
          isVisible={status !== '' ? true : false}
          styleProps={{ maxWidth: 550 }}
          title={t('registerModal.register_created_successfully')}
          // content={t('registerModal.verification_link', {
          //   mail:
          //     status !== '' &&
          //     JSON?.parse(window?.atob(status?.split('.')[1]))['email'],
          // })}
          // content={`Doğrulama linkini ${
          //   status !== '' &&
          //   JSON?.parse(window?.atob(status?.split('.')[1]))['email']
          // } mail adresine gönderdik.`}
          footer={
            <Button
              type={ButtonType.Successbg}
              textColor={TextColors.Light}
              bold
              buttonCol={5}
              text={t('button.close')}
              onClick={() => navigate('/onboarding')}
            />
          }
          onClose={() => {
            navigate('/onboarding');
          }}
        />
      )}

      <Modal
        isVisible={privacyModalVisible}
        content={<Privacy />}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        footer={
          <Button
            type={ButtonType.Successbg}
            textColor={TextColors.Light}
            bold
            buttonCol={3}
            text={t('button.accept')}
            // disabled={!privacyScrolled}
            onClick={handlePrivacyCheckboxChange}
          />
        }
        onClose={() => {
          setPrivacyModalVisible(false);
        }}
      />

      <Modal
        isVisible={termsModalVisible}
        content={<TermsOFUse />}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        footer={
          <Button
            type={ButtonType.Successbg}
            textColor={TextColors.Light}
            bold
            buttonCol={3}
            text={t('button.accept')}
            onClick={handleTermsCheckboxChange}
            // disabled={!termsScrolled}
          />
        }
        onClose={() => {
          setTermsModalVisible(false);
        }}
      />
    </div>
  );
  return useLoader() ? <Loader /> : content;
}
export default Register;
