import React, { useEffect, useState } from 'react';
import Input from '../../../components/Common/Input/Input';
import {
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import { REGEX } from '../../../utils/RegexEnums';
import { useDispatch, useSelector } from 'react-redux';
import regexValidator from '../../../hooks/useRegexValidator';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import { checkLogged } from '../../../hooks/useCheckLogged';
import Button from '../../../components/Common/Button/Button';
import validationMessage from '../../../hooks/useValidationMessage';
import { formatPhone } from '../../../hooks/useFormatPhone';

export default function PhoneConfirmation({
  phoneConfirmInfo,
  isGift = false,
  trialStatus,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validationMessages = useSelector((e) => e.user.validationMessage);
  const successCheck = useSelector((e) => e.user.isSuccess);
  const userData = useSelector((e) => e.user.user);
  const [confirmationData, setConfirmationData] = useState({
    phoneNumber: '',
    token: '',
  });
  const [requestSent, setRequestSent] = useState(false);
  const [handleValidationMessages, setHandleValidationMessage] =
    useState(validationMessages);
  const [error, setError] = useState({});
  const [hideButton, setHideButton] = useState('');

  const handlePhoneConfirm = (e) => {
    e?.preventDefault();
    if (
      confirmationData?.phoneNumber !== '' &&
      confirmationData?.token === '' &&
      error.phoneNumber === undefined
    ) {
      dispatch(Actions.userActions.phoneConfirmationAction(confirmationData));
    } else if (
      confirmationData?.phoneNumber !== '' &&
      confirmationData?.token !== ''
    ) {
      dispatch(Actions.userActions.confirmPhoneNumberAction(confirmationData));
    } else if (
      confirmationData?.token === '' &&
      (confirmationData?.phoneNumber !== '' || error.phoneNumber !== undefined)
    ) {
      setHandleValidationMessage(t('calendar.token_confirm_error'));
    } else if (
      (confirmationData?.phoneNumber === '' ||
        confirmationData?.phoneNumber === undefined) &&
      confirmationData?.token === undefined
    ) {
      setHandleValidationMessage(t('calendar.phone_confirm_error'));
    }
  };
  const handlePhoneConfirmText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      setConfirmationData({ ...confirmationData, [id]: value });
    } else {
      setConfirmationData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };
  const onFocusPoneConfirmModal = (e) => {
    e.preventDefault();
    if (error?.phoneNumber !== undefined) {
      const cleanValue = formatPhone(undefined, confirmationData?.phoneNumber);
      setConfirmationData({
        ...confirmationData,
        phoneNumber: cleanValue,
      });
    }
    setHandleValidationMessage('');
  };
  useEffect(() => {
    if (successCheck === false) {
      setHandleValidationMessage(validationMessages);
      if (validationMessages.split('{br}').length - 1 > 1) {
        setRequestSent(true);
      }
    }
  }, [successCheck, validationMessages]);

  useEffect(() => {
    if (successCheck && confirmationData.phoneNumber !== '') {
      setRequestSent(true);
    }
    if (successCheck && confirmationData.token !== '') {
      setConfirmationData({});
      setHideButton(undefined);
      phoneConfirmInfo(
        <div className="d-flex flex-column align-items-center text-start">
          <div className="row mt-2">
            {t('calendar.phone_confirm_success_message')}
          </div>
          {(trialStatus !== 0 || trialStatus !== undefined) && (
            <div className="row">
              {isGift
                ? t('calendar.phone_confirm_success_message_redeem')
                : t('calendar.phone_confirm_success_message_after')}
            </div>
          )}
        </div>
      );

      if (!isGift) {
        dispatch(Actions.userPackageActions.completeAction());
        dispatch(Actions.orderActions.selectSessionReducer([], false));
      }
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
    }
  }, [successCheck]);

  useEffect(() => {
    return () => {
      dispatch(Actions.userActions.cleanState(true));
      setError({});
      setHandleValidationMessage('');
    };
  }, []);

  useEffect(() => {
    if (confirmationData?.phoneNumber?.length > 15 || error?.phoneNumber) {
      const cleanValue = formatPhone(undefined, confirmationData?.phoneNumber);
      setConfirmationData({
        ...confirmationData,
        phoneNumber: cleanValue,
      });
    }
  }, [confirmationData?.phoneNumber, error?.phoneNumber]);

  useEffect(() => {
    if (userData?.phone !== undefined && userData?.phone !== null) {
      setConfirmationData({
        ...confirmationData,
        phoneNumber: userData?.phone,
      });
    }
  }, [userData]);

  return (
    <React.Fragment>
      {!requestSent ? (
        <Input
          className={
            'w-100 mb-3 bg-m2t border-0 h6 border-bottom border-secondary'
          }
          id={'phoneNumber'}
          type={InputType.Tel}
          placeholder={'(5xx) xxx xx xx'}
          onChange={handlePhoneConfirmText}
          value={formatPhone(confirmationData?.phoneNumber)}
          required
          error={error?.phoneNumber}
          pattern={REGEX.TEL}
          onFocus={onFocusPoneConfirmModal}
        />
      ) : (
        <Input
          className={
            'w-100 mb-3 bg-m2t border-0 h6 border-bottom border-secondary'
          }
          id={'token'}
          type={InputType.Text}
          placeholder={t('calendar.confirmation_code')}
          onChange={handlePhoneConfirmText}
          value={confirmationData?.token || ''}
          required
          onFocus={onFocusPoneConfirmModal}
        />
      )}
      {handleValidationMessages !== '' && (
        <>
          {handleValidationMessages?.split('{br}')?.map((item, index) => (
            <span
              key={index}
              className="d-flex position-relative justify-content-center text-danger"
              style={{ maxWidth: '550px' }}
            >
              <strong>{item}</strong>
            </span>
          ))}
        </>
      )}
      <div className="d-flex justify-content-center mt-4">
        {hideButton === '' && (
          <Button
            type={ButtonType.Successbg}
            textColor={TextColors.Light}
            bold
            buttonCol={5}
            text={
              !requestSent ? t('button.common_submit') : t('button.confirm')
            }
            onClick={(e) => handlePhoneConfirm(e)}
          />
        )}
      </div>
    </React.Fragment>
  );
}
