import {
  create,
  update,
  remove,
  getById,
  getAll,
} from '../../../services/apiServices/careerServices';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';
import ActionTypes from './actionTypes';
import config from '../../../config.json';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.career.CREATE_ACTION_CAREER };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData, id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
      if (res?.data?.isSuccess) {
        await dispatch(createReducer());
      }
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.career.UPDATE_ACTION_CAREER };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
      await dispatch(handleError(reponseStatus.fail));
    });;
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getById(id);
        let response = res?.data?.data;
        await dispatch(getByIdReducer(response));
        await dispatch(holdValue(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getByIdReducer = (data) => {
  return { type: ActionTypes.career.GETBYID_ACTION_CAREER, payload: { data } };
};
const getAllAction = (requestType) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(requestType);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.career.GETALL_ACTION_CAREER, payload: { data } };
};
const holdValue = (data) => {
  return { type: ActionTypes.career.HOLD_VALUE_CAREER, payload: data };
};
const handleError = (data) => {
  return {
    type: ActionTypes.career.HANDLE_ERROR_CAREER,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.career.HANDLE_VALIDATION_CAREER,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
      await dispatch(getByIdReducer());
      await dispatch(updateReducer());
    }
  };
};

const careerActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  holdValue,
  cleanState,
};
export default careerActions;
