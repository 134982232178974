import ActionTypes from './actionTypes';

const holdFilter = (data) => {
  return async (dispatch) => {
    await dispatch(holdFilterReducer(data));
  };
};
const holdFilterReducer = (data) => {
  return { type: ActionTypes.filter.HOLD_FILTER, payload: { data } };
};
const holdCreateData = (data, keepData) => {
  return async (dispatch) => {
    await dispatch(holdCreateDataReducer(data, keepData));
  };
};
const holdCreateDataReducer = (data, keepData) => {
  return {
    type: ActionTypes.filter.HOLD_CREATE_DATA,
    payload: { data: keepData !== undefined ? {...keepData, ...data} : data },
  };
};
const holdForBadge = (data) => {
  return async (dispatch) => {
    await dispatch(holdForBadgeReducer(data));
  };
};
const holdForBadgeReducer = (data) => {
  return { type: ActionTypes.filter.HOLD_FOR_BADGE, payload: { data } };
};

const resetStateAction = () => {
  return {
    type: ActionTypes.filter.RESET_STATE,
  };
};

const filterActions = {
  holdFilter,
  holdCreateData,
  holdForBadge,
  resetStateAction,
};
export default filterActions;
