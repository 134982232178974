import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const meetingAttendeeReducer = (
  state = initialState.meetingAttendee,
  action
) => {
  switch (action.type) {
    case ActionTypes.meetingAttendee.HANDLE_ERROR:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.meetingAttendee.HANDLE_VALIDATION:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.resetStore.RESET_STORE:
      return {
        ...state,
        isSuccess: '',
        validationMessage: '',
      };
    default:
      return state;
  }
};
export default meetingAttendeeReducer;
