import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './LandingCard.css'; // Import the CSS file
import VideoModal from '../Modal/VideoModal';

const LandingCard = ({
  imgSrc,
  videoSrc,
  title,
  text,
  link,
  buttonText,
  buttonIcon,
  iconColor,
  roundedPill,
  thumbnail = false,
  frameOn = false,
}) => {
  const [vidoeUp, setVideoUp] = useState(false);
  if (thumbnail) {
    const videoId = videoSrc.split('embed/')[1];
    imgSrc = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }

  return (
    <div
      className="cardLanding bg-white"
    >
      {frameOn ? (
        <div
          style={{
            position: 'relative',
            minHeight: '18rem',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => setVideoUp(true)}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${imgSrc})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
            }}
            onClick={() => setVideoUp(true)}
          >
            <div
              style={{
                width: '34px',
                height: '34px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => setVideoUp(true)}
            >
              <svg width="30" height="30" viewBox="0 0 24 24">
                <path d="M8 5v14l10-7z" fill="black" />
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="image-container">
            <img src={imgSrc} className="img-circle" alt={title} />
            <div className="outer-circle"></div>
            <div className="inner-circle"></div>
          </div>
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text mt-4">{text}</p>
            <button
              onClick={() => setVideoUp(true)}
              className={`btn bg-custom text-white mx-auto mt-auto mb-2 ${
                roundedPill ? 'rounded-pill' : ''
              }`}
            >
              {buttonText}
              {
                <FontAwesomeIcon
                  className={`${iconColor} px-1`}
                  icon={buttonIcon}
                  onClick={() => setVideoUp(true)}
                />
              }
            </button>
          </div>
        </React.Fragment>
      )}
      {vidoeUp && (
        <VideoModal
          videoUrl={videoSrc}
          styleProps={{ maxWidth: 550, height: 500 }}
          onClose={() => setVideoUp(!vidoeUp)}
        />
      )}
    </div>
  );
};

export default LandingCard;
