import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(
    endPoint.meetingSchema.CREATE_MEETINGSCHEMA,
    createData
  );
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.meetingSchema.UPDATE_MEETINGSCHEMA}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(
    `${endPoint.meetingSchema.REMOVE_MEETINGSCHEMA}/${id}`
  );
};
export const getById = async (id) => {
  return await getRequest(
    `${endPoint.meetingSchema.GETBYID_MEETINGSCHEMA}/${id}`
  );
};

export const getAll = async (data) => {
  return await postRequest(endPoint.meetingSchema.GETALL_MEETINGSCHEMA, data);
};
export const getAllAttendeeSchedule = async (id = '') => {
  return await getRequest(
    `${endPoint.meetingSchema.GET_MEETING_ATTENDEE_SCHEDULE}${id !== '' ? '/' + id : ''
    }`
  );
};
export const getAllSessionsCount = async () => {
  return await getRequest(`${endPoint.meetingSchema.GET_ALL_SESSION_COUNT}`);
};
export const getSpeakerDropdownList = async (filter) => {
  return await postRequest(
    `${endPoint.meetingSchema.GET_SPEAKER_DROPDOWN_LIST}`,
    filter
  );
};

export const getTodaysMeetingBySpeaker = async () => {
  return await getRequest(
    endPoint.meetingSchema.GET_MEETING_BY_SPEAKER_MEETINGSCHEMA
  );
};
export const getAllSpeakerSchedule = async (id = '') => {
  return await getRequest(
    `${endPoint.meetingSchema.GetMeetingSpeakerSchedule}${id !== '' ? '/' + id : ''
    }`
  );
};
export const getMeetingAttendees = async (data) => {
  return await postRequest(endPoint.meetingSchema.GET_MEETING_ATTENDEES, data);
};
export const attendanceCheck = async (data) => {
  return await postRequest(endPoint.meetingSchema.ATTENDANCE_CHECK, data);
};