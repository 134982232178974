import {
  create,
  update,
  remove,
  getById,
  getAll,
  getLocationsByUserArea,
} from '../../../services/apiServices/locationServices';
import ActionTypes from './actionTypes';

const createAction = (createData) => {
  return async (dispatch) => {
    await create(createData).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(createReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.location.CREATE_ACTION_LOCATION };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await update(updateData, id).then(async (res) => {
      if (res?.data?.code === 200) {
        await dispatch(updateReducer());
        await dispatch(handleError(true));
      }
      if (res?.response?.data) {
        await dispatch(handleError(false));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.location.UPDATE_ACTION_LOCATION };
};
const removeAction = (id) => {
  return async () => {
    await remove(id);
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.location.GETBYID_ACTION_LOCATION,
    payload: { data },
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.location.GETALL_ACTION_LOCATION,
    payload: { data },
  };
};
const getLocationsByUserAreaAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await getLocationsByUserArea(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(getLocationsByUserAreaReducer(res.data?.data));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const getLocationsByUserAreaReducer = (data) => {
  return {
    type: ActionTypes.location.GET_BY_USER_AREA_LOCATION,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.location.HANDLE_VALIDATION_LOCATION,
    payload: data,
  };
};
const holdValue = (data) => {
  return { type: ActionTypes.location.HOLD_VALUE_LOCATION, payload: data };
};
const handleError = (data) => {
  return { type: ActionTypes.location.HANDLE_ERROR_LOCATION, payload: data };
};

const cleanState = () => {
  return async (dispatch) => {
    await dispatch(getLocationsByUserAreaReducer([]));
    await dispatch(getAllReducer([]));
    await dispatch(getByIdReducer([]));
  };
};

const locationActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  getLocationsByUserAreaAction,
  holdValue,
  cleanState,
};
export default locationActions;
