import React, { useState } from 'react';
import {
  faCheck,
  faPencil,
  faTrashCan,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button/Button';
import { ButtonIcon, ButtonType } from '../../../utils/ComponentEnums';
import { t } from 'i18next';
import { useEffect } from 'react';
import addDay from '../../../hooks/useAddDay';

export default function BasicTable({
  labelFunc,
  data,
  headers,
  emptyThread,
  matchValues,
  func,
  status,
  labelProp,
  onClickFuncSetDate,
  onClickFuncPopup,
  onClickFuncRemove,
  actionButtonProps,
  action2ndButtonProps,
  infoButtonProps,
  inputRadio,
  oncalendar,
  setArrows,
  id = false,
}) {
  const style = oncalendar
    ? {
        maxHeight: '125px',
        overflowY: 'auto',
      }
    : {};
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!data) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [data]);

  return showLoader ? (
    <div className="d-flex mx-auto align-items-center py-3 justify-content-center">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : (
    <div className="table-responsive" style={style}>
      {data && (
        <table className="table table-striped table-rounded table-hover text-center table-sm table-responsive">
          <thead>
            <tr
              className="position-sticky top-0 align-middle"
              style={{ zIndex: '1' }}
            >
              {headers?.map((val, index) => (
                <th key={`h_${val.typeofSession}_${index}`}>
                  {val.label === 'Id' ? undefined : val.label}
                </th>
              ))}
              {emptyThread ? <th></th> : undefined}
            </tr>
          </thead>
          <tbody>
            {data.map((val, i) => (
              <tr className="align-middle" key={`i_${i}`}>
                {headers.map((p) => (
                  <td key={`i_${i}_${p.key}`}>
                    {p.key !== labelProp ? (
                      typeof val[p.key] === 'boolean' ? (
                        val[p.key] ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : (
                          <FontAwesomeIcon icon={faXmark} />
                        )
                      ) : p.key === 'earnedDate' ? (
                        addDay('00:00', val[p.key])
                      ) : p.key === 'installment' && i === 0 ? (
                        t('buy.singleCharge')
                      ) : (
                        val[p.key === 'id' && !id ? undefined : p.key]
                      )
                    ) : val[labelProp] !== '' && inputRadio ? (
                      <input
                        onClick={(e) => labelFunc(val?.installment)}
                        type="radio"
                        id={i}
                        name="color"
                        value={i}
                      />
                    ) : val[labelProp] !== '' ? (
                      <label
                        onClick={(e) => labelFunc(i, val?.id)}
                        id={val['id']}
                        title={val[labelProp]}
                        role={'button'}
                        style={{ fontSize: '.9rem' }}
                      >
                        <FontAwesomeIcon
                          className="ms-2 text-success"
                          icon={faTrashCan}
                        />
                      </label>
                    ) : (
                      <label
                        onClick={labelFunc}
                        id={val['id']}
                        title={val[labelProp]}
                        role={'button'}
                        style={{ fontSize: '.9rem' }}
                      >
                        {'Add Note'}
                        <FontAwesomeIcon
                          onClick={(value) => value[labelProp]}
                          className="ms-1"
                          icon={faPencil}
                        />
                      </label>
                    )}
                  </td>
                ))}
                {matchValues ? (
                  <td key={`i_${i}_${val[matchValues.key] + i}`}>
                    {((val[matchValues?.actionButtonProps?.key] ===
                      matchValues?.actionButtonProps?.value &&
                      matchValues?.action2ndButtonProps?.key !== undefined &&
                      val[matchValues?.action2ndButtonProps?.key] ===
                        matchValues?.action2ndButtonProps?.value) ||
                      (typeof val[matchValues?.actionButtonProps?.key] ===
                        'string' &&
                        val[matchValues?.actionButtonProps?.key]?.includes(
                          matchValues?.actionButtonProps?.value
                        )) ||
                      (val[matchValues?.actionButtonProps?.key] >
                        matchValues?.actionButtonProps?.value &&
                        val[matchValues?.infoButtonProps?.key] !==
                          matchValues?.infoButtonProps?.value)) && (
                      <Button
                        id={i}
                        type={
                          val['orderStatus'] === t('OrderStatus.5')
                            ? ButtonType.Secondary
                            : actionButtonProps.type
                        }
                        text={actionButtonProps.text}
                        onClick={(e) => onClickFuncSetDate(e.currentTarget.id)}
                        textColor={actionButtonProps.textColor}
                        buttonCol={12}
                        disabled={val['orderStatus'] === t('OrderStatus.5')}
                      />
                    )}
                    {val[matchValues?.action2ndButtonProps?.key] !==
                      matchValues?.action2ndButtonProps?.value &&
                      val[matchValues?.actionButtonProps?.key] ===
                        matchValues?.actionButtonProps?.value && (
                        <Button
                          id={i}
                          type={action2ndButtonProps?.type}
                          text={action2ndButtonProps?.text}
                          onClick={(e) => onClickFuncRemove(e.currentTarget.id)}
                          textColor={action2ndButtonProps?.textColor}
                          buttonCol={12}
                        />
                      )}
                    {val[matchValues?.infoButtonProps?.key] ===
                      matchValues?.infoButtonProps?.value && (
                      <Button
                        id={i}
                        type={infoButtonProps?.type}
                        text={infoButtonProps?.text}
                        onClick={() => {
                          if (onClickFuncPopup) {
                            onClickFuncPopup(val);
                          }
                        }}
                        textColor={infoButtonProps?.textColor}
                        buttonCol={12}
                      />
                    )}
                  </td>
                ) : data?.[0]?.id !== undefined && setArrows === undefined ? (
                  data.map((value, index) => (
                    <td
                      key={`e_i_${i}_${index}`}
                      id={value?.id}
                      role={'button'}
                      onClick={func}
                    >
                      {status?.[value?.id] ? (
                        <FontAwesomeIcon
                          onClick={func}
                          id={value?.id}
                          icon={ButtonIcon.ArrowUp}
                        />
                      ) : (
                        <FontAwesomeIcon
                          onClick={func}
                          id={value?.id}
                          icon={ButtonIcon.ArrowDown}
                        />
                      )}
                    </td>
                  ))
                ) : undefined}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {data?.length === 0 ? (
        <div className="fw-bold d-flex justify-content-center">
          {t('basicTable.no_data')}
        </div>
      ) : undefined}
    </div>
  );
}
