import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import Card from '../../../../components/Common/Card/Card';
import Table from '../../../../components/Common/Table/Table';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import DropdownWithSearch from '../../../../components/Common/Dropdown/DropdownWithSearch';
import { TimesPerWeek } from '../../../../utils/Enum';
import SubmitButton from '../../../../components/Common/Button/SubmitButton';
import Input from '../../../../components/Common/Input/Input';
import Toast from '../../../../components/Common/Popup/Toast';

const RenewOrderItem = ({ orderItem, tableObject, updateList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const renewResponse = useSelector((e) => e.order.renewOrderItem);
  const locationList = useSelector((e) => e.location.getAll);
  const ageList = useSelector((e) => e.ageInterval.getAll);
  const staticDatas = useSelector((e) => e.staticData);
  const serviceType = staticDatas.UserArea;
  const sessionPackageList = useSelector((e) => e.sessionPackage.getAll);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const [createData, setCreateData] = useState({
    orderItemId: orderItem?.id,
    zone: 1,
    type: 1,
    ageId: 4,
  });
  const [renewData, setRenewData] = useState(null);
  const [resetState, setResetState] = useState(null);
  const [handleToast, setHandleToast] = useState(undefined);

  const onCalculate = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.orderActions.renewOrderItemCalculationAction(createData)
    );
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    await dispatch(Actions.orderActions.renewOrderItemAction(renewData));
  };

  const getList = async () => {
    if (checkClaims(currentUserClaim, Claims.backofficeClaims.order.renew)) {
      dispatch(Actions.locationActions.getAllAction());
      dispatch(Actions.sessionPackageActions.getAllAction());
      dispatch(Actions.ageIntervalActions.getAllAction());
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const ageIntervalWithOptions = ageList?.map((item) => ({
    ...item,
    name: `${item.minAge} - ${item.maxAge}`,
    value: item.id,
    userArea: item.userArea,
  }));
  useEffect(() => {
    if (resetState) {
      if (
        createData?.age === undefined &&
        createData?.location === undefined &&
        createData?.sessionPackage === undefined
      ) {
        setResetState(null);
      }
    }
    if (renewData !== null) {
      setRenewData(null);
    }
  }, [createData]);

  useEffect(() => {
    if (Object.keys(renewResponse).length > 0) {
      const renewObje = {
        orderItemId: createData?.orderItemId,
        meetingRedateIds: renewResponse?.meetingRedateIds,
        options: renewResponse?.options,
        totalSessionCount: renewResponse?.totalSessionCount,
        balance: renewResponse?.balance,
        totalAmount: renewResponse?.totalAmount,
        userArea: createData?.userArea,
        redateRight: renewResponse?.redateRight,
        weekCount: renewResponse?.weekCount,
        ageId: createData?.age,
      };
      setRenewData(renewObje);
    }
  }, [renewResponse]);

  useEffect(() => {
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  const content = (
    <div className="d-flex justify-content-center">
      <div className="col-12">
        <Card
          body={
            <React.Fragment>
              <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                <HeadTags
                  hSize={5}
                  strong
                  text={t('orderItem.renewOrderItemForm')}
                />
              </div>
              <Table
                data={[tableObject.data]}
                headers={tableObject.headers}
                striped
                bordered
                noUtc={false}
                hidePagination={true}
              />
              <div className="row mt-lg-3 mt-2">
                <div className="col-lg-6 col-sm-6">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="col-lg-12 col-sm-12">
                        <DropdownWithSearch
                          placeHolder={t('dropdownSelect.select_user_area')}
                          options={serviceType}
                          selectedProps={{
                            key: 'key',
                            value: 'value',
                          }}
                          onChange={(value) => {
                            if (
                              parseInt(value) !== createData?.userArea &&
                              createData?.userArea !== undefined
                            ) {
                              const data = {
                                orderItemId: createData.orderItemId,
                                type: createData.type,
                                zone: createData.zone,
                                userArea: parseInt(value),
                                userAreaString: serviceType
                                  .find((val) => val.value === value)
                                  ?.path.split('/')[1],
                              };
                              setCreateData(data);
                              setResetState(true);
                            } else {
                              setCreateData({
                                ...createData,
                                userArea: parseInt(value),
                                userAreaString: serviceType
                                  .find((val) => val.value === value)
                                  ?.path.split('/')[1],
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-2">
                        <DropdownWithSearch
                          resetState={resetState}
                          placeHolder={t('dropdownSelect.select_age_interval')}
                          options={ageIntervalWithOptions.filter(
                            (area) =>
                              area.userArea === createData?.userArea ||
                              (area.userArea === 4 &&
                                createData?.userArea !== 2 &&
                                createData?.userArea !== undefined)
                          )}
                          selectedProps={{ key: 'name', value: 'value' }}
                          onChange={(value) =>
                            setCreateData({
                              ...createData,
                              age: parseInt(value),
                            })
                          }
                          selectRule={t('dropdownWithSearch.select_rule_area')}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-2">
                        <DropdownWithSearch
                          resetState={resetState}
                          placeHolder={t('dropdownSelect.select_location')}
                          options={locationList.filter(
                            (area) =>
                              area.locationArea === createData?.userArea ||
                              (area.locationArea === 0 &&
                                createData?.userArea === 2)
                          )}
                          selectedProps={{ key: 'locationName', value: 'id' }}
                          onChange={(value) =>
                            setCreateData({
                              ...createData,
                              location: parseInt(value),
                            })
                          }
                          selectRule={t('dropdownWithSearch.select_rule_area')}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-3">
                        <DropdownWithSearch
                          resetState={resetState}
                          placeHolder={t(
                            'dropdownSelect.select_session_package'
                          )}
                          options={sessionPackageList.filter((item) =>
                            item.displayName
                              .toLowerCase()
                              .includes(createData?.userAreaString)
                          )}
                          selectedProps={{
                            key: 'person',
                            supKey: 'minute',
                            value: 'id',
                            display: 'displayName',
                          }}
                          onChange={(value) =>
                            setCreateData({
                              ...createData,
                              package: parseInt(value),
                            })
                          }
                          selectRule={t('dropdownWithSearch.select_rule_area')}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-3">
                        <DropdownWithSearch
                          resetState={resetState}
                          placeHolder={t('giftOrder.timesPerWeek')}
                          options={TimesPerWeek}
                          selectedProps={{ key: 'perWeek', value: 'perWeek' }}
                          onChange={(value) =>
                            setCreateData({
                              ...createData,
                              timePerWeek: parseInt(value),
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 mt-2 mt-lg-0 d-flex flex-column justify-content-center align-items-center">
                      <SubmitButton
                        text={'button.calculate'}
                        stateName={'order'}
                        onClick={onCalculate}
                        objectLengthRule={
                          renewData === null &&
                          Object.keys(createData).length > 8
                            ? false
                            : true
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 mt-lg-4 mt-2">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 justify-content-center">
                      <div className="col-lg-12 col-sm-12">
                        <Input
                          className={'col-lg-6 col-7'}
                          labelOnRight
                          labelValue={t('order.totalSessionCount')}
                          value={renewData?.totalSessionCount || ''}
                          disabled
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-2">
                        <Input
                          className={'col-lg-6 col-7'}
                          labelOnRight
                          labelValue={t('orderItem.redateRight')}
                          value={renewData?.redateRight || ''}
                          disabled
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-2">
                        <Input
                          className={'col-lg-6 col-7'}
                          labelOnRight
                          labelValue={t('order.weekCount')}
                          value={renewData?.weekCount || ''}
                          disabled
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-2">
                        <Input
                          className={'col-lg-6 col-7'}
                          labelOnRight
                          labelValue={t('order.total_amount')}
                          value={renewData?.totalAmount || ''}
                          disabled
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-lg-3 mt-2">
                        <Input
                          className={'col-lg-6 col-7'}
                          labelOnRight
                          labelValue={t('order.balance')}
                          value={renewData?.balance || ''}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 mt-2 mt-lg-0 d-flex flex-column justify-content-center align-items-center">
                      <SubmitButton
                        stateName={'order'}
                        onClick={(e) => {
                          onSubmit(e);
                          setHandleToast(null);
                        }}
                        objectLengthRule={renewData === null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
        />
      </div>
      {handleToast === null && (
        <Toast
          stateName={'order'}
          onClose={() => {
            setCreateData({});
            updateList(orderItem?.orderId);
            setHandleToast(undefined);
            setRenewData(null);
            dispatch(Actions.orderActions.cleanState());
          }}
        />
      )}
    </div>
  );
  return content;
};
export default RenewOrderItem;
