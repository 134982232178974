import { getAll, getSpeakerDetail, unPayMeeting } from "../../../services/apiServices/speakerEffortServices";
import ActionTypes from "./actionTypes";

const getAllAction = (requestType) => {
    return async (dispatch) => {
        await getAll(requestType).then(async (res) => {
            let response = res?.data?.data;
            await dispatch(getAllReducer(response));
        });
    };
};
const getAllReducer = (data) => {
    return { type: ActionTypes.speakerEffort.GET_ALL_ACTION_SPEAKEREFFORT, payload: { data } };
};
const getDetailAction = (id) => {
    return async (dispatch) => {
        await getSpeakerDetail(id).then(async (res) => {
            let response = res?.data?.data;
            await dispatch(getByIdReducer(response));
        });
    };
};
const getByIdReducer = (data) => {
    return {
        type: ActionTypes.speakerEffort.GET_DETAILS_ACTION_SPEAKEREFFORT,
        payload: { data },
    };
};

const unPayMeetingAction = (data) => {
    return async () => {
        await unPayMeeting(data);
    };
};

const handleError = (data) => {
    return {
        type: ActionTypes.speakerEffort.HANDLE_ERROR_SPEAKEREFFORT,
        payload: data,
    };
};
const handleValidationReducer = (data) => {
    return {
        type: ActionTypes.speakerEffort.HANDLE_VALIDATION_SPEAKEREFFORT,
        payload: data,
    };
};
const cleanState = () => {
    return async (dispatch) => {
        await dispatch(getByIdReducer());
        await dispatch(handleError(''));
        await dispatch(handleValidationReducer(''));
    };
};

const speakerEffortActions = {
    getAllAction,
    getDetailAction,
    unPayMeetingAction,
    handleError,
    handleValidationReducer,
    cleanState,
};
export default speakerEffortActions;
