import authActions from './authActions';
import screenActions from './screenActions';
import langActions from './langActions';
import careerActions from './careerActions';
import companyPriceRequestActions from './companyPriceRequestActions';
import faqGroupActions from './faqGroupActions';
import faqActions from './faqActions';
import userActions from './userActions';
import staticDataActions from './staticDataActions';
import speakerActions from './speakerActions';
import onboardActions from './onboardActions';
import couponCodesActions from './couponCodesActions';
import timesPerWeekActions from './timesPerWeekActions';
import sessionPackageActions from './sessionPackageActions';
import zoneActions from './zoneActions';
import attendeeActions from './attendeeActions';
import attendeeFreezeActions from './attendeeFreezeActions';
import branchFreezeActions from './branchFreezeActions';
import managerActions from './managerActions';
import locationActions from './locationActions';
import ageIntervalActions from './ageIntervalActions';
import timesPerMonthActions from './timesPerMonthActions';
import sessionCategoryActions from './sessionCategoryActions';
import meetingSchemaActions from './meetingSchemaActions';
import orderActions from './orderActions';
import becomeAPartnerActions from './becomeAPartnerAction';
import bannerActions from './bannerActions';
import userPackageActions from './userPackageAction';
import calendarActions from './calendarActions';
import meetingAttendeeActions from './meetingAttendeeActions';
import speakerAvailabilityActions from './speakerAvailabilityActions';
import classroomActions from './classroomActions';
import preferenceActions from './preferenceActions';
import subscriptionActions from './subscriptionActions';
import userOperationClaimActions from './userOperationClaimActions';
import paymentActions from './paymentActions';
import filterActions from './filterActions';
import resetStoreAction from './resetStoreAction';
import feedbackActions from './feedbackActions';
import speakerEffortActions from './speakerEffortActions';
import leadActions from './leadActions';
import priceActions from './priceActions';
import companyActions from './companyActions';
import campaignActions from './campaignActions';

const Actions = {
  authActions,
  screenActions,
  langActions,
  careerActions,
  companyPriceRequestActions,
  faqGroupActions,
  faqActions,
  userActions,
  staticDataActions,
  speakerActions,
  onboardActions,
  couponCodesActions,
  timesPerWeekActions,
  sessionPackageActions,
  zoneActions,
  attendeeActions,
  attendeeFreezeActions,
  branchFreezeActions,
  managerActions,
  locationActions,
  ageIntervalActions,
  timesPerMonthActions,
  sessionCategoryActions,
  meetingSchemaActions,
  orderActions,
  becomeAPartnerActions,
  bannerActions,
  userPackageActions,
  calendarActions,
  meetingAttendeeActions,
  speakerAvailabilityActions,
  classroomActions,
  preferenceActions,
  subscriptionActions,
  userOperationClaimActions,
  paymentActions,
  filterActions,
  resetStoreAction,
  feedbackActions,
  speakerEffortActions,
  leadActions,
  priceActions,
  companyActions,
  campaignActions,
};

export default Actions;
