import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import Toast from '../../../components/Common/Popup/Toast';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
  ButtonSize,
} from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import addDay from '../../../hooks/useAddDay';
import checkClaims from '../../../hooks/useCheckClaims';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const campaign = useSelector((e) => e.campaign.campaign);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const [createData, setCreateData] = useState(campaign);
  const [imageSrc, setImageSrc] = useState('');
  const redirect = '/campaign-list';

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.campaignActions.cleanState());
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
  };

  const onCreate = (e) => {
    e.preventDefault();
    dispatch(Actions.campaignActions.createAction(createData));
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setCreateData({
        ...createData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
    }
  };

  const addDetailRow = () => {
    setCreateData((prevData) => {
      const newId = prevData.campaignImages.length + 1;
      return {
        ...prevData,
        campaignImages: [
          ...prevData.campaignImages,
          {
            id: newId,
            imageName: '',
            imageSrc: '',
            imageExtension: '',
          },
        ],
      };
    });
  };

  const deleteDetailRow = (index) => {
    const updatedDetails = [...createData.campaignImages];
    updatedDetails.splice(index, 1);
    setCreateData({ ...createData, campaignImages: updatedDetails });
  };

  const onChangeDetails = (index, field, value) => {
    const updatedDetails = [...createData.campaignImages];
    if (field === 'imageSrc') {
      updatedDetails[index]['imageSrc'] = value.base64String;
      updatedDetails[index]['imageExtension'] = value.extension;
      updatedDetails[index]['url'] = value.fileName;
      updatedDetails[index]['imgSrc'] = value.imgSrc;
      setCreateData({ ...createData, campaignImages: updatedDetails });
    } else {
      updatedDetails[index][field] = value;
      setCreateData({ ...createData, campaignImages: updatedDetails });
    }
  };

  console.log(createData);
  const generateInsertStatement = (userId, operationClaimId) => {
    // Format operationClaimId as a string with single quotes
    return (
      `INSERT INTO \`m2t_prod\`.\`user_operation_claims\` ` +
      `(\`user_id\`, \`operation_claim_id\`, \`is_active\`, \`created_at\`) ` +
      `VALUES ('${userId}', '${operationClaimId}', '1', CURRENT_TIMESTAMP(6));`
    );
  };

  const userId = '48595';
  const startId = 1;
  const endId = 218;

  const sqlStatements = [];
  for (let i = startId; i <= endId; i++) {
    sqlStatements.push(generateInsertStatement(userId, i));
  }
  const sqlOutput = sqlStatements.join('\n');
  console.log(sqlOutput)
  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2 mt-sm-3">
                  <div className="col-12 col-md-1">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('campaign.add_new_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputLabelOnBorder
                      id={'title'}
                      inputType={InputType.Text}
                      labelText={t('campaign.title')}
                      inputValue={createData.title || ''}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-0 mt-2">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={createData.name || ''}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'zones'}
                      inputType={InputType.Text}
                      labelText={'Zones'}
                      inputValue={createData.zones || '1'}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      disabled
                    />
                  </div> */}
                  <div className="col-12 col-md-6 mt-md-2 mt-1">
                    <div className="col-12 d-flex justify-content-center">
                      {imageSrc ? (
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={imageSrc}
                          alt={imageSrc}
                          height={90}
                          style={{ maxHeight: '90px' }}
                        />
                      ) : null}
                    </div>
                    <CustomFileInput
                      id={'image'}
                      key={'image'}
                      hidden={true}
                      buttonText={t('fileUpload.upload_picture')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      func={(val) => {
                        setCreateData(
                          Object.assign({}, createData, {
                            image: val.base64String,
                            extension: val.extension,
                            url: val.fileName,
                          })
                        );
                        setImageSrc(val.imgSrc);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-2 mt-1">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={createData.startDateView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-2 mt-1">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={createData.endDateView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label
                      className="ms-2 position-absolute"
                      style={{
                        marginTop: '-1.25rem',
                        fontSize: '.9rem',
                      }}
                    >
                      <span className="h6 small bg-white text-muted px-1">
                        {t('campaign.content')}
                      </span>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={createData.content || ''}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setCreateData({
                          ...createData,
                          content: data,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <p className="mt-1">{t('campaign.explaining')}</p>
                  {createData?.campaignImages?.map((detail, index) => (
                    <React.Fragment key={index}>
                      <div className="d-md-flex align-items-center justify-content-md-evenly mt-md-4">
                        <div className="col-12 col-md-4 mt-2 mt-md-0">
                          <InputLabelOnBorder
                            id={`details[${index}].imageName`}
                            inputType={InputType.Text}
                            labelText={t('campaign.contentImage')}
                            inputValue={detail.imageName}
                            onChange={(e) =>
                              onChangeDetails(
                                index,
                                'imageName',
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6 mt-md-2 mt-1">
                          <div className="col-12 d-flex justify-content-center">
                            {detail.imgSrc ? (
                              <img
                                className={'img-fluid rounded-2 me-3'}
                                src={detail.imgSrc}
                                alt={detail.imgSrc}
                                height={90}
                                style={{ maxHeight: '90px' }}
                              />
                            ) : null}
                          </div>
                          <CustomFileInput
                            id={`details[${index}].imageSrc`}
                            key={`details[${index}].imageSrc`}
                            hidden={true}
                            buttonText={t('fileUpload.upload_picture')}
                            textColor={TextColors.Light}
                            buttonColor={ButtonType.Successbg}
                            fileType={'Image'}
                            func={(val) => {
                              onChangeDetails(index, 'imageSrc', val);
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-1 mt-2 mt-md-0">
                          <Button
                            buttonCol={12}
                            type={ButtonType.Danger}
                            size={ButtonSize.Small}
                            icon={ButtonIcon.TrashCan}
                            onClick={() => deleteDetailRow(index)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="col-12 col-md-3 mt-3">
                    <Button
                      type={ButtonType.Primary}
                      buttonCol={12}
                      text={t('campaign.contentImageAdd')}
                      onClick={addDetailRow}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'campaign'}
                        onClick={onCreate}
                        objectLengthRule={
                          Object.keys(createData).length > 3 ? false : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'campaign'}
        onClose={() => {
          setImageSrc('');
          setCreateData({});
          dispatch(Actions.campaignActions.cleanState());
          navigate(redirect);
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.banner.create,
    content
  );
};
export default Create;
