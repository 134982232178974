import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import HeadTags from '../HeadTags/HeadTags';
import {
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleUser,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CalendarModal from '../Modal/CalendarModal';
import Card from '../Card/Card';
import Button from '../Button/Button';
import Actions from '../../../store/redux/actions';
import { useTranslation } from 'react-i18next';
import BasicPopUp from '../Popup/BasicPopUp';
import { ToastContainer, toast } from 'react-toastify';
import calculateUTC from '../../../hooks/useCalculateUTC';
import getTimeZone from '../../../hooks/useGetTimeZone';
import Modal from '../Modal/Modal';
import findMyPath from '../../../hooks/useFindMyPath';
import useGTM from '../../../hooks/useGTM';
import addDay from '../../../hooks/useAddDay';
import MeetingDetails from '../../../pages/BackOffice/Operation/MeetingDetails';
import { Loader } from '../Loader/Loader';
import Toast from '../Popup/Toast';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import ActionDropdown from '../Dropdown/ActionDropdown';

function WeeklyCalendar({
  isSmallCalendar,
  sideOn,
  color,
  onCellSelect,
  fromRecompense,
  selectLaterState,
  modalData,
  recompenseDate,
  recompenseOrderItemId,
  recompenseMeetingAttendeeId,
  packageModel = {},
  fromMentorCalendarButton,
  emptyData = false,
  attendeeId = undefined,
  getTableData,
  removeWithSelect,
  fromSetDate,
  removeAction = null,
  fromEarnFreeSession,
  getSelectedDate,
  currentView,
  areaFromParent,
  controlPage = undefined,
  getMeIndex,
  area,
  languageLevel,
  learningPurposes,
  sendFilterData,
  filter,
}) {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);
  const navigate = useNavigate();
  const auth = useSelector((e) => e.auth.auth);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const onboarColor = useSelector(
    (e) => e.holdFilter?.filter?.UserArea?.key || e.onboard.onboard
  );
  const scrollToFirstinterval = useSelector((e) => e.holdFilter?.filter);

  const noAction = currentUserClaim?.length === 0;
  const date = fromRecompense
    ? moment(recompenseDate).locale(locale)
    : moment().locale(locale);
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.order.basketItems);
  const selectedSessions =
    orderItems?.unpayedOrderItemList?.[0].scheduleUserSelection
      .selectionOptions;
  const [createData, setCreateData] = useState(packageModel);
  const [selectedWeek, setSelectedWeek] = useState(date);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedDay, setSelectedDay] = useState({ time: '', day: '' });
  const [selectedCellColor, setSelectedCellColor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const currency = useSelector(
    (e) => e.preferenceOptions.preferenceCurrencyUnit
  );
  const userLevel = useSelector((e) => e.user.getById.languageLevel);
  const [isBasicOpen, setIsBasicOpen] = useState(false);
  const [selectedCellIndex, setSelectedCellIndex] = useState();
  const [recompensePopup, setRecompensePopup] = useState(false);
  const userInfo = useSelector((e) => e.user.getById);
  const currentUserCompanyId = userInfo.companyId;
  const isChild = userInfo?.isChild ?? null;
  const calendarTable = useSelector((e) => e.order.calendarTable);
  const levelGroups = useSelector(
    (e) => e.preferenceOptions.preferenceLevelGroup
  );
  const calendarLoading = useSelector((e) => e.calendar.showLoading);
  const allMentors = useSelector((e) => e.calendar.getAllFiltered);
  const selectedMentor = useSelector((e) => e.calendar.getBySpeakerId);
  const mentors = isSmallCalendar ? selectedMentor : allMentors;
  const popupDetails = useSelector((e) => e.calendar.getCellDatas);
  const getMeetings = useSelector((e) => e.calendar.getMeetings);
  const { sendEventToDataLayer } = useGTM();
  const handleClose = () => {
    setIsBasicOpen(false);
    setRecompensePopup(false);
  };
  const calendarTableRef = useRef(calendarTable);
  useEffect(() => {
    if (calendarTableRef.current.length < calendarTable.length) {
      //gtmAddToCart();
    }
    calendarTableRef.current = calendarTable;
  }, [calendarTable]);

  const [notifyMessage, setNotifyMesage] = useState('');
  useEffect(() => {
    if (notifyMessage !== '') {
      toast.info(notifyMessage, {
        onClose: () => {
          setNotifyMesage('');
          if (selectedDay.time !== '') {
            setSelectedDay({ time: '', day: '' });
          }
          setIsOpen(false);
        },
      });
    }
  }, [notifyMessage]);
  const customToastStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: 9999999,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const [packageAreaInfo, setPackageAreaInfo] = useState('');
  useEffect(() => {
    if (packageAreaInfo !== '') {
      toast.info(packageAreaInfo, {
        onClose: () => {
          setPackageAreaInfo('');
        },
      });
    }
  }, [packageAreaInfo]);
  const closePopup = () => {
    setSelectedDay({ time: '', day: '' });
    setSelectedCellColor(null);
    onCellSelect(null);
    setIsOpen(false);
    setIsBasicOpen(false);
    setPopupData({});
    dispatch(Actions.calendarActions.cleanState());
    setChangeModalContent(false);
  };

  const [sameDateIssue, setSameDateIssue] = useState('');
  useEffect(() => {
    if (sameDateIssue !== '') {
      toast.warning(sameDateIssue, {
        onClose: () => {
          setSameDateIssue('');
          if (selectedDay.time !== '') {
            setSelectedDay({ time: '', day: '' });
          }
        },
      });
    }
  }, [sameDateIssue]);

  const [dateIssue, setDateIssue] = useState({
    open: false,
    text: null,
    card: {},
  });
  const [popupData, setPopupData] = useState({});
  const openPopup = (date, event, qq, cellIndex) => {
    const cell = moment(date).day();
    setSelectedCellIndex(parseInt(`${cellIndex}${cell}`));
    setPopupData({ date: date, qq: qq, cellIndex: cellIndex });
    getSelectedDate({
      time: qq.time,
      filter: qq.day,
      popup: true,
      ids: event.target.id,
    });
  };

  function popupDataArrived(date, qq, cellIndex) {
    const cell = moment(date).day();
    if (!noAction && modalData?.length === 1) {
      setIsOpen(true);
      onCellSelect(selectedDay);
    } else if (modalData?.length === 1 && noAction) {
      onCellSelect(selectedDay);
      popupDetails?.contents?.map((contentItem) => {
        if (
          contentItem.attendeeAvailability !== 'Not Avaible' &&
          !fromRecompense
        ) {
          return onSelectMentor(
            contentItem,
            undefined,
            parseInt(`${cellIndex}${cell}`)
          );
        } else if (
          fromRecompense &&
          contentItem.attendeeAvailability !== 'Not Avaible'
        ) {
          return setOnRecompenseAction(
            <>
              <span
                className="d-flex position-relative mx-auto  justify-content-center fs-6 my-2"
                style={{
                  maxWidth: '550px',
                }}
              >
                <strong>{t('button.set_date_question')}</strong>
              </span>
              <div className="d-flex justify-content-around">
                <div className="col-4">
                  <Button
                    text={t('button.yes')}
                    type={ButtonType.Success}
                    textColor={TextColors.Light}
                    onClick={() => onSetdate(contentItem)}
                  />
                </div>
                <div className="col-4">
                  <Button
                    text={t('button.no')}
                    type={ButtonType.Danger}
                    textColor={TextColors.Light}
                    onClick={() => setOnRecompenseAction('')}
                  />
                </div>
              </div>
            </>
          );
        } else {
          return setSameDateIssue(
            <strong>{t('weeklyCalendarToast.same_day_issue')}</strong>
          );
        }
      });
    } else {
      setIsOpen(true);
      onCellSelect(selectedDay);
    }
    if (currentView !== 3) {
      setSelectedDate(date);
    }
  }

  useEffect(() => {
    if (popupDetails?.title !== undefined) {
      popupDataArrived(popupData.date, popupData.qq, popupData.cellIndex);
    }
  }, [popupDetails]);

  const [currentTime, setCurrentTime] = useState(new Date());
  const calendarRef = useRef(null);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 60000); // update the current time every minute

  //   return () => clearInterval(intervalId);
  // }, []);
  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (
      mentors &&
      mentors?.oneToOneSessions &&
      mentors?.groupSessions &&
      noAction
    ) {
      let allSessions;

      if (scrollToFirstinterval?.TypeOfService?.[0]?.value === '1') {
        allSessions = [...mentors?.groupSessions];
      } else {
        allSessions = [...mentors?.oneToOneSessions, ...mentors?.groupSessions];
      }

      if (allSessions?.length > 0) {
        const sortedSessions = allSessions.sort((a, b) => {
          const startTimeA = a.interval.split('-')[0];
          const startTimeB = b.interval.split('-')[0];
          return startTimeA.localeCompare(startTimeB);
        });

        const [startTime] = sortedSessions[0]?.interval.split('-');
        const timeSlotEl = calendarEl?.querySelector(
          `[data-time="${startTime}"]`
        );

        if (timeSlotEl) {
          calendarEl.scrollTop = timeSlotEl.offsetTop + 200;
        }
      }
    }
  }, [
    mentors?.groupSessions,
    mentors?.oneToOneSessions,
    mentors,
    scrollToFirstinterval,
  ]);

  const anotherDates = [];
  const reportDates = [];
  const [rDate, setRDate] = useState(null);
  const [offsetDays, setOffsetDays] = useState();

  useEffect(() => {
    const view =
      currentView === 1
        ? 7
        : currentView === 2
        ? 3
        : currentView === undefined
        ? 7
        : 1;
    if (!fromRecompense) {
      setOffsetDays(view);
    }

    if (reportDates?.length > 0 && !fromRecompense) {
      if (currentView !== 3) {
        setSelectedWeek(
          currentView !== 3 ? moment(date) : moment(reportDates[0])
        );
      }
      if (currentView === 3) {
        setSelectedDate(moment(reportDates[0]));
      }
    }
  }, [currentView]);

  const previousWeek = () => {
    let prevDates = [];
    let prevWeek = [];

    for (let dayOffset = 7; dayOffset > 0; dayOffset--) {
      let prevDate = moment(selectedWeek).subtract(dayOffset, 'days').toDate();
      if (!fromRecompense) {
        if (
          moment(prevDate).isSameOrAfter(moment(date, 'date')) ||
          moment(prevDate).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD')
        ) {
          prevDates.push(moment(prevDate));
        }
      }
      prevDates.push(moment(prevDate));
    }

    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
      var a = moment(prevDates[0]).add(dayOffset, 'days').toDate();
      prevWeek.push(moment(a));
    }
    setOffsetDays(7);
    const parentDate = new Date(prevWeek[1]).toISOString();
    getSelectedDate({ filter: parentDate, popup: false });
    setSelectedWeek(() => moment(prevWeek[0]));
  };
  const [countWeek, setCountWeek] = useState(false);

  let mainText =
    currentView === 3
      ? `${moment(selectedDate).format('D MMM')} `
      : `${
          offsetDays > 1 ? `${moment(selectedWeek).format('MMM D')}-` : ''
        } ${moment(selectedWeek)
          .add(fromRecompense ? offsetDays - 1 : 6, 'days')
          .format('MMM D, YYYY')}`;
  let prevText =
    currentView === 3
      ? `${moment(selectedDate).subtract(1, 'days').format('D, MMM, YYYY')}`
      : `${moment(selectedWeek).subtract(7, 'days').format('MMM D')} - ${moment(
          selectedWeek
        )
          .subtract(1, 'days')
          .format('MMM D, YYYY')}`;
  let nextText =
    currentView === 3
      ? `${moment(selectedDate).add(1, 'days').format('D, MMM, YYYY')}`
      : `${moment(selectedWeek).add(7, 'days').format('MMM D')} - ${moment(
          selectedWeek
        )
          .add(13, 'days')
          .format('MMM D, YYYY')}`;
  const nextWeek = () => {
    let nextDates = [];
    let nxtWeek = [];
    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
      let nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
      nextDates.push(moment(nextDate));
    }

    for (let dayOffset = 1; dayOffset < 8; dayOffset++) {
      var a = moment(nextDates[6]).add(dayOffset, 'days').toDate();
      nxtWeek.push(moment(a));
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const checkDate = new Date(date);
    const clc = Math.abs(checkDate - currentDate);
    const ruleDate = checkDate < currentDate ? -1 : 1;
    const dayDiff = Math.floor(clc / (24 * 60 * 60 * 1000));
    const selectedWeekDate = new Date(nxtWeek[0]);
    selectedWeekDate.setHours(0, 0, 0, 0);
    const recompensedDateRule = moment(
      addDays(recompenseDate, 7 - dayDiff * ruleDate)
    );
    const selectedWeekRule = moment(selectedWeekDate);
    if (
      fromRecompense &&
      recompensedDateRule.day() === selectedWeekRule.day() &&
      recompensedDateRule.month() === selectedWeekRule.month()
    ) {
      if (ruleDate === 1) {
        if (dayDiff + 4 <= 7) {
          setOffsetDays(dayDiff + 4);
        } else if (dayDiff >= 4 && !countWeek) {
          setOffsetDays(7);
          setCountWeek(true);
        } else {
          setOffsetDays(
            dayDiff + 4 <= 7 ? dayDiff + 4 : dayDiff + 4 > 7 ? dayDiff - 3 : 7
          );
        }
      } else {
        setOffsetDays(4 - dayDiff);
      }
    }
    const parentDate = new Date(nxtWeek[0]).toISOString();
    getSelectedDate({ filter: parentDate, popup: false });
    setSelectedWeek(() => moment(nxtWeek[0]));
  };
  const previousDay = () => {
    const dNow = selectedDate;
    const nDate = moment(dNow).subtract(1, 'day').toDate();
    if (!reportDates.some((date) => date.isSame(moment(nDate), 'date'))) {
      const parentDate = new Date(nDate).toISOString();
      getSelectedDate({ filter: parentDate, popup: false });
      setSelectedWeek(moment(nDate));
    }
    setSelectedDate(moment(nDate));
  };

  const nextDay = () => {
    const dNow = selectedDate;
    const nDate = moment(dNow).add(1, 'day').toDate();
    if (!reportDates.some((date) => date.isSame(moment(nDate), 'date'))) {
      const parentDate = new Date(nDate).toISOString();
      getSelectedDate({ filter: parentDate, popup: false });
      setSelectedWeek(moment(nDate));
    }
    setSelectedDate(moment(nDate));
  };

  const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate?.setDate(date?.getDate() + days);
    return newDate;
  };
  useEffect(() => {
    if (fromRecompense) {
      const currentDate = new Date();
      const checkDate = new Date(date);
      currentDate.setHours(0, 0, 0, 0);
      const ruleDate = checkDate < currentDate ? -1 : 1;
      const calc = Math.abs(checkDate - currentDate);
      const diff = Math.floor(calc / (24 * 60 * 60 * 1000)) * ruleDate;
      if (diff === 0) {
        setSelectedWeek(moment(addDays(currentDate, 0)));
        setRDate(moment(addDays(currentDate, 0)));
        setOffsetDays(7);
      } else if (diff > 0 && diff < 10) {
        setOffsetDays(7);
        setRDate(moment(addDays(recompenseDate, -diff)));
        setSelectedWeek(moment(addDays(recompenseDate, -diff)));
      } else if (diff >= 10) {
        setOffsetDays(7);
        setRDate(moment(addDays(recompenseDate, -10)));
        setSelectedWeek(moment(addDays(recompenseDate, -10)));
      } else if (diff < 0 && diff > -5) {
        setSelectedWeek(moment(addDays(currentDate, 0)));
        setRDate(moment(addDays(currentDate, 0)));
        setOffsetDays(7);
      } else if (diff < -4) {
        const offset = Math.abs(diff);
        const offsetDays = 11 - offset;
        setSelectedWeek(moment(addDays(currentDate, 0)));
        setRDate(moment(addDays(currentDate, 0)));
        setOffsetDays(offsetDays);
      }
    }
  }, [fromRecompense]);

  for (
    let dayOffset = 0;
    dayOffset < (currentView === 3 ? 7 : offsetDays);
    dayOffset++
  ) {
    const nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
    reportDates.push(moment(nextDate));
    anotherDates.push(moment(nextDate));
    anotherDates.push(moment(...anotherDates, reportDates));
  }

  let timeSlots = [];
  const timeSlotsNormal = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      timeSlotsNormal.push(
        `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`
      );
    }
  }
  const timeSlotsSmall = [];
  for (let hour = 0; hour < 24; hour += 4) {
    timeSlotsSmall.push(
      `${hour.toString().padStart(2, '0')} - ${(hour + 4)
        .toString()
        .padStart(2, '0')}`
    );
  }
  isSmallCalendar
    ? (timeSlots = timeSlotsSmall)
    : (timeSlots = timeSlotsNormal);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const defaultView = screenWidth < 768;
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setMobileView(window.innerWidth < 577);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderTableHeaders = (nDate) => {
    return currentView === 3 ? (
      <div
        className="d-flex align-items-center justify-content-between pb-2 m-0 text-center text-nowrap"
        style={{ overflowX: 'auto', width: '100%', maxWidth: '100vw' }}
      >
        {reportDates?.map((day, index) => {
          const isSameDay = moment(nDate)?.isSame(day, 'day');
          return (
            <span
              role="button"
              key={`${day?.format('YYYY-MM-DD')}-${index}`}
              className={`${
                !defaultView ? 'btn btn-default' : ''
              } rounded-4 border py-2 mx-1 d-inline-flex align-items-center justify-content-center flex-grow-1 ${
                isSameDay ? 'selected bg-t-calendar text-white' : 'bg-light'
              }`}
              style={{ fontSize: '1em', width: '30px' }}
              onClick={() => {
                if (!defaultView) {
                  setSelectedDate(day);
                }
              }}
              onTouchStart={(e) => {
                e.preventDefault();
                if (defaultView) {
                  setSelectedDate(day);
                }
              }}
            >
              {day?.format('ddd')}
              <br />
              {day?.format('D')}
            </span>
          );
        })}
      </div>
    ) : (
      <tr
        className="text-muted position-sticky bg-light top-0"
        style={{ zIndex: '1' }}
      >
        <th
          style={{ width: '50px' }}
          className="p-0 m-0 text-center align-middle "
        >
          {`UTC ${getTimeZone()}`}
        </th>
        {fromRecompense
          ? reportDates.map((day) => (
              <th
                key={day?.format('dddd, MMMM Do YYYY')}
                className="text-center p-0 m-0"
                style={isSmallCalendar ? { width: '35px' } : {}}
              >
                {sideOn ? day?.format('dd') : day?.format('ddd')}
                <br />
                {sideOn ? undefined : day?.format('D')}
              </th>
            ))
          : reportDates.map((day) => (
              <th
                key={day?.format('dddd, MMMM Do YYYY')}
                className="text-center p-0 m-0"
                style={isSmallCalendar ? { width: '35px' } : {}}
              >
                {sideOn ? day?.format('dd') : day?.format('ddd')}
                <br />
                {sideOn ? undefined : day?.format('D')}
              </th>
            ))}
      </tr>
    );
  };

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return daysOfWeek[dayOfWeek];
  }
  function convertDateArray(array) {
    const convertedArray = array?.map((dateTimeString) => {
      const dateTime = new Date(dateTimeString);
      const hours = dateTime.getUTCHours();
      const minutes = dateTime.getUTCMinutes();
      const time1 = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
      const date1 = dateTime.toISOString().split('T')[0];
      const result = addDay(time1, date1, false, false, false, true);
      return { time: result.nTime, date: result.resultDate };
    });

    return convertedArray;
  }

  function getSelectedDatesWithCondition(array, condition) {
    const filteredArray = array?.filter((item) =>
      condition ? item.meetingSchemaId === null : item.meetingSchemaId !== null
    );
    const selectedDates = filteredArray?.map((item) => item.selectedDate);

    return selectedDates || [];
  }

  function colorMeIn(time, selectedDay, day, type, index) {
    const array = popupDetails?.contents?.[0];
    const isSessionNotAvailable =
      array !== undefined &&
      array?.date === day.format('DD-MM-YYYY') &&
      calculateUTC(array?.interval?.split('-')[0]) === time &&
      array?.attendeeAvailability === 'Not Avaible';

    if (type === 'Private') {
      const getFirst = getSelectedDatesWithCondition(
        createData?.selectionOptions,
        true
      );

      const checkMe = convertDateArray(getFirst);
      let setColorMe = '';

      const checkValueInArray = (array, time, day) => {
        return array.some(
          (item) => item.time === time && item.date === day.format('YYYY-MM-DD')
        );
      };

      if (checkMe?.length > 0) {
        const isValueInArray = checkValueInArray(checkMe, time, day);
        setColorMe = isValueInArray
          ? 'bg-secondary bg-opacity-50'
          : calculateUTC(time) === selectedDay.time &&
            day.format('YYYY-MM-DD') === selectedDay.day &&
            (index !== undefined
              ? selectedCellColor?.index === index &&
                selectedCellColor?.type === 'Private'
              : true)
          ? 'bg-secondary bg-opacity-50'
          : 'bg-success';
      } else {
        setColorMe =
          calculateUTC(time) === selectedDay.time &&
          day.format('YYYY-MM-DD') === selectedDay.day &&
          (index !== undefined
            ? selectedCellColor?.index === index &&
              selectedCellColor?.type === 'Private'
            : true)
            ? `bg-secondary bg-opacity-50 ${
                isSessionNotAvailable && !noAction
                  ? 'border border-2 border-danger'
                  : ''
              }`
            : `bg-success ${
                isSessionNotAvailable && !noAction
                  ? 'border border-2 border-danger'
                  : ''
              }`;
      }

      return setColorMe;
    }
    if (type === 'Group') {
      const getFirst = getSelectedDatesWithCondition(
        createData?.selectionOptions,
        false
      );

      const checkMe = convertDateArray(getFirst);
      let setColorMe = '';
      const checkValueInArray = (array, time, day) => {
        return array.some(
          (item) => item.time === time && item.date === day.format('YYYY-MM-DD')
        );
      };
      if (checkMe?.length > 0) {
        const isValueInArray = checkValueInArray(checkMe, time, day);
        setColorMe = isValueInArray
          ? 'bg-secondary bg-opacity-50'
          : calculateUTC(time) === selectedDay.time &&
            day.format('YYYY-MM-DD') === selectedDay.day &&
            (index !== undefined
              ? selectedCellColor?.index === index &&
                selectedCellColor?.type === 'Private'
              : true)
          ? 'bg-secondary bg-opacity-50'
          : 'bg-warning';
      } else {
        setColorMe =
          calculateUTC(time, true) === selectedDay.time &&
          day.format('YYYY-MM-DD') === selectedDay.day &&
          (index !== undefined
            ? selectedCellColor?.index === index &&
              selectedCellColor?.type === 'Group'
            : true)
            ? `bg-secondary bg-opacity-50 ${
                isSessionNotAvailable && !noAction
                  ? 'border border-2 border-danger'
                  : ''
              }`
            : `bg-warning ${
                isSessionNotAvailable && !noAction
                  ? 'border border-2 border-danger'
                  : ''
              }`;
      }
      return setColorMe;
    }
  }

  const renderTableRows = () => {
    return timeSlots?.map((time, i) => (
      <tr key={`${time}_${i + 2}`} className="p-0 m-0 ">
        <td
          key={`${time}_${i + 1}`}
          data-time={time}
          style={{
            width: currentView === 3 ? '40px' : '',
            maxWidth: currentView === 3 ? '40px' : '',
          }}
          className="p-0 m-0 text-center align-middle text-muted"
        >
          {time}
        </td>
        {currentView === 3 ? (
          <td
            key={`${selectedDate?.format('YYYY-MM-DD')}-${time}`}
            className={`p-0 m-0`}
            contentEditable={false}
            style={{
              height: '50px',
              fontSize: '12px',
            }}
          >
            <div className="d-flex text-white ">
              {color === undefined ? (
                <>
                  {!isSmallCalendar ? (
                    <>
                      {mentors?.oneToOneSessions?.map(
                        (value, index) =>
                          time === calculateUTC(value.interval.split('-')[0]) &&
                          getDayOfWeek(selectedDate?.format('YYYY-MM-DD')) ===
                            getDayOfWeek(
                              moment(value.date, 'DD-MM-YYYY').format(
                                'YYYY-MM-DD'
                              )
                            ) && (
                            <div
                              onClick={(e) => {
                                setSelectedDay({
                                  time: time,
                                  day: selectedDate?.format('YYYY-MM-DD'),
                                });
                                setSelectedCellColor({
                                  type: 'Private',
                                  index: index,
                                });
                                let dateNow = {
                                  time: time,
                                  day: selectedDate?.format('YYYY-MM-DD'),
                                };
                                setSelectedDate(moment(selectedDate).day);
                                openPopup(moment(selectedDate), e, dateNow, i);
                              }}
                              key={`f_d${index + 1}_${
                                value.interval.split('-')[0]
                              }`}
                              id={value.speakerIds}
                              role={'button'}
                              style={{
                                height:
                                  noAction && isSmallCalendar
                                    ? '24.8px'
                                    : '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              className={`col ${
                                selectedCellColor !== null
                                  ? selectedCellColor?.type === 'Private' &&
                                    selectedCellColor?.index === index
                                    ? 'bg-secondary bg-opacity-50'
                                    : 'bg-success'
                                  : colorMeIn(
                                      calculateUTC(time, true),
                                      selectedDay,
                                      moment(selectedDate),
                                      'Private'
                                    )
                              } ${!noAction ? '' : 'text-center'} text-white ${
                                time === selectedDay.time &&
                                selectedDate?.format('DD-MM-YYYY') ===
                                  selectedDay.day
                                  ? 'border border-2 border-danger'
                                  : ''
                              }`}
                            >
                              {/* {!noAction && (
                                <div className="d-flex mt-1">
                                  <div className="  justify-content-center  align-items-center top-0 start-0 ">
                                    <FontAwesomeIcon
                                      icon={faUser}
                                      className="text-white mx-1"
                                    />
                                  </div>

                                  <div className=" ms-auto  align-items-center top-0 end-0">
                                    <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                      2
                                      <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        className="text-white ms-auto  mx-1"
                                      />
                                    </span>
                                    <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                      3
                                      <FontAwesomeIcon
                                        icon={faSquare}
                                        className="text-white   mx-1"
                                      />
                                    </span>
                                  </div>
                                </div>
                              )} */}

                              <span
                                id={value.speakerIds}
                                className={
                                  !noAction
                                    ? `d-flex align-items-center justify-content-center`
                                    : ''
                                }
                              >
                                {modalData?.length === 1 ? (
                                  ' '
                                ) : (
                                  <>
                                    {noAction && (
                                      <span
                                        id={value.speakerIds}
                                        className="me-1"
                                      >
                                        {value.sessionCount}
                                      </span>
                                    )}

                                    <span
                                      id={value.speakerIds}
                                      className="d-none d-sm-flex"
                                    >
                                      {!noAction && (
                                        <span
                                          id={value.speakerIds}
                                          className="me-1"
                                        >
                                          {value.sessionCount}
                                        </span>
                                      )}
                                      {value.sessionCount === 1
                                        ? t('calendar.session')
                                        : t('calendar.sessions')}
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          )
                      )}

                      {mentors?.groupSessions?.map(
                        (value, index) =>
                          time === calculateUTC(value.interval.split('-')[0]) &&
                          getDayOfWeek(selectedDate?.format('YYYY-MM-DD')) ===
                            getDayOfWeek(
                              moment(value.date, 'DD-MM-YYYY').format(
                                'YYYY-MM-DD'
                              )
                            ) && (
                            <div
                              id={value.speakerIds}
                              onClick={(e) => {
                                setSelectedDay({
                                  time: time,
                                  day: selectedDate?.format('YYYY-MM-DD'),
                                });
                                setSelectedCellColor({
                                  type: 'Group',
                                  index: index,
                                });
                                let dateNow = {
                                  time: time,
                                  day: selectedDate?.format('YYYY-MM-DD'),
                                };
                                openPopup(moment(selectedDate), e, dateNow, i);
                              }}
                              key={`k_d${index + 1}_${
                                value.interval.split('-')[0]
                              }`}
                              role={'button'}
                              style={{
                                height:
                                  noAction && isSmallCalendar
                                    ? '24.8px'
                                    : '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              className={`col ${
                                selectedCellColor !== null
                                  ? selectedCellColor?.type === 'Group' &&
                                    selectedCellColor?.index === index
                                    ? 'bg-secondary bg-opacity-50'
                                    : 'bg-warning'
                                  : colorMeIn(
                                      calculateUTC(time, true),
                                      selectedDay,
                                      moment(selectedDate),
                                      'Group'
                                    )
                              } text-center text-white ${
                                time === selectedDay.time &&
                                selectedDate?.format('DD-MM-YYYY') ===
                                  selectedDay.day
                                  ? 'border border-3 border-end-0 border-danger'
                                  : ''
                              }`}
                            >
                              {/* {!noAction && (
                                <div className="d-flex mt-1">
                                  <div className="  justify-content-center  align-items-center top-0 start-0 ">
                                    <FontAwesomeIcon
                                      icon={faUser}
                                      className="text-white mx-1"
                                    />
                                  </div>

                                  <div className=" ms-auto  align-items-center top-0 end-0">
                                    <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                      2
                                      <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        className="text-white ms-auto  mx-1"
                                      />
                                    </span>
                                    <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                      3
                                      <FontAwesomeIcon
                                        icon={faSquare}
                                        className="text-white   mx-1"
                                      />
                                    </span>
                                  </div>
                                </div>
                              )} */}
                              <span
                                id={value.speakerIds}
                                className={
                                  !noAction
                                    ? `d-flex align-items-center justify-content-center`
                                    : ''
                                }
                              >
                                {modalData?.length === 1 ? (
                                  ' '
                                ) : (
                                  <>
                                    {noAction && (
                                      <span
                                        id={value.speakerIds}
                                        className="me-1"
                                      >
                                        {value.sessionCount}
                                      </span>
                                    )}
                                    <span
                                      id={value.speakerIds}
                                      className="d-none d-sm-flex"
                                    >
                                      {!noAction && (
                                        <span
                                          id={value.speakerIds}
                                          className="me-1"
                                        >
                                          {value.sessionCount}
                                        </span>
                                      )}
                                      {value.sessionCount === 1
                                        ? t('calendar.session')
                                        : t('calendar.sessions')}
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          )
                      )}
                    </>
                  ) : (
                    <>
                      {mentors?.oneToOneSessions?.map((value, index) => {
                        const start = calculateUTC(time.split(' -')[0]);
                        const end = calculateUTC(time.split('- ')[1]);
                        if (
                          getDayOfWeek(selectedDate?.format('YYYY-MM-DD')) ===
                            getDayOfWeek(
                              moment(value.date, 'DD-MM-YYYY').format(
                                'YYYY-MM-DD'
                              )
                            ) &&
                          start <= value.interval &&
                          end > value.interval
                        ) {
                          return (
                            <div
                              key={`d_d${index + 1}_${value.interval}`}
                              style={{
                                width: '20px',
                                fontSize: '12px',
                                height: '24.8px',
                              }}
                              className={`col bg-success`}
                            ></div>
                          );
                        }

                        return null;
                      })}

                      {mentors?.groupSessions?.map((value, index) => {
                        const start = calculateUTC(time.split(' -')[0]);
                        const end = calculateUTC(time.split('- ')[1]);
                        if (
                          getDayOfWeek(selectedDate?.format('YYYY-MM-DD')) ===
                            getDayOfWeek(
                              moment(value.date, 'DD-MM-YYYY').format(
                                'YYYY-MM-DD'
                              )
                            ) &&
                          start <= value.interval &&
                          end > value.interval
                        ) {
                          return (
                            <div
                              key={`t_d${index + 1}_${value.interval}`}
                              style={{
                                width: '20px',
                                fontSize: '12px',
                                height: '24.8px',
                              }}
                              className={`col  bg-warning`}
                            ></div>
                          );
                        }

                        return null;
                      })}
                    </>
                  )}
                </>
              ) : color === 'Private' ? (
                <div
                  className={`col text-center text-white ${colorMeIn(
                    calculateUTC(time, true),
                    selectedDay,
                    moment(selectedDate),
                    'Private'
                  )}`}
                  role={'button'}
                  onClick={(e) => {
                    setSelectedDay({
                      time: time,
                      day: selectedDate?.format('YYYY-MM-DD'),
                    });
                    let dateNow = {
                      time: time,
                      day: selectedDate?.format('YYYY-MM-DD'),
                    };
                    openPopup(moment(selectedDay?.day), e, dateNow, i);
                  }}
                  key={`s_d${i}`}
                >
                  {mentors?.oneToOneSessions?.map((value, i) => {
                    let text = '';
                    if (
                      time === calculateUTC(value.interval.split('-')[0]) &&
                      getDayOfWeek(selectedDate?.format('YYYY-MM-DD')) ===
                        getDayOfWeek(
                          moment(value.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                        )
                    ) {
                      text = (
                        <>
                          <span
                            id={value.speakerIds}
                            key={`${value.interval.split('-')[0]}_${i + 1}`}
                            style={{
                              height:
                                noAction && isSmallCalendar
                                  ? '24.8px'
                                  : noAction && '50px',
                              maxHeight:
                                noAction && isSmallCalendar
                                  ? '24.8px'
                                  : noAction && '50px',
                            }}
                          >
                            {/* {!noAction && (
                              <div className="d-flex mt-1">
                                <div className="  justify-content-center  align-items-center top-0 start-0 ">
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    className="text-white mx-1"
                                  />
                                </div>

                                <div className=" ms-auto  align-items-center top-0 end-0">
                                  <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                    2
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                      className="text-white ms-auto  mx-1"
                                    />
                                  </span>
                                  <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                    3
                                    <FontAwesomeIcon
                                      icon={faSquare}
                                      className="text-white   mx-1"
                                    />
                                  </span>
                                </div>
                              </div>
                            )} */}
                            <span
                              id={value.speakerIds}
                              style={{
                                height:
                                  noAction && isSmallCalendar
                                    ? '24.8px'
                                    : noAction && '50px',
                                maxHeight:
                                  noAction && isSmallCalendar
                                    ? '24.8px'
                                    : noAction && '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {modalData?.length === 1 ? (
                                ' '
                              ) : (
                                <>
                                  {noAction && (
                                    <span
                                      id={value.speakerIds}
                                      className="me-1"
                                    >
                                      {value.sessionCount}
                                    </span>
                                  )}
                                  <br />
                                  <span
                                    id={value.speakerIds}
                                    className="d-none d-sm-flex"
                                  >
                                    {!noAction && (
                                      <span
                                        id={value.speakerIds}
                                        className="me-1"
                                      >
                                        {value.sessionCount}
                                      </span>
                                    )}
                                    {value.sessionCount === 1
                                      ? t('calendar.session')
                                      : t('calendar.sessions')}
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                        </>
                      );
                    }
                    return text;
                  })}
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    setSelectedDay({
                      time: time,
                      day: selectedDate?.format('YYYY-MM-DD'),
                    });
                    let dateNow = {
                      time: time,
                      day: selectedDate?.format('YYYY-MM-DD'),
                    };
                    openPopup(moment(selectedDay?.day), e, dateNow, i);
                  }}
                  key={`fs_d${i}`}
                  className={`col text-center text-white ${colorMeIn(
                    calculateUTC(time, true),
                    selectedDay,
                    moment(selectedDate),
                    'Group'
                  )}`}
                  role={'button'}
                >
                  {mentors?.groupSessions?.map((value, i) => {
                    let text = '';
                    if (
                      time === calculateUTC(value.interval.split('-')[0]) &&
                      getDayOfWeek(selectedDate?.format('YYYY-MM-DD')) ===
                        getDayOfWeek(
                          moment(value.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                        )
                    ) {
                      text = (
                        <>
                          <span
                            id={value.speakerIds}
                            key={`${value.interval.split('-')[0]}_${i + 1}`}
                            style={{
                              height:
                                noAction && isSmallCalendar
                                  ? '24.8px'
                                  : noAction && '50px',
                              maxHeight:
                                noAction && isSmallCalendar
                                  ? '24.8px'
                                  : noAction && '50px',
                            }}
                          >
                            {/* {!noAction && (
                              <div className="d-flex mt-1">
                                <div className="  justify-content-center  align-items-center top-0 start-0 ">
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    className="text-white mx-1"
                                  />
                                </div>

                                <div className=" ms-auto  align-items-center top-0 end-0">
                                  <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                    2
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                      className="text-white ms-auto  mx-1"
                                    />
                                  </span>
                                  <span className="  justify-content-center  align-items-center top-0 end-0 ">
                                    3
                                    <FontAwesomeIcon
                                      icon={faSquare}
                                      className="text-white   mx-1"
                                    />
                                  </span>
                                </div>
                              </div>
                            )} */}
                            <span
                              id={value.speakerIds}
                              style={{
                                height:
                                  noAction && isSmallCalendar
                                    ? '24.8px'
                                    : noAction && '50px',
                                maxHeight:
                                  noAction && isSmallCalendar
                                    ? '24.8px'
                                    : noAction && '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {modalData?.length === 1 ? (
                                ' '
                              ) : (
                                <>
                                  {noAction && (
                                    <span
                                      id={value.speakerIds}
                                      className="me-1"
                                    >
                                      {value.sessionCount}
                                    </span>
                                  )}
                                  <br />
                                  <span
                                    id={value.speakerIds}
                                    className="d-none d-sm-flex"
                                  >
                                    {!noAction && (
                                      <span
                                        id={value.speakerIds}
                                        className="me-1"
                                      >
                                        {value.sessionCount}
                                      </span>
                                    )}
                                    {value.sessionCount === 1
                                      ? t('calendar.session')
                                      : t('calendar.sessions')}
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                        </>
                      );
                    }
                    return text;
                  })}
                </div>
              )}
            </div>
          </td>
        ) : (
          reportDates.map((day, dayIndex) => (
            <td
              key={`${day.format('YYYY-MM-DD')}-${time}-${dayIndex}`}
              className={`p-0 m-0`}
              contentEditable={false}
              style={{
                height: isSmallCalendar ? '24.8px' : '50px',
                fontSize: '12px',
              }}
            >
              {
                <div className="d-flex text-white">
                  {color === undefined ? (
                    <>
                      {!isSmallCalendar ? (
                        <>
                          {mentors?.oneToOneSessions?.map(
                            (value, index) =>
                              time ===
                                calculateUTC(value.interval.split('-')[0]) &&
                              getDayOfWeek(day.format('YYYY-MM-DD')) ===
                                getDayOfWeek(
                                  moment(
                                    addDay(value.interval, value.date),
                                    'DD-MM-YYYY'
                                  ).format('YYYY-MM-DD')
                                ) && (
                                <div
                                  id={value.speakerIds}
                                  onClick={(e) => {
                                    setSelectedCellColor({
                                      type: 'Private',
                                      index: index,
                                    });
                                    setSelectedDay({
                                      time: time,
                                      day: day.format('YYYY-MM-DD'),
                                    });
                                    let dateNow = {
                                      time: time,
                                      day: day.format('YYYY-MM-DD'),
                                    };
                                    openPopup(moment(day), e, dateNow, i);
                                  }}
                                  key={`f_d${index + 1}_${
                                    value.interval.split('-')[0]
                                  }-Private`}
                                  role={'button'}
                                  style={{
                                    height:
                                      noAction && isSmallCalendar
                                        ? '24.8px'
                                        : '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  className={`col ${
                                    selectedCellColor !== null
                                      ? selectedCellColor?.type === 'Private' &&
                                        selectedCellColor?.index === index
                                        ? 'bg-secondary bg-opacity-50'
                                        : colorMeIn(
                                            calculateUTC(time, true),
                                            selectedDay,
                                            day,
                                            'Private',
                                            index
                                          )
                                      : colorMeIn(
                                          calculateUTC(time, true),
                                          selectedDay,
                                          day,
                                          'Private',
                                          index
                                        )
                                  } text-center text-white ${
                                    time === selectedDay.time &&
                                    day.format('DD-MM-YYYY') === selectedDay.day
                                      ? 'border border-3 border-end-0 border-danger'
                                      : ''
                                  }`}
                                >
                                  <span id={value.speakerIds}>
                                    {modalData?.length === 1 ? (
                                      ' '
                                    ) : (
                                      <>
                                        {noAction && (
                                          <span
                                            id={value.speakerIds}
                                            className="me-1"
                                          >
                                            {value.sessionCount}
                                          </span>
                                        )}

                                        <span id={value.speakerIds}>
                                          {!noAction && (
                                            <span
                                              id={value.speakerIds}
                                              className={`me-1 ${
                                                value.sessionCount < 2 &&
                                                'd-none d-sm-flex justify-content-center'
                                              }`}
                                              style={{
                                                fontSize:
                                                  value.sessionCount > 10 &&
                                                  mobileView
                                                    ? '0.58rem'
                                                    : '0.8rem',
                                              }}
                                            >
                                              {value.sessionCount}
                                            </span>
                                          )}
                                          <span
                                            id={value.speakerIds}
                                            className="d-none d-sm-flex"
                                          >
                                            {value.sessionCount === 1
                                              ? t('calendar.session')
                                              : t('calendar.sessions')}
                                          </span>
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </div>
                              )
                          )}

                          {mentors?.groupSessions?.map(
                            (value, index) =>
                              time ===
                                calculateUTC(value.interval.split('-')[0]) &&
                              getDayOfWeek(day.format('YYYY-MM-DD')) ===
                                getDayOfWeek(
                                  moment(
                                    addDay(value.interval, value.date),
                                    'DD-MM-YYYY'
                                  ).format('YYYY-MM-DD')
                                ) && (
                                <div
                                  id={value.speakerIds}
                                  onClick={(e) => {
                                    setSelectedCellColor({
                                      type: 'Group',
                                      index: index,
                                    });
                                    setSelectedDay({
                                      time: time,
                                      day: day.format('YYYY-MM-DD'),
                                    });
                                    let dateNow = {
                                      time: time,
                                      day: day.format('YYYY-MM-DD'),
                                    };
                                    openPopup(moment(day), e, dateNow, i);
                                  }}
                                  key={`k_d${index + 1}_${
                                    value.interval.split('-')[0]
                                  }-Group`}
                                  role={'button'}
                                  style={{
                                    height:
                                      noAction && isSmallCalendar
                                        ? '24.8px'
                                        : '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  className={`col ${
                                    selectedCellColor !== null
                                      ? selectedCellColor?.type === 'Group' &&
                                        selectedCellColor?.index === index
                                        ? 'bg-secondary bg-opacity-50'
                                        : colorMeIn(
                                            calculateUTC(time, true),
                                            selectedDay,
                                            day,
                                            'Group',
                                            index
                                          )
                                      : colorMeIn(
                                          calculateUTC(time, true),
                                          selectedDay,
                                          day,
                                          'Group',
                                          index
                                        )
                                  } text-center text-white ${
                                    time === selectedDay.time &&
                                    day.format('DD-MM-YYYY') === selectedDay.day
                                      ? 'border border-3 border-end-0 border-danger'
                                      : ''
                                  }`}
                                >
                                  <span
                                    id={value.speakerIds}
                                    className={
                                      !noAction
                                        ? `d-flex align-items-center justify-content-center`
                                        : ''
                                    }
                                  >
                                    {modalData?.length === 1 ? (
                                      ' '
                                    ) : (
                                      <>
                                        {noAction && (
                                          <span
                                            id={value.speakerIds}
                                            className="me-1"
                                          >
                                            {value.sessionCount}
                                          </span>
                                        )}
                                        <span id={value.speakerIds}>
                                          {!noAction && (
                                            <span
                                              id={value.speakerIds}
                                              className={`me-1 ${
                                                value.sessionCount < 2 &&
                                                'd-none d-sm-flex justify-content-center'
                                              }`}
                                              style={{
                                                fontSize:
                                                  value.sessionCount > 10 &&
                                                  mobileView
                                                    ? '0.58rem'
                                                    : '0.8rem',
                                              }}
                                            >
                                              {value.sessionCount}
                                            </span>
                                          )}
                                          <span
                                            id={value.speakerIds}
                                            className="d-none d-sm-flex"
                                          >
                                            {value.sessionCount === 1
                                              ? t('calendar.session')
                                              : t('calendar.sessions')}
                                          </span>
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </div>
                              )
                          )}
                        </>
                      ) : (
                        <>
                          {mentors?.oneToOneSessions?.map((value, index) => {
                            const startUTC = moment.utc(
                              `${value.date} ${value.interval.split('-')[0]}`,
                              'DD-MM-YYYY HH:mm'
                            );
                            const endUTC = moment.utc(
                              `${value.date} ${value.interval.split('-')[1]}`,
                              'DD-MM-YYYY HH:mm'
                            );

                            const startLocal = startUTC.local();
                            const endLocal = endUTC.local();

                            const start = startLocal.format('HH:mm');
                            const end = endLocal.format('HH:mm');

                            const slotStart = time.split(' -')[0];
                            const slotEnd = time.split('- ')[1];

                            if (
                              getDayOfWeek(day.format('YYYY-MM-DD')) ===
                                getDayOfWeek(startLocal.format('YYYY-MM-DD')) &&
                              start <= slotEnd &&
                              end > slotStart
                            ) {
                              const nextDayStart = moment(startLocal)
                                .add(1, 'day')
                                .startOf('day');
                              const nextDayEnd = moment(startLocal)
                                .add(1, 'day')
                                .endOf('day');

                              return (
                                <React.Fragment
                                  key={`d_d${index + 1}_${value.interval}`}
                                >
                                  <div
                                    style={{
                                      width: '20px',
                                      fontSize: '12px',
                                      height: '24.8px',
                                    }}
                                    className={`col bg-success`}
                                  ></div>
                                  {endLocal.isAfter(nextDayStart) &&
                                    endLocal.isBefore(nextDayEnd) && (
                                      <div
                                        style={{
                                          width: '20px',
                                          fontSize: '12px',
                                          height: '24.8px',
                                        }}
                                        className={`col bg-success`}
                                      ></div>
                                    )}
                                </React.Fragment>
                              );
                            }

                            return null;
                          })}

                          {mentors?.groupSessions?.map((value, index) => {
                            const startUTC = moment.utc(
                              `${value.date} ${value.interval.split('-')[0]}`,
                              'DD-MM-YYYY HH:mm'
                            );
                            const endUTC = moment.utc(
                              `${value.date} ${value.interval.split('-')[1]}`,
                              'DD-MM-YYYY HH:mm'
                            );

                            const startLocal = startUTC.local();
                            const endLocal = endUTC.local();

                            const start = startLocal.format('HH:mm');
                            const end = endLocal.format('HH:mm');

                            const slotStart = time.split(' -')[0];
                            const slotEnd = time.split('- ')[1];

                            if (
                              getDayOfWeek(day.format('YYYY-MM-DD')) ===
                                getDayOfWeek(startLocal.format('YYYY-MM-DD')) &&
                              start <= slotEnd &&
                              end > slotStart
                            ) {
                              const nextDayStart = moment(startLocal)
                                .add(1, 'day')
                                .startOf('day');
                              const nextDayEnd = moment(startLocal)
                                .add(1, 'day')
                                .endOf('day');

                              return (
                                <React.Fragment
                                  key={`d_d${index + 1}_${value.interval}`}
                                >
                                  <div
                                    style={{
                                      width: '20px',
                                      fontSize: '12px',
                                      height: '24.8px',
                                    }}
                                    className={`col bg-warning`}
                                  ></div>
                                  {endLocal.isAfter(nextDayStart) &&
                                    endLocal.isBefore(nextDayEnd) && (
                                      <div
                                        style={{
                                          width: '20px',
                                          fontSize: '12px',
                                          height: '24.8px',
                                        }}
                                        className={`col bg-warning`}
                                      ></div>
                                    )}
                                </React.Fragment>
                              );
                            }

                            return null;
                          })}
                        </>
                      )}
                    </>
                  ) : color === 'Private' ? (
                    <div
                      className={`col text-center text-white ${colorMeIn(
                        calculateUTC(time, true),
                        selectedDay,
                        day,
                        'Private'
                      )}`}
                      role={'button'}
                      onClick={(e) => {
                        setSelectedDay({
                          time: time,
                          day: day.format('YYYY-MM-DD'),
                        });
                        let dateNow = {
                          time: time,
                          day: day.format('YYYY-MM-DD'),
                        };
                        openPopup(moment(day), e, dateNow, i);
                      }}
                      key={`s_d${dayIndex}_${i}`}
                    >
                      {mentors?.oneToOneSessions?.map((value, i) => {
                        let text = '';

                        if (
                          time === calculateUTC(value.interval.split('-')[0]) &&
                          getDayOfWeek(day.format('YYYY-MM-DD')) ===
                            getDayOfWeek(
                              moment(
                                addDay(value.interval, value.date),
                                'DD-MM-YYYY'
                              ).format('YYYY-MM-DD')
                            )
                        ) {
                          text = (
                            <>
                              <span
                                id={value.speakerIds}
                                key={`${value.interval.split('-')[0]}_${i + 1}`}
                                style={{
                                  height:
                                    noAction && isSmallCalendar
                                      ? '24.8px'
                                      : noAction && '50px',
                                  maxHeight:
                                    noAction && isSmallCalendar
                                      ? '24.8px'
                                      : noAction && '50px',
                                }}
                              >
                                <span
                                  id={value.speakerIds}
                                  style={{
                                    height: '50px',
                                    maxHeight: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {modalData?.length === 1 ? (
                                    ' '
                                  ) : (
                                    <>
                                      {noAction && (
                                        <span
                                          id={value.speakerIds}
                                          className="me-1"
                                        >
                                          {value.sessionCount}
                                        </span>
                                      )}

                                      <span id={value.speakerIds}>
                                        {!noAction && (
                                          <span
                                            id={value.speakerIds}
                                            className={`me-1 ${
                                              value.sessionCount < 2 &&
                                              'd-none d-sm-flex justify-content-center'
                                            }`}
                                            style={{
                                              fontSize:
                                                value.sessionCount > 10 &&
                                                mobileView
                                                  ? '0.58rem'
                                                  : '0.8rem',
                                            }}
                                          >
                                            {value.sessionCount}
                                          </span>
                                        )}
                                        <span
                                          id={value.speakerIds}
                                          className="d-none d-sm-flex"
                                        >
                                          {value.sessionCount === 1
                                            ? t('calendar.session')
                                            : t('calendar.sessions')}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </span>
                              </span>
                            </>
                          );
                        }
                        return text;
                      })}
                    </div>
                  ) : (
                    <div
                      onClick={(e) => {
                        setSelectedDay({
                          time: time,
                          day: day.format('YYYY-MM-DD'),
                        });
                        let dateNow = {
                          time: time,
                          day: day.format('YYYY-MM-DD'),
                        };
                        openPopup(moment(day), e, dateNow, i);
                      }}
                      key={`fs_d${dayIndex}`}
                      className={`col text-center text-white ${colorMeIn(
                        calculateUTC(time, true),
                        selectedDay,
                        day,
                        'Group'
                      )}`}
                      role={'button'}
                    >
                      {mentors?.groupSessions?.map((value, i) => {
                        let text = '';
                        if (
                          time === calculateUTC(value.interval.split('-')[0]) &&
                          getDayOfWeek(day.format('YYYY-MM-DD')) ===
                            getDayOfWeek(
                              moment(
                                addDay(value.interval, value.date),
                                'DD-MM-YYYY'
                              ).format('YYYY-MM-DD')
                            )
                        ) {
                          text = (
                            <>
                              <span
                                key={`${value.interval.split('-')[0]}_${i + 1}`}
                                id={value.speakerIds}
                                style={{
                                  height:
                                    noAction && isSmallCalendar
                                      ? '24.8px'
                                      : noAction && '50px',
                                  maxHeight:
                                    noAction && isSmallCalendar
                                      ? '24.8px'
                                      : noAction && '50px',
                                }}
                              >
                                <span
                                  id={value.speakerIds}
                                  style={{
                                    height: '50px',
                                    maxHeight: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {modalData?.length === 1 ? (
                                    ' '
                                  ) : (
                                    <>
                                      {noAction && (
                                        <span
                                          id={value.speakerIds}
                                          className="me-1"
                                        >
                                          {value.sessionCount}
                                        </span>
                                      )}

                                      <span id={value.speakerIds}>
                                        {!noAction && (
                                          <span
                                            id={value.speakerIds}
                                            className={`me-1 ${
                                              value.sessionCount < 2 &&
                                              'd-none d-sm-flex justify-content-center'
                                            }`}
                                            style={{
                                              fontSize:
                                                value.sessionCount > 10 &&
                                                mobileView
                                                  ? '0.58rem'
                                                  : '0.8rem',
                                            }}
                                          >
                                            {value.sessionCount}
                                          </span>
                                        )}
                                        <span
                                          id={value.speakerIds}
                                          className="d-none d-sm-flex"
                                        >
                                          {value.sessionCount === 1
                                            ? t('calendar.session')
                                            : t('calendar.sessions')}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </span>
                              </span>
                            </>
                          );
                        }
                        return text;
                      })}
                    </div>
                  )}
                </div>
              }
            </td>
          ))
        )}
      </tr>
    ));
  };
  const isAdmin = checkClaims(
    currentUserClaim,
    Claims.actionClaims.unRecompenseAttendee
  );
  const onSetdate = async (card) => {
    if (!isAdmin) {
      const dDate = new Date();
      const nDate = new Date(`${popupData?.qq?.day}T${popupData?.qq?.time}:00`);
      const differenceInHours =
        (nDate.getTime() - dDate.getTime()) / (1000 * 60 * 60);
      const checkTime = nDate > dDate;
      const sameDay = dDate.getDate() === nDate.getDate();
      const hourDiff = calculateTimeDifference(dDate, nDate);
      const minuteDiff = hourDiff * 60;

      const isAdminSetdate = checkClaims(
        currentUserClaim,
        Claims.actionClaims.adminSetdate
      );

      if (fromRecompense && card !== '' && differenceInHours < 24) {
        if (!checkTime) {
          return setSameDateIssue(
            <strong>{t('weeklyCalendarToast.past_time_issue')}</strong>
          );
        } else if (
          sameDay &&
          card.productType === 'Group' &&
          card.attendeeCount === 0 &&
          hourDiff <= 6 &&
          !isAdminSetdate
        ) {
          return setSameDateIssue(
            <strong>
              {t('weeklyCalendarToast.group_session_count_zero_attendee')}
            </strong>
          );
        } else if (
          hourDiff <= 24 &&
          card.productType !== 'Group' &&
          !isAdminSetdate
        ) {
          return setSameDateIssue(
            <strong>{t('weeklyCalendarToast.hours_before_issue')}</strong>
          );
        } else if (
          sameDay &&
          minuteDiff <= 20 &&
          card.productType === 'Group' &&
          card.attendeeCount !== 0 &&
          !isAdminSetdate
        ) {
          return setSameDateIssue(
            <strong>{t('weeklyCalendarToast.group_session_20min_rule')}</strong>
          );
        }
      }
    }
    const [day, month, year] = card?.date?.split('-').map(Number);
    const startTime = card?.interval?.split('-')[0];
    const cardDate = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}T${startTime}:00.000Z`;
    const updateData = {
      speakerId: card.speakerId,
      meetingDate: cardDate,
      orderItemId: recompenseOrderItemId,
      meetingSchemaId: card.meetingSchemaId,
      meetingAttendeeId: recompenseMeetingAttendeeId,
    };
    const setDateArgs =
      attendeeId === undefined ? [updateData] : [updateData, attendeeId];
    await dispatch(
      Actions.meetingAttendeeActions.setDateAction(...setDateArgs)
    );
  };
  const reDateCheckSuccess = useSelector((e) => e.meetingAttendee.isSuccess);
  const reDateCheckMessage = useSelector(
    (e) => e.meetingAttendee.validationMessage
  );

  useEffect(() => {
    if (controlPage === undefined) {
      if (
        reDateCheckSuccess &&
        reDateCheckMessage !== '' &&
        (fromRecompense || !noAction)
      ) {
        setReDateResult(
          <span
            className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
            style={{ maxWidth: '550px' }}
          >
            <strong>{t('operations.setdate_success')}</strong>
          </span>
        );
      } else if (
        !reDateCheckSuccess &&
        reDateCheckMessage !== '' &&
        (fromRecompense || !noAction)
      ) {
        setReDateResult(
          <span
            className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
            style={{ maxWidth: '550px' }}
          >
            <strong>{reDateCheckMessage}</strong>
          </span>
        );
      }
    }
  }, [reDateCheckSuccess, reDateCheckMessage]);

  const [reDateResult, setReDateResult] = useState('');

  useEffect(() => {
    if (
      reDateResult !== '' &&
      reDateCheckMessage !== '' &&
      !reDateCheckSuccess
    ) {
      toast.error(reDateResult, {
        onClose: () => {
          setReDateResult('');
          if (attendeeId === undefined && !reDateCheckSuccess) {
            navigate(findMyPath('schedule'));
          } else {
            getMeIndex(0);
          }
        },
      });
    }
    if (
      reDateResult !== '' &&
      reDateCheckMessage !== '' &&
      reDateCheckSuccess
    ) {
      toast.success(reDateResult, {
        onClose: () => {
          setSelectedDay({ time: '', day: '' });
          onCellSelect(null);
          setIsOpen(false);
          setIsBasicOpen(false);
          setPopupData({});
          dispatch(Actions.calendarActions.getCellDetailsReducer([]));
          setReDateResult('');
          if (attendeeId === undefined && reDateCheckSuccess) {
            navigate(findMyPath('schedule'));
          } else {
            getMeIndex(0);
          }
        },
      });
    }
  }, [reDateResult]);

  function extractParameters(data) {
    return data?.map((item) => ({
      selectedCellIndex: item.selectedCellIndex.toString(),
      speakerId: item.speakerId,
      meetingSchemaId: item.meetingSchemaId,
      selectedDate: item.selectedDate,
      speakerName: item.speakerName,
    }));
  }
  useEffect(() => {
    if (Object?.keys(orderItems)?.length > 0 && calendarTable?.length === 0) {
      const dataFromBasketItems = extractParameters(selectedSessions);
      setCreateData({ selectionOptions: dataFromBasketItems });
    } else if (
      Object?.keys(orderItems)?.length === 0 &&
      calendarTable?.length === 0 &&
      Object?.keys(packageModel)?.length === 0
    ) {
      setCreateData([]);
    }
  }, [orderItems]);

  useEffect(() => {
    if (calendarTable?.length > 0) {
      const items = extractParameters(calendarTable);
      setCreateData({ selectionOptions: items });
    } else if (
      Object?.keys(orderItems)?.length === 0 &&
      Object?.keys(packageModel)?.length === 0
    ) {
      setCreateData([]);
    }
  }, [calendarTable, orderItems]);

  useEffect(() => {
    if (emptyData && !fromMentorCalendarButton) {
      setCreateData({ ...createData, selectionOptions: [] });
    }
    if (emptyData && fromMentorCalendarButton) {
      setCreateData({});
    }
  }, [emptyData]);
  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    const year = dateObject.getFullYear();
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(
      dateObject
    );
    const day = dateObject.getDate();
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    const time = `${hours}:${minutes}`;
    const formattedDate = `${month} ${day} ${time}`;

    return formattedDate;
  }

  useEffect(() => {
    if (removeAction !== null) {
      onSelectMentor('', removeAction);
      setSelectedCellColor(null);
    }
  }, [removeAction]);

  function calculateTimeDifference(date1, date2) {
    const nDate1 = new Date(date1);
    const nDate2 = new Date(date2);

    const timeDifference =
      nDate2.getDay() * 24 * 60 +
      nDate2.getHours() * 60 +
      nDate2.getMinutes() -
      (nDate1.getDay() * 24 * 60 +
        nDate1.getHours() * 60 +
        nDate1.getMinutes());
    const hoursDifference = timeDifference / 60;
    return hoursDifference;
  }

  useEffect(() => {
    setIsOpen(false);
  }, []);

  const handleTimeDiffIssue = (val) => {
    if (val) {
      onSelectMentor(dateIssue.card, undefined, undefined, true);
    }
    setSelectedDay({ time: '', day: '' });
    setSelectedCellColor(null);
    onCellSelect(null);
    setIsOpen(false);
    setIsBasicOpen(false);
    setPopupData({});
    dispatch(Actions.calendarActions.getCellDetailsReducer([]));
    setDateIssue({ open: false, text: null, card: {} });
  };

  const onSelectMentor = (
    card,
    cellIndex = undefined,
    cellIndexFromFunc = undefined,
    ignoreIssue = false
  ) => {
    const attendeeCount = card?.attendeeCount > 0;
    let isRemoveCase = false;
    let isSameSpeaker = false;
    if (calendarTable?.length > 0 || selectedSessions?.length > 0) {
      let match;
      if (calendarTable?.length > 0) {
        match = calendarTable.find(
          (item) => item.selectedCellIndex === selectedCellIndex
        );
      } else {
        match = selectedSessions.find(
          (item) => item.selectedCellIndex === selectedCellIndex
        );
      }
      isRemoveCase = match?.selectedCellIndex === selectedCellIndex;
      isSameSpeaker = match?.speakerId === card?.speakerId;
    }

    const dDate = new Date();
    const nDate = new Date(`${popupData?.qq?.day}T${popupData?.qq?.time}:00`);
    const differenceInHours =
      (nDate.getTime() - dDate.getTime()) / (1000 * 60 * 60);

    const checkTime = nDate < dDate;
    const hourDiff = calculateTimeDifference(dDate, nDate);
    const sameDay = dDate.getDate() === nDate.getDate();
    const minuteDiff = hourDiff * 60;
    const isAdminSetdate = checkClaims(
      currentUserClaim,
      Claims.actionClaims.adminSetdate
    );

    if (
      !fromRecompense &&
      !ignoreIssue &&
      card !== '' &&
      (hourDiff <= 24 || checkTime) &&
      differenceInHours < 24
    ) {
      let message;
      if (checkTime) {
        message = (
          <div className="fw-bold">
            <p>{t('weeklyCalendarModal.past_time_reason')}</p>
            <p>{t('weeklyCalendarModal.past_time_explain')}</p>
          </div>
        );
        return setDateIssue({ open: true, text: message, card: card });
      } else if (
        isRemoveCase &&
        !isSameSpeaker &&
        !attendeeCount &&
        !isAdminSetdate
      ) {
        message = (
          <div className="fw-bold">
            <p>
              {t(
                card.productType !== 'Group'
                  ? 'weeklyCalendarModal.time_issue_explain_24h_private'
                  : 'weeklyCalendarModal.time_issue_explain_6h_group'
              )}
            </p>
            <p>{t('weeklyCalendarModal.time_issue_explain_6_hour')}</p>
          </div>
        );
        return setDateIssue({ open: true, text: message, card: card });
      } else if (
        hourDiff <= 24 &&
        !attendeeCount &&
        !isRemoveCase &&
        !isAdminSetdate
      ) {
        if (noAction) {
          if (card.productType !== 'Group') {
            message = (
              <div className="fw-bold">
                <p>{t('weeklyCalendarModal.time_issue_explain_24h_private')}</p>
                <p>{t('weeklyCalendarModal.time_issue_explain_6_hour')}</p>
              </div>
            );
            return setDateIssue({ open: true, text: message, card: card });
          } else if (sameDay && card.productType === 'Group' && hourDiff <= 6) {
            message = (
              <div className="fw-bold">
                <p>{t('weeklyCalendarModal.time_issue_explain_6h_group')}</p>
                <p>{t('weeklyCalendarModal.time_issue_explain_6_hour')}</p>
              </div>
            );
            return setDateIssue({ open: true, text: message, card: card });
          }
        } else if (!noAction && card.productType !== 'Group') {
          message = (
            <div className="fw-bold">
              <p>{t('weeklyCalendarModal.time_issue_explain_24h_private')}</p>
              <p>{t('weeklyCalendarModal.time_issue_explain_6_hour')}</p>
            </div>
          );
          return setDateIssue({ open: true, text: message, card: card });
        }
      } else if (
        noAction &&
        sameDay &&
        minuteDiff <= 20 &&
        card.productType === 'Group' &&
        attendeeCount &&
        !isAdminSetdate
      ) {
        message = (
          <div className="fw-bold">
            <p>{t('weeklyCalendarToast.group_session_20min_rule')}</p>
          </div>
        );
        return setSameDateIssue(
          <strong>{t('weeklyCalendarToast.group_session_20min_rule')}</strong>
        );
      }
    }

    let cardDate;
    let checkingDate;
    if (ignoreIssue) {
      let parsingDate = card?.date?.split('-');
      const newDate = new Date(
        `${parsingDate[2]}-${parsingDate[1]}-${parsingDate[0]}`
      );
      const oneWeekLater = new Date(newDate);
      oneWeekLater.setDate(newDate.getDate() + 7);
      const formatingDate = oneWeekLater.toISOString().split('T')[0];
      const [year, month, day] = formatingDate?.split('-').map(Number);
      checkingDate = `${day}-${month}-${year}`;
    } else {
      checkingDate = card?.date;
    }

    if (card !== '') {
      const [day, month, year] = checkingDate?.split('-').map(Number);
      const startTime = card?.interval?.split('-')[0];
      const paddedDay = String(day).padStart(2, '0');
      const paddedMonth = String(month).padStart(2, '0');
      cardDate = `${year}-${paddedMonth}-${paddedDay}T${startTime}:00.000Z`;
    }

    if (isChild !== null) {
      if (isChild && userInfo?.userArea !== 2) {
        setPackageAreaInfo(
          <span
            className="d-flex position-relative mx-auto  justify-content-center fs-6"
            style={{ maxWidth: '550px' }}
          >
            <strong>{t('onlineGroup.kids_adult_warning')}</strong>
          </span>
        );
        return;
      }
      if (!isChild && userInfo?.userArea === 2) {
        setPackageAreaInfo(
          <span
            className="d-flex position-relative mx-auto  justify-content-center fs-6"
            style={{ maxWidth: '550px' }}
          >
            <strong>{t('onlineGroup.adult_kids_warning')}</strong>
          </span>
        );
        return;
      }
    }
    const basketOptions =
      orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection;
    let selectedOptions = createData?.selectionOptions || [];

    let timesPerWeek =
      (packageModel?.TimesPerWeek ||
        packageModel?.PackageOptions?.TimesPerWeek) ??
      basketOptions?.timesPerWeek;
    if (createData.trialStatus === 0 || createData.trialStatus === 1) {
      timesPerWeek = 1;
    }
    let companyId = basketOptions?.companyId ?? 1;
    const isFreeTrial =
      basketOptions?.trialStatus === 0 ||
      packageModel?.trialStatus === 0 ||
      createData?.trialStatus === 0;
    const isPaidTrial =
      basketOptions?.trialStatus === 1 ||
      packageModel?.trialStatus === 1 ||
      createData?.trialStatus === 1;

    const defaultLng =
      card.meetingSchemaId !== 0
        ? card.languageLevel
        : userInfo?.userArea !== undefined
        ? userInfo?.userArea !== 2
          ? `${levelGroups?.adult}.0`
          : `${levelGroups?.kids}.0`
        : basketOptions?.userArea !== 2
        ? `${levelGroups?.adult}.0`
        : `${levelGroups?.kids}.0`;

    if (fromSetDate) {
      timesPerWeek = selectLaterState?.timesPerWeek;
      const existingIndex =
        cellIndex === undefined
          ? selectedOptions.findIndex(
              (option) =>
                parseInt(option.selectedCellIndex) ===
                (cellIndexFromFunc === undefined
                  ? selectedCellIndex
                  : cellIndexFromFunc)
            )
          : cellIndex;
      if (
        Object.keys(selectedOptions).length >= timesPerWeek &&
        existingIndex === -1
      ) {
        setNotifyMesage(
          <span
            className="d-flex position-relative mx-auto  justify-content-center fs-6"
            style={{ maxWidth: '550px' }}
          >
            <strong>
              {t('calendar.select_warning', {
                timesperweek: timesPerWeek,
              })}
            </strong>
          </span>
        );
        return;
      }
      if (existingIndex !== -1) {
        if (
          selectedOptions[existingIndex]?.speakerId !== card.speakerId &&
          cellIndex === undefined
        ) {
          const formattedDateRange = formatDate(cardDate);
          selectedOptions[existingIndex] = {
            selectedCellIndex:
              cellIndexFromFunc === undefined
                ? selectedCellIndex
                : cellIndexFromFunc,
            speakerId: card.speakerId,
            meetingSchemaId:
              card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
            selectedDate: cardDate,
            sessionContent: fromEarnFreeSession
              ? `${t('calendar.table_group')} (90${t(
                  'calendar.table_minute_short'
                )})`
              : selectLaterState?.sessionContent,
            sessionCount: fromEarnFreeSession
              ? 1
              : selectLaterState?.sessionCount / selectLaterState?.timesPerWeek,
            speakerName: card.speakerName,
            sessionDate: formattedDateRange,
          };
        } else {
          selectedOptions.splice(existingIndex, 1);
        }
      }
      if (existingIndex === -1) {
        const formattedDateRange = formatDate(cardDate);
        selectedOptions.push({
          selectedCellIndex:
            cellIndexFromFunc === undefined
              ? selectedCellIndex
              : cellIndexFromFunc,
          speakerId: card.speakerId,
          meetingSchemaId:
            card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
          selectedDate: cardDate,
          sessionContent: fromEarnFreeSession
            ? `${t('calendar.table_group')} (90${t(
                'calendar.table_minute_short'
              )})`
            : selectLaterState?.sessionContent,
          sessionCount: fromEarnFreeSession
            ? 1
            : selectLaterState?.sessionCount / selectLaterState?.timesPerWeek,
          speakerName: card.speakerName,
          sessionDate: formattedDateRange,
        });
      }

      const updatedData = {
        productType: card?.productType,
        languageLevel: selectLaterState?.languageLevel,
        selectionOptions: selectedOptions,
        userArea: selectLaterState?.userArea,
        trialStatus: selectLaterState?.trialStatus,
        attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
        monthDuration:
          packageModel?.MonthDuration ?? basketOptions?.monthDuration,
        timesPerWeek: timesPerWeek,
        companyId: selectLaterState?.companyId,
        ageId: selectLaterState?.ageId,
      };

      setCreateData(updatedData);
      getTableData(updatedData?.selectionOptions);
    } else if (isFreeTrial || isPaidTrial) {
      let existingIndex =
        cellIndex === undefined
          ? selectedOptions.findIndex(
              (option) =>
                parseInt(option.selectedCellIndex) ===
                (cellIndexFromFunc === undefined
                  ? selectedCellIndex
                  : cellIndexFromFunc)
            )
          : cellIndex;
      let keepId = selectedOptions[existingIndex]?.speakerId;
      if (existingIndex !== -1) {
        if (
          selectedOptions[existingIndex].speakerId !== card.speakerId &&
          cellIndex === undefined
        ) {
          const formattedDateRange = formatDate(cardDate);
          selectedOptions[existingIndex] = {
            selectedCellIndex:
              cellIndexFromFunc === undefined
                ? selectedCellIndex
                : cellIndexFromFunc,
            speakerId: card.speakerId,
            meetingSchemaId:
              card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
            selectedDate: cardDate,
            sessionContent: `${
              createData?.productType === 1
                ? t('calendar.table_private')
                : t('calendar.table_group')
            } ${
              isFreeTrial
                ? t('calendar.table_free_trial_session')
                : t('calendar.table_paid_trial_session')
            }`,
            sessionCount: 1,
            speakerName: card.speakerName,
            sessionDate: formattedDateRange,
          };
        } else {
          selectedOptions.splice(existingIndex, 1);
        }
      }
      if (existingIndex === -1) {
        if (Object.keys(selectedOptions).length === 1) {
          if (isFreeTrial) {
            setNotifyMesage(
              <span
                className="d-flex position-relative mx-auto  justify-content-center fs-6"
                style={{ maxWidth: '550px' }}
              >
                <strong>{t('calendar.free_trial_select_warning')}</strong>
              </span>
            );
            return;
          } else if (isPaidTrial) {
            setNotifyMesage(
              <span
                className="d-flex position-relative mx-auto  justify-content-center fs-6"
                style={{ maxWidth: '550px' }}
              >
                <strong>{t('calendar.paid_trial_select_warning')}</strong>
              </span>
            );
            return;
          }
        } else {
          const formattedDateRange = formatDate(cardDate);
          selectedOptions.push({
            selectedCellIndex: selectedCellIndex,
            speakerId: card.speakerId,
            meetingSchemaId:
              card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
            selectedDate: cardDate,
            sessionContent: `${
              createData?.productType === 1
                ? t('calendar.table_private')
                : t('calendar.table_group')
            } ${
              isFreeTrial
                ? t('calendar.table_free_trial_session')
                : t('calendar.table_paid_trial_session')
            }`,
            sessionCount: 1,
            speakerName: card.speakerName,
            sessionDate: formattedDateRange,
          });
        }
      }

      const PackageOptions = {
        location: card.locationId,
        monthDuration: 1,
        timesPerWeek: 1,
        currency: currency.code,
        couponCode: packageModel?.CouponCode ?? basketOptions?.CouponCode,
        type: 1,
      };
      const updatedData =
        existingIndex === -1
          ? {
              productType: card?.productType,
              languageLevel: languageLevel ?? defaultLng,
              learningPurposes: formatLearningPurposes(learningPurposes),
              packageOptions: PackageOptions,
              selectionOptions: selectedOptions,
              userArea:
                areaFromParent !== undefined
                  ? areaFromParent
                  : userInfo?.userArea ?? basketOptions?.userArea,
              trialStatus:
                packageModel?.trialStatus ?? basketOptions?.trialStatus,
              attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
              companyId: companyId,
              ageId: packageModel?.ageInterval ?? basketOptions?.ageId,
            }
          : existingIndex !== -1 && keepId !== card.speakerId && card !== ''
          ? {
              productType: card?.productType,
              languageLevel: languageLevel ?? defaultLng,
              learningPurposes: formatLearningPurposes(learningPurposes),
              packageOptions: PackageOptions,
              selectionOptions: selectedOptions,
              userArea:
                areaFromParent !== undefined
                  ? areaFromParent
                  : userInfo?.userArea ?? basketOptions?.userArea,
              trialStatus:
                packageModel?.trialStatus ?? basketOptions?.trialStatus,
              attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
              companyId: companyId,
              ageId: packageModel?.ageInterval ?? basketOptions?.ageId,
            }
          : {};
      setCreateData(updatedData);
      dispatch(Actions.orderActions.selectSessionAction(updatedData));
      if (Object?.keys(userInfo)?.length === 0) {
        dispatch(Actions.userPackageActions.createAction(updatedData));
      }
    } else if (fromMentorCalendarButton) {
      const existingIndex =
        cellIndex === undefined
          ? selectedOptions.findIndex(
              (option) =>
                parseInt(option.selectedCellIndex) ===
                (cellIndexFromFunc === undefined
                  ? selectedCellIndex
                  : cellIndexFromFunc)
            )
          : cellIndex;
      if (existingIndex !== -1) {
        if (
          selectedOptions[existingIndex].speakerId !== card.speakerId &&
          cellIndex === undefined
        ) {
          selectedOptions[existingIndex] = {
            selectedCellIndex:
              cellIndexFromFunc === undefined
                ? selectedCellIndex
                : cellIndexFromFunc,
            speakerId: card.speakerId,
            meetingSchemaId:
              card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
            selectedDate: cardDate,
            speakerName: card.speakerName,
          };
        } else {
          selectedOptions.splice(existingIndex, 1);
        }
      }
      if (existingIndex === -1) {
        selectedOptions.push({
          selectedCellIndex:
            cellIndexFromFunc === undefined
              ? selectedCellIndex
              : cellIndexFromFunc,
          speakerId: card.speakerId,
          meetingSchemaId:
            card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
          selectedDate: cardDate,
          speakerName: card.speakerName,
        });
      }
      const updatedData =
        existingIndex === -1
          ? {
              productType: card?.productType,
              languageLevel: languageLevel ?? defaultLng,
              learningPurposes: formatLearningPurposes(learningPurposes),
              selectionOptions: selectedOptions,
              userArea:
                areaFromParent !== undefined
                  ? areaFromParent
                  : userInfo?.userArea ?? basketOptions?.userArea,
              trialStatus:
                packageModel?.trialStatus ?? basketOptions?.timesPerWeek,
              attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
              companyId: companyId,
              ageId: packageModel?.ageInterval ?? basketOptions?.ageId,
            }
          : existingIndex !== -1 && selectedOptions.length > 0
          ? {
              productType: card?.productType,
              languageLevel: languageLevel ?? defaultLng,
              learningPurposes: formatLearningPurposes(learningPurposes),
              selectionOptions: selectedOptions,
              userArea:
                areaFromParent !== undefined
                  ? areaFromParent
                  : userInfo?.userArea ?? basketOptions?.userArea,
              trialStatus:
                packageModel?.trialStatus ?? basketOptions?.timesPerWeek,
              attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
              companyId: companyId,
              ageId: packageModel?.ageInterval ?? basketOptions?.ageId,
            }
          : {};
      setCreateData(updatedData);
      dispatch(Actions.orderActions.selectSessionAction(updatedData));
      if (auth === '') {
        dispatch(Actions.userPackageActions.createAction(updatedData));
      }
    } else {
      const existingIndex =
        cellIndex === undefined
          ? selectedOptions.findIndex(
              (option) =>
                parseInt(option.selectedCellIndex) ===
                (cellIndexFromFunc === undefined
                  ? selectedCellIndex
                  : cellIndexFromFunc)
            )
          : cellIndex;

      if (existingIndex !== -1) {
        if (
          selectedOptions[existingIndex].speakerId !== card.speakerId &&
          cellIndex === undefined
        ) {
          selectedOptions[existingIndex] = {
            selectedCellIndex:
              cellIndexFromFunc === undefined
                ? selectedCellIndex
                : cellIndexFromFunc,
            speakerId: card.speakerId,
            meetingSchemaId:
              card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
            selectedDate: cardDate,
            speakerName: card.speakerName,
          };
        } else {
          selectedOptions.splice(existingIndex, 1);
        }
      }
      if (existingIndex === -1) {
        if (Object.keys(selectedOptions).length >= timesPerWeek) {
          setNotifyMesage(
            <span
              className="d-flex position-relative mx-auto  justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>
                {t('calendar.select_warning', {
                  timesperweek: timesPerWeek,
                })}
              </strong>
            </span>
          );
          return;
        } else if (cellIndex === undefined) {
          selectedOptions.push({
            selectedCellIndex:
              cellIndexFromFunc === undefined
                ? selectedCellIndex
                : cellIndexFromFunc,
            speakerId: card.speakerId,
            meetingSchemaId:
              card.meetingSchemaId === 0 ? null : card.meetingSchemaId,
            selectedDate: cardDate,
            speakerName: card.speakerName,
          });
        }
      }
      const firstPackageId =
        createData?.sessionPackage !== undefined
          ? createData?.sessionPackage
          : packageModel?.SessionPackage ?? basketOptions?.SessionPackage;
      const PackageOptions = {
        location: card.locationId,
        monthDuration:
          packageModel?.MonthDuration ?? basketOptions?.monthDuration,
        timesPerWeek: packageModel?.TimesPerWeek ?? basketOptions?.timesPerWeek,
        sessionPackage: firstPackageId,
        currency: currency.code,
        couponCode: packageModel?.CouponCode ?? basketOptions?.CouponCode,
        type: 1,
      };
      const updatedData =
        existingIndex === -1
          ? {
              productType: card?.productType,
              languageLevel: languageLevel ?? defaultLng,
              learningPurposes: formatLearningPurposes(learningPurposes),
              packageOptions: PackageOptions,
              selectionOptions: selectedOptions,
              userArea:
                areaFromParent !== undefined
                  ? areaFromParent
                  : userInfo?.userArea ?? basketOptions?.userArea,
              trialStatus:
                packageModel?.trialStatus ?? basketOptions?.timesPerWeek,
              attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
              companyId: companyId,
              ageId: packageModel?.ageInterval ?? basketOptions?.ageId,
            }
          : existingIndex !== -1 && selectedOptions.length > 0
          ? {
              productType: card?.productType,
              languageLevel: languageLevel ?? defaultLng,
              learningPurposes: formatLearningPurposes(learningPurposes),
              packageOptions: PackageOptions,
              selectionOptions: selectedOptions,
              userArea:
                areaFromParent !== undefined
                  ? areaFromParent
                  : userInfo?.userArea ?? basketOptions?.userArea,
              trialStatus:
                packageModel?.trialStatus ?? basketOptions?.timesPerWeek,
              attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
              companyId: companyId,
              ageId: packageModel?.ageInterval ?? basketOptions?.ageId,
            }
          : existingIndex !== -1 && selectedOptions.length === 0 && auth === ''
          ? {
              languageLevel: languageLevel,
              learningPurposes: formatLearningPurposes(learningPurposes),
              packageOptions: PackageOptions,
              selectionOptions: selectedOptions,
              userArea:
                areaFromParent !== undefined
                  ? areaFromParent
                  : userInfo?.userArea ?? basketOptions?.userArea,
              trialStatus:
                packageModel?.trialStatus ?? basketOptions?.timesPerWeek,
              attendeeId: userInfo?.attendeeId ?? basketOptions?.attendeeId,
              companyId: companyId,
              ageId: packageModel?.ageInterval ?? basketOptions?.ageId,
            }
          : {};
      setCreateData(updatedData);
      dispatch(Actions.orderActions.selectSessionAction(updatedData));
      if (auth === '') {
        dispatch(Actions.userPackageActions.createAction(updatedData));
      }
    }
    setSelectedDay({ time: '', day: '' });
    onCellSelect(null);
    setIsOpen(false);
    setIsBasicOpen(false);
    setPopupData({});
    dispatch(Actions.calendarActions.getCellDetailsReducer([]));
  };

  function formatLearningPurposes(learningPurposes) {
    if (!learningPurposes || learningPurposes.length === 0) {
      return '2';
    }

    return learningPurposes.join(',');
  }

  const IconDisplay = ({ iconCount, capacity }) => {
    const renderExtraIcon = iconCount > 3;
    return (
      <div className="icon-container">
        {Array.from({ length: Math.min(iconCount, 3) }).map((_, index) => (
          <FontAwesomeIcon
            key={`${index}_${iconCount + 1}`}
            icon={faCircleUser}
            size="2x"
            className={`icon ${index !== iconCount - 1 ? 'hidden' : ''}`}
            style={{ zIndex: index + 1 }}
          />
        ))}
        <div
          className={`icon-container position-relative`}
          style={{ left: '-1px' }}
        >
          {renderExtraIcon && (
            <>
              <FontAwesomeIcon
                className="text-secondary icon"
                icon={faCircle}
                size="2x"
                style={{ zIndex: 20 }}
              />
              <span
                className="position-absolute ms-2 top-50 start-90 translate-middle badge rounded-pill"
                style={{ zIndex: 20 }}
              >
                {renderExtraIcon && `+${iconCount - 3}`}
              </span>
            </>
          )}
          <span style={{ marginLeft: '5px' }}>
            <strong className="ms-sm-2 sm-1">
              {`- ${capacity - iconCount} ${t(
                'weeklyCalendarModal.count_attendess'
              )}`}
            </strong>
          </span>
        </div>
      </div>
    );
  };

  function getButtonTextByPath(card) {
    const matchingElement = createData?.selectionOptions?.find(
      (item) =>
        parseInt(item.selectedCellIndex) === selectedCellIndex &&
        item.speakerId === card.speakerId
    );
    if (matchingElement) {
      return t('button.selected');
    } else {
      return t('button.select');
    }
  }
  const [onRecompenseAction, setOnRecompenseAction] = useState('');
  useEffect(() => {
    if (onRecompenseAction !== '') {
      toast.warning(onRecompenseAction, {
        onClose: () => {
          setOnRecompenseAction('');
        },
      });
    }
  }, [onRecompenseAction]);
  const customToastStyleNormal = {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: 10000,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const [changeModalContent, setChangeModalContent] = useState(false);
  const [meetingDetailsData, setMeetingDetailsData] = useState({});

  const handleButtonClick = (id, date, time) => {
    if (id !== undefined) {
      const [day, month, year] = date.split('-').map(Number);
      const dateValue = `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}T${time.split('-')[0]}:00.000Z`;
      setMeetingDetailsData({ id: id, date: dateValue });
      dispatch(
        Actions.calendarActions.getMeetingAttendeesAction({
          id: id,
          date: dateValue,
        })
      );
      setChangeModalContent(!changeModalContent);
    } else {
      setChangeModalContent(!changeModalContent);
    }
  };

  const setColorOn = (val) => {
    let result;
    switch (val) {
      case 0:
        result = 'Online';
        break;
      case 1:
        result = 'Cafe';
        break;
      case 2:
        result = 'Kids';
        break;
      case 3:
        result = 'Company';
        break;
      default:
        result = onboarColor;
        break;
    }
    return result;
  };

  // function languageLevelParse(card) {
  //   const levels = card?.languageLevel?.split(',');
  //   if (area === 2 || card?.userArea === 2) {
  //     const kidLevels = levels?.filter((level) =>
  //       level?.includes(levelGroups?.kids)
  //     );
  //     return kidLevels;
  //   } else if (area !== 2 || card?.userArea !== 2) {
  //     const adultLevels = levels?.filter((level) =>
  //       level?.includes(levelGroups?.adult)
  //     );
  //     return adultLevels;
  //   }
  // }
  return (
    <div className="container-fluid mt-2 ">
      <div className={`${sideOn ? 'd-none' : ''}`}>
        <div className="d-flex justify-content-between align-items-center text-nowrap my-1 p-0 m-0">
          <div
            className="d-flex justify-content-start mx-end align-items-center"
            role={
              (
                controlPage || fromRecompense
                  ? selectedWeek?.date() !== rDate?.date()
                  : (selectedWeek?.date() !== date?.date() &&
                      currentView !== 3) ||
                    (selectedDate?.date() !== date?.date() && currentView === 3)
              )
                ? 'button'
                : ''
            }
            onClick={
              (
                controlPage || fromRecompense
                  ? selectedWeek?.date() !== rDate?.date()
                  : (selectedWeek?.date() !== date?.date() &&
                      currentView !== 3) ||
                    (selectedDate?.date() !== date?.date() && currentView === 3)
              )
                ? currentView === 3
                  ? previousDay
                  : previousWeek
                : null
            }
          >
            <div className="col-2">
              {(
                controlPage || fromRecompense
                  ? selectedWeek?.date() !== rDate?.date()
                  : (selectedWeek?.date() !== date?.date() &&
                      currentView !== 3) ||
                    (selectedDate?.date() !== date?.date() && currentView === 3)
              ) ? (
                <FontAwesomeIcon
                  className="border rounded-circle p-2"
                  icon={faChevronLeft}
                />
              ) : undefined}
            </div>
            <div className="col-10 text-start  align-middle ps-2 align-items-center d-md-block d-none">
              {(
                !noAction || fromRecompense
                  ? selectedWeek?.date() !== rDate?.date()
                  : selectedWeek?.date() !== date?.date()
              ) ? (
                <HeadTags
                  hSize={6}
                  text={prevText}
                  strong
                  textColor={TextColors.Muted}
                />
              ) : undefined}
            </div>
          </div>
          <div className="text-center mx-auto align-middle align-items-center">
            <HeadTags
              hSize={5} // Adjust the breakpoint as needed
              text={mainText}
              strong
              textColor={TextColors.Muted}
            />
          </div>
          {(fromRecompense
            ? moment(addDays(recompenseDate, 4)) > selectedWeek
            : true) &&
            ((!noAction &&
              selectedWeek < moment()?.locale(locale)?.add(20, 'days')) ||
              fromRecompense) && (
              <div
                className="d-flex justify-content-end mx-start pe-2 align-items-center"
                role={'button'}
                onClick={currentView === 3 ? nextDay : nextWeek}
              >
                <div className="col-10 text-end align-middle  pe-2 align-items-center d-md-block d-none">
                  <HeadTags
                    hSize={6}
                    text={nextText}
                    strong
                    textColor={TextColors.Muted}
                  />
                </div>
                <div className="col-2 me-2 align-middle">
                  <FontAwesomeIcon
                    className="border rounded-circle p-2"
                    icon={faChevronRight}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
      {(notifyMessage !== '' ||
        onRecompenseAction !== '' ||
        reDateResult !== '' ||
        packageAreaInfo !== '' ||
        sameDateIssue !== '') && (
        <ToastContainer
          style={
            notifyMessage !== '' ? customToastStyle : customToastStyleNormal
          }
          autoClose={false}
          closeOnClick
          theme="light"
        />
      )}
      {currentView === 3 && renderTableHeaders(selectedDate.toDate())}
      <div
        className="col-12 table-responsive calendar-scroll"
        ref={calendarRef}
        style={
          isSmallCalendar
            ? { width: '100%', overflowY: 'hidden' }
            : { width: '100%' }
        }
      >
        <table
          className="table table-sm table-bordered"
          style={{ tableLayout: 'fixed', width: '100%' }}
        >
          <thead>{currentView !== 3 && renderTableHeaders()}</thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
      {isOpen && (
        <CalendarModal
          isVisible={isOpen}
          styleProps={{
            maxHeight: 400,
            maxWidth: changeModalContent ? 800 : 550,
          }}
          title={
            <strong
              style={{
                fontSize: '0.9em',
              }}
            >
              {addDay(
                popupDetails?.contents?.[0]?.interval,
                popupDetails?.contents?.[0]?.date,
                false,
                true
              )}
            </strong>
          }
          content={
            isOpen && (
              <div className="row d-flex justify-content-center">
                {!changeModalContent
                  ? popupDetails?.contents?.map((card, index) =>
                      controlPage === undefined ? (
                        <div
                          key={`f_d${card.date}_${card.speakerId}_${index}_${
                            index - card.capacity
                          }`}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <img
                            key={`pop_upcard_image_${index}_${card.speakerId}`}
                            alt={card.speakerImage}
                            className="img-fluid me-sm-2 me-1"
                            src={card.speakerImage}
                            style={{
                              height: '120px',
                              width: mobileView ? '85px' : '100px',
                              minWidth: mobileView ? '85px' : '100px',
                              borderRadius: '0.5rem',
                            }}
                            role="button"
                            onClick={() => {
                              if (noAction && attendeeId === undefined) {
                                setSelectedDay({ time: '', day: '' });
                                onCellSelect(null);
                                setIsOpen(false);
                                setIsBasicOpen(false);
                                setPopupData({});
                                dispatch(
                                  Actions.calendarActions.getCellDetailsReducer(
                                    []
                                  )
                                );
                                navigate(
                                  findMyPath(
                                    `speakers/speaker-detail/${card?.speakerId}`
                                  )
                                );
                              }
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/images/empty-profile.png';
                            }}
                          />
                          <div
                            key={`s_d${card.date}_${card.speakerId}_${index}_${
                              index - card.capacity
                            }`}
                            className={`p-0 m-0 col-9`}
                            style={{
                              marginBottom: '-30px',
                            }}
                          >
                            <Card
                              padding={8}
                              key={`pop_up_card_${index}_${card.date}_${card.speakerId}`}
                              shadow={true}
                              minHeight={'120px'}
                              scheduleBorder={card.productType}
                              cardRounded={false}
                              body={
                                <div
                                  key={`pop_up_card_body_1std${index}_${card.date}_${card.speakerId}`}
                                  className="col-12"
                                >
                                  {mobileView && (
                                    <div className="d-flex justify-content-between text-nowrap">
                                      <strong
                                        className={`${
                                          card?.userArea !== 1 ? '7' : '6'
                                        }`}
                                        style={{
                                          fontSize:
                                            card.speakerName.length >= 17
                                              ? '7.5px'
                                              : card.speakerName.length >= 12
                                              ? '9px'
                                              : '10px',
                                        }}
                                        key={`pop_up_card_title_${index}_${card.date}_${card.speakerId}`}
                                      >
                                        {card.speakerName}
                                      </strong>
                                      <div
                                        className={`col-${
                                          card?.userArea !== 1 ? '5' : '6'
                                        } ${
                                          ButtonType[
                                            t(
                                              setColorOn(
                                                area ?? card?.userArea
                                              ),
                                              {
                                                lng: 'en',
                                              }
                                            )
                                          ]
                                        } d-flex align-items-center justify-content-center text-white fw-bold rounded`}
                                      >
                                        <span
                                          key={`pop_up_card_div_${index}_${card.date}_${card.speakerId}`}
                                          className="d-flex"
                                          style={{
                                            whiteSpace: 'nowrap',
                                            fontSize:
                                              card?.locationId === 2
                                                ? '7px'
                                                : '7.5px',
                                          }}
                                        >
                                          {card?.locationName}{' '}
                                          {card?.userArea !== 1 &&
                                            t(
                                              `UserArea.${
                                                area ?? card?.userArea
                                              }`,
                                              {
                                                lng: 'en',
                                              }
                                            )}{' '}
                                          {card?.productType === 'Private' &&
                                            t('onlineGroup.onetoone')}
                                          {card?.productType === 'Group' && (
                                            <div
                                              key={`pop_up_card_div_capacity_${index}_${card.date}_${card.speakerId}`}
                                              className="ms-1 text-capitalize"
                                            >
                                              {t('onlineGroup.person', {
                                                person: card?.capacity,
                                              })}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {!mobileView && (
                                    <div className="d-flex justify-content-between mb-3">
                                      <strong
                                        key={`pop_up_card_title_${index}_${card.date}_${card.speakerId}`}
                                        style={{
                                          fontSize: '1em',
                                        }}
                                      >
                                        {card.speakerName}
                                      </strong>

                                      <div
                                        className={`${
                                          ButtonType[
                                            t(
                                              setColorOn(
                                                area ?? card?.userArea
                                              ),
                                              {
                                                lng: 'en',
                                              }
                                            )
                                          ]
                                        } flex-shrink-sm-1 align-middle text-white fw-bold d-flex rounded`}
                                        style={{
                                          fontSize: '0.8em',
                                        }}
                                      >
                                        <span
                                          key={`pop_up_card_div_${index}_${card.date}_${card.speakerId}`}
                                          className="d-flex p-1"
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {card?.locationName}{' '}
                                          {t(
                                            `UserArea.${
                                              area ?? card?.userArea
                                            }`,
                                            {
                                              lng: 'en',
                                            }
                                          )}{' '}
                                          {card?.productType === 'Private'
                                            ? t('TypeOfService.0')
                                            : t('TypeOfService.1')}
                                          {card?.productType === 'Group' && (
                                            <div
                                              key={`pop_up_card_div_capacity_${index}_${card.date}_${card.speakerId}`}
                                              className="ps-1 text-capitalize"
                                            >
                                              {t('onlineGroup.person', {
                                                person: card?.capacity,
                                              })}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {/* //TODO here mobil görünüm */}
                                  <div
                                    className={`d-flex justify-content-lg-between justify-content-between align-items-center ${
                                      card.productType === 'Private' &&
                                      mobileView
                                        ? 'mt-2'
                                        : ''
                                    }`}
                                    key={`pop_up_card_body_2std${index}_${card.date}_${card.speakerId}`}
                                  >
                                    <div
                                      style={{
                                        fontSize: mobileView ? '0.8em' : '',
                                      }}
                                    >
                                      <div
                                        key={`pop_up_card_body_5std${index}_${card.date}_${card.speakerId}`}
                                        className={`text-nowrap`}
                                        style={{
                                          fontSize: mobileView ? '0.9em' : '',
                                        }}
                                      >
                                        {t('weeklyCalendarModal.time')}
                                        {`${calculateUTC(
                                          card?.interval.split('-')[0]
                                        )}-${calculateUTC(
                                          card?.interval.split('-')[1]
                                        )}`}
                                        <div
                                          className="text-nowrap"
                                          style={{
                                            fontSize: mobileView ? '0.8em' : '',
                                          }}
                                        >
                                          {(card?.meetingSchemaId !== 0 ||
                                            filter?.LanguageLevels !== null) &&
                                            t('weeklyCalendarModal.level')}
                                          {filter?.LanguageLevels === null &&
                                          card?.meetingSchemaId !== 0
                                            ? t(card?.languageLevel)
                                            : // languageLevelParse(card)?.map(
                                              //     (level, index) => (
                                              //       <React.Fragment>
                                              //         {t(level)}
                                              //         {index <
                                              //           languageLevelParse(card)
                                              //             ?.length -
                                              //             1 && ', '}
                                              //       </React.Fragment>
                                              //     )
                                              //   )
                                              t(filter?.LanguageLevels)}
                                        </div>
                                        {card.ageInterval && (
                                          <span>
                                            {t('weeklyCalendarModal.age')}
                                            {card.ageInterval}
                                          </span>
                                        )}
                                        {card.productType === 'Group' && (
                                          <span
                                            style={{
                                              fontSize: mobileView
                                                ? '7.5px'
                                                : '',
                                            }}
                                          >
                                            <strong>
                                              {card.attendeeCount === 0 ? (
                                                t(
                                                  'weeklyCalendarModal.first_person'
                                                )
                                              ) : (
                                                <IconDisplay
                                                  iconCount={card.attendeeCount}
                                                  capacity={card.capacity}
                                                />
                                              )}
                                            </strong>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {(noAction || attendeeId !== undefined) &&
                                      mobileView && (
                                        <div
                                          className="col-5"
                                          style={{ maxHeight: '10px' }}
                                          key={`pop_up_card_body_6std${index}_${card.date}_${card.speakerId}`}
                                        >
                                          {isBasicOpen?.isOpen &&
                                            isBasicOpen?.meetingSchemaId ===
                                              card?.meetingSchemaId &&
                                            isBasicOpen?.index === index && (
                                              <BasicPopUp
                                                key={`pop_up_card_body_basic_popup${index}_${card.date}_${card.speakerId}`}
                                                isOpen={isBasicOpen}
                                                onClose={handleClose}
                                                position={{
                                                  top: 10,
                                                  left: 0,
                                                }}
                                                textColor={TextColors.Danger}
                                                text={
                                                  <strong
                                                    key={`pop_up_card_body_basic_popup_title${index}_${card.date}_${card.speakerId}`}
                                                  >
                                                    {t(
                                                      'weeklyCalendarToast.same_day_issue'
                                                    )}
                                                  </strong>
                                                }
                                              />
                                            )}
                                          {!(
                                            isBasicOpen?.isOpen &&
                                            isBasicOpen?.meetingSchemaId ===
                                              card?.meetingSchemaId &&
                                            isBasicOpen?.index === index
                                          ) && (
                                            <Button
                                              key={`pop_up_card_body_button1${index}_${card.date}_${card.speakerId}`}
                                              type={
                                                card.userArea === 0
                                                  ? ButtonType.Cafe
                                                  : card.userArea === 2
                                                  ? ButtonType.Info
                                                  : card.userArea === 1
                                                  ? ButtonType.Successbg
                                                  : ButtonType.CalendarSelectButton
                                              }
                                              textColor={TextColors.Light}
                                              size={ButtonSize.ExtraSmall}
                                              onClick={() => {
                                                if (
                                                  card.attendeeAvailability ===
                                                  'Not Avaible'
                                                ) {
                                                  setIsBasicOpen({
                                                    isOpen: true,
                                                    meetingSchemaId:
                                                      card.meetingSchemaId,
                                                    index: index,
                                                  });
                                                } else {
                                                  if (fromRecompense) {
                                                    setOnRecompenseAction(
                                                      <React.Fragment>
                                                        <span
                                                          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
                                                          style={{
                                                            maxWidth: '550px',
                                                          }}
                                                        >
                                                          <strong>
                                                            {t(
                                                              'button.set_date_question'
                                                            )}
                                                          </strong>
                                                        </span>
                                                        <div className="d-flex justify-content-around">
                                                          <div className="col-4">
                                                            <Button
                                                              text={t(
                                                                'button.yes'
                                                              )}
                                                              type={
                                                                ButtonType.Success
                                                              }
                                                              textColor={
                                                                TextColors.Light
                                                              }
                                                              onClick={() =>
                                                                onSetdate(card)
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-4">
                                                            <Button
                                                              text={t(
                                                                'button.no'
                                                              )}
                                                              type={
                                                                ButtonType.Danger
                                                              }
                                                              textColor={
                                                                TextColors.Light
                                                              }
                                                              onClick={() =>
                                                                setOnRecompenseAction(
                                                                  ''
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </React.Fragment>
                                                    );
                                                  } else {
                                                    onSelectMentor(card);
                                                  }
                                                }
                                              }}
                                              text={
                                                fromRecompense ? (
                                                  <strong
                                                    style={{
                                                      fontSize: '0.7em',
                                                    }}
                                                  >
                                                    {t('button.set_date')}
                                                  </strong>
                                                ) : (
                                                  <strong
                                                    style={{
                                                      fontSize: '0.7em',
                                                    }}
                                                  >
                                                    {getButtonTextByPath(card)}
                                                  </strong>
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      )}
                                    {(noAction || attendeeId !== undefined) &&
                                      !mobileView && (
                                        <div
                                          className="col-3"
                                          key={`pop_up_card_body_6std${index}_${card.date}_${card.speakerId}`}
                                        >
                                          {isBasicOpen?.isOpen &&
                                            isBasicOpen?.meetingSchemaId ===
                                              card?.meetingSchemaId &&
                                            isBasicOpen?.index === index && (
                                              <BasicPopUp
                                                key={`pop_up_card_body_basic_popup${index}_${card.date}_${card.speakerId}`}
                                                isOpen={isBasicOpen}
                                                onClose={handleClose}
                                                position={{
                                                  top: 10,
                                                  left: 0,
                                                }}
                                                textColor={TextColors.Danger}
                                                text={
                                                  <strong
                                                    key={`pop_up_card_body_basic_popup_title${index}_${card.date}_${card.speakerId}`}
                                                  >
                                                    {t(
                                                      'weeklyCalendarToast.same_day_issue'
                                                    )}
                                                  </strong>
                                                }
                                              />
                                            )}
                                          {!(
                                            isBasicOpen?.isOpen &&
                                            isBasicOpen?.meetingSchemaId ===
                                              card?.meetingSchemaId &&
                                            isBasicOpen?.index === index
                                          ) && (
                                            <Button
                                              key={`pop_up_card_body_button1${index}_${card.date}_${card.speakerId}`}
                                              type={
                                                card.userArea === 0
                                                  ? ButtonType.Cafe
                                                  : card.userArea === 2
                                                  ? ButtonType.Info
                                                  : card.userArea === 1
                                                  ? ButtonType.Successbg
                                                  : ButtonType.CalendarSelectButton
                                              }
                                              size={ButtonSize.Small}
                                              textColor={TextColors.Light}
                                              onClick={() => {
                                                if (
                                                  card.attendeeAvailability ===
                                                  'Not Avaible'
                                                ) {
                                                  setIsBasicOpen({
                                                    isOpen: true,
                                                    meetingSchemaId:
                                                      card.meetingSchemaId,
                                                    index: index,
                                                  });
                                                } else {
                                                  if (fromRecompense) {
                                                    setOnRecompenseAction(
                                                      <>
                                                        <span
                                                          className="d-flex position-relative mx-auto  justify-content-center fs-6 my-2"
                                                          style={{
                                                            maxWidth: '550px',
                                                          }}
                                                        >
                                                          <strong>
                                                            {t(
                                                              'button.set_date_question'
                                                            )}
                                                          </strong>
                                                        </span>
                                                        <div className="d-flex justify-content-around">
                                                          <div className="col-4">
                                                            <Button
                                                              text={t(
                                                                'button.yes'
                                                              )}
                                                              type={
                                                                ButtonType.Success
                                                              }
                                                              textColor={
                                                                TextColors.Light
                                                              }
                                                              onClick={() =>
                                                                onSetdate(card)
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-4">
                                                            <Button
                                                              text={t(
                                                                'button.no'
                                                              )}
                                                              type={
                                                                ButtonType.Danger
                                                              }
                                                              textColor={
                                                                TextColors.Light
                                                              }
                                                              onClick={() =>
                                                                setOnRecompenseAction(
                                                                  ''
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  } else {
                                                    onSelectMentor(card);
                                                  }
                                                }
                                              }}
                                              text={
                                                fromRecompense ? (
                                                  <strong
                                                    style={{
                                                      fontSize: '0.7em',
                                                    }}
                                                  >
                                                    {t('button.set_date')}
                                                  </strong>
                                                ) : (
                                                  <strong
                                                    style={{
                                                      fontSize: '0.7em',
                                                    }}
                                                  >
                                                    {getButtonTextByPath(card)}
                                                  </strong>
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          key={`f_d${card.date}_${card.speakerId}_${index}_${
                            index - card.capacity
                          }`}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <img
                            key={`pop_upcard_image_${index}_${card.speakerId}`}
                            alt={card.speakerImage}
                            className="img-fluid me-sm-2 me-1"
                            src={card.speakerImage}
                            style={{
                              height:
                                popupDetails?.contents?.length < 2 &&
                                !mobileView
                                  ? '120px'
                                  : popupDetails?.contents?.length > 1 &&
                                    !mobileView
                                  ? '100px'
                                  : '120px',
                              width: '100px',
                              borderRadius: '0.5rem',
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/images/empty-profile.png';
                            }}
                          />
                          <div
                            key={`s_d${card.date}_${card.speakerId}_${index}_${
                              index - card.capacity
                            }`}
                            className="p-0 m-0 align-items-sm-center align-items-md-start flex-grow-1"
                            style={{
                              marginBottom: '-30px',
                              cursor:
                                controlPage !== undefined
                                  ? 'pointer'
                                  : 'default',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (controlPage !== undefined) {
                                handleButtonClick(
                                  card.meetingId,
                                  card.date,
                                  card.interval
                                );
                              }
                            }}
                          >
                            <Card
                              padding={8}
                              key={`pop_up_card_${index}_${card.date}_${card.speakerId}`}
                              cardTitle={{
                                title: (
                                  <div className="d-flex justify-content-sm-between justify-content-md-start align-items-sm-center align-items-md-start">
                                    <h6 className="flex-grow-1">
                                      <strong
                                        key={`pop_up_card_title_${index}_${card.date}_${card.speakerId}`}
                                        style={{
                                          fontSize: '0.8em',
                                        }}
                                      >
                                        {card.speakerName}
                                      </strong>
                                    </h6>
                                    {!mobileView && (
                                      <div
                                        className={`${
                                          ButtonType[
                                            t(
                                              setColorOn(
                                                area ?? card?.userArea
                                              ),
                                              {
                                                lng: 'en',
                                              }
                                            )
                                          ]
                                        } flex-shrink-sm-1 align-middle text-white fw-bold d-flex rounded`}
                                        style={{
                                          fontSize: '0.8em',
                                        }}
                                      >
                                        <span
                                          key={`pop_up_card_div_${index}_${card.date}_${card.speakerId}`}
                                          className="d-flex p-1"
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {card?.locationName}{' '}
                                          {t(
                                            `UserArea.${
                                              area ?? card?.userArea
                                            }`,
                                            {
                                              lng: 'en',
                                            }
                                          )}{' '}
                                          {card?.productType === 'Private'
                                            ? t('TypeOfService.0')
                                            : t('TypeOfService.1')}
                                          {card?.productType === 'Group' && (
                                            <div
                                              key={`pop_up_card_div_capacity_${index}_${card.date}_${card.speakerId}`}
                                              className="ps-1 text-capitalize"
                                            >
                                              {t('onlineGroup.person', {
                                                person: card?.capacity,
                                              })}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    )}
                                    {controlPage !== undefined &&
                                      isAdmin &&
                                      !mobileView && (
                                        <div
                                          className="d-flex justify-content-end"
                                          key={`pop_up_card_body_6std${index}_${card.date}_${card.speakerId}`}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <ActionDropdown
                                            data={card}
                                            id={'activeMeetingPopup'}
                                            onClick={() => {
                                              setIsOpen(!isOpen);
                                            }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                ),
                              }}
                              minHeight={
                                popupDetails?.contents?.length < 2
                                  ? 150
                                  : undefined
                              }
                              shadow={true}
                              scheduleBorder={card.productType}
                              cardRounded={false}
                              body={
                                <div
                                  key={`pop_up_card_body_1std${index}_${card.date}_${card.speakerId}`}
                                >
                                  {/* //TODO here mobil görünüm */}
                                  {mobileView && (
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div
                                        className={`${
                                          ButtonType[
                                            t(
                                              setColorOn(
                                                area ?? card?.userArea
                                              ),
                                              {
                                                lng: 'en',
                                              }
                                            )
                                          ]
                                        } d-flex align-items-center text-white fw-bold rounded`}
                                        style={{
                                          fontSize: '0.8em',
                                          height: '20px',
                                        }}
                                      >
                                        <span
                                          key={`pop_up_card_div_${index}_${card.date}_${card.speakerId}`}
                                          className="d-flex p-1"
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {card?.locationName}{' '}
                                          {card?.locationId === 1 &&
                                            t(
                                              `UserArea.${
                                                area ?? card?.userArea
                                              }`,
                                              {
                                                lng: 'en',
                                              }
                                            )}{' '}
                                          {card?.productType === 'Private' &&
                                            t('TypeOfService.0')}
                                          {card?.productType === 'Group' && (
                                            <div
                                              key={`pop_up_card_div_capacity_${index}_${card.date}_${card.speakerId}`}
                                              className="ps-1 text-capitalize"
                                            >
                                              {t('onlineGroup.person', {
                                                person: card?.capacity,
                                              })}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                      {controlPage !== undefined && isAdmin && (
                                        <div
                                          className="d-flex justify-content-end"
                                          key={`pop_up_card_body_6std${index}_${card.date}_${card.speakerId}`}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <ActionDropdown
                                            data={card}
                                            id={'activeMeetingPopup'}
                                            onClick={() => {
                                              setIsOpen(!isOpen);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    key={`pop_up_card_body_2std${index}_${card.date}_${card.speakerId}`}
                                  >
                                    <div
                                      className={`flex-shrink-1  p-1 `}
                                      style={{
                                        fontSize: '0.8em',
                                      }}
                                    >
                                      <div
                                        key={`pop_up_card_body_5std${index}_${card.date}_${card.speakerId}`}
                                        className={` text-nowrap`}
                                      >
                                        {t('weeklyCalendarModal.time')}
                                        {`${calculateUTC(
                                          card?.interval.split('-')[0]
                                        )}-${calculateUTC(
                                          card?.interval.split('-')[1]
                                        )}`}
                                        <div className="text-nowrap  ">
                                          {t('weeklyCalendarModal.level')}
                                          {t(`${card?.languageLevel}`)}
                                        </div>
                                        {card.ageInterval && (
                                          <span>
                                            {t('weeklyCalendarModal.age')}
                                            {card.ageInterval}
                                          </span>
                                        )}
                                        {card.productType === 'Group' && (
                                          <div className="row d-flex justify-content-between align-items-center">
                                            <span className="">
                                              <strong>
                                                {card.attendeeCount === 0 ? (
                                                  t(
                                                    'weeklyCalendarModal.first_person'
                                                  )
                                                ) : (
                                                  <IconDisplay
                                                    iconCount={
                                                      card.attendeeCount
                                                    }
                                                    capacity={card.capacity}
                                                  />
                                                )}
                                              </strong>
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        </div>
                      )
                    )
                  : !noAction && (
                      <MeetingDetails
                        requestData={meetingDetailsData}
                        handleBack={(val) => setChangeModalContent(val)}
                        calendarRequest={() => {
                          closePopup();
                          dispatch(
                            Actions.calendarActions.getAllBOCalendarAction(
                              filter
                            )
                          );
                        }}
                        date={popupData?.qq}
                        filter={sendFilterData}
                      />
                    )}
              </div>
            )
          }
          onClose={() => closePopup()}
        />
      )}
      {controlPage !== undefined && getMeetings?.length === 0 && (
        <Toast
          id={'weeklyCalenderMeetingDetails'}
          stateName={'meetingAttendee'}
          onClose={() => {
            closePopup();
            dispatch(Actions.calendarActions.getAllBOCalendarAction(filter));
            dispatch(Actions.meetingAttendeeActions.cleanState(true));
          }}
        />
      )}
      {dateIssue?.open && (
        <Modal
          isVisible={dateIssue?.open}
          styleProps={{ maxWidth: 550 }}
          content={
            <>
              {dateIssue.text}
              <div className="d-flex justify-content-evenly">
                <div className="mx-2">
                  <Button
                    text={t('button.cancel')}
                    type={ButtonType.Danger}
                    outline
                    bold
                    onClick={() => handleTimeDiffIssue(false)}
                    buttonCol={12}
                  />
                </div>
                <div className="mx-2">
                  <Button
                    text={t('button.continue')}
                    type={ButtonType.Success}
                    outline
                    bold
                    onClick={() => handleTimeDiffIssue(true)}
                    buttonCol={12}
                  />
                </div>
              </div>
            </>
          }
          onClose={() => handleTimeDiffIssue(false)}
        />
      )}
      {calendarLoading && <Loader />}
    </div>
  );
}

export default WeeklyCalendar;
