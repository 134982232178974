import {
  removeRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(
    endPoint.timesPerMonth.CREATE_TIMESPERMONTH,
    createData
  );
};
export const update = async (updateData, id) => {
  return await putRequest(
    `${endPoint.timesPerMonth.UPDATE_TIMESPERMONTH}/${id}`,
    updateData
  );
};
export const remove = async (id) => {
  return await removeRequest(
    `${endPoint.timesPerMonth.REMOVE_TIMESPERMONTH}/${id}`
  );
};
export const getById = async (id) => {
  return await getRequest(
    `${endPoint.timesPerMonth.GETBYID_TIMESPERMONTH}/${id}`
  );
};
export const getAll = async () => {
  return await getRequest(endPoint.timesPerMonth.GETALL_TIMESPERMONTH);
};
