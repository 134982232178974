import React, { useEffect, useState } from 'react';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  ButtonWidth,
  TextColors,
} from '../../../utils/ComponentEnums';
import { LoaderButton } from '../Loader/LoaderButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchButton = ({
  onClick,
  size,
  bold,
  width,
  id,
  stateName,
  filterChanged,
  mountingStatus,
}) => {
  const { t } = useTranslation();
  const isSuccess = useSelector((e) => e[stateName].isSuccess);
  let justLanded = mountingStatus || mountingStatus === null;

  let className = `btn ${
    isSuccess === null || !filterChanged || justLanded
      ? `btn${ButtonType.Secondary}`
      : ButtonType.Successbg
  } ${bold ? 'fw-bold' : ''}`;

  switch (size) {
    case ButtonSize.Small:
      className += ButtonSize.Small;
      break;
    case ButtonSize.Large:
      className += ButtonSize.Large;
      break;
    default:
      break;
  }

  switch (width) {
    case ButtonWidth.S:
      className += ButtonWidth.S;
      break;
    case ButtonWidth.M:
      className += ButtonWidth.M;
      break;
    case ButtonWidth.L:
      className += ButtonWidth.L;
      break;
    default:
      className += ButtonWidth.XL;
      break;
  }

  // Debounce function

  return (
    <div className="col-12 col-md-1">
      <button
        id={id}
        type="button"
        className={`${className} d-flex align-items-center justify-content-center`}
        onClick={onClick}
        disabled={isSuccess === null || !filterChanged || justLanded}
      >
        {isSuccess === null && !filterChanged && !justLanded ? null : (
          <FontAwesomeIcon
            className={`${TextColors.Light} pe-2`}
            icon={ButtonIcon.Search}
          />
        )}
        <span className={TextColors.Light}>
          {isSuccess === null && !filterChanged && !justLanded ? (
            <LoaderButton />
          ) : (
            t('cardHeaderFilter.search')
          )}
        </span>
      </button>
    </div>
  );
};

export default SearchButton;
