import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../components/Common/Card/Card';

const BackOfficeRoles = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Roles</h1>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card
            header={'Roles'}
            body={
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">RoleName</th>
                      <th scope="col">NormalizedName</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Admin</td>
                      <td>Admin</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Attendee</td>
                      <td>Attendee</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Speaker</td>
                      <td>Speaker</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Manager</td>
                      <td>Manager</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>CompanyAd</td>
                      <td>CompanyAd</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
          ></Card>
        </div>
      </div>
    </div>
  );
};
export default BackOfficeRoles;
