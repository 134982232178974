import {
    removeRequest,
    getRequest,
    postRequest,
    putRequest,
} from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
    return await postRequest(endPoint.company.Create, createData);
};
export const update = async (updateData) => {
    return await putRequest(endPoint.company.Update, updateData);
};
export const remove = async (id) => {
    return await removeRequest(`${endPoint.company.Remove}/${id}`);
};
export const getById = async (id) => {
    return await getRequest(`${endPoint.company.GetById}/${id}`);
};
export const getAll = async (filterData) => {
    return await postRequest(endPoint.company.GetAll, filterData);
};
export const dropdownList = async () => {
    return await getRequest(`${endPoint.company.GetAll}/Dropdown`);
};
export const getCompanyAttendeesDropdown = async (id) => {
    return await getRequest(`${endPoint.company.GetCompanyAttendeesDropdown}/${id}`);
};
export const getCompanyAttendees = async (id) => {
    return await getRequest(`${endPoint.company.GetCompanyAttendees}/${id}`);
};
export const getCompanyManagers = async (id) => {
    return await getRequest(`${endPoint.company.GetCompanyManagers}/${id}`);
};
export const createCompanyOrders = async (data) => {
    return await putRequest(endPoint.company.CreateCompanyOrders, data);
};
export const getCompanyOrders = async (id) => {
    return await getRequest(`${endPoint.company.GetCompanyOrders}/${id}`);
};
export const assignCompanyOrders = async (createData) => {
    return await postRequest(endPoint.company.AssignCompanyOrders, createData);
};