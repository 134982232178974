import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Actions from '../../../store/redux/actions';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import SearchButton from '../../../components/Common/Button/SearchButton';
import Toast from '../../../components/Common/Popup/Toast';
import { ButtonSize, InputType } from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import { Entities } from '../../../utils/Enum';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import checkClaims from '../../../hooks/useCheckClaims';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const paymentList = useSelector((e) => e.order.payments);
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const locationList = useSelector((e) => e.location.getAll);
  const staticDatas = useSelector((e) => e.staticData);
  const managerList = useSelector((e) => e.manager.getAll);
  const userAreaList = staticDatas.UserArea;
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(
      null,
      true,
      false,
      true
    );

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
    setMountStatus(false);
  }, []);

  useEffect(() => {
    getList();
  }, [mountStatus]);

  const getList = (func = false) => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.order.getAll) &&
      Object.keys(filterData).length > 0 &&
      mountStatus !== null
    ) {
      const action = Actions.orderActions.paymentsAction(filterData);
      if (dateChanged && func) {
        setShowLoader(true);
        dispatch(action).then(() => setShowLoader(false));
        setDateChanged(!dateChanged);
      } else if (dateChanged === false) {
        setShowLoader(true);
        dispatch(action).then(() => setShowLoader(false));
      }
    }
  };

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        getList(true);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const onSearch = (e) => {
    e.preventDefault();
    getList(true);
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        setDateChanged(true);
        return rest;
      });
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      setDateChanged(true);
      return rest;
    });
  };

  const headers = [
    { key: 'orderId', label: t('common.orderId') },
    { key: 'id', label: t('orderItem.orderItemId') },
    { key: 'attendeeName', label: t('order.attendee_name') },
    { key: 'phoneNumber', label: t('common.phone') },
    { key: 'location', label: t('order.table_location') },
    { key: 'paymentType', label: t('order.payment_type') },
    { key: 'cardPaymentType', label: t('order.cardPaymentType') },
    { key: 'paymentStatus', label: t('order.payment_status') },
    { key: 'installmentCount', label: t('order.installmentCount') },
    // { key: 'totalAmount', label: t('order.total_amount') },
    { key: 'netAmount', label: t('order.net_amount') },
    { key: 'parasutInvoiceId', label: t('order.parasutInvoiceId') },
    { key: 'paymentDate', label: t('order.payment_date') },
    { key: 'createdAt', label: t('common.created_at') },
    { key: 'managerName', label: t('attendeeList.manager') },
    // { key: 'createdBy', label: t('common.created_by') },
    { key: 'updatedAt', label: t('common.updated_at') },
    // { key: 'updatedBy', label: t('common.updated_by') },
  ];

  const cardHeader = [
    { key: 'totalAmount', label: t('order.totalAmount') },
    { key: 'totalActiveOrderAmount', label: t('order.totalActiveOrderAmount') },
  ];

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch(Actions.locationActions.getAllAction());
    }
    if (managerList.length === 0) {
      dispatch(Actions.managerActions.getAllAction('Dropdown'));
    }
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('orderPayment.payment_list')} strong />
      </div>
      <div className="col">
        <Card
          body={
            <React.Fragment>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4 form-div-border">
                <HeadTags
                  hSize={6}
                  strong
                  text={t('cardHeaderFilter.filter')}
                />
              </div>
              <div className="d-sm-flex align-items-center justify-content-end">
                <SearchButton
                  stateName={'order'}
                  size={ButtonSize.Small}
                  bold
                  onClick={onSearch}
                  filterChanged={dateChanged}
                  mountingStatus={mountStatus}
                />
              </div>
              <div className="row my-2">
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'name'}
                    inputType={InputType.Text}
                    labelText={t('order.filter_attendee_search')}
                    inputValue={filterData.name || ''}
                    onChange={onChangeText}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'startDate'}
                    inputType={InputType.Date}
                    labelText={t('common.start_date')}
                    inputValue={filterData.startDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'endDate'}
                    inputType={InputType.Date}
                    labelText={t('common.end_date')}
                    inputValue={filterData.endDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_location')}
                    options={locationList}
                    selectedProps={{ key: 'locationName', value: 'id' }}
                    onChange={(value) => handleDropdown('SType2', value)}
                    onReset={() => handleResetDropdown('SType2')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_user_area')}
                    options={userAreaList}
                    selectedProps={{ key: 'key', value: 'value' }}
                    onChange={(value) => handleDropdown('SType3', value)}
                    onReset={() => handleResetDropdown('SType3')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_manager')}
                    options={managerList}
                    selectedProps={{ key: 'managerName', value: 'managerId' }}
                    onChange={(value) => handleDropdown('sType', value)}
                    onReset={() => handleResetDropdown('sType')}
                    isfilter
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
        <Card
          body={
            <div>
              <div className="col-lg-3 ms-auto">
                <Card
                  body={
                    <>
                      {cardHeader.map((val, i) => (
                        <div
                          key={i}
                          className={`d-flex justify-content-between border-bottom py-1 ${
                            i % 2 ? 'bg-light' : ''
                          }`}
                        >
                          <div className="fw-bold px-1">
                            {t(`order.${val.key}`)}
                          </div>
                          <div className="px-1">
                            {paymentList[val.key] &&
                            paymentList[val.key].length > 0
                              ? paymentList[val.key].map(
                                  (currencyItem, index) => (
                                    <div key={index}>
                                      {currencyItem.total}{' '}
                                      {currencyItem.currency}
                                    </div>
                                  )
                                )
                              : ''}
                          </div>
                        </div>
                      ))}
                    </>
                  }
                />
              </div>
              <Table
                // searchAble
                data={paymentList?.orderList ?? []}
                showLoading={showLoader}
                headers={headers}
                striped
                bordered
                page={Entities.order}
                currentUserClaim={currentUserClaim}
                exportName={Entities.order.exportName.payments}
              />
            </div>
          }
        />
      </div>
      <Toast
        stateName={'order'}
        onClose={() => {
          dispatch(Actions.orderActions.cleanState(true));
          getList(true);
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.accountingDept,
    content
  );
};
export default List;
