import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import Toast from '../../../components/Common/Popup/Toast';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import checkClaims from '../../../hooks/useCheckClaims';
import validationMessage from '../../../hooks/useValidationMessage';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const leadId = location?.state?.id;
  const currentUserClaim = useSelector((e) => e.auth.claim);
  const leadData = useSelector((e) => e.lead.getById);
  const levelGroup = useSelector(
    (e) => e.preferenceOptions.preferenceLevelGroup
  );
  const staticDatas = useSelector((e) => e.staticData);
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [error, setError] = useState({});
  const redirect = '/lead-list';

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.leadActions.cleanState());
  };
  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\+90\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/;
    return phoneRegex.test(value);
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;

    let formattedValue = value;

    if (id === 'phone') {
      const numericValue = value.replace(/\D/g, '');

      if (numericValue.startsWith('90')) {
        formattedValue = '+90';

        const digits = numericValue.substring(2, 12);
        if (digits.length >= 10) {
          formattedValue +=
            ' ' + digits.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
        } else {
          formattedValue += ' ' + digits;
        }
      }
    }

    setUpdateData({ ...updateData, [id]: formattedValue });

    const validationResult =
      id === 'phone' ? validatePhoneNumber(formattedValue) : true;

    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    setDataChanged(true);
  };

  const onSave = (e) => {
    e.preventDefault();
    dispatch(Actions.leadActions.updateAction(updateData));
  };

  const handleDropdown = (propName, value) => {
    setUpdateData({ ...updateData, [propName]: value });
    setDataChanged(true);
  };

  function FetchById() {
    dispatch(Actions.leadActions.getByIdAction(leadId));
  }

  useEffect(() => {
    if (
      Object.keys(leadData).length > 0 &&
      Object.keys(updateData).length === 0
    ) {
      setUpdateData({ ...leadData, id: leadId });
    }
  }, [leadData]);

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  const selectedLanguageLevels = [];

  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    if (staticDatas.LanguageLevels[levelGroup?.adult]) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    }
  }
  useEffect(() => {
    FetchById();
    return () => {
      dispatch(Actions.leadActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-11">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2 mb-2 mb-md-0 mt-sm-3">
                  <div className="col-12 col-md-1">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mb-3 mb-md-4 form-div-border">
                  <HeadTags hSize={5} strong text={t('lead.update_form')} />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('common.fullName')}
                      inputValue={updateData.name || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-md-6 mt-2 mt-md-0">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Email}
                      labelText={t('common.email')}
                      inputValue={updateData.email || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-md-6 mt-3 mt-md-4">
                    <DropdownWithSearch
                      placeHolder={t('dropdownSelect.select_learningPurpose')}
                      options={staticDatas.LearningPurpose}
                      selectedProps={{
                        key: 'search',
                        value: 'search',
                        searchKey: 'search',
                      }}
                      selectedValues={updateData?.learningPurpose}
                      onChange={(value) =>
                        handleDropdown('learningPurpose', value)
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-3 mt-md-4">
                    <DropdownWithSearch
                      placeHolder={t('dropdownSelect.select_level')}
                      options={selectedLanguageLevels}
                      selectedProps={{
                        key: 'search',
                        value: 'search',
                        searchKey: 'search',
                      }}
                      selectedValues={updateData?.languageLevel}
                      onChange={(value) =>
                        handleDropdown('languageLevel', value)
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <InputLabelOnBorder
                      id={'phone'}
                      inputType={InputType.Tel}
                      labelText={t('common.phone')}
                      inputValue={updateData.phone || ''}
                      onChange={onChangeText}
                      placeholder={'+90 (5xx) xxx xx xx'}
                      error={error?.phone}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center justify-content-md-end mt-3">
                  <div className="col-12 col-md-2">
                    <SubmitButton
                      stateName={'lead'}
                      onClick={onSave}
                      objectLengthRule={
                        dataChanged &&
                        Object.values(error).every((e) => e === undefined)
                          ? false
                          : true
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'lead'}
        onClose={() => {
          setUpdateData({});
          dispatch(Actions.leadActions.cleanState());
          navigate(redirect);
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.lead.update,
    content
  );
};
export default Update;
