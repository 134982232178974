import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import {
  faInbox,
  faPaperclip,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../../components/Common/Modal/Modal';
import Button from '../../../components/Common/Button/Button';

import { ButtonType, ButtonWidth } from '../../../utils/ComponentEnums';
import { changeLanguage } from 'i18next';

const AllNotifications = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [data, setData] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get('http://localhost:4000/notificationsData') //json web server test data
  //     .then((response) => {
  //       setData(response.data);
  //     })
  //     .catch((error) => {
  //       // Handle error
  //     });
  // }, []);

  const groupedData = data?.reduce((acc, obj) => {
    const paramValue = obj.day; // choose the parameter to group by
    if (!acc[paramValue]) {
      acc[paramValue] = [];
    }
    acc[paramValue].push({
      id: obj.id,
      time: obj.time,
      isRead: obj.isRead,
      avatar: obj.avatar,
      name: obj.name,
      action: obj.action,
      type: obj.type,
      service: obj.service,
      files: obj.files,
      message: obj.message,
    });
    return acc;
  }, {});

  const activityClass = (type) => {
    switch (type) {
      case 'Online':
        return 'border-custom text-custom fw-bold bg-custom bg-opacity-25  ';
      case 'Kids':
        return 'border-t-turuncu text-t-turuncu bg-t-turuncu bg-opacity-25 ';
      case 'Cafe':
        return 'border-t-sarı text-t-sarı fw-bold bg-t-sarı bg-opacity-25  ';
      case 'Company':
        return 'border-t-calendar text-t-calendar bg-t-calendar bg-opacity-25 ';
      default:
        return 'border-custom text-custom fw-bold bg-custom bg-opacity-25  ';
    }
  };

  const [isBasicOpen, setIsBasicOpen] = useState(false);

  const [modalData, setModalData] = useState(null);
  const handleModalClose = () => {
    setModalData(null);
    setIsBasicOpen(false);
  };

  useEffect(() => {
    const topBarData = JSON.parse(localStorage?.getItem('topBarData'));
    localStorage?.removeItem('topBarData');
    const fromWhere = location?.state?.fromNavigateWhere === 'notification';
    if (fromWhere && topBarData) {
      setModalData(topBarData);
      setIsBasicOpen(true);
    }
  }, [location?.state]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="box shadow-sm rounded  mb-3">
            <div className="box-title  p-3">
              <h3 className="ms-4"> {t('headerNotification.view_all')}</h3>
            </div>

            {Object?.keys(groupedData)?.length === 0 ? (
              <div className="h6 text-center my-5 ">
                {t('headerNotification.no_notifications')}
              </div>
            ) : (
              <div>
                {Object?.keys(groupedData).map((key, index) => (
                  <div
                    key={index}
                    className="box-body p-0"
                    style={{ fontSize: '0.9em' }}
                  >
                    <div className=" position-relative ms-5 my-5 fw-bold">
                      {key}
                    </div>

                    {groupedData[key].map((obj, index) => (
                      <div
                        key={index}
                        className=" d-flex align-items-center osahan-post-header"
                      >
                        <section className=" ms-5">
                          <ul className="list-unstyled position-relative">
                            <li className="position-relative ms-4 ">
                              <div className="text-muted fw-bold mx-auto">
                                {obj.time}
                              </div>

                              <div
                                key={obj.id}
                                role="button"
                                className="timelineBorderLeft ms-2  "
                                onClick={() => {
                                  setModalData(obj);
                                  setIsBasicOpen(true);
                                }}
                              >
                                <div className="ms-5">
                                  <div className="d-flex align-items-center">
                                    {obj.type === 'Success' ? (
                                      <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        className="bg-custom"
                                        style={{
                                          height: '40px',
                                          width: '40px',
                                          borderRadius: '20%',
                                        }}
                                      ></FontAwesomeIcon>
                                    ) : obj.type === 'Info' ? (
                                      <FontAwesomeIcon
                                        icon={faInbox}
                                        className="bg-info"
                                        style={{
                                          height: '40px',
                                          width: '40px',
                                          borderRadius: '20%',
                                        }}
                                      ></FontAwesomeIcon>
                                    ) : (
                                      <img
                                        src={obj.avatar}
                                        alt={obj.name}
                                        style={{
                                          height: '50px',
                                          width: '50px',
                                          borderRadius: '20%',
                                        }}
                                      />
                                    )}

                                    <div className="mx-2">
                                      <h5
                                        className="fw-bold"
                                        style={{ fontSize: '1.2em' }}
                                      >
                                        {obj.name}{' '}
                                        {obj.action && (
                                          <span className="text-muted ">
                                            {obj.action}
                                          </span>
                                        )}
                                      </h5>
                                      {obj.service &&
                                        (Array.isArray(obj.service) ? (
                                          obj.service.map((type) => (
                                            <span
                                              key={type.id}
                                              className={`${activityClass(
                                                type.type
                                              )} badge border fw-bold rounded-3 d-inline m-1`}
                                            >
                                              {type.type}
                                            </span>
                                          ))
                                        ) : (
                                          <span
                                            className={`${activityClass(
                                              obj.service
                                            )} badge border  fw-bold rounded-3 d-inline m-1`}
                                          >
                                            {obj.service}
                                          </span>
                                        ))}
                                    </div>
                                  </div>

                                  {obj.files && (
                                    <p className="d-flex align-items-center p-2 m-2 rounded-3 bg-light border ">
                                      {obj.files &&
                                        (Array.isArray(obj.files) ? (
                                          obj.files.map((type) => (
                                            <span
                                              key={type.id}
                                              className=" mx-3"
                                            >
                                              {
                                                <FontAwesomeIcon
                                                  icon={faPaperclip}
                                                  className="border border-3 rounded bg-gray"
                                                ></FontAwesomeIcon>
                                              }{' '}
                                              {type.type}
                                            </span>
                                          ))
                                        ) : (
                                          <span
                                            className={`${activityClass(
                                              obj.files
                                            )} badge border  fw-bold rounded-3 d-inline m-1`}
                                          >
                                            {obj.files}
                                          </span>
                                        ))}
                                    </p>
                                  )}

                                  {obj.message && (
                                    <p className="text-muted m-2 ">
                                      {obj.message}
                                    </p>
                                  )}
                                </div>

                                <Modal
                                  isVisible={isBasicOpen}
                                  title={
                                    <div key={obj.id} className="mx-2">
                                      <h5
                                        className="fw-bold"
                                        style={{ fontSize: '0.7em' }}
                                      >
                                        {modalData?.name}{' '}
                                        {modalData?.action && (
                                          <span className="text-muted ">
                                            {modalData?.action}
                                          </span>
                                        )}
                                      </h5>
                                      {modalData?.service &&
                                        (Array.isArray(modalData?.service) ? (
                                          modalData?.service.map((type) => (
                                            <span
                                              key={type.id}
                                              className={`${activityClass(
                                                type.type
                                              )} badge border fw-bold rounded-3 d-inline m-1`}
                                            >
                                              {type.type}
                                            </span>
                                          ))
                                        ) : (
                                          <span
                                            className={`${activityClass(
                                              modalData?.service
                                            )} badge border  fw-bold rounded-3 d-inline m-1`}
                                          >
                                            {modalData?.service}
                                          </span>
                                        ))}
                                    </div>
                                  }
                                  content={
                                    <div>
                                      {' '}
                                      {modalData?.files && (
                                        <p className="d-flex align-items-center p-2 m-2 rounded-3 bg-light border ">
                                          {modalData?.files &&
                                            (Array.isArray(modalData?.files) ? (
                                              modalData?.files.map((type) => (
                                                <span
                                                  key={type.id}
                                                  className=" mx-3"
                                                >
                                                  {
                                                    <FontAwesomeIcon
                                                      icon={faPaperclip}
                                                      className="border border-3 rounded bg-gray"
                                                    ></FontAwesomeIcon>
                                                  }{' '}
                                                  {type.type}
                                                </span>
                                              ))
                                            ) : (
                                              <span
                                                className={`${activityClass(
                                                  modalData?.files
                                                )} badge border  fw-bold rounded-3 d-inline m-1`}
                                              >
                                                {modalData?.files}
                                              </span>
                                            ))}
                                        </p>
                                      )}
                                      {modalData?.message && (
                                        <p className="text-muted m-2 ">
                                          {modalData?.message}
                                        </p>
                                      )}
                                    </div>
                                  }
                                  footer={
                                    <Button
                                      type={ButtonType.Successbg}
                                      bold
                                      text={'Kapat'}
                                      onClick={handleModalClose}
                                      buttonCol={3}
                                    />
                                  }
                                  onClose={handleModalClose}
                                />
                              </div>
                            </li>
                          </ul>
                        </section>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllNotifications;
