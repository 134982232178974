import React from 'react';

const Carousel = ({ contents }) => {
  return (
    <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="false">
      <div className="carousel-inner">
        {contents.map((content, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4">
                <div className="card border">
                  <div className="card-body">
                    <p className="card-title fs-6">{content.name}</p>
                  </div>
                  <div className="ratio ratio-4x3">
                    <iframe
                      className="embed-responsive-item rounded-4 py-2 px-4"
                      src={content.videoSrc}
                      title={content.name}
                      allowFullScreen
                      loading="lazy"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>
  );
};

export default Carousel;
