import React, { useState } from 'react';
import Card from '../../../components/Common/Card/Card';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';

const segmentOptions = [
  { segmentId: 1, segmentName: 'Status', segmentType: 'string' },
  { segmentId: 2, segmentName: 'Services Type', segmentType: 'integer' },
  { segmentId: 3, segmentName: 'Session Type' },
];

const operatorOptions = [
  { operatorId: 1, operatorName: 'IS' },
  { operatorId: 2, operatorName: 'CONTAINS' },
];

const valuesOptions = {
  1: [
    { valueId: 1, valueName: 'New Sign Up' },
    { valueId: 2, valueName: 'First Login' },
    { valueId: 3, valueName: 'Refer ile Gelenler' },
    { valueId: 4, valueName: 'All Attendees' },
  ],
  2: [
    { valueId: 5, valueName: 'Online' },
    { valueId: 6, valueName: 'Cafe' },
    { valueId: 7, valueName: 'Company' },
    { valueId: 8, valueName: 'Kids' },
  ],
  3: [
    { valueId: 9, valueName: 'Group' },
    { valueId: 10, valueName: 'One to One' },
  ],
};

const SegmentEditor = () => {
  const [segments, setSegments] = useState([]);
  const [segmentGroups, setSegmentGroups] = useState([
    {
      segments: [
        // Initial segment row for the first group
        {
          SegmentName: '',
          Operator: '',
          Value: '',
        },
      ],
      logic: 'AND',
    },
  ]);

  const [savedSegmentGroups, setSavedSegmentGroups] = useState([]);
  const [segmentRows, setSegmentRows] = useState([1]);
  const [createData, setCreateData] = useState({});
  const addSegment = (groupIndex) => {
    const newSegment = {
      SegmentName: '',
      Operator: '',
      Value: '',
    };

    const updatedSegmentGroups = [...segmentGroups];
    updatedSegmentGroups[groupIndex].segments.push(newSegment);
    setSegmentGroups(updatedSegmentGroups);

    // Update the segments state with all segments from all groups
    const allSegments = updatedSegmentGroups.reduce(
      (acc, segmentGroup) => [...acc, ...segmentGroup.segments],
      []
    );
    setSegments(allSegments);
  };

  const addSegmentGroup = () => {
    const newSegmentGroup = {
      segments: [],
      logic: 'AND',
    };
    const updatedSegmentGroups = [...segmentGroups, newSegmentGroup];
    setSegmentGroups(updatedSegmentGroups);

    const updatedSegmentRows = [...segmentRows];
    updatedSegmentRows.push(1);
    setSegmentRows(updatedSegmentRows);
  };

  const onBlur = (e) => {
    e.preventDefault();
    // dispatch(Actions.meetingSchemaActions.holdValue({ ...createData }));
  };
  const handleDropdown = (propName, value, groupIndex, segmentIndex) => {
    // Update the selected segment row with the new value
    if (propName === 'segmentId') {
      setCreateData({ ...createData, [propName]: value });

      // Update the segment name when segmentId changes
      const updatedSegmentGroups = [...segmentGroups];
      updatedSegmentGroups[groupIndex].segments[segmentIndex].segmentId = value;
      setSegmentGroups(updatedSegmentGroups);
    } else {
      setCreateData({ ...createData, [propName]: value });

      // Update the selected segment row with the new value
      const updatedSegmentGroups = [...segmentGroups];
      updatedSegmentGroups[groupIndex].segments[segmentIndex][propName] = value;
      setSegmentGroups(updatedSegmentGroups);
    }
  };

  const handleSegmentRowDelete = (groupIndex, segmentIndex) => {
    const updatedSegmentGroups = [...segmentGroups];
    updatedSegmentGroups[groupIndex].segments.splice(segmentIndex, 1);
    setSegmentGroups(updatedSegmentGroups);

    const updatedSegmentRows = [...segmentRows];
    updatedSegmentRows[groupIndex] -= 1;
    setSegmentRows(updatedSegmentRows);
  };

  const handleSegmentGroupLogicChange = (groupIndex, value) => {
    const updatedSegmentGroups = [...segmentGroups];
    updatedSegmentGroups[groupIndex].logic = value;
    setSegmentGroups(updatedSegmentGroups);
  };

  const handleSegmentGroupDelete = (groupIndex) => {
    const updatedSegmentGroups = [...segmentGroups];
    updatedSegmentGroups.splice(groupIndex, 1);
    setSegmentGroups(updatedSegmentGroups);

    const updatedSegmentRows = [...segmentRows];
    updatedSegmentRows.splice(groupIndex, 1);
    setSegmentRows(updatedSegmentRows);
  };

  const saveSegments = () => {
    const savedSegments = segmentGroups.map((segmentGroup) => {
      const { logic, segments } = segmentGroup;
      return {
        logic,
        segments: segments.map((segment) => ({
          SegmentName: segmentOptions.find(
            (option) => option.segmentId === segment.segmentId
          ).segmentName,
          Operator: operatorOptions.find(
            (option) => option.operatorId === segment.operatorId
          ).operatorName,
          Value: valuesOptions[segment.segmentId].find(
            (option) => option.valueId === segment.valueId
          ).valueName,
        })),
      };
    });

    setSavedSegmentGroups(savedSegments);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <div className="col">
          {/* HEre goes the segment group method. */}
          <h4>Segment Editor</h4>
          {segmentGroups.map((segmentGroup, groupIndex) => (
            <>
              <Card
                key={groupIndex}
                body={
                  <>
                    <h4>Segment Group {groupIndex + 1}</h4>

                    {segmentGroup.segments.map((segment, segmentIndex) => (
                      <div className="row d-flex my-3" key={segmentIndex}>
                        <div className="col-3">
                          <DropdownWithSearch
                            isSearchable
                            onBlur={onBlur}
                            placeHolder={'Segment Name'}
                            options={segmentOptions}
                            selectedProps={{
                              key: 'segmentName',
                              value: 'segmentId',
                            }}
                            onChange={(value) =>
                              handleDropdown(
                                'segmentId',
                                value,
                                groupIndex,
                                segmentIndex
                              )
                            }
                          />
                        </div>
                        <div className="col-3">
                          <DropdownWithSearch
                            isSearchable
                            onBlur={onBlur}
                            placeHolder={'Operator'}
                            options={operatorOptions}
                            selectedProps={{
                              key: 'operatorName',
                              value: 'operatorId',
                            }}
                            onChange={(value) =>
                              handleDropdown(
                                'operatorId',
                                value,
                                groupIndex,
                                segmentIndex
                              )
                            }
                          />
                        </div>
                        <div className="col-3">
                          <DropdownWithSearch
                            isSearchable
                            onBlur={onBlur}
                            placeHolder={'Value'}
                            options={valuesOptions[createData?.segmentId]}
                            selectedProps={{
                              key: 'valueName',
                              value: 'valueId',
                            }}
                            onChange={(value) =>
                              handleDropdown(
                                'valueId',
                                value,
                                groupIndex,
                                segmentIndex
                              )
                            }
                          />
                        </div>
                        {segmentIndex > 0 && (
                          <div className="col-1">
                            <button
                              onClick={() =>
                                handleSegmentRowDelete(groupIndex, segmentIndex)
                              }
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="col-3">
                      <button onClick={() => addSegment(groupIndex)}>
                        Add Segment
                      </button>
                    </div>
                  </>
                }
              />
              <div className="d-flex justify-content-center py-2">
                <button
                  className="mx-1"
                  onClick={() =>
                    handleSegmentGroupLogicChange(groupIndex, 'AND')
                  }
                >
                  AND
                </button>
                <button
                  className="mx-1"
                  onClick={() =>
                    handleSegmentGroupLogicChange(groupIndex, 'OR')
                  }
                >
                  OR
                </button>
              </div>
            </>
          ))}

          <div className="d-flex justify-content-center">
            <button onClick={addSegmentGroup}>Add Segment Group</button>
          </div>

          <div className="d-flex justify-content-center">
            <button onClick={saveSegments}>Save Segment Group</button>
          </div>
          <h3>Segments:</h3>
          <ul>
            {savedSegmentGroups.map((savedSegmentGroup, groupIndex) => (
              <li key={groupIndex}>
                <strong>Segment Group {groupIndex + 1}:</strong>
                <ul>
                  {savedSegmentGroup.segments.map((segment, segmentIndex) => (
                    <li key={segmentIndex}>
                      <strong>Segment:</strong> {segment.SegmentName},{' '}
                      <strong>Operator:</strong> {segment.Operator},{' '}
                      <strong>Value:</strong> {segment.Value}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SegmentEditor;
