import React, { useState, useEffect } from 'react';
import axios from '../../services/axios';
import { toast, ToastContainer } from 'react-toastify';
import config from '../../config.json';
import InputLabelOnBorder from '../../components/Common/Input/InputLabelOnBorder';
import Button from '../../components/Common/Button/Button';
import { ButtonType, TextColors } from '../../utils/ComponentEnums';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};
const BASE_URL = config.Connections.API.host;

const TestRedate = () => {
  const [data, setData] = useState([]);
  const [zones, setZones] = useState([]);
  const [packages, setPackages] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');

  useEffect(() => {
    GetZones();
    GetPackages();

    if (selectedZone && selectedPackage) {
      handleRequest();
    }
  }, [selectedZone, selectedPackage]);

  const GetZones = () => {
    axios.get(BASE_URL + 'Zone/GetAll', headers).then((response) => {
      setZones(response.data.data);
    });
  };

  const GetPackages = () => {
    axios.get(BASE_URL + 'SessionPackage/GetAll', headers).then((response) => {
      setPackages(response.data.data);
    });
  };

  const handleZoneChange = (event) => {
    setSelectedZone(event.target.value);
  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };

  const handleRequest = () => {
    axios
      .get(
        BASE_URL +
          `Preference/GetAllRedateParams?zone=${selectedZone}&package=${selectedPackage}`,
        headers
      )
      .then((response) => {
        setData(response.data.data);
      });
  };

  const renderInputValues = (inputValues, inputGroupId, inputGroupName) => {
    return inputValues.map((inputValue) => {
      if (inputValue.type === 'Dropdown') {
        return (
          <div key={inputValue.id} className="colp-2">
            <label
              className="ms-2 position-absolute"
              style={{ marginTop: '-0.25rem', fontSize: '.9rem' }}
            >
              <span className="h6 small bg-white text-muted px-1">
                {inputGroupName}
              </span>
            </label>
            <select
              key={inputValue.id}
              value={inputValue.value}
              className={`form-control mt-2 form-input-border selectStyle`}
              onChange={(e) =>
                handleInputChange(
                  inputGroupId,
                  inputValue.id,
                  e.target.selectedIndex
                )
              }
            >
              {inputValue.data.map((option, index) => (
                <option key={option} value={index}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      } else {
        return (
          <div
            key={inputValue.id}
            className={` col p-2 ${
              ['Zone', 'Package'].includes(inputGroupName) ? 'd-none' : 'd-flex'
            }`}
          >
            <InputLabelOnBorder
              key={inputValue.id}
              id={inputValue.id}
              inputType={inputValue.type}
              labelText={inputGroupName}
              inputValue={inputValue.value}
              onChange={(e) =>
                handleInputChange(inputGroupId, inputValue.id, e.target.value)
              }
            />
          </div>
        );
      }
    });
  };

  const renderInputGroups = (inputGroups) => {
    return inputGroups.map((inputGroup) => (
      <div key={inputGroup.id} className={`row d-flex mx-2 `}>
        <div className="col-md-12 row d-flex ">
          {renderInputValues(
            inputGroup.inputValues,
            inputGroup.id,
            inputGroup.name
          )}
        </div>
        {inputGroup.inputGroups.length > 0 &&
          renderInputGroups(inputGroup.inputGroups)}
      </div>
    ));
  };

  const handleInputChange = (groupId, inputId, value) => {
    const updatedData = { ...data };
    const findInputGroup = (inputGroups) => {
      for (const inputGroup of inputGroups) {
        if (inputGroup.id === groupId) {
          for (const inputValue of inputGroup.inputValues) {
            if (inputValue.id === inputId) {
              inputValue.value = value;
              break;
            }
          }
          break;
        }
        if (inputGroup.inputGroups.length > 0) {
          findInputGroup(inputGroup.inputGroups);
        }
      }
    };

    findInputGroup(updatedData.inputGroups);
    setData(updatedData);
  };

  const handleSubmit = () => {
    axios
      .put(BASE_URL + 'Preference/UpdateRedateParams', data, headers)
      .then((response) => {
        if (response.data.code === 200) {
          setData(response.data.data);
          toast.success('Success');
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="container ">
      <ToastContainer
        limit={1}
        position={'top-center'}
        autoClose={2000}
        closeOnClick
        theme="light"
      />

      <div className="m-5 p-5   bg-white rounded">
        <h2 className="display-5">Meeting Redate</h2>
        <div className="d-flex col my-3 justify-content-evenly ">
          <div className="col-6 p-2">
            <label
              className="ms-2 position-absolute"
              style={{ marginTop: '-0.25rem', fontSize: '.9rem' }}
            >
              <span className="h6 small bg-white text-muted px-1">
                {'Zone'}
              </span>
            </label>
            <select
              name="zone"
              id="zone"
              value={selectedZone}
              className={`form-control mt-2 form-input-border selectStyle`}
              onChange={handleZoneChange}
            >
              <option value="">Zones</option>
              {zones.map((zone) => (
                <option value={zone.id} key={zone.id}>
                  {zone.zoneName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-6 p-2">
            <label
              className="ms-2 position-absolute"
              style={{ marginTop: '-0.25rem', fontSize: '.9rem' }}
            >
              <span className="h6 small bg-white text-muted px-1">
                {'Packages'}
              </span>
            </label>
            <select
              name="package"
              id="package"
              value={selectedPackage}
              className={`form-control mt-2 form-input-border selectStyle`}
              onChange={handlePackageChange}
            >
              <option value="">Packages</option>
              {packages.map((pkg) => (
                <option value={pkg.id} key={pkg.id}>
                  {pkg.person + '-' + pkg.minute}
                </option>
              ))}
            </select>
          </div>
        </div>
        {data.inputGroups && (
          <div>
            <div className="row  d-flex">
              {renderInputGroups(data.inputGroups)}
            </div>
            <div className="mt-3">
              <Button
                type={ButtonType.Successbg}
                text={'Submit'}
                textColor={TextColors.Light}
                buttonCol={3}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestRedate;
