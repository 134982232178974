import { InputType } from '../utils/ComponentEnums';
let result = '';
function validationMessage(type) {
  switch (type) {
    case InputType.Text:
      result = 'please_enter_only_letters';
      break;
    case InputType.Number:
      result = 'please_enter_a_number_greater_than_zero';
      break;
    case InputType.Email:
      result = 'please_enter_a_valid_email_address';
      break;
    case InputType.Password:
      result = 'please_enter_a_valid_password';
      break;
    case InputType.Date:
      result = 'please_enter_a_valid_date';
      break;
    case InputType.Tel:
      result = 'please_enter_a_valid_phone_number';
      break;
    case InputType.Url:
      result = 'please_enter_a_valid_URL';
      break;
    default:
      break;
  }
  return result;
}
export default validationMessage;
