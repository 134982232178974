import {
  recompense,
  setDate,
  recompenseMeeting,
  unRecompense,
  cancelMeeting,
} from '../../../services/apiServices/meetingRedateServices';
import ActionTypes from './actionTypes';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';

const recompenseAction = (data, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await recompense(data, id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const setDateAction = (data, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await setDate(data, id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const unRecompenseAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await unRecompense(data).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const recompenseMeetingAction = (meetingId) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await recompenseMeeting(meetingId).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const cancelMeetingAction = (meetingId) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await cancelMeeting(meetingId).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.meetingAttendee.HANDLE_ERROR,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.meetingAttendee.HANDLE_VALIDATION,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};
const meetingAttendeeActions = {
  recompenseAction,
  setDateAction,
  unRecompenseAction,
  recompenseMeetingAction,
  cancelMeetingAction,
  cleanState,
};
export default meetingAttendeeActions;
