import { storageKeys, UserAreasSring } from '../utils/Enum';

function transformString(inputString) {
  const transformedString = `/${inputString?.toLowerCase()}`;
  return transformedString;
}
function findMyPath(page) {
  const userArea = sessionStorage.getItem(storageKeys.userArea);

  if (userArea === UserAreasSring.None) {
    return `/${page}`;
  }

  const path = transformString(userArea);
  return `${path}/${page}`;
}

export default findMyPath;
